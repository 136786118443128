import React from 'react'
import Stack from '@mui/material/Stack'
import Gallery from './Gallery'
import PrevNext from './PrevNext'

const Setting = (props) => {

  return (
    <Stack
      sx={{ pt: { md: 3.75, xs: 2.5 }, pl: { md: 5, xs: 2 }, pr: { md: 0, xs: 2 }, height: '100%', justifyContent: 'space-between' }}
    >
      <Gallery {...props} />
      <PrevNext />
    </Stack>
  )
}

export default Setting
