/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import Fixed from "../assets/models/fixed_wall.glb";
import { subsystems } from "../utils/constant";
import * as THREE from "three";
import { useSnapshot } from "valtio";
import state from "../state";
import { FixedGlassCount } from "../models/DivCount";
import { xrepeat, yrepeat } from "../utils/constant";

const deltas = [0, -170, -155, -145, -150];
const deltas2 = [0, 2, 20, 8, -4];

export default function FixedWall(props) {
  const { nodes, materials } = useGLTF(Fixed);
  const { px, py, pz, width, height, systemID } = props;
  const int_width = subsystems[systemID].width;
  const int_height = subsystems[systemID].height;
  const glassHeight = subsystems[systemID].glassHeight;
  const delta = int_height - glassHeight;
  const sh2 = height / int_height;
  const dh = height - int_height;
  const dw = width - int_width;
  const sw = (width - delta) / (int_width - delta);
  const sw2 = width / int_width;
  const snap = useSnapshot(state);

  var glassCount = Math.max(
    2,
    parseInt(width / subsystems[systemID].sliderWidth, 0)
  );
  if (props.ID === 4 && snap.columns.addedSubSystem[5] === true) {
    glassCount = Math.max(
      2,
      parseInt((width - 50) / subsystems[systemID].sliderWidth, 0)
    );
  }

  if (glassCount > 4 && glassCount % 2 && props.side % 2)
    glassCount = glassCount + 1;
  if (
    props.side % 2 === 0 &&
    snap.columns.systemDirection[props.ID] == 2 &&
    glassCount % 2 &&
    glassCount > 3
  )
    glassCount = glassCount + 1;
  if (props.side % 2 === 0 && width === 3170) glassCount = 4;
  if (props.side % 2 && width >= 2200) glassCount = Math.max(3, glassCount);
  if (
    props.side % 2 &&
    width <= 3800 &&
    width > 2800 &&
    snap.columns.addedSubSystem[5] === false
  )
    glassCount = 4;

  glassCount = FixedGlassCount(width, glassCount, props.modelID);
  state.systemSliderCount[props.ID] = glassCount;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  // texture.repeat.set(xrepeat, yrepeat);
  texture.repeat.set(1, 0.05);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  state.subSystemWidth[props.ID] = width;
  state.subSystemHeight[props.ID] = height;

  const div_width = (width - delta + 80) / glassCount;

  const list = [];
  for (var i = 0; i < glassCount - 1; i = i + 1) {
    list.push(
      <group key={i} position={[div_width * (i + 1) * 0.001, 0, 0]}>
        <mesh
          geometry={nodes["inner-80"].geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[-1.74 - (dw * 0.001) / 2, 1.39 + (dh * 0.001) / 2, 0.01]}
          rotation={[0, 0, Math.PI / 2]}
          scale={[0.001 * sh2, 0.001, 0.001]}
          material-color={snap.structure.color}
        />
      </group>
    );
  }

  const blade_list = [];
  const blade_count = parseInt((height - delta) / 155);
  const last_height = height - delta - blade_count * 155 + 50;

  for (var i = 0; i <= blade_count; i = i + 1) {
    if (i < blade_count) {
      blade_list.push(
        <group key={i} position={[0, 155 * 0.001 * i, 0]}>
          <mesh
            geometry={nodes.Box005.geometry}
            material={
              props.isTexture ? material_texture : materials["03 - Default"]
            }
            position={[-0.01, 0.28, 0.01]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[0.00101 * sw, 0.001, 0.001]}
            material-color={snap.structure.color}
          />
        </group>
      );
    } else {
      blade_list.push(
        <group
          key={i}
          position={[0, 155 * 0.001 * i + ((last_height - 155) * 0.001) / 2, 0]}
        >
          <mesh
            geometry={nodes.Box005.geometry}
            material={
              props.isTexture ? material_texture : materials["03 - Default"]
            }
            position={[-0.01, 0.28, 0.01]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[0.00101 * sw, 0.001, (0.001 * last_height) / 155]}
            material-color={snap.structure.color}
          />
        </group>
      );
    }
  }

  return (
    <group
      rotation={props.rotation}
      scale={props.scale * 0.1}
      position={[px, props.modelID == 18 ? pz : 0, py]}
      onPointerOver={(e) => {
        document.body.style.cursor = "pointer";
      }}
      onPointerOut={(e) => {
        document.body.style.cursor = "auto";
      }}
      onClick={(e) => {
        e.stopPropagation();
        state.editingSubSystem = true;
        state.editingSubSystemID = props.ID;
        state.editingSystemType = props.systemType;
        state.editingGlassCount = glassCount;
      }}
    >
      <group
        position={[
          snap.isModel ? deltas[props.side] : 0,
          width / 2 + (snap.isModel ? deltas2[props.side] : 0),
          -140,
        ]}
        rotation={[Math.PI / 2, Math.PI / 2, 0]}
        scale={1000}
      >
        <mesh
          geometry={nodes["top-60"].geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[0, 2.62 + dh * 0.001, 0.01]}
          scale={[0.001 * sw2, 0.001, 0.001]}
          material-color={snap.structure.color}
        />
        <mesh
          geometry={nodes["bottom-60"].geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[0, 0.17, 0.01]}
          scale={[0.001 * sw2, 0.001, 0.001]}
          material-color={snap.structure.color}
        />
        <mesh
          geometry={nodes["right-60"].geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[1.73 + (dw * 0.001) / 2, 1.39 + (dh * 0.001) / 2, 0.01]}
          rotation={[0, 0, Math.PI / 2]}
          scale={[0.001 * sh2, 0.001, 0.001]}
          material-color={snap.structure.color}
        />
        <mesh
          geometry={nodes["left-60"].geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[-1.74 - (dw * 0.001) / 2, 1.39 + (dh * 0.001) / 2, 0.01]}
          rotation={[0, 0, Math.PI / 2]}
          scale={[0.001 * sh2, 0.001, 0.001]}
          material-color={snap.structure.color}
        />
        {list}
        {blade_list}
        {/* <mesh geometry={nodes.Box005.geometry} material={materials['03 - Default']} position={[-0.01, 0.28, 0.01]} rotation={[-Math.PI / 2, 0, 0]}  scale={[0.00101 * sw, 0.001, 0.001]} /> */}
      </group>
    </group>
  );
}
