import axios from 'axios';

class StorageService {
  get(key) {
    const value = localStorage.getItem(key);
    if (!value && key === 'lang') {
      return 'EN';
    }
    return value;
  }

  set(key, value) {
    localStorage.setItem(key, value);
  }
}

export default new StorageService();