import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { Divider } from "@mui/material";
import axios from 'axios';
import AuthService from "services/auth.service";
import { useScreen } from 'hooks'
import state from "state";
import { Opeartion } from "components/Drawer";
import { settingList, settingListWithAdmin, navigateCases } from "constant";
import { API_URL, axiosInstanceAuthorized, logourl, ROLE_TYPES, SERVER_URL } from "utils/constant";
import AppContext from 'context/AppContext';
import CircularProgress from '@mui/material/CircularProgress';
import { toastError } from 'common/Toast';
import { useTranslation } from "react-i18next";

const avatarColor = (str, s, l) => {
  const hash = Array.from(str).reduce(
    (acc, char) => char.charCodeAt(0) + ((acc << 5) - acc),
    0
  );
  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
};

export default function AccountMenu() {

  const { mode } = React.useContext(AppContext);
  const navigate = useNavigate();
  const isMobile = useScreen()
  const [drawer, setDrawer] = React.useState(false);
  const [db_loading, setDbLoading] = React.useState(false)
  const { t, i18n } = useTranslation();

  const user = JSON.parse(
    localStorage.getItem("user") ?? localStorage.getItem("embed_user")
  )?.user;
  const isAdmin = user?.role === ROLE_TYPES.ADMIN ?? false;

  const logout = () => {
    AuthService.logout();
    window.location.href = "/login";
  };

  const handleClick = () => setDrawer(true);

  const handleShow = (label, i) => {
    state.isModel = false;
    if (i === 7) {
      setDbLoading(true)
      axiosInstanceAuthorized.get(API_URL + 'auth/download-db', { responseType: 'blob' })
        .then((response) => {
          // const contentDisposition = response.headers['content-disposition'];
          // const fileNameMatch = contentDisposition && contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
          // const fileName = fileNameMatch && fileNameMatch[1];

          const currentDate = new Date();

          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, '0');
          const day = String(currentDate.getDate()).padStart(2, '0');
          const hour = String(currentDate.getHours()).padStart(2, '0');
          const minute = String(currentDate.getMinutes()).padStart(2, '0');

          const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', `patioschildr_db-${year}${month}${day}${hour}${minute}.sql`);
          document.body.appendChild(link);
          link.click();
          setDbLoading(false)
        })
        .catch((error) => {
          toastError(error.message);
          setDbLoading(false)
        });
    } else {
      var a = document.createElement("a");
      a.href =
        "/allproducts/" + JSON.parse(localStorage.getItem("user")).user.embed_key + "/" + i18n.language;
      a.target = "_blank";

      label === "AllProducts" ? a.click() : navigate(navigateCases[label]);
    }

  };

  const brand_color = user === null ? null : user.brand_color;
  const company_logo = user.logo_option === 1 || user.logo_option === '1' ? (logourl) : (SERVER_URL + user.company_logo);

  return (
    user !== null && (
      <div>
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Opeartion
            open={drawer}
            handler={setDrawer}
            side="right"
            width={isMobile ? 20 : 60}
          >
            <div style={{ marginTop: "20px" }}>
              <img src={company_logo} style={{ width: '150px', marginLeft: '25px', marginBottom: '20px' }} />
              {settingList.map((item, index) => {
                return user.role !== 'Employee' || (item.title !== 'Sections' && item.title !== 'Page' && item.title !== 'Employees') ? (
                  <MenuItem
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      handleShow(item.route);
                    }}
                    sx={{ fontSize: '20px' }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    {t(item.title)}
                  </MenuItem>
                ) : null;
              })}
              <Divider />
              {isAdmin &&
                settingListWithAdmin.map((item, index) => {
                  return (
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        handleShow(item.route, item.id);
                      }}
                      key={index}
                      sx={{ fontSize: '20px' }}
                    >
                      {(db_loading && item.id === 7) ?
                        <CircularProgress size={18} sx={{ mr: 2 }} className="circular_loader2" />
                        : <ListItemIcon>{item.icon}</ListItemIcon>}
                      {t(item.title)}
                    </MenuItem>
                  );
                })}

              <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
                sx={{ fontSize: '20px' }}
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                {t('Logout')}
              </MenuItem>
            </div>
          </Opeartion>

          <Tooltip title="Account settings">
            <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  backgroundColor:
                    brand_color ?? avatarColor(user.first_name, 30, 50),
                }}
              >
                {user.first_name[0]} {user.last_name[0]}
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    )
  );
}
