import React, { useContext, useState, useEffect } from "react";
import { useSnapshot } from "valtio";

import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import Typography from '@mui/material/Typography';
import { useMediaQuery } from "@mui/material";

import Popover from '@mui/material/Popover';

import state from "../../state";
import AppContext from '../../context/AppContext';
import { topStyles, default_forte_pos, API_URL, SERVER_URL, axiosInstanceAuthorized } from "../../utils/constant";
import { BsPlusCircleFill } from "react-icons/bs";
import axios from "axios";

const TopInfills = () => {
  const snap = useSnapshot(state);
  const { modelID } = useContext(AppContext);
  const adding = snap.columns.addingSubSystem;
  const isMobile = useMediaQuery('(max-width:769px)')
  const [products, setProducts] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [body, setBody] = useState('');

  const getProducts = () => {
    axiosInstanceAuthorized
      .get(API_URL + "product/get")
      .then(response => {
        setProducts(response.data.products);
        return response.data.products;
      })
      .catch(err => {
        console.log(err);
      })

  }


  const handleClick = (event, bodytext) => {
    setAnchorEl(event.currentTarget);
    setBody(bodytext);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const selected = (selected_title, systemID) => {
    topStyles[modelID].forEach((item, index) => {
      if (item.title === selected_title) {
        state.systemID = systemID;
        state.columns.addedSubSystem[20] = true;
        state.columns.systemPos[20] = default_forte_pos;
        state.columns.systemPos[21] = default_forte_pos;
        document.getElementById(item.title).style.opacity = 1.0;
        document.getElementById(item.title).style.border = "5px solid #31d84a";
      } else {
        document.getElementById(item.title).style.border = "0px";
        document.getElementById(item.title).style.opacity = 0.7;
      }
    });
  };

  useEffect(()=> {
    getProducts();
  }, [])


  return products !== null ?(
    <Box 
      className={!isMobile ? 'overview' : ''}
      sx={{ p: 0, pr: { md: 2, xs: 0 }, mb: { md: 0, xs: 2.5 } }}
    >
      <Typography sx={{ fontSize: 15, fontWeight: 500, mb: 1.25 }}>Add new</Typography>
      <ImageList variant="woven" cols={2} gap={15}>
        {topStyles[modelID].map((item, idx) => (
          <label htmlFor={item.title} key={idx} className="add-label">
            <img
              src={SERVER_URL + products.filter(product=>product.link === item.url)[0].image}
              alt={item.title}
              id={item.title}
              className="styleImage"
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                selected(item.title, item.systemID);
              }}
            />
            {item.title}
            <div className="moreInfo"  aria-describedby={id} onClick={e=>{handleClick(e, products.filter(product=>product.link === item.url)[0].body)}}>
              <u>More Info</u>
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{ width: '400px' }}
            >
              <Typography sx={{ p: 2 }}>{body}</Typography>
            </Popover>

            {adding === false && snap.columns.addedSubSystem[20] === false ?
              <BsPlusCircleFill className="add-new"
                onClick={e => {
                  e.preventDefault();
                  selected(item.title, item.systemID);
                }}
              /> : null}
          </label>
        ))}
      </ImageList>
    </Box>
  ):null;
}

export default TopInfills;