import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
const xrepeat = 0.05,
  yrepeat = 0.05;
export default function MiddleProfile2(props) {
  const { color, isTexture, nodes, materials } = props;
  const { width, depth, height1 } = props.length;
  const {
    int_width,
    int_depth,
    top_div_length,
    unit,
    mid_length_limit,
    column_thickness,
    int_div_width,
  } = props.int_length;

  const dw = width - int_width;
  const dd = depth - int_depth;
  const texture = useTexture(props.texture);
  texture.rotation = Math.PI;
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  if (props.doubleSystem) {
    if (props.origin && (!props.systemVisible[0] || !props.systemVisible[1]))
      return null;
    if (!props.origin && (!props.systemVisible[2] || !props.systemVisible[3]))
      return null;
  }

  const sd = depth / top_div_length;
  var bias = 0;
  // if (props.doubleSystem) {
  //   if (props.origin) bias = column_thickness * unit / 4
  //   else bias = -column_thickness * unit / 4
  // }

  let scale = [0.1, 0.1, 0.1 * sd * 0.48];
  let position = [
    (width * unit) / 2 - (dw * unit) / 2,
    (height1 - 150) * unit,
    (-depth * unit) / 2 + dd * unit,
  ];

  let position1 = [
    (width * unit) / 3 - (dw * unit) / 2,
    (height1 - 150) * unit,
    (-depth * unit) / 2 + dd * unit,
  ];

  let position2 = [
    (width * unit * 2) / 3 - (dw * unit) / 2 + (column_thickness / 4) * unit,
    (height1 - 150) * unit,
    (-depth * unit) / 2 + dd * unit,
  ];

  if (mid_length_limit[1] < width) {
    return (
      <>
        <group dispose={null} position={position1}>
          <group rotation={[0, 0, 0]} scale={scale}>
            <mesh
              castShadow
              geometry={nodes.middleseperator2.geometry}
              material={isTexture ? material_texture : materials.profile}
              material-color={color}
              // position={[-35, 25, 10 - bias]}
              position={[-100, 25, 10 - bias]}
            />
          </group>
        </group>
        <group dispose={null} position={position2}>
          <group rotation={[0, 0, 0]} scale={scale}>
            <mesh
              castShadow
              geometry={nodes.middleseperator2.geometry}
              material={isTexture ? material_texture : materials.profile}
              material-color={color}
              // position={[-35, 25, 10 - bias]}
              position={[-100, 25, 10 - bias]}
            />
          </group>
        </group>
      </>
    );
  }
  if (mid_length_limit[0] < width) {
    return (
      <group dispose={null} position={position}>
        <group rotation={[0, 0, 0]} scale={scale}>
          <mesh
            castShadow
            geometry={nodes.middleseperator2.geometry}
            material={isTexture ? material_texture : materials.profile}
            material-color={color}
            // position={[-35, 25, 10 - bias]}
            position={[-100, 25, 10 - bias]}
          />
        </group>
      </group>
    );
  } else {
    return null;
  }
}

// useGLTF.preload('models/divider_panora_cabbana.glb')
