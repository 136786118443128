import React, {useEffect} from 'react'
import { useGLTF, useProgress } from '@react-three/drei'
import { useSnapshot } from "valtio"
import { lengths, columnFlag, default_forte_pos } from '../../utils/constant';
import state from "../../state";
import { secondHeight } from '../../utils/constant';
import { Html } from "@react-three/drei";
import { BsPlusCircle } from "react-icons/bs";
import { TriPart } from '../urban/TriPart';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber';
import {
  WaterProfile, BackProfile,
  ColumnLeft, ColumnRight, Columns,
  Glass, TopDiv, CapLeft, CapRight, BackLeft, BackRight,
  FootLeft, FootRight, Foots, Lights, LightsForUrban,
  Side, AddExtraColumns, AddWalls,
  LengthInfo, ColumnLabels, Walls, asciiDif
} from '../point/Point';
import SideInfills2 from '../SideInfills2';
import TopInfills2 from '../TopInfills2';
import pointModel from '../../assets/models/point.glb';
import ProgressBar from '../../common/Loading/ProgressBar';

export function AddSubSystems(props) {
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;
  const { columns } = props;

  const list = [];
  const pos_list = [];

  for (let i = 2; i < 24; i++) {
    if (columns.added[i]) {
      pos_list.push({ pos: columns.pos[i], id: i });
    }
  }
  pos_list.push({ pos: columns.pos[0], id: 0 });
  pos_list.push({ pos: width, id: 0 })
  pos_list.sort(function (a, b) { return a.pos - b.pos });

  for (let i = 0; i < pos_list.length - 1; i = i + 1) {
    const swidth = pos_list[i + 1].pos - pos_list[i].pos;
    const px = pos_list[i].pos;
    const ID = pos_list[i].id;
    list.push(
      <Html key={i} scaleFactor={5} position={[width * unit / 2 - pos_list[i].pos * unit - swidth * unit / 2, -oy * unit, -oz * unit]}>
        <div
          className="side-info side-info-subsystem"
          onPointerOver={(e) => {
            e.preventDefault();
            props.setSelectedSide(1);
            props.setCurrentWidth(swidth);
            props.setCurrentPos(px + swidth / 2);
          }}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            // e.preventDefault();
            props.setSelectedSide(-1);
            if (props.systemID === 6 && columns.addedSubSystem[ID + 2] === true 
              && (columns.systemId[ID + 2] <= 3 || columns.systemId[ID + 2] === 5)) {
              state.columns.addedForte[ID + 2] = true;
              state.columns.fortePos[ID + 2] = default_forte_pos;
            }
            else {
              state.columns.addedSubSystem[ID + 2] = true;
              state.columns.systemId[ID + 2] = props.systemID;
              if (props.systemID === 0) {
                state.columns.systemId[ID + 2 + 8] = props.systemID;
              }
            }
            state.columns.addingSubSystem = false;
          }}
        >
          <BsPlusCircle />
        </div>
      </Html>

    )
  }

  return (props.activeStep === 2 && columns.addingSubSystem === true) ? (
    <group>
      {/* <Html scaleFactor={5} position={[0, -oy * unit, -oz * unit]}>
        <div
          className="side-info"
          onPointerOver={(e) => {
            props.setSelectedSide(1);
          }}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            props.setSelectedSide(-1);
            state.walls.added[0] = true;
            state.walls.adding = false;
          }}
        >
          Front Side<BsPlusCircle />
        </div>
      </Html> */}
      <Html scaleFactor={5} position={[-width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
        <div
          className="side-info"
          onPointerOver={(e) => { props.setSelectedSide(2) }}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            e.preventDefault();
            props.setSelectedSide(-1);
            if (props.systemID === 6 && columns.addedSubSystem[1] === true && (columns.systemId[1] <= 3 || columns.systemId[1] === 5)) {
              state.columns.addedForte[1] = true;
              state.columns.fortePos[1] = default_forte_pos;
            }
            else {
              state.columns.addedSubSystem[1] = true
              state.columns.systemId[1] = props.systemID;
              if (props.systemID === 0) {
                state.columns.systemId[1 + 8] = props.systemID;
              }
            }
            state.columns.addingSubSystem = false;
          }}
        >
          Left Side<BsPlusCircle />
        </div>
      </Html>
      {/* <Html scaleFactor={5} position={[0, (depth-oy) * unit, -oz * unit]}>
        <div
          className="side-info"
          onPointerOver={(e) => {props.setSelectedSide(3)}}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            props.setSelectedSide(-1);
            state.walls.added[2] = true;
            state.walls.adding = false;
          }}
        >Back Side<BsPlusCircle />
        </div>
      </Html> */}
      <Html scaleFactor={5} position={[width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
        <div
          className="side-info"
          onPointerOver={(e) => { props.setSelectedSide(4) }}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            e.preventDefault();
            props.setSelectedSide(-1);
            if (props.systemID === 6 && columns.addedSubSystem[0] === true && (columns.systemId[0] <= 3 || columns.systemId[0] === 5)) {
              state.columns.addedForte[0] = true;
              state.columns.fortePos[0] = default_forte_pos;
            }
            else {
              state.columns.addedSubSystem[0] = true
              state.columns.systemId[0] = props.systemID;
              if (props.systemID === 0) {
                state.columns.systemId[0 + 8] = props.systemID;
              }
            }
            state.columns.addingSubSystem = false;
          }}
        >Right Side<BsPlusCircle />
        </div>
      </Html>
      {list}
    </group>
  ) : null;

}

export function SideForSubSystem(props) {
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz } = props.int_length;
  const { currentPos, currentWidth } = props;
  if (props.selectedSide === 0) return null;
  switch (props.selectedSide) {
    case 1:
      return (
        <mesh
          position={[width * unit / 2 - currentPos * unit, -oy * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[currentWidth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 2:
      return (
        <mesh
          position={[-width * unit / 2, (depth / 2 - oy) * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 3:
      return (
        <mesh
          position={[0, (depth - oy) * unit, (height2 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[width * unit, height2 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 4:
      return (
        <mesh
          position={[width * unit / 2, (depth / 2 - oy) * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    default:
      return null;
  }
}


export function UrbanGlass(props) {
  const modelID = props.modelID;
  const gltf = useLoader(GLTFLoader, pointModel, loader => {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/draco-gltf/')
    loader.setDRACOLoader(dracoLoader)
   })
  // const onProgress = (xhr) => {
  //   if (xhr.lengthComputable) {
  //     const newProgress = (xhr.loaded / xhr.total) * 100;
  //     props.setProgress(Math.round(newProgress));
  //   }
  // };

  // const gltf = useLoader(GLTFLoader, pointModel, onProgress);
  // const { progress } = useProgress();
  // const [loaded, setLoaded] = React.useState(false);

  // useEffect(()=>{
  //   setLoaded(true);
  // }, [])

  const { nodes, materials } = gltf;
  // const { nodes, materials } = useGLTF(pointModel)
  const snap = useSnapshot(state);
  const [currentSide, setCurrentSide] = React.useState(0);
  const [currentPos, setCurrentPos] = React.useState(-1);
  const [selectedSide, setSelectedSide] = React.useState(0);
  const [currentWidth, setCurrentWidth] = React.useState(0);

  const ShiftColumn = (column) => {
    state.columns.editing = true;
    state.columns.editingColumn = column;
    state.columns.added[asciiDif(column, 'A')] = true;
    state.columns.adding = false;
    setCurrentSide(0);
  }

  // return !loaded?
  //   <ProgressBar value={progress} text={'Loading Model...'} />:
  return (
    <group {...props} dispose={null} ref={props.group}>
      <group position={[0, 13, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={1}>

        <WaterProfile
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.textureD}
        />
        <BackProfile
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.textureD}
        />
        <ColumnLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <ColumnRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <Columns
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          flag={snap.flag}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <Glass
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.blades.color}
          isDesign={snap.isDesign}
          isTexture={snap.isBladeTexture}
          texture={snap.blades.texture}
          opacity={snap.blades.opacity}
          modelID={modelID}
          addedSubSystem={snap.columns.addedSubSystem}
          deletedSubSystem={snap.columns.deletedSubSystem}
          subSystemCount={snap.columns.subSystemCount}
        />
        <TopDiv
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          modelID={modelID}
          addedSubSystem={snap.columns.addedSubSystem}
          deletedSubSystem={snap.columns.deletedSubSystem}
          subSystemCount={snap.columns.subSystemCount}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <CapLeft
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <CapRight
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <BackLeft
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <BackRight
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <FootLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <FootRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <Foots
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        {/* <Lights
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          visible={snap.hasLight}
        /> */}

        <LightsForUrban
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          visible={snap.hasLight}
          modelID={modelID}
          addedSubSystem={snap.columns.addedSubSystem}
          deletedSubSystem={snap.columns.deletedSubSystem}
          subSystemCount={snap.columns.subSystemCount}
        />

        <ColumnLabels
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
        />
        {/* <LengthInfo
          secondHeight={secondHeight[modelID]}
          length={snap.length}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
        /> */}
        <AddExtraColumns
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          ShiftColumn={ShiftColumn}
          setCurrentSide={setCurrentSide}
        />
        <AddWalls
          walls={snap.walls}
          length={snap.length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setCurrentSide={setCurrentSide}
        />
        <Walls
          walls={snap.walls}
          length={snap.length}
          int_length={lengths[modelID]}
        />
        <Side
          walls={snap.walls}
          columns={snap.columns}
          length={snap.length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          current_side={currentSide}
        />
        <TriPart
          length={snap.length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isDesign={snap.isDesign}
          isTexture={snap.isTexture}
          texture={snap.structure.textureB}
          opacity={snap.blades.opacity}
          modelID={modelID}
          addedSubSystem={snap.columns.addedSubSystem}
          walls={snap.walls}
        />
        <AddSubSystems
          walls={snap.walls}
          columns={snap.columns}
          length={snap.length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setSelectedSide={setSelectedSide}
          setCurrentPos={setCurrentPos}
          setCurrentWidth={setCurrentWidth}
          systemID={snap.systemID}
        />
        <SideForSubSystem
          walls={snap.walls}
          columns={snap.columns}
          length={snap.length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          selectedSide={selectedSide}
          currentPos={currentPos}
          currentWidth={currentWidth}
        />
        <SideInfills2
          length={snap.length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          systemID={snap.systemID}
          dy={30}
          dz = {70}
          modelID={modelID}
          isTexture={snap.isTexture}
          texture={snap.structure.textureC}
          walls={snap.walls}
        />
        <TopInfills2
          length={snap.length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          systemID={9}
          modelID={modelID}
          addedSubSystem={snap.columns.addedSubSystem}
          deletedSubSystem={snap.columns.deletedSubSystem}
          px={0}
          py={-17000}
          pz={2850}
          alpha={0}
          dr={1}
          isTexture={snap.isTexture}
          texture={snap.structure.textureA}
        />
      </group>
    </group>
  )
}

useGLTF.preload('models/point.glb')
