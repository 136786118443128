/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import * as THREE from 'three'
import triangle from '../../assets/models/triangleNEW.glb';
// import { GlassCount } from '../DivCount';
// import { subsystems } from '../../utils/constant';

const xrepeat = 0.09,
  yrepeat = 0.03;

export function TriPart(props) {
  const { nodes, materials } = useGLTF(triangle);
  const color = props.color;
  const { width, height1, height2, depth } = props.length;
  const { int_depth, int_height2, int_height1, unit, column_depth, oz, oy } = props.int_length;
  const sd = (depth + 55) / (3000 - 120);
  const sd2 = (depth - 50) / 2880;
  const sh = (height2 - height1 - 260) / 514;
 
  const material = new THREE.MeshPhongMaterial({
    color: '#a7c7cb',
    opacity: 0.3,
    transparent: true,
  });
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  // const div_count_left = GlassCount(depth - column_depth, props.columns.systemId[0], props.modelID);
  // const div_count_right = GlassCount(depth - column_depth, props.columns.systemId[1], props.modelID);

  const div_count_left = 4;
  const div_count_right = 4;

  var tri_pos_left = 0, tri_pos_right = 0;
  if (div_count_left < 6) tri_pos_left = (depth - column_depth) * (div_count_left - 1) / div_count_left;
  else tri_pos_left = (depth - column_depth) * (div_count_left - 2) / div_count_left;
  if (div_count_right < 6) tri_pos_right = (depth - column_depth) * (div_count_right - 1) / div_count_right;
  else tri_pos_right = (depth - column_depth) * (div_count_right - 2) / div_count_right;

  return (
    <group {...props} dispose={null}
      rotation={[0, 0, Math.PI]}
    >
    {props.addedSubSystem[0] && props.walls.added[1] === false?
    <>
      <group scale={[unit, unit * sd, unit]} position={[width * unit / 2 - 45 * unit, -oy, -oz * unit + height1 * unit + 178]}>
        <group scale={[1, 1, sh]} position={[0, 10, -50 * sh]}>
          <mesh geometry={nodes.glass.geometry} material={material} position={[0, -30, 0]} scale={10.05} />
          <mesh geometry={nodes.triangleprofile.geometry} material={props.isTexture?material_texture:materials.profile} position={[0, 0, 0]} material-color={color} scale={[10 * 45 / 39, 10, 10]} />
        </group>
        {/* <mesh geometry={nodes.triangle_bottom.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, -0.15, 1.4]} scale={[10, 10, 10]} /> */}
      </group>
      <group scale={[unit, unit * sd2, unit]} position={[width * unit / 2 - 45 * unit, -oy + 100, -oz * unit + height1 * unit + 178]}>
        <mesh geometry={nodes.triangle_bottom.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, -0.15, 1.4]} scale={[10, 10, 18]} />
      </group>
      <group scale={[unit, unit, unit]} position={[width * unit / 2 - 45 * unit, -oy + 120, -oz * unit + height1 * unit + 178]}>
        <group scale={[1, 1, sh]} position={[0, 10, -70 * sh]}>
          {depth > 3300 ? <mesh geometry={nodes.extra_profile.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, tri_pos_left, 286]} scale={[10 * 45 / 39, 10, 10]} /> : null}
        </group>
      </group>
      </>:null}
      {props.addedSubSystem[1] && props.walls.added[3] === false?
      <>
      <group scale={[unit, unit * sd, unit]} position={[-width * unit / 2 + 45 * unit, -oy, -oz * unit + height1 * unit + 178]}>
        <group scale={[1, 1, sh]} position={[0, 10, -50 * sh]}>
          <mesh geometry={nodes.glass.geometry} material={material} position={[0, -30, 0]} scale={[-10.05, 10.05, 10.05]} />
          <mesh geometry={nodes.triangleprofile.geometry} material={props.isTexture?material_texture:materials.profile} position={[0, 0, 0]} material-color={color} scale={[10 * 45 / 39, 10, 10]} />
        </group>
        {/* <mesh geometry={nodes.triangle_bottom.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, -0.15, 1.4]} scale={[10, 10, 10]} /> */}
      </group>
      <group scale={[unit, unit * sd2, unit]} position={[-width * unit / 2 + 45 * unit, -oy + 100, -oz * unit + height1 * unit + 178]}>
        <mesh geometry={nodes.triangle_bottom.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, -0.15, 1.4]} scale={[10, 10, 18]} />
      </group>
      <group scale={[unit, unit, unit]} position={[-width * unit / 2 + 45 * unit, -oy + 120, -oz * unit + height1 * unit + 178]}>
        <group scale={[1, 1, sh]} position={[0, 10, -70 * sh]}>
          {depth > 3300 ? <mesh geometry={nodes.extra_profile.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, tri_pos_right, 286]} scale={[10 * 45 / 39, 10, 10]} /> : null}
        </group>
      </group>
    </>:null}
    </group>
  )
}
