import React from "react";

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
// import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter';
import UserMenu from './UserMenu';
import { ReactComponent as Menu } from 'assets/img/svg/menu.svg'
import Basket from './Basket';
import Cart from './Cart';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { useContext } from "react";
import AppContext from "context/AppContext";
import Logout from "@mui/icons-material/Logout";
import AuthServiceEmbed from "services/auth.embed.service";

// const handleExport = (group, modelID) => {
//   const exporter = new OBJExporter();
//   const result = exporter.parse(group.current);
//   saveString(result, title[modelID] + ".obj");
// };

const link = document.createElement('a');
link.style.display = 'none';
document.body.appendChild(link);

// const save = (blob, filename) => {
//   link.href = URL.createObjectURL(blob);
//   link.download = filename;
//   link.click();
// }

// const saveString = (text, filename) => {
//   save(new Blob([text], { type: 'text/plain' }), filename);
// }


const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const logout = (embed_key) => {
  AuthServiceEmbed.logout();
  window.location.href = "/embed/login/"+embed_key;
};


const TopToolbar = (props) => {
  const {sidebar, setSidebar, mode, showprice} = useContext(AppContext);
  const user = mode === 0 ? JSON.parse(localStorage.getItem('user')).user : JSON.parse(localStorage.getItem('embed_user'));

  return (
    <Stack direction="row" alignItems="center" sx={{ position: 'fixed', top: { md: 40, xs: 20 }, right: { md: 40, xs: 15 }, zIndex: 99, '& svg': { cursor: 'pointer', mr: { md: 3, xs: 2 } } }}>
      {/* <BootstrapTooltip title="Download" className="tool-tip">
        <DownloadIcon onClick={() => handleExport(props.group, props.modelID)} />
      </BootstrapTooltip>
      <BootstrapTooltip title="Share">
        <ShareIcon />
      </BootstrapTooltip>
      <BootstrapTooltip title="Send to dealer">
        <SendIcon color="info"/>
      </BootstrapTooltip> */}
      {mode === 0 || (mode === 1 && showprice === 1)?<BootstrapTooltip title="My orders">
        <>
        {/* <Basket /> */}
        <Cart />
        </>
      </BootstrapTooltip>:null}
      <Stack direction='row' >
        {mode === 0 ? <UserMenu />:<Typography sx={{ mr: 2, fontSize: 15, ml: { md: 3, xs: 0 } }} color="white">{mode === 1 ? null:null}</Typography>}
        {mode === 1 ? 
          <Tooltip title="Send To Friend"><ForwardToInboxIcon sx={{color: 'white'}} onClick={e=>{e.preventDefault(); props.setOpen(true)}}/></Tooltip>
        :null}
      </Stack>
      {mode < 2 ?
      <Menu
          className="menu-icon"
          onClick={e=>{
            e.preventDefault();
            setSidebar(!sidebar)}}
      />:null}
      {mode === 1 && (user.embedlogin === '1' || user.embedlogin === 1)?
          <Tooltip title="Logout"><Logout sx={{color: 'white'}} onClick={e=>{e.preventDefault(); logout(user.embed_key)}}/></Tooltip>
          :null
      }

      <br />
    </Stack>
  )
}

export default TopToolbar;