import React, { useEffect } from 'react'
import { useGLTF, useTexture, useProgress } from '@react-three/drei'
import { useSnapshot } from "valtio"
import { lengths, columnFlag, } from '../../utils/constant';
import state from "../../state"
import * as THREE from 'three'
import { secondHeight } from '../../utils/constant';
import { TriPart } from './TriPart';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber';

import avantgardeModel from '../../assets/models/avantgarde.glb'
import elegantModel from '../../assets/models/elegant.glb'
import SideInfills4 from '../SideInfills4';
import {
  WaterProfile,
  Top, Side, AddExtraColumns,
  AddWalls, LengthInfo, ColumnLabels, Walls, asciiDif, AddSubSystems,
  SideForSubSystem,
} from '../pergolaavantgarde/PergolaAvantgarde';
import ProgressBar from '../../common/Loading/ProgressBar';

const xrepeat = 1;
const yrepeat = 1;


export function ColumnLeft(props) {
  const { nodes, materials } = useGLTF(elegantModel);
  const { color, columns } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const sh1 = (height1 + 20) / int_height1;
  const scale = [1, 1, 1.034 * sh1]
  const scale2 = [1, 1, 1.034 * (height1 + 150) / int_height1];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  return (
    <>
      <group
        position={[dw * unit / 2 - columns.pos[1] * unit, 0, 0]}
      >
        <mesh castShadow material-color={color} geometry={nodes.leftcolumn1.geometry} material={props.isTexture ? material_texture : materials.profile} position={[3070.33, 2948.7, -1983.42]} scale={scale} />
      </group>
      {columns.addedSubSystem[1] && depth > 6000 ?
        <group
          position={[dw * unit / 2 - columns.pos[1] * unit, -(depth) / 2 * unit, 0]}
        >
          <mesh castShadow material-color={color} geometry={nodes.leftcolumn1.geometry} material={props.isTexture ? material_texture : materials.profile} position={[3070.33, 2948.7, -1983.42]} scale={scale2} />
        </group>
        : null}
    </>
  )

}

export function ColumnRight(props) {
  const { nodes, materials } = useGLTF(elegantModel);
  const { color, columns } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const sh1 = (height1 + 20) / int_height1;
  const scale = [1, 1, 1.034 * sh1]
  const scale2 = [1, 1, 1.034 * (height1 + 150) / int_height1];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  return (
    <>
      <group
        position={[-dw * unit / 2 + columns.pos[0] * unit, 0, 0]}
      >
        <mesh castShadow material-color={color} geometry={nodes.rightcolumn1.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-3090.16, 2948.7, -1983.42]} rotation={[0, 0, 0]} scale={scale} />
      </group>
      {columns.addedSubSystem[0] && depth > 6000 ?
        <group
          position={[-dw * unit / 2 + columns.pos[0] * unit, -(depth) / 2 * unit, 0]}
        >
          <mesh castShadow material-color={color} geometry={nodes.rightcolumn1.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-3090.16, 2948.7, -1983.42]} rotation={[0, 0, 0]} scale={scale2} />
        </group>
        : null}
    </>
  )

}

export function Columns(props) {
  const { nodes, materials } = useGLTF(elegantModel);
  const { color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit, column_width } = props.int_length;
  const dw = width - int_width;
  const sh1 = (height1 + 20) / int_height1;
  const scale = [1, 1, 1.034 * sh1]
  const px2 = 3070.33;
  const py = 2948.7;
  const pz = -1983.42;
  const list = [];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  for (var i = 2; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      list.push(
        <mesh key={i} castShadow material-color={color} geometry={nodes.leftcolumn1.geometry} material={props.isTexture ? material_texture : materials.profile} position={[dw * unit / 2 + px2 - columns.pos[i] * unit + 80 * unit, py, pz]} rotation={[0, 0, 0]} scale={scale} />
      )
    }
  }
  return (
    <group>
      {list}
    </group>
  )

}

export function Pergola(props) {
  const modelID = props.modelID;
  const gltf = useLoader(GLTFLoader, avantgardeModel, loader => {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/draco-gltf/')
    loader.setDRACOLoader(dracoLoader)
  })
  // const onProgress = (xhr) => {
  //   if (xhr.lengthComputable) {
  //     const newProgress = (xhr.loaded / xhr.total) * 100;
  //     props.setProgress(Math.round(newProgress));
  //   }
  // };

  // const gltf = useLoader(GLTFLoader, avantgardeModel, onProgress);
  // const { progress } = useProgress();
  // const [loaded, setLoaded] = React.useState(false);

  // useEffect(()=>{
  //   setLoaded(true);
  // }, [])

  const { nodes, materials } = gltf;
  // const { nodes, materials } = useGLTF(avantgardeModel)
  const snap = useSnapshot(state);
  const [currentSide, setCurrentSide] = React.useState(0);
  const [currentPos, setCurrentPos] = React.useState(-1);
  const [selectedSide, setSelectedSide] = React.useState(0);
  const [currentWidth, setCurrentWidth] = React.useState(0);

  const ShiftColumn = (column) => {
    state.columns.editing = true;
    state.columns.editingColumn = column;
    state.columns.added[asciiDif(column, 'A')] = true;
    state.columns.adding = false;
    setCurrentSide(0);
  }

  // return !loaded?
  //   <ProgressBar value={progress} text={'Loading Model...'} />:
  return (
    <group {...props} dispose={null} ref={props.group}>
      <group position={[0, 1.98, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={0.001}>
        <WaterProfile
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <ColumnLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <ColumnRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <Columns
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          flag={snap.flag}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <Top
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          glassColor={snap.isDesign ? snap.designStyle : snap.blades.color}
          coverColor={snap.isDesign ? snap.designStyle : snap.structure.color}
          isDesign={snap.isDesign}
          isBladeTexture={snap.isBladeTexture}
          bladeTexture={snap.blades.texture}
          isTexture={snap.isTexture}
          texture={snap.structure.textureA}
          texture2={snap.structure.textureB}
          texture3={snap.structure.textureR}
          opacity={snap.blades.opacity}
          modelID={modelID}
          addedSubSystem={snap.columns.addedSubSystem}
          subSystemCount={snap.columns.subSystemCount}
          systemPos={snap.columns.systemPos}
          visible={snap.hasLight}
          direction={snap.columns.systemDirection[23]}
          systemColor={snap.systemColor}
        />
        <ColumnLabels
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
        />
        {/* <LengthInfo
          secondHeight={secondHeight[modelID]}
          length={snap.length}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          modelID={modelID}
        /> */}
        <AddExtraColumns
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          ShiftColumn={ShiftColumn}
          setCurrentSide={setCurrentSide}
        />
        <AddWalls
          walls={snap.walls}
          length={snap.length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setCurrentSide={setCurrentSide}
        />

        <Walls
          walls={snap.walls}
          length={snap.length}
          int_length={lengths[modelID]}
        />

        <Side
          walls={snap.walls}
          columns={snap.columns}
          length={snap.length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          current_side={currentSide}
        />

        <AddSubSystems
          walls={snap.walls}
          columns={snap.columns}
          length={snap.length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setSelectedSide={setSelectedSide}
          setCurrentPos={setCurrentPos}
          setCurrentWidth={setCurrentWidth}
          systemID={snap.systemID}
        />
        <SideForSubSystem
          walls={snap.walls}
          columns={snap.columns}
          length={snap.length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          selectedSide={selectedSide}
          currentPos={currentPos}
          currentWidth={currentWidth}
        />
        <SideInfills4
          length={snap.length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          systemID={snap.systemID}
          dy={90}
          dz={0}
          modelID={modelID}
          isTexture={snap.isTexture}
          texture={snap.structure.textureB}
          walls={snap.walls}
        />
        {snap.columns.addedSubSystem[0] || snap.columns.addedSubSystem[1] ?
          <TriPart
            length={snap.length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
            isDesign={snap.isDesign}
            opacity={snap.blades.opacity}
            modelID={modelID}
            addedSubSystem={snap.columns.addedSubSystem}
            columns={snap.columns}
            isTexture={snap.isTexture}
            texture={snap.structure.textureC}
            walls={snap.walls}
          /> : null}

      </group>
    </group>
  )
}
// useGLTF.preload(dynamicModel)