/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import Fixed from '../assets/models/fixed_wall.glb';
import { subsystems } from '../utils/constant';
import * as THREE from 'three'
import { useSnapshot } from 'valtio';
import state from '../state';
import { FixedGlassCount } from '../models/DivCount';

const xrepeat = 0.1,
  yrepeat = 0.1;

export default function FixedGlass2(props) {
  const { nodes, materials } = useGLTF(Fixed)
  const { px, py, pz, width, height, systemID, unit } = props;
  const int_width = subsystems[systemID].width;
  const int_height = subsystems[systemID].height;
  const glassHeight = subsystems[systemID].glassHeight;
  const delta = int_height - glassHeight;
  const sh2 = height / int_height;
  const dh = height - int_height;
  const dw = width - int_width;
  const sw = (width - delta) / (int_width - delta);
  const sw2 = width / int_width;
  const snap = useSnapshot(state);


  var glassCount = Math.max(2, parseInt(width / subsystems[systemID].sliderWidth, 0));
  if (props.ID === 4 && snap.columns.addedSubSystem[5] === true) {
    glassCount = Math.max(2, parseInt((width - 50) / subsystems[systemID].sliderWidth, 0));
  }

  if (glassCount > 4 && glassCount % 2 && props.side % 2) glassCount = glassCount + 1;
  if (props.side % 2 === 0 && snap.columns.systemDirection[props.ID] == 2 && glassCount % 2 && glassCount > 3) glassCount = glassCount + 1;
  if (props.side % 2 === 0 && width === 3170) glassCount = 4;
  if (props.side % 2 && width >= 2200) glassCount = Math.max(3, glassCount);
  if (props.side % 2 && width <= 3800 && width > 2800 && snap.columns.addedSubSystem[5] === false) glassCount = 4;
  glassCount = FixedGlassCount(width, glassCount, props.modelID);
  state.systemSliderCount[props.ID] = glassCount;

  const texture = useTexture(snap.structure.textures[4]);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const div_width = (width - delta + 80) / glassCount;

  const list = [];
  for (var i = 0; i < glassCount - 1; i = i + 1) {
    list.push(
      <group position={[div_width * (i + 1) * 0.001, 0, 0]}>
        <mesh geometry={nodes['inner-80'].geometry} material={props.isTexture ? material_texture : materials.profile} position={[-1.74 - dw * 0.001 / 2, 1.39 + dh * 0.001 / 2, 0.01]} rotation={[0, 0, Math.PI / 2]} scale={[0.001 * sh2, 0.001, 0.001]}
          material-color={snap.structure.color}
        />
      </group>
    )
  }

  const blade_list = [];
  const blade_count = Math.round((height - delta) / 150);
  for (var i = 0; i < blade_count; i = i + 1) {
    blade_list.push(
      <group position={[0, 155 * 0.001 * i, 0]}>
        <mesh geometry={nodes.Box005.geometry} material={props.isTexture ? material_texture : materials['03 - Default']} position={[-0.01, 0.28, 0.01]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.00101 * sw, 0.001, 0.001]}
          material-color={snap.structure.color}
        />
      </group>
    )
  }

  state.subSystemWidth[props.ID] = width;
  state.subSystemHeight[props.ID] = height;

  var xbias = 0;
  var ybias = 0;
  var xdelta = 0;

  if (props.modelID === 3 || props.modelID === 5) {
    if (props.side % 2 === 0) ybias = 100 * unit;
    if (props.side % 2) ybias = 110 * unit;
    if (props.side % 2 === 0) xbias = 60 * unit * (props.side - 3)
  }
  if (props.modelID === 9 || props.modelID === 11 || props.modelID === 16 || props.modelID === 20) {
    if (props.side % 2) ybias = 0 * unit;
    if (props.side % 2 === 0) xbias = -90 * unit * (props.side - 3);
  }
  if (props.modelID === 6 || props.modelID === 10) {
    if (props.side % 2) ybias = -40 * unit;
    if (props.side % 2 === 0) xbias = -80 * unit * (props.side - 3);
  }

  if (props.modelID === 7) {
    if (props.side % 2) ybias = 80 * unit;
    if (props.side % 2 === 0) xbias = 80 * unit * (props.side - 3);
    xdelta = -10 * unit;
  }


  return (
    <group
      rotation={props.rotation}
      scale={props.scale}
      position={[px * unit + xbias + xdelta, py * unit + ybias, pz * unit]}
      onPointerOver={
        e => {
          document.body.style.cursor = 'pointer';
        }
      }
      onPointerOut={
        e => {
          document.body.style.cursor = 'auto';
        }
      }
      onClick={
        e => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID;
          state.editingSystemType = props.systemType;
          state.editingGlassCount = glassCount;
        }
      }

    >
      <group position={[0, -140, 0]} scale={1000}>
        <mesh geometry={nodes['top-60'].geometry} material={props.isTexture ? material_texture : materials.profile} position={[0, 2.62 + dh * 0.001, 0.01]} scale={[0.001 * sw2, 0.001, 0.001]}
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes['bottom-60'].geometry} material={props.isTexture ? material_texture : materials.profile} position={[0, 0.17, 0.01]} scale={[0.001 * sw2, 0.001, 0.001]}
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes['right-60'].geometry} material={props.isTexture ? material_texture : materials.profile} position={[1.74 + dw * 0.001 / 2, 1.39 + dh * 0.001 / 2, 0.01]} rotation={[0, 0, Math.PI / 2]} scale={[0.001 * sh2, 0.001, 0.001]}
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes['left-60'].geometry} material={props.isTexture ? material_texture : materials.profile} position={[-1.74 - dw * 0.001 / 2, 1.39 + dh * 0.001 / 2, 0.01]} rotation={[0, 0, Math.PI / 2]} scale={[0.001 * sh2, 0.001, 0.001]}
          material-color={snap.structure.color}
        />
        {list}
        {blade_list}
      </group>
      {/* {list} */}
    </group>
  )
}
