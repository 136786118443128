import axios from "axios";
import { API_URL, EMBED_USER_KEY, axiosInstancePublic } from '../utils/constant';
import authHeader, { setAuthHeader } from "./auth-header";

class AuthServiceEmbed {
  login(email, password, key) {
    const data = {
      email: email,
      password: password,
      embed_key: key,
    };
    return axiosInstancePublic
      .post(API_URL + "auth/embedsignin", data)
      .then(response => {
        if (response.data.success === true) {
          localStorage.setItem(EMBED_USER_KEY, JSON.stringify(response.data));
          setAuthHeader(authHeader(EMBED_USER_KEY)['Authorization']);
        }
        else {
          localStorage.removeItem(EMBED_USER_KEY);
        }
        return response.data.success;
      });
  }

  forgotPassword(email) {
    const data = {
      email: email,
    };
    return axiosInstancePublic
      .post(API_URL + "auth/forgotPasswordEmbed", data)
      .then(response => {
        return response.data;
      });
  }


  logout() {
    localStorage.removeItem(EMBED_USER_KEY);
  }

  getCurrentEmbedUser() {
    return JSON.parse(localStorage.getItem(EMBED_USER_KEY));;
  }
}

export default new AuthServiceEmbed();