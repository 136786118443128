/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import car_new from '../../assets/models/carnew.glb'

export default function CarBody(props) {
  const { nodes, materials } = useGLTF(car_new)
  const { width, depth, height1 } = props.length
  const { columns } = props
  const { int_width, int_depth, int_height1, mid_length_limit, unit, column_thickness } = props.int_length

  const dw = (width - int_width);
  const dw1 = width - 7200
  const dd = depth - int_depth
  const dh = height1 - int_height1

  const material = new THREE.MeshPhongMaterial({
    color: '#3f3f3f',
    opacity: 0.2,
    transparent: true,
  });

  const scale = 75

  const list = []

  if (width < mid_length_limit[0]) {
    list.push(
      <group key={11} position={[0, 0, dd * unit / 2]}>
        <mesh
          castShadow
          geometry={nodes.mesh_0.geometry}
          material={material}
          position={[198.65, 0, -150.59]}
          scale={scale}
          rotation={[Math.PI / 2, -Math.PI, 0]}
        />
      </group>
    )
  } else if (width > mid_length_limit[1]) {
    list.push(
      <group key={30} position={[(-dw1 * unit * 0.667) / 2, 0, dd * unit / 2]}>
        <mesh
          castShadow
          geometry={nodes.mesh_0.geometry}
          material={material}
          position={[-35.05, 0, -150.59]}
          scale={scale}
          rotation={[Math.PI / 2, -Math.PI, 0]}
        />
      </group>
    )
    list.push(
      <group key={31} position={[0, 0, dd * unit / 2]}>
        <mesh
          castShadow
          geometry={nodes.mesh_0.geometry}
          material={material}
          position={[195.95, 0, -150.59]}
          scale={scale}
          rotation={[Math.PI / 2, -Math.PI, 0]}
        />
      </group>)
    list.push(
      <group key={32} position={[(dw1 * unit * 0.667) / 2, 0, dd * unit / 2]}>
        <mesh
          castShadow
          geometry={nodes.mesh_0.geometry}
          material={material}
          position={[439.25, 0, -150.59]}
          scale={scale}
          rotation={[Math.PI / 2, -Math.PI, 0]}
        />
      </group>)
  } else {
    list.push(
      <group key={20} position={[(-dw * unit * 0.5) / 2, 0, dd * unit / 2]}>
        <mesh
          castShadow
          geometry={nodes.mesh_0.geometry}
          material={material}
          position={[101.65, 0, -150.59]}
          scale={scale}
          rotation={[Math.PI / 2, -Math.PI, 0]}
        />
      </group>
    )
    list.push(
      <group key={21} position={[(dw * unit * 0.5) / 2, 0, dd * unit / 2]}>
        <mesh
          castShadow
          geometry={nodes.mesh_0.geometry}
          material={material}
          position={[301.65, 0, -150.59]}
          scale={scale}
          rotation={[Math.PI / 2, -Math.PI, 0]}
        />
      </group>
    )
  }

  return (
    <>
      {list}
    </>

  )
}

// useGLTF.preload('models/cabbana_lights.glb')
