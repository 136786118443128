import React, { useState, useRef, useEffect } from 'react';
import { Box, Tooltip, IconButton, Typography, Popover, Button } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import NotificationDialog from 'pages/NotificationDialog';
import axios from 'axios';
import { API_URL, ROLE_TYPES, SERVER_URL, axiosInstanceAuthorized } from '../utils/constant';
import UpdateUser from 'common/UpdateUser';
import { Link } from 'react-router-dom'
import { ImageViewer } from './ImageViewer';
import { useNavigate } from 'react-router-dom'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export default function ProfileComplete(props) {
  const { userID } = props
  const [complete_status, setCompleteStatus] = useState(40)
  const navigate = useNavigate()

  const user = JSON.parse(localStorage.getItem('user')).user;

  const [open, setOpen] = React.useState(false);
  const [notifications, setNotifications] = useState([]);
  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState(null);

  const handleClick = (event) => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false)
    localStorage.setItem("profile_popup", false)
  }

  useEffect(() => {
    getStatus()

  }, [])

  const goSettings = () => {
    navigate('/settings', { replace: true });

  }

  useEffect(() => {
    // Update the body background when `open` value changes
    if (open) {
      document.body.style.backgroundColor = '#eeeeee'; // Set your desired background color
    } else {
      document.body.style.backgroundColor = ''; // Reset the background color
    }
  }, [open]);

  const getStatus = () => {
    if (user) {
      axiosInstanceAuthorized.post(API_URL + 'auth/profileStatus', { user_id: userID || user.id })
        .then((response) => {
          if (response.data.success) {
            const status = response.data.profileStatus
            var tot = 0;

            if (status.api_status == 0) tot = tot + 1
            if (status.brand_status == 0) tot = tot + 1
            if (status.calculation_status == 0) tot = tot + 1
            if (status.assigned_status == 0) tot = tot + 3

            const completness = parseInt((10 - tot) / 10 * 100);
            setCompleteStatus(completness)
            if (completness != 100 && !localStorage.hasOwnProperty("profile_popup")) {
              setOpen(true)
            }
          }
        })
        .catch(err => {
          console.log("profile status error==>", err.message);
        });
    }
  }

  return (
    <div>
      <Box sx={{ display: complete_status == 100 ? 'none' : 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip >
          <IconButton
            onClick={handleClick}
            size="medium"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <NotificationsIcon className="zoom-animation" />
          </IconButton>
        </Tooltip>

      </Box>
      {open ?
        <Box
        >

          <div className="popup-container">
            <div className="popup1"
              style={{
                right: user.role === ROLE_TYPES.ADMIN ? '240px' : '300px',
                top: '75px',
              }}
            >
              <div className="tooltip-arrow" />
              <div className='close-button'>
                <IconButton onClick={handleCloseDialog}>
                  <CloseIcon />
                </IconButton>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', padding: '16px 16px 0px 16px' }}>
                <div className='mb-3'>
                  <Typography variant="h6" className="mb-1">
                    Profile Completeness
                  </Typography>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div style={{ width: '85%' }}>
                      <BorderLinearProgress variant="determinate" value={complete_status} sx={{
                        '& .MuiLinearProgress-bar': {
                          background: complete_status == 100 ? '#22bb33' : complete_status > 40 ? '#f0ad4e' : '#bb2124'
                        }
                      }} />
                    </div>

                    <span style={{ fontSize: 18 }}>{complete_status}%</span>
                  </div>
                  <Box className='text-center mt-2'>
                    <Button
                      variant="contained"
                      onClick={goSettings}
                      sx={{
                        fontSize: 20,
                        textTransform: 'capitalize'
                      }}
                    >
                      Complete Profile
                    </Button>
                  </Box>

                </div>
              </div>
            </div>
          </div>
        </Box> : null}
      <ImageViewer open={openImage} setOpen={setOpenImage} image={SERVER_URL + image} />
    </div>

  )
}