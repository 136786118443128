import React, { useContext } from 'react';
import state from "../../state";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AppContext from '../../context/AppContext';
import {SERVER_URL} from 'utils/constant';

const getColorByBgColor = (bgColor) => {
    if (!bgColor) { return ''; }
    return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff';
}

const CircleSelect = (props) => {

    const {brandColor} = useContext(AppContext)
    
    return props.type === 'texture' ?
        <Stack>
            <Box
                className={ `${props.size==='normal'?'circle-select':'circle-select-small'} ${props.isSelected?'circle-selected':''}` }
                id={props.title}
                sx={{ 
                    backgroundColor: props.color, 
                    color: props.color, 
                    '&:hover': { color: getColorByBgColor(props.color) },
                    borderColor: (props.isSelected && brandColor !== '#7596AE' )? brandColor : (props.isSelected? '#7aae75' : '')
                }}
                onClick={() => {
                    if (props.size === 'normal') {
                        if (props.material === 'structure') {
                            state.isDesign = false;
                            state.structure.color = props.color;
                            state.structure.colorName = props.title;
                        }
                        else {
                            state.isBladeTexture = false;
                            state.blades.colorName = props.title;
                            state.isDesign = false;
                            state.blades.color = props.color;
                        }
                    }
                    else if(props.size === 'small'){
                        if (props.isForte === true) {
                            state.forteColor = props.color;
                            state.forteColorName = props.title;
                        }
                        else {
                            state.systemColor[props.systemID] = props.color;
                            state.systemColorName[props.systemID] = props.title;
                        }
                        state.systemTexture[props.systemID] = null;

                    }
                    else {
                        state.systemTexture[props.systemID] = props.color;                        
                    }
                }
                }
            >
                {props.title}
            </Box>
            {props.isSelected && props.size==="normal"?props.title:null}
        </Stack>
        :
        <Stack>
            <Box
                className={ `${props.size==='normal'?'circle-select':'circle-select-small'} ${props.isSelected?'circle-selected':''}` }
                id={props.title}
                sx={{ backgroundImage: `url(${props.url})`, color: 'white',
                    borderColor: (props.isSelected && brandColor !== '#7596AE' )? brandColor : (props.isSelected? '#7aae75' : '')
                }}
                onClick={() => {
                    if (props.size === 'normal') {
                        if (props.material === 'structure') {
                            state.structure.texture = props.url;
                            state.structure.textureR = props.url2;
                            state.structure.textureA = props.urlA;
                            state.structure.textureB = props.urlB;
                            state.structure.textureC = props.urlC;
                            state.structure.textureD = props.urlD;
                            const textures = [];
                            for (var i = 6; i <= 14; i = i + 1) {
                              textures.push(SERVER_URL + props.urls['image'+i]);
                            }
                            state.structure.textures = textures;
                            state.isTexture = true;
                            state.structure.colorName = props.title;
                            state.structure.color = '#aaaaaa';
                        }
                        else {
                            state.isBladeTexture = true;
                            state.blades.texture = props.url;
                            state.blades.colorName = props.title;
                            state.blades.color = '#aaaaaa';
                        }
                    }
                    else if(props.size === 'small') { 
                        state.systemColor[props.systemID] = props.color;
                        state.systemColorName[props.systemID] = props.title;
                        state.systemTexture[props.systemID] = null;
                    }
                    else {
                        state.systemTexture[props.systemID] = props.color;
                    }
                }
                }
            >
                <span className="circle-select-texture">{props.title}</span>
            </Box>
            {props.isSelected && props.size === 'normal' ?props.title:null}
        </Stack>
}

export default CircleSelect;