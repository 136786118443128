import { useState, useEffect, useContext } from 'react';

import { API_URL, IP_API_KEY, axiosInstanceAuthorized } from '../utils/constant';
import axios from 'axios';
import { toast } from 'react-toastify';
import AppContext from '../context/AppContext';

export function GetTempLink(templink) {
    const [user, setUser] = useState(null);
    const {setBrandColor, setShowprice} = useContext(AppContext);
    const [status, setStatus] = useState(null);

    const isValid = () => {
        const finded_user = user
          localStorage.setItem('api_url', finded_user.extra_link);
          localStorage.setItem('thanks', finded_user.thanks);
          localStorage.setItem('company_logo', finded_user.company_logo);
          localStorage.setItem('user_id', finded_user.user_id);
          localStorage.setItem('id', finded_user.id);
          setBrandColor(finded_user.brand_color);
          setShowprice(finded_user.showprice === 1 ? 1:0);
          localStorage.setItem('embed_key', finded_user.embed_key);
          localStorage.setItem('first_name', finded_user.first_name);
          localStorage.setItem('last_name', finded_user.last_name);
          localStorage.setItem('benefit', finded_user.benefit);
          localStorage.setItem('discount', finded_user.discount);
          localStorage.setItem('user_install', finded_user.install);
          localStorage.setItem('embed_user', JSON.stringify(finded_user));
          if (!finded_user.unit || finded_user.unit === null || finded_user.unit == 0) localStorage.setItem('unit', 'inch');
          else localStorage.setItem('unit', 'mm');
          if (finded_user.company_logo === null || finded_user.company_logo === undefined) localStorage.setItem('logo_option', '1');
          else localStorage.setItem('logo_option', finded_user.logo_option);
          localStorage.setItem('mode', 'temp');
        return true
    }

    const fetchUserCountry = () => {
      try {
        fetch('https://api.ipify.org/?format=json')
        .then((response) => response.json())
        .then((data) => {
          const userIP = data.ip;

          fetch(`https://ipinfo.io/${userIP}?token=`+IP_API_KEY)
          .then((responsedata) => responsedata.json())
          .then((data) => {
            localStorage.setItem('ip_addr', userIP);
            localStorage.setItem('country', data.country);
            getUser(data.country);
          })
          .catch(error=>{
            console.log(error)
          })
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      } catch (error) {
        console.error("Error fetching user's country:", error);
        // Handle error fetching country information
      }
    };

    const getUser = (country) => {
      axiosInstanceAuthorized
          .post(API_URL + "templink/getUser", {templink: templink, country: country})
          .then(response => {
            console.log(response.data);
            if (response.data.success) {
              setUser(response.data.user);
              localStorage.setItem('restricted_country', response.data.country);
            }
            else {
              setStatus(response.data.message);
            }
          })
          .catch(err => {
            console.log(err);
            if (err.message === 'Network Error') {
              toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          })
      }

    /* eslint-disable */
    useEffect(() => {
      // fetchUserCountry();
      getUser('');
    }, [])
    /* eslint-enable */

    return user === null?status:isValid();
}
