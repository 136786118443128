import React, { useContext } from "react";
import { useSnapshot } from 'valtio';

import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import state from '../../state';
import ColumnCount from "../ColumnCount";
import ValidationForm from './ValidationForm';
import AppContext from "../../context/AppContext";
import { models } from "../../utils/constant";

const Order = () => {
  const col_num = ColumnCount();
  const snap = useSnapshot(state);
  const { mode, modelID, modelCount } = useContext(AppContext);
  const isMobile = useMediaQuery('(max-width:769px)')
  const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).user : JSON.parse(localStorage.getItem('embed_user'));
  const user_install =
    mode === 0
      ? JSON.parse(localStorage.getItem("user")).user.install
      : localStorage.getItem("user_install");

  const api_url = user.extra_link_user;
  const thanks_url = user.thanks;

  const handleSend = (formData) => {
    const data = {
      modelName: models[modelID].title,
      unit: snap.length.unitName,
      width: parseInt(snap.length.width * snap.length.unit + 0.5),
      depth: parseInt(snap.length.depth * snap.length.unit + 0.5),
      height1: parseInt(snap.length.height1 * snap.length.unit + 0.5),
      height2: parseInt(snap.length.height2 * snap.length.unit + 0.5),
      coverColor: snap.structure.color,
      isTexture: snap.isTexture,
      glassColor: snap.blades.color,
      glassTexture: snap.blades.textureType,
      Light: snap.hasLight,
      numberOfColumns: col_num,
      addedColumns: JSON.stringify(snap.columns.added),
      addedColumnPosition: JSON.stringify(snap.columns.pos),
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      city: formData.city,
      findInfo: formData.info,
      zipCode: formData.zipcode,
      address: formData.address,
      phoneNumber: formData.phoneNumber,
      message: formData.message,
      install: snap.install == 1 ? "With" : 
      (user_install == 2 ? 'Without But Calculated':"Without"),
            modelCount: modelCount,
      modelCount: modelCount,
    }
    fetch(api_url, {  // Enter your IP address here

      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data) // body data type must match "Content-Type" header

    })
      .then(response => response.json())
      .then(data => {
        state.responseMsg = data;
        state.datasent = 1;
        window.location.href = thanks_url;
      })
      .catch(error => {
        state.datasent = -1;
      });

  };

  return (
    <Box className={!isMobile ? 'overview overview-order' : ''} sx={{ p: 0, pr: { md: 2, xs: 0 }, mb: { md: 0, xs: 2.5 } }} >
      <ValidationForm handleSend={handleSend} />
    </Box>
  );
}

export default Order;