import React, {useEffect} from 'react';
import axios from 'axios';
import {API_URL, title, SERVER_URL, axiosInstanceAuthorized} from 'utils/constant';
import state from 'state';

export default function SelectDefaultGlassType(modelID) {
    axiosInstanceAuthorized.get(API_URL+'glasstype/get')
    .then(response=>{
        const all_glass_types = response.data.glasstypes;
        const glasstypes = all_glass_types.filter(item=>(item.list.toLowerCase().split(',').includes(title[modelID].toLowerCase())));
        if (glasstypes.length > 0) {
            const glasstype = glasstypes[0];
            if (glasstype.image !== null) {
                state.isBladeTexture = true;
                state.blades.texture = SERVER_URL + glasstype.image;
                }
                else {
                state.isBladeTexture = false;
                }
                // state.blades.texture = value;
                state.blades.textureType = glasstype.title;
                state.blades.opacity = glasstype.opacity / 100;
                state.blades.colorName = glasstype.title;
            }
    })

    return true;
} 