import React, { useContext } from 'react'
import { useSnapshot } from 'valtio'

import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import FormDialog from './Form'

import { steps } from '../../utils/constant'
import state from '../../state'
import AppContext from '../../context/AppContext'
import { toastWarning } from '../Toast'
import Darken from '../Darken'
import { useTranslation } from 'react-i18next'

const PrevNext = (props) => {
  const {
    activeStep,
    setActiveStep,
    subStep,
    setSubStep,
    modelID,
    install,
    setOpenSendForm,
    brandColor,
    model,
    mode,
    setInstall,
  } = useContext(AppContext)
  const { t, i18n } = useTranslation();

  const snap = useSnapshot(state)
  const user_install = (mode === 0?JSON.parse(localStorage.getItem('user')).user.install : localStorage.getItem('user_install'));

  const handleNext = () => {

    if (steps[modelID].length === activeStep + 3 && (user_install === 0 || user_install === '0' || user_install === 2 || user_install === '2')) {
      setActiveStep((prevActiveStep) => prevActiveStep + 2)
      setSubStep(0);
      return;
    }

    if (steps[modelID].length === activeStep + 2) {
      if (install === 'None' && snap.isModel === true) {
        if (user_install === 1 || user_install === '1') {
          toastWarning('Please select install option!');
          return;
        }
        else {
          setInstall(0);
          state.install = 0;
        }
      }
    }
    if (steps[modelID].length === activeStep + 1) {
        setOpenSendForm(true);
    }
    else {
        if (
            steps[modelID][activeStep].detail === undefined ||
            steps[modelID][activeStep].detail.length === subStep + 1 ||
            !snap.isModel
          ) {
            if (!snap.isModel && activeStep === 1) setActiveStep(4);
            else setActiveStep((prevActiveStep) => prevActiveStep + 1)
            setSubStep(0)
          } else {
            setSubStep((prevSubStep) => prevSubStep + 1)
          }
    }
  }

  const handlePrev = () => {
    if (subStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
      setSubStep(steps[modelID][activeStep - 1].detail.length - 1)
    } else {
      setSubStep((prevSubStep) => prevSubStep - 1)
    }
  }

  const handleBack = () => {
    state.columns.editing = false
  }

  return (
    <Stack 
        direction="row" 
        justifyContent="space-between" 
        alignItems="center" 
        sx={{ mb: { md: 0, xs: 3 }, pr: {md: 5, xs: 0}, mt: {xs: 3} }}
        display={{ md: (activeStep < steps[modelID].length-1)?'':'none'}}
    >
        {snap.columns.editing === false ? (
          activeStep === steps[modelID].length && subStep === 0 ?
            null
            :
            <>
              <Button
                size="medium"
                variant="contained"
                sx={{
                  bgcolor: brandColor,
                  borderRadius: 0.5,
                  width: '50%',
                  mr: 1.25,
                  '&:hover': { bgcolor: Darken(brandColor) }
                }}
                onClick={handlePrev}
                disabled={activeStep === 0 && subStep === 0}
              >
                {t('Previous')}
              </Button>
              {activeStep !== steps[modelID].length ? (
                <Button
                  sx={{ bgcolor: brandColor, borderRadius: 0.5, width: '50%' ,
                  '&:hover': { bgcolor: Darken(brandColor) }
                  }}
                  variant="contained"
                  size="medium"
                  onClick={handleNext}
                >
                  {t('Next')}
                </Button>
              ) : (
                <FormDialog />
              )}
            </>

        ) : (
          <Button
            variant="contained"
            sx={{
              width: '100%',
              borderRadius: 0.5,
              bgcolor: '#7AAE75',
            }}
            onClick={handleBack}
          >
            {t('Back to Columns')}
          </Button>
        )}
      </Stack>
  )
}

export default PrevNext;
