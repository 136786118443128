/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 classy_second_handle.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import classyhandle from '../assets/models/classy_second_handle.glb'
import * as THREE from 'three';

export default function ClassyHandle(props) {
  const { nodes, materials } = useGLTF(classyhandle)
  const silver_material = new THREE.MeshStandardMaterial( {
    metalness: 1,   // between 0 and 1
    roughness: 0.5, // between 0 and 1
  } );
  return (
    <group {...props} scale={1000}>
      <mesh material-color={props.materialColor} geometry={nodes.classy_second_handle.geometry} material={silver_material} position={[0, 0.01, 0]} scale={[0.01, 0.01, 0.001]} />
    </group>
  )
}

useGLTF.preload('/classy_second_handle.glb')
