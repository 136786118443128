import React from "react";
import { SideInfills, SideForSubSystem, AddSubSystems } from "./SideInfills";

const DrawElement = (props) => {
  const {
    element,
    material,
    materials,
    snap,
    mesh_position = null,
    mesh_scale = null,
    mesh_rotation = null,
    materialTexture,
    color,
  } = props;

  const isTexture = snap.isTexture;

  let position = element.position;
  let scale = element.scale;
  let rotation = element.rotation;
  if (mesh_position)
    position = [
      position.x + mesh_position[0],
      position.y + mesh_position[1],
      position.z + mesh_position[2],
    ];
  if (mesh_scale) scale = mesh_scale;
  if (mesh_rotation) rotation = mesh_rotation;

  return (
    <group position={position}>
      <group rotation={rotation} scale={scale}>
        <mesh
          castShadow
          geometry={element.geometry}
          material={
            material
              ? material
              : isTexture
              ? materialTexture
              : materials.profile
          }
          material-color={color}
        />
      </group>
    </group>
  );
};

const SimpleDoor = (props) => {
  const {
    nodes,
    materials,
    material,
    isTexture,
    snap,
    width,
    height1,
    height2,
    materialTexture,
    silverMaterial,
    handleColor,
    activeStep,
    subStep,
  } = props;
  const [currentColumn, setCurrentColumn] = React.useState(-1);
  const elements = nodes.Scene.children[0].children;
  const meshes = []; // fixed door
  const meshes2 = []; // rotate door
  const meshes3 = []; // handle
  const spw = 132.29; // sidePanelWidth
  const spw2 = 134; // rightsidePanelWidth
  const spw3 = 54.75; // rightSideWidth
  const sph = 1949.999; // sidePanelHeight
  const sfh = 1899.999; // sideFrameHeight
  const upw = 556.27; // underPanelWidth
  const upw2 = 417.4; // underRightPanelWidth
  const uph = 160; // underPanelHeight
  const uph2 = 20; // underPanelHeight2
  const tph = 49.999; // topPanelHeight
  const ddw = spw * 2 + upw; // defaultDoorWidth
  const ddh = sph; //defaultDoorHeight
  const gh = 1640; //glassHeight
  const gh2 = 1845.44; //glassHeight2
  //   const scale = [sw * 0.036, sh * 0.036, 0.036];
  const scale = [1, 1, 1];

  const bw = width - spw - spw2; // real bottom width
  const ssw = height2 - spw3; // real right bottom width
  const sw = [bw / upw, 1, 1];
  const sw2 = [ssw / upw2, 1, 1];

  const dh = height1 / ddh;
  const sh = [1, dh, 1];
  const sgh = (height1 - uph - tph) / gh; // scale glass height
  const sgh2 = (height1 - uph2 * 2) / gh2; // scale glass height2
  meshes.push({
    name: "door_bottom",
    scale: sw,
    pos: [-height2 / 2, 0, 0],
  });
  meshes2.push({
    name: "profile_top",
    scale: sw,
    pos: [-height2 / 2, 0, height1 - ddh],
  });
  meshes2.push({
    name: "profile_left",
    scale: sh,
    pos: [(ddw - width) / 2 - height2 / 2, 0, ((dh - 1) * ddh) / 2],
  });
  meshes2.push({
    name: "profile_right",
    scale: sh,
    pos: [(width - ddw) / 2 - height2 / 2, 0, ((dh - 1) * ddh) / 2],
  });

  meshes.push({
    name: "door_left",
    scale: sh,
    pos: [(ddw - width) / 2 - height2 / 2, 0, ((dh - 1) * sfh) / 2],
  });
  meshes.push({
    name: "door_right",
    scale: sh,
    pos: [(width - ddw) / 2 - height2 / 2, 0, ((dh - 1) * sfh) / 2],
  });
  meshes.push({
    name: "door_top",
    scale: sw,
    pos: [-height2 / 2, 0, height1 - ddh - (ddh - sfh) * (dh - 1)],
  });
  meshes2.push({
    name: "right_top",
    scale: sw2,
    pos: [(width - ddw) / 2 + (ssw - upw2) / 2 - height2 / 2, 0, height1 - ddh],
  });
  meshes2.push({
    name: "right_right",
    scale: sh,
    pos: [
      (width - ddw) / 2 + (ssw - upw2) - height2 / 2,
      0,
      ((dh - 1) * ddh) / 2,
    ],
  });
  meshes2.push({
    name: "right_bottom",
    scale: sw2,
    pos: [(width - ddw) / 2 + (ssw - upw2) / 2 - height2 / 2, 0, 0],
  });

  meshes3.push({
    name: "Handle_1",
    pos: [(ddw - width) / 2 - height2 / 2, 0, 0],
  });
  meshes3.push({
    name: "handle_back_1",
    pos: [(ddw - width) / 2 - height2 / 2, 0, 0],
  });
  meshes3.push({
    name: "key",
    pos: [(ddw - width) / 2 - height2 / 2, 0, 0],
  });
  meshes3.push({
    name: "doorpet_001",
    pos: [(width - ddw) / 2 - height2 / 2, 0, height1 - ddh],
  });
  meshes3.push({
    name: "doorpet_002",
    pos: [(width - ddw) / 2 - height2 / 2, 0, 0],
  });

  const ctrX = width / 2 - height2 / 2 - 270;
  const ctrY = 10;

  return (
    <group scale={scale}>
      <group position={[ctrX, ctrY, 0]}>
        <group rotation={[0, 0, (snap.blades.rotation / 180) * Math.PI]}>
          <group position={[-ctrX, -ctrY, 0]}>
            <DrawElement
              element={elements.find((item) => item.name == "door_glass")}
              material={material}
              materialTexture={materialTexture}
              isTexture={isTexture}
              color={snap.structure.color}
              snap={snap}
              mesh_scale={[bw / upw, sgh, 1]}
              mesh_position={[-height2 / 2, 0, ((dh - 1) * gh) / 2]}
            />

            {meshes3.map((mesh, index) => (
              <DrawElement
                key={index}
                element={elements.find((item) => item.name == mesh.name)}
                material={silverMaterial}
                materialTexture={silverMaterial}
                color={handleColor}
                snap={snap}
                mesh_position={mesh.pos}
                mesh_scale={mesh.scale}
              />
            ))}
            {meshes.map((mesh, index) => (
              <DrawElement
                key={index}
                element={elements.find((item) => item.name == mesh.name)}
                materials={materials}
                materialTexture={materialTexture}
                isTexture={isTexture}
                color={snap.structure.color}
                snap={snap}
                mesh_position={mesh.pos}
                mesh_scale={mesh.scale}
              />
            ))}
          </group>
        </group>
      </group>
      <DrawElement
        element={elements.find((item) => item.name == "right_glass")}
        material={material}
        materialTexture={materialTexture}
        isTexture={isTexture}
        color={snap.structure.color}
        snap={snap}
        mesh_scale={[ssw / upw2, sgh2, 1]}
        mesh_position={[
          (width - ddw + ssw - upw2) / 2 - height2 / 2,
          0,
          (gh2 * (dh - 1)) / 2,
        ]}
      />
      {meshes2.map((mesh, index) => (
        <DrawElement
          key={index}
          element={elements.find((item) => item.name == mesh.name)}
          materials={materials}
          materialTexture={materialTexture}
          isTexture={isTexture}
          color={snap.structure.color}
          snap={snap}
          mesh_position={mesh.pos}
          mesh_scale={mesh.scale}
        />
      ))}
      <AddSubSystems
        columns={snap.columns}
        width={snap.length.width + snap.length.height2}
        height={snap.length.height1}
        systemID={snap.systemID}
        activeStep={activeStep}
        subStep={subStep}
        setCurrentColumn={setCurrentColumn}
      />
      <SideForSubSystem
        width={snap.length.width + snap.length.height2}
        height={snap.length.height1}
        columns={snap.columns}
        activeStep={activeStep}
        subStep={subStep}
        currentColumn={currentColumn}
      />
      <SideInfills
        width={snap.length.width + snap.length.height2}
        height={snap.length.height1}
        columns={snap.columns}
        systemID={snap.systemID}
        isTexture={snap.isTexture}
        texture={snap.structure.textureD}
        texture1={snap.structure.textureC}
        doubleSystem={props.doubleSystem}
        walls={snap.walls}
        dx={-246}
      />
    </group>
  );
};

export default SimpleDoor;
