import React from "react";
import SubSystem from "../../subsystems/SubSystem";
import state from "../../state";
import { Html, Box } from "@react-three/drei";
import { BsPlusCircle } from "react-icons/bs";

const rotations = [
  [Math.PI / 2, Math.PI, -Math.PI / 2],
  [Math.PI / 2, Math.PI, 0],
  [Math.PI / 2, Math.PI, Math.PI / 2],
  [Math.PI / 2, Math.PI, Math.PI],
];

function SideInfill(props) {
  const { side, columns, width, height, dx = 0 } = props;
  const pos_list = [
    { id: 0, pos: [-width / 2 + dx, -985, 170] },
    { id: 1, pos: [width / 2 + dx + 2027, -985, 170] },
  ];

  const list = [];

  pos_list.forEach((pos, index) => {
    if (columns.addedSubSystem[index] === false) return;
    list.push(
      <SubSystem
        key={index}
        px={pos.pos[0]}
        py={pos.pos[1]}
        pz={pos.pos[2]}
        rotation={[0, 0, Math.PI / 2]}
        width={2000}
        height={height}
        systemID={columns.systemId[index]}
        unit={1}
        modelID={18}
        ID={index}
        scale={10}
        type={1}
        side={side}
        systemType={1}
        isTexture={props.isTexture}
        texture={props.texture}
        texture1={props.texture1}
      />
    );
  });

  return list;
}

export function SideForSubSystem(props) {
  const { columns, currentColumn, width, height } = props;
  const list = [];

  const pos_list = [
    { id: 0, pos: [-width / 2 - 1235, 0, 70] },
    { id: 1, pos: [width / 2 + 765, 0, 70] },
  ];
  pos_list.map((pos, index) => {
    if (currentColumn === index) {
      list.push(
        <mesh key={index} position={pos.pos} rotation={[0, 0, 0]}>
          <boxGeometry args={[2000, 1, height]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>
      );
    }
  });

  return list;
}

export function AddSubSystems(props) {
  const { columns, width, height, systemID } = props;
  const list = [];
  const pos_list = [
    { id: 0, pos: [-width / 2 - 500, 0, 0] },
    { id: 1, pos: [width / 2, 0, 0] },
  ];
  pos_list.map((pos, index) => {
    if (columns.addingSubSystem === true)
      list.push(
        <Html scaleFactor={10} position={pos.pos} key={index}>
          <div
            className="side-info side-info-subsystem"
            id={"side" + pos.id}
            onPointerOver={(e) => {
              e.preventDefault();
              props.setCurrentColumn(index);
            }}
            onPointerOut={(e) => props.setCurrentColumn(-1)}
            onClick={(e) => {
              e.preventDefault();

              state.columns.addedSubSystem[index] = true;
              state.columns.systemId[index] = systemID;
              state.columns.systemDirection[index] = 0;
              if (systemID === 0) {
                state.columns.systemId[index + 8] = systemID;
                state.columns.systemDirection[index + 8] = 0;
              }

              state.columns.addingSubSystem = false;
              props.setCurrentColumn(-1);
            }}
          >
            <BsPlusCircle />
          </div>
        </Html>
      );
  });

  return list;
}

export function SideInfills(props) {
  const list = [];
  for (var i = 0; i < 4; i = i + 1) {
    if (props.walls.added[i]) continue;
    list.push(
      <SideInfill
        key={i}
        side={i + 1}
        columns={props.columns}
        width={props.width}
        height={props.height}
        systemID={props.systemID}
        isTexture={props.isTexture}
        texture={props.texture}
        texture1={props.texture1}
        walls={props.walls}
        dx={props.dx}
      />
    );
  }
  return list;
}
