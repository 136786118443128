import React, { useContext, useState, useEffect } from "react";
import { useSnapshot } from "valtio";

import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import Popover from "@mui/material/Popover";

import state from "../../state";
import AppContext from "../../context/AppContext";
import {
  sideStyles,
  API_URL,
  SERVER_URL,
  default_forte_pos,
  axiosInstanceAuthorized,
} from "../../utils/constant";
import { BsPlusCircleFill } from "react-icons/bs";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import SideCount from "../SideCount";
import axios from "axios";
import { useTranslation } from "react-i18next";

const SideInfills = () => {
  const snap = useSnapshot(state);
  const { modelID } = useContext(AppContext);
  const adding = snap.columns.addingSubSystem;
  const sideCount = SideCount(modelID);
  const isMobile = useMediaQuery("(max-width:769px)");
  const [products, setProducts] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [body, setBody] = useState("");
  const { t, i18n } = useTranslation();

  const getProducts = () => {
    axiosInstanceAuthorized
      .get(API_URL + "product/get")
      .then((response) => {
        setProducts(response.data.products);
        return response.data.products;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selected = (selected_title, systemID) => {
    sideStyles[modelID].forEach((item, index) => {
      if (item.title === selected_title) {
        state.systemID = systemID;
        document.getElementById(item.title).style.opacity = 1.0;
        document.getElementById(item.title).style.border = "5px solid #31d84a";
        if (systemID === 9) {
          state.columns.addedSubSystem[20] = true;
          state.columns.systemPos[20] = default_forte_pos;
          state.columns.systemPos[21] = default_forte_pos;
          state.columns.systemPos[20 + 24] = default_forte_pos;
          state.columns.systemPos[21 + 24] = default_forte_pos;
          if (modelID === 8 || modelID === 2) {
            state.columns.systemPos[22] = default_forte_pos;
            state.columns.systemPos[19] = default_forte_pos;
            state.columns.systemPos[22 + 24] = default_forte_pos;
            state.columns.systemPos[19 + 24] = default_forte_pos;
          }
        }
      } else {
        document.getElementById(item.title).style.border = "0px";
        document.getElementById(item.title).style.opacity = 0.7;
      }
    });
  };

  const handleClick = (event, bodytext) => {
    setAnchorEl(event.currentTarget);
    setBody(bodytext);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeSubsystem = () => {
    // TODO: short term solution for removing subsystems causing wrong calculation
    for (var u = 0; u < state.subSystemWidth.length; u = u + 1) {
      state.subSystemWidth[u] = 0;
    }

    for (var i = 0; i < 20; i = i + 1) {
      state.columns.addedSubSystem[i] = false;
      state.columns.addedForte[i] = false;
    }
    if (
      modelID === 10 ||
      modelID === 17 ||
      modelID === 16 ||
      modelID === 1 ||
      modelID === 4 ||
      modelID === 2 ||
      modelID === 8 ||
      modelID === 13 ||
      modelID === 15 ||
      modelID === 14 ||
      modelID === 19
    ) {
      for (var i = 0; i < 20; i = i + 1) {
        state.columns.addedSubSystem[24 + i] = false;
        state.columns.addedForte[24 + i] = false;
      }
    }
  };

  const refreshSubsystem = (systemID) => {
    // TODO: short term solution for removing subsystems causing wrong calculation
    for (var u = 0; u < state.subSystemWidth.length; u = u + 1) {
      state.subSystemWidth[u] = 0;
    }
    for (var i = 0; i < 20; i = i + 1) {
      if (modelID === 10 && i === 0) continue;
      if (systemID === 8 && modelID === 10) {
        state.columns.systemDirection[i] = 1;
      }
      if (systemID === 0) {
        state.columns.systemId[i] = systemID;
        state.columns.systemDirection[i] = 0;
        state.columns.systemDirection[i + 8] = 0;
        state.columns.systemDirection[i + 16] = 0;
      } else if (
        systemID === 6 &&
        snap.columns.addedSubSystem[i] &&
        (snap.columns.systemId[i] <= 3 || snap.columns.systemId[i] === 5)
      ) {
        state.columns.addedForte[i] = true;
        state.columns.fortePos[i] = 30;
      } else {
        if (systemID > 3 && systemID !== 5) state.columns.addedForte[i] = false;
        state.columns.systemId[i] = systemID;
      }
      state.columns.addedSubSystem[i] = true;
    }
    if (
      modelID === 10 ||
      modelID === 1 ||
      modelID === 4 ||
      modelID === 2 ||
      modelID === 8 ||
      modelID === 13 ||
      modelID === 15 ||
      modelID === 14 ||
      modelID === 16 ||
      modelID === 19 ||
      modelID === 17
    ) {
      for (var i = 0; i < 20; i = i + 1) {
        if (modelID === 10 && i === 1) continue;
        if (systemID === 8 && modelID === 10) {
          state.columns.systemDirection[24 + i] = 0;
        }
        if (systemID === 0) {
          state.columns.systemId[24 + i] = systemID;
          state.columns.systemId[24 + i + 8] = systemID;
          state.columns.systemId[24 + i + 16] = systemID;
          state.columns.systemDirection[24 + i] = 0;
          state.columns.systemDirection[24 + i + 8] = 0;
          state.columns.systemDirection[24 + i + 16] = 0;
        } else if (
          systemID === 6 &&
          snap.columns.addedSubSystem[24 + i] &&
          (snap.columns.systemId[24 + i] <= 3 ||
            snap.columns.systemId[24 + i] === 5)
        ) {
          state.columns.addedForte[24 + i] = true;
          state.columns.fortePos[24 + i] = 30;
        } else {
          if (systemID > 3 && systemID !== 5)
            state.columns.addedForte[24 + i] = false;
          state.columns.systemId[24 + i] = systemID;
        }
        state.columns.addedSubSystem[24 + i] = true;
        if (modelID === 16) {
          for (var j = 0; j < 4; j++) {
            state.columns.systemId[20 + j] = systemID;
            state.columns.addedSubSystem[20 + j] = true;
            state.columns.systemId[20 + 24 + j] = systemID;
            state.columns.addedSubSystem[20 + 24 + j] = true;
          }
        }
      }
    }
    return true;
  };

  useEffect(() => {
    getProducts();
  }, []);

  return products !== null ? (
    <Box
      className={!isMobile ? "overview" : ""}
      sx={{ p: 0, pr: { md: 2, xs: 0 }, mb: { md: 0, xs: 2.5 } }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography
          className="reset-default"
          sx={{ mb: 3 }}
          onClick={(e) => {
            e.preventDefault();
            removeSubsystem();
          }}
        >
          <span>
            <DeleteIcon />
          </span>
          <span>{t('Remove subsystems')}</span>
        </Typography>
      </Box>
      <ImageList variant="woven" cols={2} gap={15}>
        {sideStyles[modelID]
          .filter(
            (item) => (modelID === 15 && !item.hide) || (modelID !== 15 && true)
          )
          .map((item, idx) => (
            <label htmlFor={item.title} key={idx} className="add-label">
              <img
                src={
                  SERVER_URL +
                  products.filter((product) => product.link === item.url)[0]
                    .image
                }
                alt={item.title}
                id={item.title}
                className="styleImage"
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  selected(item.title, item.systemID);
                }}
              />
              {products.filter((product) => product.link === item.url)[0].title}
              <div
                className="moreInfo"
                aria-describedby={id}
                onClick={(e) => {
                  handleClick(
                    e,
                    products.filter((product) => product.link === item.url)[0]
                      .body
                  );
                }}
              >
                <u>{t('More Info')}</u>
              </div>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{ width: "400px" }}
              >
                <Typography sx={{ p: 2 }}>{body}</Typography>
              </Popover>

              {(item.systemID < 9 &&
                ((adding === false && sideCount) || item.systemID === 6)) ||
              (item.systemID === 9 &&
                adding === false &&
                snap.columns.addedSubSystem[20] === false) ? (
                <AddIcon
                  className="add-new"
                  fontSize="medium"
                  key={idx}
                  id={item.title + "plus"}
                  onClick={(e) => {
                    // e.preventDefault();
                    if (item.systemID < 9) state.columns.addingSubSystem = true;
                    selected(item.title, item.systemID);
                  }}
                />
              ) : null}
              {item.systemID !== 9 && adding === false ? (
                <RefreshIcon
                  fontSize="medium"
                  className="refresh"
                  key={idx + 20}
                  id={item.title + "refresh"}
                  onClick={(e) => {
                    refreshSubsystem(item.systemID);
                  }}
                />
              ) : null}
            </label>
          ))}
      </ImageList>
    </Box>
  ) : null;
};

export default SideInfills;
