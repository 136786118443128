import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";

export default function MiddleProfile1(props) {
  // const { nodes, materials } = useGLTF(divider_panora_cabbana);
  const { color, isTexture, xrepeat, yrepeat, nodes, materials } = props;
  const { width, depth, height1 } = props.length;
  const {
    int_width,
    int_depth,
    top_div_length,
    unit,
    mid_length_limit,
    column_thickness,
  } = props.int_length;
  const rwidth = width > mid_length_limit[0] ? width / 2 : width;
  // const sw = rwidth / (top_div_length + 150);
  const sw = rwidth / (top_div_length + 100);
  const dw = width - int_width;
  const dd = depth - int_depth;
  const texture = useTexture(props.texture);
  const scale = [0.08, 0.1, 0.1 * sw * 0.5];
  const rotation = [Math.PI / 2, Math.PI / 2, Math.PI / 2];
  texture.rotation = Math.PI;
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  if (!props.doubleSystem) return null;
  if (!props.origin) return null;

  if (width > mid_length_limit[0]) {
    return (
      <>
        {!props.systemVisible[1] || !props.systemVisible[3] ? null : (
          <group
            dispose={null}
            position={[
              (width * unit * 3) / 4 - (dw * unit) / 2 - 10,
              (height1 - 150) * unit,
              dd * unit - 75 * unit,
            ]}
          >
            {/* <group rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={[isPanora ? 1 : 0.85, sw, 3]}> */}
            <group rotation={rotation} scale={scale}>
              <mesh
                castShadow
                geometry={nodes.middleseperator2.geometry}
                material={isTexture ? material_texture : materials.profile}
                material-color={color}
              />
            </group>
          </group>
        )}

        {!props.systemVisible[0] || !props.systemVisible[2] ? null : (
          <group
            dispose={null}
            position={[
              (width * unit) / 4 - (dw * unit) / 2 - 10,
              (height1 - 150) * unit,
              dd * unit - 75 * unit,
            ]}
          >
            {/* <group rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={[isPanora ? 1 : 0.85, sw, 3]}> */}
            <group rotation={rotation} scale={scale}>
              <mesh
                castShadow
                geometry={nodes.middleseperator2.geometry}
                material={isTexture ? material_texture : materials.profile}
                material-color={color}
              />
            </group>
          </group>
        )}
      </>
    );
  } else {
    return (
      // <group dispose={null} position={[width * unit / 2 - dw * unit / 2 - 150 * unit, (height1 - 150) * unit, dd * unit - 80 * unit]}>
      <group
        // dispose={null}
        position={[
          (width * unit) / 2 - (dw * unit) / 2 - 110 * unit,
          (height1 - 150) * unit,
          dd * unit - 80 * unit,
        ]}
      >
        {/* <group rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={[0.85, sw, 3]}> */}
        <group rotation={rotation} scale={scale}>
          <mesh
            castShadow
            geometry={nodes.middleseperator2.geometry}
            material={isTexture ? material_texture : materials.profile}
            material-color={color}
          />
        </group>
      </group>
    );
  }
}

// useGLTF.preload('models/divider_panora_cabbana.glb')
