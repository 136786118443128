import React, {useState, useEffect, useContext} from 'react';
import { useParams } from 'react-router-dom';
import {ConfigContext} from 'context/config';
import axios from 'axios';
import {API_URL, axiosInstanceAuthorized} from 'utils/constant';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Navbar from './Navbar';
import Footer from 'common/Footer';
import he from 'he';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import QuillEditor from 'common/QuillEditor';

const customStyles = 
  `
    .ql-editor {
      padding: 0 !important;
    }
    .ql-container {
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
  }
  `
;

export default function AboutPage(props) {
    const { isDark } = useContext(ConfigContext);
    const {id} = useParams();
    const [rightContent, setRightContent] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        // Fetch the current footer content from the backend when the component mounts
        fetchFooterContent();
      }, []);
    
      const fetchFooterContent = async () => {
        axiosInstanceAuthorized.get(API_URL + 'footer/getall')
        .then(response=>{
            if (response.data.data === null) return false;
            const footer_right= response.data.footers;
            footer_right.map(item=>{
                if (item.title.replace(/\s/g, '') === id) {
                    setTitle(item.title);
                    setDescription(item.description);
                }
            })
        })
        .catch(err=>{
          //   toastError(err.message);
        })
      };

      return (
        <>
      <Grid container className="about" sx={{pl: {md: 10}, pr: {md: 10}}}>
      <Grid item lg={12} md={12} sm={12} xs={12} className="main" >
        <Box
          sx={{
            background:
              isDark ?
                'black' :
                'white',
          }}>
          <Navbar nomenu={true}/>
          <Box sx={{ pt: { md: 5 }, pb: {md: 5} }}>
            <Box>
              <Typography sx={{ fontSize: {md: 66, xs: 50}, fontWeight: 500 }} variant='h1'>{title}</Typography>
              <style>{customStyles}</style>
              <ReactQuill
                readOnly={true}
                modules={{ toolbar: false }}
                value={description}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
    <Footer user={JSON.parse(localStorage.getItem('user')).user}/>
    </>)

}