import React, { useContext } from "react";
import { useSnapshot } from "valtio";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import state from "../../state";
import AppContext from '../../context/AppContext';
import CircleSelect from "./CircleSelect";
import { coverColors, systemColors } from "../../utils/constant";

const Colors = () => {
  const snap = useSnapshot(state);
  const { modelID } = useContext(AppContext);

  // const selected = (selected_url) => {
  //   coverColors[modelID].forEach((item) => {
  //     if (item.url === selected_url) {
  //       document.getElementById(item.url).style.opacity = 1.0;
  //       document.getElementById(item.url).style.border = "5px solid #31d84a";
  //     } else {
  //       document.getElementById(item.url).style.border = "0px";
  //       document.getElementById(item.url).style.opacity = 0.7;
  //     }
  //   });
  // };

  return (
    <Box>
      <Typography sx={{ fontSize: 15, fontWeight: 500, mb: 2 }}>Structure color</Typography>

      <Box className="clearfix" sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {(snap.isModel?coverColors[modelID]:systemColors).map((item, index) => {
          return (
            <CircleSelect
              size="normal"
              isSelected={item.color === snap.structure.color}
              title={item.title}
              url={item.url}
              color={item.color}
              type={"texture"}
              material={"structure"}
              key={index}
            />)
        })}
      </Box>
    </Box>
  );
}

export default Colors;
