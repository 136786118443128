/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

import logo from '../../assets/models/logo.glb'

export function Logo(props) {
  const { nodes, materials } = useGLTF(logo)
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.logo_left.geometry} material={materials.logo} material-color={props.color}/>
    </group>
  )
}

// useGLTF.preload('models/logo.glb')
