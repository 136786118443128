import React, { useContext } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TuneIcon from "@mui/icons-material/Tune";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppContext from "../context/AppContext";
import Option from "./Option";
import { hasBlade, steps } from "../utils/constant";
import { useSnapshot } from "valtio";
import state from "../state";

export default function BladeOption(props) {
  const snap = useSnapshot(state);
  const { modelID, bladeOption, setBladeOption, activeStep, mode, systemID } =
    useContext(AppContext);
  const isMobile = useMediaQuery("(max-width:769px)");

  if (snap.isModel === false && snap.isSubSystem === false) return null;
  if (activeStep === steps[modelID].length - 1) return null;
  if (mode === 2) return null;
  else {
    return (
      <>
        {!isMobile ? (
          <>
            <Option />
          </>
        ) : (
          <Stack
            direction="row"
            onClick={() => setBladeOption(!bladeOption)}
            sx={{
              py: 0.6,
              px: 1.25,
              right: 15,
              zIndex: 99,
              borderRadius: 0.5,
              position: "fixed",
              top: "calc(30vh - 50px)",
              bgcolor: (theme) => theme.palette.background.default,
            }}
          >
            <Typography sx={{ mr: 1 }}>
              {snap.editingSubSystem
                ? "Sub system"
                : hasBlade[modelID].option
                ? hasBlade[modelID].label
                : "Mounting Wall"}
            </Typography>
            <TuneIcon />
          </Stack>
        )}
      </>
    );
  }
}
