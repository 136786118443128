import React, { useState } from "react";

import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Badge from "@mui/material/Badge";
import Link from "@mui/material/Link";

import { API_URL, SERVER_URL, axiosInstanceAuthorized } from "../utils/constant";
import axios from "axios";
import { toast } from "react-toastify";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import { makeStyles } from "@mui/styles";
import {
  IconButton,
  Popover,
  Typography,
  ListItem,
  Collapse,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ValidationForm from "common/LeftSide/ValidationForm";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from "react-router-dom";
import { toastSuccess, toastError } from "./Toast";
import state from "state";
import md5 from "md5";
import {
  SITE_URL,
  logo,
  title,
  secondHeight,
  steps,
  subsystems,
  hasMotor,
  lengths,
} from "utils/constant";
import Darken from "common/Darken";
import OverForte from "common/OverForte";
import { Opeartion } from "components/Drawer";
import { useScreen } from "hooks";
import { FormatNumber } from "common/FormatNumber";
import ConvertString from "./ConvertString";
import { getBenefit, getInstall } from "utils/helpers";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  dropdown: {
    position: "absolute",
    top: "100%",
    right: 0,
    width: "200px",
    backgroundColor: "#eee",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
    padding: "0px",
    borderRadius: "5px",
    zIndex: 1,
  },
  listItem: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    // justifyContent: 'space-between',
    padding: "10px 0px !important",
    // '&:hover': {
    //   cursor: 'pointer',
    //   backgroundColor: '#e3e3e3',
    // },
    borderTop: "1px solid #e3e3e3",
    borderBottom: "1px solid #e3e3e3",
  },
  image: {
    width: "100px",
    height: "70px",
    marginRight: "10px",
  },
  title: {
    width: "250px",
    marginRight: "10px",
  },
  closeicon: {
    position: "absolute",
    right: "0px",
    top: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  btn: {
    "&:hover": {
      backgroundColor: "#FFA500 !important",
      color: "white",
    },
  },
  info: {
    fontSize: "10px !important",
  },
}));

var hash_list = {};
const dr = [", Slide left", ", Slide right", ", Slide center"];
const compact_dr = ["3 Sliders", "2 Sliders"];
const openings = ["Left", "Right", "Center"];

const getHash = (a, b, c, d, e, f, g, h) => {
  return md5(
    a +
      "" +
      parseInt(b) +
      "" +
      parseInt(c) +
      "" +
      d +
      "" +
      e.toString() +
      "" +
      f.toString() +
      g.toString() +
      h.toString()
  );
};

const Add = (a, b, c, d, e, f, g, h) => {
  const key = getHash(a, b, c, d, e, f, a === 2 ? g : false, h);
  if (key in hash_list) {
    const val = hash_list[key];
    hash_list[key] = {
      systemID: a,
      width: b,
      height: c,
      direction: d,
      extra_forte: e,
      slider_count: f,
      has_key: a === 2 ? g : false,
      count: val.count + 1,
      color: h,
    };
  } else {
    hash_list[key] = {
      systemID: a,
      width: b,
      height: c,
      direction: d,
      extra_forte: e,
      slider_count: f,
      has_key: a === 2 ? g : false,
      count: 1,
      color: h,
    };
  }
};

var render = 0;

const Cart = (props) => {
  const { cartItems, setCartItems, priceData, setPriceData } =
    useContext(AppContext);
  // const [cartItems, setCartItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [optionals, setOptionals] = useState([]);
  const [drawer, setDrawer] = React.useState(false);
  const {
    flag,
    setFlag,
    openOrderForm,
    setOpenOrderForm,
    brandColor,
    mode,
    openOrderId,
  } = useContext(AppContext);
  const user = !mode
    ? JSON.parse(localStorage.getItem("user")).user
    : JSON.parse(localStorage.getItem("embed_user"));
  const { t, i18n } = useTranslation();

  const user_install =
    mode === 0
      ? JSON.parse(localStorage.getItem("user")).user.install
      : localStorage.getItem("user_install");
  const product_prices =
    user.prices === undefined || user.prices === null
      ? {}
      : JSON.parse(user.prices);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  // const snap = useSnapshot(state);
  const api_url = user.extra_link_user;
  const thanks_url = user.thanks;
  const embed_key = user.embed_key;
  const isMobile = useScreen();
  // const [priceData, setPriceData] = useState([]);

  const navigate = useNavigate();

  const handleBasketOpen = (event) => {
    setOpen(!open);
  };

  const handleBasketClose = () => {
    setOpen(false);
  };

  const calcSection = (modelID, width, depth, systemVisible) => {
    if (
      depth <=
      (modelID === 1 || modelID === 4
        ? 3500
        : modelID === 2 || modelID === 8
        ? 4000
        : 4500)
    ) {
      if (width <= lengths[modelID].mid_length_limit[0]) return 1;
      return 2;
    } else {
      if (width <= lengths[modelID].mid_length_limit[0]) return 2;
      return systemVisible.filter((item) => item === true).length;
    }
  };

  const getProductId = (product_name) => {
    const product = products.filter(
      (product) => product.title.toLowerCase() === product_name.toLowerCase()
    );
    return product.length > 0 ? product[0].id : -1;
  };

  const getProducts = () => {
    axiosInstanceAuthorized
      .get(API_URL + "product/get")
      .then((response) => {
        if (!render) {
          render = render + 1;
          getOptionals(response.data.products);
        }
        setProducts(response.data.products);
        return response.data.products;
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "Network Error") {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const getOrders = (user, optionals, products) => {
    axiosInstanceAuthorized
      .post(API_URL + "order/getBasket", user)
      .then((response) => {
        const _priceData = [];
        for (var i = 0; i < response.data.orders.length; i = i + 1) {
          _priceData.push({
            op: 0,
            tp: 0,
          });
        }

        setPriceData(_priceData);
        setCartItems(response.data.orders);
        calcPrice(response.data.orders, optionals, products);
        return response.data.orders;
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "Network Error") {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const getBasket = (optionals, products) => {
    var cartItem = [];
    if (localStorage.getItem("carts"))
      cartItem = JSON.parse(localStorage.getItem("carts"));
    const _priceData = [];
    for (var i = 0; i < cartItem.length; i = i + 1) {
      _priceData.push({
        op: 0,
        tp: 0,
      });
    }

    setPriceData(_priceData);
    setCartItems(cartItem);
    calcPrice(cartItem, optionals, products);
  };

  const getProductImageByName = (product_name) => {
    if (product_name === undefined) return "";
    if (products.length === 0 || products === undefined || products === null)
      return "";
    const finded_product = products.filter(
      (product) => product.title.toLowerCase() === product_name.toLowerCase()
    );
    if (finded_product.length === 0) return "";
    return finded_product[0].image;
  };

  const removeBasket = (id) => {
    axiosInstanceAuthorized
      .post(API_URL + "order/delete", { id: id })
      .then(() => {
        const updatedCartItems = cartItems.filter((item) => item.id !== id);
        setCartItems(updatedCartItems);
        toastSuccess("Removed from the Cart", brandColor);
        setFlag((prevflag) => !prevflag);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeBasketEmbed = (index) => {
    const updatedCartItems = cartItems;
    updatedCartItems.splice(index, 1);
    setCartItems(updatedCartItems);
    localStorage.setItem("carts", JSON.stringify(updatedCartItems));
    // if (cartItems.length <= 1) localStorage.removeItem('carts');
    toastSuccess("Removed from the Cart", brandColor);
    setFlag((prevflag) => !prevflag);
  };

  const handleClose = () => {
    setOpenOrderForm(false);
  };

  const handleSend = (formData) => {
    const _user = {
      UserName: user.user_id,
      firstName: user.first_name,
      lastName: user.last_name,
      embed: user.embed_key,
      benefit: user.benefit,
      discount: user.discount,
      shipping_price: user.shipping_price,
      campaign: user.campaign_per_product,
      extra_formula: user.extra_benefit,
    };

    const _client = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phoneNumber,
      zipCode: formData.zipcode,
      city: formData.city,
      address: formData.address,
    };

    const _message = formData.message;
    const _findInfo = formData.info;

    axiosInstanceAuthorized
      .get(API_URL + "order/getOrderNumber")
      .then((oresponse) => {
        const order_number = oresponse.data.order_number;
        cartItems.map((cartItem, index) => {
          if (openOrderId < 0 || openOrderId === index) {
            const snap =
              typeof cartItem.data === "string"
                ? JSON.parse(cartItem.data)
                : cartItem.data;
            const modelID = snap.modelID;
            const systemID = snap.systemID;
            hash_list = {};
            if (
              snap.columns.addedSubSystem[1] &&
              secondHeight[modelID] &&
              snap.walls.added[modelID === 6 ? 3 : 1] === false
            ) {
              Add(
                snap.columns.systemId[1],
                snap.subSystemWidth[1],
                snap.subSystemHeight[1],
                snap.columns.systemDirection[1],
                snap.columns.addedForte[1],
                snap.systemSliderCount[1],
                snap.columns.systemLock[1],
                snap.systemColorName[1],
                snap.systemSide[1]
              );
            }

            // For only Roomy
            // if (
            //   modelID === 10 &&
            //   snap.columns.addedSubSystem[24 + 1] &&
            //   secondHeight[modelID]
            // ) {
            //   Add(
            //     snap.columns.systemId[24 + 1],
            //     snap.subSystemWidth[24 + 1],
            //     snap.subSystemHeight[24 + 1],
            //     snap.columns.systemDirection[24 + 1],
            //     snap.columns.addedForte[24 + 1],
            //     snap.systemSliderCount[24 + 1],
            //     snap.columns.systemLock[24 + 1],
            //     snap.systemColorName[24 + 1],
            //     snap.systemSide[24 + 1],
            //   );
            // }

            // For only Avantgarde
            if (
              (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 20) &&
              snap.columns.addedSubSystem[1] &&
              secondHeight[modelID] &&
              snap.length.depth > 6000 &&
              snap.walls.added[1] === false
            ) {
              Add(
                snap.columns.systemId[6 + 1],

                snap.subSystemWidth[6 + 1],
                snap.subSystemHeight[6 + 1],
                snap.columns.systemDirection[6 + 1],
                snap.columns.addedForte[6 + 1],
                snap.systemSliderCount[6 + 1],
                snap.columns.systemLock[6 + 1],
                snap.systemColorName[6 + 1],
                snap.systemSide[6 + 1]
              );
            }

            if (
              modelID !== 10 &&
              snap.columns.addedSubSystem[0] &&
              secondHeight[modelID] &&
              snap.walls.added[modelID === 6 ? 1 : 3] === false
            ) {
              Add(
                snap.columns.systemId[0],
                snap.subSystemWidth[0],
                snap.subSystemHeight[0],
                snap.columns.systemDirection[0],
                snap.columns.addedForte[0],
                snap.systemSliderCount[0],
                snap.columns.systemLock[0],
                snap.systemColorName[0],
                snap.systemSide[0]
              );
            }

            //For only Roomy
            if (
              modelID === 10 &&
              snap.columns.addedSubSystem[24 + 0] &&
              secondHeight[modelID]
            ) {
              Add(
                snap.columns.systemId[24 + 0],
                snap.subSystemWidth[24 + 0],
                snap.subSystemHeight[24 + 0],
                snap.columns.systemDirection[24 + 0],
                snap.columns.addedForte[24 + 0],
                snap.systemSliderCount[24 + 0],
                snap.columns.systemLock[24 + 0],
                snap.systemColorName[24 + 0],
                snap.systemSide[24 + 0]
              );
            }

            // For only Avantgarde
            if (
              (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 20) &&
              snap.columns.addedSubSystem[0] &&
              secondHeight[modelID] &&
              snap.length.depth > 6000 &&
              snap.walls.added[3] === false
            ) {
              Add(
                snap.columns.systemId[6 + 0],
                snap.subSystemWidth[6 + 0],
                snap.subSystemHeight[6 + 0],
                snap.columns.systemDirection[6 + 0],
                snap.columns.addedForte[6 + 0],
                snap.systemSliderCount[6 + 0],
                snap.columns.systemLock[6 + 0],
                snap.systemColorName[6 + 0],
                snap.systemSide[6 + 0]
              );
            }

            for (var i = secondHeight[modelID] ? 2 : 0; i < 24; i = i + 1) {
              if (secondHeight[modelID]) {
                if (
                  snap.columns.addedSubSystem[i] === true &&
                  i === 20 &&
                  snap.columns.systemId[i] === 9
                ) {
                  const tmp_cnt = snap.capellaCount;
                  const tmp_unit = snap.capellaUnit;
                  for (var j = 0; j < tmp_cnt; j = j + tmp_unit) {
                    for (var k = 0; k < tmp_unit; k = k + 1)
                      Add(
                        snap.columns.systemId[i],
                        snap.capellaSize[k],
                        snap.subSystemHeight[i],
                        snap.columns.systemDirection[i],
                        snap.columns.addedForte[i],
                        snap.systemSliderCount[i],
                        snap.columns.systemLock[i],
                        snap.systemColorName[i],
                        snap.systemSide[i]
                      );
                  }
                } else {
                  if (
                    snap.columns.addedSubSystem[i] === true &&
                    ((snap.columns.added[i - 2] === true && i !== 3) ||
                      i === 20) &&
                    snap.subSystemWidth[i] > 0
                  ) {
                    Add(
                      snap.columns.systemId[i],
                      snap.subSystemWidth[i],
                      snap.subSystemHeight[i],
                      snap.columns.systemDirection[i],
                      snap.columns.addedForte[i],
                      snap.systemSliderCount[i],
                      snap.columns.systemLock[i],
                      snap.systemColorName[i],
                      snap.systemSide[i]
                    );
                  }

                  //For only Roomy
                }
                if (
                  modelID === 10 &&
                  snap.columns.addedSubSystem[24 + i] === true &&
                  snap.columns.added[i - 2] === true &&
                  i !== 3
                ) {
                  Add(
                    snap.columns.systemId[24 + i],
                    snap.subSystemWidth[24 + i],
                    snap.subSystemHeight[24 + i],
                    snap.columns.systemDirection[24 + i],
                    snap.columns.addedForte[24 + i],
                    snap.systemSliderCount[24 + i],
                    snap.columns.systemLock[24 + i],
                    snap.systemColorName[24 + i],
                    snap.systemSide[24 + i]
                  );
                }
              } else {
                if (
                  snap.walls.added[snap.columns.side[i] - 1] === true &&
                  snap.columns.systemId[i] < 9
                )
                  continue;
                if (
                  snap.columns.addedSubSystem[i] === true &&
                  snap.subSystemWidth[i] > 0 &&
                  snap.columns.systemId[i] === 9
                ) {
                  const tmp_cnt = snap.capellaCount;
                  const tmp_unit = snap.capellaUnit;
                  for (var j = 0; j < tmp_cnt; j = j + tmp_unit) {
                    for (var k = 0; k < tmp_unit; k = k + 1)
                      Add(
                        snap.columns.systemId[i],
                        snap.capellaSize[k],
                        snap.subSystemHeight[i],
                        snap.columns.systemDirection[i],
                        snap.columns.addedForte[i],
                        snap.systemSliderCount[i],
                        snap.columns.systemLock[i],
                        snap.systemColorName[i],
                        snap.systemSide[i]
                      );
                  }
                } else {
                  if (snap.length.depth > 4000 && (i === 2 || i === 4)) {
                  } else {
                    if (
                      snap.columns.addedSubSystem[i] === true &&
                      snap.subSystemWidth[i] > 0
                    ) {
                      if (
                        !snap.columns.systemId[i] &&
                        snap.subSystemWidth[i] > 4000
                      ) {
                        Add(
                          snap.columns.systemId[i],
                          snap.subSystemWidth[i] / 2,
                          snap.subSystemHeight[i],
                          snap.columns.systemDirection[i],
                          snap.columns.addedForte[i],
                          snap.systemSliderCount[i],
                          snap.columns.systemLock[i],
                          snap.systemColorName[i],
                          snap.systemSide[i]
                        );
                        Add(
                          snap.columns.systemId[i],
                          snap.subSystemWidth[i] / 2,
                          snap.subSystemHeight[i],
                          snap.columns.systemDirection[i + 8],
                          snap.columns.addedForte[i],
                          snap.systemSliderCount[i],
                          snap.columns.systemLock[i],
                          snap.systemColorName[i],
                          snap.systemSide[i]
                        );
                      } else {
                        Add(
                          snap.columns.systemId[i],
                          snap.subSystemWidth[i],
                          snap.subSystemHeight[i],
                          snap.columns.systemDirection[i],
                          snap.columns.addedForte[i],
                          snap.systemSliderCount[i],
                          snap.columns.systemLock[i],
                          snap.systemColorName[i],
                          snap.systemSide[i]
                        );
                      }
                    }
                  }
                }
                if (
                  (modelID === 1 ||
                    modelID === 4 ||
                    modelID === 2 ||
                    modelID === 8 ||
                    modelID === 13 ||
                    modelID === 15 ||
                    modelID === 14) &&
                  snap.columns.addedSubSystem[24 + i] === true &&
                  snap.subSystemWidth[24 + i] > 0 &&
                  snap.length.depth >
                    (modelID === 1 || modelID === 4
                      ? 3500
                      : modelID === 2 || modelID === 8
                      ? 4000
                      : 4500)
                ) {
                  if (
                    !snap.columns.systemId[24 + i] &&
                    snap.subSystemWidth[24 + i] > 4000
                  ) {
                    Add(
                      snap.columns.systemId[24 + i],
                      snap.subSystemWidth[24 + i] / 2,
                      snap.subSystemHeight[24 + i],
                      snap.columns.systemDirection[24 + i],
                      snap.columns.addedForte[24 + i],
                      snap.systemSliderCount[24 + i],
                      snap.columns.systemLock[24 + i],
                      snap.systemColorName[24 + i],
                      snap.systemSide[24 + i]
                    );
                    Add(
                      snap.columns.systemId[24 + i],
                      snap.subSystemWidth[24 + i] / 2,
                      snap.subSystemHeight[24 + i],
                      snap.columns.systemDirection[24 + i + 8],
                      snap.columns.addedForte[24 + i],
                      snap.systemSliderCount[24 + i],
                      snap.columns.systemLock[24 + i],
                      snap.systemColorName[24 + i],
                      snap.systemSide[24 + i]
                    );
                  } else {
                    Add(
                      snap.columns.systemId[24 + i],
                      snap.subSystemWidth[24 + i],
                      snap.subSystemHeight[24 + i],
                      snap.columns.systemDirection[24 + i],
                      snap.columns.addedForte[24 + i],
                      snap.systemSliderCount[24 + i],
                      snap.columns.systemLock[24 + i],
                      snap.systemColorName[24 + i],
                      snap.systemSide[24 + i]
                    );
                  }
                }
              }
            }

            const all_subsystems = [];
            const render_cnt = 1;
            const sections = calcSection(
              modelID,
              snap.length.width,
              snap.length.depth,
              snap.systemVisible
            );

            if (snap.isModel) {
              const benefit = getBenefit(title[modelID], product_prices);
              const install = getInstall(title[modelID], product_prices);
              if (!secondHeight[modelID]) {
                all_subsystems.push({
                  product_id: getProductId(title[modelID]),
                  model: title[modelID],
                  width: snap.length.width,
                  depth: snap.length.depth,
                  front_height: snap.length.height1,
                  back_height: secondHeight[modelID]
                    ? snap.length.height2
                    : snap.length.height1,
                  structure_color: snap.structure.colorName,
                  section: sections,
                  benefit: benefit,
                  install: install,
                });
              } else {
                if (modelID === 6 || modelID === 10) {
                  all_subsystems.push({
                    product_id: getProductId(title[modelID]),
                    model: title[modelID],
                    width: snap.length.width,
                    depth: snap.length.depth,
                    front_height: snap.length.height1,
                    back_height: secondHeight[modelID]
                      ? snap.length.height2
                      : snap.length.height1,
                    structure_color: snap.structure.colorName,
                    glassType: snap.blades.colorName,
                    benefit: benefit,
                    install: install,
                  });
                } else if (
                  modelID === 1 ||
                  modelID === 4 ||
                  modelID === 13 ||
                  modelID === 15 ||
                  modelID === 14
                ) {
                  all_subsystems.push({
                    product_id: getProductId(title[modelID]),
                    model: title[modelID],
                    width: snap.length.width,
                    depth: snap.length.depth,
                    front_height: snap.length.height1,
                    back_height: secondHeight[modelID]
                      ? snap.length.height2
                      : snap.length.height1,
                    structure_color: snap.structure.colorName,
                    blade_color: snap.blades.colorName,
                    benefit: benefit,
                    install: install,
                  });
                } else if (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 20) {
                  all_subsystems.push({
                    product_id: getProductId(title[modelID]),
                    model: title[modelID],
                    width: snap.length.width,
                    depth: snap.length.depth,
                    front_height: snap.length.height1,
                    back_height: secondHeight[modelID]
                      ? snap.length.height2
                      : snap.length.height1,
                    structure_color: snap.structure.colorName,
                    curtain_color: snap.systemColorName[23],
                    benefit: benefit,
                    install: install,
                  });
                } else {
                  all_subsystems.push({
                    product_id: getProductId(title[modelID]),
                    model: title[modelID],
                    width: snap.length.width,
                    depth: snap.length.depth,
                    front_height: snap.length.height1,
                    back_height: secondHeight[modelID]
                      ? snap.length.height2
                      : snap.length.height1,
                    structure_color: snap.structure.colorName,
                    benefit: benefit,
                    install: install,
                  });
                }
              }
              for (const [key, sub] of Object.entries(hash_list)) {
                const benefit = getBenefit(subsystems[sub.systemID].title, product_prices);
                const install = getInstall(subsystems[sub.systemID].title, product_prices);
                const benefit_f = getBenefit("Forte", product_prices);
                const install_f = getInstall("Forte", product_prices);
                if (sub.systemID === 9) {
                  all_subsystems.push({
                    product_id: getProductId(subsystems[sub.systemID].title),
                    model: subsystems[sub.systemID].title,
                    width: sub.width,
                    height: sub.height,
                    count: sub.count / render_cnt,
                    color: sub.color,
                    benefit: benefit,
                    install: install,
                  });
                } else if (sub.systemID === 2) {
                  all_subsystems.push({
                    product_id: getProductId(subsystems[sub.systemID].title),
                    model: subsystems[sub.systemID].title,
                    width: sub.width,
                    height: sub.height,
                    count:
                      (!sub.systemID && sub.width > 4000
                        ? sub.count * 2
                        : sub.count) / render_cnt,
                    slide: !sub.systemID
                      ? !sub.direction
                        ? 3
                        : 2
                      : sub.slider_count,
                    opening: openings[sub.direction],
                    overforte: sub.extra_forte,
                    key: sub.has_key,
                    benefit: benefit,
                    install: install,
                  });
                  if (sub.extra_forte) {
                    all_subsystems.push({
                      product_id: getProductId("Forte"),
                      model: "FORTE",
                      width: OverForte(sub.width, sub.height)
                        ? sub.width / 2
                        : sub.width,
                      height: sub.height,
                      count:
                        (OverForte(sub.width, sub.height)
                          ? sub.count * 2
                          : sub.count) / render_cnt,
                      color: snap.forteColorName,
                      benefit: benefit_f,
                      install: install_f,
                    });
                  }
                } else if (sub.systemID === 6) {
                  all_subsystems.push({
                    product_id: getProductId("Forte"),
                    model: "FORTE",
                    width: OverForte(sub.width, sub.height)
                      ? sub.width / 2
                      : sub.width,
                    height: sub.height,
                    count:
                      (OverForte(sub.width, sub.height)
                        ? sub.count * 2
                        : sub.count) / render_cnt,
                    color: snap.forteColorName,
                    benefit: benefit_f,
                    install: install_f,
                  });
                } else if (sub.systemID === 8) {
                  all_subsystems.push({
                    product_id: getProductId(subsystems[sub.systemID].title),
                    model: subsystems[sub.systemID].title,
                    width: sub.width,
                    height: sub.height,
                    count: sub.count / render_cnt,
                    inout: sub.inout == 0 ? "Out" : "In",
                    opening: openings[sub.direction],
                  });
                } else {
                  all_subsystems.push({
                    product_id: getProductId(subsystems[sub.systemID].title),
                    model: subsystems[sub.systemID].title,
                    width: sub.width,
                    height: sub.height,
                    count:
                      (!sub.systemID && sub.width > 4000
                        ? sub.count * 2
                        : sub.count) / render_cnt,
                    slide: !sub.systemID
                      ? !sub.direction
                        ? 3
                        : 2
                      : sub.slider_count,
                    opening: openings[sub.direction],
                    overforte: sub.extra_forte,
                    benefit: benefit,
                    install: install,
                  });
                  if (sub.extra_forte) {
                    all_subsystems.push({
                      product_id: getProductId("Forte"),
                      model: "FORTE",
                      width: OverForte(sub.width, sub.height)
                        ? sub.width / 2
                        : sub.width,
                      height: sub.height,
                      count:
                        (OverForte(sub.width, sub.height)
                          ? sub.count * 2
                          : sub.count) / render_cnt,
                      color: snap.forteColorName,
                      benefit: benefit_f,
                      install: install_f,
                    });
                  }
                }
              }
              if (
                modelID > 0 &&
                secondHeight[modelID] &&
                ((snap.columns.addedSubSystem[0] &&
                  snap.walls.added[modelID === 6 ? 1 : 3] === false) ||
                  (snap.columns.addedSubSystem[1] &&
                    snap.walls.added[modelID === 6 ? 3 : 1] === false))
              )
                all_subsystems.push({
                  model: "TRIANGLE",
                  width: snap.length.depth,
                  height: snap.length.height2 - snap.length.height1,
                  count:
                    snap.columns.addedSubSystem[0] &&
                    snap.walls.added[modelID === 6 ? 1 : 3] === false &&
                    snap.columns.addedSubSystem[1] &&
                    snap.walls.added[modelID === 6 ? 3 : 1] === false
                      ? 2
                      : 1,
                });
            } else {
              const benefit = getBenefit(subsystems[systemID].title, product_prices);
              const install = getInstall(subsystems[systemID].title, product_prices);
              all_subsystems.push({
                product_id: getProductId(subsystems[systemID].title),
                model: subsystems[systemID].title,
                width: snap.length.width,
                height: snap.length.depth,
                structure_color: snap.structure.colorName,
                slide: !systemID
                  ? !snap.columns.systemDirection[0]
                    ? 3
                    : 2
                  : snap.systemSliderCount[0],
                opening: openings[snap.columns.systemDirection[0]],
                benefit: benefit,
                install: install,
              });
            }

            const _optionals = [];
            optionals.map((optional, index) => {
              if (snap.optionals[index]) {
                if (optional.title.toLowerCase().includes("perimeter")) {
                  _optionals.push({
                    name: optional.title,
                    iscount: snap.optionals[index],
                    count: snap.optionals[index]
                      ? parseInt(snap.optionalCount[index])
                      : 0,
                    length: (snap.length.width + snap.length.depth) * 2,
                  });
                } else {
                  _optionals.push({
                    name: optional.title,
                    iscount: snap.optionals[index],
                    count: snap.optionals[index]
                      ? parseInt(snap.optionalCount[index])
                      : 0,
                  });
                }
              }
            });

            const _optional =
              modelID === 1 ||
              modelID === 4 ||
              modelID === 13 ||
              modelID === 15 ||
              modelID === 14
                ? {
                    install: snap.install,
                    wifi: snap.hasWifi,
                    fanprofile: snap.hasFan,
                    perimeterLight: snap.hasLight,
                    ledLight: snap.hasLedLight,
                    heater: snap.hasHeater,
                  }
                : {
                    install: snap.install === "With" ? 1 : 0,
                    wifi: snap.hasWifi,
                    fanprofile: snap.hasFan,
                    light: snap.hasLight,
                    heater: snap.hasHeater,
                  };

            const _unit = snap.length.unitName;
            const _main_product = snap.isModel
              ? title[modelID]
              : subsystems[systemID].title;

            const data = {
              client_id: -11,
              model_name: snap.isModel
                ? title[modelID]
                : subsystems[systemID].title,
              unit: snap.length.unitName,
              width: parseInt(snap.length.width * snap.length.unit + 0.5),
              depth: parseInt(snap.length.depth * snap.length.unit + 0.5),
              height1: parseInt(snap.length.height1 * snap.length.unit + 0.5),
              height2: parseInt(snap.length.height2 * snap.length.unit + 0.5),
              cover_color: snap.structure.color,
              glass_texture: snap.blades.textureType,
              light: snap.hasLight,
              added_columns: JSON.stringify(snap.columns.added),
              added_column_position: JSON.stringify(snap.columns.pos),
              install: 0,
              inst: 0,
              ledprice: 0,
              weight: 0,
              data: snap,
              user_id: user.id,
              email: formData.email,
            };

            if (mode === 0) {
              axiosInstanceAuthorized
                .post(API_URL + "order/updateOrder", {
                  client_id: -11,
                  id: cartItem.id,
                })
                .then((response) => {
                  const data1 = {
                    //old request
                    order_number: order_number,
                    modelName: snap.isModel
                      ? title[modelID]
                      : subsystems[systemID].title,
                    // unit: snap.length.unitName,
                    width: parseInt(snap.length.width * snap.length.unit + 0.5),
                    depth: parseInt(snap.length.depth * snap.length.unit + 0.5),
                    height1: parseInt(
                      snap.length.height1 * snap.length.unit + 0.5
                    ),
                    height2: parseInt(
                      snap.length.height2 * snap.length.unit + 0.5
                    ),
                    coverColor: snap.structure.color,
                    isTexture: snap.isTexture,
                    glassColor: snap.blades.color,
                    glassTexture: snap.blades.textureType,
                    Light: snap.hasLight,
                    numberOfColumns: 4,
                    addedColumns: JSON.stringify(snap.columns.added),
                    addedColumnPosition: JSON.stringify(snap.columns.pos),
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    city: formData.city,
                    // findInfo: formData.info,
                    zipCode: formData.zipcode,
                    address: formData.address,
                    phoneNumber: formData.phoneNumber,
                    // message: formData.message,
                    install:
                      snap.install == 1
                        ? "With"
                        : user_install == 2
                        ? "Without But Calculated"
                        : "Without",
                    modelCount: 1,
                    embed_key: embed_key,

                    //new request
                    user: _user,
                    client: _client,
                    optional: _optionals,
                    unit: _unit,
                    message: _message,
                    findInfo: _findInfo,
                    main_product: _main_product,
                    products: all_subsystems,
                    // section: secondHeight[modelID]?0:sections,
                    viewLink: SITE_URL + "/view/" + md5(cartItem.id),
                  };
                  setFlag((prevflag) => !prevflag);
                  fetch(api_url, {
                    // Enter your IP address here
                    method: "POST",
                    mode: "cors",
                    body: JSON.stringify(data1), // body data type must match "Content-Type" header
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      state.responseMsg = data;
                      state.datasent = 1;
                      var a = document.createElement("a");
                      a.href = thanks_url;
                      a.target = "_top";
                      a.click();
                      // window.location.href = thanks_url;
                      toastSuccess(
                        t("Your form has been submitted successfully!"),
                        brandColor
                      );
                      setOpenOrderForm(false);
                    })
                    .catch((error) => {
                      toastError(
                        t("Network Error! Please check internet connection or your setting")
                      );
                      setOpenOrderForm(false);
                      state.datasent = -1;
                    });

                  if (index === cartItems.length - 1 || openOrderId >= 0) {
                    toastSuccess(
                      t("Order has submitted successfully!"),
                      brandColor
                    );
                    if (openOrderId < 0) {
                      setCartItems([]);
                    } else {
                      setCartItems((previtems) => previtems.splice(index, 1));
                    }
                  }
                })
                .catch((err) => {
                  toastError(err.message);
                });
            } else {
              const data1 = {
                //old request
                order_number: order_number,
                modelName: snap.isModel
                  ? title[modelID]
                  : subsystems[systemID].title,
                // unit: snap.length.unitName,
                width: parseInt(snap.length.width * snap.length.unit + 0.5),
                depth: parseInt(snap.length.depth * snap.length.unit + 0.5),
                height1: parseInt(snap.length.height1 * snap.length.unit + 0.5),
                height2: parseInt(snap.length.height2 * snap.length.unit + 0.5),
                coverColor: snap.structure.color,
                isTexture: snap.isTexture,
                glassColor: snap.blades.color,
                glassTexture: snap.blades.textureType,
                Light: snap.hasLight,
                numberOfColumns: 4,
                addedColumns: JSON.stringify(snap.columns.added),
                addedColumnPosition: JSON.stringify(snap.columns.pos),
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                city: formData.city,
                // findInfo: formData.info,
                zipCode: formData.zipcode,
                address: formData.address,
                phoneNumber: formData.phoneNumber,
                // message: formData.message,
                install:
                  snap.install == 1
                    ? "With"
                    : user_install == 2
                    ? "Without But Calculated"
                    : "Without",
                modelCount: 1,
                embed_key: embed_key,

                //new request
                user: _user,
                client: _client,
                optional: _optionals,
                unit: _unit,
                message: _message,
                findInfo: _findInfo,
                main_product: _main_product,
                products: all_subsystems,
                // section: secondHeight[modelID]?0:sections,
                viewLink: SITE_URL + "/view/" + cartItem.data_id,
              };
              fetch(api_url, {
                // Enter your IP address here
                method: "POST",
                mode: "cors",
                body: JSON.stringify(data1), // body data type must match "Content-Type" header
              })
                .then((response) => response.json())
                .then((data) => {
                  state.responseMsg = data;
                  state.datasent = 1;
                  var a = document.createElement("a");
                  a.href = thanks_url;
                  a.target = "_top";
                  a.click();
                  // window.location.href = thanks_url;
                  toastSuccess(
                    t("Your form has been submitted successfully!"),
                    brandColor
                  );
                  setOpenOrderForm(false);
                })
                .catch((error) => {
                  toastError(
                    t("Network Error! Please check internet connection or your setting")
                  );
                  setOpenOrderForm(false);
                  state.datasent = -1;
                });

              if (index === cartItems.length - 1 || openOrderId >= 0) {
                toastSuccess(t("Order has submitted successfully!"), brandColor);
                if (openOrderId < 0) {
                  localStorage.removeItem("carts");
                  setFlag((prevflag) => !prevflag);
                  setCartItems([]);
                } else {
                  localStorage.removeItem("carts");
                  var updatedCartItems = cartItems;
                  updatedCartItems.splice(index, 1);

                  localStorage.setItem(
                    "carts",
                    JSON.stringify(updatedCartItems)
                  );
                  setFlag((prevflag) => !prevflag);
                  setCartItems((previtems) => previtems.splice(index, 1));
                }
              }
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOptionals = (products) => {
    axiosInstanceAuthorized
      .get(API_URL + "optional/get")
      .then((response) => {
        if (!mode) getOrders(user, response.data.optionals, products);
        else getBasket(response.data.optionals, products);
        return response.data.optionals;
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "Network Error") {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  React.useEffect(() => {
    render = 0;
    getProducts();
  }, [flag]);

  const handleClick = () => setDrawer(true);
  const widthByPercent = (percent) => (window.innerWidth * percent) / 100;

  const calcPrice = (cartItems, optionals, products) => {
    let total_price = 0;
    let total_optioanl_price = 0;
    let total_product_price = 0;
    if (cartItems.length === 0) return true;
    cartItems.map((cartItem, index) => {
      const snap =
        typeof cartItem.data === "string"
          ? JSON.parse(cartItem.data)
          : cartItem.data;
      const modelID = snap.modelID;
      const systemID = snap.systemID;
      hash_list = {};
      if (
        snap.columns.addedSubSystem[1] &&
        secondHeight[modelID] &&
        snap.walls.added[modelID === 6 ? 3 : 1] === false
      ) {
        Add(
          snap.columns.systemId[1],
          snap.subSystemWidth[1],
          snap.subSystemHeight[1],
          snap.columns.systemDirection[1],
          snap.columns.addedForte[1],
          snap.systemSliderCount[1],
          snap.columns.systemLock[1],
          snap.systemColorName[1],
          snap.systemSide[1]
        );
      }

      if (
        (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 20) &&
        snap.columns.addedSubSystem[1] &&
        secondHeight[modelID] &&
        snap.length.depth > 6000 &&
        snap.walls.added[1] === false
      ) {
        Add(
          snap.columns.systemId[6 + 1],
          snap.subSystemWidth[6 + 1],
          snap.subSystemHeight[6 + 1],
          snap.columns.systemDirection[6 + 1],
          snap.columns.addedForte[6 + 1],
          snap.systemSliderCount[6 + 1],
          snap.columns.systemLock[6 + 1],
          snap.systemColorName[6 + 1],
          snap.systemSide[6 + 1]
        );
      }

      if (
        modelID !== 10 &&
        snap.columns.addedSubSystem[0] &&
        secondHeight[modelID] &&
        snap.walls.added[modelID === 6 ? 1 : 3] === false
      ) {
        Add(
          snap.columns.systemId[0],
          snap.subSystemWidth[0],
          snap.subSystemHeight[0],
          snap.columns.systemDirection[0],
          snap.columns.addedForte[0],
          snap.systemSliderCount[0],
          snap.columns.systemLock[0],
          snap.systemColorName[0],
          snap.systemSide[0]
        );
      }

      //For only Roomy
      if (
        modelID === 10 &&
        snap.columns.addedSubSystem[24 + 0] &&
        secondHeight[modelID]
      ) {
        Add(
          snap.columns.systemId[24 + 0],
          snap.subSystemWidth[24 + 0],
          snap.subSystemHeight[24 + 0],
          snap.columns.systemDirection[24 + 0],
          snap.columns.addedForte[24 + 0],
          snap.systemSliderCount[24 + 0],
          snap.columns.systemLock[24 + 0],
          snap.systemColorName[24 + 0],
          snap.systemSide[24 + 0]
        );
      }

      // For only Avantgarde
      if (
        (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 20) &&
        snap.columns.addedSubSystem[0] &&
        secondHeight[modelID] &&
        snap.length.depth > 6000 &&
        snap.walls.added[3] === false
      ) {
        Add(
          snap.columns.systemId[6 + 0],
          snap.subSystemWidth[6 + 0],
          snap.subSystemHeight[6 + 0],
          snap.columns.systemDirection[6 + 0],
          snap.columns.addedForte[6 + 0],
          snap.systemSliderCount[6 + 0],
          snap.columns.systemLock[6 + 0],
          snap.systemColorName[6 + 0],
          snap.systemSide[6 + 0]
        );
      }

      for (var i = secondHeight[modelID] ? 2 : 0; i < 48; i = i + 1) {
        if (secondHeight[modelID]) {
          if (
            snap.columns.addedSubSystem[i] === true &&
            i === 20 &&
            snap.columns.systemId[i] === 9
          ) {
            const tmp_cnt = snap.capellaCount;
            const tmp_unit = snap.capellaUnit;
            for (var j = 0; j < tmp_cnt; j = j + tmp_unit) {
              for (var k = 0; k < tmp_unit; k = k + 1)
                Add(
                  snap.columns.systemId[i],
                  snap.capellaSize[k],
                  snap.subSystemHeight[i],
                  snap.columns.systemDirection[i],
                  snap.columns.addedForte[i],
                  snap.systemSliderCount[i],
                  snap.columns.systemLock[i],
                  snap.systemColorName[i],
                  snap.systemSide[i]
                );
            }
          } else {
            if (
              snap.columns.addedSubSystem[i] === true &&
              ((snap.columns.added[i - 2] === true && i !== 3) || i === 20) &&
              snap.subSystemWidth[i] > 0
            ) {
              Add(
                snap.columns.systemId[i],
                snap.subSystemWidth[i],
                snap.subSystemHeight[i],
                snap.columns.systemDirection[i],
                snap.columns.addedForte[i],
                snap.systemSliderCount[i],
                snap.columns.systemLock[i],
                snap.systemColorName[i],
                snap.systemSide[i]
              );
            }

            //For only Roomy
          }
          if (
            modelID === 10 &&
            snap.columns.addedSubSystem[24 + i] === true &&
            snap.columns.added[i - 2] === true &&
            i !== 3
          ) {
            Add(
              snap.columns.systemId[24 + i],
              snap.subSystemWidth[24 + i],
              snap.subSystemHeight[24 + i],
              snap.columns.systemDirection[24 + i],
              snap.columns.addedForte[24 + i],
              snap.systemSliderCount[24 + i],
              snap.columns.systemLock[24 + i],
              snap.systemColorName[24 + i],
              snap.systemSide[24 + i]
            );
          }
        } else {
          if (
            snap.walls.added[snap.columns.side[i] - 1] === true &&
            snap.columns.systemId[i] < 9
          )
            continue;
          if (
            snap.columns.addedSubSystem[i] === true &&
            snap.subSystemWidth[i] > 0 &&
            snap.columns.systemId[i] === 9
          ) {
            const tmp_cnt = snap.capellaCount;
            const tmp_unit = snap.capellaUnit;
            for (var j = 0; j < tmp_cnt; j = j + tmp_unit) {
              for (var k = 0; k < tmp_unit; k = k + 1)
                Add(
                  snap.columns.systemId[i],
                  snap.capellaSize[k],
                  snap.subSystemHeight[i],
                  snap.columns.systemDirection[i],
                  snap.columns.addedForte[i],
                  snap.systemSliderCount[i],
                  snap.columns.systemLock[i],
                  snap.systemColorName[i],
                  snap.systemSide[i]
                );
            }
          } else {
            if (snap.length.depth > 4000 && (i === 2 || i === 4)) {
            } else {
              if (
                snap.columns.addedSubSystem[i] === true &&
                snap.subSystemWidth[i] > 0
              ) {
                if (
                  !snap.columns.systemId[i] &&
                  snap.subSystemWidth[i] > 4000
                ) {
                  Add(
                    snap.columns.systemId[i],
                    snap.subSystemWidth[i] / 2,
                    snap.subSystemHeight[i],
                    snap.columns.systemDirection[i],
                    snap.columns.addedForte[i],
                    snap.systemSliderCount[i],
                    snap.columns.systemLock[i],
                    snap.systemColorName[i],
                    snap.systemSide[i]
                  );
                  Add(
                    snap.columns.systemId[i],
                    snap.subSystemWidth[i] / 2,
                    snap.subSystemHeight[i],
                    snap.columns.systemDirection[i + 8],
                    snap.columns.addedForte[i],
                    snap.systemSliderCount[i],
                    snap.columns.systemLock[i],
                    snap.systemColorName[i],
                    snap.systemSide[i]
                  );
                } else {
                  Add(
                    snap.columns.systemId[i],
                    snap.subSystemWidth[i],
                    snap.subSystemHeight[i],
                    snap.columns.systemDirection[i],
                    snap.columns.addedForte[i],
                    snap.systemSliderCount[i],
                    snap.columns.systemLock[i],
                    snap.systemColorName[i],
                    snap.systemSide[i]
                  );
                }
              }
            }
          }
          if (
            (modelID === 1 ||
              modelID === 4 ||
              modelID === 2 ||
              modelID === 8 ||
              modelID === 13 ||
              modelID === 15 ||
              modelID === 14) &&
            snap.columns.addedSubSystem[24 + i] === true &&
            snap.subSystemWidth[24 + i] > 0 &&
            snap.length.depth >
              (modelID === 1 || modelID === 4
                ? 3500
                : modelID === 2 || modelID === 8
                ? 4000
                : 4500)
          ) {
            if (
              !snap.columns.systemId[24 + i] &&
              snap.subSystemWidth[24 + i] > 4000
            ) {
              Add(
                snap.columns.systemId[24 + i],
                snap.subSystemWidth[24 + i] / 2,
                snap.subSystemHeight[24 + i],
                snap.columns.systemDirection[24 + i],
                snap.columns.addedForte[24 + i],
                snap.systemSliderCount[24 + i],
                snap.columns.systemLock[24 + i],
                snap.systemColorName[24 + i],
                snap.systemSide[24 + i]
              );
              Add(
                snap.columns.systemId[24 + i],
                snap.subSystemWidth[24 + i] / 2,
                snap.subSystemHeight[24 + i],
                snap.columns.systemDirection[24 + i + 8],
                snap.columns.addedForte[24 + i],
                snap.systemSliderCount[24 + i],
                snap.columns.systemLock[24 + i],
                snap.systemColorName[24 + i],
                snap.systemSide[24 + i]
              );
            } else {
              Add(
                snap.columns.systemId[24 + i],
                snap.subSystemWidth[24 + i],
                snap.subSystemHeight[24 + i],
                snap.columns.systemDirection[24 + i],
                snap.columns.addedForte[24 + i],
                snap.systemSliderCount[24 + i],
                snap.columns.systemLock[24 + i],
                snap.systemColorName[24 + i],
                snap.systemSide[24 + i]
              );
            }
          }
        }
      }

      const all_subsystems = [];
      const render_cnt = 1;
      const sections = calcSection(
        modelID,
        snap.length.width,
        snap.length.depth,
        snap.systemVisible
      );

      if (snap.isModel) {
        const benefit = getBenefit(title[modelID], product_prices);
        const install = getInstall(title[modelID], product_prices);
        if (!secondHeight[modelID]) {
          all_subsystems.push({
            // product_id: getProductId(title[modelID]),
            product_id: products.filter(
              (product) =>
                product.title.toLowerCase() === title[modelID].toLowerCase()
            )[0].id,
            model: title[modelID],
            width: snap.length.width,
            depth: snap.length.depth,
            front_height: snap.length.height1,
            back_height: secondHeight[modelID]
              ? snap.length.height2
              : snap.length.height1,
            structure_color: snap.structure.colorName,
            section: sections,
            benefit: benefit,
            install: install,
          });
        } else {
          if (modelID === 6 || modelID === 10) {
            all_subsystems.push({
              // product_id: getProductId(title[modelID]),
              product_id: products.filter(
                (product) =>
                  product.title.toLowerCase() === title[modelID].toLowerCase()
              )[0].id,
              model: title[modelID],
              width: snap.length.width,
              depth: snap.length.depth,
              front_height: snap.length.height1,
              back_height: secondHeight[modelID]
                ? snap.length.height2
                : snap.length.height1,
              structure_color: snap.structure.colorName,
              glassType: snap.blades.colorName,
              benefit: benefit,
              install: install,
            });
          } else if (
            modelID === 1 ||
            modelID === 4 ||
            modelID === 13 ||
            modelID === 15 ||
            modelID === 14
          ) {
            all_subsystems.push({
              // product_id: getProductId(title[modelID]),
              product_id: products.filter(
                (product) =>
                  product.title.toLowerCase() === title[modelID].toLowerCase()
              )[0].id,
              model: title[modelID],
              width: snap.length.width,
              depth: snap.length.depth,
              front_height: snap.length.height1,
              back_height: secondHeight[modelID]
                ? snap.length.height2
                : snap.length.height1,
              structure_color: snap.structure.colorName,
              blade_color: snap.blades.colorName,
              benefit: benefit,
              install: install,
            });
          } else if (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 20) {
            all_subsystems.push({
              product_id: products.filter(
                (product) =>
                  product.title.toLowerCase() === title[modelID].toLowerCase()
              )[0].id,
              model: title[modelID],
              width: snap.length.width,
              depth: snap.length.depth,
              front_height: snap.length.height1,
              back_height: secondHeight[modelID]
                ? snap.length.height2
                : snap.length.height1,
              structure_color: snap.structure.colorName,
              curtain_color: snap.systemColorName[23],
              benefit: benefit,
              install: install,
            });
          } else {
            all_subsystems.push({
              // product_id: getProductId(title[modelID]),
              product_id: products.filter(
                (product) =>
                  product.title.toLowerCase() === title[modelID].toLowerCase()
              )[0].id,
              model: title[modelID],
              width: snap.length.width,
              depth: snap.length.depth,
              front_height: snap.length.height1,
              back_height: secondHeight[modelID]
                ? snap.length.height2
                : snap.length.height1,
              structure_color: snap.structure.colorName,
              benefit: benefit,
              install: install,
            });
          }
        }
        for (const [key, sub] of Object.entries(hash_list)) {
          const benefit = getBenefit(subsystems[sub.systemID].title, product_prices);
          const install = getInstall(subsystems[sub.systemID].title, product_prices);
          const benefit_f = getBenefit("Forte", product_prices);
          const install_f = getInstall("Forte", product_prices);
          if (sub.systemID === 9) {
            all_subsystems.push({
              // product_id: getProductId(subsystems[sub.systemID].title),
              product_id: products.filter(
                (product) =>
                  product.title.toLowerCase() ===
                  subsystems[sub.systemID].title.toLowerCase()
              )[0].id,
              model: subsystems[sub.systemID].title,
              width: sub.width,
              height: sub.height,
              count: sub.count / render_cnt,
              color: sub.color,
              benefit: benefit,
              install: install,
            });
          } else if (sub.systemID === 2) {
            all_subsystems.push({
              // product_id: getProductId(subsystems[sub.systemID].title),
              product_id: products.filter(
                (product) =>
                  product.title.toLowerCase() ===
                  subsystems[sub.systemID].title.toLowerCase()
              )[0].id,
              model: subsystems[sub.systemID].title,
              width: !sub.systemID && sub.width > 4000 ? sub.width : sub.width,
              height: sub.height,
              count:
                (!sub.systemID && sub.width > 4000
                  ? sub.count * 2
                  : sub.count) / render_cnt,
              slide: !sub.systemID
                ? !sub.direction
                  ? 3
                  : 2
                : sub.slider_count,
              opening: openings[sub.direction],
              overforte: sub.extra_forte,
              key: sub.has_key,
              benefit: benefit,
              install: install,
            });
            if (sub.extra_forte) {
              all_subsystems.push({
                // product_id: getProductId('Forte'),
                product_id: products.filter(
                  (product) => product.title.toLowerCase() === "forte"
                )[0].id,
                model: "FORTE",
                width: OverForte(sub.width, sub.height)
                  ? sub.width / 2
                  : sub.width,
                height: sub.height,
                count:
                  (OverForte(sub.width, sub.height)
                    ? sub.count * 2
                    : sub.count) / render_cnt,
                color: snap.forteColorName,
                benefit: benefit_f,
                install: install_f,
              });
            }
          } else if (sub.systemID === 6) {
            all_subsystems.push({
              // product_id: getProductId('Forte'),
              product_id: products.filter(
                (product) => product.title.toLowerCase() === "forte"
              )[0].id,
              model: "FORTE",
              width: OverForte(sub.width, sub.height)
                ? sub.width / 2
                : sub.width,
              height: sub.height,
              count:
                (OverForte(sub.width, sub.height) ? sub.count * 2 : sub.count) /
                render_cnt,
              color: snap.forteColorName,
              benefit: benefit_f,
              install: install_f,
            });
          } else if (sub.systemID === 8) {
            all_subsystems.push({
              product_id: products.filter(
                (product) =>
                  product.title.toLowerCase() ===
                  subsystems[sub.systemID].title.toLowerCase()
              )[0].id,
              model: subsystems[sub.systemID].title,
              width: sub.width,
              height: sub.height,
              count: sub.count / render_cnt,
              inout: sub.inout == 0 ? "Out" : "In",
              opening: openings[sub.direction],
              benefit: benefit,
              install: install,
            });
          } else {
            all_subsystems.push({
              // product_id: getProductId(subsystems[sub.systemID].title),
              product_id: products.filter(
                (product) =>
                  product.title.toLowerCase() ===
                  subsystems[sub.systemID].title.toLowerCase()
              )[0].id,
              model: subsystems[sub.systemID].title,
              width:
                !sub.systemID && sub.width > 4000
                  ? (sub.width - 50) / 2
                  : sub.width,
              height: sub.height,
              count:
                (!sub.systemID && sub.width > 4000
                  ? sub.count * 2
                  : sub.count) / render_cnt,
              slide: !sub.systemID
                ? !sub.direction
                  ? 3
                  : 2
                : sub.slider_count,
              opening: openings[sub.direction],
              overforte: sub.extra_forte,
              benefit: benefit,
              install: install,
            });
            if (sub.extra_forte) {
              all_subsystems.push({
                // product_id: getProductId('Forte'),
                product_id: products.filter(
                  (product) => product.title.toLowerCase() === "forte"
                )[0].id,
                model: "FORTE",
                width: OverForte(sub.width, sub.height)
                  ? sub.width / 2
                  : sub.width,
                height: sub.height,
                count:
                  (OverForte(sub.width, sub.height)
                    ? sub.count * 2
                    : sub.count) / render_cnt,
                color: snap.forteColorName,
                benefit: benefit_f,
                install: install_f,
              });
            }
          }
        }
        if (
          modelID > 0 &&
          secondHeight[modelID] &&
          ((snap.columns.addedSubSystem[0] &&
            snap.walls.added[modelID === 6 ? 1 : 3] === false) ||
            (snap.columns.addedSubSystem[1] &&
              snap.walls.added[modelID === 6 ? 3 : 1] === false))
        ) {
          all_subsystems.push({
            model: "TRIANGLE",
            width: snap.length.depth,
            height: snap.length.height2 - snap.length.height1,
            count:
              snap.columns.addedSubSystem[0] &&
              snap.walls.added[modelID === 6 ? 1 : 3] === false &&
              snap.columns.addedSubSystem[1] &&
              snap.walls.added[modelID === 6 ? 3 : 1] === false
                ? 2
                : 1,
          });
        }
      } else {
        const benefit = getBenefit(subsystems[systemID].title, product_prices);
        const install = getInstall(subsystems[systemID].title, product_prices);
        all_subsystems.push({
          // product_id: getProductId(subsystems[systemID].title),
          product_id: products.filter(
            (product) =>
              product.title.toLowerCase() === subsystems[systemID].title
          )[0].id,
          model: subsystems[systemID].title,
          width: snap.length.width,
          height: snap.length.depth,
          structure_color: snap.structure.colorName,
          slide: !systemID
            ? !snap.columns.systemDirection[0]
              ? 3
              : 2
            : snap.systemSliderCount[0],
          opening: openings[snap.columns.systemDirection[0]],
          benefit: benefit,
          install: install,
        });
      }

      const _optionals = [];
      optionals.map((optional, index) => {
        if (snap.optionals[index] && snap.isModel) {
          if (optional.title.toLowerCase().includes("perimeter")) {
            _optionals.push({
              name: optional.title,
              iscount: snap.optionals[index],
              count: snap.optionals[index]
                ? parseInt(snap.optionalCount[index])
                : 0,
              length: (snap.length.width + snap.length.depth) * 2,
            });
          } else {
            _optionals.push({
              name: optional.title,
              iscount: snap.optionals[index],
              count: snap.optionals[index]
                ? parseInt(snap.optionalCount[index])
                : 0,
            });
          }
        }
      });

      const _user = {
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        embed: user.embed_key,
        benefit: user.benefit,
        discount: user.discount,
        shipping_price: user.shipping_price,
        campaign: user.campaign_per_product,
        extra_formula: user.extra_benefit,
      };

      const data1 = {
        optional: _optionals,
        products: all_subsystems,
        action: "Cart",
        modelname: title[modelID],
        width: snap.length.width,
        depth: snap.length.depth,
        front_height: snap.length.height1,
        back_height: secondHeight[modelID]
          ? snap.length.height2
          : snap.length.height1,
        user: _user,
        isTexture: snap.isTexture ? 1 : 0,
        install:
          snap.install == 1
            ? "With"
            : user_install == 2
            ? "Without But Calculated"
            : "Without",
      };
      axios
        .post(user.price_link || "https://schildr.com/price", data1, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          total_optioanl_price = response.data.total_optionals_price;
          total_product_price = response.data.total_products_price;
          total_price = response.data.total_price;
          const price_data = {
            op: total_optioanl_price,
            tp: total_price,
          };
          setPriceData((prevState) => {
            // Get a copy of the array using the spread operator
            const newArray = [...prevState];

            // Modify the ith element of the copied array
            newArray[index] = price_data;

            // Return the updated state object
            return newArray;
          });
        })
        .catch((err) => {
          toastError(err.message);
        });
    });
  };

  return (
    <div>
      <Box
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        className="cart"
      >
        <Opeartion
          open={drawer}
          handler={setDrawer}
          side="right"
          width={isMobile ? 20 : 60}
          isCart={true}
        >
          <div
            style={{
              display: "flex",
              position: "relative",
              marginBottom: "20px",
            }}
          >
            <Typography variant="h5">{t('CART')}</Typography>
            <CloseIcon
              sx={{
                float: "right",
                position: "absolute",
                right: "0px",
                top: "5px",
                cursor: "pointer",
              }}
              fontSize="medium"
              onClick={(e) => {
                e.preventDefault();
                setDrawer(false);
              }}
            />
          </div>

          {cartItems.length === 0 ? (
            <Typography variant="body1">{t('Your cart is empty.')}</Typography>
          ) : (
            <>
              {cartItems.map((item, index) => (
                <ListItem
                  key={item.id}
                  className={classes.listItem}
                  // onClick={e=>{
                  //   navigate('/baskets');
                  // }}
                >
                  <img
                    src={SERVER_URL + getProductImageByName(item.model_name)}
                    alt={item.model_name}
                    className={classes.image}
                  />
                  <div style={{ fontSize: "10px" }}>
                    <Typography classsName={classes.info}>
                      {item.model_name}
                    </Typography>
                    <Typography classsName={classes.info}>
                      {priceData[index]?.tp > 0
                        ? "$" + FormatNumber(priceData[index]?.tp)
                        : t("Calculating...")}
                    </Typography>
                    <Typography
                      classsName={classes.info}
                      sx={{ cursor: "pointer", color: "gray" }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/carts");
                      }}
                    >
                      <u>{t('View Detail')}</u>
                    </Typography>
                  </div>
                  {/* <Typography variant="body1">{item.quantity} x ${item.price}</Typography> */}
                  <DeleteOutlinedIcon
                    fontSize="small"
                    className={classes.closeicon}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!mode) removeBasket(item.id);
                      else removeBasketEmbed(index);
                    }}
                  />
                </ListItem>
              ))}

              <Typography
                sx={{
                  position: "absolute",
                  bottom: "70px",
                }}
              >
                {t('Total')}: ${" "}
                {FormatNumber(
                  priceData.reduce((sum, partial) => sum + partial.tp, 0)
                )}
              </Typography>

              <Button
                sx={{
                  width: widthByPercent(isMobile ? 20 : 60) - 40,
                  bgcolor: brandColor,
                  borderRadius: 0.5,
                  // ml: 1.25,
                  "&:hover": { bgcolor: Darken(brandColor) },
                  color: "white",
                  position: "absolute",
                  bottom: "20px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenOrderForm(true);
                }}
              >
                {/* Order Now */}
                {t('Get Estimate')}
              </Button>
            </>
          )}
        </Opeartion>

        <IconButton aria-label="cart" onClick={handleClick}>
          <Badge
            badgeContent={cartItems.length}
            color="secondary"
            sx={{
              "& .MuiBadge-badge": {
                color: "white",
                backgroundColor: brandColor,
              },
            }}
          >
            <ShoppingCartOutlinedIcon
              className={props.color === "black" ? "" : "order-basket"}
              onClick={(e) => {
                e.preventDefault();
                handleBasketOpen();
              }}
            />
          </Badge>
        </IconButton>
      </Box>

      <Dialog open={openOrderForm} onClose={handleClose} fullWidth>
        <DialogTitle>{/* Order Now */}{t('Get Estimate')}</DialogTitle>
        <DialogContent>
          <ValidationForm handleSend={handleSend} brandColor={brandColor} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default Cart;
