import React, { useRef  } from "react";
import { useFrame } from '@react-three/fiber'
import Preloader from './Preloader'
import { Html } from "@react-three/drei";

export default function Loader3d() {
    const box = useRef()
    useFrame(() => box.current && void (box.current.rotation.y += 0.05))
    return (
        <group ref={box}>
            <Preloader />
        </group>
    );
}

export function Loader3dView() {
    const box = useRef()
    useFrame(() => box.current && void (box.current.rotation.y += 0.05))
    return (
        <>
            <group ref={box}>
                <Preloader />
            </group>
        </>
    );
}