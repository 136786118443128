/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { subsystems } from '../utils/constant';
import { useSnapshot } from 'valtio';
import state from '../state';
import * as THREE from 'three'
import { handleHeight } from '../utils/constant';
import classy from '../assets/models/classy.glb';
import { ClassyGlassCount } from '../models/DivCount';
import { GetHandleColor } from './GetHandleColor';
import ClassyHandle from './ClassyHandle';

const thickness = 45;
const xrepeat = 7,
  yrepeat = 2;

export default function Classy(props) {
  const { nodes, materials } = useGLTF(classy)
  const { px, py, width, height, systemID } = props;

  const [opacity, setOpacity] = useState(0.3);
  const [color, setColor] = useState('#a7c7cb');
  const snap = useSnapshot(state);
  const handle_color = GetHandleColor();
  // const handle_height = height >= 2000 ? handleHeight + 130 : height / 2 + 130;
  const handle_height = handleHeight + 130;
  const direction = snap.columns.systemDirection[props.ID];
  const isLeft = direction != 0 ? true : false;
  const dr = isLeft ? 1 : -1;

  const int_width = subsystems[systemID].width;
  const int_height = subsystems[systemID].height;
  const sw = width / int_width;
  const sh = height / int_height;
  const glassWidth = subsystems[systemID].glassWidth;
  // let glassCount = Math.max(2, Math.round((width - 40) / glassWidth, 0));
  let glassCount = Math.max(2, parseInt(width / subsystems[systemID].sliderWidth));
  glassCount = ClassyGlassCount(width, glassCount, props.modelID);
  if (glassCount > 4 && glassCount % 2) glassCount = glassCount + 1;

  if (!snap.isModel && glassCount >= subsystems[systemID].option_limit && glassCount % 2 === 0) state.columns.systemDirection[props.ID] = 2;
  if ((glassCount % 2 || glassCount === 2) && snap.columns.systemDirection[props.ID] === 2) state.columns.systemDirection[props.ID] = 0;
  if (!snap.isModel) state.editingGlassCount = glassCount;
  if (glassCount > 4 && glassCount % 2 === 0) state.columns.systemDirection[props.ID] = 2;
  if (glassCount === 4 && snap.columns.systemDirection[props.ID] === 2) state.columns.systemDirection[props.ID] = 0;
  state.systemSliderCount[props.ID] = glassCount;

  const div_width = (width - 40) / glassCount;

  // state.columns.subSystemCount[props.ID] = glassCount;
  const selected = (snap.editingSubSystem && snap.editingSubSystemID === props.ID);

  const material = new THREE.MeshPhongMaterial({
    color: selected ? '#002853' : '#a7c7cb',
    opacity: opacity,
    transparent: true,
  });

  const texture = useTexture(snap.structure.textureA);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  // texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const list = [];
  var pos = snap.columns.systemPos[props.ID];
  if (snap.columns.systemDirection[props.ID] === 2) pos = Math.max(2.5 / (glassCount - 2), pos);

  if (snap.columns.systemDirection[props.ID] != 2 || glassCount % 2) {
    for (let i = 0; i < glassCount; i = i + 1) {
      const rank = isLeft ? glassCount - i - 1 : i;
      const start_point = rank * 100 / (glassCount - 1);
      const move_point = Math.max(0, Math.min(pos, 100 - 8 / glassCount) - start_point) / 100 * div_width * (glassCount - 1);
      list.push(
        <group key={i} position={[-div_width * i + move_point * dr, 0, thickness * rank]} scale={[div_width / glassWidth, 1, 1]}>
          <mesh geometry={nodes.leftdoor1profile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[0, 1.33, -3.48]} rotation={[-Math.PI, Math.PI, -Math.PI]} scale={1} />
          <mesh geometry={nodes.leftdoor1glass.geometry} material={material} position={[13.26, 1.33, -3.47]} rotation={[-Math.PI, 0, -Math.PI]} scale={1} />
        </group>
      )
    }
  }
  else {
    const cnt = glassCount / 2;
    for (let i = 0; i < cnt; i = i + 1) {
      const rank = isLeft ? cnt - i - 1 : i;
      const start_point = rank * 100 / (cnt - 1);
      const move_point = Math.max(0, Math.min(pos, 100 - 6 / cnt) - start_point) / 100 * div_width * (cnt - 1);
      list.push(
        <group key={i} position={[-div_width * i + move_point * dr, 0, thickness * rank]} scale={[div_width / glassWidth, 1, 1]}>
          <mesh geometry={nodes.leftdoor1profile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[0, 1.33, -3.48]} rotation={[-Math.PI, Math.PI, -Math.PI]} scale={1} />
          <mesh geometry={nodes.leftdoor1glass.geometry} material={material} position={[13.26, 1.33, -3.47]} rotation={[-Math.PI, 0, -Math.PI]} scale={1} />
        </group>
      )
    }

    for (let i = 0; i < cnt; i = i + 1) {
      const rank = isLeft ? i : cnt - i - 1;
      const start_point = rank * 100 / (cnt - 1);
      const move_point = Math.max(0, Math.min(pos, 100 - 6 / cnt) - start_point) / 100 * div_width * (cnt - 1);
      list.push(
        <group key={i + cnt} position={[-div_width * (cnt + i) - move_point * dr, 0, thickness * rank]} scale={[div_width / glassWidth, 1, 1]}>
          <mesh geometry={nodes.leftdoor1profile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[0, 1.33, -3.48]} rotation={[-Math.PI, 0, -Math.PI]} scale={1} />
          <mesh geometry={nodes.leftdoor1glass.geometry} material={material} position={[-13.26, 1.33, -3.47]} rotation={[-Math.PI, Math.PI, -Math.PI]} scale={1} />
        </group>
      )
    }
  }

  const handle_list = [];

  const silver_material = new THREE.MeshStandardMaterial({
    metalness: 1,   // between 0 and 1
    roughness: 0.5, // between 0 and 1
  });

  if (snap.columns.systemDirection[props.ID] != 2) {
    handle_list.push(
      <React.Fragment key={0}>
        {/* <mesh material-color={handle_color} geometry={nodes.rightdoor1handle.geometry} material={silver_material} position={[dr * (width / 2 - 40), handle_height, glassCount * 20]} rotation={[0, -Math.PI / 2, 0]} scale={1} /> */}
        <ClassyHandle materialColor={handle_color} position={[dr * (width / 2 - 30), handle_height, glassCount * thickness - 20]} scale={1} />
        <ClassyHandle materialColor={handle_color} position={[dr * (width / 2 - 30), handle_height, glassCount * thickness - 50 - 30]} scale={1} />
        <mesh material-color={handle_color} geometry={nodes.rightdoor1handle.geometry} material={silver_material} position={[dr * (-width / 2 + 20 + Math.min(pos, 100 - 8 / glassCount) / 100 * div_width * (glassCount - 1)), handle_height, 40]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
        <ClassyHandle materialColor={handle_color} position={[dr * (-width / 2 + 20 + Math.min(pos, 100 - 8 / glassCount) / 100 * div_width * (glassCount - 1)), handle_height, -30]} scale={1} />
        {/* <ClassyHandle materialColor={handle_color} position={[dr * (width / 2 - 40), handle_height, 0]} scale={1} /> */}
      </React.Fragment>
    )
  }
  else {
    if (isLeft) {
      handle_list.push(
        <React.Fragment key={0}>
          <ClassyHandle materialColor={handle_color} position={[width / 2 - 20, handle_height, glassCount / 2 * thickness - 25]} scale={1} />
          <ClassyHandle materialColor={handle_color} position={[width / 2 - 20, handle_height, glassCount / 2 * thickness - 50 - 30]} scale={1} />
          <ClassyHandle materialColor={handle_color} position={[-width / 2 + 20, handle_height, glassCount / 2 * thickness - 25]} scale={1} />
          <ClassyHandle materialColor={handle_color} position={[-width / 2 + 20, handle_height, glassCount / 2 * thickness - 50 - 30]} scale={1} />
          <ClassyHandle materialColor={handle_color} position={[Math.min(pos, 100 - 6 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1) + 10, handle_height, -28]} scale={1} />

          <mesh material-color={handle_color} geometry={nodes.rightdoor1handle.geometry} material={silver_material} position={[Math.min(pos, 100 - 6 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1) + 15, handle_height, 40]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
          <mesh material-color={handle_color} geometry={nodes.rightdoor1handle.geometry} material={silver_material} position={[-Math.min(pos, 100 - 6 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1) - 15, handle_height, 40]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
          <ClassyHandle materialColor={handle_color} position={[-Math.min(pos, 100 - 6 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1) - 10, handle_height, -30]} scale={1} />
        </React.Fragment>
      )
    }
    else {
      handle_list.push(
        <React.Fragment key={0}>
          <mesh material-color={handle_color} geometry={nodes.rightdoor1handle.geometry} material={silver_material} position={[20, handle_height, glassCount / 2 * thickness]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
          <mesh material-color={handle_color} geometry={nodes.rightdoor1handle.geometry} material={silver_material} position={[-20, handle_height, glassCount / 2 * thickness]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
          <mesh material-color={handle_color} geometry={nodes.rightdoor1handle.geometry} material={silver_material} position={[width / 2 - 20 - Math.min(pos, 100 - 6 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1), handle_height, 40]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
          <mesh material-color={handle_color} geometry={nodes.rightdoor1handle.geometry} material={silver_material} position={[-width / 2 + 40 + Math.min(pos, 100 - 6 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1), handle_height, 40]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
        </React.Fragment>
      )
    }
  }

  state.subSystemWidth[props.ID] = width;
  state.subSystemHeight[props.ID] = height;
  const st = (glassCount <= 4 ? glassCount : glassCount / 2) / 4;
  return (
    <group
      rotation={props.rotation}
      scale={props.scale * 0.1}
      // position={[px * unit, py * unit, pz * unit]}
      // position={[px + width * unit / 2, pz, py]}
      position={[px, 0, py]}
      onPointerOver={
        e => {
          setOpacity(0.5);
          setColor('#002853');
          document.body.style.cursor = 'pointer';
        }
      }
      onPointerOut={
        e => {
          setOpacity(0.3);
          setColor('#a7c7cb');
          document.body.style.cursor = 'auto';
        }
      }
      onClick={
        e => {
          e.stopPropagation();
          console.log("click")
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID;
          state.editingSystemType = props.systemType;
          state.editingGlassCount = glassCount;
        }
      }

    >
      <group rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        position={[-90, width / 2, 0]}
      >
        <group scale={[1, 1, snap.isModel ? 1 : (glassCount <= 4 ? glassCount : glassCount / 2) / 4]} position={[0, 0, snap.isModel ? 0 : 60 * st]}>
          <mesh
            geometry={nodes.topprofile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[0, height, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={[sw, 1, 1]}
            material-color={snap.structure.color}
          />
          <mesh geometry={nodes.bottomprofile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[sw, 1, 1]}
            material-color={snap.structure.color}
          />
          <mesh geometry={nodes.leftprofile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-width / 2, height / 2, 0]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={[sh, 1, 1]}
            material-color={snap.structure.color}
          />
          <mesh geometry={nodes.rightprofile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[width / 2, height / 2, 0]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={[sh, 1, 1]}
            material-color={snap.structure.color}
          />
        </group>
        <group position={[0, 0, snap.isModel ? -65 : 0]}>
          {handle_list}
          <group position={[width / 2 - div_width / 2 - 20, height / 2, 0]} scale={[1, sh, 1]}>
            {list}
          </group>
        </group>
      </group>
    </group>
  )
}

// useGLTF.preload('models/classy8.glb')
