import { useSnapshot } from "valtio";
import state from "../state";
import { lengths } from "../utils/constant";

function Calc_delta(modelID) {
    const snap = useSnapshot(state);
    if (!modelID) return 0;
    if (modelID > 20) {
        return lengths[modelID - 20].column_depth;
    }
    const width = snap.length.width;
    const column_count = width > lengths[modelID].mid_length_limit[1] ? 4 : (width > lengths[modelID].mid_length_limit[0] ? 3 : 2);
    const rwidth = column_count * lengths[modelID].column_width;
    const delta = rwidth / (column_count - 1);
    return delta;
}

export function TopDivCount(width, modelID) {
    if (modelID === 3 || modelID === 5) {
        if (width > 8000) return 3 * TopDivCount(width / 3, modelID);
        if (width > 4000) return 2 * TopDivCount(width / 2, modelID);
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 4700) return 4;
        if (width <= 5500) return 5;
        if (width <= 6040) return 6;
    }
    if (modelID === 2) {
        if (width > 6000) return TopDivCount(width / 2, modelID);
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 4700) return 4;
        if (width <= 5500) return 5;
        if (width <= 6000) return 6;
        return 6;
    }
    if (modelID === 8) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 5000) return 4;
        if (width <= 6000) return 6;
        return 6;
    }
    if (modelID === 6 || modelID === 10) {
        if (width > 8000) return 3 * TopDivCount(width / 3, modelID);
        if (width > 4000) return 2 * TopDivCount(width / 2, modelID);
        if (width <= 3300) return 3;
        return 4;
    }
    if (modelID === 7) {
        if (width < 3805) return 2;
        if (width < 5025) return 3;
        if (width < 6250) return 4;
        if (width < 7475) return 5;
        if (width < 8700) return 6;
        if (width < 9925) return 7;
        if (width < 11150) return 8;
        return 9;
    }
}

export function TopDepthDivCount(depth, modelID) {
    if (modelID === 2 || modelID === 6 || modelID === 8 || modelID === 10) {
        if (depth <= 3000) return 1;
        return 2;
    }
    if (modelID === 7) {
        if (depth < 2700) return 1;
        if (depth <= 3000) return 2;
        return 3;
    }
}

export function MinimaGlassCount(dwidth, glassCount, modelID) {
    const delta = Calc_delta(modelID);
    const width = dwidth + delta;

    if (modelID === 1 || modelID === 4 || modelID === 13 || modelID === 15 || modelID === 14) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 4700) return 4;
        if (width <= 5500) return 5;
        if (width <= 6040) return 6;
        return glassCount;
    }
    if (modelID === 2) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 4700) return 4;
        if (width <= 5500) return 5;
        if (width <= 6000) return 6;
        return glassCount;
    }
    if (width <= 2700) return 2;
    if (width <= 3700) return 3;
    if (width <= 4700) return 4;
    if (width <= 5500) return 5;
    if (width <= 6040) return 6;

    return glassCount;
}

export function FixedGlassCount(dwidth, glassCount, modelID) {
    const delta = Calc_delta(modelID);
    const width = dwidth + delta;
    if (modelID === 1 || modelID === 4 || modelID === 13 || modelID === 15 || modelID === 14) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 4700) return 4;
        if (width <= 5500) return 5;
        if (width <= 6040) return 6;
        return glassCount;
    }
    if (modelID === 2) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 4700) return 4;
        if (width <= 5500) return 5;
        if (width <= 6000) return 6;
        return glassCount;
    }
    if (modelID === 8) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 5000) return 4;
        if (width <= 6000) return 6;
        return glassCount;
    }
    if (modelID === 6 || modelID === 10) {
        if (width <= 3300) return 3;
        if (width <= 4000) return 4;
        return glassCount;
    }
    if (modelID === 7) {
        if (width <= 3000) return 2;
        if (width <= 4000) return 3;
        return glassCount;
    }

    if (width <= 2700) return 2;
    if (width <= 3700) return 3;
    if (width <= 4700) return 4;
    if (width <= 5500) return 5;
    if (width <= 6040) return 6;
    return glassCount;
}


export function ClassyGlassCount(dwidth, glassCount, modelID) {
    const delta = Calc_delta(modelID);
    const width = dwidth + delta;

    if (modelID === 0) {
        return Math.max(parseInt((dwidth - 1) / 1500) + 1, 2);
    }

    if (modelID === 1 || modelID === 4 || modelID === 13 || modelID === 15 || modelID === 14) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 5000) return 4;
        if (width <= 6040) return 6;
        return glassCount;
    }
    if (modelID === 2) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 5000) return 5;
        if (width <= 6000) return 6;
        return glassCount;
    }
    if (modelID === 8) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 5000) return 4;
        if (width <= 6000) return 6;
        return glassCount;
    }
    if (modelID === 6 || modelID === 10 || modelID === 30 || modelID === 26) {
        if (width <= 3300) return 3;
        if (width <= 4000) return 4;
        return 4;
    }
    if (modelID === 7 || modelID === 27) {
        if (width <= 3000) return 2;
        if (width < 4000) return 3;
        return 4;
    }
    if (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 29 || modelID === 31 || modelID === 20) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 5000) return 4;
        return 6;
    }
    if (width <= 2700) return 2;
    if (width <= 3700) return 3;
    if (width <= 5000) return 5;
    if (width <= 6040) return 6;
    return glassCount;
}

export function ZionGlassCount(dwidth, glassCount, modelID) {
    const delta = Calc_delta(modelID);
    const width = dwidth + delta;

    if (modelID === 0) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 5000) return 4;
        if (width <= 6040) return 6;
        return glassCount;
    }

    if (modelID === 1 || modelID === 4 || modelID === 13 || modelID === 15 || modelID === 14) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 5000) return 4;
        if (width <= 6040) return 6;
        return glassCount;
    }
    if (modelID === 2) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 5000) return 4;
        if (width <= 6000) return 6;
        return glassCount;
    }
    if (modelID === 8) {
        if (width <= 3040) return 2;
        if (width <= 4040) return 3;
        if (width <= 5000) return 4;
        if (width <= 6000) return 6;
        return glassCount;
    }
    if (modelID === 6 || modelID === 10 || modelID === 26) {
        if (width <= 2400) return 2;
        if (width <= 3300) return 3;
        if (width <= 4000) return 4;
        return glassCount;
    }
    if (width <= 2700) return 2;
    if (width <= 3700) return 3;
    if (width <= 5000) return 4;
    if (width <= 6040) return 6;
    return glassCount;
}

export function BifoldGlassCount(dwidth, glassCount, modelID) {
    const delta = Calc_delta(modelID);
    const width = dwidth + delta;

    if (modelID === 0) {
        return Math.max(parseInt((dwidth - 1) / 1200) + 1, 2);
    }

    if (modelID === 1 || modelID === 4 || modelID === 13 || modelID === 15 || modelID === 14) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 5000) return 5;
        if (width <= 6040) return 6;
        return glassCount;
    }
    if (modelID === 2) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 5000) return 5;
        if (width <= 6000) return 6;
        return glassCount;
    }
    if (modelID === 8) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 5000) return 4;
        if (width <= 6000) return 6;
        return glassCount;
    }
    if (modelID === 6 || modelID === 10) {
        if (width <= 3300) return 3;
        if (width <= 4000) return 4;
        return glassCount;
    }
    if (modelID === 7) {
        if (width <= 2400) return 2;
        if (width <= 3300) return 3;
        if (width <= 4000) return 4;
        return glassCount;
    }
    if (width <= 2700) return 2;
    if (width <= 3700) return 3;
    if (width <= 5000) return 5;
    if (width <= 6040) return 6;
    return glassCount;
}

export function LiftGlassCount(dwidth, glassCount, modelID) {
    const delta = Calc_delta(modelID);
    const width = dwidth + delta;

    if (modelID === 0) {
        return Math.max(parseInt((dwidth - 1) / 2500) + 1, 2);
    }

    if (modelID === 8 || modelID === 28) {
        if (width <= 4300) return 2;
        return 4;
    }
    if (modelID === 6 || modelID === 10) {
        if (width <= 3300) return 2;
        if (width <= 4000) return 3;
        return 4;
    }
    if (modelID === 26 || modelID === 30) {
        if (width <= 4000) return 2;
        return 4;
    }
    if (modelID === 7) {
        if (width <= 3000) return 2;
        if (width <= 4000) return 3;
        return glassCount;
    }
    if (modelID === 27) {
        if (width <= 4000) return 2;
        return 4;
    }
    if (width <= 3300) return 2;
    if (width <= 4000) return 3;
    return parseInt(width / 1300);
}

export function GuillotineGlassCount(dwidth, modelID) {
    const delta = Calc_delta(modelID);
    const width = dwidth + delta;
    if (modelID === 1 || modelID === 4 || modelID === 13 || modelID === 15 || modelID === 14) {
        if (width <= 4300) return 1;
        return 2;
    }
    if (modelID === 6 || modelID === 10) {
        if (width <= 4000) return 1;
        return 2;
    }
    if (width <= 4000) return 1;
    return 2;
}

export function GlassCount(width, systemID, modelID, glassCount) {
    switch (systemID) {
        case 0:
            return 3;
        case 1:
            return ClassyGlassCount(width, 1, modelID + 20, glassCount);
        case 2:
            return MinimaGlassCount(width, 1, modelID + 20, glassCount);
        case 3:
            return FixedGlassCount(width, 1, modelID + 20, glassCount);
        case 4:
            return FixedGlassCount(width, 1, modelID + 20, glassCount);
        case 5:
            return LiftGlassCount(width, 1, modelID + 20, glassCount);
        case 6:
            return 3;
        case 7:
            return ZionGlassCount(width, 1, modelID + 20, glassCount);
        case 8:
            return BifoldGlassCount(width, 1, modelID + 20, glassCount);
        default:
            return 3;
    }
}