import React, { useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import AppContext from 'context/AppContext';
import {ConfigContext} from 'context/config';
import { logo, logourl, SERVER_URL } from '../utils/constant.js';
import { useTranslation } from "react-i18next";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: 568, xs: 350 },
    bgcolor: 'background.paper',
    borderRadius: .5,
    boxShadow: 24,
    p: 4,
};

const ModelLog = (props) => {
    const navigate = useNavigate();
    const { key } = useParams();
    const [open, setOpen] = useState(false);
    const {mode, showprice} = useContext(AppContext);
    const {isDark} = useContext(ConfigContext);
    const { t, i18n } = useTranslation();
    const handleClose = () => setOpen(false);
    const handleOpen = () => {
        if (mode === 0 || (mode === 1 && showprice === 1)) {
            setOpen(true);
        }
    }
    const goHome = (flag) => {
        setOpen(false);
        if (flag) {
            if (mode === 0) {
                navigate('/');
            }
            else {
                navigate('/allproducts/'+key);
            }
        }
    }

    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : JSON.parse(localStorage.getItem('embed_user'));
    const company_logo = mode === 0 ? (SERVER_URL + user.company_logo) : (SERVER_URL + localStorage.getItem('company_logo'));
    const logo_option = mode === 0 ? user.logo_option : localStorage.getItem('logo_option');

    return (
        <>
            <Box >
                {logo_option !==null && logo_option !== 'null'? <Box onClick={handleOpen} component='img' src={logo_option === '0'||logo_option==='2'?company_logo:(isDark || props.pos !== 'home' ? logo:logourl)} sx={{ position: { md: 'absolute', xs: 'fixed' }, width: { md: 147, xs: 110 }, top: { md: props.pos==="home"?35:60, xs: 20 }, left: { md: 40, xs: 15 }, zIndex: 999, cursor: 'pointer' }} />:null}
                {logo_option === '2' && mode === 0?<Box onClick={handleOpen} component='img' src={logo} sx={{ position: { md: 'absolute', xs: 'fixed' }, width: { md: 147, xs: 110 }, bottom: { md: 30, xs: 15 }, left: { md: 40, xs: 15 }, zIndex: 999, cursor: 'pointer' }}  />:null}
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Stack direction='row' justifyContent='space-between' alignItems='flex-start'>
                        <Typography variant="h6" component="h2" sx={{ fontSize: 30, }}>
                            {t('DO YOU WANT TO CLOSE THE CONFIGURATOR?')}
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography sx={{ my: 2.5, fontSize: 15, }}>
                        {t('All changes will not be saved!')}
                    </Typography>
                    <Stack direction='row'>
                        <Button onClick={() => goHome(true)} variant="contained" color="primary" sx={{ color: '#fff', width: '50%', borderRadius: .5, bgcolor: '#7596AE', mr: 1.25 }}>
                            {t('Yes')}
                        </Button>
                        <Button onClick={() => goHome(false)} variant="contained" color="error" sx={{ color: '#fff', width: '50%', borderRadius: .5 }}>
                            {t('No')}
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default ModelLog;