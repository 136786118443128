import React, { useState, useContext, useEffect } from "react";

import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { useMediaQuery } from "@mui/material";

import { ReactComponent as Heater } from '../../assets/img/svg/heater.svg'
import { ReactComponent as Lightning } from '../../assets/img/svg/lighting.svg'
import { ReactComponent as Fan } from '../../assets/img/svg/volume.svg'
import { ReactComponent as Wifi } from '../../assets/img/svg/wifi.svg'
import { ReactComponent as Installation } from '../../assets/img/svg/install.svg'
import poly from '../../assets/img/svg/poly.png'
import { useSnapshot } from "valtio";
import state from "../../state";
import AppContext from '../../context/AppContext'
import { API_URL, SERVER_URL, axiosInstanceAuthorized, title } from "../../utils/constant";
import axios from 'axios';
import { toast } from 'react-toastify';
import Popover from '@mui/material/Popover';
import CloseIcon from '@mui/icons-material/Close';
import { LedCount } from 'models/LedCount'
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";

const Icon = {
  heater: <Heater className="svg-1" />,
  fan: <Fan className="svg-2" />,
  lightning: <Lightning className="svg-1" />,
  led: <Lightning className="svg-1" />,
  wifi: <Wifi className="svg-2" />,
  poly: <img className="polyurethan" src={poly} alt="" />,
  install: <Installation className="svg-2" />
}

const OptionalIcon = ({ str }) => {
  switch (str) {
    case 'Light':
      return Icon['lightning'];
    case 'Led Light UL':
      return Icon['lightning'];
    case 'Perimeter Light UL':
      return Icon['led'];
    case 'Fan Profile':
      return Icon['fan'];
    case 'Wifi':
      return Icon['wifi'];
    case 'Heater':
      return Icon['heater'];
    case 'install':
      return Icon['install'];
    default:
      return null;
  }
}

export const ComfortBtn = (props) => {
  const { label, str, status, size, id, info, brandColor, icon } = props;
  const { setFlag } = useContext(AppContext);
  const snap = useSnapshot(state);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const idx = open ? 'simple-popover' : undefined;
  const [body, setBody] = useState('');
  const { t, i18n } = useTranslation();

  const updateStatus = (id, str) => {
    // setFlag(prevFlag=>!prevFlag);
    // props.setTP(0);
    props.setDisabled(false);
    if (str === 'install') {
      if (snap.install) props.setAction('Removed Optional Install');
      else props.setAction("Added Optional Install");
      state.install = !snap.install;
    }
    else {
      if (snap.optionals[id]) props.setAction("Removed Optional " + str);
      else props.setAction("Added Optional " + str);
      if (str === 'Light' || str === 'Led Light UL') state.hasLight = !snap.optionals[id];
      if (str === 'Perimeter Light UL') state.hasLedLight = !snap.optionals[id];
      if (str === 'Fan Profile') state.hasFan = !snap.optionals[id];
      if (str === 'Heater') state.hasHeater = !snap.optionals[id];
      if (str === 'Wifi') state.hasWifi = !snap.optionals[id];
      if ((str === 'Light' || str === 'Led Light UL') && !snap.optionals[id]) {
        state.optionalCount[id] = LedCount(snap.length.width, snap.length.depth, snap.modelID)
        state.optionals[id] = !snap.optionals[id];
      }
      else {
        state.optionalCount[id] = snap.optionals[id] ? 0 : 1;
        state.optionals[id] = !snap.optionals[id];
      }
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, bodytext) => {
    setAnchorEl(event.currentTarget);
    setBody(bodytext);
  };

  return (
    <Button
      direction="row"
      alignitems="center"
      sx={{
        p: (size === 'medium' ? 1.25 : 0.75),
        borderRadius: 1,
        color: status ? '#fff' : '#232323',
        cursor: 'pointer',
        bgcolor: status ? brandColor : '#F6F6F6',
        textTransform: 'capitalize',
        justifyContent: "space-between",
        '&:hover': {
          bgcolor: status ? brandColor : '#F6F6F6',
        },
      }}
      onClick={(event) => { updateStatus(id, str) }}
    >
      <Stack
        direction="row"
        alignitems="center"
        justifyContent="space-between"
        className={status ? "selected" : ""}
      >
        {str === 'install' ?
          <OptionalIcon str={str} /> :
          (icon !== null ? <img src={SERVER_URL + icon} alt="icon" className={status ? 'invert' : ''} style={{ width: '20px', height: '24px' }} /> : null)
        }

        {/*  */}
        <Typography sx={{ ml: 1.25 }}>{size === 'medium' ? t(label) : null}</Typography>
      </Stack>
      {
        status ?
          <CloseIcon sx={{ color: '#fff', height: 20, width: 20 }} /> :
          (size === 'medium' ?
            <Tooltip key={str + idx} title={t(info)} placement="bottom-end">
              <InfoOutlinedIcon aria-describedby={str + idx} sx={{ color: '#A7A7A7', height: 20, width: 20 }}
              // onMouseEnter = {e=>{e.preventDefault(); e.stopPropagation(); handleClick(e, info)}}
              // onMouseLeave = {handleClose}
              />
            </Tooltip>
            :
            <CheckIcon sx={{ color: '#A7A7A7', height: 20, width: 20 }} />
          )
      }
      {size === 'medium' ?
        <Popover
          id={str + idx}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ width: '400px' }}
        >
          <Typography sx={{ p: 2 }}>{t(body)}</Typography>
        </Popover> : null}

    </Button>
  )
}

const ComfortDesign = () => {
  const isMobile = useMediaQuery('(max-width:769px)')
  const snap = useSnapshot(state);
  const { modelID, brandColor } = useContext(AppContext);
  const [optionals, setOptionals] = useState(null);
  const [tp, setTP] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [action, setAction] = useState('');

  const getOptionals = () => {
    axiosInstanceAuthorized
      .get(API_URL + "optional/get")
      .then(response => {
        setOptionals(response.data.optionals);
        return response.data.optionals;
      })
      .catch(err => {
        console.log(err);
        if (err.message === 'Network Error') {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })

  }

  useEffect(() => {
    getOptionals();
  }, [])

  const hasOptional = (list, model) => {

    const product_list = list.toLowerCase().split(',');
    const model_name = model.toLowerCase();
    return product_list.includes(model_name);

  }

  return optionals !== null ? (
    <Stack spacing={0.5}
      className={!isMobile ? 'overview' : ''}
      sx={{ p: 0, pr: { md: 2, xs: 0 }, mb: { md: 0, xs: 2.5 } }}
    >
      {
        optionals.map((one, i) => (hasOptional(one.list, title[modelID]) ?
          <ComfortBtn key={i} {...{ label: one.title, str: one.title, status: snap.optionals[i], id: i, info: one.info, size: 'medium', brandColor: brandColor, icon: one.icon, setTP: setTP, setAction: setAction, setDisabled: setDisabled }} /> : null
        ))
      }
    </Stack >
  ) : null;
}

export default ComfortDesign;