import React, { useEffect } from 'react'
import { useTexture, useProgress } from '@react-three/drei'
import { useSnapshot } from "valtio"
import { lengths, columnFlag, default_forte_pos } from '../../utils/constant';
import state from "../../state"
import { Html } from "@react-three/drei";
import { BsPlusCircle } from "react-icons/bs";
import * as THREE from 'three'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber';
import { wallThickness, wallTopHeight, secondHeight } from '../../utils/constant';
import Wall from '../Wall';
import { TriPart } from './TriPart';
import grandeModel from '../../assets/models/grande.glb'
import TopInfills2 from '../TopInfills2';
import SideInfills3 from '../SideInfills3';
import GetLogo from './GetLogo';
import { TopDepthDivCount, TopDivCount } from '../DivCount';
import ProgressBar from '../../common/Loading/ProgressBar';

const xrepeat = 1;
const yrepeat = 1;

export const CalcRotation = (props) => {
  const { depth, height1, height2 } = props.length;
  const { int_height1, int_height2, thickness, column_depth } = props.int_length;
  const alpha1 = Math.atan(Math.abs(height2 - height1 - thickness) / (depth - column_depth / 2));
  const alpha2 = Math.atan(Math.abs(int_height2 - int_height1 - thickness) / (depth - column_depth / 2));

  if (height1 > height2 - thickness) return - (alpha1 + alpha2);
  return alpha1 - alpha2;
}

export const CalcScale = (props) => {
  const { depth, height1, height2 } = props.length;
  const { int_height1, int_height2, thickness, column_depth } = props.int_length;
  const alpha2 = Math.atan(Math.abs(height2 - height1 - thickness) / (depth - column_depth * 2 / 3));
  const alpha1 = Math.atan(Math.abs(int_height2 - int_height1 - thickness) / (depth - column_depth * 2 / 3));
  return Math.cos(alpha1) / Math.cos(alpha2);
}

export function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export function invertColor(hex) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
}


export function WaterProfile(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dh1 = height1 - int_height1;
  const sw = width / int_width;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);

  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  return (
    <group
      position={[0, 0, dh1 * unit]}
      scale={[sw, 1, 1]}
    >

      {/* <mesh castShadow geometry={nodes.under_profile.geometry} material={props.isTexture?material_texture:materials.profile} position={[-17.06, 12328.28, 10163.16]} rotation={[0, -Math.PI / 2, 0]} scale={1} material-color={color} /> */}
      <mesh castShadow geometry={nodes.under_profile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-18716.77, 13464.52, 10787.05]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={1} material-color={color} />
    </group>
  )
}

export function BackProfile(props) {
  const { nodes, materials, color } = props;
  const { width, depth, height2 } = props.length;
  const { int_width, int_depth, int_height2, unit } = props.int_length;
  const dd = depth - int_depth;
  const dh2 = height2 - int_height2;
  const sw = width / int_width;
  const alpha = CalcRotation(props);
  // const rotation = [Math.PI / 2 + alpha, 0, 0];
  const rotation = [-alpha, -Math.PI / 2, 0]
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  return (
    <group
      scale={[sw, 1, 1]}
      position={[0, -dd * unit, dh2 * unit]}
    >
      <mesh
        castShadow
        geometry={nodes.top_profile.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[27.38, -13107.59, 17258.2]}
        rotation={rotation}
        material-color={color} />
    </group>
  )
}

export function ColumnLeft(props) {
  const { nodes, materials, color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const sh1 = height1 / int_height1;
  const scale = [1, 1, 1 * sh1]
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  return (
    <group
      position={[dw * unit / 2 - columns.pos[1] * unit, 0, 0]}
    >
      <mesh castShadow geometry={nodes.rightcolumn1.geometry} material={props.isTexture ? material_texture : materials.profile} position={[17996.65, 12647.94, -18207.17]} scale={scale} material-color={color} />
    </group>
  )
}

export function ColumnRight(props) {
  const { nodes, materials, color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const sh1 = height1 / int_height1;
  const scale = [1, 1, 1 * sh1]
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  return (
    <group
      position={[-dw * unit / 2 + columns.pos[0] * unit, 0, 0]}
    >
      <mesh castShadow geometry={nodes.leftcolumn2.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-18093.87, 12647.94, -18207.17]} scale={scale} material-color={color} />
    </group>
  )
}

export function Columns(props) {
  const { nodes, materials, color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit, column_thickness } = props.int_length;
  const dw = width - int_width;
  const sh1 = height1 / int_height1;
  const scale = [1, 1, 1 * sh1]
  const px2 = 17996.65;
  const py = 12647.94;
  const pz = -18207.17;
  const list = [];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  for (var i = 2; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      list.push(
        <mesh key={i} castShadow geometry={nodes.rightcolumn1.geometry} material={props.isTexture ? material_texture : materials.profile} position={[dw * unit / 2 + px2 - columns.pos[i] * unit + column_thickness * unit / 2, py, pz]} scale={scale} material-color={color} />
      )
    }
  }
  return (
    <group>
      {list}
    </group>
  )

}

function HorizontalProfile(props) {
  const { nodes, materials } = props;
  const { width, depth } = props.length;
  const { int_width, top_div_width, top_glass_height, unit } = props.int_length;
  const dw = width - int_width;
  const sw = (width) / (int_width)
  const sd2 = CalcScale(props);
  const dx = top_glass_height * (sd2 - 1) * Math.cos(0.26);
  const dy = top_glass_height * (sd2 - 1) * Math.sin(0.26);
  const div_count = TopDepthDivCount(depth, props.modelID);
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  return div_count > 1 ? (
    <group position={[0, 0, -100]} scale={1}>
      <mesh castShadow geometry={nodes.center_profile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-18518.13 - dw * unit / 2, -308.95 - dx * unit, 14143.42 + dy * unit]} rotation={[1.32, -Math.PI / 2, 0]} scale={[1, 1, sw]}
        material-color={props.coverColor}
      />
    </group>
  ) : null
}


export function Glass(props) {
  const { nodes, materials } = props;
  const { width, depth } = props.length;
  const { int_width, top_glass_width, top_glass_height, column_width, unit } = props.int_length;
  const dwidth = (width - column_width / 2);
  var div_count = 0;
  div_count = TopDivCount(width, props.modelID);
  const div_width = dwidth / div_count;
  const xdelta = (div_width - 152 - top_glass_width) / 2;
  const px = -13661.17 + xdelta;
  const list = [];
  const sd2 = CalcScale(props);
  const depth_div_count = TopDepthDivCount(depth, props.modelID);
  const scale = [1, sd2 * 2 / depth_div_count, (div_width - 56) / top_glass_width];

  const rotation = [-0.26, 1.57, 0];
  const dx = top_glass_height * (sd2 - 1) * Math.cos(0.26);
  const dy = top_glass_height * (sd2 - 1) * Math.sin(0.26);
  const bias_x = depth_div_count > 1 ? 100 : 300;

  for (var i = 0; i < div_count; i = i + 1) {
    list.push(
      <group
        key={i}
        position={[-(width - int_width) * unit / 2 + div_width * i * unit + (div_width - top_glass_width) * unit / 2, 0, 0]}
      >
        {!props.isDesign ?

          <group>
            <mesh geometry={nodes.leftglasspanel8.geometry} material={materials.glass} position={[px, 12297.74 - bias_x, 10774.58]} rotation={rotation} scale={scale} material-color={'#ffffff'}
            >
              <meshPhysicalMaterial
                transparent
                ditherTransparent
                thickness={1}
                opacity={props.opacity}
                reflectivity={0.3}
              />
            </mesh>
            {depth_div_count > 1 ?
              <mesh geometry={nodes.leftglasspanel7.geometry} material={materials.glass} position={[px, -1025.44 - dx * unit - bias_x, 14318.14 + dy * unit]} rotation={rotation} scale={scale} material-color={'#ffffff'}>
                <meshPhysicalMaterial
                  transparent
                  ditherTransparent
                  thickness={1}
                  opacity={props.opacity}
                  reflectivity={0.3}
                />
              </mesh> : null}
          </group>
          :
          <group>
            <mesh geometry={nodes.glasspanel111.geometry} material={materials.glass} position={[px, 12297.74 - bias_x, 10774.58]} rotation={rotation} scale={scale}
            />
            {depth_div_count > 1 ?
              <mesh geometry={nodes.glasspanel6.geometry} material={materials.glass} position={[px, -1025.44 - dx * unit - bias_x, 14318.14]} rotation={rotation} scale={scale}
              /> : null}
          </group>}
      </group>
    )
  }
  return (
    <group
      position={[0, 0, 0]}
    >
      {list}
    </group>
  )

}

export function TopDiv(props) {
  const { nodes, materials, coverColor, visible } = props;
  const { width } = props.length;
  const { int_width, unit, column_width } = props.int_length;
  const dw = width - int_width;
  const list = [];
  const dwidth = width - column_width / 2;

  var div_count = 0;
  div_count = TopDivCount(width, props.modelID);
  const div_width = dwidth / div_count;
  const px = -width / 2 * unit + 400;
  const py = 12322.97;
  const pz = 10829.44;
  const sd2 = CalcScale(props);

  const scale = [1, 1, sd2];
  const rotation = [-1.83, 0, 0];

  const light_geometry = new THREE.BoxGeometry(200, 100, 1000);
  const light_material = new THREE.MeshPhysicalMaterial({ color: '#ffffff' })

  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  list.push(
    <group key={-1} position={[-dw * unit / 2, 0, 0]}>
      <mesh castShadow geometry={nodes.sideprofilerighttop.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-18132.45, py, pz]} rotation={rotation} scale={[-1, 1, sd2]} material-color={coverColor} />
      {/* <mesh castShadow geometry={nodes.sideprofilerightbootom.geometry} material={props.isTexture?material_texture:materials.profile} position={[-18132.45, py, pz]} rotation={rotation} scale={[-1, 1, sd2]} material-color={coverColor} /> */}
    </group>
  )
  list.push(
    <group key={0} position={[dw * unit / 2, 0, 0]}>
      <mesh castShadow geometry={nodes.sideprofilelefttop.geometry} material={props.isTexture ? material_texture : materials.profile} position={[18135.09, py, pz]} rotation={rotation} scale={scale} material-color={coverColor} />
      {/* <mesh castShadow geometry={nodes.sideprofileleftbottom.geometry} material={props.isTexture?material_texture:materials.profile} position={[18135.09, py, pz]} rotation={rotation} scale={scale} material-color={coverColor}/> */}
    </group>
  )

  for (var i = 1; i < div_count; i = i + 1) {
    list.push(
      <group key={i}>
        <mesh key={i} castShadow geometry={nodes.verticalprofile3top.geometry} material={props.isTexture ? material_texture : materials.profile} position={[px + i * div_width * unit, py, pz]} rotation={rotation} scale={scale} material-color={coverColor} />
        {visible ?
          <>
            <mesh key={i + div_count} geometry={light_geometry} material={light_material} position={[px + i * div_width * unit, py - 9000, pz + 920]} rotation={rotation} scale={scale} />
            <mesh key={i + div_count * 2} geometry={light_geometry} material={light_material} position={[px + i * div_width * unit, py - 17500, pz + 3200]} rotation={rotation} scale={scale} />
          </> : null}
      </group>
    )
  }
  return (
    <group
      position={[0, 0, 0]}
    >
      {list}
    </group>
  )

}


export function TopDivBottom(props) {
  const { nodes, materials, coverColor, visible } = props;
  const { width, depth } = props.length;
  const { int_width, int_depth, unit, column_width, column_depth } = props.int_length;
  const dw = width - int_width;
  const list = [];
  const dwidth = width - column_width / 2;

  var div_count = 0;
  div_count = TopDivCount(width, props.modelID);
  const div_width = dwidth / div_count;

  const sd = (depth - column_depth / 2) / (int_depth - column_depth / 2);
  const sd3 = (depth - 350) / (int_depth - 350);
  const sd2 = CalcScale(props) * sd3 / sd;

  const px = -width / 2 * unit + 400;
  const py = 12322.97;
  const pz = 10829.44;
  const dy = - (1 - sd2) * 12000;
  const dz = (1 - sd2) * 4300;

  const scale = [1, 1, sd2];
  const rotation = [-1.83, 0, 0];


  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  list.push(
    <group key={-1} position={[-dw * unit / 2, 0, 0]}>
      <mesh
        castShadow
        geometry={nodes.sideprofilerightbootom.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[-18132.45, py + dy, pz + dz]}
        rotation={rotation} scale={[-1, 1, sd2]}
        material-color={coverColor}
      />
    </group>
  )
  list.push(
    <group key={0} position={[dw * unit / 2, 0, 0]}>
      <mesh
        castShadow
        geometry={nodes.sideprofileleftbottom.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[18135.09, py + dy, pz + dz]}
        rotation={rotation} scale={scale}
        material-color={coverColor} />
    </group>
  )

  for (var i = 1; i < div_count; i = i + 1) {
    list.push(
      <group key={i}>
        <mesh key={i} castShadow geometry={nodes.verticalprofile3.geometry} material={props.isTexture ? material_texture : materials.profile} position={[px + i * div_width * unit, 12454.5 + dy, 10964.99 + dz]} rotation={rotation} scale={scale} material-color={coverColor} />
      </group>
    )
  }


  return (
    <group
      position={[0, 0, 0]}
    >
      {list}
    </group>
  )

}




export function Side(props) {
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz } = props.int_length;
  if (props.current_side === 0) return null;
  switch (props.current_side) {
    case 1:
      return (
        <mesh
          position={[0, -oy * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[width * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 2:
      if (props.modelID === 6) {
        return (
          <mesh
            position={[-width * unit / 2, (-depth / 2 + oy) * unit, (height1 / 2 - oz) * unit]}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
          >
            <boxGeometry args={[depth * unit, height1 * unit, 1]} />
            <meshBasicMaterial color="#002853" transparent opacity={0.4} />
          </mesh>);
      }
      else {
        return (
          <mesh
            position={[-width * unit / 2, (-depth / 2 + oy) * unit, (height1 / 2 - oz) * unit]}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <boxGeometry args={[depth * unit, height1 * unit, 1]} />
            <meshBasicMaterial color="#002853" transparent opacity={0.4} />
          </mesh>);
      }
    case 3:
      return (
        props.isRoomy ?
          <mesh
            position={[width * unit / 2, (oy - depth) * unit, (height2 / 2 - oz) * unit]}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
          >
            <boxGeometry args={[depth * 2 * unit, height2 * unit, 1]} />
            <meshBasicMaterial color="#002853" transparent opacity={0.4} />
          </mesh>
          :
          <mesh
            position={[0, (oy - depth) * unit, (height2 / 2 - oz) * unit]}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <boxGeometry args={[width * unit, height2 * unit, 1]} />
            <meshBasicMaterial color="#002853" transparent opacity={0.4} />
          </mesh>
      );
    case 4:
      return (
        <mesh
          position={[width * unit / 2, (-depth / 2 + oy) * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    default:
      return null;
  }
}

export function AddExtraColumns(props) {
  const { columns, columnFlag, secondHeight } = props;
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;

  return props.activeStep === 0 && props.subStep === 1 && columns.adding === true ? (
    <group>
      {columnFlag[4] === true ?
        <Html scaleFactor={5} position={[0, -oy * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => {
              if (columns.added[4] === false || columns.added[5] === false) props.setCurrentSide(1);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              if (!columns.added[4]) props.ShiftColumn('E')
              else if (!columns.added[5]) props.ShiftColumn('F')
            }}
          >
            Front Side<BsPlusCircle />
          </div>
        </Html> : null}
      {!secondHeight ?
        <Html scaleFactor={5} position={[-width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { if (columns.added[5] === false) props.setCurrentSide(2) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => { if (columns.added[5] === false) props.ShiftColumn('F') }}
          >
            Right Side<BsPlusCircle />
          </div>
        </Html> : null}
      {!secondHeight ?
        <Html scaleFactor={5} position={[0, (depth - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { if (columns.added[6] === false) props.setCurrentSide(3) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => { if (columns.added[6] === false) props.ShiftColumn('G') }}
          >Back Side<BsPlusCircle />
          </div>
        </Html> : null}
      {!secondHeight ?
        <Html scaleFactor={5} position={[width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { if (columns.added[7] === false) props.setCurrentSide(4) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => { if (columns.added[7] === false) props.ShiftColumn('H') }}
          >Left Side<BsPlusCircle />
          </div>
        </Html> : null}

    </group>
  ) : null

}

export function AddWalls(props) {
  const { walls, secondHeight } = props;
  const { width, depth } = props.length;
  const { unit, int_depth, oy, oz } = props.int_length;

  return walls.adding === true ? (
    <group>
      {!secondHeight && walls.added[0] === false ?
        <Html scaleFactor={5} position={[0, -oy * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(1);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[0] = true;
              state.walls.adding = false;
            }}
          >
            Front Side<BsPlusCircle />
          </div>
        </Html> : null}
      {walls.added[1] === false ?
        <Html scaleFactor={5} position={[-width * unit / 2, (-depth / 2 + oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(2) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[1] = true;
              state.walls.adding = false;
            }}
          >
            Right Side<BsPlusCircle />
          </div>
        </Html> : null}
      {walls.added[2] === false ?
        <Html scaleFactor={5} position={[0, (oy - depth) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(3) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[2] = true;
              state.walls.adding = false;
            }}
          >Back Side<BsPlusCircle />
          </div>
        </Html> : null}
      {walls.added[3] === false ?
        <Html scaleFactor={5} position={[width * unit / 2, (-depth / 2 + oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(4) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[3] = true;
              state.walls.adding = false;
            }}
          >Left Side<BsPlusCircle />
          </div>
        </Html> : null}

    </group>
  ) : null

}

export function AddWallsForRoomy(props) {
  const { walls } = props;
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;

  return walls.adding === true ? (
    <group>
      {walls.added[2] === false ?
        <Html scaleFactor={5} position={[width * unit / 2, (oy - depth) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(3) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[2] = true;
              state.walls.adding = false;
            }}
          >Back Side<BsPlusCircle />
          </div>
        </Html> : null}

      {walls.added[1] === false && false ?
        <Html scaleFactor={5} position={[0, (oy - depth) * unit + depth * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(2) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[1] = true;
              state.walls.adding = false;
            }}
          >Left Side<BsPlusCircle />
          </div>
        </Html> : null}

      {walls.added[1] === false && false ?
        <Html scaleFactor={5} position={[0, (oy - depth) * unit - depth * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(4) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[1] = true;
              state.walls.adding = false;
            }}
          >Right Side<BsPlusCircle />
          </div>
        </Html> : null}
    </group>
  ) : null

}


export function LengthInfo(props) {
  const { secondHeight } = props;
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz, LineColor, LineEndColor, lengthEndLineWidth, lengthInfoDist } = props.int_length;
  const points = []
  points.push(new THREE.Vector3(0, 0, 0))
  points.push(new THREE.Vector3(width * unit, 0, 0))
  const lineGeometryForWidth = new THREE.BufferGeometry().setFromPoints(points)
  const points2 = [];
  points2.push(new THREE.Vector3(0, 0, 0))
  points2.push(new THREE.Vector3(0, depth * unit, 0))
  const lineGeometryForDepth = new THREE.BufferGeometry().setFromPoints(points2)
  const points3 = [];
  points3.push(new THREE.Vector3(0, 0, 0))
  points3.push(new THREE.Vector3(0, 0, height1 * unit));
  const lineGeometryForHeight1 = new THREE.BufferGeometry().setFromPoints(points3)
  const points4 = [];
  points4.push(new THREE.Vector3(0, 0, 0))
  points4.push(new THREE.Vector3(0, 0, height2 * unit));
  const lineGeometryForHeight2 = new THREE.BufferGeometry().setFromPoints(points4)

  const points5 = [];
  points5.push(new THREE.Vector3(0, 0, 0));
  points5.push(new THREE.Vector3(0, 0, lengthEndLineWidth * unit));
  const lineGeometryForEnd = new THREE.BufferGeometry().setFromPoints(points5);

  return props.activeStep === 0 && props.subStep === 0 ? (
    <group>
      <Html scaleFactor={5} position={[0, oy * unit, (height1 - oz) * unit]}>
        <div className="length-info">{parseInt(width * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html>

      <Html scaleFactor={5} position={[width * unit / 2, oy * unit - depth * unit / 2, (-oz + 200) * unit]}>
        <div className="length-info">{parseInt(depth * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html>
      <Html scaleFactor={5} position={[(width + 100) * unit / 2, oy * unit, (height1 / 2 - oz) * unit]}>
        <div className="length-info">{parseInt(height1 * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html>
      {secondHeight ? <Html scaleFactor={5} position={[width * unit / 2, (-depth + oy) * unit, (height2 / 2 - oz) * unit]}>
        <div className="length-info">{parseInt(height2 * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html> : null}

      <line geometry={lineGeometryForWidth} position={[-width * unit / 2, (oy + lengthInfoDist) * unit, -oz * unit + (height1) * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForDepth} position={[width * unit / 2, (oy - depth) * unit, (-oz + 20) * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForHeight1} position={[width * unit / 2, oy * unit, -oz * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForHeight2} position={[(width + (props.modelID === 10 ? 100 : 0)) * unit / 2, (oy - depth - (props.modelID === 10 ? 50 : 0)) * unit, -oz * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[-width * unit / 2, (oy + lengthInfoDist) * unit, (-oz + height1 - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (oy + lengthInfoDist) * unit, (-oz + height1 - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (oy + lengthEndLineWidth / 2) * unit, -oz * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (oy + lengthEndLineWidth / 2) * unit, (-oz + height1) * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[(width + (props.modelID === 10 ? 100 : 0)) * unit / 2, (oy - depth + lengthEndLineWidth / 2 - (props.modelID === 10 ? 50 : 0)) * unit, -oz * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForEnd} position={[(width + (props.modelID === 10 ? 100 : 0)) * unit / 2, (oy - depth + lengthEndLineWidth / 2 - (props.modelID === 10 ? 50 : 0)) * unit, (-oz + height2) * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>



      <line geometry={lineGeometryForEnd} position={[width * unit / 2, oy * unit, (-oz - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (oy - depth) * unit, (-oz - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>


    </group>
  ) : null;
}

export function ColumnLabels(props) {
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz, secondHeight } = props.int_length;
  const columns = props.columns;
  const columnFlag = props.columnFlag;

  const list = [];
  for (var i = 2; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      list.push(
        <Html key={i} scaleFactor={5} position={[width * unit / 2 - columns.pos[i] * unit, oy * unit, (height1 - oz) * unit]}>
          <div className="length-info">{String.fromCharCode(65 + i)}</div>
        </Html>
      )
    }
  }

  return props.activeStep === 0 && props.subStep === 1 ? (
    <group>
      {columnFlag[0] ? <Html scaleFactor={5} position={[width * unit / 2 - columns.pos[1] * unit, oy * unit, (height1 - oz) * unit]}>
        <div className="length-info">B</div>
      </Html> : null}
      {columnFlag[1] ? <Html scaleFactor={5} position={[-width * unit / 2 + columns.pos[0] * unit, oy * unit, (height1 - oz) * unit]}>
        <div className="length-info">A</div>
      </Html> : null}
      {secondHeight === false && columnFlag[2] === true ? <Html scaleFactor={5} position={[-width * unit / 2, (depth - oy) * unit, (height2 - oz) * unit]}>
        <div className="length-info">C</div>
      </Html> : null}
      {secondHeight === false && columnFlag[2] === true ? <Html scaleFactor={5} position={[width * unit / 2, (depth - oy) * unit, (height2 - oz) * unit]}>
        <div className="length-info">D</div>
      </Html> : null}
      {list}
    </group>
  ) : null;

}

export function Walls(props) {
  const { walls } = props;
  const { width, depth, height2 } = props.length;
  const { unit, int_depth, oy, oz } = props.int_length;
  const dd = depth - int_depth;

  return (
    <>
      <group position={[0, (-250 - depth) * unit, -oz * unit + height2 / 2 * unit + wallTopHeight * unit / 2]}>
        {walls.added[2] ?
          <Wall width={width} height={height2} unit={unit} position={[0, (oy + 50) * unit + wallThickness * unit / 2, 0]} rotation={[Math.PI / 2, 0, 0]} ID={2} />
          : null}
      </group>
      <group position={[-width * unit / 2, -dd * unit / 2, -oz * unit + height2 / 2 * unit + wallTopHeight * unit / 2]}>
        {walls.added[1] ?
          <Wall width={depth} height={height2} unit={unit} position={[-wallThickness * unit / 2, 0, 0]} rotation={[Math.PI / 2, Math.PI / 2, 0]} ID={1} />
          : null}
      </group>
      <group position={[width * unit / 2, -dd * unit / 2, -oz * unit + height2 / 2 * unit + wallTopHeight * unit / 2]}>
        {walls.added[3] ?
          <Wall width={depth} height={height2} unit={unit} position={[wallThickness * unit / 2, 0, 0]} rotation={[Math.PI / 2, Math.PI / 2, 0]} ID={3} />
          : null}
      </group>
    </>
  )
}

export function WallsForRoomy(props) {
  const { walls } = props;
  const { width, depth, height2 } = props.length;
  const { unit, oy, oz } = props.int_length;

  return (
    <group position={[width * unit / 2 + wallThickness * unit / 2, (-250 - depth) * unit, -oz * unit + height2 / 2 * unit + wallTopHeight * unit / 2]}>
      {/* <Wall width = {width} height = {height2} unit={unit} position={[0, (oy + 50) * unit + wallThickness * unit / 2, 0]} rotation = {[Math.PI / 2, 0, 0]}/>  */}
      {walls.added[2] ?
        <Wall width={depth * 2} height={height2} unit={unit} position={[0, (oy + 200) * unit, 0]} rotation={[Math.PI / 2, Math.PI / 2, 0]} ID={2} />
        : null}
    </group>
  )
}


export function asciiDif(a, b) {
  return a.charCodeAt(0) - b.charCodeAt(0);
}

function TopCap(props) {
  const { nodes, materials, coverColor, visible } = props;
  const { width, height1, depth } = props.length;
  const { int_height1, int_depth, int_width, unit, column_depth } = props.int_length;


  const alpha = CalcRotation(props);
  const sd = (depth - column_depth / 2) / (int_depth - column_depth / 2);
  const dh1 = height1 - int_height1;
  const dw = width - int_width;

  const py = 12159.71;
  const pz = 10060.51;

  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const list = [];
  const rotation = [-1.83, 0, 0];
  const scale = [1, 1, 1];

  list.push(
    <group key={-1} position={[-dw * unit / 2, 0, 0]}>
      <mesh castShadow geometry={nodes.sideprofilerightfrontcap.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-18705.07, 12159.71, 10060.51]} rotation={rotation} scale={[-1, 1, 1]} material-color={coverColor} />
    </group>
  )
  list.push(
    <group key={0} position={[dw * unit / 2, 0, 0]}>
      <mesh castShadow geometry={nodes.sideprofilerightfrontcap.geometry} material={props.isTexture ? material_texture : materials.profile} position={[18705.07, 12159.71, 10060.51]} rotation={rotation} scale={[1, 1, 1]} material-color={coverColor} />
    </group>
  )

  return (
    <group position={[0, 0, dh1 * unit]}>
      {list}
    </group>
  )

}

function TopCapBack(props) {
  const { nodes, materials, coverColor, visible } = props;
  const { width, height1, depth } = props.length;
  const { int_height1, int_depth, int_width, unit, column_depth } = props.int_length;


  const alpha = CalcRotation(props);
  const sd = (depth - column_depth / 2) / (int_depth - column_depth / 2);
  const dh1 = height1 - int_height1;
  const dw = width - int_width;

  const py = 12159.71;
  const pz = 10060.51;

  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const list = [];

  list.push(
    <group key={-1} position={[-dw * unit / 2, 0, 0]}>
      <mesh castShadow geometry={nodes.sideprofilerightbackcap.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-18669.59, -13277.14, 17160.27]} rotation={[-1.83, 0, 0]} scale={[-1, 1, 1]} material-color={coverColor} />
    </group>
  )
  list.push(
    <group key={0} position={[dw * unit / 2, 0, 0]}>
      <mesh castShadow geometry={nodes.sideprofileleftbackcap.geometry} material={props.isTexture ? material_texture : materials.profile} position={[18706.21, -13277.14, 17160.28]} rotation={[-1.83, 0, 0]} scale={[1, 1, 1]} material-color={coverColor} />
    </group>
  )

  return (
    <group position={[0, 0, 0]}>
      {list}
    </group>
  )

}


function Top(props) {
  const { height1, height2, depth } = props.length;
  const { int_height1, int_height2, int_depth, unit, column_depth } = props.int_length;


  const alpha = CalcRotation(props);
  const sd = (depth - column_depth / 2) / (int_depth - column_depth / 2);
  const dh1 = height1 - int_height1;
  const dh2 = height2 - int_height2;
  const dd = depth - int_depth;

  return (
    <group>
      <group position={[0, 12580.01, 10190 + dh1 * unit]} rotation={[-alpha, 0, 0]} scale={[1, sd, 1]}>
        <group position={[0, -12580.01, -10190]} >
          <TopDiv {...props} />
          <Glass {...props} />
          <HorizontalProfile {...props} />
        </group>
      </group>

      <group position={[0, 12580.01, 10190 + dh1 * unit]} rotation={[-alpha, 0, 0]} scale={[1, sd, 1]}>
        <group position={[0, -12580.01, -10190]} >
          <TopDivBottom {...props} />
        </group>
      </group>

      <group position={[0, 12580.01 - dd * unit, 10190 + dh2 * unit]} rotation={[0, 0, 0]} scale={[1, 1, 1]}>
        <group position={[0, -12580.01, -10190]} >
          <TopCapBack {...props} />
        </group>
      </group>


      <TopCap {...props} />
    </group>
  )
}

function AddSubSystems(props) {
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;
  const { columns, modelID, isRoomy } = props;
  const bias = isRoomy ? 24 : 0;


  const list = [];
  const pos_list = [];

  for (let i = 2; i < 24; i++) {
    if (columns.added[i]) {
      pos_list.push({ pos: columns.pos[i], id: i });
    }
  }
  pos_list.push({ pos: columns.pos[0], id: 0 });
  pos_list.push({ pos: width, id: 0 })
  pos_list.sort(function (a, b) { return a.pos - b.pos });


  for (let i = 0; i < pos_list.length - 1; i = i + 1) {
    const swidth = pos_list[i + 1].pos - pos_list[i].pos;
    const px = pos_list[i].pos;
    const ID = pos_list[i].id;
    list.push(
      <Html key={i} scaleFactor={5} position={[width * unit / 2 - pos_list[i].pos * unit - swidth * unit / 2, oy * unit, -oz * unit]}>
        <div
          className="side-info side-info-subsystem"
          onPointerOver={(e) => {
            e.preventDefault();
            props.setSelectedSide(1);
            props.setCurrentWidth(swidth);
            props.setCurrentPos(px + swidth / 2);
          }}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            console.log("systemID==> ", props.systemId)
            props.setSelectedSide(-1);
            if (props.systemID === 6 && columns.addedSubSystem[bias + ID + 2] === true
              && (columns.systemId[bias + ID + 2] <= 3 || columns.systemId[bias + ID + 2] === 5)) {
              state.columns.addedForte[bias + ID + 2] = true;
              state.columns.fortePos[bias + ID + 2] = default_forte_pos;
            }
            else {
              state.columns.addedSubSystem[bias + ID + 2] = true;
              state.columns.systemId[bias + ID + 2] = props.systemID;
              if (props.systemID === 0) {
                state.columns.systemId[bias + ID + 2 + 8] = props.systemID;
              }
            }

            state.columns.addingSubSystem = false;
          }}
        >
          <BsPlusCircle />
        </div>
      </Html>

    )
  }

  return (props.activeStep === 2 && columns.addingSubSystem === true) ? (
    <group>
      {modelID === 10 && isRoomy ? null : <Html scaleFactor={5} position={[width * unit / 2, (-depth / 2 + oy) * unit, -oz * unit]}>
        <div
          className="side-info"
          onPointerOver={(e) => { props.setSelectedSide(2) }}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            e.preventDefault();
            props.setSelectedSide(-1);
            if (props.systemID === 6 && columns.addedSubSystem[1] === true && (columns.systemId[bias + 1] <= 3 || columns.systemId[bias + 1] === 5)) {
              state.columns.addedForte[bias + 1] = true;
              state.columns.fortePos[bias + 1] = default_forte_pos;
            }
            else {
              state.columns.addedSubSystem[bias + 1] = true
              state.columns.systemId[bias + 1] = props.systemID;
              if (props.systemID === 0) {
                state.columns.systemId[bias + 1 + 8] = props.systemID;
              }
            }
            state.columns.addingSubSystem = false;
          }}
        >
          {modelID === 10 ? '' : 'Left Side'}<BsPlusCircle />
        </div>
      </Html>}
      {/* <Html scaleFactor={5} position={[0, (depth-oy) * unit, -oz * unit]}>
        <div
          className="side-info"
          onPointerOver={(e) => {props.setSelectedSide(3)}}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            props.setSelectedSide(-1);
            state.walls.added[2] = true;
            state.walls.adding = false;
          }}
        >Back Side<BsPlusCircle />
        </div>
      </Html> */}
      {modelID === 10 && !isRoomy ? null : <Html scaleFactor={5} position={[-width * unit / 2, (oy - depth / 2) * unit, -oz * unit]}>
        <div
          className="side-info"
          onPointerOver={(e) => { props.setSelectedSide(4) }}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            e.preventDefault();
            props.setSelectedSide(-1);
            if (props.systemID === 6 && columns.addedSubSystem[bias + 0] === true && (columns.systemId[bias + 0] <= 3 || columns.systemId[bias + 0] === 5)) {
              state.columns.addedForte[bias + 0] = true;
              state.columns.fortePos[bias + 0] = default_forte_pos;
            }
            else {
              state.columns.addedSubSystem[bias + 0] = true
              state.columns.systemId[bias + 0] = props.systemID;
              if (props.systemID === 0) {
                state.columns.systemId[bias + 8] = props.systemID;
              }
            }
            state.columns.addingSubSystem = false;
          }}
        >{modelID === 10 ? '' : 'Right Side'}<BsPlusCircle />
        </div>
      </Html>}
      {list}
    </group>
  ) : null;

}

function SideForSubSystem(props) {
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz } = props.int_length;
  const { currentPos, currentWidth } = props;
  if (props.selectedSide === 0) return null;
  switch (props.selectedSide) {
    case 1:
      return (
        <mesh
          position={[width * unit / 2 - currentPos * unit, oy * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[currentWidth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 2:
      return (
        <mesh
          position={[width * unit / 2, (oy - depth / 2) * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 3:
      return (
        <mesh
          position={[0, (depth - oy) * unit, (height2 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[width * unit, height2 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 4:
      return (
        <mesh
          position={[-width * unit / 2, (oy - depth / 2) * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    default:
      return null;
  }
}

export function Grande(props) {
  const modelID = props.modelID;
  const isRoomy = props.isRoomy;
  const gltf = useLoader(GLTFLoader, grandeModel, loader => {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/draco-gltf/')
    loader.setDRACOLoader(dracoLoader)
  })
  // const onProgress = (xhr) => {
  //   if (xhr.lengthComputable) {
  //     const newProgress = (xhr.loaded / xhr.total) * 100;
  //     props.setProgress(Math.round(newProgress));
  //   }
  // };



  // const gltf = useLoader(GLTFLoader, grandeModel, onProgress);
  // const { progress } = useProgress();
  // const [loaded, setLoaded] = React.useState(false);

  // useEffect(()=>{
  //   setLoaded(true);
  // }, [])

  const { nodes, materials } = gltf;
  // const { nodes, materials } = useGLTF(grandeModel)
  const snap = useSnapshot(state);
  const [currentSide, setCurrentSide] = React.useState(0);
  const [currentPos, setCurrentPos] = React.useState(-1);
  const [selectedSide, setSelectedSide] = React.useState(0);
  const [currentWidth, setCurrentWidth] = React.useState(0);

  const ShiftColumn = (column) => {
    state.columns.editing = true;
    state.columns.editingColumn = column;
    state.columns.added[asciiDif(column, 'A')] = true;
    state.columns.adding = false;
    setCurrentSide(0);
  }

  const length = {
    ...snap.length,
    depth: modelID === 10 ? (snap.length.depth - 150) / 2 : snap.length.depth
  }

  // return !loaded?
  //   <ProgressBar value={progress} text={'Loading Model...'} />:
  return (
    <group {...props} dispose={null} ref={props.group}>
      <group position={[0, 0, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={1}>
        <WaterProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <BackProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <ColumnLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <ColumnRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />


        <Columns
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          flag={snap.flag}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <Top
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          glassColor={snap.isDesign ? snap.designStyle : snap.blades.color}
          coverColor={snap.isDesign ? snap.designStyle : snap.structure.color}
          isDesign={snap.isDesign}
          isBladeTexture={snap.isBladeTexture}
          bladeTexture={snap.blades.texture}
          isTexture={snap.isTexture}
          texture={snap.structure.textureA}
          opacity={snap.blades.opacity}
          modelID={modelID}
          addedSubSystem={snap.columns.addedSubSystem}
          deletedSubSystem={snap.columns.deletedSubSystem}
          subSystemCount={snap.columns.subSystemCount}
          visible={snap.hasLight}
        />
        {/* 
        <ColumnLabels
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
        /> */}
        {/* {modelID === 10 && isRoomy?
        null:<LengthInfo
          secondHeight={secondHeight[modelID]}
          length={length}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          modelID={modelID}
        />} */}
        <AddExtraColumns
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          ShiftColumn={ShiftColumn}
          setCurrentSide={setCurrentSide}
        />
        {modelID !== 10 ?
          <>
            <AddWalls
              walls={snap.walls}
              length={length}
              secondHeight={secondHeight[modelID]}
              int_length={lengths[modelID]}
              activeStep={props.activeStep}
              subStep={props.subStep}
              setCurrentSide={setCurrentSide}
              isRoomy={props.isRoomy}
            />
            <Walls
              walls={snap.walls}
              length={length}
              int_length={lengths[modelID]}
            />
          </> : null}

        {props.isRoomy ?
          <>
            <AddWallsForRoomy
              walls={snap.walls}
              length={length}
              secondHeight={secondHeight[modelID]}
              int_length={lengths[modelID]}
              activeStep={props.activeStep}
              subStep={props.subStep}
              setCurrentSide={setCurrentSide}
            />
            <WallsForRoomy
              walls={snap.walls}
              length={length}
              int_length={lengths[modelID]}
            />
          </>
          : null}


        <Side
          walls={snap.walls}
          columns={snap.columns}
          length={length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          current_side={currentSide}
          isRoomy={props.isRoomy}
          modelID={modelID}
        />

        <AddSubSystems
          walls={snap.walls}
          columns={snap.columns}
          length={length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setSelectedSide={setSelectedSide}
          setCurrentPos={setCurrentPos}
          setCurrentWidth={setCurrentWidth}
          systemID={snap.systemID}
          modelID={modelID}
          isRoomy={isRoomy}
        />
        <SideForSubSystem
          walls={snap.walls}
          columns={snap.columns}
          length={length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          selectedSide={selectedSide}
          currentPos={currentPos}
          currentWidth={currentWidth}
          modelID={modelID}
          isRoomy={isRoomy}
        />
        <SideInfills3
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          systemID={snap.systemID}
          modelID={modelID}
          isRoomy={isRoomy}
          dy={70}
          dz={80}
          isTexture={snap.isTexture}
          texture={snap.structure.textureC}
          walls={snap.walls}
        />
        <TriPart
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isDesign={snap.isDesign}
          opacity={snap.blades.opacity}
          isRoomy={isRoomy}
          modelID={modelID}
          isTexture={snap.isTexture}
          texture={snap.structure.textureB}
          addedSubSystem={snap.columns.addedSubSystem}
          walls={snap.walls}
        />

        <TopInfills2
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          systemID={9}
          modelID={modelID}
          addedSubSystem={snap.columns.addedSubSystem}
          deletedSubSystem={snap.columns.deletedSubSystem}
          px={0}
          py={-20000 + (snap.length.height2 - snap.length.height1) * 1}
          pz={3000 - (snap.length.height2 - snap.length.height1) * 0.2}
          alpha={Math.PI}
          dr={-1}
          isRoomy={isRoomy}
          isTexture={snap.isTexture}
          texture={snap.structure.textureD}
        />

        <GetLogo
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isRoomy={isRoomy}
          modelID={modelID}
        />


        {/* <GrandeOrgin 
          nodes={nodes}
          materials={materials}
        /> */}


      </group>
    </group>
  )
}
// useGLTF.preload(dynamicModel)