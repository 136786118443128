import React from "react";
import { wooddesign } from "../../utils/constant";
import ImageList from "@mui/material/ImageList";
import state from "../../state";
import AppContext from "../../context/AppContext";

export default function BladeColors() {

  const { modelID } = React.useContext(AppContext);
  const selected = (selected_texture_type, selected_url, opacity) => {
    state.isTexture = true;
    wooddesign[modelID].forEach((item) => {
      if (item.url === selected_url) {
        document.getElementById(item.url).style.opacity = 1.0;
        document.getElementById(item.url).style.border = "5px solid #31d84a";
      } else {
        document.getElementById(item.url).style.border = "0px";
        document.getElementById(item.url).style.opacity = 0.7;
      }
    });
    state.blades.texture = selected_url;
    state.blades.textureType = selected_texture_type;
    state.blades.opacity = opacity;
  };

  return (
    <div className="colorPicker mt-5 content-main">
      
      <ImageList variant="woven" cols={2} gap={15} className="mt-3 woodStyle">
        {wooddesign[modelID].map((item, index) => (
          <label for={item.title}>
            <img
              src={item.view}
              alt={item.view}
              id={item.url}
              className="styleImage"
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                selected(item.title, item.url, item.opacity);
              }}
            />
            <h6>{item.title}</h6>
          </label>
        ))}
      </ImageList>
    </div>
  );
}
