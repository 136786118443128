import React, {useState, useEffect} from "react";

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
// import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter';
import UserMenu from './UserMenu';
import { ReactComponent as Menu } from 'assets/img/svg/menu.svg'
import Basket from './Basket';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { useContext } from "react";
import AppContext from "context/AppContext";
import {ConfigContext} from "context/config";
import axios from 'axios';
import {API_URL, ROLE_TYPES, axiosInstanceAuthorized } from 'utils/constant';

// const handleExport = (group, modelID) => {
//   const exporter = new OBJExporter();
//   const result = exporter.parse(group.current);
//   saveString(result, title[modelID] + ".obj");
// };

const link = document.createElement('a');
link.style.display = 'none';
document.body.appendChild(link);

// const save = (blob, filename) => {
//   link.href = URL.createObjectURL(blob);
//   link.download = filename;
//   link.click();
// }

// const saveString = (text, filename) => {
//   save(new Blob([text], { type: 'text/plain' }), filename);
// }


const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const Balance = (props) => {
  const { isDark } = useContext(ConfigContext);
  const {balance, setBalance} = useContext(AppContext);
  const user = JSON.parse(localStorage.getItem('user')).user;
  const {sidebar, setSidebar} = useContext(AppContext);
  const navigate = useNavigate();

  const product_list = user.products !== null && user.products !== undefined ? user.products.split(',') : [];    
  const now = new Date();
  const lastpay = new Date(user.lastpay);

  const getProducts = () => {
    axiosInstanceAuthorized 
      .get(API_URL + "product/get")
      .then(response => {
        const user_products = response.data.products.filter(product=>product.visible && product.ready && product.monthly_payment !== null);
        axiosInstanceAuthorized 
        .post(API_URL + "product/getUserRecord", user)
        .then(resp=>{
          const all_products = resp.data.products;
          const all_records = [];
          var total_balance = 0;
          user_products.map(product=>{
            var status = false;
            var st = '';
            var ed = '';
            const date_list = [];
            var price = 0;
            const last_pay = user.lastpaid !== null && user.lastpaid !== undefined?new Date(user.lastpaid):null;

            all_products.map(item=>{
              const one_product_list = item.products !== null && item.products !== undefined ? item.products.split(',') : [];
              if (!status) {
                if (one_product_list.includes(product.title)) {
                  st = item.assigned_date;
                  status = true;
                }
              }
              else {
                if (!one_product_list.includes(product.title)) {
                  ed = item.assigned_date;
                  status = false;
                  const end_time = new Date(ed);
                  const st_time = new Date(st);
                  if (last_pay !== null && end_time > last_pay) {
                    date_list.push({
                      st: st_time < last_pay?user.last_paid: st,
                      ed: ed,
                    })
                    const start_date = new Date(st);
                    const end_date = new Date(ed);
                    price = price + product.monthly_payment * (end_date - start_date) / 1000 / 3600 / 24 / 30;
                  }
                }
              }
            })
            if (status) {
              const end_time = new Date();
              const st_time = new Date(st);
              
              if (end_time > last_pay) {
                date_list.push({
                  st: st_time < last_pay ? user.lastpaid : st,
                  ed: new Date().toString(),
                })
                price = price + product.monthly_payment * (end_time - (st_time < last_pay?last_pay:st_time)) / 1000 / 3600 / 24 / 30;
              }
            }

            if (date_list.length > 0) {
              all_records.push({
                title: product.title,
                monthly_payment: product.monthly_payment,
                total: price,
                interval: date_list,
              })
              total_balance = total_balance + price;
            }
          })
          setBalance(total_balance);
        })
        .catch(err=>{
          console.log(err);
        })
        return true;
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(()=>{
    // UpdateUser();
    getProducts();
  }, [])



  return user!==null && user.role === ROLE_TYPES.USER ?(
    <Stack className="user-balance" direction="row" alignItems="center" sx={{ position: 'absolute', top: { md: 40, xs: 30 }, right: { md: 100, xs: 15 }, zIndex: 99, '& svg': { cursor: 'pointer', mr: { md: 3, xs: 2 } } }}
        onClick={e=>{
          e.preventDefault();
          navigate('/payment');
        }}
    >
        ${(balance + user.balance)?.toFixed(2)}
    </Stack>
  ):null
}

export default Balance;