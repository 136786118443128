import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import divider_panora_cabbana from "../../assets/models/divider_panora_cabbana.glb";

export default function MiddleProfile(props) {
  const { nodes, materials } = useGLTF(divider_panora_cabbana);
  const { color, isTexture, xrepeat, yrepeat } = props;
  const { width, depth, height1 } = props.length;
  const {
    int_width,
    int_depth,
    top_div_length,
    unit,
    mid_length_limit,
    column_thickness,
  } = props.int_length;
  const rwidth = width > mid_length_limit[0] ? width / 2 : width;
  const sw = rwidth / (top_div_length - 100);
  const dw = width - int_width;
  const dd = depth - int_depth;
  const texture = useTexture(props.texture);
  texture.rotation = Math.PI;
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  if (!props.doubleSystem) return null;
  if (!props.origin) return null;
  if (props.modelID == 15) return null;

  const isPanora = props.modelID === 2 || props.modelID === 8;

  if (width > mid_length_limit[0]) {
    return (
      <>
        {!props.systemVisible[1] || !props.systemVisible[3] ? null : (
          <group
            dispose={null}
            position={[
              (width * unit * 3) / 4 - (dw * unit) / 2,
              (height1 - 150) * unit,
              dd * unit - 80 * unit,
            ]}
          >
            <group
              rotation={[-Math.PI / 2, 0, Math.PI / 2]}
              scale={[isPanora ? 1 : 0.85, sw, 3]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_0.geometry}
                material={isTexture ? material_texture : materials.center}
                material-color={color}
              />
            </group>
          </group>
        )}

        {!props.systemVisible[0] || !props.systemVisible[2] ? null : (
          <group
            dispose={null}
            position={[
              (width * unit) / 4 - (dw * unit) / 2,
              (height1 - 150) * unit,
              dd * unit - 80 * unit,
            ]}
          >
            <group
              rotation={[-Math.PI / 2, 0, Math.PI / 2]}
              scale={[isPanora ? 1 : 0.85, sw, 3]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_0.geometry}
                material={isTexture ? material_texture : materials.center}
                material-color={color}
              />
            </group>
          </group>
        )}
      </>
    );
  } else {
    return (
      <group
        dispose={null}
        position={[
          (width * unit) / 2 - (dw * unit) / 2,
          (height1 - 150) * unit,
          dd * unit - 80 * unit,
        ]}
      >
        <group
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
          scale={[isPanora ? 1 : 0.85, sw, 3]}
        >
          <mesh
            castShadow
            geometry={nodes.mesh_0.geometry}
            material={isTexture ? material_texture : materials.center}
            material-color={color}
          />
        </group>
      </group>
    );
  }
}

// useGLTF.preload('models/divider_panora_cabbana.glb')
