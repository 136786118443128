import React from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import * as THREE from 'three'
import roomyModel from '../../assets/models/RoomyCenterTopAndCaps.glb'
// import { xrepeat, yrepeat } from '../../utils/constant';

export function RoomyCenter(props) {
    const { nodes, materials } = useGLTF(roomyModel);
    const texture = useTexture(props.texture);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(0.1, 0.1);

    const textureC = useTexture(props.textureC);
    textureC.wrapS = THREE.RepeatWrapping;
    textureC.wrapT = THREE.RepeatWrapping;
    textureC.repeat.set(0.1, 0.1);

    // texture.rotation = Math.PI / 2;
    const material_texture = new THREE.MeshPhysicalMaterial({
      map: texture,
    });
    const material_textureC = new THREE.MeshPhysicalMaterial({
        map: textureC,
      });

      return (
        <group>
            <mesh material-color={props.color} geometry={nodes.cap1.geometry} material={props.isTexture?material_textureC:materials.profile} position={[-0.03, 35.81, 0.75]} rotation={[0, -Math.PI / 2, 0]} scale={[0.002, 0.002, 0.001]} />
            <mesh material-color={props.color} geometry={nodes.topcenter.geometry} material={props.isTexture?material_texture:materials.profile} position={[18.7, 35.79, 0.75]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.001, 0.001, 0.001]} />
            <mesh material-color={props.color} geometry={nodes.cap2.geometry} material={props.isTexture?material_textureC:materials.profile} position={[37.44, 35.81, 0.75]} rotation={[0, -Math.PI / 2, 0]} scale={[0.002, 0.002, 0.001]} />
        </group>
    )
}