import React, { useRef } from "react";
import {
  useTexture,
  useProgress,
  useGLTF,
  BoxHelper,
  OrbitControls,
} from "@react-three/drei";
import { useSnapshot } from "valtio";
import { lengths, columnFlag, model_images } from "../../utils/constant";
import state from "../../state";
import { Html } from "@react-three/drei";
import { BsPlusCircle } from "react-icons/bs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import * as THREE from "three";
import { ShortLight } from "./ShortLight";
import { LongLight } from "./LongLight";
import TopLights from "./TopLights";
import DividerMiddle1 from "./Divider1";
import CarColumn from "../../subsystems/CarColumn";
import {
  wallThickness,
  wallTopHeight,
  secondHeight,
  default_forte_pos,
} from "../../utils/constant";
import Wall from "../Wall";
import SideInfills6 from "../SideInfills6";
import CarBody from "./CarBody";
// import cabbana from "../../assets/models/cabbana_new.glb";
import cabbana from "../../assets/models/cabbana_new_1.glb";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader, useFrame } from "@react-three/fiber";
import MiddleProfile1 from "./MiddleProfile1";
import MiddleProfile2 from "./MiddleProfile2";
import GetLogo from "./GetLogo";
import { useEffect } from "react";
import ProgressBar from "../../common/Loading/ProgressBar";
import { LineBasicMaterial, Vector3, BufferGeometry, Line } from "three";

const xrepeat = 0.3;
const yrepeat = 0.3;

export const CalcRotation = (props) => {
  const { depth, height1, height2 } = props.length;
  const { int_depth, int_height1, int_height2, thickness } = props.int_length;
  const alpha1 = Math.atan(
    Math.abs(height2 - height1 - thickness) / (depth - thickness)
  );
  const alpha2 = Math.atan(
    Math.abs(int_height2 - int_height1 - thickness) / (int_depth - thickness)
  );
  if (height1 > height2 - thickness) return -(alpha1 + alpha2);
  return alpha1 - alpha2;
};

export const CalcScale = (props) => {
  const { depth, height1, height2 } = props.length;
  const { int_depth, int_height1, int_height2, column_width } =
    props.int_length;
  const h1 = Math.abs(height1 - height2);
  const h2 = Math.abs(int_height1 - int_height2);
  const l1 = h1 * h1 + depth * (depth - column_width);
  const l2 = h2 * h2 + int_depth * (int_depth - column_width);
  return Math.sqrt(l1 / l2);
};

export function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

export function invertColor(hex) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

const CloseIconT = (props) => {
  // Define the points for the close icon
  const points1 = [new THREE.Vector3(-1, -1, 0), new THREE.Vector3(1, 1, 0)];

  const points2 = [new THREE.Vector3(-1, 1, 0), new THREE.Vector3(1, -1, 0)];

  // Create a tube geometry using the points
  const geometry1 = new THREE.TubeBufferGeometry(
    new THREE.CatmullRomCurve3(points1),
    64,
    0.1, // Thickness of the line
    8, // Number of segments
    false // Closed
  );

  const geometry2 = new THREE.TubeBufferGeometry(
    new THREE.CatmullRomCurve3(points2),
    64,
    0.1, // Thickness of the line
    8, // Number of segments
    false // Closed
  );

  // Create a custom material for the line
  const material = new THREE.MeshBasicMaterial({
    color: "red",
    side: THREE.DoubleSide,
    depthTest: false,
    depthWrite: false,
  });

  const centerPoint = new THREE.Vector3()
    .addVectors(points1[0], points2[0])
    .multiplyScalar(0.5);

  return (
    <group position={centerPoint}>
      <mesh
        geometry={geometry1}
        material={material}
        position={props.position}
        rotation={[Math.PI / 2, 0, 0]}
        scale={20}
      />
      <mesh
        geometry={geometry2}
        material={material}
        position={props.position}
        rotation={[Math.PI / 2, 0, 0]}
        scale={20}
      />
    </group>
  );
};

// export function VisibleSystem(props) {
//   const { systemVisible } = props;
//   const { width, depth, height1 } = props.length;
//   const { int_width, int_depth, unit, mid_length_limit } = props.int_length;
//   const dw = width - int_width;
//   const dd = depth - int_depth;
//   const cnt = systemVisible.filter(item=>item === true).length;

//   if (!props.doubleSystem) return null;
//   if (width <= mid_length_limit[0]) return null;
//   if (props.viewMode === true) return null;

//   // if (localStorage.getItem('user_id') === 'null' || localStorage.getItem('user_id') === null) return null;

//   const zdelta = 250;

//   return props.origin === true?(
//     <>
//     {cnt === 3 && systemVisible[0]?
//       null:
//       <CloseIconT  position={[-dw * unit / 2, (height1 + zdelta) * unit, dd * unit - depth * unit]}
//         onClick={e=>{e.preventDefault(); state.systemVisible[0]=false}}
//       />
//     }
//     {cnt === 3 && systemVisible[1]?
//       null:
//       <CloseIconT position={[-dw * unit / 2 + width * unit, (height1 + zdelta) * unit, dd * unit - depth * unit]}
//         onClick={e=>{e.preventDefault(); state.systemVisible[1]=false}}
//       />
//     }
//     </>
//     ):(
//     <>
//     {cnt === 3 && systemVisible[2]?
//       null:
//       <CloseIconT scaleFactor={5} position={[-dw * unit / 2, (height1 + zdelta) * unit, dd * unit]}
//         onClick={e=>{e.preventDefault(); state.systemVisible[2]=false}}
//       />
//     }
//     {cnt === 3 && systemVisible[3]?
//       null:
//       <CloseIconT position={[-dw * unit / 2 + width * unit, (height1 + zdelta) * unit, dd * unit]}
//         onClick={e=>{e.preventDefault(); state.systemVisible[3]=false}}
//       />
//     }
//     </>
//   )
// }

export function VisibleSystem(props) {
  const { systemVisible } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, unit, mid_length_limit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const cnt = systemVisible.filter((item) => item === true).length;

  if (!props.doubleSystem) return null;
  if (width <= mid_length_limit[0]) return null;
  if (props.viewMode === true) return null;

  // if (localStorage.getItem('user_id') === 'null' || localStorage.getItem('user_id') === null) return null;

  const zdelta = 250;

  return props.origin === true ? (
    <group renderOrder={0}>
      {cnt === 3 && systemVisible[0] ? null : (
        <Html
          scaleFactor={5}
          position={[
            (-dw * unit) / 2,
            (height1 + zdelta) * unit,
            dd * unit - depth * unit,
          ]}
        >
          <div className="visible-info">
            {systemVisible[0] ? (
              <CancelOutlinedIcon
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  state.systemVisible[0] = false;
                }}
              />
            ) : (
              <AddCircleOutlineIcon
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  state.systemVisible[0] = true;
                }}
              />
            )}
          </div>
        </Html>
      )}

      {cnt === 3 && systemVisible[1] ? null : (
        <Html
          scaleFactor={5}
          position={[
            (-dw * unit) / 2 + width * unit,
            (height1 + zdelta) * unit,
            dd * unit - depth * unit,
          ]}
          raycast={false}
        >
          <div className="visible-info">
            {systemVisible[1] ? (
              <CancelOutlinedIcon
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  state.systemVisible[1] = false;
                }}
              />
            ) : (
              <AddCircleOutlineIcon
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  state.systemVisible[1] = true;
                }}
              />
            )}
          </div>
        </Html>
      )}
    </group>
  ) : (
    <group renderOrder={0}>
      {cnt === 3 && systemVisible[2] ? null : (
        <Html
          scaleFactor={5}
          position={[(-dw * unit) / 2, (height1 + zdelta) * unit, dd * unit]}
          raycast={false}
        >
          <div className="visible-info">
            {systemVisible[2] ? (
              <CancelOutlinedIcon
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  state.systemVisible[2] = false;
                }}
              />
            ) : (
              <AddCircleOutlineIcon
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  state.systemVisible[2] = true;
                }}
              />
            )}
          </div>
        </Html>
      )}
      {cnt === 3 && systemVisible[3] ? null : (
        <Html
          scaleFactor={5}
          position={[
            (-dw * unit) / 2 + width * unit,
            (height1 + zdelta) * unit,
            dd * unit,
          ]}
          raycast={false}
        >
          <div className="visible-info">
            {systemVisible[3] ? (
              <CancelOutlinedIcon
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  state.systemVisible[3] = false;
                }}
              />
            ) : (
              <AddCircleOutlineIcon
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  state.systemVisible[3] = true;
                }}
              />
            )}
          </div>
        </Html>
      )}
    </group>
  );
}

export function Normalize(geometry) {
  // Get the vertex positions of the geometry
  var positions = geometry.attributes.position.array;

  // Create a new array to store the corrected UV coordinates
  const uvs = [];

  // Iterate over the vertices of the geometry and calculate the UV coordinates
  for (let i = 0; i < positions.length; i += 3) {
    const x = positions[i];
    const y = positions[i + 1];
    const z = positions[i + 2];

    // Calculate the UV coordinates based on the position of the vertex
    const u = (x + 0.5) / 706; // 1 is the width of the texture
    const v = (z + 0.5) / 1000; // 1 is the height of the texture

    // Add the UV coordinates to the array
    uvs.push(u, v);
  }

  // Set the UV coordinates on the geometry
  geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));
  return geometry;
}

export function TestProfile(props) {
  const { nodes, materials } = props;
  const texture = useLoader(THREE.TextureLoader, props.texture);

  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(1, 1);

  const geometry = Normalize(nodes.profileback.geometry);

  return (
    <mesh scale={0.2} geometry={geometry} position={[0, 200, -300]}>
      <meshStandardMaterial map={texture} />
    </mesh>
  );
}

export function WaterProfile(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, column_thickness, unit } = props.int_length;
  const dw = width - int_width;
  const dh1 = height1 - int_height1;
  // const bias = 100;
  const bias = 0;
  const water_profile_width = int_width - column_thickness * 2 - bias;
  const real_width = width - column_thickness * 2 - bias;
  const real_width2 = (width - column_thickness * 3) / 2;

  var sw = (real_width + column_thickness) / water_profile_width;
  var px =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[0]) ||
      (!props.origin && !props.systemVisible[2]))
      ? (real_width2 + column_thickness) / 2
      : props.isLarge &&
        props.doubleSystem &&
        ((props.origin && !props.systemVisible[1]) ||
          (!props.origin && !props.systemVisible[3]))
      ? -(real_width2 + column_thickness) / 2
      : 0;

  if (
    props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    (!props.systemVisible[0] || !props.systemVisible[1])
  ) {
    sw = real_width2 / water_profile_width;
  }

  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    (!props.systemVisible[2] || !props.systemVisible[3])
  ) {
    sw = real_width2 / water_profile_width;
  }

  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    (!props.systemVisible[0] || !props.systemVisible[1])
  ) {
    sw = real_width2 / water_profile_width;
    if (!props.systemVisible[1]) {
      px = (real_width2 + column_thickness) / 2;
    } else {
      // px = -(width + column_thickness) / 2 + column_thickness;
      px = -(real_width2 + column_thickness) / 2;
    }
  }

  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const cnt = props.systemVisible.filter((item) => item === true).length;
  if (props.doubleSystem) {
    if (props.isLarge && !props.origin && cnt === 4) return null;
    if (
      props.isLarge &&
      !props.origin &&
      props.systemVisible[0] &&
      props.systemVisible[1]
    )
      return null;
    if (
      !props.isLarge &&
      !props.origin &&
      props.systemVisible[1] &&
      props.systemVisible[3]
    )
      return null;
  }

  return (
    <group
      position={[
        (-dw * unit) / 2 +
          ((width + column_thickness) / 2 - column_thickness * 2) * unit +
          px * unit +
          (bias * (1 - sw) * unit) / 2,
        dh1 * unit,
        0,
      ]}
    >
      <mesh
        castShadow
        geometry={nodes.beam6.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        // position={[16.98, 235.27, -288.11]}
        position={[16.98, 235.27, -288.01]}
        scale={[-0.1, 0.1, sw * 0.1]}
        rotation={[0, Math.PI / 2, 0]}
        material-color={color}
      />
    </group>
  );
}

export function BackProfile(props) {
  const { nodes, materials, color } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, int_height1, column_thickness, unit } =
    props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  // const bias = 100;
  const bias = 0;
  const water_profile_width = int_width - column_thickness * 2 - bias;
  var sw = (width - column_thickness - bias) / water_profile_width;
  // var px =
  //   props.isLarge &&
  //     props.doubleSystem &&
  //     ((props.origin && !props.systemVisible[0]) ||
  //       (!props.origin && !props.systemVisible[2]))
  //     ? (width + column_thickness) / 2 - column_thickness
  //     : 0;
  // var px =
  //   props.isLarge &&
  //     props.doubleSystem &&
  //     ((props.origin && !props.systemVisible[0]) ||
  //       (!props.origin && !props.systemVisible[2]))
  //     ? (width - column_thickness) / 4
  //     : 0;
  var px =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[0]) ||
      (!props.origin && !props.systemVisible[2]))
      ? (width - column_thickness) / 4
      : props.isLarge &&
        props.doubleSystem &&
        ((props.origin && !props.systemVisible[1]) ||
          (!props.origin && !props.systemVisible[3]))
      ? -(width - column_thickness) / 4
      : 0;

  var pz = 0;
  if (
    props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    (!props.systemVisible[0] || !props.systemVisible[1])
  ) {
    sw =
      ((width + column_thickness) / 2 - column_thickness * 2) /
      water_profile_width;
  }
  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    (!props.systemVisible[2] || !props.systemVisible[3])
  ) {
    sw =
      ((width + column_thickness) / 2 - column_thickness * 2) /
      water_profile_width;
  }
  if (
    props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    (!props.systemVisible[2] || !props.systemVisible[3])
  ) {
    sw =
      ((width + column_thickness) / 2 - column_thickness * 2) /
      water_profile_width;
    if (!props.systemVisible[2]) {
      px = -width / 4;
    } else {
      px = width / 4 - 20;
    }
  }

  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const cnt = props.systemVisible.filter((item) => item === true).length;
  if (props.doubleSystem) {
    if (props.isLarge && props.origin && cnt === 4) return null;
    if (
      props.isLarge &&
      props.origin &&
      props.systemVisible[2] &&
      props.systemVisible[3]
    )
      return null;
    if (
      !props.isLarge &&
      props.origin &&
      props.systemVisible[1] &&
      props.systemVisible[3]
    )
      return null;
  }

  // const geo = normalizeUVs(nodes.profilefront.geometry);
  return (
    <group
      position={[
        (-dw * unit) / 2 +
          ((width + column_thickness) / 2 - column_thickness * 2) * unit +
          px * unit +
          (bias * (1 - sw) * unit) / 2,
        dh1 * unit,
        dd * unit + pz * unit,
      ]}
    >
      <mesh
        castShadow
        geometry={nodes.beam6.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        // position={[16.99, 235.27, -9.67]}
        position={[16.99, 235.27, -9.57]}
        scale={[0.1, 0.1, sw * 0.1]}
        rotation={[0, Math.PI / 2, 0]}
        material-color={color}
      />
    </group>
  );
}

export function LeftProfile(props) {
  const { nodes, materials, color } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, int_height1, column_thickness, unit } =
    props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  // const bias = 100;
  const bias = 0;
  const delta = column_thickness * 2 + bias;
  let sd = (depth - delta) / (int_depth - delta);
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const px =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[0]) ||
      (!props.origin && !props.systemVisible[2]))
      ? (width + column_thickness) / 2 - column_thickness + 18
      : 0;

  if (
    props.doubleSystem &&
    ((props.origin && props.systemVisible[0]) ||
      (!props.origin && props.systemVisible[2]))
  ) {
    sd = (depth - delta / 2) / (int_depth - delta);
  }
  const scale = [0.1, 0.1, 0.1 * sd];

  return (
    <group
      position={[
        (-dw * unit) / 2 + px * unit,
        dh1 * unit,
        dd * unit -
          (depth / 2 - column_thickness) * unit +
          ((sd - 1) * bias * unit) / 2,
      ]}
    >
      <mesh
        castShadow
        geometry={nodes.beam7.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        // position={[10.78, 235.27, -15.77]}
        position={[-6.78 + 4.84, 235.27, -15.77]}
        scale={scale}
        rotation={[Math.PI, 0, -Math.PI]}
        material-color={color}
      />
    </group>
  );
}

export function RightProfile(props) {
  const { nodes, materials, color } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, int_height1, column_thickness, unit } =
    props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  // const bias = 100;
  const bias = 0;
  const delta = column_thickness * 2 + bias;
  let sd = (depth - delta) / (int_depth - delta);
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const px =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[1]) ||
      (!props.origin && !props.systemVisible[3]))
      ? (width + column_thickness) / 2 - column_thickness + 65
      : 0;
  if (
    props.doubleSystem &&
    ((props.origin && props.systemVisible[1]) ||
      (!props.origin && props.systemVisible[3]))
  )
    sd = (depth - delta / 2) / (int_depth - delta);
  const scale = [-0.1, 0.1, 0.1 * sd];

  return (
    <group
      position={[
        (dw * unit) / 2 - px * unit,
        dh1 * unit,
        dd * unit -
          (depth / 2 - column_thickness) * unit +
          ((sd - 1) * bias * unit) / 2,
      ]}
    >
      <mesh
        castShadow
        geometry={nodes.beam7.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        // position={[389.7, 235.27, -15.77]}
        position={[389.7 - 4.84, 235.27, -15.77]}
        scale={scale}
        rotation={[Math.PI, 0, -Math.PI]}
        material-color={color}
      />
    </group>
  );
}

export function ColumnLeft(props) {
  const { nodes, materials, color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, column_height, unit } = props.int_length;
  const dw = width - int_width;
  const delta = int_height1 - column_height;
  const sh = (height1 - delta) / (int_height1 - delta);
  const dh = height1 - int_height1;
  const scale = [0.1, 0.1, 0.1 * sh];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  if (
    props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    !props.systemVisible[1]
  )
    return null;
  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    !props.systemVisible[3]
  )
    return null;

  return (
    <group position={[(dw * unit) / 2 - columns.pos[0] * unit - 4.87, 0, 0.13]}>
      <mesh
        castShadow
        geometry={nodes.column1.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[391.98, 121.1 + (dh * unit) / 2, -290.39]}
        scale={scale}
        rotation={[-Math.PI / 2, 0, 0]}
        material-color={color}
      />
      <mesh
        castShadow
        geometry={nodes.coltop026.geometry}
        material={props.isTexture ? material_texture : materials.All_profiles}
        position={[385.48, 228.7 + dh * unit, -283.7]}
        scale={0.1}
        rotation={[-Math.PI / 2, 0, 0]}
        material-color={color}
      />
    </group>
  );
}

export function ColumnBackRight(props) {
  const { nodes, materials, color, columns } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, int_height1, column_height, unit } =
    props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const delta = int_height1 - column_height;
  const sh = (height1 - delta) / (int_height1 - delta);
  const dh = height1 - int_height1;
  const scale = [0.1, 0.1, 0.1 * sh];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    !props.systemVisible[2]
  )
    return null;
  if (
    props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    !props.systemVisible[0]
  )
    return null;

  return (
    <group
      position={[
        (-dw * unit) / 2 + columns.pos[2] * unit - 12.67,
        0,
        dd * unit + 0.1,
      ]}
    >
      <mesh
        castShadow
        geometry={nodes.column5.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[8.5, 121.1 + (dh * unit) / 2, -7.39]}
        scale={scale}
        rotation={[-Math.PI / 2, 0, 0]}
        material-color={color}
      />
      <mesh
        castShadow
        geometry={nodes.coltop028.geometry}
        material={props.isTexture ? material_texture : materials.All_profiles}
        position={[11.2, 228.7 + dh * unit, -19.2]}
        scale={0.1}
        rotation={[-Math.PI / 2, 0, 0]}
        material-color={color}
      />
    </group>
  );
}

export function ColumnRight(props) {
  const { nodes, materials, color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, column_height, unit } = props.int_length;
  const dw = width - int_width;
  const delta = int_height1 - column_height;
  const sh = (height1 - delta) / (int_height1 - delta);
  const dh = height1 - int_height1;
  const scale = [0.1, 0.1, 0.1 * sh];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  if (
    props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    !props.systemVisible[0]
  )
    return null;
  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    !props.systemVisible[2]
  )
    return null;

  return (
    // <group position={[(-dw * unit) / 2 + columns.pos[1] * unit, 0, 0]}>
    // <group position={[(-dw * unit) / 2 + columns.pos[1] * unit - 12.67, 0, 0.14]}>
    <group
      position={[(-dw * unit) / 2 + columns.pos[1] * unit - 12.67, 0, 0.14]}
    >
      <mesh
        castShadow
        geometry={nodes.column3.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        // position={[8.5, 21.1, -290.39]}
        position={[8.5, 121.1 + dh * unit * 0.5, -290.39]}
        scale={scale}
        rotation={[-Math.PI / 2, 0, 0]}
        material-color={color}
      />
      <mesh
        castShadow
        geometry={nodes.coltop021.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[15, 228.7 + dh * unit, -283.7]}
        scale={0.1}
        rotation={[-Math.PI / 2, 0, 0]}
        material-color={color}
      />
    </group>
  );
}

export function ColumnBackLeft(props) {
  const { nodes, materials, color, columns } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, int_height1, column_height, unit } =
    props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const delta = int_height1 - column_height;
  const sh = (height1 - delta) / (int_height1 - delta);
  const dh = height1 - int_height1;
  const scale = [0.1, 0.1, 0.1 * sh];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    !props.systemVisible[3]
  )
    return null;
  if (
    props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    !props.systemVisible[1]
  )
    return null;

  return (
    // <group position={[(dw * unit) / 2 - columns.pos[3] * unit, 0, dd * unit]}>
    <group
      position={[(dw * unit) / 2 - columns.pos[3] * unit - 4.87, 0, dd * unit]}
    >
      <mesh
        castShadow
        geometry={nodes.column8.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        // position={[391.98, 21.1, -7.39]}
        position={[391.98, 121.1 + (dh * unit) / 2, -7.39]}
        scale={scale}
        rotation={[-Math.PI / 2, 0, 0]}
        // material-color={color}
      />
      <mesh
        castShadow
        geometry={nodes.coltop028.geometry}
        material={props.isTexture ? material_texture : materials.All_profiles}
        // position={[381.1, 228.7 + dh * unit, -19.2]}
        position={[385.48, 228.7 + dh * unit, -19.2]}
        scale={0.1}
        rotation={[-Math.PI / 2, 0, 0]}
        material-color={color}
      />
    </group>
  );
}

export function Columns(props) {
  const { nodes, materials, color, columns } = props;
  const { width, depth, height1 } = props.length;
  const {
    int_width,
    int_depth,
    int_height1,
    column_height,
    unit,
    column_thickness,
  } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const delta = int_height1 - column_height;
  const sh = (height1 - delta) / (int_height1 - delta);
  // const sh = (height1 - 250) / (int_height1 - delta)
  const dh = height1 - int_height1;
  const scale = [0.1, 0.1, 0.1 * sh];
  const px1 = 391.98;
  const px2 = 8.5;
  const py = 21.1;
  const pz1 = -290.25; // -290.39
  const pz2 = -7.39;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const list = [];

  for (let i = 4; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      switch (columns.side[i]) {
        case 1:
          list.push(
            <mesh
              key={i}
              castShadow
              geometry={nodes.column7.geometry}
              material={props.isTexture ? material_texture : materials.profile}
              position={[
                (dw * unit) / 2 +
                  px1 -
                  columns.pos[i] * unit +
                  (column_thickness * unit) / 2 -
                  10.87,
                py + 100 + (dh * unit) / 2,
                pz1,
              ]}
              scale={scale}
              material-color={color}
              rotation={[-Math.PI / 2, 0, 0]}
            />
          );
          break;
        case 2:
          list.push(
            <mesh
              key={i}
              castShadow
              geometry={nodes.column7.geometry}
              material={props.isTexture ? material_texture : materials.profile}
              position={[
                (-dw * unit) / 2 + px2 - 10.87,
                py + 100 + (dh * unit) / 2,
                pz1 + columns.pos[i] * unit - (column_thickness * unit) / 2,
              ]}
              scale={scale}
              material-color={color}
              rotation={[-Math.PI / 2, 0, 0]}
            />
          );
          break;
        case 3:
          list.push(
            <mesh
              key={i}
              castShadow
              geometry={nodes.column7.geometry}
              material={props.isTexture ? material_texture : materials.profile}
              position={[
                (-dw * unit) / 2 +
                  px2 +
                  columns.pos[i] * unit -
                  (column_thickness * unit) / 2 -
                  10.87,
                py + 100 + (dh * unit) / 2,
                pz2 + dd * unit,
              ]}
              scale={scale}
              material-color={color}
              rotation={[-Math.PI / 2, 0, 0]}
            />
          );
          break;
        case 4:
          list.push(
            <mesh
              key={i}
              castShadow
              geometry={nodes.column7.geometry}
              material={props.isTexture ? material_texture : materials.profile}
              position={[
                (dw * unit) / 2 + px1 - 10.87,
                py + 100 + (dh * unit) / 2,
                pz2 +
                  dd * unit -
                  columns.pos[i] * unit +
                  (column_thickness * unit) / 2,
              ]}
              scale={scale}
              material-color={color}
              rotation={[-Math.PI / 2, 0, 0]}
            />
          );
          break;
        default:
          break;
      }
    }
  }
  return <group>{list}</group>;
}

// export function GetLogo(props) {
//   const { nodes, materials, color, columns } = props;
//   const { width, depth, height1 } = props.length;
//   const { int_width, int_depth, int_height1, unit } = props.int_length;
//   const dw = width - int_width;
//   const dd = depth - int_depth;
//   const dh1 = height1 - int_height1;
//   if (props.isLarge && props.doubleSystem && !props.origin && !props.systemVisible[3]) return null;
//   if (props.doubleSystem && props.origin && props.systemVisible[3]) return null;
//   return (
//     <group
//       position={[dw * unit / 2 - columns.pos[1] * unit, dh1 * unit, dd * unit]}
//     >
//       <mesh castShadow geometry={nodes.Schildr_Logo.geometry} material={materials.Logo} position={[361.31, 227.48, 1.15]}
//         material-color={invertColor(color)}
//         rotation={[0, Math.PI / 2, 0]}
//         scale={0.1}
//       />
//     </group>
//   )

// }

export function Glass(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, int_div_width, top_glass_width, unit } =
    props.int_length;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(1, 1);
  const dh1 = height1 - int_height1;
  const div_count = parseInt(width / int_div_width);
  const div_width = width / div_count;
  const px = -20000;
  const py = -13513.99;
  const pz = 8122.18;
  const list = [];
  const alpha = CalcRotation(props);
  const rotation = [1.7 + alpha, 0, 0];
  const sd2 = CalcScale(props);
  const scale = [(10 * div_width) / top_glass_width, 10, 10 * sd2];

  for (let i = 0; i < div_count; i = i + 1) {
    list.push(
      <group
        key={i}
        position={[
          (-(width - int_width) * unit) / 2 + div_width * i * unit,
          0,
          0,
        ]}
      >
        {props.isTexture && !props.isDesign ? (
          <mesh
            castShadow
            geometry={nodes.glass_1.geometry}
            material={materials.glass}
            position={[px, py, pz]}
            rotation={rotation}
            scale={scale}
          >
            <meshPhysicalMaterial
              transparent
              ditherTransparent
              thickness={1}
              opacity={props.opacity}
              reflectivity={0.3}
              map={texture}
              castShadow
            />
          </mesh>
        ) : (
          <mesh
            castShadow
            geometry={nodes.glass_1.geometry}
            material={materials.glass}
            position={[px, py, pz]}
            rotation={rotation}
            scale={scale}
            material-color={color}
          />
        )}
      </group>
    );
  }
  return <group position={[0, 0, dh1 * unit]}>{list}</group>;
}

export function TopDiv(props) {
  const {
    nodes,
    color,
    visible,
    bladeRotation,
    bladeType,
    materials,
    middleColor,
    modelID,
  } = props;
  const { width, depth, height1 } = props.length;
  const {
    int_width,
    int_depth,
    int_height1,
    int_div_width,
    top_div_length,
    mid_length_limit,
    unit,
    column_thickness,
  } = props.int_length;
  const isLS = props.modelID > 10;

  const dw = width - int_width;
  const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  const delta = int_depth - top_div_length - 10;
  const blade_length = 3760;
  const sd =
    props.doubleSystem === false
      ? (depth - column_thickness * 1.4) / blade_length
      : (depth - column_thickness * 1.6) / blade_length;
  // const sd =
  // props.doubleSystem === false
  //   ? (depth - delta) / top_div_length
  //   : (depth - delta - column_thickness / 2 + 30) / top_div_length;
  //   const sd =
  // width > mid_length_limit[1]
  //   ? (width / 3 - column_thickness) / top_div_length
  //   : width > mid_length_limit[0]
  //   ? (width / 2 - column_thickness) / top_div_length
  //   : (width - column_thickness) / top_div_length;
  const bladeDepth = ((sd * blade_length) / 2) * unit;
  // const bladeDepth = (depth / 2 - (props.doubleSystem ? 127 : 97)) * unit;
  const list = [];
  const div_count =
    parseInt(
      (width <= mid_length_limit[0] ? width / 0.727 : width / 2 + 100) /
        int_div_width
    ) - 1;
  const div_width =
    (width <= mid_length_limit[0] ? width : width / 2 + 100) / div_count;

  // const bias =
  //   props.doubleSystem === false || props.origin === false
  //     ? 0
  //     : (column_thickness / 2 - 80);
  const bias =
    props.doubleSystem === false
      ? 0
      : props.origin === true
      ? column_thickness / 2 - 55
      : column_thickness / 2 - 112;
  const px = 11.1 - (dw * unit) / 2;
  const px2 = ((width * 2 - dw) * unit) / 2 - 22.1;
  // const py = 236.52;
  const py = 243.82;

  // const pz = -10.23 - bias * unit;
  // const pz = -7.23 - bias * unit - (depth / 2 - column_thickness / 2) * unit;
  const pz =
    props.doubleSystem === false
      ? -((depth - column_thickness * 0.2) / 2) * unit
      : props.origin === false
      ? -((depth - column_thickness * 0.38) / 2) * unit
      : -((depth + column_thickness * 0.1) / 2) * unit;
  // const scale = [0.1, (0.1 * div_width) / 200, 0.1 * sd];
  // const scale = [0.1, (0.1 * div_width) * 0.737 / 200, 0.1 * 0.745 * sd];
  const scale = [(-0.1 * div_width) / 200, 0.1 * 0.737, 0.1 * sd];
  const scale_blade = [(-0.1 * div_width) / 200, 0.1, 0.155];
  const scale_l = [1, div_width / 200, sd];
  const rotation = [0, 0, (bladeRotation / 180) * Math.PI];
  const rotation2 = [0, 0, (bladeRotation / 180) * Math.PI - Math.PI / 2];
  const rotation3 = [0, 0, (-bladeRotation / 180) * Math.PI + Math.PI / 2];
  const led_x = 2.4;
  const led_height = -2.15;
  const led_rotation = [0, 0, (-bladeRotation / 180) * Math.PI];
  const scale3 = [
    (-0.1 * div_width) / 200,
    0.1 * 0.737,
    0.1 * 0.745 * 0.997 * sd,
  ];

  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const structure_texture = useTexture(props.structure_texture);
  structure_texture.wrapS = THREE.RepeatWrapping;
  structure_texture.wrapT = THREE.RepeatWrapping;
  structure_texture.repeat.set(xrepeat, yrepeat);
  structure_texture.rotation = Math.PI / 2;
  const middle_texture = new THREE.MeshPhysicalMaterial({
    map: structure_texture,
  });

  // const bladeDepth = (depth / 2 - 97) * unit
  const material_blade = new THREE.MeshPhysicalMaterial();

  const blade_direction =
    width > mid_length_limit[0] ||
    props.modelID === 1 ||
    props.modelID === 13 ||
    props.modelID === 15 ||
    props.modelID === 17
      ? 0
      : props.blades.direction;

  const offset_unit =
    props.modelID === 4 || props.modelID === 14
      ? (100 - props.blades.offset) / 100
      : 1;
  const offset_direction =
    (props.modelID === 4 || props.modelID === 14) &&
    width <= mid_length_limit[0]
      ? blade_direction == 0
        ? 0
        : (width > mid_length_limit[0]
            ? width / 2 - div_width * 2
            : width - div_width * 2) *
          unit *
          (1 - offset_unit) *
          -1
      : 0;
  var bias1 = 0;
  const bias2 = 0;
  const position_middle = [5, 0, -pz - column_thickness * 0.45 * unit];

  for (let i = 0; i < div_count * 1; i = i + 1) {
    if (i === div_count - 1) continue;
    if (i > div_count && i === div_count * 2 - 1) continue;
    if (props.isLarge && props.doubleSystem) {
      if (props.origin && !props.systemVisible[0]) continue;
      if (!props.origin && !props.systemVisible[2]) continue;
    }
    const isLight = i % 3 === 2 && visible ? true : false;

    if (props.isTexture) {
      list.push(
        <group
          position={[
            px +
              i * div_width * unit * offset_unit -
              offset_direction +
              bias1 +
              bias2,
            py,
            pz,
          ]}
          key={i + 100}
        >
          {props.doubleSystem && props.origin && (
            <group rotation={[0, -Math.PI / 2, 0]} position={position_middle}>
              <mesh
                castShadow
                geometry={nodes.middleseperator2on1.geometry}
                material={
                  props.isMiddleTexture ? middle_texture : materials.profile
                }
                material-color={middleColor}
                scale={scale_blade}
              />
            </group>
          )}
          <group rotation={rotation3}>
            <mesh
              castShadow
              geometry={nodes.blade1.geometry}
              material={material_texture}
              scale={scale}
              material-color={color}
              position={[0, -3, 0]}
            />
            <mesh
              castShadow
              geometry={nodes.blade1left.geometry}
              material={material_texture}
              material-color={color}
              scale={scale}
              position={[0, 0, bladeDepth]}
            />
            <mesh
              castShadow
              geometry={nodes.blade1right.geometry}
              position={[0, 0, -bladeDepth]}
              material={material_texture}
              material-color={color}
              scale={scale}
            />
          </group>
          {isLight ? (
            i % 6 === 2 ? (
              <group>
                <ShortLight
                  bladeRotation={bladeRotation}
                  rotation={led_rotation}
                  scale={scale_l}
                  position={[led_x, led_height, (depth * unit) / 4]}
                />
                <ShortLight
                  bladeRotation={bladeRotation}
                  rotation={led_rotation}
                  scale={scale_l}
                  position={[led_x, led_height, -(depth * unit) / 4]}
                />
              </group>
            ) : (
              <LongLight
                bladeRotation={bladeRotation}
                position={[led_x, led_height, 0]}
                rotation={led_rotation}
                scale={scale_l}
              />
            )
          ) : null}
        </group>
      );
    } else {
      list.push(
        <group
          position={[
            px +
              i * div_width * unit * offset_unit -
              offset_direction +
              bias1 +
              bias2,
            py,
            pz,
          ]}
          key={i}
        >
          {props.doubleSystem && props.origin && (
            <group rotation={[0, -Math.PI / 2, 0]} position={position_middle}>
              <mesh
                castShadow
                geometry={nodes.middleseperator2on1.geometry}
                material={
                  props.isMiddleTexture ? middle_texture : materials.profile
                }
                material-color={middleColor}
                // scale={scale}
                scale={scale_blade}
              />
            </group>
          )}
          <group rotation={rotation3}>
            <mesh
              castShadow
              geometry={nodes.blade1.geometry}
              material={material_blade}
              material-color={color}
              scale={scale}
              position={[0, -3, 0]}
            />
            <mesh
              castShadow
              geometry={nodes.blade1left.geometry}
              material={material_blade}
              material-color={color}
              scale={scale}
              position={[0, 0, bladeDepth]}
            />
            <mesh
              castShadow
              geometry={nodes.blade1right.geometry}
              position={[0, 0, -bladeDepth]}
              material={material_blade}
              material-color={color}
              scale={scale}
            />
          </group>

          {isLight ? (
            i % 6 === 2 ? (
              <group>
                <ShortLight
                  bladeRotation={bladeRotation}
                  rotation={led_rotation}
                  scale={scale_l}
                  position={[led_x, led_height, (depth * unit) / 4]}
                />
                <ShortLight
                  bladeRotation={bladeRotation}
                  rotation={led_rotation}
                  scale={scale_l}
                  position={[led_x, led_height, -(depth * unit) / 4]}
                />
              </group>
            ) : (
              <LongLight
                bladeRotation={bladeRotation}
                position={[led_x, led_height, 0]}
                rotation={led_rotation}
                scale={scale_l}
              />
            )
          ) : null}
        </group>
      );
    }
  }

  if (width > mid_length_limit[0]) {
    for (let i = 0; i < div_count; i = i + 1) {
      if (i === div_count - 1) continue;
      if (props.isLarge && props.doubleSystem) {
        if (props.origin && !props.systemVisible[1]) continue;
        if (!props.origin && !props.systemVisible[3]) continue;
      }
      const isLight = i % 3 === 2 && visible ? true : false;
      if (props.isTexture) {
        list.push(
          <group
            key={100 + div_count + i}
            position={[
              px2 -
                i * div_width * unit * offset_unit -
                offset_direction +
                bias1 +
                bias2,
              py,
              pz,
            ]}
          >
            {props.doubleSystem && props.origin && (
              <group rotation={[0, -Math.PI / 2, 0]} position={position_middle}>
                <mesh
                  castShadow
                  geometry={nodes.middleseperator2on1.geometry}
                  material={
                    props.isMiddleTexture ? middle_texture : materials.profile
                  }
                  material-color={middleColor}
                  scale={scale_blade}
                />
              </group>
            )}
            <group rotation={rotation3}>
              <mesh
                castShadow
                geometry={nodes.blade1.geometry}
                material={material_texture}
                material-color={color}
                scale={scale}
                position={[0, -3, 0]}
              />
              <mesh
                castShadow
                geometry={nodes.blade1left.geometry}
                material={material_texture}
                material-color={color}
                scale={scale}
                position={[0, 0, bladeDepth]}
              />
              <mesh
                castShadow
                geometry={nodes.blade1right.geometry}
                position={[0, 0, -bladeDepth]}
                material={material_texture}
                material-color={color}
                scale={scale}
              />
            </group>

            {isLight ? (
              i % 6 === 2 ? (
                <group>
                  <ShortLight
                    bladeRotation={bladeRotation}
                    rotation={led_rotation}
                    scale={scale_l}
                    position={[led_x, led_height, (depth * unit) / 4]}
                  />
                  <ShortLight
                    bladeRotation={bladeRotation}
                    rotation={led_rotation}
                    scale={scale_l}
                    position={[led_x, led_height, -(depth * unit) / 4]}
                  />
                </group>
              ) : (
                <LongLight
                  bladeRotation={bladeRotation}
                  position={[led_x, led_height, 0]}
                  rotation={led_rotation}
                  scale={scale_l}
                />
              )
            ) : null}
          </group>
        );
      } else {
        list.push(
          <group key={div_count + i}>
            <group
              position={[
                px2 -
                  i * div_width * unit * offset_unit -
                  offset_direction +
                  bias1 +
                  bias2,
                py,
                pz,
              ]}
            >
              {props.doubleSystem && props.origin && (
                <group
                  rotation={[0, -Math.PI / 2, 0]}
                  position={position_middle}
                >
                  <mesh
                    castShadow
                    geometry={nodes.middleseperator2on1.geometry}
                    material={
                      props.isMiddleTexture ? middle_texture : materials.profile
                    }
                    material-color={middleColor}
                    scale={scale_blade}
                  />
                </group>
              )}
              <group rotation={rotation3}>
                <mesh
                  castShadow
                  geometry={nodes.blade1.geometry}
                  material={material_blade}
                  material-color={color}
                  scale={scale}
                  position={[0, -3, 0]}
                />
                <mesh
                  castShadow
                  geometry={nodes.blade1left.geometry}
                  material={material_blade}
                  material-color={color}
                  scale={scale}
                  position={[0, 0, bladeDepth]}
                />
                <mesh
                  castShadow
                  geometry={nodes.blade1right.geometry}
                  position={[0, 0, -bladeDepth]}
                  material={material_blade}
                  material-color={color}
                  scale={scale}
                />
              </group>
              {isLight ? (
                i % 6 === 2 ? (
                  <group>
                    <ShortLight
                      bladeRotation={bladeRotation}
                      rotation={led_rotation}
                      scale={scale_l}
                      position={[led_x, led_height, (depth * unit) / 4]}
                    />
                    <ShortLight
                      bladeRotation={bladeRotation}
                      rotation={led_rotation}
                      scale={scale_l}
                      position={[led_x, led_height, -(depth * unit) / 4]}
                    />
                  </group>
                ) : (
                  <LongLight
                    bladeRotation={bladeRotation}
                    position={[led_x, led_height, 0]}
                    rotation={led_rotation}
                    scale={scale_l}
                  />
                )
              ) : null}
            </group>
          </group>
        );
      }
    }
  }

  return (
    <group
      position={[/* isLS ? 2.5 :  */ -4.5, dh1 * unit, dd * unit]}
      renderOrder={1}
    >
      {list}
    </group>
  );
}

export function TopDiv2(props) {
  const {
    nodes,
    color,
    visible,
    bladeRotation,
    bladeType,
    materials,
    middleColor,
  } = props;
  const { width, depth, height1 } = props.length;
  const {
    int_width,
    int_depth,
    int_height1,
    int_div_width,
    top_div_length,
    mid_length_limit,
    unit,
    column_thickness,
  } = props.int_length;
  const isLS = props.modelID > 10;

  const dw = width - int_width;
  const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  const delta = int_depth - top_div_length - 10;
  // const bladeDepth = (depth / 2 - 97) * unit

  const sd =
    width > mid_length_limit[1]
      ? (width / 3 - column_thickness) / top_div_length
      : width > mid_length_limit[0]
      ? (width / 2 - column_thickness) / top_div_length
      : (width - column_thickness) / top_div_length;
  const bladeDepth = ((sd * top_div_length) / 1.97) * unit;
  const list = [];
  const div_count = parseInt(depth / int_div_width) - 1;
  const div_width = depth / div_count;

  // const bias =
  //   props.doubleSystem === false || props.origin === false
  //     ? 0
  //     : (column_thickness / 2 - 80);
  const bias =
    props.doubleSystem === false
      ? 0
      : props.origin === true
      ? column_thickness / 2 - 55
      : column_thickness / 2 - 112;
  const px = (int_width / 2 - column_thickness / 4) * unit;
  const px2 = (int_width / 2) * unit - width * unit;
  // const py = 236.52;
  const py = 243.82;
  // const pz = -10.23 - bias * unit;
  const pz = 22.23 - bias * unit - depth * unit;
  // const scale = [0.1, (0.1 * div_width) / 200, 0.1 * sd];
  // const scale = [0.1, (0.1 * div_width) * 0.737 / 200, 0.1 * 0.745 * sd];
  const scale = [(-0.1 * div_width) / 200, 0.1 * 0.737, 0.1 * 0.745 * sd];
  const scale_blade = [(-0.1 * div_width) / 200, 0.1, 0.155];
  const scale_l = [1, div_width / 200, sd];
  const rotation = [0, 0, (bladeRotation / 180) * Math.PI];
  const rotation2 = [0, 0, (bladeRotation / 180) * Math.PI - Math.PI / 2];
  const rotation3 = [0, Math.PI / 2, 0];

  const led_x = 2.4;
  const led_height = -2.15;
  const led_rotation = [0, 0, (-bladeRotation / 180) * Math.PI];

  const scale3 = [
    (-0.1 * div_width) / 200,
    0.1 * 0.737,
    0.1 * 0.745 * 0.997 * sd,
  ];

  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const structure_texture = useTexture(props.structure_texture);
  structure_texture.wrapS = THREE.RepeatWrapping;
  structure_texture.wrapT = THREE.RepeatWrapping;
  structure_texture.repeat.set(xrepeat, yrepeat);
  structure_texture.rotation = Math.PI / 2;
  const middle_texture = new THREE.MeshPhysicalMaterial({
    map: structure_texture,
  });

  const material_blade = new THREE.MeshPhysicalMaterial();

  const blade_direction =
    width > mid_length_limit[0] ||
    props.modelID === 1 ||
    props.modelID === 13 ||
    props.modelID === 15 ||
    props.modelID === 17
      ? 0
      : props.blades.direction;

  const offset_unit =
    props.modelID === 4 || props.modelID === 14
      ? (100 - props.blades.offset) / 100
      : 1;
  const offset_direction =
    (props.modelID === 4 || props.modelID === 15) &&
    width <= mid_length_limit[0]
      ? blade_direction == 0
        ? 0
        : (width > mid_length_limit[0]
            ? width / 2 - div_width * 2
            : width - div_width * 2) *
          unit *
          (1 - offset_unit) *
          -1
      : 0;
  const bias1 =
    width <= mid_length_limit[0]
      ? 0
      : width <= mid_length_limit[1]
      ? -(width / 4) * unit - 3
      : -(width / 3) * unit - 3;
  const bias2 =
    width <= mid_length_limit[0]
      ? 0
      : width <= mid_length_limit[1]
      ? (width / 2) * unit + 3.5
      : (width / 3) * unit + 3.5;
  const bias3 =
    width <= mid_length_limit[0]
      ? 0
      : width <= mid_length_limit[1]
      ? 0
      : (width / 3) * unit + 3.5;
  const position_middle = [5, 0, (depth / 2 - 57.5) * unit];

  for (let i = 0; i < div_count * 1; i = i + 1) {
    if (i === div_count - 1) continue;
    if (i > div_count && i === div_count * 2 - 1) continue;
    if (props.isLarge && props.doubleSystem) {
      if (props.origin && !props.systemVisible[0]) continue;
      if (!props.origin && !props.systemVisible[2]) continue;
    }
    const isLight = i % 3 === 2 && visible ? true : false;

    if (props.isTexture) {
      list.push(
        <group
          position={[
            px + bias1,
            py,
            pz + i * div_width * unit * offset_unit - offset_direction,
          ]}
          key={i + 100}
        >
          {props.doubleSystem && props.origin && (
            <group rotation={rotation3} position={position_middle}>
              <mesh
                castShadow
                geometry={nodes.middleseperator2on1.geometry}
                material={
                  props.isMiddleTexture ? middle_texture : materials.profile
                }
                material-color={middleColor}
                scale={scale_blade}
              />
            </group>
          )}
          <group rotation={rotation3}>
            <mesh
              castShadow
              geometry={nodes.blade1.geometry}
              material={material_texture}
              scale={scale}
              material-color={color}
              position={[0, -3, 0]}
            />
            <mesh
              castShadow
              geometry={nodes.blade1left.geometry}
              material={material_texture}
              material-color={color}
              scale={scale}
              position={[0, 0, bladeDepth]}
            />
            <mesh
              castShadow
              geometry={nodes.blade1right.geometry}
              position={[0, 0, -bladeDepth]}
              material={material_texture}
              material-color={color}
              scale={scale}
            />
          </group>
          {isLight ? (
            i % 6 === 2 ? (
              <group>
                <ShortLight
                  bladeRotation={bladeRotation}
                  rotation={led_rotation}
                  scale={scale_l}
                  position={[led_x, led_height, (depth * unit) / 4]}
                />
                <ShortLight
                  bladeRotation={bladeRotation}
                  rotation={led_rotation}
                  scale={scale_l}
                  position={[led_x, led_height, -(depth * unit) / 4]}
                />
              </group>
            ) : (
              <LongLight
                bladeRotation={bladeRotation}
                position={[led_x, led_height, 0]}
                rotation={led_rotation}
                scale={scale_l}
              />
            )
          ) : null}
        </group>
      );
    } else {
      list.push(
        <group
          position={[
            px + bias1,
            py,
            pz + i * div_width * unit * offset_unit - offset_direction,
          ]}
          key={i}
        >
          {props.doubleSystem && props.origin && (
            <group rotation={[0, -Math.PI / 2, 0]} position={position_middle}>
              <mesh
                castShadow
                geometry={nodes.middleseperator2on1.geometry}
                material={
                  props.isMiddleTexture ? middle_texture : materials.profile
                }
                material-color={middleColor}
                // scale={scale}
                scale={scale_blade}
              />
            </group>
          )}
          <group rotation={rotation3}>
            <mesh
              castShadow
              geometry={nodes.blade1.geometry}
              material={material_blade}
              material-color={color}
              scale={scale}
              position={[0, -3, 0]}
            />
            <mesh
              castShadow
              geometry={nodes.blade1left.geometry}
              material={material_blade}
              material-color={color}
              scale={scale}
              position={[0, 0, bladeDepth]}
            />
            <mesh
              castShadow
              geometry={nodes.blade1right.geometry}
              position={[0, 0, -bladeDepth]}
              material={material_blade}
              material-color={color}
              scale={scale}
            />
          </group>
          {isLight ? (
            i % 6 === 2 ? (
              <group>
                <ShortLight
                  bladeRotation={bladeRotation}
                  rotation={led_rotation}
                  scale={scale_l}
                  position={[led_x, led_height, (depth * unit) / 4]}
                />
                <ShortLight
                  bladeRotation={bladeRotation}
                  rotation={led_rotation}
                  scale={scale_l}
                  position={[led_x, led_height, -(depth * unit) / 4]}
                />
              </group>
            ) : (
              <LongLight
                bladeRotation={bladeRotation}
                position={[led_x, led_height, 0]}
                rotation={led_rotation}
                scale={scale_l}
              />
            )
          ) : null}
        </group>
      );
    }
  }

  if (width > mid_length_limit[0]) {
    for (let i = 0; i < div_count; i = i + 1) {
      if (i === div_count - 1) continue;
      if (props.isLarge && props.doubleSystem) {
        if (props.origin && !props.systemVisible[1]) continue;
        if (!props.origin && !props.systemVisible[3]) continue;
      }
      const isLight = i % 3 === 2 && visible ? true : false;

      if (props.isTexture) {
        list.push(
          <group
            position={[
              px + bias1 + bias2,
              py,
              pz + i * div_width * unit * offset_unit - offset_direction,
            ]}
            key={100 + div_count + i}
          >
            {props.doubleSystem && props.origin && (
              <group rotation={[0, -Math.PI / 2, 0]} position={position_middle}>
                <mesh
                  castShadow
                  geometry={nodes.middleseperator2on1.geometry}
                  material={
                    props.isMiddleTexture ? middle_texture : materials.profile
                  }
                  material-color={middleColor}
                  scale={scale_blade}
                />
              </group>
            )}
            <group rotation={rotation3}>
              <mesh
                castShadow
                geometry={nodes.blade1.geometry}
                material={material_texture}
                material-color={color}
                scale={scale}
                position={[0, -3, 0]}
              />
              <mesh
                castShadow
                geometry={nodes.blade1left.geometry}
                material={material_texture}
                material-color={color}
                scale={scale}
                position={[0, 0, bladeDepth]}
              />
              <mesh
                castShadow
                geometry={nodes.blade1right.geometry}
                position={[0, 0, -bladeDepth]}
                material={material_texture}
                material-color={color}
                scale={scale}
              />
            </group>
            {isLight ? (
              i % 6 === 2 ? (
                <group>
                  <ShortLight
                    bladeRotation={bladeRotation}
                    rotation={led_rotation}
                    scale={scale_l}
                    position={[led_x, led_height, (depth * unit) / 4]}
                  />
                  <ShortLight
                    bladeRotation={bladeRotation}
                    rotation={led_rotation}
                    scale={scale_l}
                    position={[led_x, led_height, -(depth * unit) / 4]}
                  />
                </group>
              ) : (
                <LongLight
                  bladeRotation={bladeRotation}
                  position={[led_x, led_height, 0]}
                  rotation={led_rotation}
                  scale={scale_l}
                />
              )
            ) : null}
          </group>
        );
      } else {
        list.push(
          <group key={div_count + i}>
            <group
              position={[
                px + bias1 + bias2,
                py,
                pz + i * div_width * unit * offset_unit - offset_direction,
              ]}
            >
              {props.doubleSystem && props.origin && (
                <group
                  rotation={[0, -Math.PI / 2, 0]}
                  position={position_middle}
                >
                  <mesh
                    castShadow
                    geometry={nodes.middleseperator2on1.geometry}
                    material={
                      props.isMiddleTexture ? middle_texture : materials.profile
                    }
                    material-color={middleColor}
                    scale={scale_blade}
                  />
                </group>
              )}
              <group rotation={rotation3}>
                <mesh
                  castShadow
                  geometry={nodes.blade1.geometry}
                  material={material_blade}
                  material-color={color}
                  scale={scale}
                  position={[0, -3, 0]}
                />
                <mesh
                  castShadow
                  geometry={nodes.blade1left.geometry}
                  material={material_blade}
                  material-color={color}
                  scale={scale}
                  position={[0, 0, bladeDepth]}
                />
                <mesh
                  castShadow
                  geometry={nodes.blade1right.geometry}
                  position={[0, 0, -bladeDepth]}
                  material={material_blade}
                  material-color={color}
                  scale={scale}
                />
              </group>
              {isLight ? (
                i % 6 === 2 ? (
                  <group>
                    <ShortLight
                      bladeRotation={bladeRotation}
                      rotation={led_rotation}
                      scale={scale_l}
                      position={[led_x, led_height, (depth * unit) / 4]}
                    />
                    <ShortLight
                      bladeRotation={bladeRotation}
                      rotation={led_rotation}
                      scale={scale_l}
                      position={[led_x, led_height, -(depth * unit) / 4]}
                    />
                  </group>
                ) : (
                  <LongLight
                    bladeRotation={bladeRotation}
                    position={[led_x, led_height, 0]}
                    rotation={led_rotation}
                    scale={scale_l}
                  />
                )
              ) : null}
            </group>
          </group>
        );
      }
    }
  }

  if (width > mid_length_limit[1]) {
    for (let i = 0; i < div_count; i = i + 1) {
      if (i === div_count - 1) continue;
      if (props.isLarge && props.doubleSystem) {
        if (props.origin && !props.systemVisible[1]) continue;
        if (!props.origin && !props.systemVisible[3]) continue;
      }
      const isLight = i % 3 === 2 && visible ? true : false;

      if (props.isTexture) {
        list.push(
          <group
            position={[
              px + bias1 + bias2 + bias3,
              py,
              pz + i * div_width * unit * offset_unit - offset_direction,
            ]}
            key={100 + div_count * 2 + i}
          >
            {props.doubleSystem && props.origin && (
              <group rotation={[0, -Math.PI / 2, 0]} position={position_middle}>
                <mesh
                  castShadow
                  geometry={nodes.middleseperator2on1.geometry}
                  material={
                    props.isMiddleTexture ? middle_texture : materials.profile
                  }
                  material-color={middleColor}
                  scale={scale_blade}
                />
              </group>
            )}
            <group rotation={rotation3}>
              <mesh
                castShadow
                geometry={nodes.blade1.geometry}
                material={material_texture}
                material-color={color}
                scale={scale}
                position={[0, -3, 0]}
              />
              <mesh
                castShadow
                geometry={nodes.blade1left.geometry}
                material={material_texture}
                material-color={color}
                scale={scale}
                position={[0, 0, bladeDepth]}
              />
              <mesh
                castShadow
                geometry={nodes.blade1right.geometry}
                position={[0, 0, -bladeDepth]}
                material={material_texture}
                material-color={color}
                scale={scale}
              />
            </group>
            {isLight ? (
              i % 6 === 2 ? (
                <group>
                  <ShortLight
                    bladeRotation={bladeRotation}
                    rotation={led_rotation}
                    scale={scale_l}
                    position={[led_x, led_height, (depth * unit) / 4]}
                  />
                  <ShortLight
                    bladeRotation={bladeRotation}
                    rotation={led_rotation}
                    scale={scale_l}
                    position={[led_x, led_height, -(depth * unit) / 4]}
                  />
                </group>
              ) : (
                <LongLight
                  bladeRotation={bladeRotation}
                  position={[led_x, led_height, 0]}
                  rotation={led_rotation}
                  scale={scale_l}
                />
              )
            ) : null}
          </group>
        );
      } else {
        list.push(
          <group key={div_count * 2 + i}>
            <group
              position={[
                px + bias1 + bias2 + bias3,
                py,
                pz + i * div_width * unit * offset_unit - offset_direction,
              ]}
            >
              {props.doubleSystem && props.origin && (
                <group
                  rotation={[0, -Math.PI / 2, 0]}
                  position={position_middle}
                >
                  <mesh
                    castShadow
                    geometry={nodes.middleseperator2on1.geometry}
                    material={
                      props.isMiddleTexture ? middle_texture : materials.profile
                    }
                    material-color={middleColor}
                    scale={scale_blade}
                  />
                </group>
              )}
              <group rotation={rotation3}>
                <mesh
                  castShadow
                  geometry={nodes.blade1.geometry}
                  material={material_blade}
                  material-color={color}
                  scale={scale}
                  position={[0, -3, 0]}
                />
                <mesh
                  castShadow
                  geometry={nodes.blade1left.geometry}
                  material={material_blade}
                  material-color={color}
                  scale={scale}
                  position={[0, 0, bladeDepth]}
                />
                <mesh
                  castShadow
                  geometry={nodes.blade1right.geometry}
                  position={[0, 0, -bladeDepth]}
                  material={material_blade}
                  material-color={color}
                  scale={scale}
                />
              </group>
              {isLight ? (
                i % 6 === 2 ? (
                  <group>
                    <ShortLight
                      bladeRotation={bladeRotation}
                      rotation={led_rotation}
                      scale={scale_l}
                      position={[led_x, led_height, (depth * unit) / 4]}
                    />
                    <ShortLight
                      bladeRotation={bladeRotation}
                      rotation={led_rotation}
                      scale={scale_l}
                      position={[led_x, led_height, -(depth * unit) / 4]}
                    />
                  </group>
                ) : (
                  <LongLight
                    bladeRotation={bladeRotation}
                    position={[led_x, led_height, 0]}
                    rotation={led_rotation}
                    scale={scale_l}
                  />
                )
              ) : null}
            </group>
          </group>
        );
      }
    }
  }

  return (
    <group
      position={[/* isLS ? 2.5 :  */ -4.5, dh1 * unit, dd * unit]}
      renderOrder={1}
    >
      {list}
    </group>
  );
}

export function TopDiv3(props) {
  const {
    nodes,
    color,
    visible,
    bladeRotation,
    bladeType,
    materials,
    middleColor,
  } = props;
  const { width, depth, height1 } = props.length;
  const {
    int_width,
    int_depth,
    int_height1,
    int_div_width,
    top_div_length,
    mid_length_limit,
    unit,
    column_thickness,
  } = props.int_length;
  const isLS = props.modelID > 10;

  const dw = width - int_width;
  const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  const delta = int_depth - top_div_length - 10;

  const blade_length = 2810;
  const blade_width = 214;
  const sd =
    props.doubleSystem === false
      ? (depth - column_thickness * 1.4) / blade_length
      : (depth - column_thickness * 1.6) / blade_length;

  const list = [];
  const div_count =
    parseInt(
      (width <= mid_length_limit[0] ? width / 0.727 : width / 2 + 100) /
        blade_width
    ) - 1;
  const div_width =
    (width <= mid_length_limit[0] ? width : width / 2 + 100) / div_count;

  // const bias =
  //   props.doubleSystem === false || props.origin === false
  //     ? 0
  //     : (column_thickness / 2 - 80);
  const bias =
    props.doubleSystem === false
      ? 0
      : props.origin === true
      ? column_thickness / 2 - 55
      : column_thickness / 2 - 112;
  const px = 11.1 - (dw * unit) / 2;
  const px2 = ((width * 2 - dw) * unit) / 2 - 22.1;
  // const py = 236.52;
  const py = 243.82;
  // const pz = -10.23 - bias * unit;
  // const pz = -7.23 - bias * unit - (depth / 2 - column_thickness / 2) * unit;
  const pz =
    props.doubleSystem === false
      ? -(column_thickness * 0.62) * unit
      : props.origin === false
      ? -(column_thickness * 0.62) * unit
      : -(column_thickness * 0.83) * unit;
  // const scale = [0.1, (0.1 * div_width) / 200, 0.1 * sd];
  // const scale = [0.1, (0.1 * div_width) * 0.737 / 200, 0.1 * 0.745 * sd];
  const scale = [(-0.1 * div_width) / 200, 0.1, 0.1 * sd];
  const scale_blade = [(-0.1 * div_width) / 200, 0.1, 0.155];
  const scale_l = [1, div_width / 200, sd];
  const rotation = [0, 0, (bladeRotation / 180) * Math.PI];
  const rotation2 = [0, 0, (bladeRotation / 180) * Math.PI - Math.PI / 2];
  const rotation3 = [0, 0, (-bladeRotation / 180) * Math.PI + Math.PI / 2];

  const led_x = 2.4;
  const led_height = -2.15;
  const led_rotation = [0, 0, (-bladeRotation / 180) * Math.PI];
  const scale3 = [
    (-0.1 * div_width) / 200,
    0.1 * 0.737,
    0.1 * 0.745 * 0.997 * sd,
  ];

  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  // texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const structure_texture = useTexture(props.structure_texture);
  structure_texture.wrapS = THREE.RepeatWrapping;
  structure_texture.wrapT = THREE.RepeatWrapping;
  structure_texture.repeat.set(xrepeat, yrepeat);
  structure_texture.rotation = Math.PI / 2;
  const middle_texture = new THREE.MeshPhysicalMaterial({
    map: structure_texture,
  });

  // const bladeDepth = (depth / 2 - 97) * unit
  const material_blade = new THREE.MeshPhysicalMaterial();

  const blade_direction =
    width > mid_length_limit[0] ||
    props.modelID === 1 ||
    props.modelID === 13 ||
    props.modelID === 15 ||
    props.modelID === 17
      ? 0
      : props.blades.direction;

  const offset_unit =
    props.modelID === 4 || props.modelID === 14
      ? (100 - props.blades.offset) / 100
      : 1;
  const offset_direction =
    (props.modelID === 4 || props.modelID === 14) &&
    width <= mid_length_limit[0]
      ? blade_direction == 0
        ? 0
        : (width > mid_length_limit[0]
            ? width / 2 - div_width * 2
            : width - div_width * 2) *
          unit *
          (1 - offset_unit) *
          -1
      : 0;
  var bias1 = 0;
  const bias2 = 0;
  const position_middle = [5, 0, -pz - column_thickness * 0.45 * unit];

  for (let i = 0; i < div_count * 1; i = i + 1) {
    if (i === div_count - 1) continue;
    if (i > div_count && i === div_count * 2 - 1) continue;
    if (props.isLarge && props.doubleSystem) {
      if (props.origin && !props.systemVisible[0]) continue;
      if (!props.origin && !props.systemVisible[2]) continue;
    }
    const isLight = i % 3 === 2 && visible ? true : false;

    if (props.isTexture) {
      list.push(
        <group
          position={[
            px +
              i * div_width * unit * offset_unit -
              offset_direction +
              bias1 +
              bias2,
            py,
            pz,
          ]}
          key={i + 100}
        >
          {props.doubleSystem && props.origin && (
            <group rotation={[0, -Math.PI / 2, 0]} position={position_middle}>
              <mesh
                castShadow
                geometry={nodes.middleseperator2on1.geometry}
                material={
                  props.isMiddleTexture ? middle_texture : materials.profile
                }
                material-color={middleColor}
                scale={scale_blade}
              />
            </group>
          )}
          <group rotation={rotation3}>
            <mesh
              castShadow
              geometry={nodes.bladetype2.geometry}
              material={material_texture}
              scale={scale}
              material-color={color}
              position={[0, -3, 0]}
            />
          </group>
          {isLight ? (
            i % 6 === 2 ? (
              <group>
                <ShortLight
                  bladeRotation={bladeRotation}
                  rotation={led_rotation}
                  scale={scale_l}
                  position={[led_x, led_height, -(depth * unit) / 4]}
                />
                <ShortLight
                  bladeRotation={bladeRotation}
                  rotation={led_rotation}
                  scale={scale_l}
                  position={[led_x, led_height, -(3 * (depth * unit)) / 4]}
                />
              </group>
            ) : (
              <LongLight
                bladeRotation={bladeRotation}
                position={[led_x, led_height, -(depth * unit) / 2]}
                rotation={led_rotation}
                scale={scale_l}
              />
            )
          ) : null}
        </group>
      );
    } else {
      list.push(
        <group
          position={[
            px +
              i * div_width * unit * offset_unit -
              offset_direction +
              bias1 +
              bias2,
            py,
            pz,
          ]}
          key={i}
        >
          {props.doubleSystem && props.origin && (
            <group rotation={[0, -Math.PI / 2, 0]} position={position_middle}>
              <mesh
                castShadow
                geometry={nodes.middleseperator2on1.geometry}
                material={
                  props.isMiddleTexture ? middle_texture : materials.profile
                }
                material-color={middleColor}
                // scale={scale}
                scale={scale_blade}
              />
            </group>
          )}
          <group rotation={rotation3}>
            <mesh
              castShadow
              geometry={nodes.bladetype2.geometry}
              material={material_blade}
              material-color={color}
              scale={scale}
              position={[0, -3, 0]}
            />
          </group>

          {isLight ? (
            i % 6 === 2 ? (
              <group>
                <ShortLight
                  bladeRotation={bladeRotation}
                  rotation={led_rotation}
                  scale={scale_l}
                  position={[led_x, led_height, -(depth * unit) / 4]}
                />
                <ShortLight
                  bladeRotation={bladeRotation}
                  rotation={led_rotation}
                  scale={scale_l}
                  position={[led_x, led_height, -(3 * (depth * unit)) / 4]}
                />
              </group>
            ) : (
              <LongLight
                bladeRotation={bladeRotation}
                position={[led_x, led_height, -(depth * unit) / 2]}
                rotation={led_rotation}
                scale={scale_l}
              />
            )
          ) : null}
        </group>
      );
    }
  }

  if (width > mid_length_limit[0]) {
    for (let i = 0; i < div_count; i = i + 1) {
      if (i === div_count - 1) continue;
      if (props.isLarge && props.doubleSystem) {
        if (props.origin && !props.systemVisible[1]) continue;
        if (!props.origin && !props.systemVisible[3]) continue;
      }
      const isLight = i % 3 === 2 && visible ? true : false;
      if (props.isTexture) {
        list.push(
          <group
            position={[
              px2 -
                i * div_width * unit * offset_unit -
                offset_direction +
                bias1 +
                bias2,
              py,
              pz,
            ]}
            key={100 + div_count + i}
          >
            {props.doubleSystem && props.origin && (
              <group rotation={[0, -Math.PI / 2, 0]} position={position_middle}>
                <mesh
                  castShadow
                  geometry={nodes.middleseperator2on1.geometry}
                  material={
                    props.isMiddleTexture ? middle_texture : materials.profile
                  }
                  material-color={middleColor}
                  scale={scale_blade}
                />
              </group>
            )}
            <group rotation={rotation3}>
              <mesh
                castShadow
                geometry={nodes.bladetype2.geometry}
                material={material_texture}
                material-color={color}
                scale={scale}
                position={[0, -3, 0]}
              />
            </group>

            {isLight ? (
              i % 6 === 2 ? (
                <group>
                  <ShortLight
                    bladeRotation={bladeRotation}
                    rotation={led_rotation}
                    scale={scale_l}
                    position={[led_x, led_height, -(depth * unit) / 4]}
                  />
                  <ShortLight
                    bladeRotation={bladeRotation}
                    rotation={led_rotation}
                    scale={scale_l}
                    position={[led_x, led_height, -(3 * depth * unit) / 4]}
                  />
                </group>
              ) : (
                <LongLight
                  bladeRotation={bladeRotation}
                  position={[led_x, led_height, -(depth * unit) / 2]}
                  rotation={led_rotation}
                  scale={scale_l}
                />
              )
            ) : null}
          </group>
        );
      } else {
        list.push(
          <group key={div_count + i}>
            <group
              position={[
                px2 - i * div_width * unit * offset_unit - offset_direction,
                py,
                pz,
              ]}
            >
              {props.doubleSystem && props.origin && (
                <group
                  rotation={[0, -Math.PI / 2, 0]}
                  position={position_middle}
                >
                  <mesh
                    castShadow
                    geometry={nodes.middleseperator2on1.geometry}
                    material={
                      props.isMiddleTexture ? middle_texture : materials.profile
                    }
                    material-color={middleColor}
                    scale={scale_blade}
                  />
                </group>
              )}
              <group rotation={rotation3}>
                <mesh
                  castShadow
                  geometry={nodes.bladetype2.geometry}
                  material={material_blade}
                  material-color={color}
                  scale={scale}
                  position={[0, -3, 0]}
                />
              </group>
              {isLight ? (
                i % 6 === 2 ? (
                  <group>
                    <ShortLight
                      bladeRotation={bladeRotation}
                      rotation={led_rotation}
                      scale={scale_l}
                      position={[led_x, led_height, -(depth * unit) / 4]}
                    />
                    <ShortLight
                      bladeRotation={bladeRotation}
                      rotation={led_rotation}
                      scale={scale_l}
                      position={[led_x, led_height, -(3 * depth * unit) / 4]}
                    />
                  </group>
                ) : (
                  <LongLight
                    bladeRotation={bladeRotation}
                    position={[led_x, led_height, (-depth / 2) * unit]}
                    rotation={led_rotation}
                    scale={scale_l}
                  />
                )
              ) : null}
            </group>
          </group>
        );
      }
    }
  }

  return (
    <group
      position={[/* isLS ? 2.5 :  */ -4.5, dh1 * unit, dd * unit]}
      renderOrder={1}
    >
      {list}
    </group>
  );
}

export function CapLeft(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, column_thickness, unit } = props.int_length;
  const dw = width - int_width;
  const dh1 = height1 - int_height1;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const px =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[1]) ||
      (!props.origin && !props.systemVisible[3]))
      ? (width + column_thickness) / 2 - column_thickness + 65
      : 0;

  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    !props.systemVisible[3]
  )
    return null;

  if (
    // props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    props.systemVisible[1]
  )
    return null;

  return (
    <group position={[(dw * unit) / 2 - px * unit, dh1 * unit, 0]}>
      <mesh
        castShadow
        geometry={nodes.corner1.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        // position={[389.82, 221.1, -288.24]}
        position={[389.82 - 2.68, 235.27, -288.24 - 2.04]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={[-0.1, 0.1, 0.1]}
        material-color={color}
      />
    </group>
  );
}

export function CapRight(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, column_thickness, unit } = props.int_length;
  const dw = width - int_width;
  const dh1 = height1 - int_height1;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const px =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[0]) ||
      (!props.origin && !props.systemVisible[2]))
      ? (width + column_thickness) / 2 - column_thickness + 18
      : 0;

  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    !props.systemVisible[2]
  )
    return null;

  if (
    // props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    props.systemVisible[0]
  )
    return null;

  return (
    <group position={[(-dw * unit) / 2 + px * unit, dh1 * unit, 0]}>
      <mesh
        castShadow
        geometry={nodes.corner3.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        // position={[10.65, 235.27, -288.24]}
        position={[10.65 - 14.81, 235.27, -288.24 - 2.06]}
        material-color={color}
        // rotation={[-Math.PI / 2, 0, Math.PI]}
        rotation={[-Math.PI / 2, 0, Math.PI]}
        scale={[-0.1, 0.1, 0.1]}
      />
    </group>
  );
}

export function BackRight(props) {
  const { nodes, materials, color } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, int_height1, column_thickness, unit } =
    props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const px =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[0]) ||
      (!props.origin && !props.systemVisible[2]))
      ? (width + column_thickness) / 2 - column_thickness + 18
      : 0;

  if (
    props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    !props.systemVisible[0]
  )
    return null;

  if (
    // props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    props.systemVisible[2]
  )
    return null;
  return (
    <group position={[(-dw * unit) / 2 + px * unit, dh1 * unit, dd * unit]}>
      <mesh
        castShadow
        geometry={nodes.corner5.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[10.65 - 14.81, 235.27, -9.54 + 2.28]}
        material-color={color}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.1}
      />
    </group>
  );
}

export function BackLeft(props) {
  const { nodes, materials, color } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, int_height1, column_thickness, unit } =
    props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const px =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[1]) ||
      (!props.origin && !props.systemVisible[3]))
      ? (width + column_thickness) / 2 - column_thickness + 65
      : 0;

  if (
    props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    !props.systemVisible[1]
  )
    return null;

  if (
    // props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    props.systemVisible[3]
  )
    return null;
  return (
    <group position={[(dw * unit) / 2 - px * unit, dh1 * unit, dd * unit]}>
      <mesh
        castShadow
        geometry={nodes.corner8.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        // position={[389.82, 221.1, -9.54]}
        position={[389.82 - 2.68, 235.27, -9.54 + 2.24]}
        material-color={color}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.1, 0.1, 0.1]}
      />
    </group>
  );
}

export function FootLeft(props) {
  const { nodes, materials, color, columns } = props;
  const { width } = props.length;
  const { int_width, unit } = props.int_length;
  const dw = width - int_width;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  if (
    props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    !props.systemVisible[1]
  )
    return null;
  if (props.doubleSystem && !props.origin) return null;

  return (
    <group position={[(dw * unit) / 2 - columns.pos[0] * unit - 4.87, 0, 0.13]}>
      <mesh
        castShadow
        geometry={nodes.foot1.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[391.98, 10.35, -290.4]}
        material-color={color}
        rotation={[-Math.PI / 2, 0, Math.PI]}
        scale={0.1}
      />
    </group>
  );
}

export function FootRight(props) {
  const { nodes, materials, color, columns } = props;
  const { width } = props.length;
  const { int_width, unit } = props.int_length;
  const dw = width - int_width;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  if (
    props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    !props.systemVisible[0]
  )
    return null;
  if (props.doubleSystem && !props.origin) return null;

  return (
    <group
      position={[(-dw * unit) / 2 + columns.pos[1] * unit - 12.67, 0, 0.13]}
    >
      <mesh
        castShadow
        geometry={nodes.foot3.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[8.5, 10.35, -290.4]}
        material-color={color}
        rotation={[-Math.PI / 2, 0, Math.PI]}
        scale={0.1}
      />
    </group>
  );
}

export function FootBackLeft(props) {
  const { nodes, materials, color, columns } = props;
  const { width, depth } = props.length;
  const { int_width, int_depth, unit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    !props.systemVisible[2]
  )
    return null;
  var alpha = Math.PI;
  if (
    props.doubleSystem &&
    props.systemVisible[0] &&
    props.systemVisible[2] &&
    props.origin
  ) {
    alpha = Math.PI / 2;
  }
  if (props.doubleSystem && !props.systemVisible[0] && props.origin)
    alpha = Math.PI;
  return (
    <group
      position={[
        (-dw * unit) / 2 + columns.pos[2] * unit - 12.67,
        0,
        dd * unit + 0.14,
      ]}
    >
      <mesh
        castShadow
        geometry={nodes.foot6.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[8.5, 10.35, -7.38]}
        material-color={color}
        rotation={[-Math.PI / 2, 0, alpha]}
        scale={0.1}
      />
    </group>
  );
}

export function FootBackRight(props) {
  const { nodes, materials, color, columns } = props;
  const { width, depth } = props.length;
  const { int_width, int_depth, unit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    !props.systemVisible[3]
  )
    return null;
  var alpha = Math.PI;
  if (
    props.doubleSystem &&
    props.systemVisible[1] &&
    props.systemVisible[3] &&
    props.origin
  )
    alpha = -Math.PI / 2;
  if (props.doubleSystem && !props.systemVisible[1] && props.origin)
    alpha = Math.PI;

  return (
    <group
      position={[(dw * unit) / 2 - columns.pos[3] * unit - 4.87, 0, dd * unit]}
    >
      <mesh
        castShadow
        geometry={nodes.foot8.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[391.98, 10.35, -7.38]}
        material-color={color}
        rotation={[-Math.PI / 2, 0, alpha]}
        // rotation={[-Math.PI / 2, 0, Math.PI]}
        scale={0.1}
      />
    </group>
  );
}

export function Foots(props) {
  const { nodes, materials, color, columns } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, unit, column_thickness, int_height1 } =
    props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const px1 = 391.98;
  const px2 = 8.5;
  const py = 10.35;
  const pz1 = -290.4;
  const pz2 = -7.38;
  const dh = height1 - int_height1;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const list = [];
  for (let i = 4; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      switch (columns.side[i]) {
        case 1:
          list.push(
            <mesh
              key={i}
              castShadow
              geometry={nodes.foot8.geometry}
              material={props.isTexture ? material_texture : materials.profile}
              position={[
                (dw * unit) / 2 +
                  px1 -
                  columns.pos[i] * unit +
                  (column_thickness * unit) / 2 -
                  10.87,
                py,
                pz1,
              ]}
              material-color={color}
              rotation={[-Math.PI / 2, 0, 0]}
              scale={0.1}
            />
          );
          break;
        case 2:
          list.push(
            <mesh
              key={i}
              castShadow
              geometry={nodes.foot8.geometry}
              material={props.isTexture ? material_texture : materials.profile}
              position={[
                (-dw * unit) / 2 + px2 - 10.87,
                py,
                pz1 + columns.pos[i] * unit - (column_thickness * unit) / 2,
              ]}
              material-color={color}
              rotation={[-Math.PI / 2, 0, Math.PI]}
              scale={0.1}
            />
          );
          break;
        case 3:
          list.push(
            <mesh
              key={i}
              castShadow
              geometry={nodes.foot8.geometry}
              material={props.isTexture ? material_texture : materials.profile}
              position={[
                (-dw * unit) / 2 +
                  px2 +
                  columns.pos[i] * unit -
                  (column_thickness * unit) / 2 -
                  10.87,
                py,
                pz2 + dd * unit,
              ]}
              material-color={color}
              rotation={[-Math.PI / 2, 0, Math.PI]}
              scale={0.1}
            />
          );
          break;
        case 4:
          list.push(
            <mesh
              key={i}
              castShadow
              geometry={nodes.foot8.geometry}
              material={props.isTexture ? material_texture : materials.profile}
              position={[
                (dw * unit) / 2 + px1 - 10.87,
                py,
                pz2 +
                  dd * unit -
                  columns.pos[i] * unit +
                  (column_thickness * unit) / 2,
              ]}
              material-color={color}
              rotation={[-Math.PI / 2, 0, 0]}
              scale={0.1}
            />
          );
          break;
        default:
          break;
      }
    }
  }
  return <group>{list}</group>;
}

export function Lights(props) {
  const { nodes, materials, visible } = props;
  const list = [];
  const px = 242.53;
  const py = 236.52;
  const pz = -10.23;

  for (let i = 1; i < 2; i = i + 1) {
    list.push(
      <group position={[px, py, pz]} key={i}>
        <mesh geometry={nodes.mesh_9_105.geometry} material={materials.Light} />
      </group>
    );
  }
  return visible ? <group>{list}</group> : null;
}

export const ShiftColumn = (column, selectedSide) => {
  state.columns.editing = true;
  state.columns.editingColumn = column;
  state.columns.editingSide = selectedSide;
  state.columns.added[asciiDif(column, "A")] = true;
  state.columns.adding = false;
  state.columns.side[asciiDif(column, "A")] = selectedSide;
};

export function Side(props) {
  const { width, depth, height1, height2 } = props.length;
  const { int_width, unit, oz } = props.int_length;
  const dw = width - int_width;

  if (props.current_side === 0) return null;
  switch (props.current_side) {
    case 1:
      return (
        <mesh
          position={[
            ((width - dw) * unit) / 2,
            (height1 * unit) / 2,
            -oz * unit,
          ]}
        >
          <boxGeometry args={[width * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>
      );
    case 2:
      return (
        <mesh
          position={[
            (-dw * unit) / 2,
            (height1 * unit) / 2,
            -oz * unit + (depth * unit) / 2,
          ]}
          rotation={[0, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>
      );
    case 3:
      return (
        <mesh
          position={[
            ((width - dw) * unit) / 2,
            (height1 * unit) / 2,
            -oz * unit + depth * unit,
          ]}
        >
          <boxGeometry args={[width * unit, height2 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>
      );
    case 4:
      return (
        <mesh
          position={[
            width * unit - (dw * unit) / 2,
            (height1 * unit) / 2,
            -oz * unit + (depth * unit) / 2,
          ]}
          rotation={[0, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>
      );
    default:
      return null;
  }
}

export const AddNewColumn = (columns, selectedSide) => {
  const c = "A";
  for (let i = 8; i < columns.added.length; i = i + 1) {
    if (columns.added[i] === false) {
      ShiftColumn(String.fromCharCode(c.charCodeAt(0) + i), selectedSide);
      break;
    }
  }
};

export function AddExtraColumns(props) {
  const { columns, columnFlag, secondHeight } = props;
  const { width, depth } = props.length;
  const { unit, oz, int_width } = props.int_length;
  const dw = width - int_width;

  return props.activeStep === 0 &&
    props.subStep === 1 &&
    columns.adding === true ? (
    <group>
      {columnFlag[4] === true ? (
        <Html
          scaleFactor={5}
          position={[((width - dw) * unit) / 2, 0, -oz * unit]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(1);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              AddNewColumn(columns, 1);
              props.setCurrentSide(0);
            }}
          >
            Back Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
      {!secondHeight ? (
        <Html
          scaleFactor={5}
          position={[(-dw * unit) / 2, 0, -oz * unit + (depth * unit) / 2]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(2);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              AddNewColumn(columns, 2);
              props.setCurrentSide(0);
            }}
          >
            Left Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
      {!secondHeight ? (
        <Html
          scaleFactor={5}
          position={[((width - dw) * unit) / 2, 0, -oz * unit + depth * unit]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(3);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              AddNewColumn(columns, 3);
              props.setCurrentSide(0);
            }}
          >
            Front Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
      {!secondHeight ? (
        <Html
          scaleFactor={5}
          position={[
            width * unit - (dw * unit) / 2,
            0,
            -oz * unit + (depth * unit) / 2,
          ]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(4);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              AddNewColumn(columns, 4);
              props.setCurrentSide(0);
            }}
          >
            Right Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
    </group>
  ) : null;
}

export function AddWalls(props) {
  const { walls, secondHeight } = props;
  const { width, depth } = props.length;
  const { unit, oz, int_width } = props.int_length;
  const dw = width - int_width;

  return walls.adding === true ? (
    <group>
      {walls.added[0] === false && props.origin ? (
        <Html
          scaleFactor={5}
          position={[((width - dw) * unit) / 2, 0, -oz * unit]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(1);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[0] = true;
              state.walls.adding = false;
            }}
          >
            Back Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
      {!secondHeight && walls.added[1] === false && props.origin ? (
        <Html
          scaleFactor={5}
          position={[(-dw * unit) / 2, 0, -oz * unit + (depth * unit) / 2]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(2);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[1] = true;
              state.walls.adding = false;
            }}
          >
            Left Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
      {!secondHeight && walls.added[2] === false && false ? (
        <Html
          scaleFactor={5}
          position={[((width - dw) * unit) / 2, 0, -oz * unit + depth * unit]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(3);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[2] = true;
              state.walls.adding = false;
            }}
          >
            Front Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
      {!secondHeight && walls.added[3] === false && props.origin ? (
        <Html
          scaleFactor={5}
          position={[
            width * unit - (dw * unit) / 2,
            0,
            -oz * unit + (depth * unit) / 2,
          ]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(4);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[3] = true;
              state.walls.adding = false;
            }}
          >
            Right Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
    </group>
  ) : null;
}

export function LengthInfo(props) {
  const { secondHeight } = props;
  const { width, depth, height1, height2 } = props.length;
  const {
    unit,
    oz,
    int_width,
    LineColor,
    LineEndColor,
    lengthEndLineWidth,
    column_thickness,
  } = props.int_length;
  if (!props.origin) return null;
  const real_depth = props.doubleSystem ? depth * 2 - column_thickness : depth;
  const dw = width - int_width;
  const points = [];
  points.push(new THREE.Vector3(0, 0, 0));
  points.push(new THREE.Vector3(width * unit, 0, 0));
  const lineGeometryForWidth = new THREE.BufferGeometry().setFromPoints(points);
  const points2 = [];
  points2.push(new THREE.Vector3(0, 0, 0));
  points2.push(new THREE.Vector3(0, 0, real_depth * unit));
  const lineGeometryForDepth = new THREE.BufferGeometry().setFromPoints(
    points2
  );
  const points3 = [];
  points3.push(new THREE.Vector3(0, 0, 0));
  points3.push(new THREE.Vector3(0, height1 * unit, 0));
  const lineGeometryForHeight1 = new THREE.BufferGeometry().setFromPoints(
    points3
  );
  const points4 = [];
  points4.push(new THREE.Vector3(0, 0, 0));
  points4.push(new THREE.Vector3(0, height2 * unit, 0));
  const lineGeometryForHeight2 = new THREE.BufferGeometry().setFromPoints(
    points4
  );

  const points5 = [];
  points5.push(new THREE.Vector3(0, 0, 0));
  points5.push(new THREE.Vector3(0, 0, lengthEndLineWidth * unit));
  const lineGeometryForEnd = new THREE.BufferGeometry().setFromPoints(points5);

  return props.activeStep === 0 && props.subStep === 0 ? (
    <group className="length-info-group">
      <Html
        scaleFactor={5}
        position={[((width - dw) * unit) / 2, height1 * unit, -oz * unit]}
      >
        <div className="length-info">
          {parseInt(width * props.length.unit + 0.5)} {props.length.unitName}
        </div>
      </Html>

      <Html
        scaleFactor={5}
        position={[
          (-dw * unit) / 2,
          height1 * unit,
          -oz * unit + (real_depth * unit) / 2,
        ]}
      >
        <div className="length-info">
          {parseInt(real_depth * props.length.unit + 0.5)}{" "}
          {props.length.unitName}
        </div>
      </Html>
      <Html
        scaleFactor={5}
        position={[(-dw * unit) / 2, (height1 * unit) / 2, -oz * unit]}
      >
        <div className="length-info">
          {parseInt(height1 * props.length.unit + 0.5)} {props.length.unitName}
        </div>
      </Html>
      {secondHeight ? (
        <Html
          scaleFactor={5}
          position={[
            (-dw * unit) / 2,
            (height2 * unit) / 2,
            -oz * unit + depth * unit,
          ]}
        >
          <div className="length-info">
            {parseInt(height2 * props.length.unit + 0.5)}{" "}
            {props.length.unitName}
          </div>
        </Html>
      ) : null}

      <line
        geometry={lineGeometryForWidth}
        position={[
          (-dw * unit) / 2,
          (height1 - lengthEndLineWidth) * unit,
          -oz * unit,
        ]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>
      <line
        geometry={lineGeometryForDepth}
        position={[
          (-dw * unit) / 2,
          (height1 - lengthEndLineWidth) * unit,
          -oz * unit,
        ]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>
      <line
        geometry={lineGeometryForHeight1}
        position={[(-dw * unit) / 2, 0, (-oz + lengthEndLineWidth / 2) * unit]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>
      {secondHeight ? (
        <line
          geometry={lineGeometryForHeight2}
          position={[(-dw * unit) / 2, 0, -oz * unit]}
        >
          <lineBasicMaterial
            attach="material"
            color={LineColor}
            linewidth={1}
            linecap={"round"}
            linejoin={"round"}
          />
        </line>
      ) : null}

      <line
        geometry={lineGeometryForEnd}
        position={[
          ((width * 2 - dw) * unit) / 2,
          (height1 - lengthEndLineWidth / 2) * unit,
          -oz * unit,
        ]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineEndColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>

      <line
        geometry={lineGeometryForEnd}
        position={[
          (-dw * unit) / 2,
          (height1 - lengthEndLineWidth / 2) * unit,
          -oz * unit,
        ]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineEndColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>

      <line
        geometry={lineGeometryForEnd}
        position={[
          (-dw * unit) / 2,
          height1 * unit,
          (-oz + lengthEndLineWidth) * unit,
        ]}
        rotation={[Math.PI, 0, 0]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineEndColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>
      <line
        geometry={lineGeometryForEnd}
        position={[(-dw * unit) / 2, 0, (-oz + lengthEndLineWidth) * unit]}
        rotation={[Math.PI, 0, 0]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineEndColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>

      <line
        geometry={lineGeometryForEnd}
        position={[
          (-dw * unit) / 2,
          (height1 - lengthEndLineWidth / 2) * unit,
          -oz * unit,
        ]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineEndColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>
      <line
        geometry={lineGeometryForEnd}
        position={[
          (-dw * unit) / 2,
          (height1 - lengthEndLineWidth / 2) * unit,
          (-oz + real_depth) * unit,
        ]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineEndColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>

      {secondHeight ? (
        <line
          geometry={lineGeometryForEnd}
          position={[
            (-dw * unit) / 2,
            height1 * unit,
            (-oz + depth + lengthEndLineWidth / 2) * unit,
          ]}
          rotation={[Math.PI, 0, 0]}
        >
          <lineBasicMaterial
            attach="material"
            color={LineEndColor}
            linewidth={1}
            linecap={"round"}
            linejoin={"round"}
          />
        </line>
      ) : null}
      {secondHeight ? (
        <line
          geometry={lineGeometryForEnd}
          position={[
            (-dw * unit) / 2,
            0,
            (-oz + depth + lengthEndLineWidth / 2) * unit,
          ]}
          rotation={[Math.PI, 0, 0]}
        >
          <lineBasicMaterial
            attach="material"
            color={LineEndColor}
            linewidth={1}
            linecap={"round"}
            linejoin={"round"}
          />
        </line>
      ) : null}
    </group>
  ) : null;
}

export function ColumnLabels(props) {
  const { columns } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, unit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const px1 = 391.98;
  const px2 = 8.5;
  const py = 50 + height1 * unit;
  const pz1 = -290.39;
  const pz2 = -7.39;

  const list = [];
  for (let i = 0; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      switch (columns.side[i]) {
        case 1:
          list.push(
            <Html
              key={i}
              scaleFactor={5}
              position={[
                (dw * unit) / 2 + px1 - columns.pos[i] * unit,
                py,
                pz1,
              ]}
            >
              <div className="length-info">{String.fromCharCode(65 + i)}</div>
            </Html>
          );
          break;
        case 2:
          if (i === 1) {
            list.push(
              <Html
                key={i}
                scaleFactor={5}
                position={[
                  (-dw * unit) / 2 + columns.pos[i] * unit + px2,
                  py,
                  pz1,
                ]}
              >
                <div className="length-info">{String.fromCharCode(65 + i)}</div>
              </Html>
            );
          } else {
            list.push(
              <Html
                key={i}
                scaleFactor={5}
                position={[
                  (-dw * unit) / 2 + px2,
                  py,
                  pz1 + columns.pos[i] * unit,
                ]}
              >
                <div className="length-info">{String.fromCharCode(65 + i)}</div>
              </Html>
            );
          }
          break;
        case 3:
          list.push(
            <Html
              key={i}
              scaleFactor={5}
              position={[
                (-dw * unit) / 2 + px2 + columns.pos[i] * unit,
                py,
                pz2 + dd * unit,
              ]}
            >
              <div className="length-info">{String.fromCharCode(65 + i)}</div>
            </Html>
          );
          break;
        case 4:
          if (i === 3) {
            list.push(
              <Html
                key={i}
                scaleFactor={5}
                position={[
                  (dw * unit) / 2 - columns.pos[i] * unit + px1,
                  py,
                  pz2 + dd * unit,
                ]}
              >
                <div className="length-info">{String.fromCharCode(65 + i)}</div>
              </Html>
            );
          } else {
            list.push(
              <Html
                key={i}
                scaleFactor={5}
                position={[
                  (dw * unit) / 2 + px1,
                  py,
                  pz2 - columns.pos[i] * unit + dd * unit,
                ]}
              >
                <div className="length-info">{String.fromCharCode(65 + i)}</div>
              </Html>
            );
          }
          break;
        default:
          break;
      }
    }
  }

  return props.activeStep === 0 && props.subStep === 1 ? (
    <group>{list}</group>
  ) : null;
}

export function Walls(props) {
  const { walls } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, unit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;

  return (
    <group position={[(-dw * unit) / 2, (wallTopHeight * unit) / 2, dd * unit]}>
      {walls.added[0] ? (
        <Wall
          width={width}
          height={height1}
          unit={unit}
          position={[
            (width * unit) / 2,
            (height1 * unit) / 2,
            -depth * unit - (wallThickness * unit) / 2,
          ]}
          ID={0}
        />
      ) : null}
      {walls.added[1] ? (
        <Wall
          width={depth}
          height={height1}
          unit={unit}
          position={[
            (-wallThickness * unit) - 5,
            (height1 * unit) / 2,
            (-depth * unit) / 2,
          ]}
          rotation={[0, Math.PI / 2, 0]}
          ID={1}
        />
      ) : null}
      {walls.added[2] ? (
        <Wall
          width={width}
          height={height1}
          unit={unit}
          position={[
            (width * unit) / 2,
            (height1 * unit) / 2,
            (wallThickness * unit) / 2,
          ]}
          ID={2}
        />
      ) : null}
      {walls.added[3] ? (
        <Wall
          width={depth}
          height={height1}
          unit={unit}
          position={[
            (wallThickness * unit) / 2 + width * unit,
            (height1 * unit) / 2,
            (-depth * unit) / 2,
          ]}
          rotation={[0, Math.PI / 2, 0]}
          ID={3}
        />
      ) : null}
    </group>
  );
}

export function AddSubSystems(props) {
  const { columns, length, int_length } = props;
  const { unit } = int_length;
  const dw = (-(length.width - int_length.int_width) * unit) / 2;
  const dd = (length.depth - int_length.int_depth) * unit;
  const list = [];
  const bias = props.origin === false ? 24 : 0;

  const mx = (length.width - int_length.column_thickness) / 2;
  const my = length.depth - int_length.column_thickness;

  for (let i = 0; i < 4; i = i + 1) {
    const pos_list = [];
    const side = i + 1;
    if (props.isLarge && props.doubleSystem === true) {
      if (props.origin === true && i === 2) continue;
      if (props.origin === false && i === 0) continue;
    }
    switch (i + 1) {
      case 1:
        pos_list.push({
          pos: columns.pos[0] + int_length.column_thickness,
          id: 0,
        });
        pos_list.push({ pos: -columns.pos[1] + length.width, id: 1 });
        break;
      case 2:
        pos_list.push({
          pos: columns.pos[1] + int_length.column_thickness,
          id: 1,
        });
        pos_list.push({ pos: -columns.pos[2] + length.depth, id: 2 });
        break;
      case 3:
        pos_list.push({
          pos: columns.pos[2] + int_length.column_thickness,
          id: 2,
        });
        pos_list.push({ pos: -columns.pos[3] + length.width, id: 3 });
        break;
      case 4:
        pos_list.push({
          pos: columns.pos[3] + int_length.column_thickness,
          id: 3,
        });
        pos_list.push({ pos: -columns.pos[0] + length.depth, id: 0 });
        break;
      default:
        break;
    }
    const cnt = columns.pos.length;
    for (let index = 4; index < cnt; index = index + 1) {
      if (columns.side[index] === side && columns.added[index])
        pos_list.push({
          pos: columns.pos[index] + int_length.column_thickness / 2,
          id: index,
        });
    }
    pos_list.sort(function (a, b) {
      return a.pos - b.pos;
    });
    const px1 = dw;
    const px2 = dw + length.width * unit;
    const py1 = dd;
    const py2 = dd - length.depth * unit;

    var dx = 0;
    if (props.isLarge && props.doubleSystem) {
      if (props.origin && !props.systemVisible[0] && side === 2) dx = mx;
      if (props.origin && !props.systemVisible[1] && side === 4) dx = -mx;
      if (!props.origin && !props.systemVisible[2] && side === 2) dx = mx;
      if (!props.origin && !props.systemVisible[3] && side === 4) dx = -mx;
    }

    if (props.activeStep === 2 && columns.addingSubSystem === true) {
      for (let j = 0; j < pos_list.length - 1; j = j + 1) {
        var dy = 0;
        if (props.isLarge && props.doubleSystem) {
          if (props.origin && !props.systemVisible[0] && side === 1 && j === 1)
            dy = my;
          if (props.origin && !props.systemVisible[1] && side === 1 && j === 0)
            dy = my;
          if (!props.origin && !props.systemVisible[2] && side === 3 && j === 0)
            dy = -my;
          if (!props.origin && !props.systemVisible[3] && side === 3 && j === 1)
            dy = -my;
        }
        const width =
          pos_list[j + 1].pos - pos_list[j].pos - int_length.column_thickness;
        const px =
          side === 1
            ? px2 - pos_list[j].pos * unit - (width * unit) / 2
            : side === 2
            ? px1
            : side === 3
            ? px1 + pos_list[j].pos * unit + (width * unit) / 2
            : px2;
        const py =
          side === 1
            ? py2
            : side === 2
            ? py2 + pos_list[j].pos * unit + (width * unit) / 2
            : side === 3
            ? py1
            : py1 - pos_list[j].pos * unit - (width * unit) / 2;
        const ID = pos_list[j].id + bias;
        // if (columns.addedSubSystem[ID]) continue;
        list.push(
          <Html
            scaleFactor={5}
            position={[px + dx * unit, 0, py + dy * unit]}
            key={`${i}_${j}`}
          >
            <div
              className="side-info side-info-subsystem"
              id={"side" + pos_list[j].id}
              onPointerOver={(e) => {
                e.preventDefault();
                props.setCurrentColumn(ID);
              }}
              onPointerOut={(e) => props.setCurrentColumn(-1)}
              onClick={(e) => {
                e.preventDefault();
                if (
                  props.systemID === 6 &&
                  columns.addedSubSystem[ID] === true &&
                  (columns.systemId[ID] <= 3 || columns.systemId[ID] === 5)
                ) {
                  state.columns.addedForte[ID] = true;
                  state.columns.fortePos[ID] = default_forte_pos;
                } else {
                  state.columns.addedSubSystem[ID] = true;
                  state.columns.systemId[ID] = props.systemID;
                  state.columns.systemDirection[ID] = 0;
                  if (props.systemID === 0) {
                    state.columns.systemId[ID + 8] = props.systemID;
                    state.columns.systemDirection[ID + 8] = 0;
                  }
                }
                state.columns.addingSubSystem = false;
                props.setCurrentColumn(-1);
              }}
            >
              <BsPlusCircle />
            </div>
          </Html>
        );
      }
    }
  }
  return list;
}

export function SideForSubSystem(props) {
  const { columns, length, int_length } = props;
  const { unit } = int_length;
  const dw = (-(length.width - int_length.int_width) * unit) / 2;
  const dd = (length.depth - int_length.int_depth) * unit;
  const list = [];

  const mx = (length.width - int_length.column_thickness) / 2;
  const my = length.depth - int_length.column_thickness;
  const bias = props.origin ? 0 : -24;

  for (let i = 0; i < 4; i = i + 1) {
    const pos_list = [];
    const side = i + 1;
    if (props.isLarge && props.doubleSystem === true) {
      if (props.origin === true && i === 2) continue;
      if (props.origin === false && i === 0) continue;
    }
    switch (i + 1) {
      case 1:
        pos_list.push({
          pos: columns.pos[0] + int_length.column_thickness,
          id: 0,
        });
        pos_list.push({ pos: -columns.pos[1] + length.width, id: 1 });
        break;
      case 2:
        pos_list.push({
          pos: columns.pos[1] + int_length.column_thickness,
          id: 1,
        });
        pos_list.push({ pos: -columns.pos[2] + length.depth, id: 2 });
        break;
      case 3:
        pos_list.push({
          pos: columns.pos[2] + int_length.column_thickness,
          id: 2,
        });
        pos_list.push({ pos: -columns.pos[3] + length.width, id: 3 });
        break;
      case 4:
        pos_list.push({
          pos: columns.pos[3] + int_length.column_thickness,
          id: 3,
        });
        pos_list.push({ pos: -columns.pos[0] + length.depth, id: 0 });
        break;
      default:
        break;
    }
    const cnt = columns.pos.length;
    for (let index = 4; index < cnt; index = index + 1) {
      if (columns.side[index] === side && columns.added[index])
        pos_list.push({
          pos: columns.pos[index] + int_length.column_thickness / 2,
          id: index,
        });
    }
    pos_list.sort(function (a, b) {
      return a.pos - b.pos;
    });
    const px1 = dw;
    const px2 = dw + length.width * unit;
    const py1 = dd;
    const py2 = dd - length.depth * unit;

    var dx = 0;
    if (props.isLarge && props.doubleSystem) {
      if (props.origin && !props.systemVisible[0] && side === 2) dx = mx;
      if (props.origin && !props.systemVisible[1] && side === 4) dx = -mx;
      if (!props.origin && !props.systemVisible[2] && side === 2) dx = mx;
      if (!props.origin && !props.systemVisible[3] && side === 4) dx = -mx;
    }

    if (props.activeStep === 2 && columns.addingSubSystem === true) {
      for (var j = 0; j < pos_list.length - 1; j = j + 1) {
        var dy = 0;
        if (props.isLarge && props.doubleSystem) {
          if (props.origin && !props.systemVisible[0] && side === 1 && j === 1)
            dy = my;
          if (props.origin && !props.systemVisible[1] && side === 1 && j === 0)
            dy = my;
          if (!props.origin && !props.systemVisible[2] && side === 3 && j === 0)
            dy = -my;
          if (!props.origin && !props.systemVisible[3] && side === 3 && j === 1)
            dy = -my;
        }
        const width =
          pos_list[j + 1].pos - pos_list[j].pos - int_length.column_thickness;
        const px =
          side === 1
            ? px2 - pos_list[j].pos * unit - (width * unit) / 2
            : side === 2
            ? px1
            : side === 3
            ? px1 + pos_list[j].pos * unit + (width * unit) / 2
            : px2;
        const py =
          side === 1
            ? py2
            : side === 2
            ? py2 + pos_list[j].pos * unit + (width * unit) / 2
            : side === 3
            ? py1
            : py1 - pos_list[j].pos * unit - (width * unit) / 2;
        if (props.currentColumn + bias === pos_list[j].id) {
          list.push(
            <mesh
              key={j}
              position={[
                px + dx * unit,
                (length.height1 * unit) / 2,
                py + dy * unit,
              ]}
              rotation={side % 2 ? [0, 0, 0] : [0, Math.PI / 2, 0]}
            >
              <boxGeometry args={[width * unit, length.height1 * unit, 1]} />
              <meshBasicMaterial color="#002853" transparent opacity={0.4} />
            </mesh>
          );
        }
      }
    }
  }
  return list;
}

export function asciiDif(a, b) {
  return a.charCodeAt(0) - b.charCodeAt(0);
}

export function CabbanaN(props) {
  const gltf = useLoader(GLTFLoader, cabbana, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/draco-gltf/");
    loader.setDRACOLoader(dracoLoader);
  });

  const modelRef = useRef();
  // const onProgress = (xhr) => {
  //   if (xhr.lengthComputable) {
  //     const newProgress = (xhr.loaded / xhr.total) * 100;
  //     props.setProgress(Math.round(newProgress));
  //   }
  // };

  // const gltf = useLoader(GLTFLoader, cabbana, onProgress);
  // const { progress } = useProgress();
  // const [loaded, setLoaded] = React.useState(false);

  // useEffect(()=>{
  //   setLoaded(true);
  // }, [])

  const { nodes, materials } = gltf;

  const snap = useSnapshot(state);
  const [currentSide, setCurrentSide] = React.useState(0);
  const [currentColumn, setCurrentColumn] = React.useState(-1);
  const { modelID, viewMode } = props;

  const rdepth = props.doubleSystem
    ? (snap.length.depth + lengths[modelID].column_thickness) / 2
    : snap.length.depth;

  const length = {
    ...snap.length,
    depth: props.doubleSystem ? rdepth : snap.length.depth,
  };

  const isLarge =
    props.doubleSystem &&
    snap.length.width > lengths[modelID].mid_length_limit[0];

  // return !loaded?
  //   <ProgressBar value={progress} text={'Loading Model...'} />:
  return (
    <group {...props} dispose={null} ref={props.group}>
      <group
        scale={1}
        position={[0, 0, (-rdepth * lengths[modelID].unit) / 2]}
        renderOrder={1}
      >
        <WaterProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <BackProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <RightProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <LeftProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <BackLeft
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <CapLeft
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <CapRight
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <BackRight
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <ColumnRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <ColumnBackLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <ColumnLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <ColumnBackRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <FootBackRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <FootLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <FootRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <FootBackLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />

        <MiddleProfile1
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.textureB}
          xrepeat={xrepeat}
          yrepeat={yrepeat}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
          modelID={modelID}
        />

        {modelID === 15 && (
          <CarBody
            length={length}
            int_length={lengths[modelID]}
            isTexture={snap.isTexture}
            columns={snap.columns}
            texture={snap.structure.texture}
          />
        )}

        {modelID === 15 ? (
          <TopDiv2
            nodes={nodes}
            materials={materials}
            length={length}
            int_length={lengths[modelID]}
            color={snap.blades.color}
            middleColor={
              snap.isDesign ? snap.designStyle : snap.structure.color
            }
            visible={snap.hasLight}
            bladeRotation={snap.blades.rotation}
            isTexture={snap.isBladeTexture}
            isMiddleTexture={snap.isTexture}
            texture={snap.blades.texture}
            structure_texture={snap.structure.textureB}
            opacity={snap.blades.opacity}
            modelID={modelID}
            doubleSystem={props.doubleSystem}
            origin={props.origin}
            systemVisible={snap.systemVisible}
            isLarge={isLarge}
            bladeType={snap.blades.type}
          />
        ) : modelID === 13 ? (
          <TopDiv3
            nodes={nodes}
            materials={materials}
            length={length}
            int_length={lengths[modelID]}
            color={snap.blades.color}
            middleColor={
              snap.isDesign ? snap.designStyle : snap.structure.color
            }
            visible={snap.hasLight}
            bladeRotation={snap.blades.rotation}
            isTexture={snap.isBladeTexture}
            isMiddleTexture={snap.isTexture}
            texture={snap.blades.texture}
            structure_texture={snap.structure.textureB}
            opacity={snap.blades.opacity}
            modelID={modelID}
            doubleSystem={props.doubleSystem}
            origin={props.origin}
            systemVisible={snap.systemVisible}
            isLarge={isLarge}
            bladeType={snap.blades.type}
          />
        ) : (
          <TopDiv
            nodes={nodes}
            materials={materials}
            length={length}
            int_length={lengths[modelID]}
            color={snap.blades.color}
            middleColor={
              snap.isDesign ? snap.designStyle : snap.structure.color
            }
            visible={snap.hasLight}
            bladeRotation={snap.blades.rotation}
            isTexture={snap.isBladeTexture}
            isMiddleTexture={snap.isTexture}
            texture={snap.blades.texture}
            structure_texture={snap.structure.textureB}
            opacity={snap.blades.opacity}
            modelID={modelID}
            doubleSystem={props.doubleSystem}
            origin={props.origin}
            systemVisible={snap.systemVisible}
            isLarge={isLarge}
            bladeType={snap.blades.type}
          />
        )}

        {modelID === 15 ? (
          <MiddleProfile2
            nodes={nodes}
            materials={materials}
            length={length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
            isTexture={snap.isTexture}
            texture={snap.structure.textureB}
            xrepeat={xrepeat}
            yrepeat={yrepeat}
            origin={props.origin}
            doubleSystem={props.doubleSystem}
            systemVisible={snap.systemVisible}
            isLarge={isLarge}
            modelID={modelID}
          />
        ) : (
          <DividerMiddle1
            nodes={nodes}
            materials={materials}
            length={length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
            isTexture={snap.isTexture}
            texture={snap.structure.textureA}
            xrepeat={xrepeat}
            yrepeat={yrepeat}
            origin={props.origin}
            doubleSystem={props.doubleSystem}
            systemVisible={snap.systemVisible}
            isLarge={isLarge}
            modelID={modelID}
          />
        )}

        <Columns
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          flag={snap.flag}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <Foots
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <SideInfills6
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          systemID={snap.systemID}
          modelID={modelID}
          isTexture={snap.isTexture}
          texture={snap.structure.textureD}
          texture1={snap.structure.textureC}
          doubleSystem={props.doubleSystem}
          origin={props.origin}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
          walls={snap.walls}
        />
        <AddSubSystems
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setCurrentColumn={setCurrentColumn}
          systemID={snap.systemID}
          doubleSystem={props.doubleSystem}
          origin={props.origin}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <GetLogo
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
          modelID={props.modelID}
        />
        <Side
          columns={snap.columns}
          walls={snap.walls}
          length={snap.length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          current_side={currentSide}
        />
        <TopLights
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          visible={snap.hasLedLight}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
          modelID={modelID}
        />

        <SideForSubSystem
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          activeStep={props.activeStep}
          subStep={props.subStep}
          currentColumn={currentColumn}
          doubleSystem={props.doubleSystem}
          origin={props.origin}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <VisibleSystem
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          activeStep={props.activeStep}
          subStep={props.subStep}
          currentColumn={currentColumn}
          doubleSystem={props.doubleSystem}
          origin={props.origin}
          modelID={modelID}
          systemVisible={snap.systemVisible}
          viewMode={viewMode}
        />
        <AddExtraColumns
          columns={snap.columns}
          length={length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setCurrentSide={setCurrentSide}
        />
        <AddWalls
          walls={snap.walls}
          length={snap.length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setCurrentSide={setCurrentSide}
          origin={props.origin}
        />
        {props.origin ? (
          <Walls
            walls={snap.walls}
            length={snap.length}
            int_length={lengths[modelID]}
          />
        ) : null}
      </group>
    </group>
  );
}

// DRACOLoader.preload('models/cabbana.glb')
// useGLTF.preload('models/cabbana.glb')
