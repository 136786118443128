/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import Compact from "./Compact";
import CompactNew from "./CompactNew";
import Classy from "./Classy";
import Compact2 from "./Compact2";
import Classy2 from "./Classy2";
import Minima from "./Minima";
import Minima2 from "./Minima2";
import FixedGlass from "./FixedGlass";
import FixedGlass2 from "./FixedGlass2";
import FixedWall from "./FixedWall";
import FixedWall2 from "./FixedWall2";
import LiftAndSlideDoor from "./LiftAndSlideDoor";
import LiftAndSlideDoor2 from "./LiftAndSlideDoor2";
import Zion from "./Zion";
import Zion2 from "./Zion2";
import Forte from "./Forte";
import Forte2 from "./Forte2";
import Capella from "./Capella";
import CapellaInside from "./CapellaInside";
import Capella2 from "./Capella2";
import BifoldDoor from "./BifoldDoor";
import BifoldDoor2 from "./BifoldDoor2";

export default function SubSystem(props) {
  switch (props.systemID) {
    case 0:
      return props.type === 1 ? (
        <CompactNew {...props} />
      ) : (
        <Compact2 {...props} />
      );
    case 1:
      return props.type === 1 ? <Classy {...props} /> : <Classy2 {...props} />;
    case 2:
      return props.type === 1 ? <Minima {...props} /> : <Minima2 {...props} />;
    case 3:
      return props.type === 1 ? (
        <FixedGlass {...props} />
      ) : (
        <FixedGlass2 {...props} />
      );
    case 4:
      return props.type === 1 ? (
        <FixedWall {...props} />
      ) : (
        <FixedWall2 {...props} />
      );
    case 5:
      return props.type === 1 ? (
        <LiftAndSlideDoor {...props} />
      ) : (
        <LiftAndSlideDoor2 {...props} />
      );
    case 6:
      return props.type === 1 ? <Forte {...props} /> : <Forte2 {...props} />;
    case 7:
      return props.type === 1 ? <Zion {...props} /> : <Zion2 {...props} />;
    case 8:
      return props.type === 1 ? (
        <BifoldDoor {...props} />
      ) : (
        <BifoldDoor2 {...props} />
      );
    case 9:
      return props.type === 1 ? (
        <Capella {...props} />
      ) : props.type === 3 ? (
        <CapellaInside {...props} />
      ) : (
        <Capella2 {...props} />
      );
    default:
      return null;
  }
}
