import { EMBED_USER_KEY, SERVER_URL } from 'utils/constant';
import ConvertString from "common/ConvertString";

export const getBenefit = (product_name, product_prices) => {
  const product = ConvertString(product_name);
  const productIndex = Object.keys(product_prices).findIndex((key) => key.toLowerCase() === product.toLowerCase());
  if (
    productIndex !== -1
  ) {
    return Object.values(product_prices)[productIndex][0];
  }
  return 0;
};

export const getInstall = (product_name, product_prices) => {
  const product = ConvertString(product_name);
  const productIndex = Object.keys(product_prices).findIndex((key) => key.toLowerCase() === product.toLowerCase());
  if (
    productIndex !== -1
  ) {
    return Object.values(product_prices)[productIndex][1];
  }
  return 0;
};



export const loadFavicon = (embed = false) => {
  try {
    let user = JSON.parse(localStorage.getItem('user'));
    if (!user && embed) {
        user = JSON.parse(localStorage.getItem(EMBED_USER_KEY));
    } else {
        user = user.user;
    }
    const userFavicon = user ? user.favicon : null;
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    if (userFavicon) {
      link.href = `${SERVER_URL}${userFavicon}`;
    }
  } catch (error) {
    console.log("no user favicon");
  }
};

export const constructUrl = (model_item, id = "", lang = "") => {
  let url = "";
  if(model_item){
    url = `/${encodeURIComponent(model_item)}`;
  }
  if (id) {
    url += `/${id}`;
  }
  
  if (lang) {
    url += `/${lang}`;
  }

  return url;
}
