import React from "react";
import { ligthingStyles } from "../../utils/constant";
import ImageList from "@mui/material/ImageList";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import state from '../../state';

const Lighting = () => {
  const isMobile = useMediaQuery('(max-width:769px)')
  const selected = (selected_url) => {
    ligthingStyles.forEach((item) => {
      if (item.url === selected_url) {
        document.getElementById(item.url).style.opacity = 1.0;
        document.getElementById(item.url).style.border = "5px solid #31d84a";
      } else {
        document.getElementById(item.url).style.border = "0px";
        document.getElementById(item.url).style.opacity = 0.7;
      }
    });
  };

  return (
    <Box
        className={!isMobile ? 'overview clearfix' : 'clearfix'}
        sx={{ p: 0, pr: { md: 2, xs: 0 }, mb: { md: 0, xs: 2.5 } }}
    >
      <ImageList variant="woven" cols={2} gap={15}>
        {ligthingStyles.map((item) => (
          <label key={item.title} className="add-label">
            <img
              src={item.url}
              alt={item.url}
              id={item.url}
              className="styleImage"
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                selected(item.url);
                state.hasLight = item.visible;
              }}
            />
            <h6>{item.title}</h6>


          </label>
        ))}
      </ImageList>
    </Box>
  );
}

export default Lighting;