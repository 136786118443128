import React from 'react';

import Stack from '@mui/material/Stack';
import UserSetting from './UserSetting';
import Balance from 'common/Balance';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip, Button } from '@mui/material';
import Help from './Help';

export default function UserMenu() {
    return (
        <>
        <Stack direction='row' alignItems='center'
            sx={{position: 'relative'}}
        >
            <UserSetting />
        </Stack>
        </>
    )
}