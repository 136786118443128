export default function ConvertString(string) {
    var words = string.split(" ");  // Split the long string into words
    var modifiedWords = [];
  
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      var modifiedWord = word.charAt(0) + word.substring(1).toLowerCase();  // Convert all characters to lowercase except the first one
      modifiedWords.push(modifiedWord);
    }
  
    var finalString = modifiedWords.join(" ");  // Join the modified words back together
    return finalString;
  }