import React, { useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";

import { axiosInstanceAuthorized, wooddesign } from "../../utils/constant";
import state from "../../state";
import { useSnapshot } from "valtio";
import {
  coverColors,
  hasBlade,
  API_URL,
  SERVER_URL,
} from "../../utils/constant";
import AppContext from "../../context/AppContext";
import CircleSelect from "./CircleSelect";
import axios from "axios";
import { toastError } from "common/Toast";
import { useTranslation } from "react-i18next";

const Colors = () => {
  const snap = useSnapshot(state);
  const { modelID } = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [colorType, setColorType] = useState("textured");
  const [bladeColorType, setBladeColorType] = useState("textured");
  const [woodTexture, setWoodTexture] = useState(null);
  const isMobile = useMediaQuery("(max-width:769px)");

  const handleChange = (_, newVal) => {
    setColorType(newVal);
    if (newVal === "wooddesign") {
      state.isTexture = true;
      state.structure.colorName = woodTexture[0].title;
      state.structure.texture = SERVER_URL + woodTexture[0].image;
      state.structure.textureR = SERVER_URL + woodTexture[0].image1;
      state.structure.textureA = SERVER_URL + woodTexture[0].image2;
      state.structure.textureB = SERVER_URL + woodTexture[0].image3;
      state.structure.textureC = SERVER_URL + woodTexture[0].image4;
      state.structure.textureD = SERVER_URL + woodTexture[0].image5;
      const textures = [];
      for (var i = 6; i <= 14; i = i + 1) {
        textures.push(SERVER_URL + woodTexture[0]["image" + i]);
      }
      state.structure.textures = textures;
      state.structure.color = coverColors[1][0].color;
    } else {
      state.isTexture = false;
      state.structure.colorName = coverColors[modelID][0].title;
    }
    // state.blades.texture = wooddesign[0].url;
  };

  const handleBladeChange = (_, newVal) => {
    setBladeColorType(newVal);
    if (newVal === "wooddesign") {
      state.isBladeTexture = true;
      state.blades.texture = SERVER_URL + woodTexture[0].image1;
      state.blades.colorName = woodTexture[0].title;
    } else {
      state.isBladeTexture = false;
      state.blades.colorName = coverColors[modelID][0].title;
    }
    // state.blades.texture = wooddesign[0].url;
  };

  const getTextures = () => {
    axiosInstanceAuthorized
      .get(API_URL + "texture/get?modelID=" + modelID)
      .then((response) => {
        setWoodTexture(response.data.textures);
        return response.data.textures;
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "Network Error") {
          toastError(err.message);
        }
      });
  };

  useEffect(() => {
    getTextures();
  }, []);

  return (
    <Box
      className={!isMobile ? "overview" : ""}
      sx={{ p: 0, pr: { md: 2, xs: 0 }, mb: { md: 0, xs: 2.5 } }}
    >
      <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
        {t('Structure color')}
      </Typography>
      <Stack direction="row" sx={{ borderBottom: "1px solid #E5E5E5" }}>
        <Button
          onClick={(e) => handleChange(e, "textured")}
          sx={{
            color:
              colorType === "textured"
                ? "darkgray"
                : (theme) => theme.palette.text.primary,
            p: 0,
            py: 1,
            borderBottom: 2,
            borderRadius: 0,
            minWidth: 0,
            mr: 2,
            fontSize: 15,
            textTransform: "capitalize",
            borderColor: colorType === "textured" ? "darkgray" : "transparent",
          }}
        >
          {t('RAL Texture')}
        </Button>
        {woodTexture !== null && woodTexture.length > 0 ? (
          <Button
            onClick={(e) => handleChange(e, "wooddesign")}
            sx={{
              color:
                colorType === "wooddesign"
                  ? "darkgray"
                  : (theme) => theme.palette.text.primary,
              p: 0,
              py: 1,
              borderBottom: 2,
              borderRadius: 0,
              minWidth: 0,
              mr: 2,
              fontSize: 15,
              textTransform: "capitalize",
              borderColor:
                colorType === "wooddesign" ? "darkgray" : "transparent",
            }}
          >
            {t('Wood Effect')}
          </Button>
        ) : null}
      </Stack>
      {colorType === "textured" ? (
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          { ![19, 20].includes(modelID) ?
          coverColors[1].map((item, index) => {
            return (
              <CircleSelect
                size="normal"
                key={index}
                isSelected={item.color === snap.structure.color}
                title={item.title}
                url={item.url}
                color={item.color}
                type={"texture"}
                material={"structure"}
              />
            );
          })
          : coverColors[modelID].map((item, index) => {
              return (
                <CircleSelect
                  size="normal"
                  key={index}
                  isSelected={item.color === snap.structure.color}
                  title={item.title}
                  url={item.url}
                  color={item.color}
                  type={"texture"}
                  material={"structure"}
                />
              );
            }) 
          }
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {woodTexture !== null && woodTexture.length > 0
            ? woodTexture.map((item, index) => {
                return item.visible ? (
                  <CircleSelect
                    size="normal"
                    key={index}
                    isSelected={
                      SERVER_URL + item.image === snap.structure.texture
                    }
                    title={item.title}
                    url={SERVER_URL + item.image}
                    url2={SERVER_URL + item.image1}
                    urlA={SERVER_URL + item.image2}
                    urlB={SERVER_URL + item.image3}
                    urlC={SERVER_URL + item.image4}
                    urlD={SERVER_URL + item.image5}
                    urls={item}
                    type={"wood"}
                    material={"structure"}
                  />
                ) : null;
              })
            : null}
        </Box>
      )}

      {hasBlade[modelID].option && hasBlade[modelID].isTexture ? (
        <>
          <Typography sx={{ fontSize: 15, fontWeight: 500, mt: 4 }}>
            {t('Blade Color & Texture')}
          </Typography>
          <Stack direction="row" sx={{ borderBottom: "1px solid #E5E5E5" }}>
            <Button
              onClick={(e) => handleBladeChange(e, "textured")}
              sx={{
                color:
                  bladeColorType === "textured"
                    ? "darkgray"
                    : (theme) => theme.palette.text.primary,
                p: 0,
                py: 1,
                borderBottom: 2,
                borderRadius: 0,
                minWidth: 0,
                mr: 2,
                fontSize: 15,
                textTransform: "capitalize",
                borderColor:
                  bladeColorType === "textured" ? "darkgray" : "transparent",
              }}
            >
              {t('RAL Texture')}
            </Button>
            {woodTexture !== null && woodTexture.length > 0 ? (
              <Button
                onClick={(e) => handleBladeChange(e, "wooddesign")}
                sx={{
                  color:
                    bladeColorType === "wooddesign"
                      ? "darkgray"
                      : (theme) => theme.palette.text.primary,
                  p: 0,
                  py: 1,
                  borderBottom: 2,
                  borderRadius: 0,
                  minWidth: 0,
                  mr: 2,
                  fontSize: 15,
                  textTransform: "capitalize",
                  borderColor:
                    bladeColorType === "wooddesign"
                      ? "darkgray"
                      : "transparent",
                }}
              >
                {t('Wood Effect')}
              </Button>
            ) : null}
          </Stack>
          {bladeColorType === "textured" ? (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {coverColors[modelID].map((item, index) => {
                return (
                  <CircleSelect
                    size="normal"
                    key={index}
                    isSelected={item.color === snap.blades.color}
                    title={item.title}
                    url={item.url}
                    color={item.color}
                    type={"texture"}
                    material={"blades"}
                  />
                );
              })}
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {woodTexture !== null && woodTexture.length > 0
                ? woodTexture.map((item, index) => {
                    return item.visible ? (
                      <CircleSelect
                        size="normal"
                        key={index}
                        isSelected={
                          SERVER_URL + item.image1 === snap.blades.texture
                        }
                        title={item.title}
                        url={SERVER_URL + item.image1}
                        color={SERVER_URL + item.image1}
                        type={"wood"}
                        material={"blades"}
                      />
                    ) : null;
                  })
                : null}
            </Box>
          )}
        </>
      ) : (
        <>
          {/* <ImageList variant="woven" cols={2} gap={15} className="mt-3 woodStyle">
            {glassType[modelID].map((item, index) => (
              <label htmlFor={item.title} key={index}>
                <img
                  src={item.view}
                  alt={item.view}
                  id={item.url + 'glass'}
                  className="styleImage"
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    selectedGlassType(item.title, item.url, item.opacity);
                  }}
                />
                <h6>{item.title}</h6>
              </label>
            ))}
          </ImageList> */}
        </>
      )}
    </Box>
  );
};

export default Colors;
