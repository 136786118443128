import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AuthService from "../services/auth.service";
import {
    API_URL, axiosInstanceAuthorized,
} from "../utils/constant";
import { useContext } from 'react';
import AppContext from '../context/AppContext';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import RadioGroup from '@mui/material/RadioGroup';
// import Radio from '@mui/material/Radio';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { info, thankyouIcon, title, SITE_URL } from '../utils/constant';
import { Grid } from '@mui/material';
import { useSnapshot } from 'valtio';
import state from '../state';
import ColumnCount from './ColumnCount';
import { toastError, toastSuccess } from './Toast';
import Darken from './Darken';

export default function FriendForm(props) {

    const [clientID, setClientID] = useState(null);
    const [clients, setClients] = useState([]);
    const {brandColor} = useContext(AppContext);
    const modelID = props.modelID;
    const snap = useSnapshot(state);
    const cnt = ColumnCount();
    const api_url = localStorage.getItem('api_url');

    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const userId = localStorage.getItem('user') === null ? localStorage.getItem('user_id') : (JSON.parse(localStorage.getItem('user'))).user.user_id;

    const handleChange = (e) => {
        setEmail(e.target.value);
    }

    const handleSend = () => {

        const data = {
            client_id: -12,
            model_name: title[modelID],
            unit: snap.length.unitName,
            width: parseInt(snap.length.width * snap.length.unit + 0.5),
            depth: parseInt(snap.length.depth * snap.length.unit + 0.5),
            height1: parseInt(snap.length.height1 * snap.length.unit + 0.5),
            height2: parseInt(snap.length.height2 * snap.length.unit + 0.5),
            cover_color: snap.structure.color,
            glass_texture: snap.blades.textureType,
            light: snap.hasLight,
            number_of_columns: cnt,
            added_columns: JSON.stringify(snap.columns.added),
            added_column_position: JSON.stringify(snap.columns.pos),
            install: 0,
            inst: 0,
            ledprice: 0,
            weight: 0,
            data: snap,
            user_id: userId,
            email: email
          }

        axiosInstanceAuthorized.post(API_URL + 'order/add', data)
        .then(response => {
          const data1 = {
            viewLink: SITE_URL + '/view/' + response.data.data_id,
            email: email,
          }
          axiosInstanceAuthorized.post(API_URL + 'order/sendtofriend', data1)
          .then(data=>{
            if (data.data.success === true) {
                setSubmitted(false);
                toastSuccess("Data sent to friend successfully!", brandColor);
                props.handleClose();
            }
            else {
                setSubmitted(false);
                toastError(data.data.message);
            }
        })
  
        })
        .catch(err => {
          toastError(err.message);
        })
    }

    const handleSubmit = () => {
        setSubmitted(true);
        handleSend();
    }

    
    return (
        <ValidatorForm
            onSubmit={ev => { ev.preventDefault(); handleSubmit() }}
        >
            <Grid item md={12} xs={12}>
            <TextValidator
                    required
                    className="form-field m-2"
                    margin="dense"
                    label="Email"
                    onChange={handleChange}
                    name="email"
                    variant="standard"
                    value={email}
                    validators={['required', 'isEmail']}
                    errormessages={['this field is required', 'email is not valid']}
                    fullWidth
                />
            </Grid>
            <Button
                sx={{ bgcolor: brandColor, borderRadius: 0.5, width: '100%',
                '&:hover': { bgcolor: Darken(brandColor) }
                }}
                variant="contained"
                type="submit"
                disabled={submitted}
                className="form-field m-2"
            >
                {
                    (submitted && 'Your form is submitted! Please wait...')
                    || (!submitted && 'SEND')
                }
            </Button>
        </ValidatorForm>
    )
 }
