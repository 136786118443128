import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import AuthService from "../services/auth.service";
import axios from 'axios';
import {
    API_URL, axiosInstanceAuthorized,
} from "../utils/constant";
import { useContext } from 'react';
import AppContext from '../context/AppContext';
import Darken from './Darken';

function Form(props) {

    const [clientID, setClientID] = useState(null);
    const [clients, setClients] = useState([]);
    const {brandColor} = useContext(AppContext);

    useEffect(() => {
        const user = AuthService.getCurrentUser().user;
        axiosInstanceAuthorized.post(API_URL + 'client/get', { employee_id: user.employee_id })
            .then(response => {
                setClients(response.data.clients);
            })
            .catch(err => {
                console.log(err);
                // toastError(err.message);
            })
    })

    const handleChange = (e) => {
        setClientID(e.target.value);
    }

    return (
        <div className="form">
            <FormControl
                className="form-field demo-select-info"
                margin="dense"
                fullWidth
            >
                <InputLabel id="demo-select-info">Select your client</InputLabel>
                <Select
                    required
                    labelId="demo-select-info"
                    id="demo-select-info"
                    label="How did you here about us?"
                    name="info"
                    value={clientID}
                    onChange={handleChange}
                    validators={['required']}
                    errormessages={['this field is required']}
                >
                    {clients.map((item, index) =>
                        <MenuItem value={item.id} key={index}>
                            {item.email} ({item.first_name} {item.last_name})</MenuItem>
                    )
                    }
                </Select>
            </FormControl>
            <Button
                color="primary"
                variant="contained"
                type="submit"
                sx={{ 'width': '100%', bgcolor: brandColor,
                '&:hover': { bgcolor: Darken(brandColor) }
                }}
                onClick={e => { e.preventDefault(); props.AddOrder(clientID); props.handleClose(); }}
            disabled={clientID === null}
            >
                Add Order
            </Button>
        </div>
    )
}

export default function OrderForm(props) {
    const handleClose = () => {
        props.setOpen(false);
    }
    return (
        <Dialog open={props.open} onClose={handleClose} fullWidth>
            <DialogTitle>Add Order</DialogTitle>
            <DialogContent>
                <Form AddOrder={props.AddOrder} handleClose={handleClose} />
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>

    )
}

