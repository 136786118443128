/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import panora_parts from "../../assets/models/panora_parts.glb";
import { TopDepthDivCount } from "../DivCount";

export function CenterProfile(props) {
  const { nodes, materials } = useGLTF(panora_parts);
  const { color, xrepeat, yrepeat } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, unit, column_thickness } = props.int_length;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const dd = depth - int_depth;
  const px = (int_width * unit) / 2 - 6;
  const py = height1 * unit - 11.5;
  const pz = (-depth * unit) / 2;
  var sc = 1;
  sc =
    props.isLarge &&
    props.origin &&
    props.doubleSystem &&
    (!props.systemVisible[0] || !props.systemVisible[1])
      ? 0.5
      : sc;
  sc =
    props.isLarge &&
    !props.origin &&
    props.doubleSystem &&
    (!props.systemVisible[2] || !props.systemVisible[3])
      ? 0.5
      : sc;
  const scale = [(width / int_width) * 1.06 * sc, 1, 2];
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const div_count = TopDepthDivCount(depth, props.modelID);

  const dx1 =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[0]) ||
      (!props.origin && !props.systemVisible[2]))
      ? (width + column_thickness) / 2 - column_thickness
      : 0;
  const dx2 =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[1]) ||
      (!props.origin && !props.systemVisible[3]))
      ? (width + column_thickness) / 2 - column_thickness
      : 0;

  return div_count > 1 ? (
    <group
      {...props}
      position={[(dx1 * unit) / 2 - (dx2 * unit) / 2, 0, dd * unit]}
    >
      <mesh
        castShadow
        geometry={nodes.centerprofile.geometry}
        material={props.isTexture ? material_texture : materials.All_profiles}
        position={[px, py, pz]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={scale}
        material-color={color}
      />
    </group>
  ) : null;
}

// useGLTF.preload('models/panora_parts.glb')
