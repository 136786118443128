import React, { useState } from 'react';
import Viewer from 'react-viewer';

export const ImageViewer = (props) => {
    const closeViewer = () => {
      props.setOpen(false);
    };
  
    return (
      <div>
        <Viewer
          visible={props.open}
          onClose={closeViewer}
          images={[{ src: props.image }]}
        />
      </div>
    );
  };