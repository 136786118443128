import React, { useContext, useRef, useState } from 'react'
import { useSnapshot } from 'valtio'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

import state from '../../state'
import CustomInput from './CustomInput'
import AppContext from '../../context/AppContext'
import { secondHeight } from '../../utils/constant'

const asciiDif = (a, b) => {
  return a.charCodeAt(0) - b.charCodeAt(0)
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 3,
    '&.Mui-checked': {
      transform: 'translateX(14px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#7596AE',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 14,
    height: 14,
    borderRadius: 50,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 50,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#D3D3D3',
    boxSizing: 'border-box',
  },
}))

const Column = (props) => {
  const id = asciiDif(props.column, 'A')
  const snap = useSnapshot(state)
  const extra =
    snap.columns.added[4] ||
    snap.columns.added[5] ||
    snap.columns.added[6] ||
    snap.columns.added[7]
  if (props.flag === false) return null
  if (extra === true && id < 4) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          p: 1.25,
          borderRadius: 1,
          cursor: 'pointer',
          bgcolor: theme => theme.palette.mode === 'dark' ? '#ffffff1c' : '#0000001c',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CheckCircleIcon sx={{ height: 25, width: 25, color: props.isExtra?'#7aae75':'#7596AE' }} />
          <Typography sx={{ ml: 1.25, color: theme => theme.palette.text.primary }}>Column {props.column} </Typography>
        </Stack>
      </Stack>
    )
  }
  return snap.columns.added[id] === true ? (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        p: 1.25,
        borderRadius: 1,
        cursor: 'pointer',
        bgcolor: theme => theme.palette.mode === 'dark' ? '#ffffff1c' : '#00000009',
      }}
      onClick={() => {
        props.setValue(0)
        props.handleChange(props.column)
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CheckCircleIcon sx={{ height: 25, width: 25, color: props.isExtra?'#7aae75':'#7596AE' }} />
        <Typography sx={{ ml: 1.25, color: theme => theme.palette.text.primary }}>Column {props.column} </Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <DriveFileRenameOutlineOutlinedIcon  
          sx={{ height: 20, width: 20, cursor: 'pointer', color: '#A7A7A7' }}
        />
        {snap.columns.removable[id] ? (
          <DeleteOutlineOutlinedIcon
            sx={{
              ml: 1,
              height: 20,
              width: 20,
              cursor: 'pointer',
              color: '#BA181B',
            }}
            onClick={() => props.handleDelete(props.column)}
          />
        ) : null}
      </Stack>
    </Stack>
  ) : null
}

const Columns = () => {
  const snap = useSnapshot(state)
  const unit = snap.length.unit
  const length_unit = parseInt(1 / snap.length.unit)

  const { modelID } = useContext(AppContext)
  const containerRef = useRef(null)
  const [value, setValue] = useState(0)
  const [unitTab, setUnitTab] = useState(0)
  const [posColumn, setPosColumn] = useState(snap.columns.minPos)
  const isMobile = useMediaQuery('(max-width:769px)')

  const minPos = snap.columns.minPos
  // const maxPos = isExtra(snap.columns.editingColumn)?(snap.columns.editingColumn === 'E' || snap.columns.editingColumn === 'G' ? snap.length.width - minPos:snap.length.depth - minPos) :snap.columns.maxPos;
  const maxPos =
    snap.columns.editingSide & 1 || secondHeight[modelID]
      ? snap.length.width - snap.columns.minPos
      : snap.length.depth - snap.columns.minPos

  const changeUnit = (newValue) => {
    setUnitTab(newValue)
  }

  const handleChange = (column) => {
    state.columns.editingColumn = column
    state.columns.editing = true
  }

  const validationVal = (min, max, val) => {
    if (min > val || val > max) return false
    else return true
  }

  const handlePlusChange = () => {
    handleColumn(snap.columns.editingColumn)
    if (validationVal(0, maxPos, posColumn + length_unit)) {
      setPosColumn(posColumn + length_unit)
    }
  }

  const handleMinusChange = (type) => {
    handleColumn(snap.columns.editingColumn)
    if (validationVal(minPos, maxPos, posColumn - length_unit)) {
      setPosColumn(posColumn - length_unit)
    }
  }

  const handleSliderChange = (event, newVal) => {
    setPosColumn(newVal)

    handleColumn(snap.columns.editingColumn)
    const dif = asciiDif(snap.columns.editingColumn, 'A')
    state.columns.pos[dif] = newVal
    state.flag = !snap.flag
  }

  const handleColumn = (column) => {
    const dif = asciiDif(snap.columns.editingColumn, 'A')
    if (column === 'No') {
      state.columns.isShift[dif] = false
      if (dif > 3) state.columns.added[dif] = false
      state.columns.pos[dif] = 0
    } else {
      state.columns.added[dif] = true
      state.columns.isShift[dif] = true
      state.columns.pos[dif] = posColumn
      if (dif > 3) {
        state.columns.isShift[0] = false
        state.columns.isShift[1] = false
        // state.columns.isShift[2] = false;
        // state.columns.isShift[3] = false;
        state.columns.pos[0] = false
        state.columns.pos[1] = false
        // state.columns.pos[2] = false;
        // state.columns.pos[3] = false;
      }
    }
  }

  const handleAdd = () => {
    if (snap.columns.adding === true) {
      state.columns.adding = false
    } else {
      state.columns.adding = true
      if (secondHeight[modelID]) {
        state.columns.editing = true
        for (var i = 4; i < 24; i = i + 1) {
          if (snap.columns.added[i] === false) {
            state.columns.editingColumn = String.fromCharCode(i + 65)
            state.columns.added[i] = true
            state.columns.pos[i] = minPos
            state.columns.adding = false
            break
          }
        }
      }
    }
  }

  const handleDelete = (column) => {
    const id = asciiDif(column, 'A')
    state.columns.added[id] = false
    state.columns.isShift[id] = false
    state.columns.pos[id] = 0
  }

  return (
    <Box 
        className={!isMobile ? 'overview clearfix' : 'clearfix'}
        sx={{ p: 0, pr: { md: 2, xs: 0 }, mb: { md: 0, xs: 2.5 } }}
    >
      {
        snap.columns.editing ?
          <Box>
            <Box>
              <Typography
                sx={{ fontSize: 15, fontWeight: 500, mb: 2.5, }}
              >
                Column {snap.columns.editingColumn}
              </Typography>
              <Stack direction="row" alignItems="center">
                <AntSwitch
                  sx={{ mr: 1.25 }}
                  onChange={(e) => {
                    handleColumn(e.target.checked ? 'Yes' : 'No')
                    setValue(e.target.checked ? 1 : 0)
                  }}
                />
                <Typography
                  sx={{ fontSize: 15, fontWeight: 500, }}
                >
                  Do you wish to shift the column
                </Typography>
              </Stack>
            </Box>

            {value === 1 ? (
              <Box sx={{ width: '100%' }}>
                <Stack direction='row' sx={{ borderBottom: '1px solid #E5E5E5', }}>
                  <Button onClick={() => changeUnit(0)} sx={{ color: unitTab === 0 ? '#7596AE' : theme => theme.palette.text.primary, p: 0, py: 1, borderBottom: 2, borderRadius: 0, minWidth: 0, mr: 2, fontSize: 15, textTransform: 'capitalize', borderColor: unitTab === 0 ? '#7596AE' : "transparent" }}>Inches</Button>
                  <Button onClick={() => changeUnit(1)} sx={{ color: unitTab === 1 ? '#7596AE' : theme => theme.palette.text.primary, p: 0, py: 1, borderBottom: 2, borderRadius: 0, minWidth: 0, mr: 2, fontSize: 15, textTransform: 'capitalize', borderColor: unitTab === 1 ? '#7596AE' : "transparent" }}>Millimeters</Button>
                </Stack>
                <Box>
                  <CustomInput
                    {...{
                      label: 'Position shifted column',
                      type: 'height2',
                      min: minPos,
                      max: maxPos,
                      value: posColumn,
                      unit,
                      unitName: snap.length.unitName,
                      handleSliderChange,
                      handlePlusChange,
                      handleMinusChange,
                    }}
                  />
                </Box>
              </Box>
            ) : null}
          </Box>
          :
          <Box className='column-box' ref={containerRef} >
            <Typography sx={{ fontSize: 15, fontWeight: 500, mb: 2.5 }}>Base Columns</Typography>
            <Stack spacing={1} sx={{ mb: 4 }}>
              <Column
                column={'A'}
                handleChange={handleChange}
                handleDelete={handleDelete}
                setValue={setValue}
                flag={snap.columns.added[0]}
                isExtra={false}
              />
              <Column
                column={'B'}
                handleChange={handleChange}
                handleDelete={handleDelete}
                setValue={setValue}
                flag={snap.columns.added[1]}
                isExtra={false}
              />
              <Column
                column={'C'}
                handleChange={handleChange}
                handleDelete={handleDelete}
                setValue={setValue}
                flag={snap.columns.added[2]}
                isExtra={false}
              />
              <Column
                column={'D'}
                handleChange={handleChange}
                handleDelete={handleDelete}
                setValue={setValue}
                flag={snap.columns.added[3]}
                isExtra={false}
              />
            </Stack>
            <h5>Extra Columns</h5>

            <Stack spacing={1}>
              {snap.columns.added.map((flag, index) => {
                return index > 3 ? (
                  <Column
                    column={String.fromCharCode(index + 65)}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                    setValue={setValue}
                    flag={flag}
                    key={index}
                    isExtra={true}
                  />
                ) : null
              })}
            </Stack>

            <Button
              variant="contained"
              color="success"
              sx={{
                mt: 2,
                width: '100%',
                borderRadius: 0.5,
                bgcolor: snap.columns.adding === false ? '#7AAE75' : '#BA181B',
              }}
              onClick={() => handleAdd()}
            >
              {snap.columns.adding === false ? 'Add extra column' : 'Cancel'}
            </Button>
          </Box>
      }
    </Box>
  )
}

export default Columns
