import React from 'react';
import ReactDOM from 'react-dom/client';

import MuiThemeProvider from './context/themeProvider';
import ConfigProvider from './context/configProvider';

import './i18n';

import App from './App';
import jQuery from 'jquery';
import './assets/css/index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

window.$ = jQuery;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ConfigProvider>
      <MuiThemeProvider>
        <App />
      </MuiThemeProvider>
    </ConfigProvider>
  </React.StrictMode>
);