import { SvgIcon } from "@mui/material";
import { PersonAdd, Settings } from "@mui/icons-material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import CotactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import FlakyOutlinedIcon from "@mui/icons-material/FlakyOutlined";
import TextureOutlinedIcon from "@mui/icons-material/TextureOutlined";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import ViewComfyOutlinedIcon from "@mui/icons-material/ViewComfyOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";

const GlassIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M12 2L3 10h18L12 2zm-1 10h2v10H9zm5 0h2v10h-2z" />
  </SvgIcon>
);

export const settingList = [
  {
    id: 1,
    route: "Settings",
    icon: <SettingsOutlinedIcon fontSize="small" />,
    title: "Profile & Settings",
  },
  {
    id: 2,
    route: "Orders",
    icon: <PersonAddAlt1OutlinedIcon fontSize="small" />,
    title: "Orders",
  },
  {
    id: 3,
    route: "Clients",
    icon: <SupportAgentOutlinedIcon fontSize="small" />,
    title: "Clients",
  },
  {
    id: 4,
    route: "Employees",
    icon: <BadgeOutlinedIcon fontSize="small" />,
    title: "Employees",
  },
  {
    id: 5,
    route: "History",
    icon: <ManageHistoryOutlinedIcon fontSize="small" />,
    title: "Requests",
  },
  {
    id: 6,
    route: "FriendHistory",
    icon: <CotactMailOutlinedIcon fontSize="small" />,
    title: "Friend Requests",
  },
  {
    id: 7,
    route: "AllProducts",
    icon: <LanguageOutlinedIcon fontSize="small" />,
    title: "Embed website",
  },
  {
    id: 8,
    route: "Sections",
    icon: <WidgetsOutlinedIcon fontSize="small" />,
    title: "Sections",
  },
  {
    id: 11,
    route: "Terms",
    icon: <PolicyOutlinedIcon fontSize="small" />,
    title: "Terms & Policy",
  },
];

export const settingListWithAdmin = [
  {
    id: 1,
    route: "Users",
    icon: <PeopleOutlinedIcon fontSize="small" />,
    title: "Users",
  },
  {
    id: 9,
    route: "AdminFooter",
    icon: <ViewComfyOutlinedIcon fontSize="small" />,
    title: "Page",
  },
  {
    id: 2,
    route: "Products",
    icon: <CategoryOutlinedIcon fontSize="small" />,
    title: "Products",
  },
  {
    id: 3,
    route: "Optionals",
    icon: <FlakyOutlinedIcon fontSize="small" />,
    title: "Optionals",
  },
  {
    id: 4,
    route: "Glasstypes",
    icon: <GlassIcon fontSize="small" />,
    title: "Glass Types",
  },
  {
    id: 5,
    route: "Textures",
    icon: <TextureOutlinedIcon fontSize="small" />,
    title: "Textures",
  },
  {
    id: 6,
    route: "WhatsNew",
    icon: <AnnouncementOutlinedIcon fontSize="small" />,
    title: "Whats New",
  },
];

export const userSettingList = [
  {
    id: 1,
    title: "USER",
  },
  {
    id: 2,
    title: "BRAND",
    complete_id: "brand_status",
  },
  {
    id: 3,
    title: "API",
    complete_id: "api_status",
  },
  {
    id: 4,
    title: "CALCULATION",
    complete_id: "calculation_status",
  },
  {
    id: 5,
    title: "SECURITY",
  },
  {
    id: 6,
    title: "PRODUCTS ASSIGNED",
    complete_id: "assigned_status",
  },
];

export const navigateCases = {
  Products: "/products",
  Optionals: "/optionals",
  Glasstypes: "/glasstypes",
  Textures: "/textures",
  WhatsNew: "/whatsnew",
  AdminFooter: "/pagemanagement",
  Users: "/users",
  Clients: "/clients",
  Employees: "/employees",
  Orders: "/orders",
  Settings: "/settings",
  History: "/history",
  FriendHistory: "/friendhistory",
  Payment: "/payment",
  Terms: "/terms",
  Sections: "/sections",
  TempLink: "/templink",
};
