import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSnapshot } from "valtio";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";

import state from "state";
import { subsystems } from "utils/constant";
import guillotineCompanct from "assets/models/guillotine_new.glb";

const xrepeat = 0.001,
  yrepeat = 0.001;

export default function Compact(props) {
  const location = useLocation();
  const snap = useSnapshot(state);
  const texture = useTexture(snap.structure.textureB);
  const { nodes, materials } = useGLTF(guillotineCompanct);

  const { width, height, unit } = props;

  const system_cnt = parseInt((width - 1) / 4000) + 1;
  const width1 = width / system_cnt;

  const sh = height / subsystems[0].height;
  const sw = width1 / subsystems[0].width;
  const [opacity, setOpacity] = useState(0.7);
  const [color, setColor] = useState("#a7c7cb");
  const [isGulliotine, setIsGulliotine] = useState(false);

  useEffect(() => {
    // setIsGulliotine(location.pathname.slice(1) === "guillotine" && true);
    setIsGulliotine(props.modelID === 0);
  }, []);

  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat * 70, yrepeat * 70);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });



  const list = [];

  const InsideElement = ({groupPosition,groupScale,selected, color}) => {
    const material = new THREE.MeshPhongMaterial({
      color: selected?'#002853':'#a7c7cb',
      opacity: opacity,
      transparent: true,
    });
    return (
      <group position={groupPosition} scale={groupScale}>
        <mesh
          geometry={nodes.LeftProfile002.geometry}
          material={
            props.isTexture ? material_texture : materials["profile.mat"]
          }
          material-color={snap.structure.color}
          position={[-3.55, 0, 1.3]}
          rotation={[0, 0, 0]}
          scale={[0.001, 0.0015, 0.0047]}
        />
        <mesh
          geometry={nodes.glass.geometry}
          material={material}
          // material-color={snap.structure.color}
          position={[0, 0, 1.3]}
          rotation={[0, 0, 0]}
          scale={[0.003, 0.0015, 0.0047]}
        />
        <mesh
          geometry={nodes.LeftProfile002.geometry}
          material={
            props.isTexture ? material_texture : materials["profile.mat"]
          }
          material-color={snap.structure.color}
          position={[3.7, 0, 1.3]}
          rotation={[0, 0, 0]}
          scale={[0.001, 0.0015, 0.0047]}
        />
        <mesh
          geometry={nodes.BottomProfile001.geometry}
          material={
            props.isTexture ? material_texture : materials["profile.mat"]
          }
          material-color={snap.structure.color}
          position={[0.07, 0.535, 1.4]}
          rotation={[0, 0, Math.PI / 2]}
          // scale={[0.0015, 0.0029, 0.0047]}
          scale={[0.0013, 0.0029, 0.0047]}
        />
        <mesh
          geometry={nodes.BottomProfile001.geometry}
          material={
            props.isTexture ? material_texture : materials["profile.mat"]
          }
          material-color={snap.structure.color}
          position={[0.07, -0.535, 1.4]}
          rotation={[0, 0, Math.PI / 2]}
          // scale={[0.0015, 0.0029, 0.0047]}
          scale={[0.0013, 0.0029, 0.0047]}
        />
      </group>
    );
  };

  for (var i = 0; i < system_cnt; i = i + 1) {
    const ID = i * 8 + props.ID;
    const degree = snap.columns.systemPos[ID];
    const systemDirection = snap.columns.systemDirection[ID]

    const selected = (snap.editingSubSystem && snap.editingSubSystemID === ID);

    if(sh>1) {
      state.columns.systemDirection[ID] = 0
    }


    list.push(
      <group
        key={i}
        scale={props.scale}
        rotation={props.rotation}
        position={[props.px, props.pz, props.py]}
        onPointerOver={(e) => {
          setOpacity(0.5);
          setColor("#002853");
          document.body.style.cursor = "pointer";
        }}
        onPointerOut={(e) => {
          setOpacity(0.3);
          setColor("#a7c7cb");
          document.body.style.cursor = "auto";
        }}
        onClick={(e) => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = ID;
          state.editingSystemType = props.systemType;
        }}
      >
        <group
          position={[
            -60.5,
            width1 * i * unit + (width1 * unit) / 2.05,
            (height * unit) / 2.2,
          ]}
          scale={
            +snap.columns.systemDirection[ID] === 0
              ? [50 * sw * 1.45, 80 * sh, 36]
              : [50 * sw * 1.45, 80 * sh, 36]
          }
          rotation={[Math.PI / 2, Math.PI / 2, isGulliotine ? Math.PI : 0]}
        >
          {i === 0 && system_cnt > 1?
          <mesh
          geometry={nodes.LefttMainProfile.geometry}
          material={
            props.isTexture ? material_texture : materials["profile.mat"]
          }
          material-color={snap.structure.color}
          position={[1.838, (sh>=0.98 ? (snap.isModel?0:0.35) : 0), 1.3] }
          rotation={[0, 0, 0]}
          scale={[0.002, 0.0015, 0.0025]}
        />:null}

          <mesh
            geometry={nodes.BottomProfile.geometry}
            material={
              props.isTexture ? material_texture : materials["profile.mat"]
            }
            material-color={snap.structure.color}
            position={[0.035, (sh>0.8 ? 1.8 : 1.9) + (isGulliotine?0:0.1), 1.3]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            scale={[0.0075, 0.00147, 0.003]}
          />
          <mesh
            geometry={nodes.LefttMainProfile.geometry}
            material={
              props.isTexture ? material_texture : materials["profile.mat"]
            }
            material-color={snap.structure.color}
            position={[1.828, (sh>=0.98 ? (snap.isModel?0:0.35) : 0), 1.3] }
            rotation={[0, 0, 0]}
            scale={[0.001, 0.0015, 0.0025]}
          />
          <mesh
            geometry={nodes.LefttMainProfile.geometry}
            material={
              props.isTexture ? material_texture : materials["profile.mat"]
            }
            material-color={snap.structure.color}
            position={[-1.76, (sh>=0.98 ? (snap.isModel?0:0.35) : 0), 1.3] }
            rotation={[0, 0, 0]}
            scale={[0.001, 0.0015, 0.0025]}
          />
          <mesh
            geometry={nodes.BottomMainProfile.geometry}
            material={
              props.isTexture ? material_texture : materials["profile.mat"]
            }
            material-color={snap.structure.color}
            position={[0.035, (sh>=1 ? -1.6 : -1.65), 1.3]}
            rotation={[0, 0, 0]}
            scale={[0.0014, (sh>=0.98 ? 0.0075 : 0.01), 0.0025] }
          />
          <InsideElement
            groupPosition={
              +systemDirection === 0 || (sh>1&&+systemDirection === 1)
                ? [
                    0,
                    -(degree / (isGulliotine ? -48.5 : 45.5)) +
                      // (isGulliotine ? (degree >= 50 ? -0.85 : -0.95) : 1.3),
                      (isGulliotine ? (degree >= 50 ? -0.85 : -0.94) : 1.3),
                    0.905,
                  ]
                : isGulliotine
                ? degree <= 50
                  ? [0, degree / 80 - 0.6, 0.9]
                  : [0, degree / 80 - 0.25, 0.9]
                : [0, -(degree / 62) + 1, 0.9]
            }
            groupScale={
              +systemDirection === 0 || (sh>1&&+systemDirection === 1)
                ? [0.5, 1, 0.4]
                : [0.5, 1.5, 0.4]
            }
            selected={selected}
            color={color}
          />
          <InsideElement
            groupPosition={
              +systemDirection === 0 || (sh>1&&+systemDirection === 1)
                ? [
                    0,
                    degree >= 50
                      ? isGulliotine
                        ? 1.2
                        : degree / (-(degree / 0.9) + 0.2)
                      : isGulliotine
                      ? 0.14
                      : 0.2,
                    0.8,
                  ]
                : isGulliotine
                ? [0, 1, 0.8]
                : [0, -0.6, 0.8]
            }
            groupScale={
              +systemDirection === 0 || (sh>1&&+systemDirection === 1)
                ? [0.5, 1, 0.4]
                : [0.5, 1.5, 0.4]
            }
            selected={selected}
            color={color}
          />
          {(+systemDirection === 0 || (sh>1&&+systemDirection === 1)) && (
            <InsideElement
              // groupPosition={[0, isGulliotine ? 1.2 : -0.9, 0.7]}
              groupPosition={[0, isGulliotine ? 1.21 : -0.9, 0.7]}
              groupScale={[0.5, 1, 0.4]}
              selected={selected}
              color={color}
            />
          )}
        </group>
      </group>
    );
  }

  state.subSystemWidth[props.ID] = width;
  state.subSystemHeight[props.ID] = height;

  return list;
}
