import * as React from 'react';
import { useState, useEffect } from 'react';
import { API_URL, axiosInstanceAuthorized } from 'utils/constant';
import axios from 'axios';

export default function UpdateUser() {

    const user = localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')) : JSON.parse(localStorage.getItem('embed_user'));
    if (user === null) return true;
    const data = {
        role: user.user.role,
        user_id: user.user.origin_id,
        accessToken: user.accessToken,
    }

    axiosInstanceAuthorized.post(API_URL + 'auth/updateUserstatus', data)
        .then(response => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
        })
        .catch(err => {
            console.log(err);
        })
}


