/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { subsystems } from "../utils/constant";
import { useSnapshot } from "valtio";
import state from "../state";
import * as THREE from "three";
import { forteTexture, forteOpacity } from "../utils/constant";
import { useGLTF, useTexture } from "@react-three/drei";

import forte from "../assets/models/forte.glb";
import { systemThickness } from "../utils/constant";
import OverForte from "common/OverForte";

const xrepeat = 0.07,
  yrepeat = 0.07;

export default function Forte2(props) {
  const { nodes, materials } = useGLTF(forte);
  const { px, py, pz, unit, width, height, systemID } = props;
  const snap = useSnapshot(state);
  // const ID = props.ID;

  const int_width = subsystems[systemID].width;
  const int_height = subsystems[systemID].height;

  const rwidth =
    props.systemType === 1
      ? width
      : width + (props.modelID === 7 ? (props.side % 2 ? 0 : -50) : 100);

  const sw = rwidth / int_width;
  const sw2 = (rwidth - 80) / (int_width - 80);
  const sh = height / int_height;

  // if (width / glassCount >= props.max_div_width) {
  //   glassCount = glassCount + 1;
  //   div_width = (width - 40) / glassCount;
  // }
  const dw = (rwidth - int_width) * unit;

  var glassCount = Math.max(
    2,
    parseInt(width / subsystems[systemID].sliderWidth, 0)
  );
  if (props.ID === 4 && snap.columns.addedSubSystem[5] === true) {
    glassCount = Math.max(
      2,
      parseInt((width - 50) / subsystems[systemID].sliderWidth, 0)
    );
  }

  if (glassCount > 4 && glassCount % 2 && props.side % 2)
    glassCount = glassCount + 1;
  if (
    props.side % 2 === 0 &&
    snap.columns.systemDirection[props.ID] == 2 &&
    glassCount % 2 &&
    glassCount > 3
  )
    glassCount = glassCount + 1;
  if (props.side % 2 === 0 && width === 3170) glassCount = 4;
  if (props.side % 2 && width >= 2200) glassCount = Math.max(3, glassCount);
  if (
    props.side % 2 &&
    width <= 3800 &&
    width > 2800 &&
    snap.columns.addedSubSystem[5] === false
  )
    glassCount = 4;

  // state.columns.subSystemCount[props.ID] = glassCount;

  const texture = useTexture(forteTexture);
  texture.rotation = Math.PI;
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
    transparent: true,
    opacity: forteOpacity,
  });

  const material_texture2 = new THREE.MeshPhysicalMaterial({
    map: texture,
    transparent: true,
    opacity: forteOpacity,
  });

  const texture1 = useTexture(snap.structure.textures[6]);
  texture1.wrapS = THREE.RepeatWrapping;
  texture1.wrapT = THREE.RepeatWrapping;
  texture1.repeat.set(xrepeat, yrepeat);
  texture1.rotation = Math.PI;
  const material_texture1 = new THREE.MeshPhysicalMaterial({
    map: texture1,
  });

  if (props.systemType === 1) {
    state.subSystemWidth[props.ID] = width;
    state.subSystemHeight[props.ID] = height;
  }

  var xbias = 0;
  var ybias = 0;

  if (props.modelID === 3 || props.modelID === 5) {
    if (props.systemType === 1) {
      if (props.side % 2 === 0) ybias = 100 * unit;
      if (props.side % 2) ybias = 30 * unit;
      if (props.side % 2 === 0) xbias = 0 * unit * (props.side - 3);
    } else {
      if (props.side % 2) ybias = 50 * unit;
    }
  }

  if (props.modelID === 9 || props.modelID === 11 || props.modelID === 16 || props.modelID === 20) {
    if (props.systemType === 1) {
      if (props.side % 2) ybias = 70 * unit;
    } else {
      if (props.side % 2) ybias = -70 * unit;
      else {
        xbias = -20 * unit * (props.side - 3);
      }
    }
  }

  if (props.modelID === 6 || props.modelID === 10) {
    if (props.systemType === 1) {
      if (props.side % 2) ybias = 30 * unit;
      if (props.side % 2 === 0) xbias = -20 * unit * (props.side - 3);
    } else {
      if (props.side % 2) ybias = -50 * unit;
      else {
        xbias = 10 * unit * (props.side - 3);
      }
    }
  }

  if (props.modelID === 7) {
    if (props.systemType === 1) {
      if (props.side % 2) ybias = 15 * unit;
      if (props.side % 2 === 0) xbias = -15 * unit * (props.side - 3);
    } else {
      if (props.side % 2 === 0) xbias = 10 * unit * (props.side - 3);
      if (props.side % 2 === 0) ybias = 80 * unit;
    }
  }

  if (!OverForte(width, height)) {
    return (
      <group
        rotation={props.rotation}
        scale={[props.scale, props.scale, props.scale]}
        position={[px * unit + xbias, py * unit + ybias, pz * unit]}
        onPointerOver={(e) => {
          document.body.style.cursor = "pointer";
        }}
        onPointerOut={(e) => {
          document.body.style.cursor = "auto";
        }}
        onClick={(e) => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID;
          state.editingSystemType = props.systemType;
        }}
      >
        <group
          key={0}
          position={[0, -120 + height, props.systemType === 2 ? 120 : 0]}
          scale={[1, sh, 1]}
          rotation={[0, (1.5 - props.systemType) * Math.PI, 0]}
        >
          <group
            position={[props.systemType === 1 ? 0.01 : -58, 2.16, -0.08]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={1}
          >
            <group
              scale={[
                1,
                sw,
                props.systemType === 2 &&
                props.modelID === 7 &&
                props.side % 2 === 0
                  ? 1.1
                  : 1,
              ]}
            >
              <mesh
                geometry={nodes.topprofile.geometry}
                material={
                  props.isTexture ? material_texture1 : materials["profile.mat"]
                }
                position={[-13.91, -76.85, 0.01]}
                scale={10}
                material-color={snap.structure.color}
              />
            </group>
            <group scale={[1, sw2, 1]}>
              <mesh
                material-color={snap.forteColor}
                geometry={nodes.screen.geometry}
                material={material_texture}
                position={[-13.91, -76.85, 0.01]}
                scale={[
                  10,
                  10,
                  (10 *
                    (100 -
                      (props.systemType === 1
                        ? snap.columns.systemPos[props.ID]
                        : snap.columns.fortePos[props.ID]))) /
                    100,
                ]}
              />
              <mesh
                geometry={nodes.bottomprofile.geometry}
                material={
                  props.isTexture ? material_texture1 : materials["profile.mat"]
                }
                position={[
                  -13.91,
                  -76.85,
                  (2025 *
                    (props.systemType === 1
                      ? snap.columns.systemPos[props.ID]
                      : snap.columns.fortePos[props.ID])) /
                    100,
                ]}
                scale={10}
                material-color={snap.structure.color}
              />
            </group>
            <mesh
              geometry={nodes.leftprofile.geometry}
              material={
                props.isTexture ? material_texture1 : materials["profile.mat"]
              }
              position={[-13.91, -76.85 + (dw * 1) / 2 / unit, 0.01]}
              scale={10}
              material-color={snap.structure.color}
            />
            <mesh
              geometry={nodes.rightprofile.geometry}
              material={
                props.isTexture ? material_texture1 : materials["profile.mat"]
              }
              position={[-13.91, -76.85 - (dw * 1) / 2 / unit, 0.01]}
              scale={10}
              material-color={snap.structure.color}
            />
          </group>
        </group>
      </group>
    );
  } else {
    state.columns.systemId[props.ID + 12] = 6;
    state.subSystemHeight[props.ID + 12] = 6;
    return (
      <group
        rotation={props.rotation}
        scale={[props.scale, props.scale, props.scale]}
        position={[px * unit + xbias, py * unit + ybias, pz * unit]}
        onPointerOver={(e) => {
          document.body.style.cursor = "pointer";
        }}
        onPointerOut={(e) => {
          document.body.style.cursor = "auto";
        }}
      >
        <group
          key={1}
          position={[
            -width / 4 - 10,
            -120 + height,
            props.systemType === 2 ? 120 : 0,
          ]}
          scale={[1, sh, 1 / 2]}
          rotation={[0, (1.5 - props.systemType) * Math.PI, 0]}
          onClick={(e) => {
            e.stopPropagation();
            state.editingSubSystem = true;
            state.editingSubSystemID = props.ID;
            state.editingSystemType = props.systemType;
          }}
        >
          <group
            position={[props.systemType === 1 ? 0.01 : -58, 2.16, -0.08]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={1}
          >
            <group
              scale={[
                1,
                sw,
                props.systemType === 2 &&
                props.modelID === 7 &&
                props.side % 2 === 0
                  ? 1.1
                  : 1,
              ]}
            >
              <mesh
                geometry={nodes.topprofile.geometry}
                material={
                  props.isTexture ? material_texture1 : materials["profile.mat"]
                }
                position={[-13.91, -76.85, 0.01]}
                scale={10}
                material-color={snap.structure.color}
              />
            </group>
            <group scale={[1, sw2, 1]}>
              <mesh
                material-color={snap.forteColor}
                geometry={nodes.screen.geometry}
                material={material_texture}
                position={[-13.91, -76.85, 0.01]}
                scale={[
                  10,
                  10,
                  (10 *
                    (100 -
                      (props.systemType === 1
                        ? snap.columns.systemPos[props.ID]
                        : snap.columns.fortePos[props.ID]))) /
                    100,
                ]}
              />
              <mesh
                geometry={nodes.bottomprofile.geometry}
                material={
                  props.isTexture ? material_texture1 : materials["profile.mat"]
                }
                position={[
                  -13.91,
                  -76.85,
                  (2025 *
                    (props.systemType === 1
                      ? snap.columns.systemPos[props.ID]
                      : snap.columns.fortePos[props.ID])) /
                    100,
                ]}
                scale={10}
                material-color={snap.structure.color}
              />
            </group>
            <mesh
              geometry={nodes.leftprofile.geometry}
              material={
                props.isTexture ? material_texture1 : materials["profile.mat"]
              }
              position={[-13.91, -76.85 + (dw * 1) / 2 / unit, 0.01]}
              scale={10}
              material-color={snap.structure.color}
            />
            <mesh
              geometry={nodes.rightprofile.geometry}
              material={
                props.isTexture ? material_texture1 : materials["profile.mat"]
              }
              position={[-13.91, -76.85 - (dw * 1) / 2 / unit, 0.01]}
              scale={10}
              material-color={snap.structure.color}
            />
          </group>
        </group>

        <group
          key={2}
          position={[
            width / 4 + 10,
            -120 + height,
            props.systemType === 2 ? 120 : 0,
          ]}
          scale={[1, sh, 1 / 2]}
          rotation={[0, (1.5 - props.systemType) * Math.PI, 0]}
          onClick={(e) => {
            e.stopPropagation();
            state.editingSubSystem = true;
            state.editingSubSystemID = props.ID + 12;
            state.editingSystemType = props.systemType;
          }}
        >
          <group
            position={[props.systemType === 1 ? 0.01 : -58, 2.16, -0.08]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={1}
          >
            <group
              scale={[
                1,
                sw,
                props.systemType === 2 &&
                props.modelID === 7 &&
                props.side % 2 === 0
                  ? 1.1
                  : 1,
              ]}
            >
              <mesh
                geometry={nodes.topprofile.geometry}
                material={
                  props.isTexture ? material_texture1 : materials["profile.mat"]
                }
                position={[-13.91, -76.85, 0.01]}
                scale={10}
                material-color={snap.structure.color}
              />
            </group>
            <group scale={[1, sw2, 1]}>
              <mesh
                material-color={snap.forteColor}
                geometry={nodes.screen.geometry}
                material={material_texture2}
                position={[-13.91, -76.85, 0.01]}
                scale={[
                  10,
                  10,
                  (10 *
                    (100 -
                      (props.systemType === 1
                        ? snap.columns.systemPos[props.ID + 12]
                        : snap.columns.fortePos[props.ID + 12]))) /
                    100,
                ]}
              />
              <mesh
                geometry={nodes.bottomprofile.geometry}
                material={
                  props.isTexture ? material_texture1 : materials["profile.mat"]
                }
                position={[
                  -13.91,
                  -76.85,
                  (2025 *
                    (props.systemType === 1
                      ? snap.columns.systemPos[props.ID + 12]
                      : snap.columns.fortePos[props.ID + 12])) /
                    100,
                ]}
                scale={10}
                material-color={snap.structure.color}
              />
            </group>
            <mesh
              geometry={nodes.leftprofile.geometry}
              material={
                props.isTexture ? material_texture1 : materials["profile.mat"]
              }
              position={[-13.91, -76.85 + (dw * 1) / 2 / unit, 0.01]}
              scale={10}
              material-color={snap.structure.color}
            />
            <mesh
              geometry={nodes.rightprofile.geometry}
              material={
                props.isTexture ? material_texture1 : materials["profile.mat"]
              }
              position={[-13.91, -76.85 - (dw * 1) / 2 / unit, 0.01]}
              scale={10}
              material-color={snap.structure.color}
            />
          </group>
        </group>
      </group>
    );
  }
}

// useGLTF.preload('models/minima6.glb')
