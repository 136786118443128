import { lazy } from 'react';
import MainLayout from '../layouts/MainLayout';
import Loadable from '../components/Loadable';

const ModelView = Loadable(lazy(() => import('../pages/ModelView')));

const ViewRoutes = {
    path: 'view',
    element: <MainLayout />,
    children: [
        {
            path: ':id',
            element: <ModelView modelID={0} />
        },
    ],
};

export default ViewRoutes;