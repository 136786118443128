import { lazy } from 'react';
import ProductView from 'pages/ProductView';
import AllProducts from 'pages/AllProducts';
import ProductInfo from 'pages/ProductInfo';
import AuthGuardEmbed from "../common/authguardembed";

const ProductRoutes = {

    path: '',
    children: [
        {
            path: 'api/product/:id',
            element: <ProductView  />
        },
        {
            path: 'allproducts/:id/:lang?',
            element: (
                <AuthGuardEmbed>
                    <AllProducts />
                </AuthGuardEmbed>
            )
        },
        {
            path: ':modelID/:id/:lang?',
            element: (
                <AuthGuardEmbed>
                    <AllProducts />
                </AuthGuardEmbed>
            )
        },
        {
            path: 'productinfo/:id',
            element: (
                <ProductInfo />
            )
        },
    ]

    // path: 'allproducts',
    // children: [
    //     {
    //         path: ':id',
    //         element: <AllProducts />
    //     }
    // ]
};

export default ProductRoutes;