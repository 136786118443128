/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import * as THREE from 'three'
import triangle from '../../assets/models/triangleNEW.glb';
import { GlassCount } from '../DivCount';

const xrepeat = 0.09,
  yrepeat = 0.03;

export function TriPart(props) {
  const { nodes, materials } = useGLTF(triangle);
  const { width, height1, height2, depth } = props.length;
  const { int_depth, unit, column_depth } = props.int_length;
  const color = props.color;

  const sd = (depth - 170) / (int_depth - 170);
  const dd = depth - int_depth
  const sh = (height2 - height1 - 160) / 490;


  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.rotation = Math.PI / 2;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  const material = new THREE.MeshPhongMaterial({
    color: '#a7c7cb',
    opacity: 0.3,
    transparent: true,
  });

  const div_count_left = props.columns.subSystemCount[0];
  const div_count_right = props.columns.subSystemCount[1];
  var tri_pos_left = 0, tri_pos_right = 0;
  if (div_count_left < 6) tri_pos_left = (depth - 140) * (div_count_left - 1) / div_count_left;
  else tri_pos_left = (depth - 140) * (div_count_left - 2) / div_count_left;
  if (div_count_right < 6) tri_pos_right = (depth - 140) * (div_count_right - 1) / div_count_right;
  else tri_pos_right = (depth - 140) * (div_count_right - 2) / div_count_right;

  return (
    <group {...props} dispose={null}>
    {props.addedSubSystem[0] && props.walls.added[3] === false?
    <>

      <group scale={[10, 10 * sd, 10]} position={[width * unit / 2 - 450, -(depth - 280) * unit / 2 + dd * unit / 2, -11980 + (height1 - 90) * unit]}>
        <group scale={[1, 1, sh]} position={[0, 10, -50 * sh]}>
          <mesh geometry={nodes.glass.geometry} material={material} position={[0, -30, 0]} scale={10.05} />
          <mesh geometry={nodes.triangleprofile.geometry} material={props.isTexture?material_texture:materials.profile} position={[0, 0, 0]} material-color={color} scale={[10 * 45 / 39, 10, 10]} />
        </group>
        <mesh geometry={nodes.triangle_bottom.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, -0.15, 1.4]} scale={10} />
      </group>
      <group scale={[10, 10, 10]} position={[width * unit / 2 - 450, -(depth - 280) * unit / 2 + dd * unit / 2, -11980 + (height1 - 90) * unit]}>
        <group scale={[1, 1, sh]} position={[0, 10, -50 * sh]}>
          {depth > 3300 ? <mesh geometry={nodes.extra_profile.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, tri_pos_left, 286]} scale={[10 * 45 / 39, 10, 10]} /> : null}
        </group>
      </group>
    </>:null}
    {props.addedSubSystem[1] && props.walls.added[1] === false?
    <>
      <group scale={[10, 10 * sd, 10]} position={[-width * unit / 2 + 450, -(depth - 300) * unit / 2 + dd * unit / 2, -11980 + (height1 - 90) * unit]}>
        <group scale={[1, 1, sh]} position={[0, 10, -50 * sh]}>
          <mesh geometry={nodes.glass.geometry} material={material} position={[0, -30, 0]} scale={[-10.05, 10.05, 10.05]} />
          <mesh geometry={nodes.triangleprofile.geometry} material={props.isTexture?material_texture:materials.profile} position={[0, 0, 0]} material-color={color} scale={[10 * 45 / 39, 10, 10]} />
        </group>
        <mesh geometry={nodes.triangle_bottom.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, -0.15, 1.4]} scale={10} />
      </group>

      <group scale={[10, 10, 10]} position={[-width * unit / 2 + 450, -(depth - 280) * unit / 2 + dd * unit / 2, -11980 + (height1 - 90) * unit]}>
        <group scale={[1, 1, sh]} position={[0, 10, -50 * sh]}>
          {depth > 3300 ? <mesh geometry={nodes.extra_profile.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, tri_pos_right, 286]} scale={[10 * 45 / 39, 10, 10]} /> : null}
        </group>
      </group>
      </>:null}


    </group>
  )
}
