import React, { useContext, useState } from "react";
import { ConfigContext } from "../context/config";
import AppContext from "context/AppContext";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Dialog from "@mui/material/Dialog";
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import Slide from "@mui/material/Slide";
import CircleIcon from "@mui/icons-material/Circle";
import Popover from "@mui/material/Popover";
import { SITE_URL, SERVER_URL, API_URL, axiosInstanceAuthorized, ROLE_TYPES } from "../utils/constant";
import axios from "axios";
import { LocalDrinkSharp } from "@mui/icons-material";
import ProductInfo from "./ProductInfo";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  linkBox: {
    marginTop: "0.25rem",
    marginBottom: "0.25rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    paddingRight: "0.6rem",
    paddingLeft: "0.6rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
}));
const View = ({
  item,
  idx,
  favorites,
  setFavorites,
  isFavorite,
  isEmbed,
  embedkey,
  setDialogOpen,
  setAnchorLeft,
  setAnchorTop,
  modelNum,
}) => {
  const classes = useStyles();
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).user
    : JSON.parse(localStorage.getItem("embed_user"));

  const { isDark } = useContext(ConfigContext);
  const { brandColor } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [embedKey, setEmbedKey] = React.useState(
    user !== null ? user.embed_key : embedkey
  );
  const [links, setLinks] = useState([JSON.parse(item.extra_info) || []]);
  const [isHover, setIsHover] = useState(false);
  const { t, i18n } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [openInfo, setOpenInfo] = React.useState(false);

  const handleClickOpenInfo = () => {
    setIsHover((prev) => !prev);
    setOpenInfo((prev) => !prev);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  // const updateFavorite = (item) => {
  //   let favorite_list = favorites;
  //   if (!favorites.includes(item)) {
  //     favorite_list = favorites.concat(item);
  //     setFavorites(favorites.concat(item));
  //   }
  //   else {
  //     let index = favorites.indexOf(item);
  //     favorite_list = [...favorites.slice(0, index), ...favorites.slice(index + 1)];
  //     setFavorites(favorite_list);
  //   }
  //   const data = {
  //     user_id: user.id,
  //     favorite: JSON.stringify(favorite_list),
  //   }
  //   axios
  //     .post(API_URL + "auth/updateFavorite", data)
  //     .then(response => {
  //       console.log("updated favorites");
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     })
  // }

  const getUser = () => {
    const data = {
      user_id: user !== null ? user.id : localStorage.getItem("user_id"),
    };
    if (data.user_id) {
      axiosInstanceAuthorized
        .post(API_URL + "auth/getUser", data)
        .then((response) => {
          setEmbedKey(response.data.user.embed_key);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const showProduct = (product_id) => {
    window.open("productinfo/" + product_id, "_blank");
    // console.log(product_id)
    return false;
  };

  React.useEffect(() => {
    getUser();
    if (!isEmbed) localStorage.setItem("mode", "embed");
  }, []);

  const handleShowLinkClick = (event) => {
    event.preventDefault();
    const x = event.clientX;
    const y = event.clientY;
    const width = window.innerWidth;
    const height = window.innerHeight;

    const mouseXPercent = Math.min((x / width) * 100, 80);
    const mouseYPercent = Math.min((y / height) * 100, 40);

    setAnchorLeft(mouseXPercent);
    setAnchorTop(mouseYPercent);
    setDialogOpen(true);
  };

  const mid_link =
    localStorage.getItem("mode") === "temp" && isEmbed
      ? embedkey + "/" + item.link
      : item.link + "/" + (!isEmbed ? embedKey : embedkey);

  const bodies = item.body.split(" - ");
  if (bodies.length === 2) {
    item.body_title = bodies[0];
    item.body_description = bodies[1];
  } else if (bodies.length === 1) {
    item.body_title = "";
    item.body_description = bodies[0];
  } else if (bodies.length > 2) {
    item.body_title = bodies[0];
    item.body_description = bodies.slice(1).join(" - ");
  } else {
    item.body_title = "";
    item.body_description = "";
  }
  // const links = JSON.parse(item.extra_info);
  return (
    <>
      <Grid
        item
        key={idx}
        lg={3}
        md={6}
        sm={6}
        xs={12}
        sx={{ position: "relative" }}
      >
        <Link
          href={`${SITE_URL}/${localStorage.getItem("mode")}/${item.link}/${!isEmbed ? embedKey : embedkey}?lang=${i18n.language}`}
          className={isHover ? "isHover" : ""}
          sx={{
            display: "flex",
            width: "100%",
            overflow: "hidden",
            position: "relative",
            "&.isHover, &:hover": {
              "& .h-bg": {
                opacity: 1,
              },
              "& img": {
                transform: "scale(1.05)",
              },
              ".desc": {
                opacity: 1,
                visibility: "visible",
              },
              "& p": {
                transform: "translateY(0)",
              },
            },
          }}
        >
          <Box
            sx={{
              display: "block",
              overflow: "hidden",
              position: "relative",
              width: "100%",
            }}
          >
            <Box
              sx={{
                bottom: 0,
                left: 0,
                opacity: 0,
                position: "absolute",
                right: 0,
                top: 0,
                transition: "opacity .3s ease",
                zIndex: 1,
                bgcolor: "rgba(32, 32, 32, .85)",
              }}
              className="h-bg"
            />
            <Box
              component="img"
              alt=""
              src={SERVER_URL + item.image}
              sx={{
                objectFit: "cover",
                height: "calc(100% + 1px)",
                width: "calc(100% + 1px)",
                transition: "transform .3s ease",
              }}
            />
          </Box>
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              opacity: 0,
              padding: 5,
              position: "absolute",
              transition: "visibility .3s ease,opacity .3s ease",
              visibility: "hidden",
              zIndex: 1,
            }}
            className="desc"
          >
            <Typography
              sx={{
                fontSize: 18,
                color: "#ffffff",
                mb: 1.5,
                transform: "translateY(20px)",
                transition: "transform .3s ease",
              }}
            >
              {item.title}
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                color: "#c8c8c8",
                lineHeight: 1,
                transform: "translateY(20px)",
                transition: "transform .3s ease",
              }}
            >
              {item.ready ? (item.body_title ? `${t(item.body_title)} - ${t(item.body_description.trim())}` : t(item.body_description.trim())) : t('Coming Soon')}
            </Typography>
          </Stack>
        </Link>
        <Stack
          sx={{
            position: "relative",
            pt: 2,
            pb: 2,
            borderBottom: !item.ready
              ? "1px solid " + brandColor
              : !isDark
              ? "1px solid #232323"
              : "1px solid white",
            display: "inline-block",
            width: "100%",
          }}
        >
          <span style={{ float: "left", fontSize: "20px" }}>
            {t(item.title) + " - " + t(item.description)}
          </span>
          <span style={{ float: "right" }} className="desc">
            {false &&
            !isEmbed &&
            (user.isNew !== 0 || user.role === ROLE_TYPES.ADMIN) &&
            !modelNum ? (
              <CircleIcon
                color="warning"
                className="logo"
                sx={{
                  width: "30%",
                  mr: 1,
                }}
                onClick={(event) => handleShowLinkClick(event)}
              />
            ) : null}
            {/* {links !== null && links.length > 0 && links[0].title !== null && links[0].title !== ''?
            <ArrowDownwardOutlinedIcon color="info" className="logo"
              aria-describedby={id} variant="contained" onClick={e=>{
                e.preventDefault();
                handleClickOpenInfo();
                // showProduct(item.id);
              }}
            />:null} */}

            {openInfo ? (
              <ArrowDropDownIcon
                sx={{ cursor: "pointer" }}
                fontSize="large"
                onClick={handleClickOpenInfo}
              />
            ) : (
              <ArrowDropUpIcon
                sx={{ cursor: "pointer" }}
                fontSize="large"
                onClick={handleClickOpenInfo}
              />
            )}
            {/* <ShareOutlinedIcon color="info" className="logo"
                aria-describedby={id} variant="contained" onClick={handleClick}
              /> */}
            {/* {isFavorite?
            <FavoriteIcon color="warning" className="logo" 
              onClick={e=>{e.preventDefault(); updateFavorite(item.id)}}
            />
            :
            (
              !favorites.includes(item.id)?<FavoriteBorderIcon color="warning" className="logo"
              onClick={e=>{e.preventDefault(); updateFavorite(item.id)}}
              />:null
            )
            } */}
          </span>
          {/* <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography sx={{ p: 2 }}><a target="_blank" href={SITE_URL + "/" + localStorage.getItem('mode') + "/" + item.link + "/" + (!isEmbed ? embedKey : embedkey)}>{SITE_URL + "/" + localStorage.getItem('mode') + "/" + mid_link}</a></Typography>
            </Popover> */}
          <Stack
            sx={{
              p: 2,
              background: "aliceblue",
              fontSize: 20,
              display: openInfo ? "inline-block" : "none",
              width: "100%",
              bottom: "60px",
            }}
            className={"animation-div" + (openInfo ? " visible-div" : "")}
          >
            <Stack>
              <Link
                target="_blank"
                sx={{ textDecoration: "none", backgroundColor: brandColor }}
                className={classes.linkBox}
                href={`${SITE_URL}/${localStorage.getItem("mode")}/${item.link}/${!isEmbed ? embedKey : embedkey}?lang=${i18n.language}`}
              >
                <span className="text-white text-uppercase">{t('EMBED LINK')} </span>
                <ChevronRightIcon fontSize="medium" sx={{ color: "white" }} />
              </Link>
            </Stack>

            {links &&
              links.length > 0 &&
              links.map((link_item, index) => {
                return link_item.link &&
                  link_item.title !== null &&
                  link_item.title !== "" ? (
                  <Stack key={index}>
                    <Link
                      target="_blank"
                      sx={{
                        textDecoration: "none",
                        backgroundColor: brandColor,
                      }}
                      className={classes.linkBox}
                      href={
                        link_item.link.startsWith("pdf")
                          ? SERVER_URL + link_item.link
                          : link_item.link
                      }
                    >
                      <span className="text-white text-uppercase">
                        {link_item.title}
                      </span>
                      <ChevronRightIcon
                        fontSize="medium"
                        sx={{ color: "white" }}
                      />
                    </Link>
                  </Stack>
                ) : null;
              })}
          </Stack>
        </Stack>
        {/* {links !== null && links.length > 0 && links[0].title !== null && links[0].title !== '' ?
          <Stack sx={{ p: 2, display: openInfo ? 'inline-block' : 'none', width: 'calc(100% - 40px)' }}
            className={"animation-div" + (openInfo ? ' visible-div' : '')}>

            {links.map((link_item, index) => {
              return link_item.title !== null && link_item.title !== '' ? (
                <Stack key={index}>
                  <span ><a href={link_item.link.startsWith("pdf") ? SERVER_URL + link_item.link : link_item.link} target="_blank"
                    style={{ textDecoration: 'none', color: 'white' }}
                  >{link_item.title} {index}</a></span>
                </Stack>
              ) : null
            })}
          </Stack> : null} */}
      </Grid>
      {/* <Dialog
        fullScreen
        open={openInfo}
        onClose={handleCloseInfo}
        TransitionComponent={Transition}
      >    
        <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: brandColor }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseInfo}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {item.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <ProductInfo id={item.id} />
      </Dialog> */}
    </>
  );
};

export default View;
