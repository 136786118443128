import { proxy } from "valtio";
import {lengths, wooddesign, coverColors, autoRotateSpeed} from '../utils/constant'
// Using a Valtio state model to bridge reactivity between
// the canvas and the dom, both can write to it and/or react to it.
const state = proxy({
  basketFlag: true,
  flag: false,
  isDesign: null,
  isTexture: false,
  isBladeTexture: true,
  designStyle: null,
  hasLedLight: 0,
  hasLight: 0,
  hasHeater: 0,
  hasFan: 0,
  hasWifi: 0,
  hasPolyurethan: 0,
  blades: {
    color: '#1976d2',
    texture: wooddesign[0][0].url,
    textureR: wooddesign[0][0].url2,
    textureA: wooddesign[0][0].url2,
    textureB: wooddesign[0][0].url2,
    textureC: wooddesign[0][0].url2,
    textureD: wooddesign[0][0].url2,
    textureType: 'Frosted',
    rotation: 0,
    offset: 0,
    direction: 0,
    opacity: 0,
    colorName: coverColors[0][0].title,
    type: 'standart',
  },
  structure: {
    color: coverColors[0][0].color,
    colorName: coverColors[0][0].title,
    texture: wooddesign[0][0].url,
    textures: [wooddesign[0].url, wooddesign[0].url, wooddesign[0].url, wooddesign[0].url, wooddesign[0].url, wooddesign[0].url, wooddesign[0].url, wooddesign[0].url, wooddesign[0].url],
  },
  length: {
    unit: 1 / 25.4,
    unitName: 'inch',
    width: lengths[0].int_width,
    depth: lengths[0].int_depth,
    height1: lengths[0].int_height1,
    height2: lengths[0].int_height2,
  },
  default_width: lengths[0].int_width,
  default_depth: lengths[0].int_depth,
  default_height1: lengths[0].int_height1,
  default_height2: lengths[0].int_height2,
  columns: {
    count: 0,
    adding: false,
    addingSubSystem: false,
    editing: false,
    editingColumn: null,
    editingSide: 0,
    minPos: 500,
    maxPos: 2100,
    direction: [true, true, true, true, true, true, true, true,true, true, true, true, true, true, true, true,true, true, true, true, true, true, true, true],
    removable: [false, false, false, false, true, true, true, true,true, true, true, true, true, true, true, true],
    added: [true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    addedSubSystem: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    deletedSubSystem: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    subSystemCount: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    systemPos: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    systemDirection : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    systemId: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    systemLock: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    pos: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    addedForte: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
      false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    fortePos: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    side: [1,2,3,4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    isShift: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]
  },
  walls: {
    adding: false,
    added: [false, false, false, false],
    addingSide: 0,
  },
  subSystemWidth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  subSystemHeight: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  systemColor: ['#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4',
                '#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4','#e9e9e4'
  ],
  systemTexture: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  systemSide: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  systemColorName: [
      'WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE',
      'WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE','WHITE',
  ],
  forteColor: '#e9e9e4',
  forteColorName: 'WHITE',
  systemSliderCount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  systemVisible: [true, true, true, true],
  capellaCount: 0,
  capellaSize: [0, 0, 0],
  capellaUnit: 1,
  datasent: 0,
  responseMsg: '',
  autoRotateSpeed: autoRotateSpeed,
  systemID: 0,
  editingSubSystem: false,
  editingSubSystemID: 0,
  editingSystemType: 1,
  editingGlassCount: 0,
  editingWall: false,
  editingWallID: 0,
  install: null,
  unit: 1 / 25.4,
  unitName: 'inch',
  isModel: false,
  isSubSystem: false,
  modelID: 0,
  loading: false,
  optionals: [false, false, false, false, false, false, false, false, false, false, false, false],
  optionalCount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  capellaDirection: 0,
});
export default state