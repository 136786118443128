import React from 'react'
import logo from '../../assets/models/schildrLogo.glb';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber';
import { invertColor } from '../cabbana/Cabbana';

export default function GetLogo(props) {
    const gltf = useLoader(GLTFLoader, logo, loader => {
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('/draco-gltf/')
        loader.setDRACOLoader(dracoLoader)
    })
    
    const { nodes, materials } = gltf;
    const { color, columns } = props;
    const { width, depth, height1 } = props.length;
    const { int_width, int_depth, int_height1, unit, oy, oz } = props.int_length;
    const dw = width - int_width;
    const dd = depth - int_depth;
    const dh1 = height1 - int_height1;
    return (
    <>
    {props.isRoomy && props.modelID === 10?
    null:
    <group
        position={[(width + 10) * unit / 2, (oy - 100) * unit, (height1 - oz + 80) * unit]}
        scale={100}
    >
        <mesh geometry={nodes.Schildr_Logo.geometry} material={materials.Logo} 
          material-color={invertColor(color)}
          scale={0.6}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        />
    </group>}
    {!props.isRoomy && props.modelID === 10?
    null:
    <group
      position={[(-width - 10) * unit / 2, (oy - 100) * unit, (height1 - oz + 80) * unit]}
      scale={100}
    >
      <mesh geometry={nodes.Schildr_Logo.geometry} material={materials.Logo} 
        material-color={invertColor(color)}
        scale={0.6}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
      />
    </group>}
    </>
  )
  
  }