import React, { useState, useEffect, useContext } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { withStyles } from "@mui/material";

import axios from "axios";
import { ConfigContext } from "../../context/config";
import AppContext from "../../context/AppContext";
import { useSnapshot } from "valtio";
import state from "../../state";
import ColumnCount from "../ColumnCount";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ValidationForm from "./ValidationForm";
import PrevNext from "./PrevNext";
import OrderForm from "../OrderForm";
import {
  API_URL,
  links,
  hasBlade,
  steps,
  subsystems,
} from "../../utils/constant";
import { toast } from "react-toastify";
import { toastSuccess, toastError } from "../Toast";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const dr = [""];
const compact_dr = ["3 Sliders", "2 Sliders"];
const openings = ["Left", "Right", "Center"];

export default function Description(props) {
  const { modelID, systemID, isModel } = props;
  const snap = useSnapshot(state);
  const { t, i18n } = useTranslation();

  var desc = "";
  desc = desc + `${t('Structure')}: ` + snap.structure.colorName;

  if (isModel && (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 20)) {
    desc = desc + ", Curtain Color: " + snap.systemColorName[23];
  }

  if (isModel && hasBlade[modelID].option) {
    desc = desc + `, ${t('Blade')}: ` + snap.blades.colorName;
  }

  if (isModel && steps[modelID][1].detail.length > 1) {
    desc = desc + ", Glass: " + snap.blades.colorName;
  }

  if (!isModel) {
    if (
      subsystems[systemID].option === 2 ||
      subsystems[systemID].option === 0
    ) {
      desc = desc + ", " + snap.systemSliderCount[0] + ` ${t('Sliders')}`;
    }
    if (subsystems[systemID].option === 2 && systemID > 0) {
      desc = desc + ", " + dr[snap.columns.systemDirection[0]];
    }
    if (systemID === 0) {
      desc =
        desc + ", " + compact_dr[parseInt(snap.columns.systemDirection[0])];
    }
    if (systemID === 2) {
      desc = desc + ", " + snap.columns.systemLock[0] ? t("Has Key") : t("No Key");
    }
    if (systemID === 7) {
      desc = desc + `, ${t('Blade')}: ` + snap.systemColorName[0];
    }
    if (systemID === 6 || systemID === 9) {
      desc = desc + ", Type: " + snap.systemColorName[0];
    }
  }

  return desc;
}
