import React from "react";
import { SideInfills, SideForSubSystem, AddSubSystems } from "./SideInfills";

const DrawElement = (props) => {
  const {
    element,
    material,
    materials,
    snap,
    mesh_position = null,
    mesh_scale = null,
    mesh_rotation = null,
    materialTexture,
    color,
    origin,
  } = props;

  const isTexture = snap.isTexture;

  let position = element.position;
  let scale = [element.scale.x, element.scale.y, element.scale.z];
  let rotation = element.rotation;
  position = [position.x, position.y, position.z];
  if (mesh_position)
    position = [
      position[0] + mesh_position[0],
      position[1] + mesh_position[1],
      position[2] + mesh_position[2],
    ];
  if (mesh_scale) scale = mesh_scale;
  if (mesh_rotation) rotation = mesh_rotation;

  if (!origin) {
    position = [-474.9 - position[0], position[1], position[2]];
    scale = [scale[0], -scale[1], scale[2]];
  }

  return (
    <group position={position}>
      <group rotation={rotation} scale={scale}>
        <mesh
          castShadow
          geometry={element.geometry}
          material={
            material
              ? material
              : isTexture
              ? materialTexture
              : materials.profile
          }
          material-color={color}
        />
      </group>
    </group>
  );
};

const TwoSidedDoor = (props) => {
  const {
    nodes,
    materials,
    material,
    isTexture,
    snap,
    width,
    height1,
    materialTexture,
    silverMaterial,
    handleColor,
    activeStep,
    subStep,
    origin,
  } = props;

  const [currentColumn, setCurrentColumn] = React.useState(-1);
  const elements = nodes.Scene.children[0].children;
  const meshes = []; // fixed door
  const meshes2 = []; // rotate door
  const meshes3 = []; // handle
  const spw = 132.29; // sidePanelWidth
  const spw2 = 134; // rightsidePanelWidth
  const sph = 1949.999; // sidePanelHeight
  const sfh = 1899.999; // sideFrameHeight
  const upw = 556.27; // underPanelWidth
  const uph = 160; // underPanelHeight
  const tph = 49.999; // topPanelHeight
  const ddw = spw * 2 + upw; // defaultDoorWidth
  const ddh = sph; //defaultDoorHeight
  const gh = 1640; //glassHeight
  const spaceX = 32.72;

  //   const scale = [sw * 0.036, sh * 0.036, 0.036];
  const scale = [1, 1, 1];

  const bw = width / 2 - spw - spw2 + spaceX; // real bottom width
  const sw = [bw / upw, 1, 1];

  const tw = width - spw2 * 2; // real top width

  const dh = height1 / ddh;
  const sh = [1, dh, 1];
  const sgh = (height1 - uph - tph) / gh; // scale glass height
  meshes.push({
    name: "door_bottom",
    scale: sw,
    pos: [width / 4 - spaceX / 2, 0, 0],
  });
  if (origin)
    meshes2.push({
      name: "profile_top",
      scale: [tw / upw, 1, 1],
      pos: [0, 0, height1 - ddh],
    });

  meshes2.push({
    name: "profile_right",
    scale: sh,
    pos: [(width - ddw) / 2, 0, ((dh - 1) * ddh) / 2],
  });
  meshes.push({
    name: "door_left",
    scale: sh,
    pos: [ddw / 2 - spaceX, 0, ((dh - 1) * sfh) / 2],
  });
  meshes.push({
    name: "door_right",
    scale: sh,
    pos: [(width - ddw) / 2, 0, ((dh - 1) * sfh) / 2],
  });
  meshes.push({
    name: "door_top",
    scale: sw,
    pos: [width / 4 - spaceX / 2, 0, height1 - ddh - (ddh - sfh) * (dh - 1)],
  });

  meshes3.push({
    name: "Handle_1",
    pos: [ddw / 2 - spaceX, 0, 0],
  });
  meshes3.push({
    name: "handle_back_1",
    pos: [ddw / 2 - spaceX, 0, 0],
  });
  if (origin)
    meshes3.push({
      name: "key",
      pos: [ddw / 2 - spaceX, 0, 0],
    });

  meshes3.push({
    name: "doorpet_001",
    pos: [(width - ddw) / 2, 0, height1 - ddh],
  });
  meshes3.push({
    name: "doorpet_002",
    pos: [(width - ddw) / 2, 0, 0],
  });

  const ctrX = origin ? width / 2 - 270 : -210 - width / 2;
  const ctrY = 10;

  return (
    <group scale={scale}>
      <group position={[ctrX, ctrY, 0]}>
        <group
          rotation={[
            0,
            0,
            (origin ? 1 : -1) * (snap.blades.rotation / 180) * Math.PI,
          ]}
        >
          <group position={[-ctrX, -ctrY, 0]}>
            <DrawElement
              element={elements.find((item) => item.name == "door_glass")}
              material={material}
              materialTexture={materialTexture}
              isTexture={isTexture}
              color={snap.structure.color}
              snap={snap}
              mesh_scale={[bw / upw, sgh, 1]}
              mesh_position={[width / 4 - spaceX / 2, 0, ((dh - 1) * gh) / 2]}
              origin={origin}
            />
            {meshes.map((mesh, index) => (
              <DrawElement
                key={index}
                element={elements.find((item) => item.name == mesh.name)}
                materials={materials}
                materialTexture={materialTexture}
                isTexture={isTexture}
                color={snap.structure.color}
                snap={snap}
                mesh_position={mesh.pos}
                mesh_scale={mesh.scale}
                origin={origin}
              />
            ))}
            {meshes3.map((mesh, index) => (
              <DrawElement
                key={index}
                element={elements.find((item) => item.name == mesh.name)}
                material={silverMaterial}
                materialTexture={silverMaterial}
                color={handleColor}
                snap={snap}
                mesh_position={mesh.pos}
                origin={origin}
              />
            ))}
          </group>
        </group>
      </group>
      {meshes2.map((mesh, index) => (
        <DrawElement
          key={index}
          element={elements.find((item) => item.name == mesh.name)}
          materials={materials}
          materialTexture={materialTexture}
          isTexture={isTexture}
          color={snap.structure.color}
          snap={snap}
          mesh_position={mesh.pos}
          mesh_scale={mesh.scale}
          origin={origin}
        />
      ))}

      {origin ? (
        <group>
          <AddSubSystems
            columns={snap.columns}
            width={snap.length.width}
            height={snap.length.height1}
            systemID={snap.systemID}
            activeStep={activeStep}
            subStep={subStep}
            setCurrentColumn={setCurrentColumn}
          />
          <SideForSubSystem
            width={snap.length.width}
            height={snap.length.height1}
            columns={snap.columns}
            activeStep={activeStep}
            subStep={subStep}
            currentColumn={currentColumn}
          />
          <SideInfills
            width={snap.length.width}
            height={snap.length.height1}
            columns={snap.columns}
            systemID={snap.systemID}
            isTexture={snap.isTexture}
            texture={snap.structure.textureD}
            texture1={snap.structure.textureC}
            doubleSystem={props.doubleSystem}
            walls={snap.walls}
            dx={-246}
          />
        </group>
      ) : null}
    </group>
  );
};

export default TwoSidedDoor;
