import { useSnapshot } from "valtio";
import state from "../state";
import { lengths, secondHeight } from "../utils/constant";
import { TopDivCount } from './DivCount';

function TopCount(width, depth, modelID) {
    if (modelID === 0) {
        const tmp_count = parseInt(width / lengths[modelID].int_div_width);
        const tmp_width = width / tmp_count;
        var div_count = 0;
        div_count = tmp_width > lengths[modelID].max_div_width ? tmp_count + 1 : tmp_count
        return div_count;
    }
    if (modelID === 1 || modelID === 2 || modelID === 4 || modelID === 8 || modelID === 13 || modelID === 15 || modelID === 14) {
        const div_count = parseInt((width <= lengths[modelID].mid_length_limit[0] ? width : width / 2) / lengths[modelID].int_div_width) - 2;
        return width <= lengths[modelID].mid_length_limit[0] ? div_count : div_count * 2;
    }
    if (modelID === 3 || modelID === 5) {
        var div_count = 0;
        if (width >= lengths[modelID].mid_length_limit[0]) div_count = 2 * TopDivCount((width) / 2, modelID);
        if (width >= lengths[modelID].mid_length_limit[1]) div_count = 3 * TopDivCount((width) / 3, modelID);
        if (width < lengths[modelID].mid_length_limit[0]) div_count = TopDivCount(width, modelID);
        return div_count;
    }
    if (modelID === 2) {
        if (width > 6000) return TopDivCount(width / 2, modelID);
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 4700) return 4;
        if (width <= 5500) return 5;
        if (width <= 6000) return 6;
        return 6;
    }
    if (modelID === 8) {
        if (width <= 2700) return 2;
        if (width <= 3700) return 3;
        if (width <= 5000) return 4;
        if (width <= 6000) return 6;
        return 6;
    }
    if (modelID === 6) {
        if (width > 8000) return 3 * TopDivCount(width / 3, modelID);
        if (width > 4000) return 2 * TopDivCount(width / 2, modelID);
        if (width <= 3300) return 3;
        return 4;
    }
    if (modelID === 10) {
        if (width > 8000) return 2 * 3 * TopDivCount(width / 3, modelID);
        if (width > 4000) return 2 * 2 * TopDivCount(width / 2, modelID);
        if (width <= 3300) return 2 * 3;
        return 2 * 4;
    }
    if (modelID === 7) {
        if (width < 3805) return 2;
        if (width < 5025) return 3;
        if (width < 6250) return 4;
        if (width < 7475) return 5;
        if (width < 8700) return 6;
        if (width < 9925) return 7;
        if (width < 11150) return 8;
        return 9;
    }
    if (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 20) {
        const div_count = parseInt((parseInt((depth - 100) / 400) + 1) / 2);
        const top_div_count = parseInt((width - 1) / 4000) + 1;
        return div_count * top_div_count + 1;
    }
}

export function LedCount(width, depth, modelID) {
    const div_count = TopCount(width, depth, modelID);

    return parseInt(width * depth / 1500000);

    if (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 20) return (div_count - 1) * 3;
    else if (secondHeight[modelID]) return (div_count - 1) * 2;
    else if (modelID === 2 || modelID === 8) {
        return div_count * 2 * (parseInt(depth / 4001) + 1);
    }
    else {
        return parseInt(div_count / 3)
    }
}
