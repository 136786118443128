/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF } from "@react-three/drei";
import { lightColor } from "../../utils/constant";
import cabbana_lights from "../../assets/models/cabbana_lights.glb";

export function ShortLight(props) {
  const { nodes, materials } = useGLTF(cabbana_lights);
  const { modelID } = props;
  return (
    <group {...props} dispose={null}>
      {props.bladeRotation < 90 ? (
        <mesh
          geometry={nodes.short_light.geometry}
          material={materials.Light}
          position={[-1.22, 2.42, 0]}
          receiveShadow={false}
          material-color={lightColor}
        />
      ) : null}
      <mesh
        geometry={nodes.short_light.geometry}
        material={materials.Light}
        position={[2.22, 2.42, 0]}
        receiveShadow={false}
        material-color={lightColor}
      />
    </group>
  );
}

// useGLTF.preload('models/cabbana_lights.glb')
