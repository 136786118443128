import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useContext } from 'react';

import { ConfigContext } from './config';
import { dark, light } from '../state/theme';

// ** Declare Theme Provider
const MuiThemeProvider = ({ children }) => {
    const { isDark } = useContext(ConfigContext);

    const theme = createTheme(isDark ? dark : light);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default MuiThemeProvider;
