/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { subsystems } from '../utils/constant';
import { useSnapshot } from 'valtio';
import state from '../state';
import * as THREE from 'three'
// import { handleHeight } from '../utils/constant';
import bifold_door from '../assets/models/bifolddoor.glb';
import { BifoldGlassCount } from '../models/DivCount';
import { GetHandleColor } from './GetHandleColor';
import { xrepeat, yrepeat } from '../utils/constant';

export default function BifoldDoor(props) {
  const { nodes, materials } = useGLTF(bifold_door)
  const { px, py, width, height, systemID } = props;
  const [opacity, setOpacity] = useState(0.7);
  const [color, setColor] = useState('#a7c7cb');
  const snap = useSnapshot(state);
  const handle_color = GetHandleColor();

  const direction = snap.columns.systemDirection[props.ID];
  const isLeft = direction != 1 ? true : false;
  const dr = isLeft?1:-1;

  const int_width = subsystems[systemID].width;
  const int_height = subsystems[systemID].height;
  const sw = width / int_width;
  const dw = width - int_width;
  const sh = height / int_height;
  const glassWidth = 932;
  // let glassCount = Math.max(2, Math.round((width - 40) / glassWidth, 0));
  let glassCount = Math.max(2, parseInt(width / subsystems[systemID].sliderWidth));
  if (glassCount > 4 && glassCount % 2) glassCount = glassCount + 1;

  glassCount = BifoldGlassCount(width, glassCount, props.modelID);
  if (glassCount > 7 && glassCount % 2) glassCount = glassCount + 1;
  if (glassCount >= subsystems[systemID].option_limit && glassCount % 2 === 0) state.columns.systemDirection[props.ID] = 2;
  if (glassCount % 2 && snap.columns.systemDirection[props.ID] === 2) state.columns.systemDirection[props.ID] = 0;
  if (!snap.isModel) state.editingGlassCount = glassCount;
  state.systemSliderCount[props.ID] = glassCount;

  const div_width = (width - 90 * sw) / glassCount;
  const gw = div_width / glassWidth;

  // state.columns.subSystemCount[props.ID] = glassCount;
  const selected = (snap.editingSubSystem && snap.editingSubSystemID === props.ID);

  const material = new THREE.MeshPhongMaterial({
    color: selected?'#002853':'#a7c7cb',
    opacity: opacity,
    transparent: true,
  });

  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  // texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const list = [];
  const pos = Math.min(85, snap.columns.systemPos[props.ID]);
  // const dk = snap.systemSide[props.ID] === 0 ? 1:-1;

  const alpha = Math.acos((100 - pos) / 100) * (snap.systemSide[props.ID] == 0 ? 1:-1);

  const silver_material = new THREE.MeshStandardMaterial( {
    metalness: 1,   // between 0 and 1
    roughness: 0.5, // between 0 and 1
  } );


  if (snap.columns.systemDirection[props.ID] != 2 || glassCount % 2) {
      for (let i = 0; i < glassCount; i = i + 1) {
        const rank = isLeft ? i : glassCount - i - 1;
        const move_point = div_width * rank * pos / 100 * dr;
        const da = isLeft?dr:dr * (glassCount % 2 === 0 ? -1 : 1);
        const dd = (isLeft || glassCount % 2 === 0)?1:0;
        if (i % 2 === 0) {
          list.push(
            <group key={i} position={[-370 + (1-dd) * div_width * Math.sin(alpha), 1270 - div_width * i + move_point - (isLeft?0:div_width * (1-Math.cos(alpha))), 0]} scale={[1, gw, 1]} rotation={[0, 0, da * alpha]}>
              <group position={[370, -1270, 0]}>
                <mesh material-color={snap.structure.color} geometry={nodes.windowframe3.geometry} material={props.isTexture?material_texture:materials.profile} position={[-370, 800.18, -11.09]} rotation={[0, 0, -0.03]} />
                <mesh geometry={nodes.window3.geometry} material={material} position={[-374, 798.45, -11.36]} rotation={[0, 0, 0]} />
                <mesh material-color={snap.structure.color} geometry={(isLeft || glassCount % 2)?nodes.bracket3.geometry:nodes.bracket2.geometry} material={materials.profile} position={[-321.07, isLeft?1264.41:334, -12.62]} />
                {i === glassCount - 1 && isLeft?<mesh material-color={handle_color} material={silver_material} geometry={nodes.Handle1.geometry} position={[-330, 350.18, -42.72]} rotation={[0, 0, Math.PI / 2]} scale={[-0.37, 0.45, 0.46]} />:null}
                {i === 0 && !isLeft?<mesh material-color={handle_color} material={silver_material} geometry={nodes.Handle1.geometry} position={[-330, 1220.18, -42.72]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[-0.37, 0.45, 0.46]} />:null}
              </group>
            </group>
          )
        }
        else {
          list.push(
            <group key={i} position={[-370 + dd * div_width * Math.sin(alpha), 1270 - div_width * i + move_point - (isLeft?0:div_width * (1-Math.cos(alpha))), 0]} scale={[1, gw, 1]} rotation={[0, 0, -da * alpha]}>
              <group position={[370, -1270, 0]}>
                <mesh material-color={snap.structure.color} geometry={nodes.windowframe3.geometry} material={props.isTexture?material_texture:materials.profile} position={[-370, 800.18, -11.09]} rotation={[0, 0, -0.03]} />
                <mesh geometry={nodes.window3.geometry} material={material} position={[-374, 798.45, -11.36]} rotation={[0, 0, 0]} />
                <mesh material-color={snap.structure.color} geometry={(isLeft || glassCount % 2)?nodes.bracket2.geometry:nodes.bracket3.geometry} material={materials.profile} position={[-321.07, isLeft?1264.41:334, -12.62]} />
                {i === glassCount - 1 && isLeft?<mesh material-color={handle_color} material={silver_material} geometry={nodes.Handle1.geometry} position={[-330, 350.18, -42.72]} rotation={[0, 0, Math.PI / 2]} scale={[-0.37, 0.45, 0.46]} />:null}
              </group>
            </group>
          )
        }
      }
  }
  else {
    const cnt = glassCount / 2;
    for (let i = 0; i < cnt; i = i + 1) {
      const rank = isLeft ? i : glassCount - i - 1;
      const move_point = div_width * rank * pos / 100 * dr;
      const da = isLeft?dr:dr * (cnt % 2 === 0 ? -1 : 1);
      const dd = (isLeft || cnt % 2 === 0)?1:0;
      if (i % 2 === 0) {
        list.push(
          <group key={i} position={[-370 + (1-dd) * div_width * Math.sin(alpha), 1270 - div_width * i + move_point - (isLeft?0:div_width * (1-Math.cos(alpha))), 0]} scale={[1, gw, 1]} rotation={[0, 0, da * alpha]}>
            <group position={[370, -1270, 0]}>
              <mesh material-color={snap.structure.color} geometry={nodes.windowframe3.geometry} material={props.isTexture?material_texture:materials.profile} position={[-370, 800.18, -11.09]} rotation={[0, 0, -0.03]} />
              <mesh geometry={nodes.window3.geometry} material={material} position={[-374, 798.45, -11.36]} rotation={[0, 0, 0]} />
              <mesh material-color={snap.structure.color} geometry={(isLeft || cnt % 2)?nodes.bracket3.geometry:nodes.bracket2.geometry} material={materials.profile} position={[-321.07, isLeft?1264.41:334, -12.62]} />
              {i === cnt - 1?<mesh material-color={handle_color} material={silver_material} geometry={nodes.Handle1.geometry} position={[-330, 350.18, -42.72]} rotation={[0, 0, Math.PI / 2]} scale={[-0.37, 0.45, 0.46]} />:null}
            </group>
          </group>
        )
      }
      else {
        list.push(
          <group key={i} position={[-370 + dd * div_width * Math.sin(alpha), 1270 - div_width * i + move_point - (isLeft?0:div_width * (1-Math.cos(alpha))), 0]} scale={[1, gw, 1]} rotation={[0, 0, -da * alpha]}>
            <group position={[370, -1270, 0]}>
              <mesh material-color={snap.structure.color} geometry={nodes.windowframe3.geometry} material={props.isTexture?material_texture:materials.profile} position={[-370, 800.18, -11.09]} rotation={[0, 0, -0.03]} />
              <mesh geometry={nodes.window3.geometry} material={material} position={[-374, 798.45, -11.36]} rotation={[0, 0, 0]} />
              <mesh material-color={snap.structure.color} geometry={(isLeft || cnt % 2)?nodes.bracket2.geometry:nodes.bracket3.geometry} material={materials.profile} position={[-321.07, isLeft?1264.41:334, -12.62]} />
              {i === cnt - 1?<mesh material-color={handle_color} material={silver_material} geometry={nodes.Handle1.geometry} position={[-330, 350.18, -42.72]} rotation={[0, 0, Math.PI / 2]} scale={[-0.37, 0.45, 0.46]} />:null}
            </group>
          </group>
        )
      }
    }


    for (let i = cnt; i < glassCount; i = i + 1) {
      const rank = glassCount - i - 1;
      const move_point = -div_width * rank * pos / 100 * dr;
      const da = isLeft?dr:dr * (cnt % 2 === 0 ? -1 : 1);
      const dd = (isLeft || cnt % 2 === 0)?1:0;
      if (i % 2 === 0) {
        list.push(
          <group key={i} position={[-370 + (1-dd) * div_width * Math.sin(alpha), 1270 - div_width * i + move_point - div_width * (1-Math.cos(alpha)), 0]} scale={[1, gw, 1]} rotation={[0, 0, da * alpha]}>
            <group position={[370, -1270, 0]}>
              <mesh material-color={snap.structure.color} geometry={nodes.windowframe3.geometry} material={materials.profile} position={[-370, 800.18, -11.09]} rotation={[0, 0, -0.03]} />
              <mesh geometry={nodes.window3.geometry} material={material} position={[-374, 798.45, -11.36]} rotation={[0, 0, 0]} />
              <mesh material-color={snap.structure.color} geometry={(cnt % 2 === 0)?nodes.bracket3.geometry:nodes.bracket2.geometry} material={materials.profile} position={[-321.07, 334, -12.62]} />
              {i === cnt?<mesh material-color={handle_color} material={silver_material} geometry={nodes.Handle1.geometry} position={[-330, 1220.18, -42.72]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[-0.37, 0.45, 0.46]} />:null}
            </group>
          </group>
        )
      }
      else {
        list.push(
          <group key={i} position={[-370 + dd * div_width * Math.sin(alpha), 1270 - div_width * i + move_point - div_width * (1-Math.cos(alpha)), 0]} scale={[1, gw, 1]} rotation={[0, 0, -da * alpha]}>
            <group position={[370, -1270, 0]}>
              <mesh material-color={snap.structure.color} geometry={nodes.windowframe3.geometry} material={materials.profile} position={[-370, 800.18, -11.09]} rotation={[0, 0, -0.03]} />
              <mesh geometry={nodes.window3.geometry} material={material} position={[-374, 798.45, -11.36]} rotation={[0, 0, 0]} />
              <mesh material-color={snap.structure.color} geometry={(cnt % 2 === 0)?nodes.bracket2.geometry:nodes.bracket3.geometry} material={materials.profile} position={[-321.07, 334, -12.62]} />
              {i === cnt?<mesh material-color={handle_color} material={silver_material} geometry={nodes.Handle1.geometry} position={[-330, 1220.18, -42.72]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[-0.37, 0.45, 0.46]} />:null}
            </group>
          </group>
        )
      }
    }



  }

  state.subSystemWidth[props.ID] = width;
  state.subSystemHeight[props.ID] = height;

  return (
    <group
      rotation={props.rotation}
      scale={props.scale * 0.1}
      // position={[px * unit, py * unit, pz * unit]}
      // position={[px + width * unit / 2, pz, py]}
      position={[px, 0, py]}
      onPointerOver={
        e => {
          setOpacity(0.7);
          setColor('#002853');
          document.body.style.cursor = 'pointer';
        }
      }
      onPointerOut={
        e => {
          setOpacity(0.7);
          setColor('#a7c7cb');
          document.body.style.cursor = 'auto';
        }
      }
      onClick={
        e => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID;
          state.editingSystemType = props.systemType;
          state.editingGlassCount = glassCount;
        }
      }

    >
      <group rotation={[0, 0, 0]}
        position={[0, width / 2 , height / 2]}
        scale={[1, 1, sh]}
      >

      <group position={[280, 1.25 + dw / 2 - 45 * (sw - 1), 0]} scale={1}>
        <mesh material-color={snap.structure.color} geometry={nodes.Profile1.geometry} material={props.isTexture?material_texture:materials.profile} position={[-362.38, -dw / 2 + 45 * (sw - 1), 0]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1, sw, 1]}/>
        {list}
      </group>

      </group>
    </group>
  )
}
