import React, { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { axiosInstanceAuthorized, modeltypes as modeltype } from "../utils/constant";
import { ConfigContext } from "../context/config";
import Navbar from "./Navbar";
import state from "../state";
import View from "./View";
import axios from "axios";
import { API_URL } from "../utils/constant";
import AppContext from "context/AppContext";
import { GetEmbedKey } from "pages/GetEmbedKey";
import { GetTempLink } from "./GetTempLink";
import AccessDeniedPage from "./AccessDeniedPage";
import { FacebookIcon, InstagramIcon, YoutubeIcon } from "components/SvgIcons";
import { useTranslation } from "react-i18next";
import storageService from "services/storage.service";
import { constructUrl } from "utils/helpers";

const AllProducts = () => {
  // const { sidebar } = useContext(AppContext);
  const { setMode } = useContext(AppContext);
  const sidebar = false;
  const { isDark } = useContext(ConfigContext);
  const [products, setProducts] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const { id, modelID, lang: urlLang } = useParams();
  const [anchorLeft, setAnchorLeft] = useState(0);
  const [anchorTop, setAnchorTop] = useState(0);
  const [user, setUser] = useState(null);
  const { t, i18n } = useTranslation();
  const [modeltypes, setModelTypes] = useState(modeltype);
  const getProducts = () => {
    axiosInstanceAuthorized
      .post(API_URL + "product/getall", { embed_key: id })
      .then((response) => {
        localStorage.setItem("mode", response.data.mode);
        setProducts(response.data.products);
        setUser(response.data.user);
        return response.data.products;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const findInFavorite = (item) => {
  //   if (favorites.includes(item)) return true;
  //   return false;
  // }

  const isEmbed = GetEmbedKey(id);
  const isTemp = GetTempLink(id);

  React.useEffect(() => {
    state.isModel = false;
    const lang = urlLang ? urlLang : storageService.get('lang');
    i18n.changeLanguage(lang.toLowerCase());
    setMode(1);
    if (id) getProducts();
    if (modelID) {
      setModelTypes([modelID]);
    } else {
      setModelTypes(modeltype);
    }
  }, [modelID]);

  if (!isEmbed && isTemp !== true) {
    return <AccessDeniedPage message={isTemp} />;
  }

  return products !== null ? (
    <>
      <Grid container>
        <Grid
          item
          lg={sidebar ? 9 : 12}
          md={sidebar ? 8 : 12}
          sm={12}
          xs={12}
          className="main"
          sx={{ bgcolor: "#F5F5F5" }}
        >
          <Box
            className="homepage"
            sx={{
              pl: { md: 10 },
              pr: { md: 10 },
              overflow: "auto",
              height: "calc(100vh - 79px)",
              background: isDark
                ? // 'linear-gradient(180deg, rgba(38,69,106,1) 0%, rgba(50,85,111,1) 35%, rgba(117,150,174,1) 100%)' :
                // 'linear-gradient(180deg, rgba(117,150,174,1) 0%, rgba(221,227,232,1) 48%, rgba(255,255,255,1) 100%)'
                "black"
                : "white",
            }}
          >
            <Navbar nomenu={isTemp} products={products || []} />
            <Box sx={{ p: { md: 0, xs: 3 }, mt: { md: "104px", xs: 0 } }}>
              <Grid container>
                <Grid item xs={12} md={8}>
                  <Box>
                    <Typography
                      sx={{ fontSize: { md: 66, xs: 50 }, fontWeight: 500 }}
                      variant="h1"
                    >
                      {t('3D CONFIGURATOR')}
                    </Typography>
                    <Typography sx={{ fontSize: 15, mt: 2 }}>
                      {t('We offer various outdoor solutions. Please scroll to see more products.')}
                    </Typography>
                    {!isEmbed && isTemp ? (
                      <Typography sx={{ fontSize: 15, mt: 2 }}>
                        {t('This temporary link is intended for use in a restricted area. Do not attempt to use this outside of the')} {` ${localStorage.getItem("restricted_country")}`}.
                      </Typography>
                    ) : null}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ fontSize: 24, mt: 2, textAlign: "right" }}
                      >
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                            user?.address
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                        >
                          {user && user.address
                            ? user.address.split("<br>").map((txt) => (
                              <>
                                {txt}
                                <br />
                              </>
                            ))
                            : null}
                        </a>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ fontSize: 24, mt: 2, textAlign: "right" }}
                      >
                        <a
                          href={`mailto:${user?.email}`}
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                        >
                          {user?.email}
                        </a>
                        <br />
                        <span>{user?.mobile}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid
                      item
                      xs={6}
                      mt={4}
                      className="d-flex"
                      style={{
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      {user?.instagram_link && user.instagram_link !== "" ? (
                        <Box sx={{ ml: 2 }}>
                          <a
                            href={user?.instagram_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "inherit",
                              ml: 2,
                            }}
                          >
                            <InstagramIcon fontSize={"medium"} />
                          </a>
                        </Box>
                      ) : null}
                      {user?.facebook_link && user.facebook_link !== "" ? (
                        <Box sx={{ ml: 2 }}>
                          <a
                            href={user?.facebook_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "inherit",
                            }}
                          >
                            <FacebookIcon fontSize={"medium"} />
                          </a>
                        </Box>
                      ) : null}
                      {user?.youtube_link && user?.youtube_link !== "" ? (
                        <Box sx={{ ml: 2 }}>
                          <a
                            href={user?.youtube_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "inherit",
                            }}
                          >
                            <YoutubeIcon fontSize={"medium"} />
                          </a>
                        </Box>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* {(products.filter(item=>(item.visible && findInFavorite(item.id))).length)>0?

              <>
              <Grid container sx={{ mt: 4 }} spacing={5} id="favorite">
              <Grid item md={12} sm={12} xs={12}
                sx={{ mb: '-20px' }}
              >
                <div
                  style={{ fontSize: '33px', position: 'relative', display: 'inline' }}
                >
                  Favorites
                  <span
                    style={{ fontSize: '12px', position: 'absolute', right: '-15px', top: '-0px' }}
                  >({products.filter(item=>(item.visible && findInFavorite(item.id))).length})</span>
                </div>
              </Grid>
              {products.map((item, idx) => {
                return (item.visible && findInFavorite(item.id))?(
                  <View item={item} idx={idx} favorites={favorites} setFavorites={setFavorites} isFavorite={true} />
                ):null
              })}
            </Grid>
            </>:null} */}
              {products !== undefined
                ? modeltypes.map((model_item, index) => {
                  return (
                    <div key={index}>
                      <Grid container sx={{ mt: 4 }} spacing={5} id={"menu_" + index}>
                        {products.filter((item) => item.product_type === model_item && item.visible).length > 0 ? (
                          <Grid item md={12} sm={12} xs={12} sx={{ mb: "-20px" }}>
                            <Link to={constructUrl(encodeURIComponent(model_item), id, urlLang)} style={{ textDecoration: "none", color: "inherit" }}>
                             <div style={{ fontSize: "33px", position: "relative", display: "inline" }}>
                                {t(model_item)}
                                <span style={{ fontSize: "12px", position: "absolute", right: "-15px", top: "-0px" }}>
                                  (
                                  {products.filter((item) => item.product_type === model_item && item.visible).length}
                                  )
                                </span>
                              </div>
                            </Link>
                          </Grid>
                        ) : null}
                        {products.map((item, idx) => {
                          return model_item === item.product_type &&
                            item.visible ? (
                            <View
                              item={item}
                              idx={idx}
                              key={idx + "product"}
                              favorites={favorites}
                              setFavorites={setFavorites}
                              isFavorite={false}
                              isEmbed={true}
                              embedkey={id}
                              setAnchorLeft={setAnchorLeft}
                              setAnchorTop={setAnchorTop}
                              modelNum={0}
                            />
                          ) : null;
                        })}
                      </Grid>
                    </div>
                  );
                })
                : null}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <Footer user={JSON.parse(localStorage.getItem('embed_user'))}/> */}
      {/* <HorizontalMenu /> */}
    </>
  ) : null;
};

export default AllProducts;