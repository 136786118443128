import { useSnapshot } from "valtio";
import state from "../state";

export default function ColumnCount() {
    const snap = useSnapshot(state);
    let n = 0;

    for (var i = 0; i < snap.columns.added.length; i = i + 1) {
        if (snap.columns.added[i]) n = n + 1;
    }
    return n;
}