/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { subsystems } from '../utils/constant';
import { useSnapshot } from 'valtio';
import state from '../state';
import * as THREE from 'three'
import { handleHeight } from '../utils/constant';
import Liftandslidedoor from '../assets/models/lands.glb';
import { LiftGlassCount } from '../models/DivCount';
import { GetHandleColor } from './GetHandleColor';

const xrepeat = 0.3,
  yrepeat = 0.3;

export default function LiftAndSlideDoor2(props) {
  const { nodes, materials } = useGLTF(Liftandslidedoor);
  const { px, py, pz, width, height, systemID, unit } = props;
  const [opacity, setOpacity] = useState(0.3);
  const [color, setColor] = useState('#a7c7cb');
  const snap = useSnapshot(state);
  const handle_color = GetHandleColor();
  // const handle_height = height >= 2000 ? handleHeight + 130 : height / 2 + 130;
  const handle_height = handleHeight + 130;

  const direction = snap.columns.systemDirection[props.ID];
  const isLeft = direction != 1 ? true : false;
  const dr = isLeft ? 1 : -1;

  const int_width = 3500;
  const int_height = 2771;
  const sw = width / int_width;
  const dw = width - int_width;
  const sh = height / int_height;
  const dh2 = height - int_height;
  const glassWidth = 1569;
  const doorWidth = 1742;
  const doorHeight = 2677;
  const glassHeight = 2507;
  const pthickness = doorWidth - glassWidth;
  const sh2 = (height - int_height + glassHeight) / glassHeight;
  const sh3 = (height - 34) / doorHeight;
  const dh3 = (height - 34) - doorHeight;
  // var glassCount = Math.max(2, Math.round((width - 40) / glassWidth, 0));
  var glassCount = Math.max(2, parseInt(width / subsystems[systemID].sliderWidth));
  glassCount = LiftGlassCount(width, glassCount, props.modelID);
  if (glassCount > 4 && glassCount % 2) glassCount = glassCount + 1;
  if (glassCount > 4 && glassCount % 2 === 0) state.columns.systemDirection[props.ID] = 2;
  if ((glassCount % 2) && snap.columns.systemDirection[props.ID] === 2) state.columns.systemDirection[props.ID] = 0;

  var div_width = (width - 110 - pthickness / 2) / glassCount + pthickness / 2;
  const dw3 = div_width - doorWidth;
  // state.columns.subSystemCount[props.ID] = glassCount;

  const selected = (snap.editingSubSystem && snap.editingSubSystemID === props.ID);

  const material = new THREE.MeshPhongMaterial({
    color: selected ? '#002853' : '#a7c7cb',
    opacity: opacity,
    transparent: true,
  });

  if (snap.columns.systemDirection[props.ID] == 2) {
    div_width = (width - 110 - pthickness) / glassCount + pthickness / 2
  }


  if (!snap.isModel) state.editingGlassCount = glassCount;
  state.systemSliderCount[props.ID] = glassCount;
  const texture = useTexture(snap.structure.textures[5]);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const list = [];
  var pos = snap.columns.systemPos[props.ID];
  if (glassCount % 2 === 0 && glassCount > subsystems[systemID].option_limit) state.columns.systemDirection[props.ID] = 2;
  if (snap.columns.systemDirection[props.ID] === 2) pos = Math.max(0 / (glassCount - 2), pos);

  if (snap.columns.systemDirection[props.ID] != 2 || glassCount % 2) {
    for (let i = 0; i < glassCount; i = i + 1) {
      const rank = isLeft ? glassCount - i - 1 : i;
      const start_point = rank * 100 / (glassCount - 1);
      const move_point = Math.max(0, Math.min(pos, 100 - 8 / glassCount) - start_point) / 100 * (div_width - pthickness / 2) * (glassCount - 1);
      list.push(
        <group key={i} position={[-(-div_width * i + move_point * dr + pthickness / 2 * i) * 10, 30 * rank * 10, 0]} scale={[1, 1, 1]}>
          <mesh geometry={nodes.leftdoor1Glass002.geometry} material={material} position={[8218.08, -254.51, -12527.77 - dh2 * 5 - 500]} rotation={[0, 0, Math.PI]} scale={[(div_width - pthickness) / glassWidth, 1, sh2 * 1.05]} />
          <mesh geometry={nodes.d1_profile_bottom001.geometry} material={props.isTexture ? material_texture : materials.profile} position={[8211.77, -273.88, -12893.83 - dh3 * 5]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[div_width / doorWidth, 1, 1]} />
          <mesh geometry={nodes.d1_profile_top001.geometry} material={props.isTexture ? material_texture : materials.profile} position={[8211.77, -273.88, 12937.98 + dh3 * 5]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[div_width / doorWidth, 1, 1]} />
          <mesh geometry={nodes.d1_profile_left001.geometry} material={props.isTexture ? material_texture : materials.profile} position={[16415.79 + dw3 * 5, -273.89, 21.61]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[1, sh3, 1]} />
          <mesh geometry={nodes.d1_profile_right001.geometry} material={props.isTexture ? material_texture : materials.profile} position={[11.17 - dw3 * 5, -273.88, 21.61]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[1, sh3, 1]} />
        </group>
      )
    }
  }
  else {
    const cnt = glassCount / 2;
    for (let i = 0; i < cnt; i = i + 1) {
      const rank = isLeft ? cnt - i - 1 : i;
      const start_point = rank * 100 / (cnt - 1);
      const move_point = Math.max(0, Math.min(pos, 100 - 6 / cnt) - start_point) / 100 * (div_width - pthickness / 2) * (cnt - 1);
      list.push(
        <group key={i} position={[-(-div_width * i + move_point * dr + pthickness / 2 * i) * 10, 30 * rank * 10, 0]} scale={[1, 1, 1]}>
          <mesh geometry={nodes.leftdoor1Glass002.geometry} material={material} position={[8218.08, -254.51, -12527.77 - dh2 * 5 - 500]} rotation={[0, 0, Math.PI]} scale={[(div_width - pthickness) / glassWidth, 1, sh2 * 1.05]} />
          <mesh geometry={nodes.d1_profile_bottom001.geometry} material={props.isTexture ? material_texture : materials.profile} position={[8211.77, -273.88, -12893.83 - dh3 * 5]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[div_width / doorWidth, 1, 1]} />
          <mesh geometry={nodes.d1_profile_top001.geometry} material={props.isTexture ? material_texture : materials.profile} position={[8211.77, -273.88, 12937.98 + dh3 * 5]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[div_width / doorWidth, 1, 1]} />
          <mesh geometry={nodes.d1_profile_left001.geometry} material={props.isTexture ? material_texture : materials.profile} position={[16415.79 + dw3 * 5, -273.89, 21.61]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[1, sh3, 1]} />
          <mesh geometry={nodes.d1_profile_right001.geometry} material={props.isTexture ? material_texture : materials.profile} position={[11.17 - dw3 * 5, -273.88, 21.61]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[1, sh3, 1]} />
        </group>
      )
    }

    for (let i = 0; i < cnt; i = i + 1) {
      const rank = isLeft ? i : cnt - i - 1;
      const start_point = rank * 100 / (cnt - 1);
      const move_point = Math.max(0, Math.min(pos, 100 - 6 / cnt) - start_point) / 100 * (div_width - pthickness / 2) * (cnt - 1);
      list.push(
        <group key={i + cnt} position={[-(-div_width * (cnt + i) - move_point * dr + pthickness * (cnt + i) / 2 - pthickness / 2) * 10, 30 * rank * 10, 0]} scale={[1, 1, 1]}>
          <mesh geometry={nodes.leftdoor1Glass002.geometry} material={material} position={[8218.08, -254.51, -12527.77 - dh2 * 5 - 500]} rotation={[0, 0, Math.PI]} scale={[(div_width - pthickness) / glassWidth, 1, sh2 * 1.05]} />
          <mesh geometry={nodes.d1_profile_bottom001.geometry} material={props.isTexture ? material_texture : materials.profile} position={[8211.77, -273.88, -12893.83 - dh3 * 5]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[div_width / doorWidth, 1, 1]} />
          <mesh geometry={nodes.d1_profile_top001.geometry} material={props.isTexture ? material_texture : materials.profile} position={[8211.77, -273.88, 12937.98 + dh3 * 5]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[div_width / doorWidth, 1, 1]} />
          <mesh geometry={nodes.d1_profile_left001.geometry} material={props.isTexture ? material_texture : materials.profile} position={[16415.79 + dw3 * 5, -273.89, 21.61]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[1, sh3, 1]} />
          <mesh geometry={nodes.d1_profile_right001.geometry} material={props.isTexture ? material_texture : materials.profile} position={[11.17 - dw3 * 5, -273.88, 21.61]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[1, sh3, 1]} />
        </group>
      )
    }
  }

  const silver_material = new THREE.MeshPhysicalMaterial({
    metalness: 1,   // between 0 and 1
    roughness: 0.4, // between 0 and 1
  });
  const handle_list = [];

  if (snap.columns.systemDirection[props.ID] != 2) {
    handle_list.push(

      <React.Fragment key={0}>
        <group position={[-0.7, 0, 0]}>
          {isLeft ?
            <>
              <group position={[-dr * (width - 200) * 10 / 2, (glassCount - 1) * 30 * 10 - 300, 0]}>
                <mesh geometry={nodes.leftdoorhandle002.geometry} material={silver_material} position={[0, -275.33, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
                <mesh geometry={nodes.key002.geometry} material={silver_material} position={[0, 331.27, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
              </group>
              <group position={[-(dr * (Math.min(pos, 100 - 8 / glassCount) / 100 * (div_width - pthickness / 2) * (glassCount - 1) - (width - 200) / 2)) * 10, -300, 0]}>
                <mesh geometry={nodes.leftdoorhandle001.geometry} material={silver_material} position={[0, -275.33, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
                <mesh geometry={nodes.key001.geometry} material={silver_material} position={[0, 331.27, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
              </group>
            </> : <>
              <group position={[-dr * (width - 200) * 10 / 2, (glassCount - 1) * 30 * 10 - 300, 0]}>
                <mesh geometry={nodes.leftdoorhandle001.geometry} material={silver_material} position={[0, -275.33, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
                <mesh geometry={nodes.key001.geometry} material={silver_material} position={[0, 331.27, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
              </group>
              <group position={[-(dr * (Math.min(pos, 100 - 8 / glassCount) / 100 * (div_width - pthickness / 2) * (glassCount - 1) - (width - 200) / 2)) * 10, -300, 0]}>
                <mesh geometry={nodes.leftdoorhandle002.geometry} material={silver_material} position={[0, -275.33, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
                <mesh geometry={nodes.key002.geometry} material={silver_material} position={[0, 331.27, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
              </group>
            </>}
        </group>
      </React.Fragment>
    )
  }
  else {
    if (true) {
      const cnt = glassCount / 2;
      const rank = 0;
      const start_point = rank * 100 / (cnt - 1);
      const move_point = Math.max(0, Math.min(pos, 100 - 6 / cnt) - start_point) / 100 * (div_width - pthickness / 2) * (cnt - 1);
      handle_list.push(
        <React.Fragment key={0}>
          <group position={[-0.7, 0, 0]}>
            <group position={[-dr * (width - 200) * 10 / 2, (glassCount / 2 - 1) * 30 * 10 - 300, 0]}>
              <mesh geometry={nodes.leftdoorhandle001.geometry} material={silver_material} position={[0, -275.33, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
              <mesh geometry={nodes.key001.geometry} material={silver_material} position={[0, 331.27, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
            </group>
            <group position={[-move_point * dr * 10 - 500, -300, 0]}>
              <mesh geometry={nodes.leftdoorhandle001.geometry} material={silver_material} position={[0, -275.33, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
              <mesh geometry={nodes.key001.geometry} material={silver_material} position={[0, 331.27, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
            </group>
            <group position={[move_point * dr * 10 + 500, -300, 0]}>
              <mesh geometry={nodes.leftdoorhandle001.geometry} material={silver_material} position={[0, -275.33, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
              <mesh geometry={nodes.key001.geometry} material={silver_material} position={[0, 331.27, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
            </group>
            <group position={[-(dr * (- (width - 200) / 2)) * 10, (glassCount / 2 - 1) * 30 * 10 - 300, 0]}>
              <mesh geometry={nodes.leftdoorhandle001.geometry} material={silver_material} position={[0, -275.33, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
              <mesh geometry={nodes.key001.geometry} material={silver_material} position={[0, 331.27, 1144.11]} rotation={[Math.PI, 0, -Math.PI]} scale={10} />
            </group>
          </group>
        </React.Fragment>
      )
    }
    else {
      handle_list.push(
        <React.Fragment key={0}>
          <group position={[-0.7, 0, 0]}>
            <group position={[dr * (width / 2 - 70) * 0.01, handle_height * 0.01, glassCount * 30 * 0.01]}>
              <mesh geometry={nodes.leftdoorhandle.geometry} material={props.isTexture ? material_texture : materials.profile} position={[0, 12.68, -0.25]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
            </group>
            <group position={[(dr * (-width / 2 + 70 + Math.min(pos, 100 - 8 / glassCount) / 100 * div_width * (glassCount - 1))) * 0.01, handle_height * 0.01, 40 * 0.01]}>
              <mesh geometry={nodes.leftdoorhandle.geometry} material={props.isTexture ? material_texture : materials.profile} position={[0, 12.68, -0.25]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
            </group>
          </group>
        </React.Fragment>
      )
    }
  }

  if (props.systemType === 1) {
    state.subSystemWidth[props.ID] = width;
    state.subSystemHeight[props.ID] = height;
  }

  var xbias = 0;
  var ybias = 0;
  var xdelta = 0;
  if (props.modelID === 6 || props.modelID === 10) {
    if (props.side % 2) ybias = 10 * unit;
    if (props.side % 2 === 0) xbias = -30 * unit * (props.side - 3);
  }

  if (props.modelID === 7) {
    if (props.side % 2) ybias = 30 * unit;
    if (props.side % 2 === 0) xbias = 30 * unit * (props.side - 3);
    if (props.side % 2 === 0) xdelta = -10 * unit;
  }
  return (
    <group
      rotation={props.rotation}
      scale={props.scale * 100}
      position={[px * unit + xbias + xdelta, py * unit + ybias, pz * unit]}
      onPointerOver={
        e => {
          setOpacity(0.5);
          setColor('#002853');
          document.body.style.cursor = 'pointer';
        }
      }
      onPointerOut={
        e => {
          setOpacity(0.3);
          setColor('#a7c7cb');
          document.body.style.cursor = 'auto';
        }
      }
      onClick={
        e => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID;
          state.editingSystemType = props.systemType;
          state.editingGlassCount = glassCount;
        }
      }
    >
      <group position={[-0.05, height * 0.01 / 2 - 0.1, 0]} rotation={[Math.PI / 2, Math.PI, 0]} scale={0.001}>
        <mesh geometry={nodes.Box001.geometry} material={props.isTexture ? material_texture : materials.profile} position={[0, 5.59, -height * 5]} scale={[sw, 1, 1]} material-color={snap.structure.color} />
        <mesh geometry={nodes.Box002.geometry} material={props.isTexture ? material_texture : materials.profile} position={[0, 5.59, height * 5]} scale={[sw, 1, 1]} material-color={snap.structure.color} />
        <mesh geometry={nodes.Box003.geometry} material={props.isTexture ? material_texture : materials.profile} position={[17228.82 + dw * 5, 5.59, -1.53]} scale={[1, 1, sh]} material-color={snap.structure.color} />
        <mesh geometry={nodes.Box004.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-17229.66 - dw * 5, 5.59, -1.53]} scale={[1, 1, sh]} material-color={snap.structure.color} />
        <group position={[dw3 * 10 / 2 - width * 10 / 2 + 1050, 0, 0]}>
          {list}
        </group>
        {/* <group position={[0, 0, -height * 5 - 2000 + handleHeight * 10]}> */}
        <group position={[0, 0, -10]}>
          {handle_list}
        </group>
      </group>
    </group>
  )
}
