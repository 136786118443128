/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 minima_handle_center_empty.glb
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import minimahandle from '../assets/models/minima_handle_center_empty.glb'

export default function MinimaHandle(props) {
  const { nodes } = useGLTF(minimahandle)
  return (
    <group {...props} dispose={null}>
      <mesh material-color={props.color} geometry={nodes.key_1.geometry} material={props.material} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} />
    </group>
  )
}

useGLTF.preload('/minima_handle_center_empty.glb')
