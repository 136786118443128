import React, { useContext, useEffect } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { sideStyles, steps } from "../../utils/constant";
import AppContext from "../../context/AppContext";

import Dimension from "./Dimension";
import DimensionDoor from "./DimensionDoor";
import Columns from "./Columns";
import Colors from "./Colors";
import ColorsTexture from "./ColorsTexture";
import Polycarbonat from "./Polycarbonat";
import GlassColor from "./GlassColor";
import SideInfills from "./SideInfills";
import TopInfills from "./TopInfills";
import Lighting from "./Lighting";
import ComfortDesign from "./ComfortDesign";
import BladeRotation from "./BladeRotation";
import BladeColors from "./BladeColors";
import Installation from "./Installation";
import GlassType from "./GlassType";
import Order from "./Order";
import { useSnapshot } from "valtio";
import state from "state";
import { useTranslation } from "react-i18next";

export default function Gallery(props) {
  const { activeStep, subStep, modelID, systemID, activeUser } =
    useContext(AppContext);
  
  const { t, i18n } = useTranslation();

  const snap = useSnapshot(state);
  // const systemID = sideStyles[modelID][0].systemID
  const render = (activeStep, subStep) => {
    let currentStep = "";
    if (modelID === 18 && activeStep == 0) currentStep = "DimensionDoor";
    else if (steps[modelID].length === activeStep) {
      currentStep = "Order";
    } else {
      currentStep =
        snap.isModel || activeStep !== 1
          ? steps[modelID][activeStep].detail[subStep]
          : "Colors&Texture";
    }

    switch (currentStep) {
      case "Dimensions":
        return <Dimension />;
      case "Columns":
        return <Columns />;
      case "Blade colors":
        return <BladeColors />;
      case "Colors":
        return <Colors />;
      case "Colors&Texture":
        return <ColorsTexture />;
      case "Polycarbonat":
        return <Polycarbonat />;
      case "Glass Color":
        return <GlassColor />;
      case "Roof Type":
        return <GlassType />;
      case "Sub systems":
        return <SideInfills />;
      case "Top":
        return <TopInfills />;
      case "Lighting":
        return <Lighting />;
      case "Optional":
        return <ComfortDesign />;
      case "Blade rotation":
        return <BladeRotation />;
      case "Installation":
        return <Installation />;
      case "Order":
        return <Order />;
      case "DimensionDoor":
        return <DimensionDoor />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          fontSize: { md: 30, xs: 20 },
          fontWeight: 500,
          pr: { md: 5, xs: 0 },
        }}
      >
        {steps[modelID].length === activeStep
          ? t("SEND CONFIGURATION DATA")
          : t(steps[modelID][activeStep].detail[subStep])}
      </Typography>
      <Typography
        sx={{
          fontSize: 15,
          mt: 2,
          mb: { md: 4, xs: 1.25 },
          pr: { md: 5, xs: 0 },
        }}
      >
        {steps[modelID].length === activeStep ? (
          ""
        ) : (
          <>
            {t(steps[modelID][activeStep].description[subStep])}
            <br />
            {props.activeUser && props.activeUser.email
              ? props.activeUser.email
              : JSON.parse(localStorage.getItem("user"))?.user.email}
          </>
        )}
      </Typography>
      {render(activeStep, subStep)}
    </Box>
  );
}
