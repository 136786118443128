import React, { useState, useContext, useEffect } from 'react';
import { TextField, Typography, Button, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@mui/styles';
import cx from "classnames";
import AppContext from 'context/AppContext';
import axios from 'axios';
import { toastSuccess, toastError } from './Toast';
import { errorMessage } from 'utils/constant';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  }
}));

const ErrorPage = (props) => {
  const { errorType } = props
  const classes = useStyles();
  const link = window.location.href
  const [email, setEmail] = useState("")
  const [isProductError, setIsProductError] = useState(errorType === "Product_not_assigned")
  const [key, setKey] = useState(errorType === "Product_not_assigned" ? errorType : "Page_error")
  const [reason, setReason] = useState(errorType === "Product_not_assigned" ? "File not found" : "Too many users")
  const [message, setMessage] = useState("")
  const { brandColor } = useContext(AppContext);

  const [isSent, setIsSent] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // if (!isProductError) {
    //   const data = {
    //     link: link,
    //     reason: reason
    //   }
    //   console.log("data==> ", data)
    //   return
    //   axios
    //     .post("https://schildr.com/conferror", data)
    //     .then(response => {
    //       // toastSuccess(response.data, brandColor);
    //       console.log("response==> ", response)
    //     })
    //     .catch(err => {
    //       toastError(err.message);
    //       return false
    //     })
    // }

    const data = {
      link: link,
      reason: reason
    }

    axios
      .post("https://schildr.com/conferror", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(response => {
        // toastSuccess(response.data, brandColor);
      })
      .catch(err => {
        // toastError(err.message);
      })
  }, [])

  const handleSendMessage = () => {
    const data = {
      link: link,
      reason: reason,
      email: email,
      message: message
    }
    setLoading(true)
    axios
      .post("https://schildr.com/conferror", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(response => {
        setIsSent(true)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        // toastError(err.message);
        // return false
      })
  }

  const handleTry = () => {
    localStorage.clear()
    window.location.reload()
  }

  return (
    <div className={cx("container-fluid", classes.root)}>
      <Box sx={{
        width: {
          lg: 700,
          md: 500,
          sm: 400
        }
      }}>
        {isSent ?
          <Typography variant="h3" className='text-center'>We received your request. Thank you for your feedback.</Typography>
          :
          <>
            <Typography variant="h1" className='text-center'>Oops!</Typography>
            <Typography variant="h3" className='text-center mb-4'>{errorMessage[key]["title"]}</Typography>
            <Typography variant="h6" className={isProductError ? "" : "my-4"}>
              {errorMessage[key]["desc1"]}
            </Typography>
            <Typography variant='h6'>
              {errorMessage[key]["desc2"]}
            </Typography>
            <div className='mt-4'>
              <TextField
                value={email}
                onChange={event => setEmail(event.target.value)}
                variant='outlined'
                fullWidth
                placeholder='Email'
              />
              <TextField
                value={message}
                onChange={event => setMessage(event.target.value)}
                variant='outlined'
                fullWidth
                minRows={5}
                multiline
                placeholder="Message"
                size='small'
                className='my-3'
              />
              <div className='d-flex'>
                <LoadingButton
                  onClick={handleSendMessage}
                  fullWidth
                  loading={loading} loadingIndicator="Sending..." variant="contained"
                  sx={{
                    fontSize: 20,
                    mr: 3
                  }}
                >
                  Send
                </LoadingButton>
                <Button
                  variant="contained"
                  onClick={handleTry}
                  fullWidth
                  sx={{
                    fontSize: 20
                  }}
                >
                  Reset Cache
                </Button>
              </div>
            </div>
          </>}

      </Box>
    </div>
  );
}

export default ErrorPage;
