import React, { useEffect } from "react";
import { useSnapshot } from "valtio";
import { useProgress } from "@react-three/drei";
import { lengths, columnFlag } from "../../utils/constant";
import state from "../../state";
import { Div } from "./Div";
import { Glass } from "./Glass";
import { CenterProfile } from "./CenterProfile";
import TopLights from "../cabbana/TopLights";
import DividerMiddle1 from "../cabbana/Divider1";

import { secondHeight } from "../../utils/constant";
import {
  WaterProfile,
  BackProfile,
  LeftProfile,
  RightProfile,
  ColumnLeft,
  ColumnBackLeft,
  ColumnRight,
  ColumnBackRight,
  Columns,
  CapLeft,
  CapRight,
  BackLeft,
  BackRight,
  FootLeft,
  FootRight,
  FootBackLeft,
  FootBackRight,
  Foots,
  AddExtraColumns,
  AddWalls,
  LengthInfo,
  ColumnLabels,
  Walls,
  Side,
  SideForSubSystem,
  AddSubSystems,
  VisibleSystem,
} from "../cabbana/CabbanaN";
import GetLogo from "../cabbana/GetLogo";
import MiddleProfile1 from "../cabbana/MiddleProfile1";
import SideInfills6 from "../SideInfills6";
import TopInfills from "../TopInfills";
import TopInfillsInside from "../TopInfillsInside";
import cabbana from "../../assets/models/cabbana_new_1.glb";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from "@react-three/fiber";
import { PropaneRounded } from "@mui/icons-material";
import ProgressBar from "../../common/Loading/ProgressBar";

const xrepeat = 1;
const yrepeat = 1;

export function Lights(props) {
  const { nodes, materials, visible } = props;
  const list = [];
  const px = 242.53;
  const py = 236.52;
  const pz = -10.23;

  for (var i = 1; i < 2; i = i + 1) {
    list.push(
      <group position={[px, py, pz]}>
        <mesh geometry={nodes.mesh_9_105.geometry} material={materials.Light} />
      </group>
    );
  }

  return visible ? <group>{list}</group> : null;
}

function Top(props) {
  var angle =
    (props.modelID === 2 || props.modelID === 8) && props.doubleSystem
      ? -Math.atan(100 / props.length.depth)
      : Math.atan(100 / props.length.depth);
  if (!props.origin) angle = -angle;
  const delta =
    (props.length.depth * Math.sin(-angle) * props.int_length.unit) / 2;
  const bias = (props.length.height1 - 200) * props.int_length.unit;
  const bias2 = -290;

  return (
    <group
      scale={[1, 1, 1]}
      position={[0, bias - delta, bias2]}
      rotation={[angle, 0, 0]}
    >
      <group position={[0, -bias, -bias2]}>
        <Glass {...props} texture={props.bladeTexture} />

        <CenterProfile {...props} />

        <Div {...props} />
      </group>
    </group>
  );
}

export function Panora(props) {
  const gltf = useLoader(GLTFLoader, cabbana, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/draco-gltf/");
    loader.setDRACOLoader(dracoLoader);
  });
  // const onProgress = (xhr) => {
  //   if (xhr.lengthComputable) {
  //     const newProgress = (xhr.loaded / xhr.total) * 100;
  //     props.setProgress(Math.round(newProgress));
  //   }
  // };

  // const gltf = useLoader(GLTFLoader, cabbana, onProgress);
  // const { progress } = useProgress();
  // const [loaded, setLoaded] = React.useState(false);

  // useEffect(()=>{
  //   setLoaded(true);
  // }, [])

  const { nodes, materials } = gltf;
  // const { nodes, materials } = useGLTF(cabbana)
  const snap = useSnapshot(state);
  const [currentSide, setCurrentSide] = React.useState(0);
  const [currentColumn, setCurrentColumn] = React.useState(-1);
  const { modelID, viewMode } = props;

  const rdepth = props.doubleSystem
    ? (snap.length.depth + lengths[modelID].column_thickness) / 2
    : snap.length.depth;

  const length = {
    ...snap.length,
    depth: props.doubleSystem ? rdepth : snap.length.depth,
  };

  const isLarge =
    props.doubleSystem &&
    snap.length.width > lengths[modelID].mid_length_limit[0];

  console.log("snap.capellaDirection", snap.capellaDirection);

  const resetSubsystemWidth = (val) => {
    // need to refresh subsystem when wall added
    setCurrentSide(val);
    for (let u = 0; u < state.subSystemWidth.length; u = u + 1) {
      state.subSystemWidth[u] = 0;
    }
    return true;
  };
  // return !loaded?
  //   <ProgressBar value={progress} text={'Loading Model...'} />:
  return (
    <group {...props} dispose={null} ref={props.group}>
      <group scale={1} position={[0, 0, (-rdepth * lengths[modelID].unit) / 2]}>
        <WaterProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <BackProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <LeftProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <RightProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <MiddleProfile1
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.textureB}
          xrepeat={xrepeat}
          yrepeat={yrepeat}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
          modelID={modelID}
        />
        <ColumnLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <ColumnRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <ColumnBackLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <ColumnBackRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <Columns
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          flag={snap.flag}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />

        <CapLeft
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <CapRight
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <BackLeft
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <BackRight
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <FootLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <FootRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <FootBackLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <FootBackRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <GetLogo
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />

        <Foots
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <ColumnLabels
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
        />
        {/* <LengthInfo
          secondHeight={secondHeight[modelID]}
          length={length}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
        /> */}
        <AddExtraColumns
          columns={snap.columns}
          length={length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setCurrentSide={setCurrentSide}
        />
        <AddWalls
          walls={snap.walls}
          length={snap.length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setCurrentSide={resetSubsystemWidth}
          origin={props.origin}
        />
        {props.origin ? (
          <Walls
            walls={snap.walls}
            length={snap.length}
            int_length={lengths[modelID]}
          />
        ) : null}
        <Side
          columns={snap.columns}
          walls={snap.walls}
          length={snap.length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          current_side={currentSide}
        />
        <TopLights
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          visible={snap.hasLedLight}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
          modelID={modelID}
        />

        {modelID === 2 || modelID === 8 ? (
          <>
            <Glass
              nodes={nodes}
              materials={materials}
              length={length}
              int_length={lengths[modelID]}
              color={snap.isDesign ? snap.designStyle : snap.blades.color}
              isDesign={snap.isDesign}
              isTexture={snap.isTexture}
              texture={snap.blades.texture}
              opacity={snap.blades.opacity}
              modelID={modelID}
              origin={props.origin}
              doubleSystem={props.doubleSystem}
              systemVisible={snap.systemVisible}
              isLarge={isLarge}
            />

            <CenterProfile
              nodes={nodes}
              materials={materials}
              length={length}
              int_length={lengths[modelID]}
              color={snap.isDesign ? snap.designStyle : snap.structure.color}
              isDesign={snap.isDesign}
              isTexture={snap.isTexture}
              texture={snap.structure.texture}
              opacity={snap.blades.opacity}
              xrepeat={xrepeat}
              yrepeat={yrepeat}
              modelID={modelID}
              origin={props.origin}
              doubleSystem={props.doubleSystem}
              systemVisible={snap.systemVisible}
              isLarge={isLarge}
            />

            <Div
              nodes={nodes}
              materials={materials}
              length={length}
              int_length={lengths[modelID]}
              color={snap.structure.color}
              visible={snap.hasLight}
              bladeRotation={snap.blades.rotation}
              isTexture={snap.isTexture}
              texture={snap.structure.texture}
              opacity={snap.blades.opacity}
              xrepeat={xrepeat}
              yrepeat={yrepeat}
              modelID={modelID}
              origin={props.origin}
              doubleSystem={props.doubleSystem}
              systemVisible={snap.systemVisible}
              isLarge={isLarge}
            />
          </>
        ) : (
          <Top
            nodes={nodes}
            materials={materials}
            length={length}
            int_length={lengths[modelID]}
            bladeColor={snap.isDesign ? snap.designStyle : snap.blades.color}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
            isDesign={snap.isDesign}
            isTexture={snap.isTexture}
            bladeTexture={snap.blades.texture}
            texture={snap.structure.textureC}
            opacity={snap.blades.opacity}
            modelID={modelID}
            xrepeat={xrepeat}
            yrepeat={yrepeat}
            origin={props.origin}
            doubleSystem={props.doubleSystem}
            systemVisible={snap.systemVisible}
            isLarge={isLarge}
            visible={snap.hasLight}
          />
        )}

        <DividerMiddle1
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          bladeColor={snap.isDesign ? snap.designStyle : snap.blades.color}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isDesign={snap.isDesign}
          isTexture={snap.isTexture}
          bladeTexture={snap.blades.texture}
          texture={snap.structure.textureA}
          opacity={snap.blades.opacity}
          modelID={modelID}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />

        <SideInfills6
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          systemID={snap.systemID}
          modelID={modelID}
          isTexture={snap.isTexture}
          texture={snap.structure.textureD}
          texture1={snap.structure.textureC}
          doubleSystem={props.doubleSystem}
          origin={props.origin}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
          walls={snap.walls}
        />
        <AddSubSystems
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setCurrentColumn={setCurrentColumn}
          systemID={snap.systemID}
          doubleSystem={props.doubleSystem}
          origin={props.origin}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <SideForSubSystem
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          activeStep={props.activeStep}
          subStep={props.subStep}
          currentColumn={currentColumn}
          doubleSystem={props.doubleSystem}
          origin={props.origin}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <VisibleSystem
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          activeStep={props.activeStep}
          subStep={props.subStep}
          currentColumn={currentColumn}
          doubleSystem={props.doubleSystem}
          origin={props.origin}
          modelID={modelID}
          systemVisible={snap.systemVisible}
          viewMode={viewMode}
        />

        {snap.capellaDirection == 0 ? (
          <TopInfills
            length={length}
            int_length={lengths[modelID]}
            columns={snap.columns}
            systemID={9}
            modelID={modelID}
            addedSubSystem={snap.columns.addedSubSystem}
            deletedSubSystem={snap.columns.deletedSubSystem}
            px={0}
            py={-17000}
            pz={2850}
            alpha={
              props.doubleSystem
                ? Math.atan(100 / length.depth)
                : -Math.atan(100 / length.depth)
            }
            theta={props.doubleSystem && props.origin ? Math.PI : 0}
            dr={1}
            isTexture={snap.isTexture}
            texture={snap.structure.texture}
            doubleSystem={props.doubleSystem}
            isLarge={snap.length.width > lengths[modelID].mid_length_limit[0]}
            origin={props.origin}
            systemVisible={snap.systemVisible}
            deltaz={props.doubleSystem ? 10 : 10}
            deltax={
              !props.doubleSystem
                ? (13 * length.depth) / 3000
                : !props.origin
                ? (13 * length.depth) / 3000
                : (-20 * length.depth) / 3000
            }
          />
        ) : (
          <TopInfillsInside
            length={length}
            int_length={lengths[modelID]}
            columns={snap.columns}
            systemID={9}
            modelID={modelID}
            addedSubSystem={snap.columns.addedSubSystem}
            deletedSubSystem={snap.columns.deletedSubSystem}
            px={0}
            py={-17000}
            pz={2850}
            alpha={
              props.doubleSystem
                ? Math.atan(100 / length.depth)
                : -Math.atan(100 / length.depth)
            }
            theta={props.doubleSystem && props.origin ? Math.PI : 0}
            dr={1}
            isTexture={snap.isTexture}
            texture={snap.structure.texture}
            doubleSystem={props.doubleSystem}
            isLarge={snap.length.width > lengths[modelID].mid_length_limit[0]}
            origin={props.origin}
            systemVisible={snap.systemVisible}
            deltaz={70}
            deltax={
              !props.doubleSystem
                ? (13 * (length.depth - 200)) / 3000 + 20
                : !props.origin
                ? (13 * (length.depth - 200)) / 3000 + 20
                : (-20 * (length.depth - 200)) / 3000 + 20
            }
          />
        )}
      </group>
    </group>
  );
}

// useGLTF.preload('models/cabbana.glb')
