import { Suspense } from 'react';

// import Loader from './Loader';
import Loading from '../common/Loading/Loading'

const Loadable =
    (Component) =>
        (props) =>
        (
            <Suspense fallback={<Loading />}>
                <Component {...props} />
            </Suspense>
        );

export default Loadable;