/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import * as THREE from 'three'
import triangle from '../../assets/models/triangleNEW.glb';
import {TopDepthDivCount} from '../DivCount';

const xrepeat = 0.09,
  yrepeat = 0.03;

export function TriPart(props) {
  const { nodes, materials } = useGLTF(triangle);
  const color = props.color;
  const { width, height1, height2, depth } = props.length;
  const { int_depth, unit, thickness, column_depth } = props.int_length;
  const sd = (depth - column_depth) / (3000 - 150);
  const sd2 = (depth- column_depth + 100) / (3000 - 150);
  const dd = depth - int_depth
  const sh = (height2 - height1 - thickness) / 490;

  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  const material = new THREE.MeshPhongMaterial({
    color: '#a7c7cb',
    opacity: 0.3,
    transparent: true,
  });

  // const div_count_left = GlassCount(depth - column_depth, props.columns.systemId[0], props.modelID);
  // const div_count_right = GlassCount(depth - column_depth, props.columns.systemId[1], props.modelID);

  const div_count = TopDepthDivCount(depth, 6);

  return (
    <group {...props} dispose={null} rotation={[0, 0, Math.PI]}>
    {(props.modelID === 6 && props.addedSubSystem[0] && props.walls.added[1] === false) || (props.modelID === 10 && props.addedSubSystem[24+0] && props.isRoomy)?
    <>
      <group scale={[10, 10 * sd2, 10]} position={[width * unit / 2 - 450, -(depth - 280) * unit / 2 + dd * unit / 2 - 100 * unit, -17200 + (height1-90) * unit]} rotation={[0, 0, 0]}>
        <group scale={[1, 1, sh]} position={[0, 10, -50 * sh]}>
          <mesh geometry={nodes.glass.geometry} material={material} position={[0, -30, 0]} scale={10.05} />
          <mesh geometry={nodes.triangleprofile.geometry} material={props.isTexture?material_texture:materials.profile} position={[0, 0, 0]} material-color={color} scale={[10 * 45 / 39, 10, 10]} />
        </group>
      </group>
      <group scale={[10, 10 * sd, 10]} position={[width * unit / 2 - 450, -(depth - 280) * unit / 2 + dd * unit / 2, -17200 + (height1-90) * unit]} rotation={[0, 0, 0]}>
        <mesh geometry={nodes.triangle_bottom.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, -0.15, 1.4]} scale={[10, 10, 10]} />
      </group>

      <group scale={[10, 10, 10]} position={[width * unit / 2 - 450, -(depth - 280) * unit / 2 + dd * unit / 2, -17200 + (height1 - 90) * unit]}>
        <group scale={[1, 1, sh]} position={[0, 10, -50 * sh]}>
          {div_count > 1 && (props.isRoomy || props.modelID === 6)? <mesh geometry={nodes.extra_profile.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, depth * 0.48, 200]} scale={[10 * 45 / 39, 10, 7]} /> : null}
        </group>
      </group>
      </>:null}
      {(props.modelID === 6 && props.addedSubSystem[1] && props.walls.added[3] === false) || (props.modelID === 10 && props.addedSubSystem[1] && !props.isRoomy)?
    <>
      <group scale={[10, 10 * sd2, 10]} position={[-width * unit / 2 + 450, -(depth - 280) * unit / 2 + dd * unit / 2 - 100 * unit, -17200 + (height1 - 90) * unit]}>
        <group scale={[1, 1, sh]} position={[0, 10, -50 * sh]}>
          <mesh geometry={nodes.glass.geometry} material={material} position={[0, -30, 0]} scale={[-10.05, 10.05, 10.05]} />
          <mesh geometry={nodes.triangleprofile.geometry} material={props.isTexture?material_texture:materials.profile} position={[0, 0, 0]} material-color={color} scale={[10 * 45 / 39, 10, 10]} />
        </group>
      </group>

      <group scale={[10, 10 * sd, 10]} position={[-width * unit / 2 + 450, -(depth - 280) * unit / 2 + dd * unit / 2, -17200 + (height1 - 90) * unit]}>
        <mesh geometry={nodes.triangle_bottom.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, -0.15, 1.4]} scale={10} />
      </group>


      <group scale={[10, 10, 10]} position={[-width * unit / 2 + 450, -(depth - 280) * unit / 2 + dd * unit / 2, -17200 + (height1 - 90) * unit]}>
        <group scale={[1, 1, sh]} position={[0, 10, -50 * sh]}>
          {div_count > 1 && (!props.isRoomy || props.modelID === 6)? <mesh geometry={nodes.extra_profile.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, depth * 0.48, 200]} scale={[[10 * 45 / 39], 10, 7]} /> : null}
        </group>
      </group>
    </>:null}

    </group>
  )
}
