import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_NEW_SERVER_URL;

class LangService {
  async getLangs() {
    const resp =  await axios.get(API_URL + '/api/langs');
    const langs = [];
    if (resp.data) {
      resp.data.forEach((lang) => {
        if (lang.enabled === '1') {
          langs.push({
            code: lang.code,
            language: lang.language
          });
        }
      });
    }
    return langs;
  }
}

export default new LangService();