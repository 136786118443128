/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState, useContext } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import AppContext from "../context/AppContext";
import { subsystems } from "../utils/constant";
import { useSnapshot } from "valtio";
import state from "../state";
import * as THREE from "three";
import { handleHeight } from "../utils/constant";
import classy from "../assets/models/door.glb";
// import classy from '../assets/models/classy.glb';
import { ClassyGlassCount } from "../models/DivCount";
import { GetHandleColor } from "./GetHandleColor";
import ClassyHandle from "./ClassyHandle";
import SimpleDoor from "./Doors/SimpleDoor";
import SimplePlusDoor from "./Doors/SimplePlusDoor";
import TwoSidedDoor from "./Doors/TwoSidedDoor";

const thickness = 45;
const xrepeat = 0.8,
  yrepeat = 0.8;

export default function Door(props) {
  const { nodes, materials } = useGLTF(classy);
  const { width, height1, height2, activeStep, subStep, modelID, group } =
    props;
  const { selDoor } = useContext(AppContext);

  const snap = useSnapshot(state);
  const handle_color = GetHandleColor();

  const selected =
    snap.editingSubSystem && snap.editingSubSystemID === props.ID;

  const material = new THREE.MeshPhongMaterial({
    color: selected ? "#002853" : "#a7c7cb",
    opacity: 0.3,
    transparent: true,
  });

  const texture = useTexture(snap.structure.textureA);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const silver_material = new THREE.MeshStandardMaterial({
    metalness: 1, // between 0 and 1
    roughness: 0.5, // between 0 and 1
  });

  const scale = [0.0005, 0.0005, 0.0005];
  const rotation = [-Math.PI / 2, 0, 0];
  const position = [0.23, 0.023, 0.18];

  return (
    <group rotation={rotation} scale={scale} position={position}>
      {selDoor === 0 ? (
        <SimpleDoor
          nodes={nodes}
          materials={materials}
          material={material}
          isTexture={props.isTexture}
          snap={snap}
          width={width}
          height1={height1}
          materialTexture={material_texture}
          handleColor={handle_color}
          silverMaterial={silver_material}
          activeStep={activeStep}
          subStep={subStep}
        />
      ) : selDoor === 1 ? (
        <SimplePlusDoor
          nodes={nodes}
          materials={materials}
          material={material}
          isTexture={props.isTexture}
          snap={snap}
          width={width}
          height1={height1}
          height2={height2}
          materialTexture={material_texture}
          handleColor={handle_color}
          silverMaterial={silver_material}
          activeStep={activeStep}
          subStep={subStep}
        />
      ) : (
        <group>
          <TwoSidedDoor
            nodes={nodes}
            materials={materials}
            material={material}
            isTexture={props.isTexture}
            snap={snap}
            width={width}
            height1={height1}
            materialTexture={material_texture}
            handleColor={handle_color}
            silverMaterial={silver_material}
            activeStep={activeStep}
            subStep={subStep}
            origin={true}
          />
          <TwoSidedDoor
            nodes={nodes}
            materials={materials}
            material={material}
            isTexture={props.isTexture}
            snap={snap}
            width={width}
            height1={height1}
            materialTexture={material_texture}
            handleColor={handle_color}
            silverMaterial={silver_material}
            isOpposite={true}
            activeStep={activeStep}
            subStep={subStep}
          />
        </group>
      )}
    </group>
  );
}

// useGLTF.preload('models/classy8.glb')
