import {toast} from 'react-toastify'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export function toastSuccess(msg, brandColor) {

    const customIcon = <CheckCircleOutlineIcon />

    toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        style: {
            background: 'white', // Change this to your desired background color
            color: brandColor, // Change this to your desired text color
          },
        progressStyle: {
            background: brandColor, // Change this to your desired progress bar color
        },
        icon: customIcon,
    });
    return true;
}

export function toastWarning(msg, isProfile) {
    toast.warning(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: isProfile?3000:1000,
    });
    return true;
}
export function toastError(msg) {
    toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
    });
    return true;
}
export function toastInfo(msg) {
    toast.info(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
    });
    return true;
}