/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import panora_parts from "../../assets/models/panora_parts.glb";
import { TopDivCount } from "../DivCount";

const xrepeat = 0.1,
  yrepeat = 0.1;

export function Div(props) {
  const { nodes, materials } = useGLTF(panora_parts);
  const { color, visible } = props;
  const { width, depth, height1 } = props.length;
  const {
    int_width,
    int_depth,
    int_height1,
    top_div_length,
    top_glass_width,
    unit,
    mid_length_limit,
    top_div_width,
    column_thickness,
  } = props.int_length;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  const delta = int_depth - top_div_length;
  const sd = (depth - delta) / top_div_length;
  const list = [];
  var div_count = parseInt(
    (width <= mid_length_limit[0] ? width : width / 2) / top_glass_width
  );
  div_count =
    width <= mid_length_limit[0]
      ? TopDivCount(width, props.modelID)
      : TopDivCount(width / 2, props.modelID);
  const div_width =
    (width <= mid_length_limit[0] ? width - top_div_width : width / 2) /
    div_count;

  const px = 32;
  const py = 236.52;
  const pz = (-int_depth * unit) / 2 - 0.5;
  const scale = [1.3, sd, 1];
  const scaleR = [1.3, sd * 0.97, 1];
  const angle = props.doubleSystem ? -Math.atan(100 / depth) : 0;
  const rotation = [-Math.PI / 2, 0, 0];
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const dx =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[0]) ||
      (!props.origin && !props.systemVisible[2]))
      ? div_count
      : 1;
  var cnt = width <= mid_length_limit[0] ? 1 : 2;
  cnt =
    props.isLarge &&
    props.origin &&
    props.doubleSystem &&
    !props.systemVisible[1]
      ? 1
      : cnt;
  cnt =
    props.isLarge &&
    !props.origin &&
    props.doubleSystem &&
    !props.systemVisible[3]
      ? 1
      : cnt;
  var isLong = true;

  const light_geometry = new THREE.BoxGeometry(200, 100, 2000);
  const light_material = new THREE.MeshPhysicalMaterial({ color: "#ffffff" });

  for (var i = dx; i < div_count * cnt; i = i + 1) {
    if (width > mid_length_limit[0] && i === div_count) continue;
    isLong = true;
    if (props.doubleSystem) {
      if (i < div_count) {
        if (props.origin) isLong = !props.systemVisible[2];
        else isLong = !props.systemVisible[0];
      } else {
        if (props.origin) isLong = !props.systemVisible[3];
        else isLong = !props.systemVisible[1];
      }
    }
    if (props.isTexture) {
      list.push(
        <group
          key={i}
          position={[
            (-(width - int_width) * unit) / 2 - 40 + div_width * i * unit,
            0,
            0,
          ]}
        >
          <mesh
            castShadow
            geometry={nodes.dividerprofile.geometry}
            material={material_texture}
            position={[px, py, pz + (!isLong && !props.origin ? 3 : 0)]}
            scale={isLong ? scale : scaleR}
            rotation={rotation}
            material-color={color}
          ></mesh>
          {visible ? (
            <>
              <mesh
                key={i + div_count * 2}
                geometry={light_geometry}
                material={light_material}
                position={[
                  px,
                  py - 7,
                  pz + (!isLong && !props.origin ? 3 : 0) - 50,
                ]}
                rotation={[0, 0, 0]}
                scale={0.01}
              />
              <mesh
                key={i + div_count * 3}
                geometry={light_geometry}
                material={light_material}
                position={[
                  px,
                  py - 7,
                  pz + (!isLong && !props.origin ? 3 : 0) + 50,
                ]}
                rotation={[0, 0, 0]}
                scale={0.01}
              />
            </>
          ) : null}
        </group>
      );
    } else {
      list.push(
        <group
          key={i}
          position={[
            (-(width - int_width) * unit) / 2 - 40 + div_width * i * unit,
            0,
            0,
          ]}
        >
          <mesh
            castShadow
            geometry={nodes.dividerprofile.geometry}
            material={materials.All_profiles}
            position={[px, py, pz + (!isLong && !props.origin ? 3 : 0)]}
            scale={isLong ? scale : scaleR}
            rotation={rotation}
            material-color={color}
          />
          {visible ? (
            <>
              <mesh
                key={i}
                geometry={light_geometry}
                material={light_material}
                position={[
                  px,
                  py - 7,
                  pz + (!isLong && !props.origin ? 3 : 0) - 50,
                ]}
                rotation={[0, 0, 0]}
                scale={0.01}
              />
              <mesh
                key={i + div_count}
                geometry={light_geometry}
                material={light_material}
                position={[
                  px,
                  py - 7,
                  pz + (!isLong && !props.origin ? 3 : 0) + 50,
                ]}
                rotation={[0, 0, 0]}
                scale={0.01}
              />
            </>
          ) : null}
        </group>
      );
    }
  }

  return <group position={[0, dh1 * unit, (dd * unit) / 2]}>{list}</group>;
}

// useGLTF.preload('models/panora_parts.glb')
