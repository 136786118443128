import * as React from 'react';
import { useParams } from "react-router-dom";
import { API_URL } from "utils/constant"
import axios from "axios"

export default function ProductView() {

    const [product, setProduct] = React.useState()
    const { id } = useParams();
    React.useEffect(() => {
        window.location.href = API_URL + 'product/' + id;
    });
}
