import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Link from "@mui/material/Link";
import Tabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
// import ListItem from '@mui/material/ListItem'

import { ReactComponent as Cube } from "../../assets/img/svg/cube.svg";
import { ReactComponent as Styling } from "../../assets/img/svg/drop.svg";
import { ReactComponent as SideInfills } from "../../assets/img/svg/side.svg";
import { ReactComponent as Comfort } from "../../assets/img/svg/sun.svg";
import { ReactComponent as Installation } from "../../assets/img/svg/install.svg";
import { ReactComponent as OverView } from "../../assets/img/svg/eye.svg";

import state from "../../state";
import AppContext from "../../context/AppContext";
import OrderForm from "../OrderForm";
import ColumnCount from "../ColumnCount";
import axios from "axios";
import {
  steps,
  title,
  subsystems,
  API_URL,
  PRICE_API_URL,
  PRICE_API_KEY,
  axiosInstanceAuthorized,
  axiosInstancePublic,
} from "../../utils/constant";

import { toastSuccess, toastError, toastWarning } from "../Toast";
import { useTranslation } from "react-i18next";

const icons = {
  STRUCTURE: <Cube />,
  STYLING: <Styling />,
  "SUB SYSTEM": <SideInfills />,
  OPTIONAL: <Comfort />,
  INSTALLATION: <Installation />,
  OVERVIEW: <OverView />,
};

const HorizontalLinearStepper = () => {
  const {
    activeStep,
    setActiveStep,
    subStep,
    setSubStep,
    modelID,
    systemID,
    install,
    setInstall,
    setOpenSendForm,
    mode,
    brandColor,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const user_install =
    mode === 0
      ? JSON.parse(localStorage.getItem("user")).user.install
      : localStorage.getItem("user_install");
  const [open, setOpen] = useState(false);

  const cnt = ColumnCount();
  const snap = useSnapshot(state);

  const handleChange = (_, newValue) => {
    state.columns.editing = false;
    setSubStep(newValue);
  };

  const updateStep = (index) => {
    if (
      index === steps[modelID].length - 2 &&
      (user_install === 0 || user_install === 2)
    ) {
      updateStep(index + 1);
      return;
    }

    if (index === steps[modelID].length - 1) {
      if (install === "None" && snap.isModel === true) {
        if (user_install === 1 || user_install === "1") {
          toastWarning(t("Please select install option!"));
          return;
        }
        setInstall(0);
        state.install = 0;
      }
    }
    state.columns.editing = false;
    setActiveStep(index);
    setSubStep(0);
  };

  const AddOrder = (clientId) => {
    const url =
      PRICE_API_URL[modelID] +
      "/" +
      snap.length.depth +
      "/" +
      snap.length.width +
      "/" +
      snap.length.height1 +
      "/" +
      snap.length.height2 +
      "/" +
      cnt +
      "/" +
      PRICE_API_KEY[modelID];
      axiosInstancePublic
      .get(url)
      .then((response) => response.data)
      .then((datas) => {
        const data = {
          client_id: clientId,
          model_name: title[modelID],
          unit: snap.length.unitName,
          width: parseInt(snap.length.width * snap.length.unit + 0.5),
          depth: parseInt(snap.length.depth * snap.length.unit + 0.5),
          height1: parseInt(snap.length.height1 * snap.length.unit + 0.5),
          height2: parseInt(snap.length.height2 * snap.length.unit + 0.5),
          cover_color: snap.structure.color,
          glass_texture: snap.blades.textureType,
          light: snap.hasLight,
          number_of_columns: cnt,
          added_columns: JSON.stringify(snap.columns.added),
          added_column_position: JSON.stringify(snap.columns.pos),
          install: Math.round(datas.install),
          inst: snap.install,
          ledprice: Math.round(datas.led),
          weight: Math.round(datas.weight),
          data: snap,
        };
        axiosInstanceAuthorized
          .post(API_URL + "order/add", data)
          .then((response) => {
            toastSuccess("Order added successfully!", brandColor);
          })
          .catch((err) => {
            toastError(err.message);
          });
        navigate("/orders");
      })
      .catch((err) => {
        return "Network Error";
      });
  };

  const orderNow = () => {
    // const user = JSON.parse(localStorage.getItem('user')).user;
    // AddOrder(user.employee_id);
    setOpen(true);
  };

  const init_state = () => {
    state.editingSubSystem = false;
    state.columns.editing = false;
    state.columns.adding = false;
    state.walls.adding = false;
    state.columns.addingSubSystem = false;
    state.editingWall = false;
  };

  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).user
    : JSON.parse(localStorage.getItem("embed_user"));

  return (
    <>
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          bgcolor: (theme) => theme.palette.background.default,
        }}
      >
        <Box sx={{ borderTop: { md: 0, xs: "1px solid #E5E5E5" } }}>
          {steps[modelID][activeStep] ? (
            <Tabs
              value={subStep}
              onChange={handleChange}
              centered
              sx={{
                minHeight: 0,
                borderBottom: "1px solid #E5E5E5",
                "& .MuiTabs-indicator": { bgcolor: "darkgray" },
              }}
            >
              {/* #7596AE */}
              {snap.isModel && steps[modelID][activeStep].detail.length > 1
                ? steps[modelID][activeStep].detail.map((item, i) => (
                    <Tab
                      label={item}
                      sx={{
                        minHeight: 0,
                        fontSize: { md: 15, xs: 12 },
                        "&.Mui-selected": { color: "darkgray" },
                      }}
                      key={i}
                      className="tabs"
                    />
                  ))
                : null}
            </Tabs>
          ) : (
            <Box sx={{ height: 42, borderBottom: "1px solid #E5E5E5" }} />
          )}
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ px: { sm: 5, xs: 0 } }}
        >
          <Box sx={{ display: { sm: "block", xs: "none" } }}>
            <Breadcrumbs aria-label="breadcrumb" component="span">
              {mode === 0 ? (
                <Link underline="hover" color="text.primary" href="/">
                  {t('HOME')}
                </Link>
              ) : null}
              <span>
                {snap.isModel ? title[modelID] : subsystems[systemID].title}
              </span>
            </Breadcrumbs>
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              justifyContent: { sm: "center", xs: "space-between" },
              width: { sm: "auto", xs: "100%" },
            }}
          >
            {steps[modelID].map((item, i) =>
              (i !== 2 && i !== 3) || snap.isModel ? (
                i === steps[modelID].length - 2 &&
                (`${user_install}` === "0" ||
                  `${user_install}` === "2") ? null : (
                  <Button
                    sx={{
                      p: 2,
                      borderBottom: { sm: 4, xs: 0 },
                      borderRadius: 0,
                      borderStyle: "none none solid none",
                      color:
                        activeStep === i
                          ? "darkgray"
                          : (theme) => theme.palette.background.default,
                    }}
                    onClick={() => {
                      updateStep(i);
                      init_state(i);
                    }}
                    key={i}
                  >
                    <Stack
                      alignItems="center"
                      sx={{
                        "& path":
                          activeStep === i
                            ? { stroke: "darkgray" }
                            : { stroke: (theme) => theme.palette.text.primary },
                      }}
                    >
                      {icons[item.label]}
                      <Typography
                        sx={{
                          fontSize: { md: 15, xs: 12 },
                          lineHeight: 1,
                          fontWeight: 500,
                          mt: 1,
                          color:
                            activeStep === i
                              ? "darkgray"
                              : (theme) => theme.palette.text.primary,
                        }}
                      >
                        {t(item.label)}
                      </Typography>
                    </Stack>
                  </Button>
                )
              ) : null
            )}
          </Stack>

          <Box
            sx={{
              display: { sm: "flex", xs: "none" },
              visibility: { md: "hidden" },
              alignItems: "center",
              position: "relative",
            }}
          >
            <Button
              variant="contained"
              disabled={steps[modelID].length !== activeStep + 1}
              // onClick={(e)=>{e.preventDefault(); setActiveStep(activeStep+1)}}
              onClick={(e) => {
                e.preventDefault();
                setOpenSendForm(true);
              }}
              sx={{
                bgcolor: "#7596AE",
                fontSize: 15,
                lineHeight: 1,
                px: 2.5,
                py: 1.25,
                mr: 4,
              }}
            >
              {/* {user !== null?'SEND TO DEALER':'ORDER NOW'} */}
              GET ESTIMATE
            </Button>
            {user !== null ? (
              <Button
                variant="contained"
                disabled={steps[modelID].length !== activeStep + 1}
                onClick={orderNow}
                sx={{
                  bgcolor: "#7596AE",
                  fontSize: 15,
                  lineHeight: 1,
                  px: 2.5,
                  py: 1.25,
                }}
              >
                ADD TO BASKET
              </Button>
            ) : null}
          </Box>
        </Stack>
      </Box>
      <OrderForm open={open} setOpen={setOpen} AddOrder={AddOrder} />
    </>
  );
};

export default HorizontalLinearStepper;
