import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";

const xrepeat = 0.05,
  yrepeat = 0.05;

export default function DividerMiddle1(props) {
  const { color, isTexture, nodes, materials } = props;
  const { width, depth, height1 } = props.length;
  const {
    int_width,
    int_depth,
    top_div_length,
    unit,
    mid_length_limit,
    column_thickness,
    int_div_width,
  } = props.int_length;

  const dw = width - int_width;
  const dd = depth - int_depth;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  if (props.doubleSystem) {
    if (props.origin && (!props.systemVisible[0] || !props.systemVisible[1]))
      return null;
    if (!props.origin && (!props.systemVisible[2] || !props.systemVisible[3]))
      return null;
  }

  const sd =
    (depth -
      (!props.doubleSystem ? -column_thickness / 2 : column_thickness / 2)) /
    top_div_length;
  var bias = 0;
  // if (props.doubleSystem) {
  //   if (props.origin) bias = column_thickness * unit / 4
  //   else bias = -column_thickness * unit / 4
  // }

  let scale = [0.1, 0.1, (0.1 * sd * 0.5) / 0.747];
  let position = [
    (width * unit) / 2 - (dw * unit) / 2,
    (height1 - 150) * unit,
    (-depth * unit) / 2 + dd * unit,
  ];

  if (
    props.modelID == 17 ||
    props.modelID == 19 ||
    props.modelID == 14 ||
    props.modelID == 4 ||
    props.modelID == 13 ||
    props.modelID == 2
  ) {
    // cabbana new model - CabbanaN.js
    const delta = int_depth - top_div_length;
    const old_depth = 3810;
    const new_depth = depth - column_thickness * 1.5;

    const sd1 = new_depth / old_depth;

    const x0 = -1005.305;
    const new_x0 = x0 + (new_depth - old_depth) / 2;
    scale = [0.1 * 0.747, 0.1 * 0.747, 0.1 * sd1];
    position = [
      (width * unit) / 2 - (dw * unit) / 2,
      (height1 - 180) * unit,
      new_x0 * unit + (props.origin === false ? 66 : 15) * unit,
    ];
  }

  if (mid_length_limit[0] < width) {
    return (
      <group dispose={null} position={position}>
        <group rotation={[0, 0, 0]} scale={scale}>
          <mesh
            castShadow
            geometry={nodes.middleseperator1.geometry}
            material={isTexture ? material_texture : materials.profile}
            material-color={color}
            position={[-130, 25, 10 - bias]}
          />
        </group>
      </group>
    );
  } else {
    return null;
  }
}

// useGLTF.preload('models/divider_panora_cabbana.glb')
