import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Extend Quill with a custom code block format
const CodeBlock = Quill.import('formats/code-block');
CodeBlock.blotName = 'code-block';
CodeBlock.tagName = 'pre';
CodeBlock.className = 'custom-code-block ql-syntax';
Quill.register(CodeBlock, true);

const QuillEditor = (props) => {
  const editorRef = useRef(null);
  const [content, setContent] = useState('<p>Example content</p>');

  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current.getEditor();

      // Add a custom button for copying code
      const customToolbar = editor.getModule('toolbar');
      customToolbar.addHandler('copyCode', handleCopyCode);

      // Update the editor
      editor.update();
    }
  }, []);

  const handleCopyCode = (range) => {
    const editor = editorRef.current.getEditor();
    const selectedText = range.toString();
    const container = document.createElement('div');
    container.innerHTML = selectedText;
  
    const codeElements = container.querySelectorAll('code');
    const clonedElements = Array.from(codeElements).map((element) =>
      element.cloneNode(true)
    );
    const clonedContainer = document.createElement('div');
    clonedElements.forEach((element) => clonedContainer.appendChild(element));
  
    document.body.appendChild(clonedContainer);
    const selection = window.getSelection();
    selection.removeAllRanges();
    const newRange = document.createRange();
    newRange.selectNode(clonedContainer);
    selection.addRange(newRange);
    document.execCommand('copy');
    document.body.removeChild(clonedContainer);
  };
  
  const handleEditorChange = (value, delta, source, editor) => {
    setContent(value);
    // Rest of the function code...
  };

  const renderCustomCodeBlock = (props, editor, next) => {
    const { children, ...attributes } = props;
    return (
      <pre {...attributes}>
        <code>{children}</code>
      </pre>
    );
  };

  return (
    <div>
      <ReactQuill
        ref={editorRef}
        value={props.value}
        onChange={handleEditorChange}
        modules={{
          toolbar: {
            container: [
              [{ header: '1' }, { header: '2' }, { font: [] }],
              [{ size: [] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ color: [] }, { background: [] }],
              ['code-block', 'copyCode'], // Add 'copyCode' to the toolbar
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              ['link', 'image', 'video'],
              ['clean'],
              [{ script: 'sub' }, { script: 'super' }],
            ],
            handlers: {
              copyCode: handleCopyCode,
            },
          },
        }}
        formats={['code-block']}
        placeholder="Enter your code..."
        renderCustomBlock={renderCustomCodeBlock} // Use the custom code block renderer
      />
    </div>
  );
};

export default QuillEditor;
