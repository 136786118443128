/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF } from "@react-three/drei";
import { lightColor } from "../../utils/constant";
import cabbana_lights from "../../assets/models/cabbana_lights.glb";

export default function TopLights(props) {
  const { nodes, materials } = useGLTF(cabbana_lights);
  const modelID = props.modelID;
  const { width, depth, height1 } = props.length;
  const {
    int_width,
    int_depth,
    thickness,
    unit,
    length_light,
    column_thickness,
  } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  // const w_delta = int_width - length_light;
  // const d_delta = int_depth - length_light;
  var sw = width / length_light;

  if (
    props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    (!props.systemVisible[0] || !props.systemVisible[1])
  ) {
    sw = ((width + column_thickness) / 2 - column_thickness * 2) / length_light;
  }

  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    (!props.systemVisible[2] || !props.systemVisible[3])
  ) {
    sw = ((width + column_thickness) / 2 - column_thickness * 2) / length_light;
  }
  const sd = depth / length_light;
  var dx1 = 0,
    dx2 = 0;

  if (
    props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    !props.systemVisible[0]
  )
    dx1 = (width - column_thickness) / 2;
  if (
    props.isLarge &&
    props.doubleSystem &&
    props.origin &&
    !props.systemVisible[1]
  )
    dx2 = -(width - column_thickness) / 2;
  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    !props.systemVisible[2]
  )
    dx1 = (width - column_thickness) / 2;
  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    !props.systemVisible[3]
  )
    dx2 = -(width - column_thickness) / 2;

  const x1 =
    modelID === 4 || modelID === 14 || modelID === 13 || modelID === 17 || modelID === 19
      ? thickness * 0.42 * unit + dx1 * unit
      : thickness * unit + dx1 * unit;
  const x2 =
    modelID === 4 || modelID === 14 || modelID === 13 || modelID === 17 || modelID === 19
      ? width * unit - thickness * 1.2 * unit + dx2 * unit
      : width * unit - thickness * unit + dx2 * unit;
  const x3 =
    modelID === 4 || modelID === 14 || modelID === 13 || modelID === 17 || modelID === 19
      ? (width * unit) / 2 + ((dx1 + dx2 - thickness) * unit) / 2
      : (width * unit) / 2 + ((dx1 + dx2) * unit) / 2;

  return props.visible ? (
    <group
      position={[
        (-dw * unit) / 2,
        height1 * unit - (thickness + 20) * unit,
        dd * unit,
      ]}
    >
      {/* <mesh geometry={nodes.long_light.geometry} material={materials.Light} position={[thickness * unit + dx1 * unit, 0, -depth * unit / 2]} material-color={lightColor} rotation={[0, 0, 0]} scale={[0.001, 0.001, 0.001 * sd]}/>
        <mesh geometry={nodes.long_light.geometry} material={materials.Light} position={[width * unit - thickness * unit + dx2 * unit, 0, -depth * unit / 2]} material-color={lightColor} rotation={[0, Math.PI, 0]}  scale={[0.001, 0.001, 0.001 * sd]}/>
        <mesh geometry={nodes.long_light.geometry} material={materials.Light} position={[width * unit / 2 + (dx1 + dx2) * unit / 2, 0, -thickness * unit]} material-color={lightColor} rotation={[0, Math.PI / 2, 0]} scale={[0.001, 0.001, 0.001 * sw]}/>
        <mesh geometry={nodes.long_light.geometry} material={materials.Light} position={[width * unit / 2 + (dx1 + dx2) * unit / 2, 0, -depth * unit + (thickness + 10) * unit]} material-color={lightColor} rotation={[0, -Math.PI / 2, 0]} scale={[0.001, 0.001, 0.001 * sw]}/> */}

      <mesh
        geometry={nodes.long_light.geometry}
        material={materials.Light}
        position={[x1, 0, (-depth * unit) / 2]}
        material-color={lightColor}
        rotation={[0, 0, 0]}
        scale={[1, 1, sd]}
      />
      <mesh
        geometry={nodes.long_light.geometry}
        material={materials.Light}
        position={[x2, 0, (-depth * unit) / 2]}
        material-color={lightColor}
        rotation={[0, Math.PI, 0]}
        scale={[1, 1, sd]}
      />
      {props.doubleSystem && props.origin ? null : (
        <mesh
          geometry={nodes.long_light.geometry}
          material={materials.Light}
          position={[x3, 0, -thickness * unit]}
          material-color={lightColor}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 1, 1 * sw]}
        />
      )}
      {props.doubleSystem && !props.origin ? null : (
        <mesh
          geometry={nodes.long_light.geometry}
          material={materials.Light}
          position={[x3, 0, -depth * unit + (thickness + 10) * unit]}
          material-color={lightColor}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[1, 1, 1 * sw]}
        />
      )}
    </group>
  ) : null;
}

// useGLTF.preload('models/cabbana_lights.glb')
