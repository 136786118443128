export default function Darken(rgba) {
    const percentage = 30;
    const matches = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)$/);
  
    if (!matches) {
      return rgba;
    }
  
    let r = parseInt(matches[1], 10);
    let g = parseInt(matches[2], 10);
    let b = parseInt(matches[3], 10);
    let a = parseFloat(matches[4] || "1");
  
    r = Math.floor(r * (1 - percentage / 100));
    g = Math.floor(g * (1 - percentage / 100));
    b = Math.floor(b * (1 - percentage / 100));
  
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }
  