import { createContext } from "react";

const AppContext = createContext({
  flag: true,
  activeStep: 0,
  subStep: 0,
  sidebar: true,
  modelID: 0,
  systemID: 0,
  bladeOption: false,
  install: "None",
  openSendForm: false,
  openOrderForm: false,
  modelCount: 1,
  mode: 0,
  brandColor: "rgba(115, 150, 174, 1)",
  showprice: 0,
  openOrderId: -1,
  balance: 0,
  totalPrice: 0,
  optionals: [],
  cartItems: [],
  priceData: [],
  profile: false,
  userFrom: "user",
});

export default AppContext;
