import React, { useState, useContext, useEffect } from "react";
import { useSnapshot } from "valtio";

import Box from "@mui/material/Box";
import { Typography, Select, useMediaQuery, MenuItem } from "@mui/material";

import state from "../../state";
import CustomInput from "./CustomInput";
import AppContext from "../../context/AppContext";
import { lengths, subsystem_lengths } from "../../utils/constant";
import { ReactComponent as RepeatIcon } from "../../assets/img/svg/repeat.svg";

const DimensionDoor = () => {
  const snap = useSnapshot(state);
  const { setSelDoor } = useContext(AppContext);
  const isMobile = useMediaQuery("(max-width:769px)");
  const [minWidth, setMinWidth] = useState(800);
  const [maxWidth, setMaxWidth] = useState(1000);
  const minHeight = 2100;
  const maxHeight = 2800;
  const minHeight2 = 300;
  const maxHeight2 = 2300;
  const height = 2100;
  const height2 = 1000;

  useEffect(() => {
    state.designStyle = snap.designStyle;
    state.length.width = snap.length.width;
    state.length.height1 = snap.length.height1;
    state.length.height2 = snap.length.height2;
    if (state.designStyle == 2) {
      setMinWidth(1600);
      setMaxWidth(2000);
    }
  }, []);

  const handleReset = () => {
    state.length.width = snap.length.width;
    state.length.height1 = snap.length.height1;
    state.length.height2 = snap.length.height2;
  };

  const doorTypes = [
    { id: 0, minW: 800, maxW: 1000, defaultW: 900, label: "Simple" },
    { id: 1, minW: 800, maxW: 1000, defaultW: 900, label: "Simple Plus" },
    { id: 2, minW: 1600, maxW: 2000, defaultW: 1800, label: "Two Sided" },
  ];

  const unit = snap.length.unit;

  const length_unit = parseInt(1 / snap.length.unit);

  const handleWidthSliderChange = (event, newVal) => {
    if (!validationVal(minWidth, maxWidth, newVal)) return false;

    const _newVal = newVal;
    state.length.width = _newVal;
  };

  const handleHeightSliderChange = (event, newVal) => {
    if (!validationVal(minHeight, maxHeight, newVal)) return false;
    const _newVal = newVal;
    state.length.height1 = _newVal;
  };

  const handleHeight2SliderChange = (event, newVal) => {
    if (!validationVal(minHeight2, maxHeight2, newVal)) return false;
    const _newVal = newVal;
    state.length.height2 = _newVal;
  };

  const validationVal = (min, max, val) => {
    if (min > val || val > max) return false;
    else return true;
  };

  const handlePlusChange = (type) => {
    switch (type) {
      case "width":
        if (
          validationVal(minWidth, maxWidth, state.length.width + length_unit)
        ) {
          state.length.width += length_unit;
        }
        break;
      case "height":
        if (
          validationVal(
            minHeight,
            maxHeight,
            state.length.height1 + length_unit
          )
        ) {
          state.length.height1 += length_unit;
        }
        break;
      case "right":
        if (
          validationVal(
            minHeight2,
            maxHeight2,
            state.length.height2 + length_unit
          )
        ) {
          state.length.height2 += length_unit;
        }
    }
  };

  const handleMinusChange = (type) => {
    switch (type) {
      case "width":
        if (
          validationVal(minWidth, maxWidth, state.length.width - length_unit)
        ) {
          state.length.width -= length_unit;
        }
        break;
      case "height":
        if (
          validationVal(
            minHeight,
            maxHeight,
            state.length.height1 - length_unit
          )
        ) {
          state.length.height1 -= length_unit;
        }
        break;
      case "right":
        if (
          validationVal(
            minHeight2,
            maxHeight2,
            state.length.height2 - length_unit
          )
        ) {
          state.length.height2 -= length_unit;
        }
    }
  };

  const handleSelectDoorType = (event) => {
    state.designStyle = event.target.value;
    setSelDoor(event.target.value);
    const door = doorTypes.find((item) => item.id == event.target.value);
    if (door) {
      setMinWidth(door.minW);
      setMaxWidth(door.maxW);
      state.length.width = door.defaultW;
      state.length.height1 = height;
      state.length.height2 = height2;
    }
  };

  return (
    <Box>
      <Box
        sx={{
          pr: 5,
          pl: 2,
        }}
      >
        <Typography variant="h6">Select Door Type</Typography>
        <Select
          value={state.designStyle ? state.designStyle : 0}
          onChange={handleSelectDoorType}
          sx={{
            mt: 0,
            mb: 3,
            width: "100%",
            "& fieldset": { display: "none" },
            /* '& .MuiSelect-select': { py: 1.25, '#2f2f2f' : '#F6F6F6' } */
          }}
        >
          {doorTypes.map((door, index) => (
            <MenuItem key={index} value={door.id}>
              {door.label}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box
        className={!isMobile ? "overview clearfix" : "clearfix"}
        sx={{ p: 0, pr: { md: 2, xs: 0 }, mb: { md: 0, xs: 2.5 } }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            className="reset-default"
            onClick={(e) => {
              e.preventDefault();
              handleReset(e);
            }}
          >
            <span>
              <RepeatIcon />
            </span>
            <span>Reset to default</span>
          </Typography>
        </Box>
        <CustomInput
          {...{
            label: "Width",
            type: "width",
            min: minWidth,
            max: maxWidth,
            value: snap.length.width,
            unit,
            unitName: snap.length.unitName,
            handleSliderChange: handleWidthSliderChange,
            handlePlusChange,
            handleMinusChange,
          }}
        />
        <CustomInput
          {...{
            label: "Height",
            type: "height",
            min: minHeight,
            max: maxHeight,
            value: snap.length.height1,
            unit,
            unitName: snap.length.unitName,
            handleSliderChange: handleHeightSliderChange,
            handlePlusChange,
            handleMinusChange,
          }}
        />
        {state.designStyle == 1 ? (
          <CustomInput
            {...{
              label: "Right part",
              type: "right",
              min: minHeight2,
              max: maxHeight2,
              value: snap.length.height2,
              unit,
              unitName: snap.length.unitName,
              handleSliderChange: handleHeight2SliderChange,
              handlePlusChange,
              handleMinusChange,
            }}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default DimensionDoor;
