import * as React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { server_url } from '../../utils/constant';
import { useSnapshot } from 'valtio';
import state from '../../state';
import ValidationForm from './ValidationForm';
import ColumnCount from "../ColumnCount";
import AppContext from "../../context/AppContext";
import { models } from "../../utils/constant";
import CloseIcon from '@mui/icons-material/Close';

export default function FormDialog() {
  const { modelID } = React.useContext(AppContext);

  const [open, setOpen] = React.useState(false);
  const snap = useSnapshot(state);
  const col_num = ColumnCount();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = (formData) => {
    const data = {
      modelName: models[modelID].title,
      unit: snap.length.unitName,
      width: parseInt(snap.length.width * snap.length.unit + 0.5),
      depth: parseInt(snap.length.depth * snap.length.unit + 0.5),
      height1: parseInt(snap.length.height1 * snap.length.unit + 0.5),
      height2: parseInt(snap.length.height2 * snap.length.unit + 0.5),
      coverColor: snap.structure.color,
      isTexture: snap.isTexture,
      glassColor: snap.blades.color,
      glassTexture: snap.blades.textureType,
      Light: snap.hasLight,
      numberOfColumns: col_num,
      addedColumns: JSON.stringify(snap.columns.added),
      addedColumnPosition: JSON.stringify(snap.columns.pos),
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      city: formData.city,
      findInfo: formData.info,
      zipCode: formData.zipcode,
      address: formData.address,
      phoneNumber: formData.phoneNumber,
      message: formData.message,
      install: snap.install,
    }
    fetch(server_url[modelID], {  // Enter your IP address here

      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data) // body data type must match "Content-Type" header

    })
      .then(response => response.json())
      .then(data => {
        state.responseMsg = data;
        state.datasent = 1;
        window.location.href = '/thanks.html';
      })
      .catch(error => {
        state.datasent = -1;
      });

    setOpen(false);
  };


  return (
    <Box sx={{ width: '50%' }}>
      <Button
        sx={{ bgcolor: '#7596AE', borderRadius: 0.5, width: '100%' }}
        variant="contained"
        size="medium"
        onClick={handleClickOpen}
      >
        Get Price
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <div className='d-flex align-items-center justify-content-between'>
          <DialogTitle>Send Configuration Data</DialogTitle>
          <CloseIcon
            sx={{
              mr: 3
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent>
          <ValidationForm
            handleSend={handleSend}
          />
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </Box>
  );
}