import React, {useEffect, useState} from "react";
import { axiosInstanceAuthorized, glassType } from "../../utils/constant";
import ImageList from "@mui/material/ImageList";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import state from "../../state";
import AppContext from "../../context/AppContext";
import GlassSelect from "./GlassSelect";
import OptionSelect from "common/OptionSelect";
import { useSnapshot } from "valtio";
import axios from 'axios';
import {API_URL, SERVER_URL, title} from 'utils/constant';

export default function Colors() {
  const { modelID } = React.useContext(AppContext);
  const snap = useSnapshot(state);

  const [glasstypes, setGlasstypes] = useState([]);

  const selected = (selected_texture_type, selected_url, opacity) => {
    state.isBladeTexture = true;
    glassType[modelID].forEach((item) => {
      if (item.url === selected_url) {
        state.blades.colorName = item.title;
        document.getElementById(item.url).style.opacity = 1.0;
        document.getElementById(item.url).style.border = "5px solid #31d84a";
      } else {
        document.getElementById(item.url).style.border = "0px";
        document.getElementById(item.url).style.opacity = 0.7;
      }
    });
    state.blades.texture = selected_url;
    state.blades.textureType = selected_texture_type;
    state.blades.opacity = opacity;
  };

  useEffect(()=>{
    axiosInstanceAuthorized.get(API_URL+'glasstype/get')
    .then(response=>{
      const all_glass_types = response.data.glasstypes;
      const glasstype = all_glass_types.filter(item=>(item.list.toLowerCase().split(',').includes(title[modelID].toLowerCase())));
      setGlasstypes(glasstype);
    })
  }, [])


  const isMobile = useMediaQuery('(max-width:769px)')

  return glasstypes.length > 0 ?(
    <Box
        className={!isMobile ? 'overview' : ''}
        sx={{ p: 0, pr: { md: 2, xs: 0 }, mb: { md: 0, xs: 2.5 } }}
    >
          {glasstypes.map((item, index) => {
            return (
              <OptionSelect 
              isTexture={true}
              title={item.title}
              value={item.icon}
              image={item.image}
              opacity={item.opacity / 100}
              id={item.title + 'glass'}
              selected = {snap.blades.textureType === item.title && snap.blades.opacity * 100 === item.opacity}
            />
            )
          })}
      </Box>
  ):null;
}
