import React, { useState } from "react";

import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Badge from "@mui/material/Badge";
import Link from "@mui/material/Link";

import { API_URL, SERVER_URL, axiosInstanceAuthorized } from "../utils/constant";
import axios from "axios";
import { toast } from "react-toastify";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import { makeStyles } from "@mui/styles";
import {
  IconButton,
  Popover,
  Typography,
  ListItem,
  Collapse,
  Button,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ValidationForm from "common/LeftSide/ValidationForm";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { toastSuccess, toastError } from "./Toast";
import state from "state";
import md5 from "md5";
import {
  SITE_URL,
  logo,
  title,
  secondHeight,
  steps,
  subsystems,
  hasMotor,
  lengths,
} from "utils/constant";
import Darken from "common/Darken";
import OverForte from "common/OverForte";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  dropdown: {
    position: "absolute",
    top: "100%",
    right: 0,
    width: "200px",
    backgroundColor: "#eee",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
    padding: "0px",
    borderRadius: "5px",
    zIndex: 1,
  },
  listItem: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    // justifyContent: 'space-between',
    marginBottom: "5px",
    marginTop: "5px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#e3e3e3",
      // transform: 'scale(1.1)',
    },
  },
  image: {
    width: "50px",
    height: "50px",
    marginRight: "10px",
  },
  title: {
    width: "250px",
    marginRight: "10px",
  },
  closeicon: {
    position: "absolute",
    right: "10px",
  },
  btn: {
    "&:hover": {
      backgroundColor: "#FFA500 !important",
      color: "white",
    },
  },
}));

var hash_list = {};
const dr = [", Slide left", ", Slide right", ", Slide center"];
const compact_dr = ["3 Sliders", "2 Sliders"];
const openings = ["Left", "Right", "Center"];

const getHash = (a, b, c, d, e, f, g, h) => {
  return md5(
    a +
      "" +
      parseInt(b) +
      "" +
      parseInt(c) +
      "" +
      d +
      "" +
      e.toString() +
      "" +
      f.toString() +
      g.toString() +
      h.toString()
  );
};

const Add = (a, b, c, d, e, f, g, h) => {
  const key = getHash(a, b, c, d, e, f, a === 2 ? g : false, h);
  if (key in hash_list) {
    const val = hash_list[key];
    hash_list[key] = {
      systemID: a,
      width: b,
      height: c,
      direction: d,
      extra_forte: e,
      slider_count: f,
      has_key: a === 2 ? g : false,
      count: val.count + 1,
      color: h,
    };
  } else {
    hash_list[key] = {
      systemID: a,
      width: b,
      height: c,
      direction: d,
      extra_forte: e,
      slider_count: f,
      has_key: a === 2 ? g : false,
      count: 1,
      color: h,
    };
  }
};

const Basket = (props) => {
  const [cartItems, setCartItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [optionals, setOptionals] = useState([]);
  const {
    flag,
    setFlag,
    openOrderForm,
    setOpenOrderForm,
    brandColor,
    mode,
    openOrderId,
  } = useContext(AppContext);
  const user = !mode
    ? JSON.parse(localStorage.getItem("user")).user
    : JSON.parse(localStorage.getItem("embed_user"));
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  // const snap = useSnapshot(state);
  const api_url = user.extra_link_user;
  const thanks_url = user.thanks;
  const embed_key = user.embed_key;

  const navigate = useNavigate();

  const handleBasketOpen = (event) => {
    setOpen(!open);
  };

  const handleBasketClose = () => {
    setOpen(false);
  };

  const calcSection = (modelID, width, depth, systemVisible) => {
    if (
      depth <=
      (modelID === 1 || modelID === 4
        ? 3500
        : modelID === 2 || modelID === 8
        ? 4000
        : 4500)
    ) {
      if (width <= lengths[modelID].mid_length_limit[0]) return 1;
      return 2;
    } else {
      if (width <= lengths[modelID].mid_length_limit[0]) return 2;
      return systemVisible.filter((item) => item === true).length;
    }
  };

  const getProductId = (product_name) => {
    const product = products.filter(
      (product) => product.title.toLowerCase() === product_name.toLowerCase()
    );
    return product.length > 0 ? product[0].id : -1;
  };

  const getProducts = () => {
    axiosInstanceAuthorized
      .get(API_URL + "product/get")
      .then((response) => {
        setProducts(response.data.products);
        return response.data.products;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOrders = (user) => {
    axiosInstanceAuthorized
      .post(API_URL + "order/getBasket", user)
      .then((response) => {
        setCartItems(response.data.orders);
        return response.data.orders;
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.warning("This client exists already!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const getBasket = () => {
    var cartItem = [];
    if (localStorage.getItem("carts"))
      cartItem = JSON.parse(localStorage.getItem("carts"));
    setCartItems(cartItem);
  };

  const getProductImageByName = (product_name) => {
    if (product_name === undefined) return "";
    if (products.length === 0 || products === undefined || products === null)
      return "";
    const finded_product = products.filter(
      (product) => product.title.toLowerCase() === product_name.toLowerCase()
    );
    if (finded_product.length === 0) return "";
    return finded_product[0].image;
  };

  const removeBasket = (id) => {
    axiosInstanceAuthorized
      .post(API_URL + "order/delete", { id: id })
      .then(() => {
        const updatedCartItems = cartItems.filter((item) => item.id !== id);
        setCartItems(updatedCartItems);
        toastSuccess("Removed from the Cart", brandColor);
        setFlag((prevflag) => !prevflag);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeBasketEmbed = (index) => {
    const updatedCartItems = cartItems;
    updatedCartItems.splice(index, 1);
    setCartItems(updatedCartItems);
    localStorage.setItem("carts", JSON.stringify(updatedCartItems));
    // if (cartItems.length <= 1) localStorage.removeItem('carts');
    toastSuccess("Removed from the Cart", brandColor);
    setFlag((prevflag) => !prevflag);
  };

  const handleClose = () => {
    setOpenOrderForm(false);
  };

  const handleSend = (formData) => {
    const _user = {
      email: user.email,
      UserName: user.user_id,
      firstName: user.first_name,
      lastName: user.last_name,
      embed: user.embed_key,
      benefit: user.benefit,
      discount: user.discount,
      shipping_price: user.shipping_price,
      campaign: user.campaign_per_product,
      extra_formula: user.extra_benefit,
    };

    const _client = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phoneNumber,
      zipCode: formData.zipcode,
      city: formData.city,
      address: formData.address,
    };

    const _message = formData.message;
    const _findInfo = formData.info;

    axiosInstanceAuthorized
      .get(API_URL + "order/getOrderNumber")
      .then((oresponse) => {
        const order_number = oresponse.data.order_number;
        cartItems.map((cartItem, index) => {
          if (openOrderId < 0 || openOrderId === index) {
            const snap =
              typeof cartItem.data === "string"
                ? JSON.parse(cartItem.data)
                : cartItem.data;
            const modelID = snap.modelID;
            const systemID = snap.systemID;
            hash_list = {};
            if (
              snap.columns.addedSubSystem[1] &&
              secondHeight[modelID] &&
              snap.walls.added[modelID === 6 ? 3 : 1] === false
            ) {
              Add(
                snap.columns.systemId[1],
                snap.subSystemWidth[1],
                snap.subSystemHeight[1],
                snap.columns.systemDirection[1],
                snap.columns.addedForte[1],
                snap.systemSliderCount[1],
                snap.columns.systemLock[1],
                snap.systemColorName[1],
                snap.systemSide[1]
              );
            }

            // For only Roomy
            // if (
            //   modelID === 10 &&
            //   snap.columns.addedSubSystem[24 + 1] &&
            //   secondHeight[modelID]
            // ) {
            //   Add(
            //     snap.columns.systemId[24 + 1],
            //     snap.subSystemWidth[24 + 1],
            //     snap.subSystemHeight[24 + 1],
            //     snap.columns.systemDirection[24 + 1],
            //     snap.columns.addedForte[24 + 1],
            //     snap.systemSliderCount[24 + 1],
            //     snap.columns.systemLock[24 + 1],
            //     snap.systemColorName[24 + 1],
            //     snap.systemSide[24 + 1],
            //   );
            // }

            // For only Avantgarde
            if (
              (modelID === 9 || modelID === 11 || modelID === 20) &&
              snap.columns.addedSubSystem[1] &&
              secondHeight[modelID] &&
              snap.length.depth > 6000 &&
              snap.walls.added[1] === false
            ) {
              Add(
                snap.columns.systemId[6 + 1],

                snap.subSystemWidth[6 + 1],
                snap.subSystemHeight[6 + 1],
                snap.columns.systemDirection[6 + 1],
                snap.columns.addedForte[6 + 1],
                snap.systemSliderCount[6 + 1],
                snap.columns.systemLock[6 + 1],
                snap.systemColorName[6 + 1],
                snap.systemSide[6 + 1]
              );
            }

            if (
              modelID !== 10 &&
              snap.columns.addedSubSystem[0] &&
              secondHeight[modelID] &&
              snap.walls.added[modelID === 6 ? 1 : 3] === false
            ) {
              Add(
                snap.columns.systemId[0],
                snap.subSystemWidth[0],
                snap.subSystemHeight[0],
                snap.columns.systemDirection[0],
                snap.columns.addedForte[0],
                snap.systemSliderCount[0],
                snap.columns.systemLock[0],
                snap.systemColorName[0],
                snap.systemSide[0]
              );
            }

            //For only Roomy
            if (
              modelID === 10 &&
              snap.columns.addedSubSystem[24 + 0] &&
              secondHeight[modelID]
            ) {
              Add(
                snap.columns.systemId[24 + 0],
                snap.subSystemWidth[24 + 0],
                snap.subSystemHeight[24 + 0],
                snap.columns.systemDirection[24 + 0],
                snap.columns.addedForte[24 + 0],
                snap.systemSliderCount[24 + 0],
                snap.columns.systemLock[24 + 0],
                snap.systemColorName[24 + 0],
                snap.systemSide[24 + 0]
              );
            }

            // For only Avantgarde
            if (
              (modelID === 9 || modelID === 11 || modelID === 20) &&
              snap.columns.addedSubSystem[0] &&
              secondHeight[modelID] &&
              snap.length.depth > 6000 &&
              snap.walls.added[3] === false
            ) {
              Add(
                snap.columns.systemId[6 + 0],
                snap.subSystemWidth[6 + 0],
                snap.subSystemHeight[6 + 0],
                snap.columns.systemDirection[6 + 0],
                snap.columns.addedForte[6 + 0],
                snap.systemSliderCount[6 + 0],
                snap.columns.systemLock[6 + 0],
                snap.systemColorName[6 + 0],
                snap.systemSide[6 + 0]
              );
            }

            for (var i = secondHeight[modelID] ? 2 : 0; i < 48; i = i + 1) {
              if (secondHeight[modelID]) {
                if (
                  snap.columns.addedSubSystem[i] === true &&
                  i === 20 &&
                  snap.columns.systemId[i] === 9
                ) {
                  const tmp_cnt = snap.capellaCount;
                  const tmp_unit = snap.capellaUnit;
                  for (var j = 0; j < tmp_cnt; j = j + tmp_unit) {
                    for (var k = 0; k < tmp_unit; k = k + 1)
                      Add(
                        snap.columns.systemId[i],
                        snap.capellaSize[k],
                        snap.subSystemHeight[i],
                        snap.columns.systemDirection[i],
                        snap.columns.addedForte[i],
                        snap.systemSliderCount[i],
                        snap.columns.systemLock[i],
                        snap.systemColorName[i],
                        snap.systemSide[i]
                      );
                  }
                } else {
                  if (
                    snap.columns.addedSubSystem[i] === true &&
                    ((snap.columns.added[i - 2] === true && i !== 3) ||
                      i === 20) &&
                    snap.subSystemWidth[i] > 0
                  ) {
                    Add(
                      snap.columns.systemId[i],
                      snap.subSystemWidth[i],
                      snap.subSystemHeight[i],
                      snap.columns.systemDirection[i],
                      snap.columns.addedForte[i],
                      snap.systemSliderCount[i],
                      snap.columns.systemLock[i],
                      snap.systemColorName[i],
                      snap.systemSide[i]
                    );
                  }

                  //For only Roomy
                }
                if (
                  modelID === 10 &&
                  snap.columns.addedSubSystem[24 + i] === true &&
                  snap.columns.added[i - 2] === true &&
                  i !== 3
                ) {
                  Add(
                    snap.columns.systemId[24 + i],
                    snap.subSystemWidth[24 + i],
                    snap.subSystemHeight[24 + i],
                    snap.columns.systemDirection[24 + i],
                    snap.columns.addedForte[24 + i],
                    snap.systemSliderCount[24 + i],
                    snap.columns.systemLock[24 + i],
                    snap.systemColorName[24 + i],
                    snap.systemSide[24 + i]
                  );
                }
              } else {
                if (
                  snap.walls.added[snap.columns.side[i] - 1] === true &&
                  snap.columns.systemId[i] < 9
                )
                  continue;
                if (
                  snap.columns.addedSubSystem[i] === true &&
                  snap.subSystemWidth[i] > 0 &&
                  snap.columns.systemId[i] === 9
                ) {
                  const tmp_cnt = snap.capellaCount;
                  const tmp_unit = snap.capellaUnit;
                  for (var j = 0; j < tmp_cnt; j = j + tmp_unit) {
                    for (var k = 0; k < tmp_unit; k = k + 1)
                      Add(
                        snap.columns.systemId[i],
                        snap.capellaSize[k],
                        snap.subSystemHeight[i],
                        snap.columns.systemDirection[i],
                        snap.columns.addedForte[i],
                        snap.systemSliderCount[i],
                        snap.columns.systemLock[i],
                        snap.systemColorName[i],
                        snap.systemSide[i]
                      );
                  }
                } else {
                  if (snap.length.depth > 4000 && (i === 2 || i === 4)) {
                  } else {
                    if (
                      snap.columns.addedSubSystem[i] === true &&
                      snap.subSystemWidth[i] > 0
                    ) {
                      if (
                        !snap.columns.systemId[i] &&
                        snap.subSystemWidth[i] > 4000
                      ) {
                        Add(
                          snap.columns.systemId[i],
                          snap.subSystemWidth[i] / 2,
                          snap.subSystemHeight[i],
                          snap.columns.systemDirection[i],
                          snap.columns.addedForte[i],
                          snap.systemSliderCount[i],
                          snap.columns.systemLock[i],
                          snap.systemColorName[i],
                          snap.systemSide[i]
                        );
                        Add(
                          snap.columns.systemId[i],
                          snap.subSystemWidth[i] / 2,
                          snap.subSystemHeight[i],
                          snap.columns.systemDirection[i + 8],
                          snap.columns.addedForte[i],
                          snap.systemSliderCount[i],
                          snap.columns.systemLock[i],
                          snap.systemColorName[i],
                          snap.systemSide[i]
                        );
                      } else {
                        Add(
                          snap.columns.systemId[i],
                          snap.subSystemWidth[i],
                          snap.subSystemHeight[i],
                          snap.columns.systemDirection[i],
                          snap.columns.addedForte[i],
                          snap.systemSliderCount[i],
                          snap.columns.systemLock[i],
                          snap.systemColorName[i],
                          snap.systemSide[i]
                        );
                      }
                    }
                  }
                }
                if (
                  (modelID === 1 ||
                    modelID === 4 ||
                    modelID === 2 ||
                    modelID === 8 ||
                    modelID === 13 ||
                    modelID === 15 ||
                    modelID === 14) &&
                  snap.columns.addedSubSystem[24 + i] === true &&
                  snap.subSystemWidth[24 + i] > 0
                ) {
                  if (
                    !snap.columns.systemId[24 + i] &&
                    snap.subSystemWidth[24 + i] > 4000
                  ) {
                    Add(
                      snap.columns.systemId[24 + i],
                      snap.subSystemWidth[24 + i] / 2,
                      snap.subSystemHeight[24 + i],
                      snap.columns.systemDirection[24 + i],
                      snap.columns.addedForte[24 + i],
                      snap.systemSliderCount[24 + i],
                      snap.columns.systemLock[24 + i],
                      snap.systemColorName[24 + i],
                      snap.systemSide[24 + i]
                    );
                    Add(
                      snap.columns.systemId[24 + i],
                      snap.subSystemWidth[24 + i] / 2,
                      snap.subSystemHeight[24 + i],
                      snap.columns.systemDirection[24 + i + 8],
                      snap.columns.addedForte[24 + i],
                      snap.systemSliderCount[24 + i],
                      snap.columns.systemLock[24 + i],
                      snap.systemColorName[24 + i],
                      snap.systemSide[24 + i]
                    );
                  } else {
                    Add(
                      snap.columns.systemId[24 + i],
                      snap.subSystemWidth[24 + i],
                      snap.subSystemHeight[24 + i],
                      snap.columns.systemDirection[24 + i],
                      snap.columns.addedForte[24 + i],
                      snap.systemSliderCount[24 + i],
                      snap.columns.systemLock[24 + i],
                      snap.systemColorName[24 + i],
                      snap.systemSide[24 + i]
                    );
                  }
                }
              }
            }

            const all_subsystems = [];
            const render_cnt = 1;
            const sections = calcSection(
              modelID,
              snap.length.width,
              snap.length.depth,
              snap.systemVisible
            );

            if (snap.isModel) {
              if (!secondHeight[modelID]) {
                all_subsystems.push({
                  product_id: getProductId(title[modelID]),
                  model: title[modelID],
                  width: snap.length.width,
                  depth: snap.length.depth,
                  front_height: snap.length.height1,
                  back_height: secondHeight[modelID]
                    ? snap.length.height2
                    : snap.length.height1,
                  structure_color: snap.structure.colorName,
                  section: sections,
                });
              } else {
                if (modelID === 6 || modelID === 10) {
                  all_subsystems.push({
                    product_id: getProductId(title[modelID]),
                    model: title[modelID],
                    width: snap.length.width,
                    depth: snap.length.depth,
                    front_height: snap.length.height1,
                    back_height: secondHeight[modelID]
                      ? snap.length.height2
                      : snap.length.height1,
                    structure_color: snap.structure.colorName,
                    glassType: snap.blades.colorName,
                  });
                } else if (
                  modelID === 1 ||
                  modelID === 4 ||
                  modelID === 13 ||
                  modelID === 15 ||
                  modelID === 14
                ) {
                  all_subsystems.push({
                    product_id: getProductId(title[modelID]),
                    model: title[modelID],
                    width: snap.length.width,
                    depth: snap.length.depth,
                    front_height: snap.length.height1,
                    back_height: secondHeight[modelID]
                      ? snap.length.height2
                      : snap.length.height1,
                    structure_color: snap.structure.colorName,
                    blade_color: snap.blades.colorName,
                  });
                } else if (modelID === 9 || modelID === 11 || modelID === 20) {
                  all_subsystems.push({
                    product_id: getProductId(title[modelID]),
                    model: title[modelID],
                    width: snap.length.width,
                    depth: snap.length.depth,
                    front_height: snap.length.height1,
                    back_height: secondHeight[modelID]
                      ? snap.length.height2
                      : snap.length.height1,
                    structure_color: snap.structure.colorName,
                    curtain_color: snap.systemColorName[23],
                  });
                } else {
                  all_subsystems.push({
                    product_id: getProductId(title[modelID]),
                    model: title[modelID],
                    width: snap.length.width,
                    depth: snap.length.depth,
                    front_height: snap.length.height1,
                    back_height: secondHeight[modelID]
                      ? snap.length.height2
                      : snap.length.height1,
                    structure_color: snap.structure.colorName,
                  });
                }
              }
              for (const [key, sub] of Object.entries(hash_list)) {
                if (sub.systemID === 9) {
                  all_subsystems.push({
                    product_id: getProductId(subsystems[sub.systemID].title),
                    model: subsystems[sub.systemID].title,
                    width: sub.width,
                    height: sub.height,
                    count: sub.count / render_cnt,
                    color: sub.color,
                  });
                } else if (sub.systemID === 2) {
                  all_subsystems.push({
                    product_id: getProductId(subsystems[sub.systemID].title),
                    model: subsystems[sub.systemID].title,
                    width: sub.width,
                    height: sub.height,
                    count:
                      (!sub.systemID && sub.width > 4000
                        ? sub.count * 2
                        : sub.count) / render_cnt,
                    slide: !sub.systemID
                      ? !sub.direction
                        ? 3
                        : 2
                      : sub.slider_count,
                    opening: openings[sub.direction],
                    overforte: sub.extra_forte,
                    key: sub.has_key,
                  });
                  if (sub.extra_forte) {
                    all_subsystems.push({
                      product_id: getProductId("Forte"),
                      model: "FORTE",
                      width: OverForte(sub.width, sub.height)
                        ? sub.width / 2
                        : sub.width,
                      height: sub.height,
                      count:
                        (OverForte(sub.width, sub.height)
                          ? sub.count * 2
                          : sub.count) / render_cnt,
                      color: sub.color,
                    });
                  }
                } else if (sub.systemID === 6) {
                  all_subsystems.push({
                    product_id: getProductId("Forte"),
                    model: "FORTE",
                    width: OverForte(sub.width, sub.height)
                      ? sub.width / 2
                      : sub.width,
                    height: sub.height,
                    count:
                      (OverForte(sub.width, sub.height)
                        ? sub.count * 2
                        : sub.count) / render_cnt,
                    color: sub.color,
                  });
                } else if (sub.systemID === 8) {
                  all_subsystems.push({
                    product_id: getProductId(subsystems[sub.systemID].title),
                    model: subsystems[sub.systemID].title,
                    width: sub.width,
                    height: sub.height,
                    count: sub.count / render_cnt,
                    inout: sub.inout == 0 ? "Out" : "In",
                    opening: openings[sub.direction],
                  });
                } else {
                  all_subsystems.push({
                    product_id: getProductId(subsystems[sub.systemID].title),
                    model: subsystems[sub.systemID].title,
                    width: sub.width,
                    height: sub.height,
                    count:
                      (!sub.systemID && sub.width > 4000
                        ? sub.count * 2
                        : sub.count) / render_cnt,
                    slide: !sub.systemID
                      ? !sub.direction
                        ? 3
                        : 2
                      : sub.slider_count,
                    opening: openings[sub.direction],
                    overforte: sub.extra_forte,
                  });
                  if (sub.extra_forte) {
                    all_subsystems.push({
                      product_id: getProductId("Forte"),
                      model: "FORTE",
                      width: OverForte(sub.width, sub.height)
                        ? sub.width / 2
                        : sub.width,
                      height: sub.height,
                      count:
                        (OverForte(sub.width, sub.height)
                          ? sub.count * 2
                          : sub.count) / render_cnt,
                      color: sub.color,
                    });
                  }
                }
              }
              if (
                modelID > 0 &&
                secondHeight[modelID] &&
                ((snap.columns.addedSubSystem[0] &&
                  snap.walls.added[modelID === 6 ? 1 : 3] === false) ||
                  (snap.columns.addedSubSystem[1] &&
                    snap.walls.added[modelID === 6 ? 3 : 1] === false))
              )
                all_subsystems.push({
                  model: "TRIANGLE",
                  width: snap.length.depth,
                  height: snap.length.height2 - snap.length.height1,
                  count:
                    snap.columns.addedSubSystem[0] &&
                    snap.walls.added[modelID === 6 ? 1 : 3] === false &&
                    snap.columns.addedSubSystem[1] &&
                    snap.walls.added[modelID === 6 ? 3 : 1] === false
                      ? 2
                      : 1,
                });
            } else {
              all_subsystems.push({
                product_id: getProductId(subsystems[systemID].title),
                model: subsystems[systemID].title,
                width: snap.length.width,
                height: snap.length.depth,
                structure_color: snap.structure.colorName,
                slide: !systemID
                  ? !snap.columns.systemDirection[0]
                    ? 3
                    : 2
                  : snap.systemSliderCount[0],
                opening: openings[snap.columns.systemDirection[0]],
              });
            }

            const _optionals = [];
            optionals.map((optional, index) => {
              if (snap.optionals[index]) {
                if (optional.title.toLowerCase().includes("perimeter")) {
                  _optionals.push({
                    name: optional.title,
                    iscount: snap.optionals[index],
                    count: snap.optionals[index]
                      ? parseInt(snap.optionalCount[index])
                      : 0,
                    length: (snap.length.width + snap.length.depth) * 2,
                  });
                } else {
                  _optionals.push({
                    name: optional.title,
                    iscount: snap.optionals[index],
                    count: snap.optionals[index]
                      ? parseInt(snap.optionalCount[index])
                      : 0,
                  });
                }
              }
            });

            const _optional =
              modelID === 1 ||
              modelID === 4 ||
              modelID === 13 ||
              modelID === 15 ||
              modelID === 14
                ? {
                    install: snap.install,
                    wifi: snap.hasWifi,
                    fanprofile: snap.hasFan,
                    perimeterLight: snap.hasLight,
                    ledLight: snap.hasLedLight,
                    heater: snap.hasHeater,
                  }
                : {
                    install: snap.install === "With" ? 1 : 0,
                    wifi: snap.hasWifi,
                    fanprofile: snap.hasFan,
                    light: snap.hasLight,
                    heater: snap.hasHeater,
                  };

            const _unit = snap.length.unitName;
            const _main_product = snap.isModel
              ? title[modelID]
              : subsystems[systemID].title;

            const data = {
              client_id: -11,
              model_name: snap.isModel
                ? title[modelID]
                : subsystems[systemID].title,
              unit: snap.length.unitName,
              width: parseInt(snap.length.width * snap.length.unit + 0.5),
              depth: parseInt(snap.length.depth * snap.length.unit + 0.5),
              height1: parseInt(snap.length.height1 * snap.length.unit + 0.5),
              height2: parseInt(snap.length.height2 * snap.length.unit + 0.5),
              cover_color: snap.structure.color,
              glass_texture: snap.blades.textureType,
              light: snap.hasLight,
              added_columns: JSON.stringify(snap.columns.added),
              added_column_position: JSON.stringify(snap.columns.pos),
              install: 0,
              inst: 0,
              ledprice: 0,
              weight: 0,
              data: snap,
              user_id: user.id,
              email: formData.email,
            };

            if (mode === 0) {
              axiosInstanceAuthorized
                .post(API_URL + "order/updateOrder", {
                  client_id: -11,
                  id: cartItem.id,
                })
                .then((response) => {
                  const data1 = {
                    //old request
                    order_number: order_number,
                    modelName: snap.isModel
                      ? title[modelID]
                      : subsystems[systemID].title,
                    // unit: snap.length.unitName,
                    width: parseInt(snap.length.width * snap.length.unit + 0.5),
                    depth: parseInt(snap.length.depth * snap.length.unit + 0.5),
                    height1: parseInt(
                      snap.length.height1 * snap.length.unit + 0.5
                    ),
                    height2: parseInt(
                      snap.length.height2 * snap.length.unit + 0.5
                    ),
                    coverColor: snap.structure.color,
                    isTexture: snap.isTexture,
                    glassColor: snap.blades.color,
                    glassTexture: snap.blades.textureType,
                    Light: snap.hasLight,
                    numberOfColumns: 4,
                    addedColumns: JSON.stringify(snap.columns.added),
                    addedColumnPosition: JSON.stringify(snap.columns.pos),
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    city: formData.city,
                    // findInfo: formData.info,
                    zipCode: formData.zipcode,
                    address: formData.address,
                    phoneNumber: formData.phoneNumber,
                    // message: formData.message,
                    install: snap.install == 1 ? "With" : "Without",
                    modelCount: 1,
                    embed_key: embed_key,

                    //new request
                    user: _user,
                    client: _client,
                    optional: _optionals,
                    unit: _unit,
                    message: _message,
                    findInfo: _findInfo,
                    main_product: _main_product,
                    products: all_subsystems,
                    // section: secondHeight[modelID]?0:sections,
                    viewLink: SITE_URL + "/view/" + md5(cartItem.id),
                  };
                  setFlag((prevflag) => !prevflag);
                  fetch(api_url, {
                    // Enter your IP address here
                    method: "POST",
                    mode: "cors",
                    body: JSON.stringify(data1), // body data type must match "Content-Type" header
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      state.responseMsg = data;
                      state.datasent = 1;
                      var a = document.createElement("a");
                      a.href = thanks_url;
                      a.target = "_top";
                      a.click();
                      // window.location.href = thanks_url;
                      toastSuccess(
                        "Your form has been submitted successfully!",
                        brandColor
                      );
                      setOpenOrderForm(false);
                    })
                    .catch((error) => {
                      toastError(
                        "Network Error! Please check internet connection or your setting"
                      );
                      setOpenOrderForm(false);
                      state.datasent = -1;
                    });

                  if (index === cartItems.length - 1 || openOrderId >= 0) {
                    toastSuccess(
                      "Order has submitted successfully!",
                      brandColor
                    );
                    if (openOrderId < 0) {
                      setCartItems([]);
                    } else {
                      setCartItems((previtems) => previtems.splice(index, 1));
                    }
                  }
                })
                .catch((err) => {
                  toastError(err.message);
                });
            } else {
              const data1 = {
                //old request
                order_number: order_number,
                modelName: snap.isModel
                  ? title[modelID]
                  : subsystems[systemID].title,
                // unit: snap.length.unitName,
                width: parseInt(snap.length.width * snap.length.unit + 0.5),
                depth: parseInt(snap.length.depth * snap.length.unit + 0.5),
                height1: parseInt(snap.length.height1 * snap.length.unit + 0.5),
                height2: parseInt(snap.length.height2 * snap.length.unit + 0.5),
                coverColor: snap.structure.color,
                isTexture: snap.isTexture,
                glassColor: snap.blades.color,
                glassTexture: snap.blades.textureType,
                Light: snap.hasLight,
                numberOfColumns: 4,
                addedColumns: JSON.stringify(snap.columns.added),
                addedColumnPosition: JSON.stringify(snap.columns.pos),
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                city: formData.city,
                // findInfo: formData.info,
                zipCode: formData.zipcode,
                address: formData.address,
                phoneNumber: formData.phoneNumber,
                // message: formData.message,
                install: snap.install == 1 ? "With" : "Without",
                modelCount: 1,
                embed_key: embed_key,

                //new request
                user: _user,
                client: _client,
                optional: _optionals,
                unit: _unit,
                message: _message,
                findInfo: _findInfo,
                main_product: _main_product,
                products: all_subsystems,
                // section: secondHeight[modelID]?0:sections,
                viewLink: SITE_URL + "/view/" + cartItem.data_id,
              };
              fetch(api_url, {
                // Enter your IP address here
                method: "POST",
                mode: "cors",
                body: JSON.stringify(data1), // body data type must match "Content-Type" header
              })
                .then((response) => response.json())
                .then((data) => {
                  state.responseMsg = data;
                  state.datasent = 1;
                  var a = document.createElement("a");
                  a.href = thanks_url;
                  a.target = "_top";
                  a.click();
                  // window.location.href = thanks_url;
                  toastSuccess(
                    "Your form has been submitted successfully!",
                    brandColor
                  );
                  setOpenOrderForm(false);
                })
                .catch((error) => {
                  toastError(
                    "Network Error! Please check internet connection or your setting"
                  );
                  setOpenOrderForm(false);
                  state.datasent = -1;
                });

              if (index === cartItems.length - 1 || openOrderId >= 0) {
                toastSuccess("Order has submitted successfully!", brandColor);
                if (openOrderId < 0) {
                  localStorage.removeItem("carts");
                  setFlag((prevflag) => !prevflag);
                  setCartItems([]);
                } else {
                  localStorage.removeItem("carts");
                  var updatedCartItems = cartItems;
                  updatedCartItems.splice(index, 1);

                  localStorage.setItem(
                    "carts",
                    JSON.stringify(updatedCartItems)
                  );
                  setFlag((prevflag) => !prevflag);
                  setCartItems((previtems) => previtems.splice(index, 1));
                }
              }
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOptionals = () => {
    axiosInstanceAuthorized
      .get(API_URL + "optional/get")
      .then((response) => {
        setOptionals(response.data.optionals);
        return response.data.optionals;
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "Network Error") {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  React.useEffect(() => {
    if (mode === 0) getOrders(user);
    else {
      getBasket();
    }
    getProducts();
    getOptionals();
  }, [flag]);

  return (
    <>
      <div className={classes.root}>
        <IconButton aria-label="cart">
          <Badge
            badgeContent={cartItems.length}
            color="secondary"
            sx={{
              "& .MuiBadge-badge": {
                color: "white",
                backgroundColor: brandColor,
              },
            }}
          >
            <ShoppingCartOutlinedIcon
              className={props.color === "black" ? "" : "order-basket"}
              onClick={(e) => {
                e.preventDefault();
                handleBasketOpen();
              }}
            />
          </Badge>
        </IconButton>
        <div className={classes.dropdown}>
          <Collapse in={open}>
            {cartItems.length === 0 ? (
              <Typography variant="body1">Your cart is empty.</Typography>
            ) : (
              <>
                {cartItems.map((item, index) => (
                  <ListItem
                    key={item.id}
                    className={classes.listItem}
                    // onClick={e=>{
                    //   navigate('/baskets');
                    // }}
                  >
                    <img
                      src={SERVER_URL + getProductImageByName(item.model_name)}
                      alt={item.model_name}
                      className={classes.image}
                    />
                    <Typography variant="body1">{item.model_name}</Typography>
                    {/* <Typography variant="body1">{item.quantity} x ${item.price}</Typography> */}
                    <CloseIcon
                      fontSize="small"
                      className={classes.closeicon}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!mode) removeBasket(item.id);
                        else removeBasketEmbed(index);
                      }}
                    />
                  </ListItem>
                ))}
                <ListItem key={-1} className={classes.listItem}>
                  <Button
                    sx={{
                      bgcolor: brandColor,
                      borderRadius: 0.5,
                      width: "50%",
                      mr: 1.25,
                      "&:hover": { bgcolor: Darken(brandColor) },
                      color: "white",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/carts");
                    }}
                  >
                    View
                  </Button>

                  <Button
                    sx={{
                      bgcolor: brandColor,
                      borderRadius: 0.5,
                      width: "50%",
                      // ml: 1.25,
                      "&:hover": { bgcolor: Darken(brandColor) },
                      color: "white",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenOrderForm(true);
                    }}
                  >
                    {/* Order Now */}
                    Get Estimate
                  </Button>
                </ListItem>
              </>
            )}
          </Collapse>
        </div>
      </div>
      <Dialog open={openOrderForm} onClose={handleClose} fullWidth>
        <DialogTitle>{/* Order Now */}Get Estimate</DialogTitle>
        <DialogContent>
          <ValidationForm handleSend={handleSend} brandColor={brandColor} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default Basket;
