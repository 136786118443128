/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { subsystems } from '../utils/constant';
import { useSnapshot } from 'valtio';
import state from '../state';
import * as THREE from 'three'
import { handleHeight } from '../utils/constant';

import minima from '../assets/models/minima.glb'
import { MinimaGlassCount } from '../models/DivCount';
import { GetHandleColor } from './GetHandleColor';
import MinimaHandle from './MinimaHandle';
import { xrepeat, yrepeat } from '../utils/constant';

const deltas = [0, -155, -140, -135, -140];

export default function Minima(props) {
  const { nodes, materials } = useGLTF(minima)
  const { px, py, width, height, systemID } = props;
  const [opacity, setOpacity] = useState(0.3);
  const [color, setColor] = useState('#a7c7cb');
  const snap = useSnapshot(state);
  const handle_color = GetHandleColor();
  // const handle_height = height >= 2000 ? handleHeight + 330 : height / 2 + 330;
  // const handle_height = height / 2 + 90;
  const handle_height = handleHeight + 130;
  const thickness = snap.isModel ? 15 : 25;

  const direction = snap.columns.systemDirection[props.ID];
  const isLeft = direction != 1 ? true : false;
  const dr = isLeft ? 1 : -1;

  const int_width = subsystems[systemID].width;
  const int_height = subsystems[systemID].height + 40;
  const sw = width / int_width;
  const sh = height / int_height;
  const glassWidth = subsystems[systemID].glassWidth;
  // var glassCount = Math.max(2, Math.round((width - 40) / glassWidth, 0));
  var glassCount = Math.max(2, parseInt(width / subsystems[systemID].sliderWidth));
  if (glassCount > 4 && glassCount % 2) glassCount = glassCount + 1;

  glassCount = MinimaGlassCount(width, glassCount, props.modelID);

  if (!snap.isModel && glassCount >= subsystems[systemID].option_limit && glassCount % 2 === 0) state.columns.systemDirection[props.ID] = 2;
  if (glassCount % 2 && snap.columns.systemDirection[props.ID] === 2) state.columns.systemDirection[props.ID] = 0;
  if (glassCount % 2 === 0 && glassCount > subsystems[systemID].option_limit) state.columns.systemDirection[props.ID] = 2;

  if (!snap.isModel) state.editingGlassCount = glassCount;
  state.systemSliderCount[props.ID] = glassCount;


  const div_width = (width - 40) / glassCount;
  // state.columns.subSystemCount[props.ID] = glassCount;

  const selected = (snap.editingSubSystem && snap.editingSubSystemID === props.ID);

  const material = new THREE.MeshPhongMaterial({
    color: selected ? '#002853' : '#a7c7cb',
    opacity: opacity,
    transparent: true,
  });

  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat * 70, yrepeat * 70);
  // texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshLambertMaterial({
    map: texture,
    side: THREE.DoubleSide,
  });

  const list = [];

  if (snap.columns.systemDirection[props.ID] != 2 || glassCount % 2) {
    for (let i = 0; i < glassCount; i = i + 1) {
      const rank = isLeft ? glassCount - i - 1 : i;
      const start_point = rank * 100 / (glassCount - 1);
      const move_point = Math.max(0, Math.min(snap.columns.systemPos[props.ID], 100 - 10 / glassCount) - start_point) / 100 * div_width * (glassCount - 1);
      list.push(
        <group key={i} position={[-div_width * i + move_point * dr, 0, thickness * rank]} scale={[div_width / glassWidth, 1, 1]}>
          <mesh material-color={snap.structure.color} geometry={nodes.leftdoor1profile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-2.85, 1.36, -0.02]} rotation={[-Math.PI / 2, 0, 0]} scale={1} />
          <mesh geometry={nodes.leftdoor1Glass.geometry} material={material} position={[-2.86, 1.4, -0.02]} rotation={[Math.PI, 0, -Math.PI]} scale={[1, 1.03, 1]} />
        </group>
      )
    }
  }
  else {
    // state.columns.systemDirection[props.ID] = 2;
    const cnt = glassCount / 2;
    for (let i = 0; i < cnt; i = i + 1) {
      const rank = isLeft ? cnt - i - 1 : i;
      const start_point = rank * 100 / (cnt - 1);
      const move_point = Math.max(0, Math.min(snap.columns.systemPos[props.ID], 100 - 10 / cnt) - start_point) / 100 * div_width * (cnt - 1);
      list.push(
        <group key={i} position={[-div_width * i + move_point * dr, 0, thickness * rank]} scale={[div_width / glassWidth, 1, 1]}>
          <mesh material-color={snap.structure.color} geometry={nodes.leftdoor1profile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-2.85, 1.36, -0.02]} rotation={[-Math.PI / 2, 0, 0]} scale={1} />
          <mesh geometry={nodes.leftdoor1Glass.geometry} material={material} position={[-2.86, 1.4, -0.02]} rotation={[Math.PI, 0, -Math.PI]} scale={[1, 1.03, 1]} />
        </group>
      )
    }

    for (let i = 0; i < cnt; i = i + 1) {
      const rank = isLeft ? i : cnt - i - 1;
      const start_point = rank * 100 / (cnt - 1);
      const move_point = Math.max(0, Math.min(snap.columns.systemPos[props.ID], 100 - 10 / cnt) - start_point) / 100 * div_width * (cnt - 1);
      list.push(
        <group key={i + cnt} position={[-div_width * (cnt + i) - move_point * dr, 0, thickness * rank]} scale={[div_width / glassWidth, 1, 1]}>
          <mesh material-color={snap.structure.color} geometry={nodes.leftdoor1profile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-2.85, 1.36, -0.02]} rotation={[-Math.PI / 2, 0, 0]} scale={1} />
          <mesh geometry={nodes.leftdoor1Glass.geometry} material={material} position={[-2.86, 1.4, -0.02]} rotation={[Math.PI, 0, -Math.PI]} scale={[1, 1.03, 1]} />
        </group>
      )
    }
  }

  const silver_material = new THREE.MeshStandardMaterial({
    metalness: 1,   // between 0 and 1
    roughness: 0.5, // between 0 and 1
  });

  const handle_list = [];

  if (snap.columns.systemDirection[props.ID] != 2) {
    handle_list.push(
      <React.Fragment key={0}>
        <MinimaHandle color={handle_color} material={silver_material} position={[dr * (width / 2 - 90), handle_height, (glassCount - 1) * thickness]} scale={1000} />
        {/* <mesh material-color={handle_color} material={silver_material} geometry={nodes.leftdoorhandle.geometry} position={[dr * (width / 2 - 60), handle_height, (glassCount - 1) * 10]} rotation={[-Math.PI / 2, 0, 0]} scale={10} /> */}
        {snap.columns.systemLock[props.ID] ?
          <mesh material-color={handle_color} material={silver_material} geometry={nodes.leftdoor1key.geometry} position={[dr * (-width / 2 + 80 + Math.min(snap.columns.systemPos[props.ID], 100 - 10 / glassCount) / 100 * div_width * (glassCount - 1)), handle_height, -20]} rotation={[-Math.PI / 2, 0, 0]} scale={10} /> :
          <MinimaHandle color={handle_color} material={silver_material} position={[dr * (-width / 2 + 80 + Math.min(snap.columns.systemPos[props.ID], 100 - 10 / glassCount) / 100 * div_width * (glassCount - 1)), handle_height, -20]} scale={1000} />}
      </React.Fragment>
    )
  }
  else {
    if (isLeft) {
      handle_list.push(
        <React.Fragment key={0}>
          <MinimaHandle color={handle_color} material={silver_material} position={[width / 2 - 90, handle_height, -30 + (glassCount / 2 - 1) * thickness]} scale={1000} />
          <MinimaHandle color={handle_color} material={silver_material} position={[-width / 2 + 90, handle_height, -30 + (glassCount / 2 - 1) * thickness]} scale={1000} />
          <MinimaHandle color={handle_color} material={silver_material} position={[-70 - Math.min(snap.columns.systemPos[props.ID], 100 - 10 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1), handle_height, -20]} scale={1000} />
          {snap.columns.systemLock[props.ID] ?
            <mesh material-color={handle_color} material={silver_material} geometry={nodes.leftdoor1key.geometry} position={[60 + Math.min(snap.columns.systemPos[props.ID], 100 - 10 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1), handle_height, -20]} rotation={[-Math.PI / 2, 0, 0]} scale={10} /> :
            <MinimaHandle color={handle_color} material={silver_material} position={[60 + Math.min(snap.columns.systemPos[props.ID], 100 - 10 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1), handle_height, -20]} scale={1000} />
          }
        </React.Fragment>
      )
    }
    else {
      handle_list.push(
        <React.Fragment key={0}>
          <MinimaHandle color={handle_color} material={silver_material} position={[60, handle_height, (glassCount / 2 - 1) * thickness]} scale={1000} />
          <mesh material-color={handle_color} material={silver_material} geometry={nodes.leftdoor1key.geometry} position={[width / 2 - 60 - Math.min(snap.columns.systemPos[props.ID], 100 - 10 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1), handle_height, 40]} rotation={[-Math.PI / 2, 0, 0]} scale={10} />
          <MinimaHandle color={handle_color} material={silver_material} position={[-60, handle_height, (glassCount / 2 - 1) * thickness]} scale={1000} />
          <mesh material-color={handle_color} material={silver_material} geometry={nodes.leftdoor1key.geometry} position={[-width / 2 + 70 + Math.min(snap.columns.systemPos[props.ID], 100 - 10 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1), handle_height, 40]} rotation={[-Math.PI / 2, 0, 0]} scale={10} />
        </React.Fragment>
      )
    }
  }
  if (props.systemType === 1) {
    state.subSystemWidth[props.ID] = width;
    state.subSystemHeight[props.ID] = height;
  }

  const st = (glassCount > 5 ? glassCount / 2 : glassCount) / 2;

  return (
    <group
      rotation={props.rotation}
      scale={props.scale * 0.1}
      // position={[px * unit, py * unit, pz * unit]}
      // position={[px + width * unit / 2, pz, py]}
      position={[px, 0, py]}
      onPointerOver={
        e => {
          setOpacity(0.5);
          setColor('#002853');
          document.body.style.cursor = 'pointer';
        }
      }
      onPointerOut={
        e => {
          setOpacity(0.3);
          setColor('#a7c7cb');
          document.body.style.cursor = 'auto';
        }
      }
      onClick={
        e => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID;
          state.editingSystemType = props.systemType;
          state.editingGlassCount = glassCount;
        }
      }

    >
      <group rotation={[Math.PI / 2, Math.PI / 2, 0]}
        position={[snap.isModel ? deltas[props.side] + 10 : 0, width / 2, 50]}
      >
        <group scale={[1, 1, snap.isModel ? 1 : st]} position={[0, snap.isModel ? 10 : 0, snap.isModel ? 0 : 5]}>
          <mesh
            geometry={nodes.top_profile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[0, height - 30, 0]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, sw]}
            material-color={snap.structure.color}
          />
          <mesh geometry={nodes.under_profile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[0, 0, 0]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, sw]}
            material-color={snap.structure.color}
          />
          <mesh geometry={nodes.sideprofile1.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-width / 2, height / 2, 0]} rotation={[-Math.PI / 2, 0, Math.PI]} scale={[1, 1, sh]}
            material-color={snap.structure.color}
          />
          <mesh geometry={nodes.sideprofile.geometry} material={props.isTexture ? material_texture : materials.profile} position={[width / 2, height / 2, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, sh]}
            material-color={snap.structure.color}
          />
        </group>
        <group position={[0, 0, -20]}>
          {handle_list}
        </group>
        <group position={[width / 2 - div_width / 2 - 20, height / 2, -25]} scale={[1, sh * 1.03, 1]}>
          {list}
        </group>
      </group>


    </group>
  )
}

// useGLTF.preload('models/minima6.glb')
