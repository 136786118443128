import { useRoutes, Navigate } from "react-router-dom";

import MainRoutes from "./MainRoutes";
import ModelRoutes from "./ModelRoutes";
import ViewRoutes from "./ViewRoutes";
import TestRoutes from "./TestRoutes";
import ProductRoutes from "./ProductRoutes";
import PageRoutes from "./PageRoutes";
import TempRoutes from "./TempRoutes";
import { useEffect } from "react";

const ThemeRoutes = () => {
  return useRoutes([
    { path: "/", element: <Navigate to="/home" /> },
    { path: "*", element: <Navigate to="/home" /> },
    MainRoutes,
    ModelRoutes,
    ViewRoutes,
    TestRoutes,
    ProductRoutes,
    PageRoutes,
    TempRoutes,
  ]);
};

export default ThemeRoutes;
