import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL, EMBED_USER_KEY, ROLE_TYPES, axiosInstancePublic, matchUrlTitle } from "utils/constant";
import AppContext from "context/AppContext";
import authHeader, { setAuthHeader } from "services/auth-header";
import { loadFavicon } from "utils/helpers";

const AuthGuardEmbed = ({ children }) => {
  const navigate = useNavigate();
  const { setUserFrom } = useContext(AppContext);
  const user = JSON.parse(localStorage.getItem(EMBED_USER_KEY));
  const [is_include, setIsInclude] = useState(false);
  const { key, id } = useParams();

  const embed_key = key === undefined || key === null ? id : key;

  const href = window.location.href;

  // auth load for embed login
  setAuthHeader(authHeader(EMBED_USER_KEY)['Authorization']);

  useEffect(() => {
    axiosInstancePublic.post(API_URL + "auth/findUser", { embed_key }).then((response) => {
      const finded_user = [response.data.user];
      console.log(finded_user)
      if (
        finded_user.length > 0 &&
        (finded_user[0].embedlogin === 1 || finded_user[0].embedlogin === "1")
      ) {
        if (!user || user.user.embed_key !== embed_key) {
          navigate("/embed/login/" + embed_key, { replace: true });
        }
      }

      if (key && finded_user.length > 0 && href.includes(`embed/`)) {
        if (finded_user[0].role === ROLE_TYPES.ADMIN) return;
        const url_params = href.split("/");
        var index = url_params.indexOf("embed");
        var str = url_params[index + 1];
        var products = finded_user[0].products.split(",");

        var isInclude = Boolean(
          products.find((item) => item == matchUrlTitle[str])
        );
        if (!isInclude) setIsInclude(true);
      }
      setUserFrom("embed");
    });
    loadFavicon(true);
  }, [user, navigate, setUserFrom]);

  useEffect(() => {
    if (is_include) throw new Error(`Product_not_assigned`);
  }, [is_include]);

  return children;
};

export default AuthGuardEmbed;
