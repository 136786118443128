/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import panora_parts from "../../assets/models/panora_parts.glb";
import { TopDepthDivCount, TopDivCount } from "../DivCount";

export function Glass(props) {
  const { nodes } = useGLTF(panora_parts);
  const { opacity } = props;
  const { width, depth, height1 } = props.length;
  const {
    int_width,
    int_depth,
    top_glass_width,
    mid_length_limit,
    unit,
    column_width,
    column_thickness,
  } = props.int_length;
  // const texture = useTexture(props.texture);
  // texture.wrapS = THREE.RepeatWrapping;
  // texture.wrapT = THREE.RepeatWrapping;
  // texture.repeat.set(1, 1);
  const dd = depth - int_depth;
  const sd = depth / int_depth;
  var div_count = parseInt(
    (width <= mid_length_limit[0] ? width : width / 2) / top_glass_width
  );
  div_count =
    width <= mid_length_limit[0]
      ? TopDivCount(width, props.modelID)
      : TopDivCount(width / 2, props.modelID);

  const div_width =
    (width <= mid_length_limit[0] ? width : width / 2) / div_count;

  const px = 32;
  const py = height1 * unit - 13;
  const pz = props.doubleSystem ? (props.origin ? -70 : -78) : -75;
  console.log("props.systemVisible", props.systemVisible);
  const list = [];
  const sw = (div_width - 10) / top_glass_width;
  const scale = [sw, sd * 1.04, 1];
  const scaleR = [sw, sd * 1, 1];
  const scale2 = [sw, sd * 2.1, 1];
  const scaleR2 = [sw, sd * 1.85, 1];

  const rotation = [-Math.PI / 2, 0, 0];
  const depth_div_count = TopDepthDivCount(depth, props.modelID);

  const dx =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[0]) ||
      (!props.origin && !props.systemVisible[2]))
      ? (width + column_thickness) / 2 - column_thickness
      : 0;
  var cnt = width <= mid_length_limit[0] ? 1 : 2;
  cnt =
    props.isLarge &&
    props.origin &&
    props.doubleSystem &&
    (!props.systemVisible[0] || !props.systemVisible[1])
      ? 1
      : cnt;
  cnt =
    props.isLarge &&
    !props.origin &&
    props.doubleSystem &&
    (!props.systemVisible[2] || !props.systemVisible[3])
      ? 1
      : cnt;

  var isLong = true;
  for (let i = 0; i < div_count * cnt; i = i + 1) {
    isLong = true;
    if (props.doubleSystem) {
      if (i < div_count) {
        if (props.origin) isLong = !props.systemVisible[2];
        else isLong = !props.systemVisible[0];
      } else {
        if (props.origin) isLong = !props.systemVisible[3];
        else isLong = !props.systemVisible[1];
      }
    }
    const isMiddle = cnt > 1 && i === div_count;
    list.push(
      <group
        key={i}
        position={[
          (-(width - int_width) * unit) / 2 +
            div_width * i * unit +
            dx * unit +
            (isMiddle ? 10 : 0),
          0,
          0,
        ]}
        scale={[
          cnt > 1 && (i === div_count - 1 || i === div_count) ? 0.9 : 1,
          1,
          1,
        ]}
      >
        {depth_div_count > 1 ? (
          <>
            {props.origin ? (
              <>
                <mesh
                  geometry={nodes.glass1.geometry}
                  position={[px, py, pz - (!isLong || i >= div_count ? 10 : 0)]}
                  rotation={rotation}
                  scale={isLong ? scale : scaleR}
                >
                  <meshPhongMaterial opacity={opacity} transparent />
                </mesh>
                <mesh
                  geometry={nodes.glass1.geometry}
                  position={[px, py, pz - (depth * unit) / 2]}
                  rotation={rotation}
                  scale={scale}
                >
                  <meshPhongMaterial opacity={opacity} transparent />
                </mesh>
              </>
            ) : (
              <>
                <mesh
                  geometry={nodes.glass1.geometry}
                  position={[px, py, pz]}
                  rotation={rotation}
                  scale={scale}
                >
                  <meshPhongMaterial opacity={opacity} transparent />
                </mesh>
                <mesh
                  geometry={nodes.glass1.geometry}
                  position={[
                    px,
                    py,
                    pz -
                      (depth * unit) / 2 -
                      (!isLong || i >= div_count ? -12 : 0),
                  ]}
                  rotation={rotation}
                  scale={isLong ? scale : scaleR}
                >
                  <meshPhongMaterial opacity={opacity} transparent />
                </mesh>
              </>
            )}
          </>
        ) : (
          <mesh
            geometry={nodes.glass1.geometry}
            position={[
              px,
              py,
              pz - (depth * unit) / 4 + (!isLong && !props.origin ? 3 : 0),
            ]}
            rotation={rotation}
            scale={isLong ? scale2 : scaleR2}
          >
            <meshPhongMaterial opacity={opacity} transparent />
          </mesh>
        )}
      </group>
    );
  }
  return (
    <group
      position={[
        ((div_width - top_glass_width) * unit) / 2 - 6,
        0,
        (dd * unit * 3) / 4,
      ]}
    >
      {list}
    </group>
  );
}

// useGLTF.preload('models/panora_parts.glb')
