import React, { useEffect } from "react";
import { useGLTF, useTexture, useProgress } from "@react-three/drei";
import { useSnapshot } from "valtio";
import { lengths, columnFlag, default_forte_pos } from "../../utils/constant";
import state from "../../state";
import { Html } from "@react-three/drei";
import { BsPlusCircle } from "react-icons/bs";
import * as THREE from "three";
import {
  wallThickness,
  wallTopHeight,
  secondHeight,
} from "../../utils/constant";
import Wall from "../Wall";
import { TriPart } from "./TriPart";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from "@react-three/fiber";

import avantgardeModel from "../../assets/models/avantgarde.glb";
import elegantModel from "../../assets/models/elegant.glb";
import SideInfills4 from "../SideInfills4";
import ProgressBar from "../../common/Loading/ProgressBar";

const xrepeat = 0.3;
const yrepeat = 0.3;

export const CalcRotation = (props) => {
  const { depth, height1, height2 } = props.length;
  const { int_height1, int_height2, thickness } = props.int_length;
  const alpha1 = Math.atan(
    Math.abs(height2 - height1 - thickness) / (depth + 220)
  );
  const alpha2 = Math.atan(
    Math.abs(int_height2 - int_height1 - thickness) / (depth + 220)
  );

  if (height1 > height2 - thickness) return -(alpha1 + alpha2);
  return alpha1 - alpha2;
};

export const CalcScale = (props) => {
  const { depth, height1, height2 } = props.length;
  const { int_depth, int_height1, int_height2, thickness } = props.int_length;
  const h1 = Math.abs(height1 - height2 - thickness);
  const h2 = Math.abs(int_height1 - int_height2 - thickness);
  const delta = 600;
  const l1 = h1 * h1 + (depth - delta) * (depth - delta);
  const l2 = h2 * h2 + (int_depth - delta) * (int_depth - delta);
  return Math.sqrt(l1 / l2);
};

export function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

export function invertColor(hex) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

export function WaterProfile(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit, water_profile_width } =
    props.int_length;
  const dh1 = height1 - int_height1;
  const sw =
    (width + water_profile_width - int_width - 250) / water_profile_width;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  return (
    <group position={[0, 0, dh1 * unit]} scale={[sw, 1, 1]}>
      <mesh
        castShadow
        material-color={color}
        geometry={nodes.underprofile1.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[0, 2964.97, 945.77]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[1, 1, 1.04]}
      />
    </group>
  );
}

export function ColumnLeft(props) {
  const { nodes, materials, color, columns, isGardes } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_height1, unit, column_depth } = props.int_length;
  const dw = width - int_width;
  const sh1 = (height1 + 20) / int_height1;
  // const scale = [1, 1, sh1];
  const scale = [1, 1, sh1];
  const scale2 = [1, 1, (height1 + 150) / int_height1];
  const bias = isGardes ? 24 : 0;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  return (
    <>
      <group position={[(dw * unit) / 2 - columns.pos[1] * unit, 0, 0]}>
        <mesh
          castShadow
          material-color={color}
          geometry={nodes.leftcolumn1.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[3110.33, 2948.7, -1983.42]}
          scale={scale}
        />
      </group>
      {columns.addedSubSystem[bias + 1] && depth > 6000 ? (
        <group
          position={[
            (dw * unit) / 2 - columns.pos[1] * unit,
            (-(depth - 50) / 2) * unit,
            0,
          ]}
        >
          <mesh
            castShadow
            material-color={color}
            geometry={nodes.leftcolumn1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[3110.33, 2948.7, -1983.42]}
            scale={scale2}
          />
        </group>
      ) : null}
    </>
  );
}

export function ColumnRight(props) {
  const { nodes, materials, color, columns, isGardes } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_height1, unit, column_depth, int_depth } =
    props.int_length;
  const dw = width - int_width;
  const sh1 = (height1 + 20) / int_height1;
  const scale = [1, 1, sh1];
  const scale2 = [1, 1, (height1 + 150) / int_height1];
  const bias = isGardes ? 24 : 0;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  return (
    <>
      <group position={[(-dw * unit) / 2 + columns.pos[0] * unit, 0, 0]}>
        <mesh
          castShadow
          material-color={color}
          geometry={nodes.rightcolumn2.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[-3046.16, 2948.7, -1983.42]}
          scale={scale}
        />
      </group>
      {columns.addedSubSystem[bias] && depth > 6000 ? (
        <group
          position={[
            (-dw * unit) / 2 + columns.pos[0] * unit,
            (-(depth - 50) / 2) * unit,
            0,
          ]}
        >
          <mesh
            castShadow
            material-color={color}
            geometry={nodes.rightcolumn2.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[-3046.16, 2948.7, -1983.42]}
            scale={scale2}
          />
        </group>
      ) : null}
    </>
  );
}

export function Columns(props) {
  const { nodes, materials, color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit, column_thickness } = props.int_length;
  const dw = width - int_width;
  const sh1 = (height1 + 20) / int_height1;
  const scale = [1, 1, sh1];
  const px2 = 3110.33;
  const py = 2948.7;
  const pz = -1983.42;
  const list = [];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  for (var i = 2; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      list.push(
        <mesh
          key={i}
          material-color={color}
          castShadow
          geometry={nodes.leftcolumn1.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[
            (dw * unit) / 2 +
              px2 -
              columns.pos[i] * unit +
              (column_thickness * unit) / 2,
            py,
            pz,
          ]}
          scale={scale}
        />
      );
    }
  }
  return <group>{list}</group>;
}

export function MiddleColumns(props) {
  const { nodes, materials, color, columns, isTexture } = props;
  const { width, height1, depth, height2 } = props.length;
  const { int_height1, unit, center_width, center_height2, int_depth } =
    props.int_length;
  // const sh2 = height2 / center_height2;
  const sh2 = (height2 - 40) / center_height2;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  const dw = width - center_width;

  return (
    <group
      scale={[1, 1, 1]}
      position={[(dw * unit) / 2 - 20, 115 - depth * unit, 0]}
    >
      <mesh
        castShadow
        material-color={color}
        geometry={nodes.leftcolumn1.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[3110.33, 2948.7, -1983.42]}
        scale={[1, 1, sh2]}
      />
      {width > 5000 ? (
        <group position={[78 - (width * unit) / 2, 0, 0]}>
          <mesh
            castShadow
            material-color={color}
            geometry={nodes.leftcolumn1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[3110.33, 2948.7, -1983.42]}
            scale={[1, 1, sh2]}
          />
        </group>
      ) : null}
    </group>
  );
}

export function Glass(props) {
  const {
    nodes,
    materials,
    systemPos,
    visible,
    coverColor,
    direction,
    systemColor,
  } = props;
  const { width, depth } = props.length;
  const {
    int_width,
    int_height1,
    int_height2,
    int_depth,
    thickness,
    column_thickness,
    unit,
  } = props.int_length;
  const delta = column_thickness * 2;
  const sw = (width - delta) / (int_width - delta);
  const sw2 = width / int_width;
  const sw3 = (width - 70) / int_width;
  const dd = depth - int_depth;

  const alpha1 = Math.tan(
    (int_height2 - int_height1 - thickness) / (depth + 220)
  );
  const alpha2 = Math.tan(
    (int_height2 - int_height1 - thickness) / (int_depth + 220)
  );

  const list = [[], [], []];
  const len = depth;
  const div_count = parseInt((len - 100) / 400);
  var div_count1 = [0, 0, 0];
  if (props.modelID === 16) {
    div_count1 = [
      parseInt((div_count * (100 - systemPos[47])) / 100),
      parseInt((div_count * (100 - systemPos[direction == 0 ? 47 : 46])) / 100),
      parseInt((div_count * (100 - systemPos[direction == 0 ? 47 : 45])) / 100),
    ];
  } else {
    div_count1 = [
      parseInt((div_count * (100 - systemPos[23])) / 100),
      parseInt((div_count * (100 - systemPos[direction == 0 ? 23 : 22])) / 100),
      parseInt((div_count * (100 - systemPos[direction == 0 ? 23 : 21])) / 100),
    ];
  }

  const div_count2 = [
    div_count - div_count1[0],
    div_count - div_count1[1],
    div_count - div_count1[2],
  ];
  const sd = (80 * div_count + 300) / 1305;
  const sdd = depth / int_depth;

  const l_width = (len - (220 * depth) / int_depth) / div_count;
  const s_width = 80;
  const dh = props.modelID === 11 || props.modelID === 16 ? 9.8 : 10;

  const light_geometry = new THREE.BoxGeometry(20, 10, 100);
  const light_material = new THREE.MeshPhysicalMaterial({ color: "#ffffff" });
  const texture = useTexture(props.texture);
  const texture2 = useTexture(props.texture2);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  texture2.wrapS = THREE.RepeatWrapping;
  texture2.wrapT = THREE.RepeatWrapping;
  texture2.repeat.set(xrepeat, yrepeat);
  // texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  const material_texture2 = new THREE.MeshPhysicalMaterial({
    map: texture2,
  });

  for (var i = 0; i < div_count; i = i + 1) {
    for (var k = 0; k < 3; k = k + 1) {
      if (i < div_count1[k]) {
        list[k].push(
          <group key={`${i}-${k}`} position={[0, i * s_width, -i * dh]}>
            {i === 0 ? (
              <mesh
                key={i}
                material-color={coverColor}
                geometry={nodes.collector1.geometry}
                material={
                  props.isTexture ? material_texture : materials.profile
                }
                position={[-3093.82, -3041.52, 1768.58]}
                rotation={[1.44, Math.PI / 2, 0]}
                scale={[2, 1, 1]}
              />
            ) : (
              <mesh
                key={i}
                material-color={coverColor}
                geometry={nodes.collector1.geometry}
                material={
                  props.isTexture ? material_texture : materials.profile
                }
                position={[-3093.82, -3041.52, 1768.58]}
                rotation={[1.44, Math.PI / 2, 0]}
              />
            )}
            {visible && i % 2 === 0 ? (
              <>
                <mesh
                  key={i + div_count + 300}
                  geometry={light_geometry}
                  material={light_material}
                  position={[-3093.82 + 600, -3041.52, 1768.58 - 30]}
                  rotation={[1.44, Math.PI / 2, 0]}
                />
                <mesh
                  key={i + div_count + 301}
                  geometry={light_geometry}
                  material={light_material}
                  position={[-3093.82 + 1500, -3041.52, 1768.58 - 30]}
                  rotation={[1.44, Math.PI / 2, 0]}
                />
                <mesh
                  key={i + div_count + 400}
                  geometry={light_geometry}
                  material={light_material}
                  position={[-3093.82 + 2400, -3041.52, 1768.58 - 30]}
                  rotation={[1.44, Math.PI / 2, 0]}
                />
              </>
            ) : null}
            <mesh
              key={i + div_count + 100}
              geometry={nodes.collected1.geometry}
              material={materials.curtain}
              position={[-1546.1, -2992.19, 1793.2]}
              rotation={[3.01, 0, 0]}
              material-color={systemColor[23]}
            />
          </group>
        );
      } else {
        const j = i - div_count1[k];

        list[k].push(
          <group
            key={`${i}-${k}`}
            position={[
              0,
              div_count1[k] * s_width + j * l_width,
              -div_count1[k] * dh - (j * dh * l_width) / s_width,
            ]}
          >
            {i === 0 ? (
              <mesh
                material-color={coverColor}
                key={i}
                geometry={nodes.collector1.geometry}
                material={
                  props.isTexture ? material_texture : materials.profile
                }
                position={[-3093.82, -3041.52, 1768.58]}
                rotation={[1.44, Math.PI / 2, 0]}
                scale={[2, 1, 1]}
              />
            ) : (
              <mesh
                material-color={coverColor}
                key={i}
                geometry={nodes.collector1.geometry}
                material={
                  props.isTexture ? material_texture : materials.profile
                }
                position={[-3093.82, -3041.52, 1768.58]}
                rotation={[1.44, Math.PI / 2, 0]}
              />
            )}
            {visible && i % 2 === 0 ? (
              <>
                <mesh
                  key={i + div_count + 500}
                  geometry={light_geometry}
                  material={light_material}
                  position={[-3093.82 + 600, -3041.52, 1768.58 - 30]}
                  rotation={[1.44, Math.PI / 2, 0]}
                />
                <mesh
                  key={i + div_count + 501}
                  geometry={light_geometry}
                  material={light_material}
                  position={[-3093.82 + 1500, -3041.52, 1768.58 - 30]}
                  rotation={[1.44, Math.PI / 2, 0]}
                />
                <mesh
                  key={i + div_count + 600}
                  geometry={light_geometry}
                  material={light_material}
                  position={[-3093.82 + 2400, -3041.52, 1768.58 - 30]}
                  rotation={[1.44, Math.PI / 2, 0]}
                />
              </>
            ) : null}
            <mesh
              key={i + div_count + 300}
              geometry={nodes.curtaiin1.geometry}
              position={[-1546.32, -2835.46, 1763.25 + 10]}
              rotation={[-0.13, 0, 3.14]}
              scale={[1, (l_width / 260) * 1.04, 1]}
              material-color={systemColor[23]}
            />
          </group>
        );
      }
    }
  }

  for (var k = 0; k < 3; k = k + 1) {
    list[k].push(
      <group
        key={`100-${div_count}-${k}`}
        position={[
          0,
          div_count1[k] * s_width + div_count2[k] * l_width + 20,
          -div_count1[k] * dh - (div_count2[k] * dh * l_width) / s_width,
        ]}
      >
        <mesh
          material-color={coverColor}
          geometry={nodes.collector1.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[-3093.82, -3041.52, 1768.58]}
          rotation={[1.44, Math.PI / 2, 0]}
          scale={[2, 1, 1]}
        />
        {visible && false ? (
          <>
            <mesh
              key={div_count + 700}
              geometry={light_geometry}
              material={light_material}
              position={[-3093.82 + 600, -3041.52, 1768.58 - 30]}
              rotation={[1.44, Math.PI / 2, 0]}
            />
            <mesh
              key={div_count + 701}
              geometry={light_geometry}
              material={light_material}
              position={[-3093.82 + 1500, -3041.52, 1768.58 - 30]}
              rotation={[1.44, Math.PI / 2, 0]}
            />
            <mesh
              key={div_count + 800}
              geometry={light_geometry}
              material={light_material}
              position={[-3093.82 + 2400, -3041.52, 1768.58 - 30]}
              rotation={[1.44, Math.PI / 2, 0]}
            />
          </>
        ) : null}
        {/* <mesh geometry={nodes.curtaiin1.geometry} material={materials.curtain} position={[-1546.32, -2902.19, 1773.25]} rotation={[-0.13, 0, 3.14]} /> */}
      </group>
    );
  }

  const total_list = [];
  if (props.modelID === 11 || props.modelID === 16 || isElegantModel(props)) {
    if (width > 8000) {
      total_list.push(
        <group
          key={22}
          position={[-1040, 0, 0]}
          scale={[2 / 3.06, 1, 1]}
          onPointerOver={(e) => {
            document.body.style.cursor = "pointer";
          }}
          onPointerOut={(e) => {
            document.body.style.cursor = "auto";
          }}
          onClick={(e) => {
            e.stopPropagation();
            state.editingSubSystem = true;
            state.editingSubSystemID = props.modelID === 16 ? 47 : 23;
            state.editingSystemType = 1;
          }}
        >
          {list[0]}
        </group>
      );
      total_list.push(
        <group
          key={23}
          position={[1000, 0, 0]}
          scale={[2 / 3.06, 1, 1]}
          onPointerOver={(e) => {
            document.body.style.cursor = "pointer";
          }}
          onPointerOut={(e) => {
            document.body.style.cursor = "auto";
          }}
          onClick={(e) => {
            e.stopPropagation();
            state.editingSubSystem = true;
            if (props.modelID === 16) {
              state.editingSubSystemID = direction == 0 ? 47 : 46;
            } else state.editingSubSystemID = direction == 0 ? 23 : 22;
            state.editingSystemType = 1;
          }}
        >
          {list[1]}
        </group>
      );
      total_list.push(
        <group
          key={24}
          position={[3040, 0, 0]}
          scale={[2 / 3.06, 1, 1]}
          onPointerOver={(e) => {
            document.body.style.cursor = "pointer";
          }}
          onPointerOut={(e) => {
            document.body.style.cursor = "auto";
          }}
          onClick={(e) => {
            e.stopPropagation();
            state.editingSubSystem = true;
            if (props.modelID === 16) {
              state.editingSubSystemID = direction == 0 ? 47 : 46;
            } else state.editingSubSystemID = direction == 0 ? 23 : 22;
            state.editingSystemType = 1;
          }}
        >
          {list[2]}
        </group>
      );
    } else if (width > 4000) {
      total_list.push(
        <group
          key={20}
          onPointerOver={(e) => {
            document.body.style.cursor = "pointer";
          }}
          onPointerOut={(e) => {
            document.body.style.cursor = "auto";
          }}
          onClick={(e) => {
            e.stopPropagation();
            state.editingSubSystem = true;
            state.editingSubSystemID = props.modelID === 16 ? 47 : 23;
            state.editingSystemType = 1;
          }}
        >
          {list[0]}
        </group>
      );
      total_list.push(
        <group
          key={21}
          position={[3120, 0, 0]}
          onPointerOver={(e) => {
            document.body.style.cursor = "pointer";
          }}
          onPointerOut={(e) => {
            document.body.style.cursor = "auto";
          }}
          onClick={(e) => {
            e.stopPropagation();
            state.editingSubSystem = true;
            if (props.modelID === 16) {
              state.editingSubSystemID = direction == 0 ? 47 : 46;
            } else state.editingSubSystemID = direction == 0 ? 23 : 22;
            state.editingSystemType = 1;
          }}
        >
          {list[1]}
        </group>
      );
    } else {
      total_list.push(
        <group
          key={27}
          position={[3120, 0, 0]}
          scale={[2, 1, 1]}
          onPointerOver={(e) => {
            document.body.style.cursor = "pointer";
          }}
          onPointerOut={(e) => {
            document.body.style.cursor = "auto";
          }}
          onClick={(e) => {
            e.stopPropagation();
            state.editingSubSystem = true;
            state.editingSubSystemID = props.modelID === 16 ? 47 : 23;
            state.editingSystemType = 1;
          }}
        >
          {list[0]}
        </group>
      );
    }
  } else {
    if (width > 8000) {
      total_list.push(
        <>
          <group key={32} position={[-1040, 0, 0]} scale={[2 / 3.06, 1, 1]}>
            {list}
          </group>
          <group key={33} position={[1000, 0, 0]} scale={[2 / 3.06, 1, 1]}>
            {list}
          </group>
          <group key={34} position={[3040, 0, 0]} scale={[2 / 3.06, 1, 1]}>
            {list}
          </group>
        </>
      );
    } else if (width > 4000) {
      total_list.push(
        <>
          <group key={35} position={[0, 0, 0]} scale={[1, 1, 1]}>
            {list}
          </group>
          <group key={36} position={[3120, 0, 0]} scale={[1, 1, 1]}>
            {list}
          </group>
        </>
      );
    } else {
      total_list.push(
        <group key={37} position={[3120, 0, 0]} scale={[2, 1, 1]}>
          {list}
        </group>
      );
    }
  }

  const sd2 = depth / int_depth;
  const delta2 = Math.min(0, (sd2 - 1) * 50);
  const a1 = Math.atan((int_height2 - int_height1 - 100) / int_depth);
  const a2 = Math.atan((int_height2 - int_height1 - 100) / depth);
  const a3 = depth * Math.tan(a1 - a2) * unit;

  return (
    <group
      position={[0, -dd * unit - 3220, 1980 + (isElegantModel(props) ? 0 : 0)]}
      rotation={[(alpha2 - alpha1) * 0.8, 0, 0]}
      scale={[1, 1, 1]}
    >
      <group position={[0, 3220, -1980]}>
        {isElegantModel(props) ? (
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.Metal_Panel.geometry}
            material={props.isTexture ? material_texture2 : materials.profile}
            position={[
              1.07,
              -1939.6 - 20 + 1300 * (sd - 1),
              1737.54 - 25 - (sd - 1) * 160,
            ]}
            rotation={[-0.13, 0, -1.57]}
            scale={[sd, sw3, 0.9]}
          />
        ) : (
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.Metal_Panel.geometry}
            material={props.isTexture ? material_texture2 : materials.profile}
            position={[
              1.07,
              -1939.6 - 20 + 1300 * (sd - 1),
              1737.54 - 5 - (sd - 1) * 160,
            ]}
            rotation={[-0.13, 0, -1.57]}
            scale={[sd, sw2, 0.95]}
          />
        )}
        <group
          position={[0, 0, isElegantModel(props) ? -22 : -10]}
          scale={[sw, 1, 1]}
        >
          {total_list}
        </group>
      </group>
    </group>
  );
}

export function TopDivPergola(props) {
  const { nodes, materials } = useGLTF(elegantModel);
  const { coverColor, direction } = props;
  const { width, depth } = props.length;
  const { int_width, column_width, unit } = props.int_length;
  const dw = width - int_width;
  const list = [];

  const sd = (depth + 50) / 5290;
  const top_div_scale = 0.7;
  const texture = useTexture(props.texture3);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 5;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  list.push(
    <group key={-1} position={[(-dw * unit) / 2, 0, 0]}>
      <mesh
        castShadow
        material-color={coverColor}
        geometry={nodes.rightprofile2.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[-3027.43, 3553.77, 1020.71]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[1, 1, 1]}
      />
    </group>
  );
  list.push(
    <group key={0} position={[(dw * unit) / 2, 0, 0]}>
      <mesh
        castShadow
        material-color={coverColor}
        geometry={nodes.leftprofile1.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[3030.86, 3553.77, 1020.71]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[1, 1, -1]}
      />
    </group>
  );

  if (width > 8000) {
    if (direction == 0) {
      list.push(
        <group
          key={1}
          position={[(width * unit) / 6 - (column_width * unit) / 2, 0, 0]}
        >
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3553.77, 1020.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
      list.push(
        <group key={2} position={[(-width * unit) / 6, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3553.77, 1020.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
    } else {
      list.push(
        <group
          key={3}
          position={[(width * unit) / 6 - column_width * unit, 0, 0]}
        >
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3553.77, 1020.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
      list.push(
        <group
          key={4}
          position={[
            (-width * unit) / 6 + (column_width * unit) / 2 + 10,
            0,
            0,
          ]}
        >
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3553.77, 1020.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
      list.push(
        <group key={5} position={[(width * unit) / 6 + 10, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3553.77, 1020.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
      list.push(
        <group
          key={6}
          position={[(-width * unit) / 6 - (column_width * unit) / 2, 0, 0]}
        >
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3553.77, 1020.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
    }
  } else if (width > 4000) {
    if (direction == 0) {
      list.push(
        <group key={7} position={[0, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3553.77, 1020.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
    } else {
      list.push(
        <group key={8} position={[(column_width * unit) / 2 + 10, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3553.77, 1020.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
      list.push(
        <group key={9} position={[(-column_width * unit) / 2, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3553.77, 1020.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
    }
  }

  return (
    <group position={[0, 6600 - 3531.5, 1028]} scale={[1, sd, 1]}>
      <group position={[0, 3531.5, -1028]} rotation={[0, 0, -Math.PI]}>
        {list}
      </group>
    </group>
  );
}

export function TopDivPergolaCap(props) {
  const { nodes, materials } = useGLTF(elegantModel);
  const { coverColor, direction } = props;
  const { width, depth, height1, height2 } = props.length;
  const { int_width, int_depth, int_height2, column_width, unit } =
    props.int_length;
  const dw = width - int_width;
  const list = [];

  const sd = depth / 5500;
  const top_div_scale = 0.7;
  const texture = useTexture(props.texture3);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 5;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  list.push(
    <group key={-1} position={[(-dw * unit) / 2, 0, 0]}>
      <mesh
        castShadow
        material-color={coverColor}
        geometry={nodes.rightprofilrcap.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[-3027.43, 3573.77, 1023.71]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[1, 1, 1]}
      />
    </group>
  );
  list.push(
    <group key={0} position={[(dw * unit) / 2, 0, 0]}>
      <mesh
        castShadow
        material-color={coverColor}
        geometry={nodes.leftprofile1cap.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[3030.86, 3573.77, 1023.71]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[1, 1, -1]}
      />
    </group>
  );

  if (width > 8000) {
    if (direction == 0) {
      list.push(
        <group
          key={1}
          position={[(width * unit) / 6 - (column_width * unit) / 2, 0, 0]}
        >
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1cap.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3573.77, 1023.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
      list.push(
        <group key={2} position={[(-width * unit) / 6, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1cap.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3573.77, 1023.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
    } else {
      list.push(
        <group
          key={3}
          position={[(width * unit) / 6 - column_width * unit, 0, 0]}
        >
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1cap.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3573.77, 1023.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
      list.push(
        <group
          key={4}
          position={[
            (-width * unit) / 6 + (column_width * unit) / 2 + 10,
            0,
            0,
          ]}
        >
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1cap.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3573.77, 1023.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
      list.push(
        <group key={5} position={[(width * unit) / 6 + 10, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1cap.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3573.77, 1023.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
      list.push(
        <group
          key={6}
          position={[(-width * unit) / 6 - (column_width * unit) / 2, 0, 0]}
        >
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1cap.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3573.77, 1023.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
    }
  } else if (width > 4000) {
    if (direction == 0) {
      list.push(
        <group key={7} position={[0, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1cap.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3573.77, 1023.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
    } else {
      list.push(
        <group key={8} position={[(column_width * unit) / 2 + 10, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1cap.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3573.77, 1023.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
      list.push(
        <group key={9} position={[(-column_width * unit) / 2, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.leftprofile1cap.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3573.77, 1023.71]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={[1, 1, 1]}
          />
        </group>
      );
    }
  }

  const alpha =
    Math.atan((height2 - height1 - 300) / int_depth) -
    Math.atan((height2 - height1 - 300) / depth);

  return (
    <group
      position={[0, 6600 - 3550, 1010]}
      scale={[1, 1, 1]}
      rotation={[alpha, 0, 0]}
    >
      <group position={[0, 3550, -1010]} rotation={[0, 0, -Math.PI]}>
        {list}
      </group>
    </group>
  );
}

export function TopDiv(props) {
  const { nodes, materials, coverColor } = props;
  const { width, depth } = props.length;
  const { int_width, int_depth, column_width, unit } = props.int_length;
  const dw = width - int_width;
  const list = [];

  // const sd = (depth + 50) / 6250;
  const sd = depth / 6300;
  const top_div_scale = [1, 1, 135 / 133];
  const texture = useTexture(props.texture3);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 5;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  list.push(
    <group key={-1} position={[(-dw * unit) / 2, 0, 0]}>
      {props.isTexture ? (
        <mesh
          castShadow
          geometry={nodes.rightprofile1.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[-3027.43, 3050.02, 1023.06]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={top_div_scale}
        />
      ) : (
        <mesh
          castShadow
          material-color={coverColor}
          geometry={nodes.rightprofile1.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[-3027.43, 3050.02, 1023.06]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={top_div_scale}
        />
      )}
    </group>
  );
  list.push(
    <group key={0} position={[(dw * unit) / 2, 0, 0]}>
      <mesh
        castShadow
        material-color={coverColor}
        geometry={nodes.leftprofile1.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[3161.86, 3050.02, 1023.06]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={top_div_scale}
      />
    </group>
  );

  if (props.modelID === 11 || props.modelID === 16) {
    if (width > 4000 && width <= 8000)
      list.push(
        <group key={-5} position={[0, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.middleprofile1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3050.02, 1023.06]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={top_div_scale}
          />
        </group>
      );
    else if (width > 8000) {
      list.push(
        <group key={-6} position={[width / 6, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.middleprofile1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3050.02, 1023.06]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={top_div_scale}
          />
        </group>
      );
      list.push(
        <group key={-7} position={[-width / 6, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.middleprofile1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3050.02, 1023.06]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={top_div_scale}
          />
        </group>
      );
    }
  } else if (width > 8000) {
    list.push(
      <group
        key={-2 + "topdiv"}
        position={[(width * unit) / 6 - (column_width * unit) / 2, 0, 0]}
      >
        <mesh
          castShadow
          material-color={coverColor}
          geometry={nodes.middleprofile1.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[68.53, 3050.02, 1023.06]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={top_div_scale}
        />
      </group>
    );
    list.push(
      <group key={-3} position={[(-width * unit) / 6, 0, 0]}>
        <mesh
          castShadow
          material-color={coverColor}
          geometry={nodes.middleprofile1.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[68.53, 3050.02, 1023.06]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={top_div_scale}
        />
      </group>
    );
  } else if (width > 4000) {
    list.push(
      <group key={-4} position={[0, 0, 0]}>
        <mesh
          castShadow
          material-color={coverColor}
          geometry={nodes.middleprofile1.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[68.53, 3050.02, 1023.06]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={top_div_scale}
        />
      </group>
    );
  }

  const sd2 = depth / int_depth;
  const delta = Math.min(0, (sd2 - 1) * 100);

  return (
    <group position={[0, 3050, 1200]} scale={[1, sd, 1]}>
      <group position={[0, -3050, -1200]}>{list}</group>
    </group>
  );
}

export function AddWallsForGardes(props) {
  const { walls } = props;
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;

  return walls.adding === true ? (
    <group>
      {walls.added[2] === false ? (
        <Html
          scaleFactor={5}
          position={[(width * unit) / 2, (oy - depth) * unit, -oz * unit]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(3);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[2] = true;
              state.walls.adding = false;
            }}
          >
            Back Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}

      {walls.added[1] === false && false ? (
        <Html
          scaleFactor={5}
          position={[0, (oy - depth) * unit + depth * unit, -oz * unit]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(2);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[1] = true;
              state.walls.adding = false;
            }}
          >
            Left Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}

      {walls.added[1] === false && false ? (
        <Html
          scaleFactor={5}
          position={[0, (oy - depth) * unit - depth * unit, -oz * unit]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(4);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[1] = true;
              state.walls.adding = false;
            }}
          >
            Right Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
    </group>
  ) : null;
}

export function WallsForGardes(props) {
  const { walls } = props;
  const { width, depth, height2 } = props.length;
  const { unit, oy, oz } = props.int_length;

  return (
    <group
      position={[
        (width * unit) / 2 + (wallThickness * unit) / 2,
        (-250 - depth) * unit,
        -oz * unit + (height2 / 2) * unit + (wallTopHeight * unit) / 2,
      ]}
    >
      {/* <Wall width = {width} height = {height2} unit={unit} position={[0, (oy + 50) * unit + wallThickness * unit / 2, 0]} rotation = {[Math.PI / 2, 0, 0]}/>  */}
      {walls.added[2] ? (
        <Wall
          width={depth * 2}
          height={height2}
          unit={unit}
          position={[0, (oy + 200) * unit, 0]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
          ID={2}
        />
      ) : null}
    </group>
  );
}

export function TopDivCap(props) {
  const { nodes, materials, coverColor } = props;
  const { width, depth, height1, height2 } = props.length;
  const { int_width, int_depth, int_height1, int_height2, column_width, unit } =
    props.int_length;
  const dw = width - int_width;
  const list = [];

  const sd = (depth + 220) / 6450;
  const top_div_scale = [1, 1, 135 / 133];
  const texture = useTexture(props.texture3);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 5;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  list.push(
    <group key={-1} position={[(-dw * unit) / 2, 0, 0]}>
      <mesh
        castShadow
        material-color={coverColor}
        geometry={nodes.rightprofile1cap.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[-3027.43, 3050.84, 1022.95]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={top_div_scale}
      />
    </group>
  );
  list.push(
    <group key={0} position={[(dw * unit) / 2, 0, 0]}>
      <mesh
        castShadow
        material-color={coverColor}
        geometry={nodes.leftprofile1cap.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[3161.86, 3050.84, 1022.95]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={top_div_scale}
      />
    </group>
  );

  if (props.modelID === 11 || props.modelID === 16) {
    if (width > 4000 && width <= 8000)
      list.push(
        <group key={-5} position={[0, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.middleprofile1cap.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3050.84, 1022.95]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={top_div_scale}
          />
        </group>
      );
    else if (width > 8000) {
      list.push(
        <group key={-6} position={[width / 6, 0, 0]}>
          {/* <mesh castShadow material-color={coverColor} geometry={nodes.middleprofile1.geometry} material={materials.profile} position={[68.53, 3050.02, 1023.06]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={[1, 1, 1]} /> */}
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.middleprofile1cap.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3050.84, 1022.95]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={top_div_scale}
          />
        </group>
      );
      list.push(
        <group key={-7} position={[-width / 6, 0, 0]}>
          <mesh
            castShadow
            material-color={coverColor}
            geometry={nodes.middleprofile1cap.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[68.53, 3050.84, 1022.95]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            scale={top_div_scale}
          />
        </group>
      );
    }
  } else if (width > 8000) {
    list.push(
      <group
        key={-2 + "topdivcap"}
        position={[(width * unit) / 6 - (column_width * unit) / 2, 0, 0]}
      >
        <mesh
          castShadow
          material-color={coverColor}
          geometry={nodes.middleprofile1cap.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[68.53, 3050.84, 1022.95]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={top_div_scale}
        />
      </group>
    );
    list.push(
      <group key={-3} position={[(-width * unit) / 6, 0, 0]}>
        <mesh
          castShadow
          material-color={coverColor}
          geometry={nodes.middleprofile1cap.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[68.53, 3050.84, 1022.95]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={top_div_scale}
        />
      </group>
    );
  } else if (width > 4000) {
    list.push(
      <group key={-4} position={[0, 0, 0]}>
        <mesh
          castShadow
          material-color={coverColor}
          geometry={nodes.middleprofile1cap.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[68.53, 3050.84, 1022.95]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={top_div_scale}
        />
      </group>
    );
  }

  const sd2 = depth / int_depth;
  const delta = Math.min(0, (sd2 - 1) * 100);
  const alpha =
    Math.atan((height2 - height1 - 300) / int_depth) -
    Math.atan((height2 - height1 - 300) / depth);

  return (
    <group
      position={[0, 3050 - 8, 1200]}
      scale={[1, 1, 1]}
      rotation={[alpha, 0, 0]}
    >
      <group position={[0, -3050, -1200]}>{list}</group>
    </group>
  );
}

export function Side(props) {
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz } = props.int_length;
  if (props.current_side === 0) return null;
  switch (props.current_side) {
    case 1:
      return (
        <mesh
          position={[0, oy * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[width * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>
      );
    case 2:
      if (props.modelID === 11)
        return (
          <mesh
            position={[
              (-width * unit) / 2,
              (oy - depth / 2 - 50) * unit,
              (height1 / 2 - oz) * unit,
            ]}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
          >
            <boxGeometry args={[depth * unit, height1 * unit, 1]} />
            <meshBasicMaterial color="#002853" transparent opacity={0.4} />
          </mesh>
        );
      else
        return (
          <mesh
            position={[
              (-width * unit) / 2,
              (oy - depth / 2 - 50) * unit,
              (height1 / 2 - oz) * unit,
            ]}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <boxGeometry args={[depth * unit, height1 * unit, 1]} />
            <meshBasicMaterial color="#002853" transparent opacity={0.4} />
          </mesh>
        );
    case 3:
      return props.isGardes ? (
        <mesh
          position={[
            (width * unit) / 2,
            (oy - depth) * unit,
            (height2 / 2 - oz) * unit,
          ]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * 2 * unit, height2 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>
      ) : (
        <mesh
          position={[0, (oy - depth) * unit, (height2 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[width * unit, height2 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>
      );
    case 4:
      return (
        <mesh
          position={[
            (width * unit) / 2,
            (oy - depth / 2 - 50) * unit,
            (height1 / 2 - oz) * unit,
          ]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>
      );
    default:
      return null;
  }
}

export function AddExtraColumns(props) {
  const { columns, columnFlag, secondHeight } = props;
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;

  return props.activeStep === 0 &&
    props.subStep === 1 &&
    columns.adding === true ? (
    <group>
      {columnFlag[4] === true ? (
        <Html scaleFactor={5} position={[0, -oy * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => {
              if (columns.added[4] === false || columns.added[5] === false)
                props.setCurrentSide(1);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              if (!columns.added[4]) props.ShiftColumn("E");
              else if (!columns.added[5]) props.ShiftColumn("F");
            }}
          >
            Front Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
      {!secondHeight ? (
        <Html
          scaleFactor={5}
          position={[(-width * unit) / 2, (depth / 2 - oy) * unit, -oz * unit]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              if (columns.added[5] === false) props.setCurrentSide(2);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              if (columns.added[5] === false) props.ShiftColumn("F");
            }}
          >
            Right Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
      {!secondHeight ? (
        <Html scaleFactor={5} position={[0, (depth - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => {
              if (columns.added[6] === false) props.setCurrentSide(3);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              if (columns.added[6] === false) props.ShiftColumn("G");
            }}
          >
            Back Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
      {!secondHeight ? (
        <Html
          scaleFactor={5}
          position={[(width * unit) / 2, (depth / 2 - oy) * unit, -oz * unit]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              if (columns.added[7] === false) props.setCurrentSide(4);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              if (columns.added[7] === false) props.ShiftColumn("H");
            }}
          >
            Left Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
    </group>
  ) : null;
}

export function AddWalls(props) {
  const { walls, secondHeight } = props;
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;

  return walls.adding === true ? (
    <group>
      {!secondHeight && walls.added[0] === false ? (
        <Html scaleFactor={5} position={[0, -oy * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(1);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[0] = true;
              state.walls.adding = false;
            }}
          >
            Front Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
      {walls.added[1] === false && false ? (
        <Html
          scaleFactor={5}
          position={[(-width * unit) / 2, (oy - depth / 2) * unit, -oz * unit]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(2);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[1] = true;
              state.walls.adding = false;
            }}
          >
            Right Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
      {walls.added[2] === false ? (
        <Html scaleFactor={5} position={[0, (depth - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(3);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[2] = true;
              state.walls.adding = false;
            }}
          >
            Back Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
      {walls.added[3] === false && false ? (
        <Html
          scaleFactor={5}
          position={[(width * unit) / 2, (oy - depth / 2) * unit, -oz * unit]}
        >
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(4);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[3] = true;
              state.walls.adding = false;
            }}
          >
            Left Side
            <BsPlusCircle />
          </div>
        </Html>
      ) : null}
    </group>
  ) : null;
}

export function LengthInfo(props) {
  const { secondHeight } = props;
  const { width, depth, height1, height2 } = props.length;
  const {
    unit,
    oy,
    oz,
    LineColor,
    LineEndColor,
    lengthEndLineWidth,
    lengthInfoDist,
  } = props.int_length;
  const points = [];
  points.push(new THREE.Vector3(0, 0, 0));
  points.push(new THREE.Vector3(width * unit, 0, 0));
  const lineGeometryForWidth = new THREE.BufferGeometry().setFromPoints(points);
  const points2 = [];
  points2.push(new THREE.Vector3(0, 0, 0));
  points2.push(new THREE.Vector3(0, depth * unit, 0));
  const lineGeometryForDepth = new THREE.BufferGeometry().setFromPoints(
    points2
  );
  const points3 = [];
  points3.push(new THREE.Vector3(0, 0, 0));
  points3.push(new THREE.Vector3(0, 0, height1 * unit));
  const lineGeometryForHeight1 = new THREE.BufferGeometry().setFromPoints(
    points3
  );
  const points4 = [];
  points4.push(new THREE.Vector3(0, 0, 0));
  points4.push(new THREE.Vector3(0, 0, height2 * unit));
  const lineGeometryForHeight2 = new THREE.BufferGeometry().setFromPoints(
    points4
  );

  const points5 = [];
  points5.push(new THREE.Vector3(0, 0, 0));
  points5.push(new THREE.Vector3(0, 0, lengthEndLineWidth * unit));
  const lineGeometryForEnd = new THREE.BufferGeometry().setFromPoints(points5);

  return props.activeStep === 0 && props.subStep === 0 ? (
    <group>
      <Html scaleFactor={5} position={[0, oy * unit, (height1 - oz) * unit]}>
        <div className="length-info">
          {parseInt(width * props.length.unit + 0.5)} {props.length.unitName}
        </div>
      </Html>

      <Html
        scaleFactor={5}
        position={[
          (width * unit) / 2,
          (oy - depth / 2) * unit,
          (-oz + 200) * unit,
        ]}
      >
        <div className="length-info">
          {parseInt(depth * props.length.unit + 0.5)} {props.length.unitName}
        </div>
      </Html>
      <Html
        scaleFactor={5}
        position={[
          ((width + 100) * unit) / 2,
          oy * unit,
          (height1 / 2 - oz) * unit,
        ]}
      >
        <div className="length-info">
          {parseInt(height1 * props.length.unit + 0.5)} {props.length.unitName}
        </div>
      </Html>
      {secondHeight ? (
        <Html
          scaleFactor={5}
          position={[
            (width * unit) / 2,
            (-depth + oy) * unit,
            (height2 / 2 - oz) * unit,
          ]}
        >
          <div className="length-info">
            {parseInt(height2 * props.length.unit + 0.5)}{" "}
            {props.length.unitName}
          </div>
        </Html>
      ) : null}

      <line
        geometry={lineGeometryForWidth}
        position={[
          (-width * unit) / 2,
          (oy + lengthInfoDist) * unit,
          -oz * unit + height1 * unit,
        ]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>
      <line
        geometry={lineGeometryForDepth}
        position={[(width * unit) / 2, (oy - depth) * unit, -oz * unit]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>
      <line
        geometry={lineGeometryForHeight1}
        position={[(width * unit) / 2, oy * unit, -oz * unit]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>
      <line
        geometry={lineGeometryForHeight2}
        position={[(width * unit) / 2, (oy - depth) * unit, -oz * unit]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>

      <line
        geometry={lineGeometryForEnd}
        position={[
          (-width * unit) / 2,
          (oy + lengthInfoDist) * unit,
          (-oz + height1 - lengthEndLineWidth / 2) * unit,
        ]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineEndColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>

      <line
        geometry={lineGeometryForEnd}
        position={[
          (width * unit) / 2,
          (oy + lengthInfoDist) * unit,
          (-oz + height1 - lengthEndLineWidth / 2) * unit,
        ]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineEndColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>

      <line
        geometry={lineGeometryForEnd}
        position={[
          (width * unit) / 2,
          oy * unit,
          (-oz - lengthEndLineWidth / 2) * unit,
        ]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineEndColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>

      <line
        geometry={lineGeometryForEnd}
        position={[
          (width * unit) / 2,
          (oy - depth) * unit,
          (-oz - lengthEndLineWidth / 2) * unit,
        ]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineEndColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>

      <line
        geometry={lineGeometryForEnd}
        position={[
          (width * unit) / 2,
          (oy + lengthEndLineWidth / 2) * unit,
          -oz * unit,
        ]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineEndColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>
      <line
        geometry={lineGeometryForEnd}
        position={[
          (width * unit) / 2,
          (oy + lengthEndLineWidth / 2) * unit,
          (-oz + height1) * unit,
        ]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineEndColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>

      <line
        geometry={lineGeometryForEnd}
        position={[
          (width * unit) / 2,
          (oy - depth + lengthEndLineWidth / 2) * unit,
          -oz * unit,
        ]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineEndColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>
      <line
        geometry={lineGeometryForEnd}
        position={[
          (width * unit) / 2,
          (oy - depth + lengthEndLineWidth / 2) * unit,
          (-oz + height2) * unit,
        ]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <lineBasicMaterial
          attach="material"
          color={LineEndColor}
          linewidth={1}
          linecap={"round"}
          linejoin={"round"}
        />
      </line>
    </group>
  ) : null;
}

export function ColumnLabels(props) {
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz, secondHeight } = props.int_length;
  const columns = props.columns;
  const columnFlag = props.columnFlag;

  const list = [];
  for (var i = 2; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      list.push(
        <Html
          key={i}
          scaleFactor={5}
          position={[
            (width * unit) / 2 - columns.pos[i] * unit,
            -oy * unit,
            (height1 - oz) * unit,
          ]}
        >
          <div className="length-info">{String.fromCharCode(65 + i)}</div>
        </Html>
      );
    }
  }

  return props.activeStep === 0 && props.subStep === 1 ? (
    <group>
      {columnFlag[0] ? (
        <Html
          scaleFactor={5}
          position={[
            (width * unit) / 2 - columns.pos[0] * unit,
            -oy * unit,
            (height1 - oz) * unit,
          ]}
        >
          <div className="length-info">A</div>
        </Html>
      ) : null}
      {columnFlag[1] ? (
        <Html
          scaleFactor={5}
          position={[
            (-width * unit) / 2 + columns.pos[1] * unit,
            -oy * unit,
            (height1 - oz) * unit,
          ]}
        >
          <div className="length-info">B</div>
        </Html>
      ) : null}
      {secondHeight === false && columnFlag[2] === true ? (
        <Html
          scaleFactor={5}
          position={[
            (-width * unit) / 2,
            (depth - oy) * unit,
            (height2 - oz) * unit,
          ]}
        >
          <div className="length-info">C</div>
        </Html>
      ) : null}
      {secondHeight === false && columnFlag[2] === true ? (
        <Html
          scaleFactor={5}
          position={[
            (width * unit) / 2,
            (depth - oy) * unit,
            (height2 - oz) * unit,
          ]}
        >
          <div className="length-info">D</div>
        </Html>
      ) : null}
      {list}
    </group>
  ) : null;
}

export function Walls(props) {
  const { walls } = props;
  const { width, depth, height2 } = props.length;
  const { int_height2, int_depth, unit, oy } = props.int_length;
  const dh2 = height2 - int_height2;

  return (
    <>
      <group position={[0, -250, ((dh2 + wallTopHeight) * unit) / 2]}>
        {walls.added[2] ? (
          <Wall
            width={width}
            height={height2}
            unit={unit}
            position={[0, (oy - depth) * unit + (wallThickness * unit) / 2, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            ID={2}
          />
        ) : null}
      </group>
      <group
        position={[
          (-width * unit) / 2,
          (oy - depth / 2) * unit,
          ((dh2 + wallTopHeight) * unit) / 2,
        ]}
      >
        {walls.added[1] ? (
          <Wall
            width={depth}
            height={height2}
            unit={unit}
            position={[(-wallThickness * unit) / 2, 0, 0]}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
            ID={1}
          />
        ) : null}
      </group>
      <group
        position={[
          (width * unit) / 2,
          (oy - depth / 2) * unit,
          ((dh2 + wallTopHeight) * unit) / 2,
        ]}
      >
        {walls.added[3] ? (
          <Wall
            width={depth}
            height={height2}
            unit={unit}
            position={[(wallThickness * unit) / 2, 0, 0]}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
            ID={3}
          />
        ) : null}
      </group>
    </>
  );
}

export function asciiDif(a, b) {
  return a.charCodeAt(0) - b.charCodeAt(0);
}

export function isElegantModel(props) {
  // TODO: Elegant and Elegant ST
  return props.modelID === 9 || props.modelID === 20
}

export function Top(props) {
  const { depth, height1, height2 } = props.length;
  const { int_height1, int_height2, int_depth, unit, thickness, column_depth } =
    props.int_length;

  const alpha = CalcRotation(props);
  const ad = (alpha / Math.PI) * 180;
  // if (ad < -3 || ad > 7) return;
  const dh1 = height1 - int_height1;

  const len1 =
    (depth + 220) * (depth + 220) +
    (height2 - height1 - thickness) * (height2 - height1 - thickness);
  const len2 =
    (depth + 220) * (depth + 220) +
    (int_height2 - int_height1 - thickness) *
      (int_height2 - int_height1 - thickness);
  const sd2 = depth / int_depth;
  const sd3 = Math.sqrt(len1 / len2) * (1.004 + (alpha * 180) / Math.PI / 1000);
  const delta2 = isElegantModel(props) ? 0 : Math.min(0, (sd2 - 1) * 100);

  return (
    <group
      position={[0, 2900, 1000 + dh1 * unit]}
      rotation={[-alpha + delta2 * 0.0, 0, 0]}
      scale={[1, sd3, (isElegantModel(props) ? 160 : 160) / 153]}
    >
      <group position={[0, -2900, -1000]}>
        {isElegantModel(props) ? (
          <>
            <TopDivPergola {...props} />
            <TopDivPergolaCap {...props} />
          </>
        ) : (
          <>
            <TopDiv {...props} />
            <TopDivCap {...props} />
          </>
        )}
        <Glass {...props} />
      </group>
    </group>
  );
}

export function AddSubSystems(props) {
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;
  const { columns, isGardes, modelID } = props;
  const bias = isGardes ? 24 : 0;

  const list = [];
  const pos_list = [];

  for (let i = 2; i < 24; i++) {
    if (columns.added[i]) {
      pos_list.push({ pos: columns.pos[i], id: i });
    }
  }
  pos_list.push({ pos: columns.pos[0], id: 0 });
  pos_list.push({ pos: width, id: 0 });
  pos_list.sort(function (a, b) {
    return a.pos - b.pos;
  });

  for (let i = 0; i < pos_list.length - 1; i = i + 1) {
    const swidth = pos_list[i + 1].pos - pos_list[i].pos;
    const px = pos_list[i].pos;
    const ID = pos_list[i].id;
    list.push(
      <Html
        key={i}
        scaleFactor={5}
        position={[
          (width * unit) / 2 - px * unit - (swidth * unit) / 2,
          oy * unit,
          -oz * unit,
        ]}
      >
        <div
          className="side-info side-info-subsystem"
          onPointerOver={(e) => {
            e.preventDefault();
            props.setSelectedSide(1);
            props.setCurrentWidth(swidth);
            props.setCurrentPos(px + swidth / 2);
          }}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            if (
              props.systemID === 6 &&
              columns.addedSubSystem[bias + ID + 2] === true &&
              (columns.systemId[bias + ID + 2] <= 3 ||
                columns.systemId[bias + ID + 2] === 5)
            ) {
              state.columns.addedForte[bias + ID + 2] = true;
              state.columns.fortePos[bias + ID + 2] = default_forte_pos;
            } else {
              state.columns.addedSubSystem[bias + ID + 2] = true;
              state.columns.systemId[bias + ID + 2] = props.systemID;

              // state.columns.addedSubSystem[ID + 2] = true;
              // state.columns.systemId[ID + 2] = props.systemID;

              if (props.systemID === 0) {
                state.columns.systemId[bias + ID + 2 + 8] = props.systemID;
              }
            }

            state.columns.addingSubSystem = false;
            props.setSelectedSide(-1);
          }}
        >
          <BsPlusCircle />
        </div>
      </Html>
    );
  }

  return props.activeStep === 2 && columns.addingSubSystem === true ? (
    <group>
      <Html
        scaleFactor={5}
        position={[(width * unit) / 2, (oy - depth / 2) * unit, -oz * unit]}
      >
        <div
          className="side-info"
          onPointerOver={(e) => {
            props.setSelectedSide(2);
          }}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            e.preventDefault();
            props.setSelectedSide(-1);
            if (
              props.systemID === 6 &&
              columns.addedSubSystem[bias + 1] === true &&
              (columns.systemId[bias + 1] <= 3 ||
                columns.systemId[bias + 1] === 5)
            ) {
              state.columns.addedForte[bias + 1] = true;
              state.columns.fortePos[bias + 1] = default_forte_pos;
            } else {
              state.columns.addedSubSystem[bias + 1] = true;
              state.columns.systemId[bias + 1] = props.systemID;
              if (props.systemID === 0) {
                state.columns.systemId[bias + 1 + 8] = props.systemID;
              }
            }
            state.columns.addingSubSystem = false;
          }}
        >
          {modelID === 16 ? "" : "Left Side"}
          <BsPlusCircle />
        </div>
      </Html>
      <Html
        scaleFactor={5}
        position={[(-width * unit) / 2, (oy - depth / 2) * unit, -oz * unit]}
      >
        <div
          className="side-info"
          onPointerOver={(e) => {
            props.setSelectedSide(4);
          }}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            props.setSelectedSide(-1);
            if (
              props.systemID === 6 &&
              columns.addedSubSystem[bias + 0] === true &&
              (columns.systemId[bias + 0] <= 3 ||
                columns.systemId[bias + 0] === 5)
            ) {
              state.columns.addedForte[bias + 0] = true;
              state.columns.fortePos[bias + 0] = default_forte_pos;
            } else {
              state.columns.addedSubSystem[bias + 0] = true;
              state.columns.systemId[bias + 0] = props.systemID;
              if (props.systemID === 0) {
                state.columns.systemId[bias + 8] = props.systemID;
              }
            }
            state.columns.addingSubSystem = false;
          }}
        >
          {modelID === 16 ? "" : "Right Side"}
          <BsPlusCircle />
        </div>
      </Html>
      {list}
    </group>
  ) : null;
}

export function SideForSubSystem(props) {
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz } = props.int_length;
  const { currentPos, currentWidth } = props;
  if (props.selectedSide === 0) return null;
  switch (props.selectedSide) {
    case 1:
      return (
        <mesh
          position={[
            (width * unit) / 2 - currentPos * unit,
            oy * unit,
            (height1 / 2 - oz) * unit,
          ]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[currentWidth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>
      );
    case 2:
      return (
        <mesh
          position={[
            (width * unit) / 2,
            (oy - depth / 2) * unit,
            (height1 / 2 - oz) * unit,
          ]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>
      );
    case 3:
      return (
        <mesh
          position={[0, (oy - depth) * unit, (height2 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[width * unit, height2 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>
      );
    case 4:
      return (
        <mesh
          position={[
            (-width * unit) / 2,
            (oy - depth / 2) * unit,
            (height1 / 2 - oz) * unit,
          ]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>
      );
    default:
      return null;
  }
}

export function PergolaAvantgarde(props) {
  const { modelID, isGardes } = props;
  const gltf = useLoader(GLTFLoader, avantgardeModel, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/draco-gltf/");
    loader.setDRACOLoader(dracoLoader);
  });
  // const onProgress = (xhr) => {
  //   if (xhr.lengthComputable) {
  //     const newProgress = (xhr.loaded / xhr.total) * 100;
  //     props.setProgress(Math.round(newProgress));
  //   }
  // };

  // const gltf = useLoader(GLTFLoader, avantgardeModel, onProgress);
  // const { progress } = useProgress();
  // const [loaded, setLoaded] = React.useState(false);

  // useEffect(()=>{
  //   setLoaded(true);
  // }, [])

  const { nodes, materials } = gltf;
  // const { nodes, materials } = useGLTF(avantgardeModel)
  const snap = useSnapshot(state);
  const [currentSide, setCurrentSide] = React.useState(0);
  const [currentPos, setCurrentPos] = React.useState(-1);
  const [selectedSide, setSelectedSide] = React.useState(0);
  const [currentWidth, setCurrentWidth] = React.useState(0);

  const ShiftColumn = (column) => {
    state.columns.editing = true;
    state.columns.editingColumn = column;
    state.columns.added[asciiDif(column, "A")] = true;
    state.columns.adding = false;
    setCurrentSide(0);
  };

  const length = {
    ...snap.length,
    depth: modelID === 16 ? (snap.length.depth - 150) / 2 : snap.length.depth,
  };

  // return !loaded?
  //   <ProgressBar value={progress} text={'Loading Model...'} />:
  return (
    <group {...props} dispose={null} ref={props.group}>
      <group
        position={[0, 1.98, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.001}
      >
        <WaterProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />

        <ColumnLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          isGardes={isGardes}
        />
        <ColumnRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          isGardes={isGardes}
        />
        <Columns
          nodes={nodes}
          materials={materials}
          modelID={props.modelID}
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          flag={snap.flag}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <MiddleColumns
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          flag={snap.flag}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <Top
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          glassColor={snap.isDesign ? snap.designStyle : snap.blades.color}
          coverColor={snap.isDesign ? snap.designStyle : snap.structure.color}
          isDesign={snap.isDesign}
          isBladeTexture={snap.isBladeTexture}
          bladeTexture={snap.blades.texture}
          isTexture={snap.isTexture}
          texture={snap.structure.textureA}
          texture2={snap.structure.textureB}
          texture3={snap.structure.textureR}
          opacity={snap.blades.opacity}
          modelID={modelID}
          addedSubSystem={snap.columns.addedSubSystem}
          subSystemCount={snap.columns.subSystemCount}
          systemPos={snap.columns.systemPos}
          systemColor={snap.systemColor}
          visible={snap.hasLight}
          direction={snap.columns.systemDirection[modelID === 16 ? 47 : 23]}
        />
        {/* <ColumnLabels
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
        /> */}
        {/* <LengthInfo
          secondHeight={secondHeight[modelID]}
          length={length}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          modelID={modelID}
        /> */}
        <AddExtraColumns
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          ShiftColumn={ShiftColumn}
          setCurrentSide={setCurrentSide}
        />
        {modelID !== 16 ? (
          <>
            {/* <AddWalls
              walls={snap.walls}
              length={length}
              secondHeight={secondHeight[modelID]}
              int_length={lengths[modelID]}
              activeStep={props.activeStep}
              subStep={props.subStep}
              setCurrentSide={setCurrentSide}
              isGardes={isGardes}
            /> */}
            <Walls
              walls={snap.walls}
              length={length}
              int_length={lengths[modelID]}
            />
          </>
        ) : null}
        {/* {isGardes ?
          <>
            <AddWallsForGardes
              walls={snap.walls}
              length={length}
              secondHeight={secondHeight[modelID]}
              int_length={lengths[modelID]}
              activeStep={props.activeStep}
              subStep={props.subStep}
              setCurrentSide={setCurrentSide}
            />
            <WallsForGardes
              walls={snap.walls}
              length={length}
              int_length={lengths[modelID]}
            />
          </>
          : null} */}
        <Side
          walls={snap.walls}
          columns={snap.columns}
          length={length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          current_side={currentSide}
          isGardes={isGardes}
          modelID={modelID}
        />

        <AddSubSystems
          walls={snap.walls}
          columns={snap.columns}
          length={length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setSelectedSide={setSelectedSide}
          setCurrentPos={setCurrentPos}
          setCurrentWidth={setCurrentWidth}
          systemID={snap.systemID}
          modelID={modelID}
          isGardes={isGardes}
        />
        <SideForSubSystem
          walls={snap.walls}
          columns={snap.columns}
          length={length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          selectedSide={selectedSide}
          currentPos={currentPos}
          currentWidth={currentWidth}
          isGardes={isGardes}
          modelID={modelID}
        />
        <SideInfills4
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          systemID={snap.systemID}
          dy={90}
          dz={0}
          modelID={modelID}
          isTexture={snap.isTexture}
          texture={snap.structure.textureD}
          walls={snap.walls}
          isGardes={isGardes}
        />

        <TriPart
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isDesign={snap.isDesign}
          opacity={snap.blades.opacity}
          modelID={modelID}
          addedSubSystem={snap.columns.addedSubSystem}
          columns={snap.columns}
          isTexture={snap.isTexture}
          texture={snap.structure.textureC}
          walls={snap.walls}
          isGardes={isGardes}
        />

        {/* <PergolaOrgin 
          nodes={nodes}
          materials={materials}
        /> */}
      </group>
    </group>
  );
}
