import { lazy } from "react";
import MainLayout from "layouts/MainLayout";
import Loadable from "components/Loadable";
import AuthGuard from "common/authguard";
import Model from "pages/Model";

const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Logout = Loadable(lazy(() => import("../pages/auth/Logout")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));

const Home = Loadable(lazy(() => import("../pages/Home")));
const Setting = Loadable(lazy(() => import("../pages/Setting")));
const Settings = Loadable(lazy(() => import("../pages/Settings")));
const TempLink = Loadable(lazy(() => import("../pages/TempLink")));
const Orders = Loadable(lazy(() => import("../pages/Orders")));
const Baskets = Loadable(lazy(() => import("../pages/Baskets")));
const History = Loadable(lazy(() => import("../pages/History")));
const FriendHistory = Loadable(lazy(() => import("../pages/FriendHistory")));
const Clients = Loadable(lazy(() => import("../pages/Clients")));
const Employees = Loadable(lazy(() => import("../pages/Employees")));
const Products = Loadable(lazy(() => import("../pages/Products")));
const Optionals = Loadable(lazy(() => import("../pages/Optionals")));
const GlassTypes = Loadable(lazy(() => import("../pages/GlassTypes")));
const Users = Loadable(lazy(() => import("../pages/Users")));
const Payment = Loadable(lazy(() => import("../pages/Payment")));
const Terms = Loadable(lazy(() => import("../pages/Terms")));
const Sections = Loadable(lazy(() => import("../pages/Sections")));
const Textures = Loadable(lazy(() => import("../pages/Textures")));
const WhatsNew = Loadable(lazy(() => import("../pages/WhatsNew")));
const AdminFooter = Loadable(lazy(() => import("../pages/PageManagement")));

const Guillotine = Loadable(lazy(() => import("../pages/Guillotine")));
const PayPal = Loadable(lazy(() => import("../pages/PayPal")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/home",
      element: (
        <AuthGuard>
          <Home />
        </AuthGuard>
      ),
    },
    {
      path: "/setting",
      element: (
        <AuthGuard>
          <Setting />
        </AuthGuard>
      ),
    },
    {
      path: "/settings/:id?",
      element: (
        <AuthGuard>
          <Settings />
        </AuthGuard>
      ),
    },
    {
      path: "/templink",
      element: (
        <AuthGuard>
          <TempLink />
        </AuthGuard>
      ),
    },
    {
      path: "/orders",
      element: (
        <AuthGuard>
          <Orders />
        </AuthGuard>
      ),
    },
    {
      path: "/carts",
      element: (
        <AuthGuard>
          <Baskets />
        </AuthGuard>
      ),
    },
    {
      path: "/history",
      element: (
        <AuthGuard>
          <History />
        </AuthGuard>
      ),
    },
    {
      path: "/friendhistory",
      element: (
        <AuthGuard>
          <FriendHistory />
        </AuthGuard>
      ),
    },
    {
      path: "/clients",
      element: (
        <AuthGuard>
          <Clients />
        </AuthGuard>
      ),
    },
    {
      path: "/employees",
      element: (
        <AuthGuard>
          <Employees />
        </AuthGuard>
      ),
    },
    {
      path: "/users",
      element: (
        <AuthGuard>
          <Users />
        </AuthGuard>
      ),
    },
    {
      path: "/paypal/:amount",
      element: (
        <AuthGuard>
          <PayPal />
        </AuthGuard>
      ),
    },
    {
      path: "/products",
      element: (
        <AuthGuard>
          <Products />
        </AuthGuard>
      ),
    },
    {
      path: "/optionals",
      element: (
        <AuthGuard>
          <Optionals />
        </AuthGuard>
      ),
    },
    {
      path: "/glasstypes",
      element: (
        <AuthGuard>
          <GlassTypes />
        </AuthGuard>
      ),
    },
    {
      path: "/pagemanagement",
      element: (
        <AuthGuard>
          <AdminFooter />
        </AuthGuard>
      ),
    },
    {
      path: "/payment",
      element: (
        <AuthGuard>
          <Payment />
        </AuthGuard>
      ),
    },
    {
      path: "/terms",
      element: (
        <AuthGuard>
          <Terms />
        </AuthGuard>
      ),
    },
    {
      path: "/sections",
      element: (
        <AuthGuard>
          <Sections />
        </AuthGuard>
      ),
    },
    {
      path: "/textures",
      element: (
        <AuthGuard>
          <Textures />
        </AuthGuard>
      ),
    },
    {
      path: "/whatsnew",
      element: (
        <AuthGuard>
          <WhatsNew />
        </AuthGuard>
      ),
    },
    {
      path: "/point",
      element: (
        <AuthGuard>
          <Model modelID={0} />
        </AuthGuard>
      ),
    },
    // {
    //     path: '/cabbana',
    //     element: (
    //         <AuthGuard>
    //             <Model modelID={1} />
    //         </AuthGuard>
    //     )
    // },
    {
      path: "/cabbanas",
      element: (
        <AuthGuard>
          <Model modelID={13} />
        </AuthGuard>
      ),
    },
    {
      path: "/lenore",
      element: (
        <AuthGuard>
          <Model modelID={15} />
        </AuthGuard>
      ),
    },
    {
      path: "/panora",
      element: (
        <AuthGuard>
          <Model modelID={2} />
        </AuthGuard>
      ),
    },
    {
      path: "/panoradouble",
      element: (
        <AuthGuard>
          <Model modelID={8} />
        </AuthGuard>
      ),
    },
    {
      path: "/urban",
      element: (
        <AuthGuard>
          <Model modelID={3} />
        </AuthGuard>
      ),
    },
    {
      path: "/lanai",
      element: (
        <AuthGuard>
          <Model modelID={4} />
        </AuthGuard>
      ),
    },
    {
      path: "/lanais",
      element: (
        <AuthGuard>
          <Model modelID={14} />
        </AuthGuard>
      ),
    },
    {
      path: "/urbanglass",
      element: (
        <AuthGuard>
          <Model modelID={5} />
        </AuthGuard>
      ),
    },
    {
      path: "/grande",
      element: (
        <AuthGuard>
          <Model modelID={6} />
        </AuthGuard>
      ),
    },
    {
      path: "/dynamic",
      element: (
        <AuthGuard>
          <Model modelID={7} />
        </AuthGuard>
      ),
    },
    {
      path: "/pergola",
      element: (
        <AuthGuard>
          <Model modelID={9} />
        </AuthGuard>
      ),
    },
    {
      path: "/pergolaavantgarde",
      element: (
        <AuthGuard>
          <Model modelID={11} />
        </AuthGuard>
      ),
    },
    {
      path: "/avantgardes",
      element: (
        <AuthGuard>
          <Model modelID={16} />
        </AuthGuard>
      ),
    },
    {
      path: "/cabbanan",
      element: (
        <AuthGuard>
          <Model modelID={17} />
        </AuthGuard>
      ),
    },
    {
      path: "/roomy",
      element: (
        <AuthGuard>
          <Model modelID={10} />
        </AuthGuard>
      ),
    },
    {
      path: "/polad",
      element: (
        <AuthGuard>
          <Model modelID={12} />
        </AuthGuard>
      ),
    },
    {
      path: "/guillotine",
      element: (
        <AuthGuard>
          <Guillotine systemID={0} />
        </AuthGuard>
      ),
    },
    {
      path: "/classy",
      element: (
        <AuthGuard>
          <Guillotine systemID={1} />
        </AuthGuard>
      ),
    },
    {
      path: "/minima",
      element: (
        <AuthGuard>
          <Guillotine systemID={2} />
        </AuthGuard>
      ),
    },
    {
      path: "/fixedglass",
      element: (
        <AuthGuard>
          <Guillotine systemID={3} />
        </AuthGuard>
      ),
    },
    {
      path: "/fixedwall",
      element: (
        <AuthGuard>
          <Guillotine systemID={4} />
        </AuthGuard>
      ),
    },
    {
      path: "/liftandslidedoor",
      element: (
        <AuthGuard>
          <Guillotine systemID={5} />
        </AuthGuard>
      ),
    },
    {
      path: "/forte",
      element: (
        <AuthGuard>
          <Guillotine systemID={6} />
        </AuthGuard>
      ),
    },
    {
      path: "/zion",
      element: (
        <AuthGuard>
          <Guillotine systemID={7} />
        </AuthGuard>
      ),
    },
    {
      path: "/bifolddoor",
      element: (
        <AuthGuard>
          <Guillotine systemID={8} />
        </AuthGuard>
      ),
    },
    {
      path: "/capella",
      element: (
        <AuthGuard>
          <Guillotine systemID={9} />
        </AuthGuard>
      ),
    },
    {
      path: "/door",
      element: (
        <AuthGuard>
          <Model modelID={18} />
        </AuthGuard>
      ),
    },
    {
      path: "/cabbanast",
      element: (
        <AuthGuard>
          <Model modelID={19} />
        </AuthGuard>
      ),
    },
    {
      path: "/pergolast",
      element: (
        <AuthGuard>
          <Model modelID={20} />
        </AuthGuard>
      ),
    },
  ],
};

export default MainRoutes;
