import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import divider_panora_cabbana from "../../assets/models/divider_panora_cabbana.glb";

const xrepeat = 0.05,
  yrepeat = 0.05;

export default function DividerMiddle(props) {
  const { nodes, materials } = useGLTF(divider_panora_cabbana);
  const { color, isTexture } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, top_div_length, unit, mid_length_limit } =
    props.int_length;
  const sd = (depth + 50) / top_div_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const texture = useTexture(props.texture);
  texture.rotation = Math.PI;
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  if (props.doubleSystem) {
    if (props.origin && (!props.systemVisible[0] || !props.systemVisible[1]))
      return null;
    if (!props.origin && (!props.systemVisible[2] || !props.systemVisible[3]))
      return null;
  }

  // return mid_length_limit[0] < width ? (
  //   <group dispose={null} position={[width * unit / 2 - dw * unit / 2, (height1 - 150) * unit, -depth * unit / 2 + dd * unit]}>
  //     <group rotation={[-Math.PI / 2, 0, 0]} scale={[1, sd, 3]}>
  //       <mesh castShadow geometry={nodes.mesh_0.geometry} material={isTexture ? material_texture : materials.center} material-color={color} />
  //     </group>
  //   </group>
  // ) : null
  if (
    (props.modelID == 15 &&
      mid_length_limit[0] < width &&
      width < mid_length_limit[1]) ||
    (props.modelID != 15 && mid_length_limit[0] < width)
  ) {
    return (
      <group
        dispose={null}
        position={[
          (width * unit) / 2 - (dw * unit) / 2,
          (height1 - 150) * unit,
          (-depth * unit) / 2 + dd * unit,
        ]}
      >
        <group rotation={[-Math.PI / 2, 0, 0]} scale={[1, sd, 3]}>
          <mesh
            castShadow
            geometry={nodes.mesh_0.geometry}
            material={isTexture ? material_texture : materials.center}
            material-color={color}
          />
        </group>
      </group>
    );
  } else if (props.modelID == 15 && width >= mid_length_limit[1]) {
    const dividerCount = 2; // Number of dividers
    const dividerWidth = width / (dividerCount + 1); // Width of each divider

    const dividers = [];
    for (let i = 0; i < dividerCount; i++) {
      const xPos = (i + 1) * dividerWidth;
      const dividerPosition = [
        xPos * unit - (dw * unit) / 2,
        (height1 - 150) * unit,
        (-depth * unit) / 2 + dd * unit,
      ];

      dividers.push(
        <group key={i} dispose={null} position={dividerPosition}>
          <group rotation={[-Math.PI / 2, 0, 0]} scale={[1, sd, 3]}>
            <mesh
              castShadow
              geometry={nodes.mesh_0.geometry}
              material={isTexture ? material_texture : materials.center}
              material-color={color}
            />
          </group>
        </group>
      );
    }

    return <group>{dividers}</group>;
  } else {
    return null;
  }
}

// useGLTF.preload('models/divider_panora_cabbana.glb')
