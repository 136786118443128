import React, { useEffect } from "react";
import { useTexture, useProgress } from "@react-three/drei";
import { useSnapshot } from "valtio";
import { lengths, columnFlag } from "../../utils/constant";
import state from "../../state";
import * as THREE from "three";
import TopLights from "../cabbana/TopLights";
import DividerMiddle1 from "../cabbana/Divider1";
import { secondHeight } from "../../utils/constant";
import {
  WaterProfile,
  BackProfile,
  LeftProfile,
  RightProfile,
  ColumnLeft,
  ColumnBackLeft,
  ColumnRight,
  ColumnBackRight,
  Columns,
  TopDiv,
  TopDiv3,
  CapLeft,
  CapRight,
  BackLeft,
  BackRight,
  FootLeft,
  FootRight,
  FootBackLeft,
  FootBackRight,
  Foots,
  AddExtraColumns,
  AddWalls,
  LengthInfo,
  ColumnLabels,
  Walls,
  Side,
  SideForSubSystem,
  AddSubSystems,
  CalcRotation,
  CalcScale,
  VisibleSystem,
} from "../cabbana/CabbanaN";
import GetLogo from "../cabbana/GetLogo";
import MiddleProfile1 from "../cabbana/MiddleProfile1";
import SideInfills6 from "../SideInfills6";
import cabbana from "../../assets/models/cabbana_new_1.glb";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from "@react-three/fiber";
import ProgressBar from "../../common/Loading/ProgressBar";

const xrepeat = 1;
const yrepeat = 1;

export function Glass(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, int_div_width, top_glass_width, unit } =
    props.int_length;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(1, 1);
  const dh1 = height1 - int_height1;
  const div_count = parseInt(width / int_div_width);
  const div_width = width / div_count;
  const px = -20000;
  const py = -13513.99;
  const pz = 8122.18;
  const list = [];
  const alpha = CalcRotation(props);
  const rotation = [1.7 + alpha, 0, 0];
  const sd2 = CalcScale(props);
  const scale = [(10 * div_width) / top_glass_width, 10, 10 * sd2];

  for (var i = 0; i < div_count; i = i + 1) {
    list.push(
      <group
        position={[
          (-(width - int_width) * unit) / 2 + div_width * i * unit,
          0,
          0,
        ]}
      >
        {props.isTexture && !props.isDesign ? (
          <mesh
            castShadow
            geometry={nodes.glass_1.geometry}
            material={materials.glass}
            position={[px, py, pz]}
            rotation={rotation}
            scale={scale}
          >
            <meshPhysicalMaterial
              transparent
              ditherTransparent
              thickness={1}
              opacity={props.opacity}
              reflectivity={0.3}
              map={texture}
              castShadow
            />
          </mesh>
        ) : (
          <mesh
            castShadow
            geometry={nodes.glass_1.geometry}
            material={materials.glass}
            position={[px, py, pz]}
            rotation={rotation}
            scale={scale}
            material-color={color}
          />
        )}
      </group>
    );
  }
  return <group position={[0, 0, dh1 * unit]}>{list}</group>;
}

export function Lights(props) {
  const { nodes, materials, visible } = props;
  const list = [];
  const px = 242.53;
  const py = 236.52;
  const pz = -10.23;

  for (var i = 1; i < 2; i = i + 1) {
    list.push(
      <group position={[px, py, pz]}>
        <mesh geometry={nodes.mesh_9_105.geometry} material={materials.Light} />
      </group>
    );
  }
  return visible ? <group>{list}</group> : null;
}

export function Lanai(props) {
  const gltf = useLoader(GLTFLoader, cabbana, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/draco-gltf/");
    loader.setDRACOLoader(dracoLoader);
  });
  // const onProgress = (xhr) => {
  //   if (xhr.lengthComputable) {
  //     const newProgress = (xhr.loaded / xhr.total) * 100;
  //     props.setProgress(Math.round(newProgress));
  //   }
  // };

  // const gltf = useLoader(GLTFLoader, cabbana, onProgress);
  // const { progress } = useProgress();
  // const [loaded, setLoaded] = React.useState(false);

  // useEffect(()=>{
  //   setLoaded(true);
  // }, [])

  const { nodes, materials } = gltf;
  // const { nodes, materials } = useGLTF(cabbana)
  const snap = useSnapshot(state);
  const [currentSide, setCurrentSide] = React.useState(0);
  const [currentColumn, setCurrentColumn] = React.useState(-1);
  const { modelID, viewMode } = props;
  const rdepth = (snap.length.depth + lengths[modelID].column_thickness) / 2;

  const length = {
    ...snap.length,
    depth: props.doubleSystem ? rdepth : snap.length.depth,
  };

  const isLarge =
    props.doubleSystem &&
    snap.length.width > lengths[modelID].mid_length_limit[0];

  // return !loaded?
  //   <ProgressBar value={progress} text={'Loading Model...'} />:
  return (
    <group {...props} dispose={null} ref={props.group}>
      <group scale={1} position={[0, 0, (-rdepth * lengths[modelID].unit) / 2]}>
        <WaterProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <BackProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <LeftProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <RightProfile
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <MiddleProfile1
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.textureB}
          xrepeat={xrepeat}
          yrepeat={yrepeat}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
          modelID={modelID}
        />
        <ColumnLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <ColumnRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <ColumnBackLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <ColumnBackRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <Columns
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          flag={snap.flag}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        {modelID === 14 ? (
          <TopDiv3
            nodes={nodes}
            materials={materials}
            length={length}
            int_length={lengths[modelID]}
            color={snap.blades.color}
            middleColor={
              snap.isDesign ? snap.designStyle : snap.structure.color
            }
            visible={snap.hasLight}
            blades={snap.blades}
            bladeRotation={snap.blades.rotation}
            isTexture={snap.isBladeTexture}
            isMiddleTexture={snap.isTexture}
            texture={snap.blades.texture}
            structure_texture={snap.structure.textureB}
            opacity={snap.blades.opacity}
            modelID={modelID}
            doubleSystem={props.doubleSystem}
            origin={props.origin}
            systemVisible={snap.systemVisible}
            isLarge={isLarge}
            bladeType={snap.blades.type}
          />
        ) : (
          <TopDiv
            nodes={nodes}
            materials={materials}
            length={length}
            int_length={lengths[modelID]}
            color={snap.blades.color}
            middleColor={
              snap.isDesign ? snap.designStyle : snap.structure.color
            }
            visible={snap.hasLight}
            blades={snap.blades}
            bladeRotation={snap.blades.rotation}
            isTexture={snap.isBladeTexture}
            isMiddleTexture={snap.isTexture}
            texture={snap.blades.texture}
            structure_texture={snap.structure.textureB}
            opacity={snap.blades.opacity}
            modelID={modelID}
            doubleSystem={props.doubleSystem}
            origin={props.origin}
            systemVisible={snap.systemVisible}
            isLarge={isLarge}
            bladeType={snap.blades.type}
          />
        )}

        <CapLeft
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <CapRight
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <BackLeft
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <BackRight
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <FootLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <FootRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <FootBackLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <FootBackRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <GetLogo
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
          modelID={modelID}
        />

        <Foots
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <ColumnLabels
          columns={snap.columns}
          length={length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
        />
        {/* <LengthInfo
          secondHeight={secondHeight[modelID]}
          length={length}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
        /> */}
        <AddExtraColumns
          columns={snap.columns}
          length={length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setCurrentSide={setCurrentSide}
        />
        <AddWalls
          walls={snap.walls}
          length={snap.length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setCurrentSide={setCurrentSide}
          origin={props.origin}
        />
        {props.origin ? (
          <Walls
            walls={snap.walls}
            length={snap.length}
            int_length={lengths[modelID]}
          />
        ) : null}
        <Side
          columns={snap.columns}
          walls={snap.walls}
          length={snap.length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          current_side={currentSide}
        />
        <TopLights
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          visible={snap.hasLedLight}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
          modelID={modelID}
        />
        <DividerMiddle1
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.textureA}
          xrepeat={xrepeat}
          yrepeat={yrepeat}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
          modelID={modelID}
        />
        <SideInfills6
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          systemID={snap.systemID}
          modelID={modelID}
          isTexture={snap.isTexture}
          texture={snap.structure.textureD}
          texture1={snap.structure.textureC}
          doubleSystem={props.doubleSystem}
          origin={props.origin}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
          walls={snap.walls}
        />
        <AddSubSystems
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setCurrentColumn={setCurrentColumn}
          systemID={snap.systemID}
          doubleSystem={props.doubleSystem}
          origin={props.origin}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <SideForSubSystem
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          activeStep={props.activeStep}
          subStep={props.subStep}
          currentColumn={currentColumn}
          doubleSystem={props.doubleSystem}
          origin={props.origin}
          systemVisible={snap.systemVisible}
          isLarge={isLarge}
        />
        <VisibleSystem
          length={length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          activeStep={props.activeStep}
          subStep={props.subStep}
          currentColumn={currentColumn}
          doubleSystem={props.doubleSystem}
          origin={props.origin}
          modelID={modelID}
          systemVisible={snap.systemVisible}
          viewMode={viewMode}
        />
      </group>
    </group>
  );
}

// useGLTF.preload('models/cabbana.glb')
