import { useState, useEffect, useContext } from 'react';

import { API_URL, axiosInstancePublic } from '../utils/constant';
import axios from 'axios';
import { toast } from 'react-toastify';
import AppContext from '../context/AppContext';

export function GetEmbedKey(embed_key) {
    const [users, setUsers] = useState(null);
    const {setBrandColor, setShowprice} = useContext(AppContext);
    const isValid = () => {
        const finded_user = users.filter(user=>user.embed_key === embed_key);
        if (finded_user.length > 0) {
          localStorage.setItem('api_url', finded_user[0].extra_link);
          localStorage.setItem('thanks', finded_user[0].thanks);
          localStorage.setItem('company_logo', finded_user[0].company_logo);
          localStorage.setItem('user_id', finded_user[0].user_id);
          localStorage.setItem('id', finded_user[0].id);
          setBrandColor(finded_user[0].brand_color);
          setShowprice(finded_user[0].showprice === 1 ? 1:0);
          localStorage.setItem('embed_key', embed_key);
          localStorage.setItem('first_name', finded_user[0].first_name);
          localStorage.setItem('last_name', finded_user[0].last_name);
          localStorage.setItem('benefit', finded_user[0].benefit);
          localStorage.setItem('discount', finded_user[0].discount);
          localStorage.setItem('user_install', finded_user[0].install);
          localStorage.setItem('embed_user', JSON.stringify(finded_user[0]));
          if (!finded_user[0].unit || finded_user[0].unit === null || finded_user[0].unit == 0) localStorage.setItem('unit', 'inch');
          else localStorage.setItem('unit', 'mm');
          if (finded_user[0].company_logo === null || finded_user[0].company_logo === undefined) localStorage.setItem('logo_option', '1');
          else localStorage.setItem('logo_option', finded_user[0].logo_option);
          localStorage.setItem('mode', 'embed');
        }
        return finded_user.length>0?true:false;
    }

    const getUsers = () => {
      axiosInstancePublic
          .post(API_URL + "auth/findUser", { embed_key })
          .then(response => {
            setUsers(response.data.user ? [response.data.user] : []);
            return response.data.users;
          })
          .catch(err => {
            console.log(err);
            if (err.message === 'Network Error') {
              toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          })
      }

    /* eslint-disable */
    useEffect(() => {
        getUsers();
    }, [])
    /* eslint-enable */

    return users === null?false:isValid();
}
