import React from 'react'

import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Input from '@mui/material/Input'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useSnapshot } from 'valtio'
import state from '../../state'

const CustomInput = (props) => {

  const snap = useSnapshot(state);

  const {
    type,
    label,
    value,
    min,
    max,
    unit,
    unitName,
    handleSliderChange,
    handlePlusChange,
    handleMinusChange,
  } = props

  const [val, setVal] = React.useState(parseInt(value * unit + 0.5));

  const handleChange = (event) => {
    handleSliderChange(event, event.target.value / unit);
    setVal(event.target.value)
  }

  const InputValidation = (event) => {
    const curVal = val / unit;
    const newVal = curVal < min ? min : (curVal > max ? max : curVal);
    setVal(parseInt(newVal * unit + 0.5));
    handleSliderChange(event, newVal);
  }

  React.useEffect(()=>{
    setVal(parseInt(value * unit + 0.5))
  },[snap.length])


  return (
    <Box sx={{ mt: 2.5 }}>
      <Typography>{label}</Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <IconButton sx={{ ml: { md: -1, xs: 0 } }} onClick={() => handleMinusChange(type)}>
          <RemoveIcon />
        </IconButton>
        <Typography component={'span'} variant={'body2'}>
          <Input 
            value={val}
            onChange={handleChange}
            className="dimension-input"
            disableUnderline={true}
            onBlur={e=>{e.preventDefault(); InputValidation(e); }}
            onKeyDown={e=>{
              if (e.key === 'Enter') {InputValidation(e);}
            }}
          />
          {unitName}
        </Typography>
        <IconButton sx={{ mr: { md: -1, xs: 0 } }} onClick={() => handlePlusChange(type)}>
          <AddIcon />
        </IconButton>
      </Stack>
      <Stack>
        <Slider
          size="small"
          color="primary"
          value={typeof value === 'number' ? value : min}
          onChange={handleSliderChange}
          min={min}
          max={max}
          sx={{ color: 'darkgray', width: 'calc(100% - 12px)', mx: 'auto' }}
        />
        <Stack direction="row" justifyContent="space-between">
          <Typography>{parseInt(min * unit + 0.5)}</Typography>
          <Typography>{parseInt(max * unit + 0.5)}</Typography>
        </Stack>
      </Stack>
    </Box>
  )
}

export default CustomInput
