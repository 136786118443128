import axios from "axios";
import { API_URL, axiosInstanceAuthorized, axiosInstancePublic } from '../utils/constant';
import authHeader, { setAuthHeader } from "./auth-header";

class AuthService {
  login(email, password) {
    const data = {
      email: email,
      password: password
    };
    return axiosInstancePublic
      .post(API_URL + "auth/signin", data)
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
          setAuthHeader(authHeader()['Authorization']);
        }
        return response.data;
      });
  }

  forgotPassword(email) {
    const data = {
      email: email,
    };
    return axiosInstancePublic
      .post(API_URL + "auth/forgotPassword", data)
      .then(response => {
        return response.data;
      });
  }


  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("profile_popup");
  }

  register(username, email, password) {
    return axiosInstancePublic.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  async check() {
    try {
      const resp = await axiosInstanceAuthorized.get(API_URL + 'auth/test');
      return resp.status === 200;
    } catch (err) {
      return false;
    }
  }
}

export default new AuthService();