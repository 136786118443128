import React from 'react'
import { useTexture } from '@react-three/drei'
import { useSnapshot } from "valtio"
import { lengths } from '../../utils/constant';
import state from "../../state"
import * as THREE from 'three'
// import { xrepeat, yrepeat } from '../../utils/constant';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber';

import roomyModel from '../../assets/models/RoomyCenter.glb'
import { RoomyCenter } from './RoomyCenter';

const xrepeat = 0.1;
const yrepeat = 0.1;

function asciiDif(a, b) {
    return a.charCodeAt(0) - b.charCodeAt(0);
}

function CenterProfile(props) {
    const { nodes, materials, color, modelID } = props;
    const { width, depth, height1, height2 } = props.length;
    const { center_height1, center_height2, center_depth, center_width } = props.int_length;
    const sw = width / center_width;
    const dw = width - center_width;
    const dh2 = height2 - center_height2;
    const sh2 = height2 / center_height2;
    const texture = useTexture(props.texture);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(xrepeat, yrepeat);
    const material_texture = new THREE.MeshPhysicalMaterial({
        map: texture,
    });
    return (
        <group
            scale={1}
            position={[-width / 2 * 0.01, 0, -0.75]}
        >
            <group scale={[sw, 1, 1]} position={[0, dh2 * 0.01, 0]}>
                <RoomyCenter
                    color={color}
                    isTexture={props.isTexture}
                    texture={props.textureR}
                    textureC={props.textureC}
                />
            </group>
            {/* <group scale={1} position={[0, dh1 * 0.01, 0]}>
            <group position={[dw * 0.01, 0, 0]}>
                <mesh geometry={nodes.horizontal4.geometry} material={materials.profile} position={[36.78, 26.93, 1.5]} scale={[0.01, 0.01, 0.01 * sd]} />
                <mesh geometry={nodes.horizontal1.geometry} material={materials.profile} position={[36.78, 26.93, 0]} scale={[0.01, 0.01, 0.01 * sd]} />
            </group>
            <group>
                <mesh geometry={nodes.horizontal2.geometry} material={materials.profile} position={[0.63, 26.93, 0]} scale={[0.01, 0.01, 0.01 * sd]} />
                <mesh geometry={nodes.horizontal3.geometry} material={materials.profile} position={[0.63, 26.93, 1.5]} scale={[0.01, 0.01, 0.01 * sd]} />
            </group>
        </group> */}
            <group scale={[1, sh2, 1]}>
                <mesh castShadow material-color={color} geometry={nodes.verticalcenter2.geometry} material={props.isTexture ? material_texture : materials.profile} position={[0.75, 0, 0.75]} scale={0.01} />
            </group>
            {width > 6000 ? <group position={[(width - 150) * 0.01 / 2, 0, 0]} scale={[1, sh2, 1]}>
                <mesh castShadow material-color={color} geometry={nodes.verticalcenter2.geometry} material={props.isTexture ? material_texture : materials.profile} position={[0.75, 0, 0.75]} scale={0.01} />
            </group> : null}
            <group position={[dw * 0.01, 0, 0]} scale={[1, sh2, 1]}>
                <mesh castShadow material-color={color} geometry={nodes.verticalcenter1.geometry} material={props.isTexture ? material_texture : materials.profile} position={[36.66, 0, 0.75]} scale={0.01} />
            </group>
        </group>
    )
}

export function Roomy(props) {
    const modelID = props.modelID;
    const gltf = useLoader(GLTFLoader, roomyModel, loader => {
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('/draco-gltf/')
        loader.setDRACOLoader(dracoLoader)
    })

    const { nodes, materials } = gltf;
    // const { nodes, materials } = useGLTF(roomyModel);
    const snap = useSnapshot(state);

    return (
        <group {...props} dispose={null} ref={props.group}>
            <group position={[0, 0, 0]} rotation={[0, 0, 0]} scale={1}>
                <CenterProfile
                    nodes={nodes}
                    materials={materials}
                    int_length={lengths[modelID]}
                    modelID={modelID}
                    length={snap.length}
                    color={snap.structure.color}
                    isTexture={snap.isTexture}
                    texture={snap.structure.textureR}
                    textureR={snap.structure.textureB}
                    textureC={snap.structure.textureC}
                />
            </group>
        </group>
    )
}
