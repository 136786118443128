import * as React from 'react';
import { useParams } from "react-router-dom";
import { API_URL, axiosInstanceAuthorized } from "utils/constant"
import axios from "axios"
import ReactQuill from 'react-quill';

export default function ProductInfo(props) {

    // const { id } = useParams();
    const [info, setInfo] = React.useState('');

    const getProduct = () => {
        axiosInstanceAuthorized.get(API_URL + 'product/get')
        .then(response=>{
            const products = response.data.products;
            const finded_product = products.filter(product=>product.id == props.id);
            if (finded_product.length > 0) setInfo(finded_product[0].extra_body)
        })
    }

    React.useEffect(()=>{
        getProduct();
    })


    return (
        <ReactQuill
            readOnly={true}
            modules={{ toolbar: false }}
            value={info}
        />
    )
}
