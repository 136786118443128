import React, { useState, useContext } from "react";

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import CheckIcon from '@mui/icons-material/Check';
import { useMediaQuery } from "@mui/material";
import state from "../state";
import { SERVER_URL } from "utils/constant";
import AppContext from 'context/AppContext';

const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : JSON.parse(localStorage.getItem('embed_user'));

const OptionSelect = (props) => {

const {brandColor} = useContext(AppContext);

const changeOption = (value, title, opacity, image, isTexture) => {

  if (image !== null) {
    state.isBladeTexture = true;
    state.blades.texture = SERVER_URL + image;
  }
  else {
    state.isBladeTexture = false;
  }
  // state.blades.texture = value;
  state.blades.textureType = title;
  state.blades.opacity = opacity;
  state.blades.colorName = title;
}

  const isMobile = useMediaQuery('(max-width:769px)')

  return (
      <Button
        variant='contained'
        size="large"
        sx={{
          mb: 0.5,
          width: '100%',
          borderRadius: .5,
          color: props.selected ? "#fff" : "#232323",
          bgcolor: props.selected ? (brandColor !== null && brandColor !== 'null'?brandColor:'#7AAE75') : "#F6F6F6",
          justifyContent: 'flex-start',
          textTransform: 'capitalize',
          '&:hover': { bgcolor: props.selected ? (brandColor !== null && brandColor !== 'null'?brandColor:'#7AAE75') : "#F6F6F6", }
        }}
        // startIcon={<CheckIcon />}
        onClick={() => changeOption(props.value, props.title, props.opacity, props.image, props.isTexture)}
      >
        <Box sx={{
          float: 'right',
          mr: 1,
          width: '20px',
          height: '20px',
          }}>
          {props.value !==null && props.value !== 'icons/undefined'?<img src={SERVER_URL + props.value} alt="icon" />:null}
        </Box>
        {props.title}
      </Button>
  );
}

export default OptionSelect