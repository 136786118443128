import * as React from 'react';
import { motion } from "framer-motion"
import Drawer from '@mui/material/Drawer';


export const Opeartion = ({ children, side = 'left', open, handler, width = 20, isCart }) => {

  const onClose = () => handler(!open);
  const widthByPercent = (percent)=> window.innerWidth * percent / 100;

  return (
        <motion.div>
            <Drawer anchor={side} open={open} onClose={onClose}>
                <div style={{ width: widthByPercent(width), padding: isCart === true?'20px':'0px' }}>{children}</div>
            </Drawer>
        </motion.div>
  );
};
