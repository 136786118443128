/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import logo from '../../assets/models/logo.for.preloader.glb'

import React from 'react'
import { useGLTF } from '@react-three/drei'

export default function Preloader(props) {
  const { nodes, materials } = useGLTF(logo)
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.logo.geometry} material={materials.logo} scale={0.006} />
    </group>
  )
}

// useGLTF.preload('models/logo.for.preloader.glb')
