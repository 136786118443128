import React, {useEffect} from 'react'
import { useTexture, useProgress } from '@react-three/drei'
import { useLoader } from '@react-three/fiber'; 
import { useSnapshot } from "valtio"
import { lengths, columnFlag } from '../../utils/constant';
import state from "../../state"
import { Html } from "@react-three/drei";
import { BsPlusCircle } from "react-icons/bs";
import * as THREE from 'three'
import { Logo } from './Logo';
import { wallThickness, wallTopHeight, secondHeight } from '../../utils/constant';
import Wall from '../Wall';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import pointModel from '../../assets/models/point.glb'
import { TopDivCount } from '../DivCount';
import ProgressBar from '../../common/Loading/ProgressBar';

const xrepeat = 1, yrepeat = 1;

export const CalcRotation = (props) => {
  const { depth, height1, height2 } = props.length;
  const { int_depth, int_height1, int_height2, thickness } = props.int_length;
  const alpha1 = Math.atan(Math.abs(height2 - height1 - thickness) / (depth - thickness));
  const alpha2 = Math.atan(Math.abs(int_height2 - int_height1 - thickness) / (int_depth - thickness));
  if (height1 > height2 - thickness) return - (alpha1 + alpha2);
  return alpha1 - alpha2;
}

export const CalcScale = (props) => {
  const { depth, height1, height2 } = props.length;
  const { int_depth, int_height1, int_height2, column_thickness } = props.int_length;
  const h1 = Math.abs(height1 - height2);
  const h2 = Math.abs(int_height1 - int_height2);
  const l1 = (h1 * h1 + (depth) * (depth - 400));
  const l2 = (h2 * h2 + (int_depth) * (int_depth - 400));
  return Math.sqrt(l1 / l2);
}

export function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export function invertColor(hex) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
}


export function WaterProfile(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dh1 = height1 - int_height1;
  const sw = width / int_width;

  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( xrepeat, yrepeat );
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  return (
    <group
      position={[0, 0, dh1 * unit]}
      scale={[sw, 1, 1]}
    >
      <mesh castShadow geometry={nodes.water_profile.geometry} material={props.isTexture?material_texture:materials.cover} position={[-2.53, -14224.6, 7541.5]} rotation={[Math.PI / 2, 0, 0]} scale={10} material-color={color} />
    </group>
  )
}

export function BackProfile(props) {
  const { nodes, materials, color } = props;
  const { width, depth, height2 } = props.length;
  const { int_width, int_depth, int_height2, unit } = props.int_length;
  const dd = depth - int_depth;
  const dh2 = height2 - int_height2;
  const sw = width / int_width;
  const alpha = CalcRotation(props);
  const rotation = [Math.PI / 2 + alpha, 0, 0];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( xrepeat, yrepeat );
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  return (
    <group
      scale={[sw, 1, 1]}
      position={[0, dd * unit, dh2 * unit]}
    >
      <mesh castShadow geometry={nodes.back_profile.geometry} material={props.isTexture?material_texture:materials.cover} position={[-3.68, 14885.38, 12222.2]} rotation={rotation} scale={10} material-color={color} />
    </group>
  )
}

export function ColumnLeft(props) {
  const { nodes, materials, color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const sh1 = height1 / int_height1;
  const scale = [10, 10 * sh1, 10]
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( xrepeat, yrepeat );
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  return (
    <group
      position={[-dw * unit / 2 + columns.pos[1] * unit, 0, 0]}
    >
      <mesh castShadow geometry={nodes.column_left.geometry} material={props.isTexture?material_texture:materials.cover} position={[-19502.65, -14162.7, -13005.71]} rotation={[Math.PI / 2, 0, 0]} scale={scale} material-color={color} />
    </group>
  )

}

export function ColumnRight(props) {
  const { nodes, materials, color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const sh1 = height1 / int_height1;
  const scale = [10, 10 * sh1, 10]
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( xrepeat, yrepeat );
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  return (
    <group
      position={[dw * unit / 2 - columns.pos[0] * unit, 0, 0]}
    >
      <mesh castShadow geometry={nodes.column_right.geometry} material={props.isTexture?material_texture:materials.cover} position={[19497.58, -14162.7, -13005.71]} rotation={[Math.PI / 2, 0, 0]} scale={scale} material-color={color} />
    </group>
  )

}

export function Columns(props) {
  const { nodes, materials, color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit, column_thickness } = props.int_length;
  const dw = width - int_width;
  const sh1 = height1 / int_height1;
  const scale = [10, 10 * sh1, 10]
  const px2 = 19497.58;
  const py = -14162.7;
  const pz = -13005.71;
  const list = [];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( xrepeat, yrepeat );
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  for (var i = 2; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      list.push(
        <mesh key={i} castShadow geometry={nodes.column_left.geometry} material={props.isTexture?material_texture:materials.cover} position={[dw * unit / 2 + px2 - columns.pos[i] * unit + column_thickness * unit / 2, py, pz]} rotation={[Math.PI / 2, 0, 0]} scale={scale} material-color={color} />
      )
    }
  }
  return (
    <group>
      {list}
    </group>
  )

}


export function Glass(props) {

  const { nodes, materials } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, int_div_width, top_glass_width, max_div_width, unit, mid_length_limit } = props.int_length;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(1, 1);
  // texture.rotation = Math.PI / 2;
  const dh1 = height1 - int_height1;
  const tmp_count = parseInt(width / int_div_width);
  const tmp_width = width / tmp_count;
  // const div_count = tmp_width > max_div_width ? tmp_count + 1 : tmp_count
  var div_count = 0;
  if (props.modelID > 0) {
    if (width >= mid_length_limit[0]) div_count = 2 * TopDivCount((width) / 2, props.modelID);
    if (width >= mid_length_limit[1]) div_count = 3 * TopDivCount((width) / 3, props.modelID);
    if (width < mid_length_limit[0]) div_count = TopDivCount(width, props.modelID);
  }
  else {
    div_count = tmp_width > max_div_width ? tmp_count + 1 : tmp_count
  }

  const div_width = width / div_count;
  const px = -20000;
  const py = -13513.99;
  const pz = 8122.18;
  const list = [];
  const alpha = CalcRotation(props);
  const rotation = [1.7 + alpha, 0, 0];
  const sd2 = CalcScale(props);
  const scale = [10 * div_width / top_glass_width, 10, 10 * sd2];

  for (var i = 0; i < div_count; i = i + 1) {
    list.push(
      <group
        key={i}
        position={[-(width - int_width) * unit / 2 + div_width * i * unit, 0, 0]}
      >
        {props.texture ?
          <mesh castShadow geometry={nodes.glass_1.geometry} material={materials.glass} position={[px, py, pz]} rotation={rotation} scale={scale}>
            {props.isTexture?
            <meshPhysicalMaterial
              transparent
              ditherTransparent
              thickness={1}
              opacity={props.opacity}
              reflectivity={0.3}
              map={texture}
              castShadow
            />:
            <meshBasicMaterial
              transparent
              ditherTransparent
              thickness={1}
              opacity={props.opacity}
              reflectivity={0.3}
              // map={texture}
              castShadow
            />
            }
          </mesh>
          :null
          // <mesh castShadow geometry={nodes.glass_1.geometry} material={materials.glass} position={[px, py, pz]} rotation={rotation} scale={scale} material-color={color} transparent opacity={props.opacity}/>
        }
      </group>
    )
  }
  return (
    <group
      position={[0, 0, dh1 * unit]}
    >
      {list}
    </group>
  )

}

export function TopDiv(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, int_div_width, max_div_width, unit, mid_length_limit } = props.int_length;
  const dw = width - int_width;
  const dh1 = height1 - int_height1;
  const list = [];
  const tmp_count = parseInt(width / int_div_width);
  const tmp_width = width / tmp_count;

  // const div_count = tmp_width > max_div_width ? tmp_count + 1 : tmp_count
  var div_count = 0;
  if (props.modelID > 0) {
    // var tmp = 0;
    // for (var i = 2; i < 20; i = i + 1) {
    //   if (props.addedSubSystem[i] || props.deletedSubSystem[i]) tmp = tmp + props.subSystemCount[i];
    // }
    // div_count =tmp;
    if (width >= mid_length_limit[0]) div_count = 2 * TopDivCount((width) / 2, props.modelID);
    if (width >= mid_length_limit[1]) div_count = 3 * TopDivCount((width) / 3, props.modelID);
    if (width < mid_length_limit[0]) div_count = TopDivCount(width, props.modelID);
  }
  else {
    div_count = tmp_width > max_div_width ? tmp_count + 1 : tmp_count
  }
  const div_width = width / div_count;
  const px = -20000 - dw * unit / 2;
  const py = -13439.17;
  const pz = 7649.58;
  const alpha = CalcRotation(props);
  const sd2 = CalcScale(props);
  const scale = [10, 10, 10 * sd2];
  const rotation = [1.73 + alpha, 0, 0];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( xrepeat, yrepeat );
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  list.push(
    <group key={-1} position={[-dw * unit / 2, 0, 0]}>
      <mesh castShadow geometry={nodes.top_div_left.geometry} material={props.isTexture?material_texture:materials.cover} position={[-19729.91, py, pz]} rotation={rotation} scale={scale} material-color={color} />
    </group>
  )
  list.push(
    <group key={0} position={[dw * unit / 2, 0, 0]}>
      <mesh castShadow geometry={nodes.top_div_last.geometry} material={props.isTexture?material_texture:materials.cover} position={[19722.52, py, pz]} rotation={rotation} scale={scale} material-color={color} />
    </group>
  )

  for (var i = 1; i < div_count; i = i + 1) {
    list.push(
      <mesh key={i} castShadow geometry={nodes.top_div_last.geometry} material={props.isTexture?material_texture:materials.cover} position={[px + i * div_width * unit, py, pz]} rotation={rotation} scale={scale} material-color={color} />
    )
  }
  return (
    <group
      position={[0, 0, dh1 * unit]}
    >
      {list}
    </group>
  )

}

export function LightsForUrban(props) {
  const { nodes, materials, visible } = props;
  const { width, depth, height1, height2 } = props.length;
  const { int_width, int_depth, int_height1, int_height2, int_div_width, mid_length_limit, unit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  const dh2 = height2 - int_height2;
  const list = [];
  const tmp_count = parseInt(width / int_div_width);
  const tmp_width = width / tmp_count;
  var div_count = 0;
  if (width >= mid_length_limit[0]) div_count = 2 * TopDivCount((width) / 2, props.modelID);
  if (width >= mid_length_limit[1]) div_count = 3 * TopDivCount((width) / 3, props.modelID);
  if (width < mid_length_limit[0]) div_count = TopDivCount(width, props.modelID);
  const div_width = width / div_count;
  const px = -20000 - dw * unit / 2;
  const py = 705.91;
  const pz = 9303.8;
  const alpha = CalcRotation(props);
  const rotation = [Math.PI / 2 + alpha, 0, 0];

  for (var i = 1; i < div_count; i = i + 1) {
    list.push(
      <>
      <mesh key={i} castShadow geometry={nodes.left_lights.geometry} material={materials.light} position={[px + i * div_width * unit, py, pz]} rotation={rotation} scale={10} />
      </>
    )
  }

  return visible ? (
    <group
      position={[0, dd * unit / 2, dh1 * unit / 2 + dh2 * unit / 2]}
    >
      {list}
    </group>
  ) : null;
}


export function CapLeft(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const dh1 = height1 - int_height1;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( xrepeat, yrepeat );
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  return (
    <group
      position={[-dw * unit / 2, 0, dh1 * unit]}
    >
      <mesh castShadow geometry={nodes.cap_left.geometry} material={props.isTexture?material_texture:materials.cover} position={[-20012.46, -14175.49, 7491.87]} rotation={[Math.PI / 2, 0, 0]} scale={10} material-color={color} />
      <Logo castShadow position={[-20035.65, -14348.81, 7607.85]} rotation={[Math.PI / 2, 0, 0]} color={invertColor(color)} scale={10} />
    </group>
  )
}

export function CapRight(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const dh1 = height1 - int_height1;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( xrepeat, yrepeat );
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  return (
    <group
      position={[dw * unit / 2, 0, dh1 * unit]}
    >
      <mesh castShadow geometry={nodes.cap_right.geometry} material={props.isTexture?material_texture:materials.cover} position={[20007.4, -14175.49, 7491.87]} rotation={[Math.PI / 2, 0, 0]} scale={10} material-color={color} />
      <Logo castShadow position={[20050.59, -14348.81, 7607.85]} rotation={[Math.PI / 2, Math.PI, 0]} color={invertColor(color)} scale={10} />
    </group>
  )
}

export function BackLeft(props) {
  const { nodes, materials, color } = props;
  const { width, depth, height2 } = props.length;
  const { int_width, int_depth, int_height2, unit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const dh2 = height2 - int_height2;
  const alpha = CalcRotation(props);
  const rotation = [Math.PI / 2 + alpha, 0, 0];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( xrepeat, yrepeat );
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  return (
    <group
      position={[-dw * unit / 2, dd * unit, dh2 * unit]}
    >
      <mesh castShadow geometry={nodes.back_left.geometry} material={props.isTexture?material_texture:materials.cover} position={[-20028.78, 14883, 12228.07]} rotation={rotation} scale={10} material-color={color} />
    </group>
  )
}

export function BackRight(props) {
  const { nodes, materials, color } = props;
  const { width, depth, height2 } = props.length;
  const { int_width, int_depth, int_height2, unit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const dh2 = height2 - int_height2;
  const alpha = CalcRotation(props);
  const rotation = [Math.PI / 2 + alpha, 0, 0];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( xrepeat, yrepeat );
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  return (
    <group
      position={[dw * unit / 2, dd * unit, dh2 * unit]}
    >
      <mesh castShadow geometry={nodes.back_tight.geometry} material={props.isTexture?material_texture:materials.cover} position={[20021.41, 14886.04, 12243.18]} rotation={rotation} scale={10} material-color={color} />
    </group>
  )
}

export function FootLeft(props) {
  const { nodes, materials, color, columns } = props;
  const { width } = props.length;
  const { int_width, unit } = props.int_length;
  const dw = width - int_width;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( xrepeat, yrepeat );
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  return (
    <group
      position={[-dw * unit / 2 + columns.pos[1] * unit, 0, 0]}
    >
      <mesh castShadow geometry={nodes.foot_left.geometry} material={props.isTexture?material_texture:materials.cover} position={[-19502.27, -14162.79, -12644.44]} rotation={[Math.PI / 2, 0, 0]} scale={10} material-color={color} />
    </group>
  )
}

export function FootRight(props) {
  const { nodes, materials, color, columns } = props;
  const { width } = props.length;
  const { int_width, unit } = props.int_length;
  const dw = width - int_width;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( xrepeat, yrepeat );
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  return (
    <group
      position={[dw * unit / 2 - columns.pos[0] * unit, 0, 0]}
    >
      <mesh castShadow geometry={nodes.foot_right.geometry} material={props.isTexture?material_texture:materials.cover} position={[19497.96, -14162.79, -12644.44]} rotation={[Math.PI / 2, 0, 0]} scale={10} material-color={color} />
    </group>
  )
}

export function Foots(props) {
  const { nodes, materials, color, columns } = props;
  const { width } = props.length;
  const { int_width, unit, column_thickness } = props.int_length;
  const dw = width - int_width;
  const px2 = 19497.96;
  const py = -14162.79;
  const pz = -12644.44;
  const list = [];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( xrepeat, yrepeat );
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  for (var i = 2; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      list.push(
        <mesh key={i} castShadow geometry={nodes.foot_left.geometry} material={props.isTexture?material_texture:materials.cover} position={[dw * unit / 2 + px2 - columns.pos[i] * unit + column_thickness * unit / 2, py, pz]} rotation={[Math.PI / 2, 0, 0]} scale={10} material-color={color} />
      )
    }
  }

  return (
    <group>
      {list}
    </group>
  )
}

export function Lights(props) {
  const { nodes, materials, visible } = props;
  const { width, depth, height1, height2 } = props.length;
  const { int_width, int_depth, int_height1, int_height2, int_div_width, max_div_width, unit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  const dh2 = height2 - int_height2;
  const list = [];
  const tmp_count = parseInt(width / int_div_width);
  const tmp_width = width / tmp_count;
  const div_count = tmp_width > max_div_width ? tmp_count + 1 : tmp_count
  const div_width = width / div_count;
  const px = -20000 - dw * unit / 2;
  const py = 705.91;
  const pz = 9303.8;
  const alpha = CalcRotation(props);
  const rotation = [Math.PI / 2 + alpha, 0, 0];

  for (var i = 1; i < div_count; i = i + 1) {
    list.push(
      <>
      {/* <RectAreaLight
        color="#ffffff"
        position={[(px + i * div_width * unit), py + 5000, pz - 300]}
        intensity={15}
        debug={true}
        visible={true}
        rotation={[Math.PI + Math.atan((height2 - height1 - 100) / depth), 0, 0]}
        width={0.1}
        height={0.1}
      />
      <RectAreaLight
        color="#ffffff"
        position={[(px + i * div_width * unit), py - 5000, pz - 300 - 8000 * Math.sin(Math.atan((height2 - height1 - 100) / depth))]}
        intensity={15}
        debug={true}
        visible={true}  
        rotation={[Math.PI + Math.atan((height2 - height1 - 100) / depth), 0, 0]}
        width={0.1}
        height={0.1}
      /> */}
      <mesh key={i} castShadow geometry={nodes.left_lights.geometry} material={materials.light} position={[px + i * div_width * unit, py, pz]} rotation={rotation} scale={10} />
      </>
    )
  }

  return visible ? (
    <group
      position={[0, dd * unit / 2, dh1 * unit / 2 + dh2 * unit / 2]}
    >
      {list}
    </group>
  ) : null;

}

export function Side(props) {
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz } = props.int_length;
  if (props.current_side === 0) return null;
  switch (props.current_side) {
    case 1:
      return (
        <mesh
          position={[0, -oy * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[width * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 2:
      return (
        <mesh
          position={[-width * unit / 2, (depth / 2 - oy) * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 3:
      return (
        <mesh
          position={[0, (depth - oy) * unit, (height2 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[width * unit, height2 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 4:
      return (
        <mesh
          position={[width * unit / 2, (depth / 2 - oy) * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    default:
      return null;
  }
}

export function AddExtraColumns(props) {
  const { columns, columnFlag, secondHeight } = props;
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;

  return props.activeStep === 0 && props.subStep === 1 && columns.adding === true ? (
    <group>
      {columnFlag[4] === true ?
        <Html scaleFactor={5} position={[0, -oy * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => {
              if (columns.added[4] === false || columns.added[5] === false) props.setCurrentSide(1);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              if (!columns.added[4]) props.ShiftColumn('E')
              else if (!columns.added[5]) props.ShiftColumn('F')
            }}
          >
            Front Side<BsPlusCircle />
          </div>
        </Html> : null}
      {!secondHeight ?
        <Html scaleFactor={5} position={[-width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { if (columns.added[5] === false) props.setCurrentSide(2) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => { if (columns.added[5] === false) props.ShiftColumn('F') }}
          >
            Right Side<BsPlusCircle />
          </div>
        </Html> : null}
      {!secondHeight ?
        <Html scaleFactor={5} position={[0, (depth - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { if (columns.added[6] === false) props.setCurrentSide(3) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => { if (columns.added[6] === false) props.ShiftColumn('G') }}
          >Back Side<BsPlusCircle />
          </div>
        </Html> : null}
      {!secondHeight ?
        <Html scaleFactor={5} position={[width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { if (columns.added[7] === false) props.setCurrentSide(4) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => { if (columns.added[7] === false) props.ShiftColumn('H') }}
          >Left Side<BsPlusCircle />
          </div>
        </Html> : null}

    </group>
  ) : null

}

export function AddWalls(props) {
  const { walls, secondHeight } = props;
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;

  return walls.adding === true ? (
    <group>
      {!secondHeight && walls.added[0] === false ?
        <Html scaleFactor={5} position={[0, -oy * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(1);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[0] = true;
              state.walls.adding = false;
            }}
          >
            Front Side<BsPlusCircle />
          </div>
        </Html> : null}
      {walls.added[1] === false ?
        <Html scaleFactor={5} position={[-width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(2) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[1] = true;
              state.walls.adding = false;
            }}
          >
            Left Side<BsPlusCircle />
          </div>
        </Html> : null}
      {walls.added[2] === false ?
        <Html scaleFactor={5} position={[0, (depth - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(3) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[2] = true;
              state.walls.adding = false;
            }}
          >Back Side<BsPlusCircle />
          </div>
        </Html> : null}
      {walls.added[3] === false ?
        <Html scaleFactor={5} position={[width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(4) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[3] = true;
              state.walls.adding = false;
            }}
          >Right Side<BsPlusCircle />
          </div>
        </Html> : null}

    </group>
  ) : null

}


export function LengthInfo(props) {
  const { secondHeight } = props;
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz, LineColor, LineEndColor, lengthEndLineWidth, lengthInfoDist } = props.int_length;
  const points = []
  points.push(new THREE.Vector3(0, 0, 0))
  points.push(new THREE.Vector3(width * unit, 0, 0))
  const lineGeometryForWidth = new THREE.BufferGeometry().setFromPoints(points)
  const points2 = [];
  points2.push(new THREE.Vector3(0, 0, 0))
  points2.push(new THREE.Vector3(0, depth * unit, 0))
  const lineGeometryForDepth = new THREE.BufferGeometry().setFromPoints(points2)
  const points3 = [];
  points3.push(new THREE.Vector3(0, 0, 0))
  points3.push(new THREE.Vector3(0, 0, height1 * unit));
  const lineGeometryForHeight1 = new THREE.BufferGeometry().setFromPoints(points3)
  const points4 = [];
  points4.push(new THREE.Vector3(0, 0, 0))
  points4.push(new THREE.Vector3(0, 0, height2 * unit));
  const lineGeometryForHeight2 = new THREE.BufferGeometry().setFromPoints(points4)

  const points5 = [];
  points5.push(new THREE.Vector3(0, 0, 0));
  points5.push(new THREE.Vector3(0, 0, lengthEndLineWidth * unit));
  const lineGeometryForEnd = new THREE.BufferGeometry().setFromPoints(points5);

  return props.activeStep === 0 && props.subStep === 0 ? (
    <group>
      <Html scaleFactor={5} position={[0, -oy * unit, (height1 - oz) * unit]}>
        <div className="length-info">{parseInt(width * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html>

      <Html scaleFactor={5} position={[width * unit / 2, (depth / 2 - oy) * unit, (-oz + 200) * unit]}>
        <div className="length-info">{parseInt(depth * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html>
      <Html scaleFactor={5} position={[(width + 100) * unit / 2, -oy * unit, (height1 / 2 - oz) * unit]}>
        <div className="length-info">{parseInt(height1 * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html>
      {secondHeight ? <Html scaleFactor={5} position={[width * unit / 2, (depth - oy) * unit, (height2 / 2 - oz) * unit]}>
        <div className="length-info">{parseInt(height2 * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html> : null}

      <line geometry={lineGeometryForWidth} position={[-width * unit / 2, (-oy - lengthInfoDist) * unit, -oz * unit + (height1) * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForDepth} position={[width * unit / 2, -oy * unit, (-oz) * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForHeight1} position={[width * unit / 2, -oy * unit, -oz * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForHeight2} position={[width * unit / 2, (depth - oy) * unit, -oz * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[-width * unit / 2, (-oy - lengthInfoDist) * unit, (-oz + height1 - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (-oy - lengthInfoDist) * unit, (-oz + height1 - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (-oy + lengthEndLineWidth / 2) * unit, -oz * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (-oy + lengthEndLineWidth / 2) * unit, (-oz + height1) * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (-oy + depth + lengthEndLineWidth / 2) * unit, -oz * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (-oy + depth + lengthEndLineWidth / 2) * unit, (-oz + height2) * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>



      <line geometry={lineGeometryForEnd} position={[width * unit / 2, -oy * unit, (-oz - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (-oy + depth) * unit, (-oz - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>


    </group>
  ) : null;
}

export function ColumnLabels(props) {
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz, secondHeight } = props.int_length;
  const columns = props.columns;
  const columnFlag = props.columnFlag;

  const list = [];
  for (var i = 2; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      list.push(
        <Html key={i} scaleFactor={5} position={[width * unit / 2 - columns.pos[i] * unit, -oy * unit, (height1 - oz) * unit]}>
          <div className="length-info">{String.fromCharCode(65 + i)}</div>
        </Html>
      )
    }
  }

  return props.activeStep === 0 && props.subStep === 1 ? (
    <group>
      {columnFlag[0] ? <Html scaleFactor={5} position={[width * unit / 2 - columns.pos[0] * unit, -oy * unit, (height1 - oz) * unit]}>
        <div className="length-info">A</div>
      </Html> : null}
      {columnFlag[1] ? <Html scaleFactor={5} position={[-width * unit / 2 + columns.pos[1] * unit, -oy * unit, (height1 - oz) * unit]}>
        <div className="length-info">B</div>
      </Html> : null}
      {secondHeight === false && columnFlag[2] === true ? <Html scaleFactor={5} position={[-width * unit / 2, (depth - oy) * unit, (height2 - oz) * unit]}>
        <div className="length-info">C</div>
      </Html> : null}
      {secondHeight === false && columnFlag[2] === true ? <Html scaleFactor={5} position={[width * unit / 2, (depth - oy) * unit, (height2 - oz) * unit]}>
        <div className="length-info">D</div>
      </Html> : null}
      {list}
    </group>
  ) : null;

}

export function Walls(props) {
  const { walls } = props;
  const { width, depth, height2 } = props.length;
  const { int_depth, int_height2, unit, oy } = props.int_length;
  const dd = depth - int_depth;
  const dh2 = height2 - int_height2;

  return (
    <>
      <group position={[0, dd * unit, dh2 * unit / 2 + wallTopHeight * unit / 2]}>
        {walls.added[2] ?
          <Wall width={width} height={height2} unit={unit} position={[0, (oy + 50) * unit + wallThickness * unit / 2, 0]} rotation={[Math.PI / 2, 0, 0]} ID={2}/>
          : null}
      </group>
      <group position={[-width * unit / 2, dd * unit / 2, dh2 * unit / 2 + wallTopHeight * unit / 2]}>
      {walls.added[1] ?
        <Wall width={depth} height={height2} unit={unit} position={[-wallThickness * unit / 2, 0, 0]} rotation={[Math.PI / 2, Math.PI / 2, 0]} ID={1}/>
        : null}
      </group>
      <group position={[width * unit / 2, dd * unit / 2, dh2 * unit / 2 + wallTopHeight * unit / 2]}>
      {walls.added[3] ?
        <Wall width={depth} height={height2} unit={unit} position={[wallThickness * unit / 2, 0, 0]} rotation={[Math.PI / 2, Math.PI / 2, 0]} ID={3}/>
        : null}
      </group>
    </>
  )
}


export function asciiDif(a, b) {
  return a.charCodeAt(0) - b.charCodeAt(0);
}

export function Point(props) {
  const modelID = props.modelID;
  const gltf = useLoader(GLTFLoader, pointModel, loader => {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/draco-gltf/')
    loader.setDRACOLoader(dracoLoader)
   })
  // const onProgress = (xhr) => {
  //   if (xhr.lengthComputable) {
  //     const newProgress = (xhr.loaded / xhr.total) * 100;
  //     props.setProgress(Math.round(newProgress));
  //   }
  // };

  // const gltf = useLoader(GLTFLoader, pointModel, onProgress);
  // const { progress } = useProgress();
  // const [loaded, setLoaded] = React.useState(false);

  // useEffect(()=>{
  //   setLoaded(true);
  // }, [])

  const { nodes, materials } = gltf;
  // const { nodes, materials } = useGLTF(pointModel)
  const snap = useSnapshot(state);
  const [currentSide, setCurrentSide] = React.useState(0);

  const ShiftColumn = (column) => {
    state.columns.editing = true;
    state.columns.editingColumn = column;
    state.columns.added[asciiDif(column, 'A')] = true;
    state.columns.adding = false;
    setCurrentSide(0);
  }

  // return !loaded?
  //   <ProgressBar value={progress} text={'Loading Model...'} />:
  return (
    <group {...props} dispose={null} ref={props.group}>
      <group position={[0, 13, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={1}>

        <WaterProfile
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <BackProfile
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <ColumnLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <ColumnRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <Columns
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          flag={snap.flag}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <Glass
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.blades.color}
          isDesign={snap.isDesign}
          isTexture={snap.isBladeTexture}
          texture={snap.blades.texture}
          opacity={snap.blades.opacity}
          modelID={modelID}
        />
        <TopDiv
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          modelID={modelID}
          addedSubSystem={snap.columns.addedSubSystem}
          subSystemCount={snap.columns.subSystemCount}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <CapLeft
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <CapRight
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <BackLeft
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <BackRight
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <FootLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <FootRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <Foots
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <Lights
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          visible={snap.hasLight}
        />

        <ColumnLabels
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
        />
        {/* <LengthInfo
          secondHeight={secondHeight[modelID]}
          length={snap.length}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          modelID={modelID}
        /> */}
        <AddExtraColumns
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          ShiftColumn={ShiftColumn}
          setCurrentSide={setCurrentSide}
        />
        <AddWalls
          walls={snap.walls}
          length={snap.length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setCurrentSide={setCurrentSide}
        />
        <Walls
          walls={snap.walls}
          length={snap.length}
          int_length={lengths[modelID]}
        />
        <Side
          walls={snap.walls}
          columns={snap.columns}
          length={snap.length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          current_side={currentSide}
        />
      </group>
    </group>
  )
}
// useGLTF.preload(pointModel)