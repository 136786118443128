import React, { useState } from "react";
import { useSnapshot } from "valtio";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";

import CustomInput from "./CustomInput";
import state from "../../state";

const BladeRotation = () => {
  const snap = useSnapshot(state);
  const minR = 0;
  const maxR = 90;
  const [rotation, setRotation] = useState(snap.blades.rotation);
  const isMobile = useMediaQuery('(max-width:769px)')

  const handleSliderChange = (_, newVal) => {
    setRotation(newVal);
    state.blades.rotation = newVal;
  };

  const validationVal = (min, max, val) => {
    if (min > val || val > max) return false;
    else return true;
  };

  const handlePlusChange = (type) => {
    if (validationVal(minR, maxR, rotation + 1)) {
      setRotation(rotation + 1);
      state.length.rotation = rotation + 1;
    }
  };

  const handleMinusChange = (type) => {
    if (validationVal(minR, maxR, rotation - 1)) {
      setRotation(rotation - 1);
      state.blades.rotation = rotation - 1;
    }
  }

  return (
    <Box
        className={!isMobile ? 'overview clearfix' : 'clearfix'}
        sx={{ p: 0, pr: { md: 2, xs: 0 }, mb: { md: 0, xs: 2.5 } }}
    >
    <CustomInput
      {...{
        label: 'Rotation',
        type: 'rotation',
        min: minR,
        max: maxR,
        value: rotation,
        unit: 1,
        unitName: '°',
        handleSliderChange,
        handlePlusChange,
        handleMinusChange,
      }}
    />
    </Box>
  );
}

export default BladeRotation;
