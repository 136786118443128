import React, { useMemo } from 'react';
import * as THREE from 'three';
import {
    wallLast,
    wallColor,
    wallOpacity,
    wallTopHeight,
    wallThickness,
    wallBorderColor
} from '../utils/constant';
import state from '../state';

const Wall = (props) => {
    const { width, height, unit } = props;
    const [opacity, setOpacity] = React.useState(wallOpacity);
    const [color, setColor] = React.useState(wallColor);

    const lineData = useMemo(() => {
        const material = new THREE.LineBasicMaterial({
            color: wallBorderColor
        });

        const points = [];
        points.push(new THREE.Vector3((width / 2 + wallLast) * unit, (height + wallTopHeight) * unit / 2, wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * -unit, (height + wallTopHeight) * unit / 2, wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * -unit, (height + wallTopHeight) * -unit / 2, wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * unit, (height + wallTopHeight) * -unit / 2, wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * unit, (height + wallTopHeight) * unit / 2, wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * unit, (height + wallTopHeight) * unit / 2, -wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * -unit, (height + wallTopHeight) * unit / 2, -wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * -unit, (height + wallTopHeight) * unit / 2, wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * -unit, (height + wallTopHeight) * unit / 2, -wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * -unit, (height + wallTopHeight) * -unit / 2, -wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * -unit, (height + wallTopHeight) * -unit / 2, wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * -unit, (height + wallTopHeight) * -unit / 2, -wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * unit, (height + wallTopHeight) * -unit / 2, -wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * unit, (height + wallTopHeight) * -unit / 2, wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * unit, (height + wallTopHeight) * -unit / 2, -wallThickness / 2 * unit));
        points.push(new THREE.Vector3((width / 2 + wallLast) * unit, (height + wallTopHeight) * unit / 2, -wallThickness / 2 * unit));

        const lgeometry = new THREE.BufferGeometry().setFromPoints(points);
        return [lgeometry, material]
    }, [width, height, unit])

    return (
        <group
            onPointerOver={
                e => {
                    setOpacity(0.7);
                    setColor('#a7c7cb');
                    document.body.style.cursor = 'pointer';
                }
            }
            onPointerOut={
                e => {
                    setOpacity(wallOpacity);
                    setColor(wallColor);
                    document.body.style.cursor = 'auto';
                }
            }
            onClick={
                e => {
                    e.stopPropagation();
                    state.editingSubSystem = false;
                    state.editingWall = true;
                    state.editingWallID = props.ID;
                }
            }
        >
            <mesh castShadow receiveShadow {...props}>
                <boxGeometry attach="geometry" args={[(width + wallLast * 2) * unit, (height + wallTopHeight) * unit, wallThickness * unit]} />
                <meshPhongMaterial attach="material" transparent color={color} opacity={opacity} />
            </mesh>
            <mesh castShadow receiveShadow {...props}>
                <line geometry={lineData[0]} material={lineData[1]} />
            </mesh>
        </group>
    )
}

export default Wall;