import React from "react";
import logo from "../../assets/models/schildrLogo.glb";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from "@react-three/fiber";
import { invertColor } from "./Cabbana";

export default function GetLogo(props) {
  const gltf = useLoader(GLTFLoader, logo, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/draco-gltf/");
    loader.setDRACOLoader(dracoLoader);
  });

  const { nodes, materials } = gltf;
  const { color, columns } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  if (
    props.isLarge &&
    props.doubleSystem &&
    !props.origin &&
    !props.systemVisible[3]
  )
    return null;
  if (props.doubleSystem && props.origin && props.systemVisible[3]) return null;
  var bias =
    props.modelID === 17 ||
    props.modelID === 19 ||
    props.modelID === 14 ||
    props.modelID === 4 ||
    props.modelID === 13
      ? 300
      : 200;
  return (
    <group
      position={[
        (width - bias - dw / 2) * unit,
        (height1 - 150) * unit,
        /* (dd + 10.6) * unit */ (dd + 15) * unit,
      ]}
      scale={1}
    >
      <mesh
        geometry={nodes.Schildr_Logo.geometry}
        material={materials.Logo}
        material-color={invertColor(color)}
        scale={1}
      />

      {/* <mesh castShadow geometry={nodes.Schildr_Logo.geometry} material={materials.Logo} position={[361.31, 227.48, 1.15]}
          material-color={invertColor(color)}
          rotation={[0, Math.PI / 2, 0]}
          scale={0.1}
        /> */}
    </group>
  );
}
