import { createContext } from 'react';

// initial state
const initialState = {
    isDark: false
};

const ConfigContext = createContext(initialState);

export { ConfigContext, initialState };
