import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

const NEW_SERVER_URL = process.env.REACT_APP_NEW_SERVER_URL;

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init({
    lng: 'en',
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    preload: ['en', 'tr', 'az'],
    // ns: ['translation'],
    // defaultNS: 'translation',
    // language resources
    backend: {
      loadPath: `${NEW_SERVER_URL}/{{lng}}/api/translations`
    },
  });

export default i18n;