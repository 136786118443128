/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { subsystems, forteOpacity } from '../utils/constant';
import { useSnapshot } from 'valtio';
import state from '../state';
import * as THREE from 'three'
import { forteTexture } from '../utils/constant';

import forte from '../assets/models/forte.glb'
import { xrepeat, yrepeat, systemThickness } from '../utils/constant';

const deltas = [0, -135, -135, -130, -135];

export default function Forte(props) {
  const { nodes, materials } = useGLTF(forte)
  const { px, py, unit, width, height, systemID } = props;
  const snap = useSnapshot(state);

  const int_width = subsystems[systemID].width;
  const int_height = subsystems[systemID].height;

  const rwidth = props.systemType === 1?width:width+0;

  const sw = rwidth / int_width;
  const sw2 = (rwidth - 100) / (int_width - 100) 
  const sh = height / int_height;
  const delta = 120;

  const dw = (rwidth - int_width) * unit;

  const texture = useTexture(forteTexture);
  texture.rotation = Math.PI;
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
    transparent: true,
    opacity: forteOpacity,
  });

  const texture1 = useTexture(props.texture);
  texture1.wrapS = THREE.RepeatWrapping;
  texture1.wrapT = THREE.RepeatWrapping;
  texture1.repeat.set(0.04, 0.04);
  texture1.rotation = Math.PI;
  const material_texture1 = new THREE.MeshPhysicalMaterial({
    map: texture1,
  });

  if (props.systemType === 1) {
    state.subSystemWidth[props.ID] = width;
    state.subSystemHeight[props.ID] = height;
  }

  return (
    <group
      rotation={props.rotation}
      scale={props.scale * 0.1}
      position={[px, 0, py + (snap.isModel && props.side === 1 ? 2 : 0)]}
      onPointerOver={
        e => {
          document.body.style.cursor = 'pointer';
        }
      }
      onPointerOut={
        e => {
          document.body.style.cursor = 'auto';
        }
      }
      onClick={
        e => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID;
          state.editingSystemType = props.systemType;
        }
      }

    >
      <group rotation={[Math.PI / 2, Math.PI, 0]}
        position={[(snap.isModel?deltas[props.side]:0) + (props.systemType === 1 ? 70 : systemThickness[snap.columns.systemId[props.ID]]), width / 2, height + delta * (1-sh) - 120]}
        scale={1}
      >
        <group position={[props.systemType === 1?16:16, 2.16, -0.08]} rotation={[-Math.PI / 2, 0, (props.systemType - 1) * Math.PI]} scale={[1, 1, sh]}>
          <group scale={[1, sw * 1.002, 1]}>
            <mesh geometry={nodes.topprofile.geometry} 
            material={props.isTexture?material_texture1:props.isTexture?material_texture1:materials['profile.mat']}
             position={[-13.91, -76.85, 0.01]} scale={10} 
              material-color={snap.structure.color}
            />
          </group>
          <group scale={[1, sw2, 1]}>
            <mesh material-color={snap.forteColor} geometry={nodes.screen.geometry} material={material_texture} position={[-13.91, -76.85, 0.01]} scale={[10, 10, 10  * (100-(props.systemType === 1 ?snap.columns.systemPos[props.ID]:snap.columns.fortePos[props.ID])) / 100]} />
            <mesh geometry={nodes.bottomprofile.geometry} material={props.isTexture?material_texture1:materials['profile.mat']} position={[-13.91, -76.85, 2025 * (props.systemType === 1 ?snap.columns.systemPos[props.ID]:snap.columns.fortePos[props.ID]) / 100]} scale={10} 
              material-color={snap.structure.color}
            />
          </group>
            <mesh geometry={nodes.leftprofile.geometry} material={props.isTexture?material_texture1:materials['profile.mat']} position={[-13.91 , -76.85 + dw * 5, 0.01]} scale={10} 
              material-color={snap.structure.color}
            />
            <mesh geometry={nodes.rightprofile.geometry} material={props.isTexture?material_texture1:materials['profile.mat']} position={[-13.91, -76.85 - dw * 5, 0.01]} scale={10} 
              material-color={snap.structure.color}
            />
        </group>
      </group>
    </group>
  )
}