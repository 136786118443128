import { axiosInstance, axiosInstanceAuthorized } from "utils/constant";

export default function authHeader(itemKey = 'user') {
    const user = JSON.parse(localStorage.getItem(itemKey));
  
    if (user && user.accessToken) {
      return { Authorization: 'Bearer ' + user.accessToken };
    } else {
      return {};
    }
  }


export const setAuthHeader = (authorization) => {
  axiosInstanceAuthorized.defaults.headers.common['Authorization'] = authorization;
  axiosInstanceAuthorized.defaults.headers['Authorization'] = authorization;
  axiosInstance.defaults.headers.common['Authorization'] = authorization;
  axiosInstance.defaults.headers['Authorization'] = authorization;
};
