/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import triangle from "../../assets/models/triangleNEW.glb";
// import { GlassCount } from '../DivCount';
// import { subsystems } from '../../utils/constant';
// import { xrepeat, yrepeat } from '../../utils/constant';

const xrepeat = 0.09,
  yrepeat = 0.03;

export function TriPart(props) {
  const { nodes, materials } = useGLTF(triangle);
  const { color, isGardes } = props;
  const { width, height1, height2, depth } = props.length;
  const { unit, column_depth, oz, oy } = props.int_length;
  const sd = (depth + 55) / (3000 - 120);
  const sd2 = (depth - 50) / 2880;
  const sh = (height2 - height1 - 260) / 514;
  const sh2 = (height2 - height1 - 300) / 423;
  const bias = isGardes ? 24 : 0;

  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });
  const material = new THREE.MeshPhongMaterial({
    color: "#a7c7cb",
    opacity: 0.3,
    transparent: true,
  });

  // const div_count_left = GlassCount(depth - column_depth, props.columns.systemId[0], props.modelID);
  // const div_count_right = GlassCount(depth - column_depth, props.columns.systemId[1], props.modelID);

  const len = depth - column_depth;
  var pos1, pos2, pos3, pos4;

  if (depth <= 3000) pos1 = len / 2;
  else if (depth <= 6000) {
    pos1 = len / 3;
    pos2 = (len * 2) / 3;
  } else {
    pos1 = len / 5;
    pos2 = (len * 2) / 5;
    pos3 = (len * 3) / 5;
    pos4 = (len * 4) / 5;
  }

  return (
    <group {...props} dispose={null} rotation={[0, 0, Math.PI]}>
      {props.addedSubSystem[bias] && props.walls.added[1] === false ? (
        <>
          <group
            scale={[unit, unit * sd, unit]}
            position={[
              (width * unit) / 2 - 45 * unit,
              -oy,
              -oz * unit + height1 * unit + 178,
            ]}
          >
            <group scale={[1, 1, sh]} position={[0, 10, -50 * sh]}>
              <mesh
                geometry={nodes.glass.geometry}
                material={material}
                position={[0, -30, 0]}
                scale={10.05}
              />
              <mesh
                geometry={nodes.triangleprofile.geometry}
                material={
                  props.isTexture ? material_texture : materials.profile
                }
                position={[0, 0, 0]}
                material-color={color}
                scale={[(10 * 80) / 39, 10, 10]}
              />
            </group>
            {/* <mesh geometry={nodes.triangle_bottom.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, -0.15, 1.4]} scale={[10, 10, 18]} /> */}
          </group>
          <group
            scale={[unit, unit * sd2, unit]}
            position={[
              (width * unit) / 2 - 45 * unit,
              -oy + 100,
              -oz * unit + height1 * unit + 178,
            ]}
          >
            <mesh
              geometry={nodes.triangle_bottom.geometry}
              material={props.isTexture ? material_texture : materials.profile}
              material-color={color}
              position={[0, -0.15, 1.4]}
              scale={[10, 10, 18]}
            />
          </group>
          <group
            scale={[unit, unit, unit]}
            position={[
              (width * unit) / 2 - 45 * unit,
              -oy,
              -oz * unit + height1 * unit + 178,
            ]}
          >
            <group
              scale={[1, 1, (sh2 * pos1) / len]}
              position={[0, 10, (-25 * sh2 * pos1) / len]}
            >
              <mesh
                geometry={nodes.extra_profile.geometry}
                material={
                  props.isTexture ? material_texture : materials.profile
                }
                material-color={color}
                position={[0, pos1, 286]}
                scale={[(10 * 80) / 39, (10 * 55) / 30, 10]}
              />
            </group>
            {depth > 3000 ? (
              <group
                scale={[1, 1, (sh2 * pos2) / len]}
                position={[0, 10, (-25 * sh2 * pos2) / len]}
              >
                <mesh
                  geometry={nodes.extra_profile.geometry}
                  material={
                    props.isTexture ? material_texture : materials.profile
                  }
                  material-color={color}
                  position={[0, pos2, 286]}
                  scale={[(10 * 80) / 39, (10 * 55) / 30, 10]}
                />
              </group>
            ) : null}
            {depth > 6000 ? (
              <group
                scale={[1, 1, (sh2 * pos3) / len]}
                position={[0, 10, (-25 * sh2 * pos3) / len]}
              >
                <mesh
                  geometry={nodes.extra_profile.geometry}
                  material={
                    props.isTexture ? material_texture : materials.profile
                  }
                  material-color={color}
                  position={[0, pos3, 286]}
                  scale={[(10 * 80) / 39, (10 * 55) / 30, 10]}
                />
              </group>
            ) : null}
            {depth > 6000 ? (
              <group
                scale={[1, 1, (sh2 * pos4) / len]}
                position={[0, 10, (-25 * sh2 * pos4) / len]}
              >
                <mesh
                  geometry={nodes.extra_profile.geometry}
                  material={
                    props.isTexture ? material_texture : materials.profile
                  }
                  material-color={color}
                  position={[0, pos4, 286]}
                  scale={[(10 * 80) / 39, (10 * 55) / 30, 10]}
                />
              </group>
            ) : null}
          </group>
        </>
      ) : null}
      {props.addedSubSystem[bias + 1] && props.walls.added[3] === false ? (
        <>
          <group
            scale={[unit, unit * sd, unit]}
            position={[
              (-width * unit) / 2 + 45 * unit,
              -oy,
              -oz * unit + height1 * unit + 178,
            ]}
          >
            <group scale={[1, 1, sh]} position={[0, 10, -50 * sh]}>
              <mesh
                geometry={nodes.glass.geometry}
                material={material}
                position={[0, -30, 0]}
                scale={[-10.05, 10.05, 10.05]}
              />
              <mesh
                geometry={nodes.triangleprofile.geometry}
                material={
                  props.isTexture ? material_texture : materials.profile
                }
                position={[0, 0, 0]}
                material-color={color}
                scale={[(10 * 80) / 39, 10, 10]}
              />
            </group>
            {/* <mesh geometry={nodes.triangle_bottom.geometry} material={props.isTexture?material_texture:materials.profile} material-color={color} position={[0, -0.15, 1.4]} scale={[10, 10, 18]} /> */}
          </group>
          <group
            scale={[unit, unit * sd2, unit]}
            position={[
              (-width * unit) / 2 + 45 * unit,
              -oy + 100,
              -oz * unit + height1 * unit + 178,
            ]}
          >
            <mesh
              geometry={nodes.triangle_bottom.geometry}
              material={props.isTexture ? material_texture : materials.profile}
              material-color={color}
              position={[0, -0.15, 1.4]}
              scale={[10, 10, 18]}
            />
          </group>

          <group
            scale={[unit, unit, unit]}
            position={[
              (-width * unit) / 2 + 45 * unit,
              -oy,
              -oz * unit + height1 * unit + 178,
            ]}
          >
            <group
              scale={[1, 1, (sh2 * pos1) / len]}
              position={[0, 10, (-25 * sh2 * pos1) / len]}
            >
              <mesh
                geometry={nodes.extra_profile.geometry}
                material={
                  props.isTexture ? material_texture : materials.profile
                }
                material-color={color}
                position={[0, pos1, 286]}
                scale={[(10 * 80) / 39, (10 * 55) / 30, 10]}
              />
            </group>
            {depth > 3000 ? (
              <group
                scale={[1, 1, (sh2 * pos2) / len]}
                position={[0, 10, (-25 * sh2 * pos2) / len]}
              >
                <mesh
                  geometry={nodes.extra_profile.geometry}
                  material={
                    props.isTexture ? material_texture : materials.profile
                  }
                  material-color={color}
                  position={[0, pos2, 286]}
                  scale={[(10 * 80) / 39, (10 * 55) / 30, 10]}
                />
              </group>
            ) : null}
            {depth > 6000 ? (
              <group
                scale={[1, 1, (sh2 * pos3) / len]}
                position={[0, 10, (-25 * sh2 * pos3) / len]}
              >
                <mesh
                  geometry={nodes.extra_profile.geometry}
                  material={
                    props.isTexture ? material_texture : materials.profile
                  }
                  material-color={color}
                  position={[0, pos3, 286]}
                  scale={[(10 * 80) / 39, (10 * 55) / 30, 10]}
                />
              </group>
            ) : null}
            {depth > 6000 ? (
              <group
                scale={[1, 1, (sh2 * pos4) / len]}
                position={[0, 10, (-25 * sh2 * pos4) / len]}
              >
                <mesh
                  geometry={nodes.extra_profile.geometry}
                  material={
                    props.isTexture ? material_texture : materials.profile
                  }
                  material-color={color}
                  position={[0, pos4, 286]}
                  scale={[(10 * 80) / 39, (10 * 55) / 30, 10]}
                />
              </group>
            ) : null}
          </group>
        </>
      ) : null}
    </group>
  );
}
