/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import { subsystems, wooddesign } from '../utils/constant';
import { useSnapshot } from 'valtio';
import state from '../state';
import * as THREE from 'three'
import { handleHeight } from '../utils/constant';
import zion from '../assets/models/zion.glb';
import { ZionGlassCount } from '../models/DivCount';
import { GetHandleColor } from './GetHandleColor';

const xrepeat = 0.0003,
  yrepeat = 0.0001;

export default function Zion2(props) {
  const { nodes, materials } = useGLTF(zion)
  const { px, py, pz, width, height, systemID, unit } = props;
  const snap = useSnapshot(state);
  const handle_color = GetHandleColor();
  // const handle_height = height >= 2000 ? handleHeight : height / 2;
  const handle_height = handleHeight + 130;

  const direction = snap.columns.systemDirection[props.ID];
  const isLeft = direction != 0 ? true : false;
  const dr = isLeft ? 1 : -1;

  const int_width = subsystems[systemID].width;
  const int_height = subsystems[systemID].height;
  const sw = width / int_width;
  const sh = height / (int_height - 50);
  const glassWidth = subsystems[systemID].glassWidth;
  // let glassCount = Math.max(2, Math.round((width - 40) / glassWidth, 0));
  let glassCount = Math.max(2, parseInt(width / subsystems[systemID].sliderWidth));
  glassCount = ZionGlassCount(width, glassCount, props.modelID);
  if (glassCount > 4 && glassCount % 2) glassCount = glassCount + 1;
  if (props.modelID !== 3 && props.modelID !== 5 && glassCount > 5) state.columns.systemDirection[props.ID] = 2;
  if (glassCount % 2 && snap.columns.systemDirection[props.ID] === 2) state.columns.systemDirection[props.ID] = 0;
  if (!snap.isModel) state.editingGlassCount = glassCount;
  state.systemSliderCount[props.ID] = glassCount;

  const div_width = (width - 40) / glassCount;
  // state.columns.subSystemCount[props.ID] = glassCount;

  const blade_list = [];
  const blade_count = height / 50;

  const zionTexture = snap.systemTexture[props.ID] === null ? wooddesign[1][0].url : snap.systemTexture[props.ID];

  const texture_1 = useLoader(TextureLoader, zionTexture);
  texture_1.repeat.set(0.001, 0.001);

  const texture = useTexture(snap.structure.textures[7]);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const material_texture1 = new THREE.MeshPhysicalMaterial({
    map: texture,
  });


  if (snap.systemTexture[props.ID] !== null) {

    for (var i = 0; i < blade_count - 1; i = i + 1) {
      blade_list.push(
        <group key={i} position={[-4.85, 50 * i * 0.01, 0]} scale={[1, 1, 1]}>
          <mesh geometry={nodes.leftblade001.geometry} position={[0, 0, 0]} rotation={[0.56, Math.PI / 2, 0]} scale={0.001} >
            <meshStandardMaterial map={texture_1} attach="material" />
          </mesh>
        </group>
      )
    }
  }
  else {
    for (var i = 0; i < blade_count - 1; i = i + 1) {
      blade_list.push(
        <group key={i} position={[-4.85, 50 * i * 0.01, 0]} scale={[1, 1, 1]}>
          <mesh geometry={nodes.leftblade001.geometry} position={[0, 0, 0]} rotation={[0.56, Math.PI / 2, 0]} scale={0.001}
            material-color={snap.systemColor[props.ID]}
          />
        </group>
      )
    }
  }


  const list = [];
  const total_blade_list = [];

  if (snap.columns.systemDirection[props.ID] != 2 || glassCount % 2) {
    for (let i = 0; i < glassCount; i = i + 1) {
      const rank = isLeft ? glassCount - i - 1 : i;
      const start_point = rank * 100 / (glassCount - 1);
      const move_point = Math.max(0, Math.min(snap.columns.systemPos[props.ID], 100 - 8 / glassCount) - start_point) / 100 * div_width * (glassCount - 1);
      list.push(
        <group key={i} position={[(-(div_width) * i + move_point * dr) * 0.01, 0, 10 * rank * 0.01]} scale={[div_width / glassWidth, 1, 1]}>
          <mesh geometry={i === 0 ? nodes.rightdoor1profile.geometry : i < glassCount - 1 ? nodes.rightdoor1profile.geometry : nodes.rightdoor1profile.geometry} material={props.isTexture ? material_texture1 : props.isTexture ? material_texture1 : materials.profile} position={[-0.05, 12.63, -13.01]} scale={[i === 0 ? 0.001 : -0.001, 0.001, 0.001]}
            material-color={snap.structure.color}
          />
        </group>
      )
      total_blade_list.push(
        <group key={i} position={[(-(div_width) * i + move_point * dr) * 0.01, 0, 10 * rank * 0.01]} scale={[div_width / glassWidth, 1, 1]}>
          {blade_list}
        </group>
      )
    }
  }
  else {
    const cnt = glassCount / 2;
    for (let i = 0; i < cnt; i = i + 1) {
      const rank = isLeft ? cnt - i - 1 : i;
      const start_point = rank * 100 / (cnt - 1);
      const move_point = Math.max(0, Math.min(snap.columns.systemPos[props.ID], 100 - 6 / cnt) - start_point) / 100 * div_width * (cnt - 1);
      list.push(
        <group key={i} position={[(-div_width * i + move_point * dr) * 0.01, 0, 10 * rank * 0.01]} scale={[div_width / glassWidth, 1, 1]}>
          <mesh material-color={snap.structure.color} geometry={i === 0 ? nodes.rightdoor1profile.geometry : i < glassCount - 1 ? nodes.rightdoor1profile.geometry : nodes.rightdoor1profile.geometry} material={props.isTexture ? material_texture1 : materials.profile} position={[-0.05, 12.63, -13.01]} scale={[i === 0 ? 0.001 : -0.001, 0.001, 0.001]} />
        </group>
      )
      total_blade_list.push(
        <group key={i} position={[(-div_width * i + move_point * dr) * 0.01, 0, 10 * rank * 0.01]} scale={[div_width / glassWidth, 1, 1]}>
          {blade_list}
        </group>
      )
    }

    for (let i = 0; i < cnt; i = i + 1) {
      const rank = isLeft ? i : cnt - i - 1;
      const start_point = rank * 100 / (cnt - 1);
      const move_point = Math.max(0, Math.min(snap.columns.systemPos[props.ID], 100 - 6 / cnt) - start_point) / 100 * div_width * (cnt - 1);
      list.push(
        <group key={i + cnt} position={[(-div_width * (cnt + i) - move_point * dr) * 0.01, 0, 10 * rank * 0.01]} scale={[div_width / glassWidth, 1, 1]}>
          <mesh material-color={snap.structure.color} geometry={i === cnt - 1 ? nodes.rightdoor1profile.geometry : nodes.rightdoor1profile.geometry} material={props.isTexture ? material_texture1 : materials.profile} position={[-0.05, 12.63, -13.01]} scale={[-0.001, 0.001, 0.001]} />
        </group>
      )
      total_blade_list.push(
        <group key={i + cnt} position={[(-div_width * (cnt + i) - move_point * dr) * 0.01, 0, 10 * rank * 0.01]} scale={[div_width / glassWidth, 1, 1]}>
          {blade_list}
        </group>
      )
    }
  }

  const silver_material = new THREE.MeshStandardMaterial({
    metalness: 1,   // between 0 and 1
    roughness: 0.5, // between 0 and 1
  });

  const handle_list = [];

  if (snap.columns.systemDirection[props.ID] != 2) {
    if (isLeft) {
      handle_list.push(
        <React.Fragment key={0}>
          <group position={[-15.2, 0, -0.3]}>
            <group position={[dr * (width / 2 - 10) * 0.01, handle_height * 0.01, (glassCount - 1) * 10 * 0.01]}>
              <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoorhandle.geometry} position={[-15.57, 0.04, -0.25]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} />
            </group>
            <group position={[dr * (-width / 2 + 60) * 0.01 + dr * Math.min(snap.columns.systemPos[props.ID], 100 - 8 / glassCount) / 100 * div_width * (glassCount - 1) * 0.01, handle_height * 0.01, 0]}>
              <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoorhandle.geometry} position={[-15.57, 0.04, -0.25]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} />
            </group>
          </group>
        </React.Fragment>
      )
    }
    else {
      handle_list.push(
        <React.Fragment key={0}>
          <group position={[-15.2, 0, -0.3]}>
            <group position={[dr * (width / 2 - 50) * 0.01, handle_height * 0.01, (glassCount - 1) * 10 * 0.01]}>
              <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoorhandle.geometry} position={[-15.57, 0.04, -0.25]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} />
            </group>
            <group position={[dr * (-width / 2 - 10) * 0.01 + dr * Math.min(snap.columns.systemPos[props.ID], 100 - 8 / glassCount) / 100 * div_width * (glassCount - 1) * 0.01, handle_height * 0.01, 0]}>
              <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoorhandle.geometry} position={[-15.57, 0.04, -0.25]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} />
            </group>
          </group>
        </React.Fragment>
      )
    }
  }
  else {
    handle_list.push(
      <React.Fragment key={0}>
        <group position={[-15.2, 0, -0.3]}>
          <group position={[dr * (width / 2) * 0.01, handle_height * 0.01, 0]}>
            <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoorhandle.geometry} position={[-15.57, 0.04, -0.25]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} />
          </group>
          <group position={[dr * (-width / 2 + 50) * 0.01, handle_height * 0.01, 0]}>
            <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoorhandle.geometry} position={[-15.57, 0.04, -0.25]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} />
          </group>
          <group position={[(40 + Math.min(snap.columns.systemPos[props.ID], 100 - 8 / glassCount) / 100 * div_width * (glassCount / 2 - 1)) * 0.01, handle_height * 0.01, -0.1]}>
            <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoorhandle.geometry} position={[-15.57, 0.04, -0.25]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} />
          </group>
          <group position={[(20 - Math.min(snap.columns.systemPos[props.ID], 100 - 8 / glassCount) / 100 * div_width * (glassCount / 2 - 1)) * 0.01, handle_height * 0.01, -0.1]}>
            <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoorhandle.geometry} position={[-15.57, 0.04, -0.25]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} />
          </group>

        </group>
      </React.Fragment>
    )
  }

  if (props.systemType === 1) {
    state.subSystemWidth[props.ID] = width;
    state.subSystemHeight[props.ID] = height;
  }

  var xbias = 0;
  var ybias = 0;
  var ydelta = 0;

  if (props.modelID === 9 || props.modelID === 11 || props.modelID === 16 || props.modelID === 20) {
    if (props.side % 2) ybias = 20 * unit;
    if (props.side % 2 === 0) xbias = -60 * unit * (props.side - 3);
  }
  if (props.modelID === 3 || props.modelID === 5) {
    ydelta = 65 * unit;
    if (props.side % 2) ybias = 10 * unit;
    if (props.side % 2 === 0) xbias = 30 * unit * (props.side - 3);
  }

  return (
    <group
      rotation={props.rotation}
      scale={props.scale * 100}
      position={[px * unit + xbias, py * unit + ybias + ydelta, pz * unit]}
      onPointerOver={
        e => {
          document.body.style.cursor = 'pointer';
        }
      }
      onPointerOut={
        e => {
          document.body.style.cursor = 'auto';
        }
      }
      onClick={
        e => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID;
          state.editingSystemType = props.systemType;
          state.editingGlassCount = glassCount;
        }
      }

    >
      <group position={[0, 0, 0]} rotation={[0, Math.PI, 0]}>
        <group scale={[sw, sh, 1]}>
          <mesh geometry={nodes.rightsideprofile.geometry} material={props.isTexture ? material_texture1 : materials.profile} position={[15.49, 0.04, -0.11]} rotation={[-Math.PI / 2, 0, 0]} scale={0.001}
            material-color={snap.structure.color}
          />
          <mesh geometry={nodes.under_profile.geometry} material={props.isTexture ? material_texture1 : materials.profile} position={[-15.42, 0.23, -0.22]} rotation={[0, Math.PI / 2, 0]} scale={0.001}
            material-color={snap.structure.color}
          />
          <mesh geometry={nodes.top_profile.geometry} material={props.isTexture ? material_texture1 : materials.profile} position={[-15.42, 25.39, -0.29]} rotation={[0, Math.PI / 2, 0]} scale={0.001}
            material-color={snap.structure.color}
          />
          <mesh geometry={nodes.leftsideprofile.geometry} material={props.isTexture ? material_texture1 : materials.profile} position={[-15.49, 0.04, -0.29]} rotation={[-Math.PI / 2, 0, Math.PI]} scale={0.001}
            material-color={snap.structure.color}
          />
        </group>

        <group scale={[1, sh * 1.01, 1]} position={[width * 0.01 / 2 - (div_width) / 2 * 0.01, 0, 0]}>
          {list}
        </group>
        <group scale={[1, 1, 1]} position={[width * 0.01 / 2 - (div_width) / 2 * 0.01, 0, -0.17]}>
          {total_blade_list}
        </group>

        <group position={[0, -10, 0]}>
          {handle_list}
        </group>
      </group>
    </group>
  )
}

// useGLTF.preload('models/classy8.glb')
