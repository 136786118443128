import React, { useContext } from "react";

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useMediaQuery } from "@mui/material";

import state from "../../state";
import { useSnapshot } from "valtio";
import AppContext from "../../context/AppContext";
import { useTranslation } from "react-i18next";

const Installation = () => {
  const {
    setInstall,
    brandColor
  } = useContext(AppContext)
  const snap = useSnapshot(state);
  const { t, i18n } = useTranslation();

  const changeOption = (value) => {
    setInstall(value);
    state.install = value;
  }

  const isMobile = useMediaQuery('(max-width:769px)')

  return (
    <Box
        className={!isMobile ? 'overview' : ''}
        sx={{ p: 0, pr: { md: 2, xs: 0 }, mb: { md: 0, xs: 2.5 } }}
    >
      <Button
        variant='contained'
        size="large"
        sx={{
          mb: 0.5,
          width: '100%',
          borderRadius: .5,
          color: snap.install? "#fff" : "#232323",
          bgcolor: snap.install? brandColor : "#F6F6F6",
          justifyContent: 'flex-start',
          textTransform: 'capitalize',
          '&:hover': { bgcolor: snap.install? brandColor : "#F6F6F6", }
        }}
        startIcon={<CheckIcon />}
        onClick={() => changeOption(1)}
      >
        {t('With Installation')}
      </Button>

      <Button
        variant='contained'
        size="large"
        sx={{
          mb: 2,
          width: '100%',
          borderRadius: .5,
          color: snap.install == 0? "#fff" : "#232323",
          bgcolor: snap.install == 0 ? brandColor : "#F6F6F6",
          justifyContent: 'flex-start',
          textTransform: 'capitalize',
          '&:hover': { bgcolor: snap.install == 0 ? brandColor : "#F6F6F6", }
        }}
        startIcon={<CloseIcon />}
        onClick={() => changeOption(0)}
      >
        {t('No Installation')}
      </Button>

      {/* <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={handleChange}
          value={snap.install}
        >
          <FormControlLabel
            value='With'
            control={<Radio />}
            label="With Installation"
          />
          <FormControlLabel
            value='Without'
            control={<Radio />}
            label="Without Installation"
          />
        </RadioGroup> 
    </FormControl>*/}
    </Box >
  );
}

export default Installation