import * as React from 'react';
import {useContext} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import AppContext from 'context/AppContext';

function CircularIndeterminate(props) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress sx={{color: props.brandColor}}/>
    </Box>
  );
}

const Loading = () => {
  const {brandColor} = useContext(AppContext);
  return (
    <div className="loader-container">
      <CircularIndeterminate brandColor={brandColor}/>
      {/* <div className="spinner" /> */}
    </div>
  );
}

export default Loading;