import { lazy } from "react";
import MainLayout from "../layouts/MainLayout";
import Loadable from "../components/Loadable";
import AuthGuard from "../common/authguard";

const TempModel = Loadable(lazy(() => import("../pages/TempModel")));
const TempGuillotine = Loadable(lazy(() => import("../pages/TempGuillotine")));

const TempRoutes = {
  path: "temp",
  element: <MainLayout />,
  children: [
    {
      path: ":key/point/:unit?",
      element: <TempModel modelID={0} />,
    },
    {
      path: ":key/urban/:unit?",
      element: <TempModel modelID={3} />,
    },
    {
      path: ":key/urbanglass/:unit?",
      element: <TempModel modelID={5} />,
    },
    {
      path: ":key/cabbana/:unit?",
      element: <TempModel modelID={1} />,
    },
    {
      path: ":key/cabbanas/:unit?",
      element: <TempModel modelID={13} />,
    },
    {
      path: ":key/cabbanan/:unit?",
      element: <TempModel modelID={17} />,
    },
    {
      path: ":key/lenore/:unit?",
      element: <TempModel modelID={15} />,
    },
    {
      path: ":key/panora/:unit?",
      element: <TempModel modelID={2} />,
    },
    {
      path: ":key/panoradouble/:unit?",
      element: <TempModel modelID={8} />,
    },
    {
      path: ":key/lanai/:unit?",
      element: <TempModel modelID={4} />,
    },
    {
      path: ":key/lanais/:unit?",
      element: <TempModel modelID={14} />,
    },
    {
      path: ":key/grande/:unit?",
      element: <TempModel modelID={6} />,
    },
    {
      path: ":key/dynamic/:unit?",
      element: <TempModel modelID={7} />,
    },
    {
      path: ":key/pergola/:unit?",
      element: <TempModel modelID={9} />,
    },
    {
      path: ":key/pergolaavantgarde/:unit?",
      element: <TempModel modelID={11} />,
    },
    {
      path: ":key/avantgardes/:unit?",
      element: <TempModel modelID={16} />,
    },
    {
      path: ":key/roomy/:unit?",
      element: <TempModel modelID={10} />,
    },
    {
      path: ":key/polad/:unit?",
      element: <TempModel modelID={12} />,
    },
    {
      path: ":key/guillotine/:unit?",
      element: <TempGuillotine systemID={0} />,
    },
    {
      path: ":key/classy/:unit?",
      element: <TempGuillotine systemID={1} />,
    },
    {
      path: ":key/minima/:unit?",
      element: <TempGuillotine systemID={2} />,
    },
    {
      path: ":key/fixedglass/:unit?",
      element: <TempGuillotine systemID={3} />,
    },
    {
      path: ":key/fixedwall/:unit?",
      element: <TempGuillotine systemID={4} />,
    },
    {
      path: ":key/liftandslidedoor/:unit?",
      element: <TempGuillotine systemID={5} />,
    },
    {
      path: ":key/forte/:unit?",
      element: <TempGuillotine systemID={6} />,
    },
    {
      path: ":key/zion/:unit?",
      element: <TempGuillotine systemID={7} />,
    },
    {
      path: ":key/bifolddoor/:unit?",
      element: <TempGuillotine systemID={8} />,
    },
    {
      path: ":key/capella/:unit?",
      element: <TempGuillotine systemID={9} />,
    },
    {
      path: ":key/door/:unit?",
      element: <TempModel modelID={18} />,
    },
    {
      path: ":key/cabbanast/:unit?",
      element: <TempModel modelID={19} />,
    },
    {
      path: ":key/pergolast/:unit?",
      element: <TempModel modelID={20} />,
    },
  ],
};

export default TempRoutes;
