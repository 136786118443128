// logo
import logoIco from "../assets/img/logo/myfavicon.png";
import logoImg from "../assets/img/logo/logo.png";
import ologo from "../assets/img/logo/mylogo.png";
import logo_main from "../assets/img/logo/logo_main.png";
import oawo180 from "../assets/img/logo/oawo180.png";
import login_background from "../assets/img/logo/mybackground.jpg";
// logo
import thankyou_icon from "../assets/img/thankyou-icon.jfif";
import view_icon from "../assets/img/360-degree-icon.jpg";

// texture
import mat_view from "../assets/img/texture/frosted.jfif";
import mat_texture from "../assets/img/texture/mat_texture.png";
import mat_texture_ from "../assets/img/texture/mat_texture_.png";
import forte_texture from "../assets/img/texture/forte_texture.png";
import CA_002 from "../assets/img/texture/CA-002.jpg";
import CA_005 from "../assets/img/texture/CA-005.jpg";
import CA_009 from "../assets/img/texture/CA-009.jpg";
// import CA_016 from '../assets/img/texture/CA-016.jpg';
// import CA_027 from '../assets/img/texture/CA-027.jpg';
import CA_002R from "../assets/img/texture/CA-002_R.jpg";
import CA_005R from "../assets/img/texture/CA-005_R.jpg";
import CA_009R from "../assets/img/texture/CA-009_R.jpg";
import CA_002A from "../assets/img/texture/CA-002_A.jpg";
import CA_005A from "../assets/img/texture/CA-005_A.jpg";
import CA_009A from "../assets/img/texture/CA-009_A.jpg";
import CA_002B from "../assets/img/texture/CA-002_B.jpg";
import CA_005B from "../assets/img/texture/CA-005_B.jpg";
import CA_009B from "../assets/img/texture/CA-009_B.jpg";
import CA_002C from "../assets/img/texture/CA-002_C.jpg";
import CA_005C from "../assets/img/texture/CA-005_C.jpg";
import CA_009C from "../assets/img/texture/CA-009_C.jpg";
import CA_002D from "../assets/img/texture/CA-002_D.jpg";
import CA_005D from "../assets/img/texture/CA-005_D.jpg";
import CA_009D from "../assets/img/texture/CA-009_D.jpg";
// import CA_016R from '../assets/img/texture/CA-016_R.jpg';
// import CA_027R from '../assets/img/texture/CA-027_R.jpg';
import clearGlass from "../assets/img/texture/clearGlass.jpg";
import insulatedGlass from "../assets/img/texture/insulatedGlass.jpg";
import ral7016 from "../assets/img/texture/ral7016.jpg";
import ral9003 from "../assets/img/texture/ral9003.jpg";
import sheffaf_texture from "../assets/img/texture/sheffaf_texture.png";
import sheffaf_view from "../assets/img/texture/clear.jfif";
import singleLaminatedGlass from "../assets/img/texture/singleLaminatedGlass.jpg";
import smartGlass from "../assets/img/texture/smartGlass.jpg";
import withLight from "../assets/img/texture/withLight.png";
import withoutLight from "../assets/img/texture/withoutLight.png";
// import shushe_view from '../assets/img/texture/shushe_view.jpg';
// import shushe_texture from '../assets/img/texture/shushe_texture.png';
// texture

// color
import ral07016 from "../assets/img/colors/ral07016.png";
import ral08017 from "../assets/img/colors/ral08017.png";
import ral09001 from "../assets/img/colors/ral09001.png";
import ral09003 from "../assets/img/colors/ral09003.png";
import ral09005 from "../assets/img/colors/ral09005.png";
import ral09006 from "../assets/img/colors/ral09006.png";
import ral09016 from "../assets/img/colors/ral09016.png";
// import RAL7016 from '../assets/img/colors/RAL7016.jfif';
// import RAL9003 from '../assets/img/colors/RAL9003.jfif';
// color

// products
import cabbana from "../assets/img/productions/cabbana.jpg";
import panora from "../assets/img/productions/panora.jpg";
import grande from "../assets/img/productions/grande.jpg";
import dynamic from "../assets/img/productions/dynamic.jpg";
import roomy from "../assets/img/productions/roomy.jpg";
import urban from "../assets/img/productions/urban.jpg";
import lanai from "../assets/img/productions/lanai.jpg";
import compact from "../assets/img/productions/compact.jpg";
import classy from "../assets/img/productions/classy.jpg";
import forte from "../assets/img/productions/forte.jpg";
import minima from "../assets/img/productions/minima.jpg";
import patiocover from "../assets/img/productions/patiocover.jpg";
import point from "../assets/img/productions/point.jpg";
import zion from "../assets/img/productions/zion.jpg";
import bifold from "../assets/img/productions/bifold.jpg";
import liftandslidedoor from "../assets/img/productions/liftandslide.png";
import capella from "../assets/img/productions/capella.jpg";
import axios from "axios";

// products

import element_Heat from "../assets/img/elements/element_Heat.png";
import element_Sound from "../assets/img/elements/element_Sound.png";
// import element_HeatSound from '../assets/img/elements/element_HeatSound.png';
// import element_LineoFix from '../assets/img/elements/element_LineoFix.png';
// import element_LineoLED from '../assets/img/elements/element_LineoLED.png';
// import element_LineoLuce from '../assets/img/elements/element_LineoLuce.png';
// import element_Protecto from '../assets/img/elements/element_Protecto.png';

import * as THREE from "three";
import authHeader from "services/auth-header";

export const SITE_URL = process.env.REACT_APP_SITE_URL || "https://schildr.store";
export const SERVER_URL = process.env.REACT_APP_SERVER_URL || "https://schildr.store:3003/";

export const API_URL = SERVER_URL + "api/";
export const IP_API_KEY = "14afb517a7b699";

export const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    ...authHeader(),
  },
});

export const axiosInstancePublic = axios.create({
  baseURL: API_URL,
});
export const axiosInstanceAuthorized = axios.create({
  baseURL: API_URL,
  headers: authHeader(),
});

export const ROLE_TYPES = Object.freeze({
  ADMIN: "Global Admin",
  USER: "User",
});

export const EMBED_USER_KEY = 'embed_user';

export const PRICE_API_URL = [
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
  "https://schildrpatio.com/storedata/api",
];

export const PRICE_API_KEY = [
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
  "SchildrPatioPro20109349287234",
];

export const title = [
  "POINT",
  "CABBANA old",
  "PANORA",
  "URBAN",
  "LANAI",
  "URBAN GLASS",
  "GRANDE",
  "DYNAMIC",
  "PANORA PLUS",
  "ELEGANT",
  "ROOMY",
  "AVANTGARDE",
  "POLAD",
  "CABBANA X",
  "LANAI X",
  "LENORE",
  "Avantgarde M",
  "CABBANA",
  "DOOR",
  "CABBANA ST",
  "ELEGANT ST",
];

export const links = [
  "POINT",
  "CABBANA",
  "PANORA",
  "URBAN",
  "LANAI",
  "URBANGLASS",
  "GRANDE",
  "DYNAMIC",
  "PANORADOUBLE",
  "PERGOLA",
  "ROOMY",
  "PERGOLAAVANTGARDE",
  "POLAD",
  "CABBANAS",
  "LANAIS",
  "LENORE",
  "Avantgardes",
  "CABBANAN",
  "DOOR",
  "CABBANAST",
  "PERGOLAST", // ELEGANT ST
];

export const server_url = [
  "https://schildrpatio.com/en/storedata",
  "https://schildr.com/en/storedata",
  "https://schildr.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildr.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildr.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
  "https://schildrpatio.com/en/storedata",
];

// export const modeltypes = [
//     "LOUVERED ROOF",
//     "RETRECTABLE PERGOLA",
//     "SUNROOM",
//     "PATIO COVER",
//     "WINDOW & DOOR",
//     "SUNSHADE",
//     "MORE",
// ];

export const modeltypes = [
  "LOUVERED ROOF",
  "RETRECTABLE PERGOLA",
  "SUNROOM",
  "PATIO COVER",
  "WINDOW & DOOR SLIDE",
  "WINDOW & DOOR",
  "STANDARD",
  "SUNSHADE",
  "MORE",
];

export const models = [
  {
    src: cabbana,
    title: "CABBANA",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/cabbana",
    type: 0,
  },
  {
    src: point,
    title: "PERGOLA",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/pergola",
    type: 1,
  },
  {
    src: point,
    title: "PERGOLA AVANTGARDE",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/pergolaavantgarde",
    type: 1,
  },
  {
    src: lanai,
    title: "LANAI",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/lanai",
    type: 0,
  },
  {
    src: panora,
    title: "PANORA",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/panora",
    type: 2,
  },
  {
    src: panora,
    title: "PANORA PLUS",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/panoradouble",
    type: 2,
  },
  {
    src: grande,
    title: "GRANDE",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/grande",
    type: 2,
  },
  {
    src: dynamic,
    title: "DYNAMIC",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/dynamic",
    type: 2,
  },
  {
    src: roomy,
    title: "ROOMY",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/roomy",
    type: 2,
  },
  {
    src: point,
    title: "POINT",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/point",
    type: 2,
  },
  {
    src: urban,
    title: "URBAN",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/urban",
    type: 2,
  },
  {
    src: urban,
    title: "URBAN GLASS",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/urbanglass",
    type: 2,
  },
];

export const submodels = [
  {
    src: minima,
    title: "MINIMA",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/minima",
    type: 3,
  },
  {
    src: classy,
    title: "CLASSY",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/classy",
    type: 3,
  },
  {
    src: compact,
    title: "GUILLOTINE COMPACT",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/guillotine",
    type: 3,
  },
  {
    src: liftandslidedoor,
    title: "LIFT AND SLIDE DOOR",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/liftandslidedoor",
    type: 3,
  },
  {
    src: bifold,
    title: "BIFOLD DOOR",
    desc: "COMING SOON",
    link: "#",
    type: 3,
  },
  {
    src: zion,
    title: "ZION",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/zion",
    type: 4,
  },
  {
    src: forte,
    title: "FORTE",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/forte",
    type: 4,
  },
  {
    src: compact,
    title: "CAPELLA",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/capella",
    type: 4,
  },
  // {
  //     src: bifold,
  //     title: "BIFOLD DOOR",
  //     desc: "COMING SOON",
  //     link: "#",
  //     type: 3,
  // },
];

export const subsystems = [
  {
    systemID: 0,
    modelName: "guillotineCompanct.glb",
    src: compact,
    title: "GUILLOTINE COMPACT",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/guillotine",
    width: 2600,
    height: 2718,
    topBottomThickness: 220,
    leftRightThickness: 100,
    topThickness: 190,
    bottomThickness: 45,
    sliderWidth: 780,
    type: "subsystem",
    option: 1,
  },
  {
    systemID: 1,
    modelName: "classy8.glb",
    src: classy,
    title: "CLASSY",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/classy",
    width: 9830,
    height: 2620,
    depth: 200,
    glassHeight: 1250,
    glassWidth: 1229,
    sliderWidth: 1600,
    type: "subsystem",
    option: 2,
    option_limit: 5,
  },
  {
    systemID: 2,
    modelName: "minima6.glb",
    src: forte,
    title: "MINIMA",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/minima",
    width: 6900,
    height: 2570,
    glassWidth: 1150,
    glassHeight: 2570,
    sliderWidth: 1200,
    type: "subsystem",
    option: 2,
    option_limit: 5,
  },
  {
    systemID: 3,
    modelName: "fixed.glb",
    src: forte,
    title: "FIXED GLASS",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/fixedglass",
    width: 3530,
    height: 2500,
    glassWidth: 3410,
    glassHeight: 2380,
    sliderWidth: 850,
    type: "fixedsystem",
    option: 0,
  },
  {
    systemID: 4,
    modelName: "fixed.glb",
    src: forte,
    title: "FIXED WALL",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/fixedwall",
    width: 3530,
    height: 2500,
    glassWidth: 3410,
    glassHeight: 2380,
    sliderWidth: 850,
    type: "fixedsystem",
    option: 0,
  },
  {
    systemID: 5,
    modelName: "liftandslidedoor.glb",
    src: liftandslidedoor,
    title: "LIFT AND SLIDE DOOR",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/slidedoor",
    width: 3450,
    height: 2800,
    glassWidth: 1640,
    glassHeight: 2575,
    sliderWidth: 1740,
    type: "subsystem",
    option: 2,
    option_limit: 3,
  },
  {
    systemID: 6,
    modelName: "forte.glb",
    src: forte,
    title: "FORTE",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/forte",
    width: 2970,
    height: 2200,
    glassWidth: 1150,
    glassHeight: 2435,
    sliderWidth: 820,
    type: "subsystem",
    option: 1,
  },
  {
    systemID: 7,
    modelName: "zion.glb",
    src: zion,
    title: "ZION",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/zion",
    width: 3105,
    height: 2600,
    glassWidth: 1010,
    glassHeight: 2435,
    sliderWidth: 1150,
    type: "subsystem",
    option: 2,
    option_limit: 4,
  },
  {
    systemID: 8,
    modelName: "minima6.glb",
    src: forte,
    title: "BIFOLD DOOR",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/bifold",
    width: 2640,
    height: 2498,
    glassWidth: 1110,
    glassHeight: 2435,
    sliderWidth: 820,
    type: "subsystem",
    option: 3,
    option_limit: 8,
  },
  {
    systemID: 9,
    modelName: "capella.glb",
    src: capella,
    title: "CAPELLA",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/capella",
    width: 4280,
    height: 3680,
    glassWidth: 1110,
    glassHeight: 2435,
    sliderWidth: 820,
    type: "topsystem",
    option: 1,
  },
  {
    systemID: 10,
    modelName: "door.glb",
    // src: capella,
    src: "",
    title: "DOOR",
    desc: "Imagine yourself under your new outside veranda. Even when the weather doesn’t allow it anymore. All system profiles are cut at 90˚ allowing the ease of installation.",
    link: "/door",
    // width: 4280,
    // height: 3680,
    width: 2290,
    height: 5570,
    glassWidth: 1110,
    glassHeight: 2435,
    sliderWidth: 820,
    type: "topsystem",
    option: 1,
  },
];

export const systemThickness = [90, 100, -10, 0, 0, 80, 0, 0, 0, 0, 0];

export const subsystem_steps = [
  {
    label: "STRUCTURE",
    // detail: ['Dimensions', 'Columns'],
    // description: ['For larger dimensions, please contact sales team.',
    //     'Define the specifications for the different columns.'],
    detail: ["Dimensions"],
    description: ["For larger dimensions, please contact sales team."],
  },
  {
    label: "STYLING",
    detail: ["Colors&Texture"],
    description: ["RAL PULVER TEXTURED"],
  },
  {
    label: "OPTIONAL",
    detail: ["Optional"],
    description: ["Add options to your configuration."],
  },
  {
    label: "INSTALLATION",
    detail: ["Installation"],
    description: ["Choose the option"],
  },
  {
    label: "OVERVIEW",
    detail: ["Overview"],
    description: ["Below you can find the overview of your configured product"],
  },
];

export const steps = [
  // POINT
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      // detail: ['Colors', 'Polycarbonat'],
      detail: ["Colors", "Roof Type"],
      description: ["RAL PULVER TEXTURED", "Choose the Roof type."],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // CABBANA
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture"],
      description: ["RAL PULVER TEXTURED"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // PANORA
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture", "Roof Type"],
      description: ["RAL PULVER TEXTURED", "Choose the Roof Type"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // URBAN
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      // detail: ['Colors&Texture', 'Polycarbonat'],
      detail: ["Colors&Texture", "Roof Type"],
      description: [
        "RAL PULVER TEXTURED",
        "Multiwall Polycarbonat Panels. Thinkness 5/8 inch.",
      ],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // LANAI
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture"],
      description: ["RAL PULVER TEXTURED"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // URBAN GLASS
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      // detail: ['Colors&Texture'],
      detail: ["Colors&Texture", "Roof Type"],
      description: ["RAL PULVER TEXTURED", "Choose the Roof Type"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // GRANDE
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture", "Roof Type"],
      description: ["RAL PULVER TEXTURED", "Choose the Roof Type"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // DYNAMIC
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture", "Roof Type"],
      description: ["RAL PULVER TEXTURED", "Choose the Roof Type"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // PANORA DOUBLE
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture", "Roof Type"],
      description: ["RAL PULVER TEXTURED", "Choose the Roof Type"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // PERGOLA
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture"],
      description: ["RAL PULVER TEXTURED"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // ROOMY
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture", "Roof Type"],
      description: ["RAL PULVER TEXTURED", "Choose the Roof Type"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // PERGOLA AVANTGARDE
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture"],
      description: ["RAL PULVER TEXTURED"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // POLAD
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture"],
      description: ["RAL PULVER TEXTURED"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // CABBANAS
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture"],
      description: ["RAL PULVER TEXTURED"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // LANAI X
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture"],
      description: ["RAL PULVER TEXTURED"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // Lenore
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture"],
      description: ["RAL PULVER TEXTURED"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // AVANTGARDE M
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture"],
      description: ["RAL PULVER TEXTURED"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // CABBANA New
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture"],
      description: ["RAL PULVER TEXTURED"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // DOOR
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture"],
      description: ["RAL PULVER TEXTURED"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // CABBANA ST
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture"],
      description: ["RAL PULVER TEXTURED"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
  // PERGOLA - ELEGANT ST
  [
    {
      label: "STRUCTURE",
      // detail: ['Dimensions', 'Columns'],
      // description: ['For larger dimensions, please contact sales team.',
      //     'Define the specifications for the different columns.'],
      detail: ["Dimensions"],
      description: ["For larger dimensions, please contact sales team."],
    },
    {
      label: "STYLING",
      detail: ["Colors&Texture"],
      description: ["RAL PULVER TEXTURED"],
    },
    {
      label: "SUB SYSTEM",
      detail: ["Sub systems"],
      description: [
        "Choose the sub systems you want to add to your configuration.",
      ],
    },
    {
      label: "OPTIONAL",
      detail: ["Optional"],
      description: ["Add options to your configuration."],
    },
    {
      label: "INSTALLATION",
      detail: ["Installation"],
      description: ["Choose the option"],
    },
    {
      label: "OVERVIEW",
      detail: ["Overview"],
      description: [
        "Below you can find the overview of your configured product",
      ],
    },
  ],
];

export const woodTexture = [
  {
    title: "CA-005",
    url: CA_005,
    view: CA_005,
    url2: CA_005R,
    urlA: CA_005A,
    urlB: CA_005B,
    urlC: CA_005C,
    urlD: CA_005D,
    urlR: CA_005R,
    opacity: 1,
  },
  {
    title: "CA-009",
    url: CA_009,
    view: CA_009,
    url2: CA_009R,
    urlA: CA_009A,
    urlB: CA_009B,
    urlC: CA_009C,
    urlD: CA_009D,
    urlR: CA_009R,
    opacity: 1,
  },
  {
    title: "CA-002",
    url: CA_002,
    view: CA_002,
    url2: CA_002R,
    urlA: CA_002A,
    urlB: CA_002B,
    urlC: CA_002C,
    urlD: CA_002D,
    urlR: CA_002R,
    opacity: 1,
  },
];

export const wooddesign = [
  // POINT
  [
    {
      title: "Frosted",
      url: mat_texture,
      url2: mat_texture,
      view: mat_view,
      opacity: 1,
    },
    {
      title: "Clear",
      url: sheffaf_texture,
      url2: sheffaf_texture,
      view: sheffaf_view,
      opacity: 0.6,
    },
  ],
  // CABBANA
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // PANORA
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // URBAN
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // LANAI
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // URBAN GLASS
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // GRANDE
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // DYNAMIC
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // PANORA DOUBLE
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // PERGOLA
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // ROOMY
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // PERGOLA AVANTGARDE
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // POLAD
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // CABBANAS
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // LANAI X
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // Lenore
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // AVANTGARDE M
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // CABBANA NEW
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // DOOR
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // CABBANA ST
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
  // PERGOLA - ELEGANT ST
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      url2: CA_005R,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      url2: CA_009R,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      url2: CA_002R,
      opacity: 1,
    },
  ],
];

export const glassType = [
  // POINT
  [
    {
      title: "Frosted",
      url: mat_texture_,
      view: mat_view,
      opacity: 1,
    },
    {
      title: "Clear",
      url: sheffaf_texture,
      view: sheffaf_view,
      opacity: 0.6,
    },
  ],
  // CABBANA
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      opacity: 1,
    },
  ],
  // PANORA
  [
    {
      title: "Clear Glass",
      url: clearGlass,
      view: clearGlass,
      opacity: 0.1,
    },
    {
      title: "Insulated Glass",
      url: insulatedGlass,
      view: insulatedGlass,
      opacity: 0.3,
    },
    {
      title: "Single Laminated Glass",
      url: singleLaminatedGlass,
      view: singleLaminatedGlass,
      opacity: 0.5,
    },
    {
      title: "Smart Glass",
      url: smartGlass,
      view: smartGlass,
      opacity: 0.7,
    },
  ],
  // URBAN
  [
    {
      title: "Frosted",
      url: mat_texture,
      view: mat_view,
      opacity: 1,
    },
    {
      title: "Clear",
      url: sheffaf_texture,
      view: sheffaf_view,
      opacity: 0.6,
    },
  ],
  // LANAI
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      opacity: 1,
    },
  ],
  // URBAN GLASS
  [
    {
      title: "Frosted",
      url: mat_texture,
      view: mat_view,
      opacity: 1,
    },
    {
      title: "Clear",
      url: sheffaf_texture,
      view: sheffaf_view,
      opacity: 0.6,
    },
  ],
  // GRANDE
  [
    // {
    //     title: 'Clear Glass',
    //     url: mat_texture,
    //     view: clearGlass,
    //     opacity: 0.1,
    // },
    {
      title: "Insulated Glass",
      url: mat_texture,
      view: insulatedGlass,
      opacity: 0.3,
    },
    {
      title: "Single Laminated Glass",
      url: mat_texture,
      view: singleLaminatedGlass,
      opacity: 0.5,
    },
    {
      title: "Smart Glass",
      url: mat_texture,
      view: smartGlass,
      opacity: 0.7,
    },
  ],
  // DYNAMIC
  [
    {
      title: "Frosted",
      url: mat_texture,
      view: mat_view,
      opacity: 1,
    },
    {
      title: "Clear",
      url: sheffaf_texture,
      view: sheffaf_view,
      opacity: 0.6,
    },
  ],
  // PANORA DOUBLE
  [
    {
      title: "Clear Glass",
      url: clearGlass,
      view: clearGlass,
      opacity: 0.1,
    },
    {
      title: "Insulated Glass",
      url: insulatedGlass,
      view: insulatedGlass,
      opacity: 0.3,
    },
    {
      title: "Single Laminated Glass",
      url: singleLaminatedGlass,
      view: singleLaminatedGlass,
      opacity: 0.5,
    },
    {
      title: "Smart Glass",
      url: smartGlass,
      view: smartGlass,
      opacity: 0.7,
    },
  ],
  // PERGOLA
  [
    {
      title: "Frosted",
      url: mat_texture,
      view: mat_view,
      opacity: 1,
    },
    {
      title: "Clear",
      url: sheffaf_texture,
      view: sheffaf_view,
      opacity: 0.6,
    },
  ],
  // ROOMY
  [
    // {
    //     title: 'Clear Glass',
    //     url: mat_texture,
    //     view: clearGlass,
    //     opacity: 0.1,
    // },
    {
      title: "Insulated Glass",
      url: mat_texture,
      view: insulatedGlass,
      opacity: 0.3,
    },
    {
      title: "Single Laminated Glass",
      url: mat_texture,
      view: singleLaminatedGlass,
      opacity: 0.5,
    },
    {
      title: "Smart Glass",
      url: mat_texture,
      view: smartGlass,
      opacity: 0.7,
    },
  ],
  // PERGOLA AVANTGARDE
  [
    {
      title: "Frosted",
      url: mat_texture,
      view: mat_view,
      opacity: 1,
    },
    {
      title: "Clear",
      url: sheffaf_texture,
      view: sheffaf_view,
      opacity: 0.6,
    },
  ],
  // POLAD
  [
    {
      title: "Frosted",
      url: mat_texture,
      view: mat_view,
      opacity: 1,
    },
    {
      title: "Clear",
      url: sheffaf_texture,
      view: sheffaf_view,
      opacity: 0.6,
    },
  ],
  // CABBANA X
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      opacity: 1,
    },
  ],
  // LANAI X
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      opacity: 1,
    },
  ],
  // Lenore
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      opacity: 1,
    },
  ],
  // AVANTGARDE M
  [
    {
      title: "Frosted",
      url: mat_texture,
      view: mat_view,
      opacity: 1,
    },
    {
      title: "Clear",
      url: sheffaf_texture,
      view: sheffaf_view,
      opacity: 0.6,
    },
  ],
  // CABBANA New
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      opacity: 1,
    },
  ],
  // DOOR
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      opacity: 1,
    },
  ],
  // CABBANA ST
  [
    {
      title: "CA-005",
      url: CA_005,
      view: CA_005,
      opacity: 1,
    },
    {
      title: "CA-009",
      url: CA_009,
      view: CA_009,
      opacity: 1,
    },
    {
      title: "CA-002",
      url: CA_002,
      view: CA_002,
      opacity: 1,
    },
  ],
  // PERGOLA - PERGOLA ST
  [
    {
      title: "Frosted",
      url: mat_texture,
      view: mat_view,
      opacity: 1,
    },
    {
      title: "Clear",
      url: sheffaf_texture,
      view: sheffaf_view,
      opacity: 0.6,
    },
  ],
];

export const coverColors = [
  // POINT
  [
    {
      url: ral7016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    {
      url: ral9003,
      color: "#f6f6f6",
      title: "RAL9003",
      des: "White",
    },
  ],
  // CABBANA
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // PANORA
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // URBAN
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // LANAI
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // URBAN GLASS
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // GRANDE
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // DYNAMIC
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // PANORA DOUBLE
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // PERGOLA
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // ROOMY
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // PERGOLA AVANTGARDE
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // POLAD
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // CABBANA X
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // LANAI X
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // Lenore
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // AVANTGARDE M
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // CABBANA New
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // DOOR
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    // {
    //     url: ral09003,
    //     color: '#f6f6f6',
    //     title: 'RAL9003',
    //     des: 'White',
    // },
    {
      url: ral08017,
      color: "#442f29",
      title: "RAL8017",
      des: "Chocolate Brown",
    },
    {
      url: ral09001,
      color: "#e9e0d2",
      title: "RAL9001",
      des: "Cream",
    },
    {
      url: ral09005,
      color: "#0e0e10",
      title: "RAL9005",
      des: "Jet Black",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // CABBANA ST
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
  // PERGOLA - ELEGANT ST
  [
    {
      url: ral09006,
      color: "#a1a1a0",
      title: "RAL9006",
      des: "Grey",
    },
    {
      url: ral07016,
      color: "#363d43",
      title: "RAL7016",
      des: "Anthracite Grey",
    },
    {
      url: ral09016,
      color: "#f1f0ea",
      title: "RAL9016",
      des: "Traffic White",
    },
  ],
];

export const default_cover_colors = [
  0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
];
export const default_blade_colors = [
  0, 3, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 3, 0, 0, 0, 0, 0,
];

export const systemColors = [
  {
    url: ral09006,
    color: "#a1a1a0",
    title: "RAL9006",
    des: "Grey",
  },
  {
    url: ral07016,
    color: "#363d43",
    title: "RAL7016",
    des: "Anthracite Grey",
  },
  // {
  //     url: ral09003,
  //     color: '#f6f6f6',
  //     title: 'RAL9003',
  //     des: 'White',
  // },
  {
    url: ral08017,
    color: "#442f29",
    title: "RAL8017",
    des: "Chocolate Brown",
  },
  {
    url: ral09001,
    color: "#e9e0d2",
    title: "RAL9001",
    des: "Cream",
  },
  {
    url: ral09005,
    color: "#0e0e10",
    title: "RAL9005",
    des: "Jet Black",
  },
  {
    url: ral09016,
    color: "#f1f0ea",
    title: "RAL9016",
    des: "Traffic White",
  },
];

export const forteColors = [
  {
    url: ral09006,
    color: "#e9e9e4",
    title: "WHITE",
    des: "Grey",
  },
  {
    url: ral07016,
    color: "#dfd6af",
    title: "CHAMPAGNE",
    des: "Anthracite Grey",
  },
  // {
  //     url: ral09003,
  //     color: '#8a8a7e',
  //     title: 'BOULDER',
  //     des: 'White',
  // },
  {
    url: ral08017,
    color: "#514d40",
    title: "BRONZE",
    des: "Chocolate Brown",
  },
  {
    url: ral09001,
    color: "#212322",
    title: "ANTHRACITE",
    des: "Cream",
  },
  {
    url: ral09005,
    color: "#2e2f2a",
    title: "DEEPBLACK",
    des: "Jet Black",
  },
];

export const curtainColors = [
  {
    url: ral09006,
    color: "#ffffff",
    title: "WHITE",
    des: "White",
  },
  {
    url: ral07016,
    color: "#FFFDD0",
    title: "QUARTZ",
    des: "Anthracite Grey",
  },
  {
    url: ral08017,
    color: "#c0c0c0",
    title: "GREY",
    des: "Chocolate Brown",
  },
];

export const bladeColors = [
  {
    url: ral09006,
    hex: "#e9e9e4",
    title: "WHITE",
    des: "Grey",
  },
  {
    url: ral07016,
    hex: "#dfd6af",
    title: "CHAMPAGNE",
    des: "Anthracite Grey",
  },
  // {
  //     url: ral09003,
  //     hex: '#8a8a7e',
  //     title: 'BOULDER',
  //     des: 'White',
  // },
  {
    url: ral08017,
    hex: "#514d40",
    title: "BRONZE",
    des: "Chocolate Brown",
  },
  {
    url: ral09001,
    hex: "#212322",
    title: "ANTHRACITE",
    des: "Cream",
  },
  {
    url: ral09005,
    hex: "#2e2f2a",
    title: "DEEPBLACK",
    des: "Jet Black",
  },
];

export const sideStyles = [
  // POINT
  [
    {
      title: "Zion",
      url: "zion",
      systemID: 0,
    },
    {
      title: "Minima",
      url: "minima",
      systemID: 2,
    },
  ],
  // CABBANA
  [
    {
      title: "Minima",
      url: "minima",
      systemID: 2,
    },
    {
      title: "Classy",
      url: "classy",
      systemID: 1,
    },
    {
      title: "Guillotine Compact",
      url: "guillotine",
      systemID: 0,
    },
    {
      title: "Zion",
      url: "zion",
      systemID: 7,
    },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
  ],
  // PANORA
  [
    {
      title: "Minima",
      url: "minima",
      systemID: 2,
    },
    // {
    //     title: 'Zion',
    //     url: 'zion',
    //     systemID: 7,
    // },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
    {
      title: "Capella",
      url: "capella",
      systemID: 9,
    },
  ],
  // URBAN
  [
    {
      title: "Minima",
      url: "minima",
      systemID: 2,
    },
    {
      title: "Zion",
      url: "zion",
      systemID: 7,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
    {
      title: "Capella",
      url: "capella",
      systemID: 9,
    },
  ],
  // LANAI
  [
    {
      title: "Minima",
      url: "minima",
      systemID: 2,
    },
    {
      title: "Classy",
      url: "classy",
      systemID: 1,
    },
    {
      title: "Guillotine Compact",
      url: "guillotine",
      systemID: 0,
    },
    {
      title: "Zion",
      url: "zion",
      systemID: 7,
    },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
  ],
  // URBAN GLASS
  [
    {
      title: "Minima",
      url: "minima",
      systemID: 2,
    },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
    {
      title: "Capella",
      url: "capella",
      systemID: 9,
    },
  ],
  // GRANDE
  [
    {
      title: "Classy",
      url: "classy",
      systemID: 1,
    },
    {
      title: "Guillotine Compact",
      url: "guillotine",
      systemID: 0,
    },
    {
      title: "Lift And Slide Door",
      url: "liftandslidedoor",
      systemID: 5,
    },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Bifold Door",
      url: "bifolddoor",
      systemID: 8,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
    {
      title: "Capella",
      url: "capella",
      systemID: 9,
    },
  ],
  // DYNAMIC
  [
    {
      title: "Classy",
      url: "classy",
      systemID: 1,
    },
    {
      title: "Guillotine Compact",
      url: "guillotine",
      systemID: 0,
    },
    {
      title: "Lift And Slide Door",
      url: "liftandslidedoor",
      systemID: 5,
    },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Bifold Door",
      url: "bifolddoor",
      systemID: 8,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
    {
      title: "Capella",
      url: "capella",
      systemID: 9,
    },
  ],
  // PANORA DOUBLE
  [
    {
      title: "Classy",
      url: "classy",
      systemID: 1,
    },
    {
      title: "Guillotine Compact",
      url: "guillotine",
      systemID: 0,
    },
    {
      title: "Lift And Slide Door",
      url: "liftandslidedoor",
      systemID: 5,
    },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Zion",
      url: "zion",
      systemID: 7,
    },
    {
      title: "Bifolddoor",
      url: "bifolddoor",
      systemID: 8,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
    {
      title: "Capella",
      url: "capella",
      systemID: 9,
    },
  ],
  // PERGOLA
  [
    {
      title: "Classy",
      url: "classy",
      systemID: 1,
    },
    {
      title: "Minima",
      url: "minima",
      systemID: 2,
    },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Zion",
      url: "zion",
      systemID: 7,
    },
    {
      title: "Guillotine Compact",
      url: "guillotine",
      systemID: 0,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
  ],
  // ROOMY
  [
    {
      title: "Classy",
      url: "classy",
      systemID: 1,
    },
    {
      title: "Guillotine Compact",
      url: "guillotine",
      systemID: 0,
    },
    {
      title: "Lift And Slide Door",
      url: "liftandslidedoor",
      systemID: 5,
    },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
    {
      title: "Bifold Door",
      url: "bifolddoor",
      systemID: 8,
    },
    {
      title: "Capella",
      url: "capella",
      systemID: 9,
    },
  ],
  // PERGOLA AVANTGARDE
  [
    {
      title: "Classy",
      url: "classy",
      systemID: 1,
    },
    {
      title: "Minima",
      url: "minima",
      systemID: 2,
    },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Zion",
      url: "zion",
      systemID: 7,
    },
    {
      title: "Guillotine Compact",
      url: "guillotine",
      systemID: 0,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
  ],
  // POLAD
  [
    {
      title: "Classy",
      url: "classy",
      systemID: 1,
    },
    {
      title: "Minima",
      url: "minima",
      systemID: 2,
    },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Zion",
      url: "zion",
      systemID: 7,
    },
    {
      title: "Guillotine Compact",
      url: "guillotine",
      systemID: 0,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
  ],
  // CABBANA X
  [
    {
      title: "Minima",
      url: "minima",
      systemID: 2,
    },
    {
      title: "Classy",
      url: "classy",
      systemID: 1,
    },
    {
      title: "Guillotine Compact",
      url: "guillotine",
      systemID: 0,
    },
    {
      title: "Zion",
      url: "zion",
      systemID: 7,
    },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
  ],
  // LANAI X
  [
    {
      title: "Minima",
      url: "minima",
      systemID: 2,
    },
    {
      title: "Classy",
      url: "classy",
      systemID: 1,
    },
    {
      title: "Guillotine Compact",
      url: "guillotine",
      systemID: 0,
    },
    {
      title: "Zion",
      url: "zion",
      systemID: 7,
    },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
  ],
  // Lenore
  [
    /* {
            title: "Minima",
            url: "minima",
            systemID: 2,
            hide: true
        },
        {
            title: "Classy",
            url: "classy",
            systemID: 1,
            hide: true
        },
        {
            title: "Guillotine Compact",
            url: "guillotine",
            systemID: 0,
            hide: true
        },
        {
            title: "Zion",
            url: "zion",
            systemID: 7,
            hide: true
        },
        {
            title: "Forte",
            url: "forte",
            systemID: 6,
            hide: true
        }, */
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
      hide: false,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
      hide: false,
    },
  ],
  // AVANTGARDE M
  [
    {
      title: "Classy",
      url: "classy",
      systemID: 1,
    },
    {
      title: "Minima",
      url: "minima",
      systemID: 2,
    },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Zion",
      url: "zion",
      systemID: 7,
    },
    {
      title: "Guillotine Compact",
      url: "guillotine",
      systemID: 0,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
  ],
  // CABBANA New
  [
    {
      title: "Minima",
      url: "minima",
      systemID: 2,
    },
    {
      title: "Classy",
      url: "classy",
      systemID: 1,
    },
    {
      title: "Guillotine Compact",
      url: "guillotine",
      systemID: 0,
    },
    {
      title: "Zion",
      url: "zion",
      systemID: 7,
    },
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
  ],
  // DOOR
  [
    {
      title: "Fixed Glass",
      url: "fixedglass",
      systemID: 3,
    },
    {
      title: "Fixed Wall",
      url: "fixedwall",
      systemID: 4,
    },
  ],
  // CABBANA ST
  [
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
  ],
  // PERGOLA - ELEGANT ST
  [
    {
      title: "Forte",
      url: "forte",
      systemID: 6,
    },
  ],
];

export const topStyles = [
  // POINT
  [],
  // CABBANA
  [],
  // PANORA
  [
    {
      title: "Capella",
      url: "capella",
      systemID: 9,
    },
  ],
  // URBAN
  [],
  // LANAI
  [],
  // URBAN GLASS
  [
    {
      title: "Capella",
      url: "capella",
      systemID: 9,
    },
  ],
  // GRANDE
  [
    {
      title: "Capella",
      url: "capella",
      systemID: 9,
    },
  ],
  // DYNAMIC
  [
    {
      title: "Capella",
      url: "capella",
      systemID: 9,
    },
  ],
  // PANORA DOUBLE
  [
    {
      title: "Capella",
      url: "capella",
      systemID: 9,
    },
  ],
  // PERGOLA
  [],
  // ROOMY
  [
    {
      title: "Capella",
      url: "capella",
      systemID: 9,
    },
  ],
  // PERGOLA AVANTGARDE
  [],
  // POLAD
  [],
  // CABBANA X
  [],
  // LANAI X
  [],
  // Lenore
  [],
  // AVANTGARDE M
  [],
  // CABBANA NEW
  [],
  // DOOR
  [],
  // CABBANA ST
  [],
  // PERGOLA - ELEGANT ST
  [],
];

export const ligthingStyles = [
  {
    title: "Without",
    url: withoutLight,
    view: withoutLight,
    visible: false,
  },
  {
    title: "With",
    url: withLight,
    view: withLight,
    visible: true,
  },
];

export const comfortDesign = [
  {
    title: "Heater",
    url: element_Heat,
  },
  {
    title: "Fan profile",
    url: element_Sound,
  },
];

export const lengths = [
  // POINT
  {
    int_width: 4000,
    min_width: 2400,
    max_width: 12000,
    int_depth: 3000,
    min_depth: 2400,
    max_depth: 4500,
    int_height1: 2000,
    min_height1: 1950,
    max_height1: 2600,
    int_height2: 2600,
    min_height2: 2400,
    max_height2: 3300,
    default_width: 4000,
    default_depth: 3000,
    default_height1: 2000,
    default_height2: 2600,
    mid_length_limit: [4270, 8540],
    int_div_width: 1000,
    top_div_width: 20,
    top_glass_width: 780,
    column_width: 400,
    column_thickness: 120,
    bar_width: 100,
    ox: 2000,
    oy: 1480,
    oz: 1300,
    unit: 10,
    thickness: 150,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    max_div_width: 1100,
  },
  // CABBANA
  {
    int_width: 4000,
    min_width: 2440,
    max_width: 11910,
    int_depth: 3000,
    min_depth: 2400,
    max_depth: 7000,
    int_height1: 2500,
    min_height1: 2500,
    max_height1: 3000,
    int_height2: 2500,
    min_height2: 2500,
    max_height2: 3300,
    default_width: 4040,
    default_depth: 3000,
    default_height1: 2500,
    default_height2: 2500,
    mid_length_limit: [6240, 13000],
    int_div_width: 200,
    top_div_width: 10,
    top_glass_width: 780,
    column_width: 170,
    column_thickness: 170,
    top_div_length: 2765,
    column_height: 2000,
    bar_width: 100,
    ox: 2000,
    oy: 1480,
    oz: 3000,
    unit: 0.1,
    thickness: 220,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    length_light: 2400,
    profile_thickness: 285,
    max_div_width: 1100,
  },
  // PANORA
  {
    int_width: 4000,
    min_width: 2400,
    max_width: 12000,
    int_depth: 3000,
    min_depth: 2400,
    max_depth: 8000,
    int_height1: 2500,
    min_height1: 2500,
    max_height1: 3000,
    int_height2: 2500,
    min_height2: 2500,
    max_height2: 3000,
    default_width: 4000,
    default_depth: 3000,
    default_height1: 3000,
    default_height2: 3000,
    mid_length_limit: [6000, 12000],
    int_div_width: 800,
    top_div_width: 80,
    top_glass_width: 720,
    column_width: 170,
    column_thickness: 170,
    top_div_length: 2765,
    column_height: 2000,
    bar_width: 100,
    ox: 2000,
    oy: 1480,
    oz: 3000,
    unit: 0.1,
    thickness: 220,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    length_light: 2400,
    profile_thickness: 285,
    max_div_width: 1100,
  },
  // URBAN
  {
    int_width: 4000,
    min_width: 2400,
    max_width: 12000,
    int_depth: 3000,
    min_depth: 2400,
    max_depth: 4500,
    int_height1: 2000,
    min_height1: 2300,
    max_height1: 2600,
    int_height2: 2600,
    min_height2: 2400,
    max_height2: 3300,
    default_width: 3300,
    default_depth: 3000,
    default_height1: 2280,
    default_height2: 2800,
    mid_length_limit: [4270, 8540],
    int_div_width: 1000,
    top_div_width: 20,
    top_glass_width: 780,
    column_width: 100,
    column_depth: 120,
    column_thickness: 100,
    bar_width: 100,
    ox: 2000,
    oy: 1480,
    oz: 1300,
    unit: 10,
    thickness: 150,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    max_div_width: 1100,
  },
  // LANAI
  {
    int_width: 4000,
    min_width: 2440,
    max_width: 11910,
    int_depth: 3000,
    min_depth: 2400,
    max_depth: 6650,
    int_height1: 2500,
    min_height1: 2500,
    max_height1: 3000,
    int_height2: 2500,
    min_height2: 2500,
    max_height2: 3300,
    default_width: 4040,
    default_depth: 3000,
    default_height1: 2500,
    default_height2: 2500,
    mid_length_limit: [6240, 13000],
    int_div_width: 200,
    top_div_width: 10,
    top_glass_width: 780,
    column_width: 170,
    column_thickness: 170,
    top_div_length: 2765,
    column_height: 2000,
    bar_width: 100,
    ox: 2000,
    oy: 1480,
    oz: 3000,
    unit: 0.1,
    thickness: 220,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    length_light: 2400,
    profile_thickness: 285,
    max_div_width: 1100,
  },
  // URBAN GLASS
  {
    int_width: 4000,
    min_width: 2400,
    max_width: 10300,
    int_depth: 3000,
    min_depth: 2400,
    max_depth: 3300,
    int_height1: 2000,
    min_height1: 1950,
    max_height1: 2457,
    int_height2: 2600,
    min_height2: 2400,
    max_height2: 3300,
    default_width: 3300,
    default_depth: 3000,
    default_height1: 2280,
    default_height2: 2800,
    mid_length_limit: [4000, 8000],
    int_div_width: 700,
    top_div_width: 20,
    top_glass_width: 780,
    column_width: 100,
    column_depth: 120,
    column_thickness: 100,
    bar_width: 100,
    ox: 2000,
    oy: 1480,
    oz: 1300,
    unit: 10,
    thickness: 150,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    max_div_width: 1100,
  },
  // GRANDE
  {
    int_width: 3740,
    min_width: 2400,
    max_width: 12000,
    int_depth: 2700,
    min_depth: 2400,
    max_depth: 4500,
    int_height1: 2760,
    min_height1: 2300,
    max_height1: 2800,
    int_height2: 3650,
    min_height2: 2700,
    max_height2: 4500,
    default_width: 4000,
    default_depth: 3000,
    default_height1: 2300,
    default_height2: 3100,
    mid_length_limit: [4000, 8000],
    int_div_width: 910,
    top_div_width: 56,
    top_glass_width: 904.5,
    top_glass_height: 1326,
    column_width: 125,
    column_depth: 180,
    column_thickness: 125,
    bar_width: 100,
    ox: 2000,
    oy: 1350,
    oz: 1825,
    unit: 10,
    thickness: 207,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    max_div_width: 1100,
  },
  // DYNAMIC
  {
    int_width: 5880,
    min_width: 2700,
    max_width: 12000,
    int_depth: 4540,
    min_depth: 2700,
    max_depth: 4000,
    int_height1: 2300,
    min_height1: 2100,
    max_height1: 2800,
    int_height2: 2930,
    min_height2: 3000,
    max_height2: 3500,
    default_width: 4000,
    default_depth: 3500,
    default_height1: 2300,
    default_height2: 3000,
    mid_length_limit: [4000, 8000],
    int_div_width: 1050,
    top_div_width: 152,
    top_glass_width: 1050,
    top_glass_height: 1400,
    column_width: 126,
    column_depth: 180,
    column_thickness: 126,
    bar_width: 126,
    ox: 0,
    oy: 0,
    oz: 0,
    unit: 10,
    thickness: 380,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    max_div_width: 1500,
    water_profile_width: 6000,
  },
  // PANORA PLUS
  {
    int_width: 4000,
    min_width: 2400,
    max_width: 12000,
    int_depth: 3000,
    min_depth: 2400,
    max_depth: 8000,
    int_height1: 2500,
    min_height1: 2500,
    max_height1: 3000,
    int_height2: 2500,
    min_height2: 2500,
    max_height2: 3300,
    default_width: 4000,
    default_depth: 3000,
    default_height1: 3000,
    default_height2: 3000,
    mid_length_limit: [6000, 12000],
    int_div_width: 800,
    top_div_width: 80,
    top_glass_width: 720,
    column_width: 170,
    column_thickness: 170,
    top_div_length: 2765,
    column_height: 2000,
    bar_width: 100,
    ox: 2000,
    oy: 1480,
    oz: 3000,
    unit: 0.1,
    thickness: 220,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    length_light: 2400,
    profile_thickness: 250,
    max_div_width: 1100,
  },

  // // PERGOLA ORIGIN
  // {
  //     int_width: 3750,
  //     min_width: 2400,
  //     max_width: 12000,
  //     int_depth: 5240,
  //     min_depth: 2400,
  //     max_depth: 6500,
  //     int_height1: 2740,
  //     min_height1: 2200,
  //     max_height1: 2800,
  //     int_height2: 3840,
  //     min_height2: 3000,
  //     max_height2: 4000,
  //     default_width: 4000,
  //     default_depth: 3000,
  //     default_height1: 2200,
  //     default_height2: 3000,
  //     mid_length_limit: [5000, 10000],
  //     int_div_width: 910,
  //     top_div_width: 56,
  //     top_glass_width: 904.5,
  //     top_glass_height: 1326,
  //     column_width: 100,
  //     column_depth: 130,
  //     column_thickness: 130,
  //     bar_width: 100,
  //     ox: 2000,
  //     oy: 2520,
  //     oz: 1900,
  //     unit: 1,
  //     thickness: 170,
  //     LightPosition: 817,
  //     RightPosition: 797,
  //     LineColor: '#fff',
  //     LineEndColor: '#c3c3c3',
  //     diffHeights: 400,
  //     lengthEndLineWidth: 120,
  //     lengthInfoDist: 100,
  //     max_div_width: 1100,
  //     water_profile_width: 3870,
  // },

  // PERGOLA
  {
    int_width: 6320,
    min_width: 2400,
    max_width: 10000,
    int_depth: 6230,
    min_depth: 2400,
    max_depth: 6500,
    int_height1: 2830,
    min_height1: 2200,
    max_height1: 2800,
    int_height2: 3960,
    min_height2: 3000,
    max_height2: 4500,
    default_width: 4000,
    default_depth: 3000, // 3000
    default_height1: 2200, // 2200
    default_height2: 3000, // 3000
    mid_length_limit: [4000, 8000],
    int_div_width: 910,
    top_div_width: 56,
    top_glass_width: 904.5,
    top_glass_height: 1326,
    column_width: 100,
    column_depth: 130,
    column_thickness: 130,
    bar_width: 100,
    ox: 2000,
    oy: 3040,
    oz: 1970,
    unit: 1,
    thickness: 207,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    max_div_width: 1100,
    water_profile_width: 6600,
  },
  // ROOMY
  {
    int_width: 3740,
    min_width: 2400,
    max_width: 12000,
    int_depth: 2700,
    min_depth: 4950,
    max_depth: 9000,
    int_height1: 2760,
    min_height1: 2300,
    max_height1: 2800,
    int_height2: 3650,
    min_height2: 2700,
    max_height2: 3500,
    default_width: 4000,
    default_depth: 6150,
    default_height1: 2300,
    default_height2: 3210,
    mid_length_limit: [4000, 8000],
    int_div_width: 910,
    top_div_width: 56,
    top_glass_width: 904.5,
    top_glass_height: 1326,
    column_width: 125,
    column_depth: 180,
    column_thickness: 125,
    bar_width: 100,
    ox: 2000,
    oy: 1350,
    oz: 1825,
    unit: 10,
    thickness: 207,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    max_div_width: 1100,
    center_width: 3735,
    center_depth: 2507,
    center_thickness: 150,
    center_height2: 3640,
    center_height1: 2640,
  },
  // PERGOLA AVANTGARDE
  {
    int_width: 6320,
    min_width: 2400,
    max_width: 10000,
    int_depth: 6230,
    min_depth: 2400,
    max_depth: 10000,
    int_height1: 2830,
    min_height1: 2200,
    max_height1: 2800,
    int_height2: 3960,
    min_height2: 3000,
    max_height2: 4500,
    default_width: 4000,
    default_depth: 3000, // 3000
    default_height1: 2200, // 2200
    default_height2: 3000, // 3000
    mid_length_limit: [4000, 8000],
    int_div_width: 910,
    top_div_width: 56,
    top_glass_width: 904.5,
    top_glass_height: 1326,
    column_width: 125,
    column_depth: 130,
    column_thickness: 130,
    bar_width: 100,
    ox: 2000,
    oy: 3040,
    oz: 1970,
    unit: 1,
    thickness: 207,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    max_div_width: 1100,
    water_profile_width: 6600,
  },
  // POLAD
  {
    int_width: 6050,
    min_width: 2400,
    max_width: 10000,
    int_depth: 5140,
    min_depth: 2400,
    max_depth: 10000,
    int_height1: 3010,
    min_height1: 2200,
    max_height1: 2800,
    int_height2: 4160,
    min_height2: 3000,
    max_height2: 4500,
    default_width: 6050,
    default_depth: 5140,
    default_height1: 3010,
    default_height2: 4160,
    mid_length_limit: [5000, 10000],
    int_div_width: 1000,
    back_depth: 1050,
    top_div_width: 56,
    top_glass_width: 950,
    top_glass_height: 1326,
    top_width: 5748.13,
    column_width: 170,
    column_depth: 170,
    column_thickness: 170,
    bar_width: 170,
    ox: 2000,
    oy: 3040,
    oz: 0,
    unit: 1,
    thickness: 207,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    max_div_width: 1100,
    water_profile_width: 6050,
    horizontal_profile_width: 5780,
  },
  // CABBANA X
  {
    int_width: 4000,
    min_width: 2440,
    max_width: 11910,
    int_depth: 3000,
    min_depth: 2400,
    max_depth: 8550,
    int_height1: 2500,
    min_height1: 2500,
    max_height1: 3000,
    int_height2: 2500,
    min_height2: 2500,
    max_height2: 3300,
    default_width: 4040,
    default_depth: 3000,
    default_height1: 2500,
    default_height2: 2500,
    mid_length_limit: [6240, 13000],
    int_div_width: 200,
    top_div_width: 10,
    top_glass_width: 780,
    column_width: 170,
    column_thickness: 170,
    top_div_length: 2765,
    column_height: 2000,
    bar_width: 100,
    ox: 2000,
    oy: 1480,
    oz: 3000,
    unit: 0.1,
    thickness: 220,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    length_light: 2400,
    profile_thickness: 285,
    max_div_width: 1100,
  },
  // LANAI X
  {
    int_width: 4000,
    min_width: 2440,
    max_width: 11910,
    int_depth: 3000,
    min_depth: 2400,
    max_depth: 8550,
    int_height1: 2500,
    min_height1: 2500,
    max_height1: 3000,
    int_height2: 2500,
    min_height2: 2500,
    max_height2: 3300,
    default_width: 4040,
    default_depth: 3000,
    default_height1: 2500,
    default_height2: 2500,
    mid_length_limit: [6240, 13000],
    int_div_width: 200,
    top_div_width: 10,
    top_glass_width: 780,
    column_width: 170,
    column_thickness: 170,
    top_div_length: 2765,
    column_height: 2000,
    bar_width: 100,
    ox: 2000,
    oy: 1480,
    oz: 3000,
    unit: 0.1,
    thickness: 220,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    length_light: 2400,
    profile_thickness: 285,
    max_div_width: 1100,
  },
  // Lenore
  {
    int_width: 4000,
    min_width: 2440,
    max_width: 10500,
    int_depth: 3000,
    // int_depth: 5500,
    // min_depth: 2400,
    min_depth: 5440,
    //max_depth: 9000,
    max_depth: 6040,
    int_height1: 2500,
    min_height1: 2500,
    max_height1: 3000,
    int_height2: 2500,
    min_height2: 2500,
    max_height2: 3300,
    default_width: 3500,
    // default_depth: 3000,
    default_depth: 5640,
    // int_depth: 5500,
    default_height1: 2500,
    default_height2: 2500,
    // mid_length_limit: [6040, 13000],
    // mid_length_limit: [7200, 14400],
    mid_length_limit: [3600, 7200],
    int_div_width: 200,
    top_div_width: 10,
    int_div_depth: 200,
    top_glass_width: 780,
    column_width: 170,
    column_thickness: 170,
    top_div_length: 2765,
    column_height: 2000,
    bar_width: 100,
    ox: 2000,
    oy: 1480,
    oz: 3000,
    unit: 0.1,
    thickness: 220,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    length_light: 2400,
    profile_thickness: 285,
    max_div_width: 1100,
  },
  // AVANTGARDE M
  {
    int_width: 6320,
    min_width: 2400,
    max_width: 10000,
    int_depth: 6300, //6230
    min_depth: 4950,
    // max_depth: 10000,
    max_depth: 20000,
    int_height1: 2844,
    min_height1: 2200,
    max_height1: 2800,
    int_height2: 3960,
    min_height2: 3000,
    max_height2: 4500,
    default_width: 4000,
    default_depth: 6150, // 3000
    default_height1: 2200, // 2200
    default_height2: 3000, // 3000
    mid_length_limit: [4000, 8000],
    int_div_width: 910,
    top_div_width: 56,
    top_glass_width: 904.5,
    top_glass_height: 1326,
    column_width: 125,
    column_depth: 130,
    column_thickness: 130,
    bar_width: 100,
    ox: 2000,
    oy: 3040,
    oz: 1970,
    unit: 1,
    thickness: 207,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    max_div_width: 1100,
    water_profile_width: 6600,
    center_width: 6310,
    center_depth: 3100,
    center_thickness: 150,
    center_height2: 2880,
    center_height1: 2700,
    center_delta_height: 960,
  },
  // CABBANA NEW
  {
    int_width: 4000,
    min_width: 2440,
    max_width: 11910,
    int_depth: 3000,
    min_depth: 2400,
    max_depth: 6650,
    int_height1: 2500,
    min_height1: 2500,
    max_height1: 3000,
    int_height2: 2500,
    min_height2: 2500,
    max_height2: 3300,
    default_width: 4040,
    // default_width: 6400,
    default_depth: 3000,
    default_height1: 2500,
    default_height2: 2500,
    mid_length_limit: [6240, 13000],
    int_div_width: 200,
    top_div_width: 10,
    top_glass_width: 780,
    column_width: 170,
    column_thickness: 170,
    top_div_length: 2765,
    // top_div_length: 3760,
    column_height: 2000,
    bar_width: 100,
    ox: 2000,
    oy: 1480,
    oz: 3000,
    unit: 0.1,
    thickness: 220,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    length_light: 2400,
    profile_thickness: 285,
    max_div_width: 1100,
  },
  // DOOR
  {
    default_width: 900,
    default_depth: 20,
    default_height1: 2100,
    default_height2: 1000,
    mid_length_limit: [6040, 13000],
  },
  // CABBANA ST
  {
    int_width: 4000,
    min_width: 2440,
    max_width: 6240,
    int_depth: 3000,
    min_depth: 2400,
    max_depth: 3352.8,
    int_height1: 2500,
    min_height1: 2500,
    max_height1: 3000,
    int_height2: 2500,
    min_height2: 2500,
    max_height2: 3300,
    default_width: 5840,
    // default_width: 6400,
    default_depth: 3352.8,
    default_height1: 2500,
    default_height2: 2500,
    mid_length_limit: [6240, 13000],
    int_div_width: 200,
    top_div_width: 10,
    top_glass_width: 780,
    column_width: 170,
    column_thickness: 170,
    top_div_length: 2765,
    // top_div_length: 3760,
    column_height: 2000,
    bar_width: 100,
    ox: 2000,
    oy: 1480,
    oz: 3000,
    unit: 0.1,
    thickness: 220,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    length_light: 2400,
    profile_thickness: 285,
    max_div_width: 1100,
  },
  // PERGOLA - ELEGANT ST
  {
    int_width: 6320,
    min_width: 2400,
    max_width: 5500,
    int_depth: 6230,
    min_depth: 2400,
    max_depth: 6096,
    int_height1: 2830,
    min_height1: 2200,
    max_height1: 2800,
    int_height2: 3960,
    min_height2: 3000,
    max_height2: 4500,
    default_width: 5500,
    default_depth: 6096, // 6096
    default_height1: 2200, // 2200
    default_height2: 3000, // 3000
    mid_length_limit: [8000, 8000],
    int_div_width: 910,
    top_div_width: 56,
    top_glass_width: 904.5,
    top_glass_height: 1326,
    column_width: 100,
    column_depth: 130,
    column_thickness: 130,
    bar_width: 100,
    ox: 2000,
    oy: 3040,
    oz: 1970,
    unit: 1,
    thickness: 207,
    LightPosition: 817,
    RightPosition: 797,
    LineColor: "#fff",
    LineEndColor: "#c3c3c3",
    diffHeights: 400,
    lengthEndLineWidth: 120,
    lengthInfoDist: 100,
    max_div_width: 1100,
    water_profile_width: 6600,
  },
];

export const membershipPlans = [
  {
    id: 1,
    name: "Basic",
    title: "60% Off",
    price: 500,
    bgColor: "rgb(140, 168, 208)",
    description:
      "Choose this package if you sell 1-2 products. You pay only $500 per product.",
  },
  {
    id: 2,
    name: "Advanced",
    title: "60% Off",
    price: 1200,
    bgColor: "rgb(183, 213, 249)",
    description:
      "Choose this package if you sell 3-5 products. You pay only $1200.",
  },
  {
    id: 3,
    name: "Pro",
    title: "60% Off",
    price: 2000,
    bgColor: "rgb(86, 106, 143)",
    description:
      "Choose this package if you sell 6 and more products. You pay only $2000.",
  },
];

export const subsystem_lengths = [
  // Guillotine
  {
    // slider_min_width: 1200,
    slider_min_width: 2000,
    slider_max_width: 4000,
    slider_min_height: 2000,
    slider_max_height: 3500,
    default_width: 2000,
    default_height: 2000,
    // min_width: 1200,
    min_width: 2000,
    max_width: 4000,
    min_height: 2000,
    max_height: 3000,
  },
  // Classy
  {
    slider_min_width: 800,
    slider_max_width: 1800,
    slider_min_height: 1100,
    slider_max_height: 3000,
    default_width: 4000,
    default_height: 2200,
    // min_width: 1600,
    min_width: 2000,
    // max_width: 14400,
    max_width: 15000,
    // min_height: 1100,
    min_height: 2100,
    // max_height: 3000,
    max_height: 2800,
  },
  // Minima
  {
    slider_min_width: 600,
    slider_max_width: 1200,
    slider_min_height: 1100,
    slider_max_height: 2800,
    default_width: 5000,
    default_height: 2200,
    // min_width: 1200,
    min_width: 2000,
    max_width: 12000,
    // min_height: 1100,
    min_height: 2100,
    max_height: 2800,
  },
  // Fixed Glass
  {
    slider_min_width: 600,
    slider_max_width: 1200,
    slider_min_height: 1100,
    slider_max_height: 2800,
    default_width: 5000,
    default_height: 2200,
    min_width: 1200,
    max_width: 12000,
    min_height: 1100,
    max_height: 2800,
  },
  // Fixed Wall
  {
    slider_min_width: 600,
    slider_max_width: 1200,
    slider_min_height: 1100,
    slider_max_height: 2800,
    default_width: 5000,
    default_height: 2200,
    min_width: 1200,
    max_width: 12000,
    min_height: 1100,
    max_height: 2800,
  },
  // Lift and slide door
  {
    slider_min_width: 1000,
    slider_max_width: 2500,
    slider_min_height: 1800,
    slider_max_height: 3000,
    default_width: 3000,
    default_height: 2200,
    min_width: 2000,
    max_width: 15000,
    // min_height: 1800,
    min_height: 2100,
    max_height: 3000,
  },
  // Forte
  {
    slider_min_width: 600,
    slider_max_width: 1200,
    slider_min_height: 1100,
    slider_max_height: 2800,
    default_width: 5000,
    default_height: 2200,
    // min_width: 1200,
    min_width: 1000,
    max_width: 6000,
    // min_height: 2000,
    min_height: 1200,
    max_height: 4700,
  },
  // Zion
  {
    slider_min_width: 600,
    slider_max_width: 1200,
    slider_min_height: 1100,
    slider_max_height: 2800,
    default_width: 5000,
    default_height: 2200,
    // min_width: 1200,
    min_width: 2000,
    max_width: 12000,
    // min_height: 1100,
    min_height: 2200,
    // max_height: 2800,
    max_height: 3000,
  },
  // Bifold door
  {
    slider_min_width: 700,
    slider_max_width: 1200,
    slider_min_height: 1500,
    slider_max_height: 3000,
    default_width: 2000,
    default_height: 2200,
    // min_width: 1400,
    min_width: 2000,
    max_width: 16800,
    // min_height: 1500,
    min_height: 2100,
    max_height: 3000,
  },
  // Capella
  {
    slider_min_width: 700,
    slider_max_width: 1200,
    slider_min_height: 1500,
    slider_max_height: 3000,
    default_width: 4000,
    default_height: 3000,
    // min_width: 1200,
    min_width: 1500,
    max_width: 6000,
    // min_height: 2000,
    min_height: 1500,
    // max_height: 3000,
    max_height: 6000,
  },
  // Door
  {
    slider_min_width: 800,
    slider_max_width: 1800,
    slider_min_height: 1100,
    slider_max_height: 3000,
    default_width: 900,
    default_height: 2200,
    // min_width: 1600,
    min_width: 2000,
    // max_width: 14400,
    max_width: 15000,
    // min_height: 1100,
    min_height: 2100,
    // max_height: 3000,
    max_height: 2800,
  },
];

export const columnFlag = [
  [true, true, true, true, true, true, false, false], // POINT
  [true, true, true, true, true, true, false, false], // CABBANA
  [true, true, true, true, true, true, false, false], // PANORA
  [true, true, true, true, true, true, false, false], // URBAN
  [true, true, true, true, true, true, false, false], // LANAI
  [true, true, true, true, true, true, false, false], // URBAN GLASS
  [true, true, true, true, true, true, false, false], // GRANDE
  [true, true, true, true, true, true, false, false], // DYNAMIC
  [true, true, true, true, true, true, false, false], // PANORA DOUBLE
  [true, true, true, true, true, true, false, false], // PERGOLA
  [true, true, true, true, true, true, false, false], // ROOMY
  [true, true, true, true, true, true, false, false], // PERGOLA AVANTGARDE
  [true, true, true, true, true, true, false, false], // POLAD
  [true, true, true, true, true, true, false, false], // CABBANA X
  [true, true, true, true, true, true, false, false], // LANAI X
  [true, true, true, true, true, true, false, false], // Lenore
  [true, true, true, true, true, true, false, false], // AVANTGARDE M
  [true, true, true, true, true, true, false, false], // CABBANA NEW
  [true, true, true, true, true, true, false, false], // DOOR
  [true, true, true, true, true, true, false, false], // CABBANA ST
  [true, true, true, true, true, true, false, false], // PERGOLA - ELEGANT ST

];

export const secondHeight = [
  true, // POINT
  false, // CABBANA
  false, // PANORA
  true, // URBAN
  false, // LANAI
  true, // URBAN GLASS
  true, // GRANDE
  true, // DYNAMIC
  false, // PANORA DOUBLE
  true, // PERGOLA
  true, // ROOMY
  true, // PERGOLA AVANTGARDE
  true, // POLAD
  false, // CABBANA X
  false, // LANAI X
  false, // Lenore
  true, // AVANTGARDE M
  false, // CABBANA NEW
  false, // DOOR
  false, // CABBANA ST
  true, // PERGOLA - ELEGANT ST
];

export const hasBlade = [
  { option: false }, // POINT
  { option: true, label: "Blade rotation", isTexture: true }, // CABBANA
  { option: true, label: "Mounting wall", isTexture: false }, // PANORA
  { option: false }, // URBAN
  { option: true, label: "Blade rotation", isTexture: true }, // LANAI
  { option: false }, // URBAN GLASS
  { option: false }, // GRANDE
  { option: false }, // DYNAMIC
  { option: true, label: "Mounting wall", isTexture: false }, // PANORA DOUBLE
  { option: false }, // PERGOLA
  { option: false }, // ROOMY
  { option: false }, // PERGOLA AVANTGARDE
  { option: false }, // POLAD
  { option: true, label: "Blade rotation", isTexture: true }, // CABBANA X
  { option: true, label: "Blade rotation", isTexture: true }, // LANAI X
  { option: false }, // Lenore
  { option: false }, // AVANTGARDE M,
  { option: true, label: "Blade rotation", isTexture: true }, // CABBANA NEW
  { option: true, label: "Opening Door", isTexture: false }, // DOOR
  { option: true, label: "Blade rotation", isTexture: true }, // CABBANA ST
  { option: false }, // PERGOLA - ELEGANT ST
];

export const hasLight = [
  true, // POINT
  true, // CABBANA
  true, // PANORA
  true, // URBAN
  true, // LANAI
  false, // URBAN GLASS
  true, // GRANDE
  true, // DYNAMIC
  false, // PANORA DOUBLE
  true, // PERGOLA
  false, // ROOMY
  true, // PERGOLA AVANTGARDE
  true, // POLAD
  true, // CABBANA X
  true, // LANAI X
  true, // Lenore
  true, // AVANTGARDE M
  true, // CABBANA NEW
  false, // DOOR
  true, // CABBANA ST
  true, // PERGOLA - ELEGANT ST
];

export const hasMotor = [
  false, // POINT
  true, // CABBANA
  false, // PANORA
  false, // URBAN
  true, // LANAI
  false, // URBAN GLASS
  false, // GRANDE
  true, // DYNAMIC
  false, // PANORA DOUBLE
  true, // PERGOLA
  false, // ROOMY
  true, // PERGOLA AVANTGARDE
  true, // POLAD
  true, // CABBANA X
  true, // LANAI X
  true, // Lenore
  true, // AVANTGARDE M,
  true, // CABBANA NEW
  false, // DOOR
  true, // CABBANA ST
  true, // PERGOLA - ELEGANT ST
];

export const minAngle = [
  0, // POINT
  0, // CABBANA
  0, // PANORA
  0, // URBAN
  0, // LANAI
  0, // URBAN GLASS
  5, // GRANDE
  0, // DYNAMIC
  0, // PANORA PLUS
  5, // PERGOLA ELEGANT
  5, // ROOMY
  5, // PERGOLA AVANTGARDE
  0, // POLAD
  0, // CABBANA X
  0, // LANAI X
  0, // Lenore
  3.6, // AVANTGARDE M
  0, // CABBANA NEW
  0, // DOOR
  0, // CABBANA ST
  5, // PERGOLA ELEGANT ST
];

export const maxAngle = [
  0, // POINT
  0, // CABBANA
  0, // PANORA
  0, // URBAN
  0, // LANAI
  0, // URBAN GLASS
  18, // GRANDE
  0, // DYNAMIC
  0, // PANORA PLUS
  15, // PERGOLA ELEGANT
  18, // ROOMY
  15, // PERGOLA AVANTGARDE
  0, // POLAD
  0, // CABBANA X
  0, // LANAI X
  0, // Lenore
  6.4, // AVANTGARDE M
  0, // CABBANA NEW
  0, // DOOR
  0, // CABBANA ST
  15, // PERGOLA ELEGANT ST
];

export const successMsg =
  "Thank you for your price request! We will contact with you soon!";
export const errorMsg = "Server connection error!";

export const oawologo = ologo;
export const logo = logoImg;
export const logourl = logo_main;
export const reglogo = oawo180;
export const logoWithoutName = logoIco;
export const loginBackground = login_background;
export const thankyouIcon = thankyou_icon;
export const forteTexture = forte_texture;
export const forteOpacity = 1;
export const viewIcon = view_icon;

export const matchUrlTitle = {
  cabbana: "Cabbana",
  lanai: "Lanai",
  urban: "Urban",
  urbanglass: "Urban Glass",
  panora: "Panora",
  panoradouble: "Panora Plus",
  grande: "Grande",
  dynamic: "Dynamic",
  roomy: "Roomy",
  point: "Point",
  cabbana: "Cabbana",
  minima: "Minima",
  classy: "Classy",
  guillotine: "Guillotine Compact",
  liftandslidedoor: "Lift And Slide Door",
  bifolddoor: "Bifold Door",
  pergola: "Elegant",
  zion: "Zion",
  forte: "Forte",
  capella: "Capella",
  pergolaavantgarde: "Avantgarde",
  polad: "Polad",
  fixedwall: "Fixed Wall",
  fixedglass: "Fixed Glass",
  cabbanas: "Cabbana X",
  lanais: "Lanai X",
  lenore: "Lenore",
  avantgardes: "Avantgarde M",
  cabbanan: "Cabbana",
  cabbanast: "Cabbana ST",
  pergolast: "Elegant ST",
};

export const info = [
  {
    label: "Google",
    icon: "",
  },
  {
    label: "Facebook",
    icon: "",
  },
  {
    label: "Instagram",
    icon: "",
  },
  {
    label: "Word of mouth",
    icon: "",
  },
  {
    label: "Others",
    icon: "",
  },
];

export const wallTopHeight = 400;
export const wallThickness = 200;
export const wallLast = 300;
export const wallColor = "#dddddd";
export const wallBorderColor = "#ffffff";
export const wallOpacity = 0.5;
export const lightColor = "#ffff00";
export const autoRotateSpeed = 0;
export const xrepeat = 0.01;
export const yrepeat = 0.02;
export const handleHeight = 1000;
export const default_forte_pos = 35;
export const model_images = [
  cabbana,
  classy,
  compact,
  forte,
  lanai,
  minima,
  panora,
  patiocover,
  point,
  urban,
  zion,
];

export const embed_key = "As3reWd26seSch4ild387rRhfsd";
export const default_brand_color = "rgba(115, 150, 174, 1)";
export const errorMessage = {
  Product_not_assigned: {
    title: "Product not found.",
    desc1:
      "We apologize for the inconvenience. The product you are looking for has already been sold out and is no longer available for this company. We recommend checking back later as the company may introduce new products in the future.",
    desc2:
      "If you would like to be informed when the product becomes available again, please fill out the form below. Thank you for your understanding.",
  },
  Page_error: {
    title: "Something went wrong.",
    desc1:
      "We apologize for the inconvenience caused. Unfortunately, an unexpected error has occurred. We kindly request you to try again later. Alternatively, if you would like to receive further information regarding this error, please take a moment fill out the form below.",
    desc2:
      "Our team will promptly assist you and provide the relevant details. Thank you for your understanding.",
  },
};

export const modelIDLinks = [
  'point',
  'cabbana',
  'panora',
  'urban',
  'lanai',
  'urbanglass',
  'grande',
  'dynamic',
  'panoradouble',
  'pergola',
  'roomy',
  'pergolaavantgarde',
  'polad',
  'cabbanas',
  'lanais',
  'lenore',
  'avantgardes',
  'cabbanan',
  'door',
  'cabbanast',
  'pergolast',
];
