// import React, { useRef, useEffect, useState } from "react";
// import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import { ToastContainer } from 'react-toastify';
// import { useSnapshot } from "valtio";

// import Home from './pages/Home';
// import Login from './pages/auth/login';
// import Point from './pages/Point';
// import Cabbana from './pages/Cabbana';
// import Panora from './pages/Panora';
// import Urban from './pages/Urban';
// import UrbanGlass from './pages/UrbanGlass';
// import Lanai from './pages/Lanai';
// import Orders from './pages/Orders';
// import Clients from './pages/Clients';
// import Guillotine from './pages/Guillotine';

// // import Toolbar from './common/Toolbar';
// import state from "./state";
// import Loading from './common/Loading/Loading';

// import "./assets/css/App.css";
// 

// function App() {
//   const group = useRef();
//   const snap = useSnapshot(state);

//   const [scale, setScale] = useState(1.0);
//   const [subStep, setSubStep] = useState(0);
//   const [sidebar, setSidebar] = useState(true);
//   const [modelID, setModelID] = useState(0);
//   const [isloading, setIsloading] = useState(false);
//   const [activeStep, setActiveStep] = useState(0);
//   const [darkMode, setDarkMode] = useState(false);
//   const [isLoggedIn, setIsLoggedIn] = useState(
//     () => localStorage.getItem('user') !== null
//   );

//   useEffect(() => {
//     setTimeout(() => {
//       setIsloading(true);
//     }, 1000);
//   });

//   const open = snap.editingSubSystem;

//   return isloading ? (
//     <AppContext.Provider
//       value={{
//         activeStep,
//         setActiveStep,
//         subStep,
//         setSubStep,
//         sidebar,
//         setSidebar,
//         modelID,
//         setModelID,
//       }}
//     >
//       {/* <Toolbar scale={scale} setScale={setScale} darkMode={darkMode} setDarkMode={setDarkMode}/> */}
//       <Preview open={open} />
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={isLoggedIn ? <Home /> : <Navigate to='/login' />} />
//           <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
//           <Route path="/orders" element={isLoggedIn ? <Orders /> : <Navigate to='/login' />} />
//           <Route path="/clients" element={isLoggedIn ? <Clients /> : <Navigate to='/login' />} />
//           <Route path="/point" element={isLoggedIn ? <Point group={group} scale={scale} darkMode={darkMode} /> : <Navigate to='/login' />} />
//           <Route path="/cabbana" element={isLoggedIn ? <Cabbana group={group} scale={scale} darkMode={darkMode} /> : <Navigate to='/login' />} />
//           <Route path="/panora" element={isLoggedIn ? <Panora group={group} scale={scale} darkMode={darkMode} /> : <Navigate to='/login' />} />
//           <Route path="/urban" element={isLoggedIn ? <Urban group={group} scale={scale} darkMode={darkMode} /> : <Navigate to='/login' />} />
//           <Route path="/lanai" element={isLoggedIn ? <Lanai group={group} scale={scale} darkMode={darkMode} /> : <Navigate to='/login' />} />
//           <Route path="/urbanglass" element={isLoggedIn ? <UrbanGlass group={group} scale={scale} darkMode={darkMode} /> : <Navigate to='/login' />} />
//           <Route path="/guillotine" element={isLoggedIn ? <Guillotine group={group} scale={scale} darkMode={darkMode} /> : <Navigate to='/login' />} />
//           <Route path="/iframe" element={<Home />} />
//         </Routes>
//       </BrowserRouter>
//       <ToastContainer />
//     </AppContext.Provider>
//   ) : (<Loading />);
// }

// export default App;

import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AppContext from "./context/AppContext";
import Routes from './routes/index.js';
import BladeOption from './common/BladeOption';
import ErrorBoundary from './common/ErrorBoundary';
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './utils/style.css'



const App = () => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null;
  const [subStep, setSubStep] = useState(0);
  const [sidebar, setSidebar] = useState(true);
  const [modelID, setModelID] = useState(0);
  const [systemID, setSystemID] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [bladeOption, setBladeOption] = useState(false);
  const [selDoor, setSelDoor] = useState(0)
  const [install, setInstall] = useState('None');
  const [openSendForm, setOpenSendForm] = useState(false);
  const [openOrderForm, setOpenOrderForm] = useState(false);
  const [openOrderId, setOpenOrderId] = useState(-1);
  // const [loadingModel, setLoadingModel] = useState(false);
  const [modelCount, setModelCount] = useState(1);
  const [mode, setMode] = useState(0);
  const [showprice, setShowprice] = useState(0);
  const [brandColor, setBrandColor] = useState(!user || user.brand_color === null || user.brand_color === 'null' ? 'rgba(115, 150, 174, 1)' : user.brand_color);
  const [flag, setFlag] = useState(true);
  const [balance, setBalance] = useState(0);
  const [totalPrice, setTP] = useState(0);
  const [optionals, setOptionals] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [priceData, setPriceData] = useState([]);
  const [profile, setProfile] = useState(false);
  const [userFrom, setUserFrom] = useState("user");

  return (
    <AppContext.Provider
      value={{
        profile,
        setProfile,
        priceData,
        setPriceData,
        cartItems,
        setCartItems,
        optionals,
        setOptionals,
        totalPrice,
        setTP,
        balance,
        setBalance,
        activeStep,
        setActiveStep,
        subStep,
        setSubStep,
        sidebar,
        setSidebar,
        modelID,
        setModelID,
        systemID,
        setSystemID,
        bladeOption,
        setBladeOption,
        install,
        setInstall,
        openSendForm,
        setOpenSendForm,
        openOrderForm,
        setOpenOrderForm,
        modelCount,
        setModelCount,
        mode,
        setMode,
        brandColor,
        setBrandColor,
        flag,
        setFlag,
        showprice,
        setShowprice,
        openOrderId,
        setOpenOrderId,
        selDoor,
        setSelDoor,
        userFrom,
        setUserFrom,
      }}>
      <ErrorBoundary>
        <BrowserRouter basename="">
          <BladeOption />
          <Routes />
        </BrowserRouter>
        <ToastContainer />
      </ErrorBoundary>
    </AppContext.Provider>
  );
}

export default App;