import React from "react";
import FacebookIconImage from "../../assets/img/svg/facebook_icon.png";
import InstagramIconImage from "../../assets/img/svg/instagram_icon.png";
import YoutubeIconImage from "../../assets/img/svg/youtube_icon.png";

const InstagramIcon = (props) => {
  const { fontSize, color } = props;
  let style =
    fontSize === "large"
      ? { width: 50, height: 50 }
      : fontSize === "medium"
      ? { width: 36, height: 36 }
      : { width: 24, height: 24 };
  if (color === "black") style = { ...style, filter: "invert(1)" };
  return <img src={InstagramIconImage} alt="Instagram Icon" style={style} />;
};

const FacebookIcon = (props) => {
  const { fontSize, color } = props;
  let style =
    fontSize === "large"
      ? { width: 50, height: 50 }
      : fontSize === "medium"
      ? { width: 36, height: 36 }
      : { width: 24, height: 24 };
  if (color === "black") style = { ...style, filter: "invert(1)" };
  return <img src={FacebookIconImage} alt="Facebook Icon" style={style} />;
};

const YoutubeIcon = (props) => {
  const { fontSize, color } = props;
  let style =
    fontSize === "large"
      ? { width: 50, height: 50 }
      : fontSize === "medium"
      ? { width: 36, height: 36 }
      : { width: 24, height: 24 };
  if (color === "black") style = { ...style, filter: "invert(1)" };
  return <img src={YoutubeIconImage} alt="Youtube Icon" style={style} />;
};

export { InstagramIcon, FacebookIcon, YoutubeIcon };
