import React, {useState, useRef, useEffect} from 'react';
import {Box, Tooltip, IconButton, Dialog, Typography, MenuItem, Menu} from '@mui/material';
import storageService from 'services/storage.service';
import { useTranslation } from "react-i18next";
import langService from 'services/lang.service';

export default function Lang(props) {

    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const savedLang = storageService.get('lang') || 'EN';
    const [lang, setLang] = useState(savedLang);
    const [availableLangs, setAvailableLangs] = useState([]);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleMenuItemClick = (language) => {
      setLang(language);
      storageService.set('lang', language);
      i18n.changeLanguage(language.toLowerCase());
      i18n.reloadResources();
      handleClose();
    };

    useEffect(() => {
        // Update the body background when `open` value changes
        if (open) {
          document.body.style.backgroundColor = '#eeeeee'; // Set your desired background color
        } else {
          document.body.style.backgroundColor = ''; // Reset the background color
        }

        langService.getLangs().then((langs) => {
          setAvailableLangs(langs);
        });
    }, [open]);

    return (
      <div>
        <Tooltip>
          <IconButton
            onClick={handleClick}
            size="medium"
            aria-controls={anchorEl ? 'language-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? 'true' : undefined}
          >
            <Typography className="zoom-animation">
              {i18n.language.toUpperCase()}
            </Typography>
          </IconButton>
        </Tooltip>
        <Menu
          id="language-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {
            availableLangs.map((lang, index) => (
              <MenuItem key={index} onClick={() => handleMenuItemClick(lang.code)}>{lang.language}</MenuItem>
            ))
          }
        </Menu>
      </div>
    );
}