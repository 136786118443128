import { lazy } from 'react';
import MainLayout from '../layouts/MainLayout';
import Loadable from '../components/Loadable';
import AuthGuard from "../common/authguard";

const Test = Loadable(lazy(() => import('../pages/Test')));
const TestScene = Loadable(lazy(() => import('../pages/TestScene')));

const MainRoutes = {
    path: 'test',
    element: <MainLayout />,
    children: [
        {
            path: 'testmodel',
            element: (
                <AuthGuard>
                    <Test  />
                </AuthGuard>
            )
        },
        {
            path: 'scene',
            element: (
                <TestScene  />
            )
        },

    ],
};

export default MainRoutes;