import React, { useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import AppContext from 'context/AppContext';
import { ConfigContext } from 'context/config';
import { logo, logourl, SERVER_URL } from '../utils/constant.js';
import { constructUrl } from "utils/helpers.js";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: 568, xs: 350 },
    bgcolor: 'background.paper',
    borderRadius: .5,
    boxShadow: 24,
    p: 4,
};

const ModelLogHome = (props) => {
    const navigate = useNavigate();
    const { lang, id, modelID,  key } = useParams();
    const [open, setOpen] = useState(false);
    const { mode } = useContext(AppContext);
    const { isDark } = useContext(ConfigContext);
    const handleClose = () => setOpen(false);
    const handleOpen = () => {
        if (!key) {
            setOpen(true);
        }
    }
    const goHome = (flag) => {
        if(modelID) navigate(`/allproducts${constructUrl(id,lang)}`)
        if (!mode) navigate('/');
    }

    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : JSON.parse(localStorage.getItem('embed_user'));
    const company_logo = mode === 0 ? (SERVER_URL + user.company_logo) : (SERVER_URL + localStorage.getItem('company_logo'));
    const logo_option = mode === 0 ? user.logo_option : localStorage.getItem('logo_option');
    
    return (
        <>
            <Box >
                {logo_option !==null && logo_option !== 'null'? <Box onClick={e=>{e.preventDefault(); goHome(true)}} component='img' src={logo_option === '0'||logo_option==='2'?company_logo:(isDark || props.pos !== 'home' ? logo:logourl)} sx={{ position: { md: 'absolute', xs: 'fixed' }, width: { md: 147, xs: 110 }, top: { md: props.pos==="home"?35:60, xs: 20 }, left: { md: 0, xs: 15 }, zIndex: 999, cursor:'pointer' }} />:null}
                {logo_option === '2' && mode === 0?<Box onClick={e=>{e.preventDefault(); goHome(true)}} component='img' src={logo} sx={{ position: { md: 'absolute', xs: 'fixed' }, width: { md: 147, xs: 110 }, bottom: { md: 30, xs: 15 }, left: { md: 40, xs: 15 }, zIndex: 999, cursor:'pointer'  }}  />:null}
            </Box>
        </>
    )
}

export default ModelLogHome;