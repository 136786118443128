import React, { useContext, useState, useEffect } from "react";

import Box from '@mui/material/Box'
import { useMediaQuery } from "@mui/material";

import state from "../../state";
import { useSnapshot } from "valtio";
import AppContext from "../../context/AppContext";
import { glassType, API_URL, title, axiosInstanceAuthorized } from "../../utils/constant";
import OptionSelect from "../OptionSelect";
import axios from "axios";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const GlassType = () => {
  const snap = useSnapshot(state);
  const { modelID } = useContext(AppContext);

  const isMobile = useMediaQuery('(max-width:769px)')
  const [glasstypes, setGlasstypes] = useState([]);
  const [checked, setChecked] = useState(false);
  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
  }

  useEffect(()=>{
    axiosInstanceAuthorized.get(API_URL+'glasstype/get')
    .then(response=>{
      const all_glass_types = response.data.glasstypes;
      const glasstype = all_glass_types.filter(item=>(item.list.toLowerCase().split(',').includes(title[modelID].toLowerCase())));
      setGlasstypes(glasstype);
    })
  }, [])

  console.log(snap.blades.opacity);

  return glasstypes.length > 0 ? (
    <Box
        className={!isMobile ? 'overview' : ''}
        sx={{ p: 0, pr: { md: 2, xs: 0 }, mb: { md: 0, xs: 2.5 } }}
    >
        {glasstypes.map((item, index) => (
            <OptionSelect 
            isTexture={checked}
            title={item.title}
            value={item.icon}
            image={item.image}
            opacity={item.opacity / 100}
            id={item.title + 'glass'}
            selected = {snap.blades.textureType === item.title && snap.blades.opacity * 100 === item.opacity}
            />
        ))}

    </Box >
  ):null;
}

export default GlassType;