import React, { useEffect, useState, useContext } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { API_URL, axiosInstanceAuthorized } from "utils/constant";
import { useScreen } from "hooks";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import ShareIcon from "@mui/icons-material/Share";
import AppContext from "context/AppContext";
import { FacebookIcon, InstagramIcon, YoutubeIcon } from "components/SvgIcons";
import { useTranslation } from "react-i18next";

const SocialIcon = ({ title, link, color }) => {
  if (title.toLowerCase().includes("youtube")) {
    return <YoutubeIcon color={color} />;
  }
  if (title.toLowerCase().includes("instagram")) {
    return <InstagramIcon color={color} />;
  }
  if (title.toLowerCase().includes("facebook")) {
    return <FacebookIcon color={color} />;
  }
  if (title.toLowerCase().includes("linkedin")) {
    return <LinkedInIcon color={color} />;
  }
  if (title.toLowerCase().includes("twitter")) {
    return <TwitterIcon color={color} />;
  }
  return <ShareIcon />;
};

const Footer = (props) => {
  const isMobile = useScreen();
  const { mode } = useContext(AppContext);
  const [leftContent, setLeftContent] = useState("");
  const [centerContent, setCenterContent] = useState("");
  const [rightContent, setRightContent] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [footerTheme, setFooterTheme] = useState(
    props.user.footer_theme === null || props.user.footer_theme === undefined
      ? 0
      : props.user.footer_theme
  );

  const { t, i18n } = useTranslation();

  const bracker = "/&|-|&/";

  useEffect(() => {
    // Fetch the current footer content from the backend when the component mounts
    fetchLeftContent(!mode ? "foruser" : props.user.embed_key);
    fetchRightContent(!mode ? "foruser" : props.user.embed_key);
    fetchSocialLinks(!mode ? "foruser" : props.user.embed_key);
  }, []);

  const fetchLeftContent = async (embed_key) => {
    axiosInstanceAuthorized
      .post(API_URL + "page/get", { embed_key: embed_key })
      .then((response) => {
        if (response.data.data === null) return false;
        const { footer_left, theme } = response.data.data;
        const pureData = footer_left.split(bracker);
        setLeftContent(pureData[0]);
        setCenterContent(pureData[1]);
        // if (theme !== null) setFooterTheme(theme);
      });
  };

  const fetchRightContent = async (embed_key) => {
    axiosInstanceAuthorized
      .post(API_URL + "footer/get", { embed_key: embed_key })
      .then((response) => {
        if (response.data.data === null) return false;
        setRightContent(response.data.footers);
      });
  };

  const fetchSocialLinks = async (embed_key) => {
    axiosInstanceAuthorized
      .post(API_URL + "social/get", { embed_key: embed_key })
      .then((response) => {
        if (response.data === null) return false;
        setSocialLinks(response.data.socials);
      });
  };

  const handleGo = (title, description, link) => {
    if (link !== "" && link !== null) {
      var a = document.createElement("a");
      a.href = link;
      a.target = "_blank";
      a.click();
    } else {
      window.location.href = "/page/" + title.replace(/\s/g, "");
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: (theme) => (theme.palette.mode === "dark" ? "#000" : "#fff"),
          marginTop: "80px",
        }}
      >
        <Grid
          container
          className="footer"
          sx={{
            pl: { md: 10, xs: 3 },
            pr: { md: 10, xs: 3 },
            pt: { md: 5, xs: 3 },
            pb: { md: 5, xs: 3 },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: !isMobile ? "column" : "row",
            bgcolor:
              footerTheme == 0 ? "white" : footerTheme == 1 ? "black" : "gray",
            color: footerTheme == 1 ? "white" : "black",
            borderTop: "1px solid gray",
          }}
        >
          <Grid item xs={!isMobile ? 12 : 3}>
            <Box className="footer-left">
              <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
                {leftContent}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={!isMobile ? 12 : 3}>
            <Box className="footer-center">
              <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
                {centerContent}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={!isMobile ? 12 : 3}>
            <Box className="footer-center">
              <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
                {t('SOCIALS')}
              </Typography>
              <br />
              <Box className="d-flex">
                {socialLinks.length > 0
                  ? socialLinks.map((item, index) => {
                      return (
                        <a
                          href={item.link}
                          key={index}
                          style={{
                            color: footerTheme == 0 ? "white" : "black",
                            padding: "5px",
                          }}
                        >
                          <SocialIcon
                            title={item.title}
                            link={item.link}
                            color={footerTheme == 0 ? "white" : "black"}
                          />
                        </a>
                      );
                    })
                  : null}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={!isMobile ? 12 : 3}>
            {rightContent.length > 0 ? (
              <Box className="footer-right">
                <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
                  {t('INFORMATION')}
                </Typography>
                <br />

                {rightContent.map((item, index) => {
                  return (
                    <Typography
                      variant="body1"
                      style={{ whiteSpace: "pre-line", cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleGo(item.title, item.description, item.link);
                      }}
                      key={index}
                    >
                      {t(item.title)}
                    </Typography>
                  );
                })}
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
