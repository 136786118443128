/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { subsystems } from "../utils/constant";
import * as THREE from "three";
import state from "../state";
import { useSnapshot } from "valtio";
import { GuillotineGlassCount } from "../models/DivCount";

import guillotineCompanct from "../assets/models/guillotine.glb";
import guillotineCompanctNew from "../assets/models/guillotine_new.glb";

const xrepeat = 0.07,
  yrepeat = 0.07;

export default function Compact(props) {
  const { width, height, unit, px, py, pz } = props;
  const { nodes, materials } = useGLTF(guillotineCompanct);
  const { nodes:nodesNew, materials:materialsNew } = useGLTF(guillotineCompanctNew);

  const system_cnt = GuillotineGlassCount(width, props.modelID);
  const width1 = width / system_cnt;

  const sh = height / subsystems[0].height;
  const sw = width1 / subsystems[0].width;

  const [opacity, setOpacity] = useState(0.7);
  const [color, setColor] = useState("#a7c7cb");
  const snap = useSnapshot(state);
  const texture = useTexture(snap.structure.textures[0]);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const list = [];

  if (height > 2500) {
    state.columns.systemDirection[props.ID] = 0;
    state.columns.systemDirection[props.ID + 8] = 0;
  }

  // const material = new THREE.MeshPhongMaterial({
  //   color: color,
  //   opacity: opacity,
  //   transparent: true,
  // });

  const InsideElement = ({groupPosition,groupScale,selected}) => {
    const material = new THREE.MeshPhongMaterial({
      color: selected?'#002853':'#a7c7cb',
      opacity: opacity,
      transparent: true,
    });
    return (
      <group position={groupPosition} scale={groupScale}>
        <mesh
          geometry={nodesNew.LeftProfile002.geometry}
          material={
            props.isTexture ? material_texture : materials["profile.mat"]
          }
          material-color={snap.structure.color}
          position={[-3.55, 0, 1.3]}
          rotation={[0, 0, 0]}
          scale={[0.001, 0.0015, 0.0025]}
        />
        <mesh
          geometry={nodesNew.glass.geometry}
          material={material}
          // material-color={snap.structure.color}
          position={[0, 0, 1.3]}
          rotation={[0, 0, 0]}
          scale={[0.003, 0.0015, 0.0047]}
        />
        <mesh
          geometry={nodesNew.LeftProfile002.geometry}
          material={
            props.isTexture ? material_texture : materials["profile.mat"]
          }
          material-color={snap.structure.color}
          position={[3.7, 0, 1.3]}
          rotation={[0, 0, 0]}
          scale={[0.001, 0.0015, 0.0025]}
        />
        <mesh
          geometry={nodesNew.BottomProfile001.geometry}
          material={
            props.isTexture ? material_texture : materials["profile.mat"]
          }
          material-color={snap.structure.color}
          position={[0.07, 0.535, 1.35]}
          rotation={[0, 0, Math.PI / 2]}
          scale={[0.0015, 0.0029, 0.0025]}
        />
        <mesh
          geometry={nodesNew.BottomProfile001.geometry}
          material={
            props.isTexture ? material_texture : materials["profile.mat"]
          }
          material-color={snap.structure.color}
          position={[0.07, -0.535, 1.35]}
          rotation={[0, 0, Math.PI / 2]}
          scale={[0.0015, 0.0029, 0.0025]}
        />
      </group>
    );
  };

  var xbias = 0;
  var ybias = 0;

  if (props.modelID === 6) {
    if (props.side % 2) ybias = 30 * unit;
    if (props.side % 2 === 0) xbias = 40 * unit * (props.side - 3);
  }

  if (props.modelID === 7) {
    if (props.side % 2 === 0) xbias = -50 * unit * (props.side - 3);
    if (props.side % 2) ybias = 20 * unit;
  }

  for (var i = 0; i < system_cnt; i = i + 1) {
    const ID = i * 8 + props.ID;
    const selected = (snap.editingSubSystem && snap.editingSubSystemID === ID);

    list.push(
      <group
        key={i}
        scale={[props.scale * 10, props.scale * 10, props.scale * 7.5]}
        rotation={props.rotation}
        position={[px * unit + xbias, py * unit + ybias, pz * unit]}
        onPointerOver={(e) => {
          setOpacity(0.5);
          setColor("#002853");
          document.body.style.cursor = "pointer";
        }}
        onPointerOut={(e) => {
          setOpacity(0.3);
          setColor("#a7c7cb");
          document.body.style.cursor = "auto";
        }}
        onClick={(e) => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = ID;
          state.editingSystemType = props.systemType;
        }}
      >
        <group
          position={[
            0 - (((system_cnt - 1) * width) / system_cnt / 2) * 0.1 +
              ((i * width) / system_cnt) * 0.1,
            (height * 0.1) / 2,
            0
          ]}
          scale={[100, 100 * sh, 100 * sw * 1.01]}
          rotation={[0, -Math.PI / 2, 0]}
        >
          <mesh
            geometry={nodes.Bottom_Profile.geometry}
            material={
              props.isTexture ? material_texture : materials["profile.mat"]
            }
            material-color={snap.structure.color}
            position={[0.07, -1.36, 1.3]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            scale={[0.01,0.013,0.01]}
          />
          <mesh
            geometry={nodes.Right_Profile.geometry}
            material={
              props.isTexture ? material_texture : materials["profile.mat"]
            }
            material-color={snap.structure.color}
            position={[0.07, -1.36, 1.3]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            scale={[0.01,0.013,0.01]}
          />
          <mesh
            geometry={nodes.Left_profile.geometry}
            material={
              props.isTexture ? material_texture : materials["profile.mat"]
            }
            material-color={snap.structure.color}
            position={[0.07, -1.36, 1.3]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            scale={[0.01,0.013,0.01]}
          />
          <mesh
            geometry={nodes.Head_Profile.geometry}
            material={
              props.isTexture ? material_texture : materials["profile.mat"]
            }
            material-color={snap.structure.color}
            position={[0.07, -1.36, 1.3]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            scale={[0.01,0.013,0.01]}
          />

          <group rotation={[0,(Math.PI/2),0]} position={[-5.1,-0.15,0]} scale={[0.7,0.8,1]}>
          <InsideElement
            groupPosition={
              +snap.columns.systemDirection[ID] === 0
                ? [0, -(snap.columns.systemPos[ID] / 45.5) + 1.3, 4.625]
                : [0, -(snap.columns.systemPos[ID] / 62) + 1, 4.55]
            }
            groupScale={
              +snap.columns.systemDirection[ID] === 0
                ? [0.49, 1, 0.4]
                : [0.5, 1.5, 0.4]
            }
            selected={selected}
          />
          <InsideElement
            groupPosition={
                +snap.columns.systemDirection[ID] === 0
                  ? [
                      0,
                      snap.columns.systemPos[ID] >= 50
                        ? snap.columns.systemPos[ID] /
                          (-(
                            snap.columns.systemPos[ID] /
                            (snap.columns.systemPos[ID] === 100 ? 0.9 : 0.9)
                          ) +
                            0.2)
                        : 0.2,
                      4.567,
                    ]
                  : [0, -0.65, 4.55]
              }
            groupScale={
              +snap.columns.systemDirection[ID] === 0
                ? [0.49, 1, 0.4]
                : [0.5, 1.5, 0.4]
            }
            selected={selected}
          />
          {+snap.columns.systemDirection[ID] === 0 && (
            <InsideElement
              groupPosition={[0, -0.9, 4.51]}
              groupScale={[0.49, 1, 0.4]}
              selected={selected}
            />
          )}

          </group>

        </group>
      </group>
    );
  }

  state.subSystemWidth[props.ID] = width;
  state.subSystemHeight[props.ID] = height;

  return list;
}
