import React, { useEffect, useState, useContext } from "react";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AppContext from "../context/AppContext";
import VpnKeySharpIcon from "@mui/icons-material/VpnKeySharp";
import VpnKeyOffSharpIcon from "@mui/icons-material/VpnKeyOffSharp";
import CircleSelect from "./LeftSide/CircleSelect";

import { useSnapshot } from "valtio";
import axios from "axios";
import state from "../state";
import {
  hasBlade,
  subsystems,
  forteColors,
  curtainColors,
  lengths,
  title,
  coverColors,
  wooddesign,
  API_URL,
  SERVER_URL,
  axiosInstanceAuthorized,
} from "../utils/constant";
import Darken from "./Darken";
import { toastError } from "./Toast";
import { useTranslation } from "react-i18next";

const isCURTAIN = (mID, sID) => {
  return (
    (mID === 9 || mID === 11 || mID === 16 || mID === 20) &&
    ((sID > 20 && sID < 24) || (sID > 44 && sID < 48))
  );
};

const Wall = (props) => {
  return props.flag ? (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ bgcolor: "#F6F6F6", borderRadius: 1, p: 1.25, mb: 2 }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CheckCircleIcon sx={{ height: 20, width: 20, color: "#7AAE75" }} />
        <Typography sx={{ ml: 1.25 }}>Mounting Wall</Typography>
      </Stack>
      <DeleteIcon
        onClick={(e) => {
          e.preventDefault();
          state.walls.added[props.id] = false;
          state.editingWall = false;
          state.editingWallID = 0;
        }}
        sx={{ height: 20, width: 20, color: "#BA181B", cursor: "pointer" }}
      />
    </Stack>
  ) : null;
};

const AddMountingWall = (props) => {
  const { t } = props;
  return (
    <Box sx={{ mt: 4, mb: 2 }}>
      {props.editing ? (
        <Stack spacing={2}>
          <Wall
            flag={true}
            id={props.wallID}
            modelID={props.modelID}
            key={props.wallID}
          />
        </Stack>
      ) : null}

      <Button
        // color="success"
        variant="contained"
        sx={{
          mt: 1.25,
          width: "100%",
          bgcolor: props.adding === false ? props.brandColor : "#BA181B",
          "&:hover": { bgcolor: Darken(props.brandColor) },
        }}
        onClick={() => props.handleAdd()}
      >
        {props.adding === false ? t("Add mounting wall") : t("Cancel")}
      </Button>
    </Box>
  );
};

const Column = (props) => {
  const handleDeleteClick = (e) => {
    e.preventDefault();
    if (props.systemType === 2) {
      state.columns.addedForte[props.index] = false;
      state.columns.fortePos[props.index] = 0;
    } else {
      if (props.index === 23) {
        state.columns.systemPos[23] = 0;
      } else if (!props.systemID) {
        if (
          (props.modelID === 10 ||
            props.modelID === 1 ||
            props.modelID === 2 ||
            props.modelID === 4 ||
            props.modelID === 8) &&
          props.index
        ) {
          state.columns.addedSubSystem[
            (props.index % 8) + (props.index >= 24 ? 24 : 0)
          ] = false;
          state.columns.addedForte[
            (props.index % 8) + (props.index >= 24 ? 24 : 0)
          ] = false;
        } else {
          state.columns.addedSubSystem[props.index % 8] = false;
          state.columns.addedForte[props.index % 8] = false;
        }
      } else if (props.systemID !== 9) {
        state.columns.addedSubSystem[props.index] = false;
        state.columns.addedForte[props.index] = false;
      } else {
        state.columns.addedSubSystem[20] = false;
        state.columns.addedSubSystem[21] = false;
      }
      state.columns.systemPos[props.index] = 0;
      state.columns.systemDirection[props.index] = 0;
      state.columns.deletedSubSystem[props.index] = true;
    }
    state.editingSubSystem = false;
  };

  return (
    <Box sx={{ mt: 4, mb: 2 }}>
      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ bgcolor: "#F6F6F6", borderRadius: 1, p: 1.25, mb: 2 }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <CheckCircleIcon sx={{ height: 20, width: 20, color: "#7AAE75" }} />
            <Typography sx={{ ml: 1.25 }}>
              {isCURTAIN(props.modelID, props.index)
                ? title[props.modelID] + " TOP"
                : props.systemType === 1
                ? props.index === 23 &&
                  props.modelID != 2 &&
                  props.modelID != 8 &&
                  props.modelID !== 9 &&
                  props.modelID !== 20 &&
                  props.modelID !== 11 &&
                  props.modelID !== 16
                  ? title[props.modelID] + " TOP"
                  : subsystems[props.systemID].title
                : "FORTE"}
            </Typography>
          </Stack>
          <DeleteIcon
            onClick={(e) => handleDeleteClick(e)}
            sx={{ height: 20, width: 20, color: "#BA181B", cursor: "pointer" }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

const Option = ({ setShowOption }) => {
  const isMobile = useMediaQuery("(max-width:769px)");
  const { modelID, brandColor, systemID, setSelDoor } = useContext(AppContext);

  const snap = useSnapshot(state);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(true);
  const [checked, setChecked] = useState(false);
  const [woodTexture, setWoodTexture] = useState(null);
  const [value, setValue] = useState({
    rotation: snap.blades.rotation,
    offset: snap.blades.offset,
    direction: snap.blades.direction,
  });
  const [door_type, setDoorType] = useState(0); // 0-Simpel Door, 1-Simple Plus Door, 2-Two Sided

  const { t, i18n } = useTranslation();

  const handleAdd = () => {
    if (snap.walls.adding === true) {
      state.walls.adding = false;
    } else {
      state.walls.adding = true;
    }
  };

  const handleChange = (event) => {
    state.columns.systemDirection[snap.editingSubSystemID] = event.target.value;
  };

  const handleChangeControl = (event) => {
    state.columns.systemDirection[23] = event.target.value;
  };

  const handleChangeSide = (event) => {
    state.systemSide[snap.editingSubSystemID] = event.target.value;
  };

  const offsetChange = (event) => {
    state.blades.direction = event.target.value;
  };

  const handleSubsystemSliderChange = (event) => {
    const newVal = event.target.value;
    if (checked) {
      for (var i = 0; i < 44; i++) {
        if (i === 21 || i === 22 || i === 23) continue;
        if (snap.editingSystemType === 1) state.columns.systemPos[i] = newVal;
        else state.columns.fortePos[i] = newVal;
      }
    } else {
      if (snap.editingSystemType === 1)
        state.columns.systemPos[snap.editingSubSystemID] = newVal;
      else state.columns.fortePos[snap.editingSubSystemID] = newVal;
    }
  };

  const handleCheckedChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleDynamicTopSliderChange = (event, systemID) => {
    state.columns.systemPos[systemID] = event.target.value;
  };

  const handleSliderChange = (newVal) => {
    setValue((pre) => ({ ...pre, ...newVal }));
  };

  const handleChangeLock = (event) => {
    state.columns.systemLock[snap.editingSubSystemID] = event.target.checked;
  };

  const handleChangeCapella = (event) => {
    state.capellaDirection = event.target.value;
  };

  const getTextures = () => {
    axiosInstanceAuthorized
      .get(API_URL + "texture/get")
      .then((response) => {
        setWoodTexture(response.data.textures);
        return response.data.textures;
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "Network Error") {
          toastError(err.message);
        }
      });
  };

  const shouldDisplayOption = () => {
    const allowedModel = modelID !== 18 && modelID !== 16 && modelID !== 11;
    const hasBladeOption = hasBlade[modelID].option;
    return allowedModel && hasBladeOption;
  }

  useEffect(() => {
    setOpen(isMobile);
    getTextures();
    if (modelID === 4 || modelID === 14) {
      state.blades.direction = 1;
      state.blades.offset = 70;
    }
  }, [isMobile]);

  const bias =
    (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 20) &&
    (snap.editingSubSystemID === 6 ||
      snap.editingSubSystemID === 7 ||
      snap.editingSubSystemID === 14 ||
      snap.editingSubSystemID === 15)
      ? 6
      : 0;

  if (snap.editingSubSystem === true) {
    return true ? (
      <Stack
        sx={{
          width: { md: 380, xs: "100%" },
          borderRadius: 0.5,
          py: 2,
          px: 2.5,
          bgcolor: (theme) => theme.palette.background.default,
          position: { md: "absolute", xs: "" },
          right: 40,
          bottom: 140,
          zIndex: 999,
        }}
      >
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
              {isCURTAIN(modelID, snap.editingSubSystemID)
                ? "CURTAIN"
                : (snap.editingSubSystemID === 23 ||
                    snap.editingSubSystemID === 47) &&
                  modelID !== 2 &&
                  modelID !== 8
                ? modelID > 8
                  ? "CURTAIN"
                  : title[modelID] + " TOP"
                : t("Sub System")}
            </Typography>
            {show ? (
              <ExpandMoreIcon
                onClick={() => setShow(!show)}
                sx={{ display: { md: "block", xs: "none" } }}
              />
            ) : (
              <ExpandLessIcon
                onClick={() => setShow(!show)}
                sx={{ display: { md: "block", xs: "none" } }}
              />
            )}
          </Stack>
          {snap.editingSystemType === 2 ||
          subsystems[snap.columns.systemId[snap.editingSubSystemID]].type !==
            "fixedsystem" ? (
            <Collapse in={show} timeout="auto">
              <Stack>
                {snap.editingSubSystemID === 23 ||
                snap.editingSubSystemID === 47 ||
                isCURTAIN(modelID, snap.editingSubSystemID) ? (
                  <Box sx={{ mt: 2 }}>
                    <Stack direction="row">
                      <Typography sx={{ mr: 1.25 }}>{t('Close')}</Typography>
                      <Slider
                        size="small"
                        color="primary"
                        value={Number(
                          snap.columns.systemPos[snap.editingSubSystemID]
                        )}
                        onChange={(event) => {
                          handleDynamicTopSliderChange(
                            event,
                            snap.editingSubSystemID
                          );
                        }}
                        min={0}
                        max={100}
                        sx={{
                          color: "#7596AE",
                          width: "calc(100% - 12px)",
                          mx: "auto",
                        }}
                      />
                      <Typography sx={{ ml: 1.25 }}>{t('Open')}</Typography>
                    </Stack>
                    {isCURTAIN(modelID, snap.editingSubSystemID) &&
                    snap.length.depth * snap.length.width < 50000000 &&
                    snap.length.width > 4000 ? (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={parseInt(snap.columns.systemDirection[23])}
                        onChange={handleChangeControl}
                      >
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="All"
                        />
                        { (![11, 9].includes(modelID)) &&
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="One by One"
                          />
                        }
                      </RadioGroup>
                    ) : null}

                    {isCURTAIN(modelID, snap.editingSubSystemID) ? (
                      <Box
                        className="clearfix"
                        sx={{ display: "flex", flexWrap: "wrap" }}
                      >
                        {curtainColors.map((item, index) => {
                          return (
                            <CircleSelect
                              size="small"
                              isSelected={item.color === snap.systemColor[23]}
                              title={item.title}
                              url={item.url}
                              color={item.color}
                              type={"texture"}
                              material={"structure"}
                              key={index}
                              systemID={23}
                            />
                          );
                        })}
                      </Box>
                    ) : null}
                  </Box>
                ) : (
                  <>
                    <Box sx={{ mt: 2 }}>
                      {snap.columns.systemId[snap.editingSubSystemID] === 0 &&
                      snap.editingSystemType === 1 ? (
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={
                            snap.columns.systemDirection[
                              snap.editingSubSystemID
                            ]
                          }
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="3 Sliders"
                          />
                          {snap.subSystemHeight[
                            snap.editingSubSystemID > 24
                              ? (snap.editingSubSystemID % 8) + 24
                              : snap.editingSubSystemID % 8
                          ] > 2500 ? (
                            <FormControlLabel
                              value={1}
                              control={<Radio disabled />}
                              label="2 Sliders"
                            />
                          ) : (
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label="2 Sliders"
                            />
                          )}
                        </RadioGroup>
                      ) : null}
                      {snap.editingSystemType === 1 &&
                      subsystems[snap.columns.systemId[snap.editingSubSystemID]]
                        .option > 1 ? (
                        // (modelID === 3 || modelID === 5 || snap.editingGlassCount < 6 || modelID === 0)
                        subsystems[
                          snap.columns.systemId[snap.editingSubSystemID]
                        ].option_limit > snap.editingGlassCount ||
                        snap.editingGlassCount % 2 === 1 ||
                        (snap.editingGlassCount === 4 &&
                          snap.columns.systemId[snap.editingSubSystemID] !==
                            5) ||
                        snap.editingGlassCount < 4 ? (
                          // <Box className='d-flex align-items-center w-100'>
                          //     <FormControl>
                          //         <RadioGroup
                          //             row
                          //             aria-labelledby="demo-row-radio-buttons-group-label"
                          //             name="row-radio-buttons-group"
                          //             value={snap.columns.systemDirection[snap.editingSubSystemID]}
                          //             onChange={handleChange}
                          //         >
                          //             <FormControlLabel value={0} control={<Radio />} label="LEFT" />
                          //             <FormControlLabel value={1} control={<Radio />} label="RIGHT" />
                          //             {snap.editingSystemType === 1 && (subsystems[snap.columns.systemId[snap.editingSubSystemID]].option > 1) && snap.editingGlassCount > 4 && snap.editingGlassCount % 2 === 0 ? <FormControlLabel value={2} control={<Radio />} label="CENTERED" /> : null}
                          //         </RadioGroup>
                          //     </FormControl>
                          //     <Box sx={{ ml: 'auto' }}>
                          //         <FormControlLabel control={<Switch checked={checked} onChange={handleCheckedChange} />} label="ALL SLIDE" />
                          //     </Box>
                          // </Box>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={
                                snap.columns.systemDirection[
                                  snap.editingSubSystemID
                                ]
                              }
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value={0}
                                control={<Radio />}
                                label={t(`LEFT`)}
                              />
                              <FormControlLabel
                                value={1}
                                control={<Radio />}
                                label={t(`RIGHT`)}
                              />
                              {snap.editingSystemType === 1 &&
                              subsystems[
                                snap.columns.systemId[snap.editingSubSystemID]
                              ].option > 1 &&
                              snap.editingGlassCount > 4 &&
                              snap.editingGlassCount % 2 === 0 ? (
                                <FormControlLabel
                                  value={2}
                                  control={<Radio />}
                                  label={t(`CENTERED`)}
                                />
                              ) : null}
                            </RadioGroup>
                          </FormControl>
                        ) : null
                      ) : null}
                      <Box className="d-flex align-items-center">
                        {snap.editingSystemType === 1 &&
                        snap.columns.systemId[snap.editingSubSystemID] === 2 ? (
                          <FormGroup sx={{ display: "inline" }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    snap.columns.systemLock[
                                      snap.editingSubSystemID
                                    ]
                                  }
                                  onChange={handleChangeLock}
                                  checkedIcon={<VpnKeySharpIcon />}
                                  icon={<VpnKeyOffSharpIcon />}
                                />
                              }
                              label={t(`KEY`)}
                            />
                          </FormGroup>
                        ) : null}
                        {modelID != 0 && (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={checked}
                                onChange={handleCheckedChange}
                              />
                            }
                            label={t(`ALL SLIDE`)}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Stack direction="row">
                        <Typography sx={{ mr: 1.25 }}>{t('Close')}</Typography>
                        <Slider
                          size="small"
                          color="primary"
                          value={Number(
                            snap.editingSystemType === 1
                              ? snap.columns.systemPos[snap.editingSubSystemID]
                              : snap.columns.fortePos[snap.editingSubSystemID]
                          )}
                          onChange={handleSubsystemSliderChange}
                          min={0}
                          max={100}
                          sx={{
                            color: "#7596AE",
                            width: "calc(100% - 12px)",
                            mx: "auto",
                          }}
                        />
                        <Typography sx={{ ml: 1.25 }}>{t('Open')}</Typography>
                      </Stack>
                    </Box>

                    {(modelID === 2 || modelID === 8) &&
                    snap.columns.systemId[snap.editingSubSystemID] === 9 ? (
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={snap.capellaDirection}
                          onChange={handleChangeCapella}
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="Outside"
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Inside"
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : null}

                    {(snap.editingSystemType === 2 &&
                      snap.columns.addedForte[snap.editingSubSystemID]) ||
                    snap.columns.systemId[snap.editingSubSystemID] === 6 ||
                    snap.columns.systemId[snap.editingSubSystemID] === 9 ? (
                      <Box
                        className="clearfix"
                        sx={{ display: "flex", flexWrap: "wrap" }}
                      >
                        {forteColors.map((item, index) => {
                          return (
                            <CircleSelect
                              size="small"
                              isForte={
                                snap.columns.systemId[
                                  snap.editingSubSystemID
                                ] === 6 || snap.editingSystemType === 2
                              }
                              isSelected={
                                snap.columns.systemId[
                                  snap.editingSubSystemID
                                ] === 6 || snap.editingSystemType === 2
                                  ? item.color === snap.forteColor
                                  : item.color ===
                                    snap.systemColor[
                                      (snap.editingSubSystemID >= 19 &&
                                        snap.editingSubSystemID < 24) ||
                                      snap.editingSubSystemID >= 43
                                        ? 20
                                        : snap.editingSubSystemID
                                    ]
                              }
                              title={item.title}
                              url={item.url}
                              color={item.color}
                              type={"texture"}
                              material={"structure"}
                              key={index}
                              systemID={
                                (snap.editingSubSystemID >= 19 &&
                                  snap.editingSubSystemID < 24) ||
                                snap.editingSubSystemID >= 43
                                  ? 20
                                  : snap.editingSubSystemID
                              }
                            />
                          );
                        })}
                      </Box>
                    ) : null}

                    {/* {(snap.editingSystemType === 2 && snap.columns.addedForte[snap.editingSubSystemID])?<Box className="clearfix" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            {forteColors.map((item, index) => {
                            return (
                                <CircleSelect
                                size="small"
                                isSelected={item.color === snap.systemColor[snap.editingSubSystemID === 21?20:snap.editingSubSystemID]}
                                title={item.title}
                                url={item.url}
                                color={item.color}
                                type={"texture"}
                                material={"structure"}
                                key={index}
                                systemID={snap.editingSubSystemID === 21?20:snap.editingSubSystemID}
                            />)
                            })}
                        </Box>:null} */}

                    {snap.columns.systemId[snap.editingSubSystemID] === 7 ? (
                      <Box
                        className="clearfix"
                        sx={{ display: "flex", flexWrap: "wrap" }}
                      >
                        {coverColors[1].map((item, index) => {
                          return (
                            <CircleSelect
                              size="small"
                              isSelected={
                                item.color ===
                                snap.systemColor[snap.editingSubSystemID]
                              }
                              title={item.title}
                              url={item.url}
                              color={item.color}
                              type={"texture"}
                              material={"structure"}
                              key={index}
                              systemID={snap.editingSubSystemID}
                            />
                          );
                        })}
                      </Box>
                    ) : null}

                    {snap.columns.systemId[snap.editingSubSystemID] === 7 ? (
                      <Box
                        className="clearfix"
                        sx={{ display: "flex", flexWrap: "wrap" }}
                      >
                        {woodTexture !== null && woodTexture.length > 0
                          ? woodTexture.map((item, index) => {
                              return item.visible ? (
                                <CircleSelect
                                  size="small-texture"
                                  isSelected={
                                    SERVER_URL + item.image ===
                                    snap.systemTexture[snap.editingSubSystemID]
                                  }
                                  title={item.title}
                                  url={SERVER_URL + item.image}
                                  color={SERVER_URL + item.image}
                                  type={"wood"}
                                  material={"structure"}
                                  key={index}
                                  systemID={snap.editingSubSystemID}
                                />
                              ) : null;
                            })
                          : null}
                      </Box>
                    ) : null}

                    {snap.columns.systemId[snap.editingSubSystemID] === 8 ? (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={snap.systemSide[snap.editingSubSystemID]}
                        onChange={handleChangeSide}
                      >
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="OUT"
                        />
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="IN"
                        />
                      </RadioGroup>
                    ) : null}
                  </>
                )}
              </Stack>
            </Collapse>
          ) : null}
        </>
        <Column
          index={snap.editingSubSystemID - bias}
          key={snap.editingSubSystemID - bias}
          subSystemID={snap.editingSubSystemID - bias}
          systemID={snap.columns.systemId[snap.editingSubSystemID - bias]}
          systemType={snap.editingSystemType}
          modelID={modelID}
        />

        <Button
          sx={{
            bgcolor: "#7596AE",
            borderRadius: 0.5,
            width: "100%",
            display: { xs: "block", md: "none" },
          }}
          variant="contained"
          onClick={() => setShowOption(false)}
        >
          {t('BACK')}
        </Button>
      </Stack>
    ) : null;
  } else {
    return snap.isModel && shouldDisplayOption() ? (
      <Stack
        sx={{
          width: { md: 380, xs: "100%" },
          borderRadius: 0.5,
          py: 2,
          px: 2.5,
          bgcolor: (theme) => theme.palette.background.default,
          position: { md: "absolute", xs: "" },
          right: 40,
          bottom: 140,
          zIndex: 999,
        }}
      >
        {hasBlade[modelID].option ? (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                {t(hasBlade[modelID].label)}
              </Typography>
              {open ? (
                <ExpandMoreIcon
                  onClick={() => setOpen(!open)}
                  sx={{ display: { md: "block", xs: "none" } }}
                />
              ) : (
                <ExpandLessIcon
                  onClick={() => setOpen(!open)}
                  sx={{ display: { md: "block", xs: "none" } }}
                />
              )}
            </Stack>

            <Collapse in={open} timeout="auto">
              <Stack>
                <Box sx={{ mt: 2 }}>
                  <Stack direction="row">
                    <Typography sx={{ mr: 1.25 }}>0</Typography>
                    <Slider
                      size="small"
                      color="primary"
                      value={Number(snap.blades.rotation)}
                      onChange={(_, val) => {
                        state.blades.rotation = val;
                        handleSliderChange({ rotation: val });
                      }}
                      min={0}
                      max={90}
                      sx={{
                        color: snap.blades.offset > 0 ? "darkgray" : "#7596AE",
                        width: "calc(100% - 12px)",
                        mx: "auto",
                        pointerEvents:
                          snap.blades.offset > 0 ? "none !important" : "",
                      }}
                    />
                    <Typography sx={{ ml: 1.25 }}>90</Typography>
                  </Stack>
                </Box>
                {modelID === 4 || modelID === 14 ? (
                  <>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ mt: 2 }}
                    >
                      <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                        {t('Blade offset')}
                      </Typography>
                    </Stack>
                    <Box sx={{ mt: 2 }}>
                      {snap.length.width <=
                      lengths[modelID].mid_length_limit[0] ? (
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={snap.blades.direction}
                            onChange={offsetChange}
                            sx={{
                              color: snap.blades.rotation > 0 ? "darkgray" : "",
                              pointerEvents:
                                snap.blades.rotation > 0
                                  ? "none !important"
                                  : "",
                            }}
                          >
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label="LEFT"
                              disabled={snap.blades.rotation > 0}
                            />
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label="RIGHT"
                              disabled={snap.blades.rotation > 0}
                            />
                          </RadioGroup>
                        </FormControl>
                      ) : null}
                      <Stack direction="row">
                        <Typography sx={{ mr: 1.25 }}>{t('Close')}</Typography>
                        <Slider
                          size="small"
                          color="primary"
                          value={Number(snap.blades.offset)}
                          onChange={(_, val) => {
                            state.blades.offset = val;
                            handleSliderChange({ offset: val });
                          }}
                          min={0}
                          max={70}
                          sx={{
                            color:
                              snap.blades.rotation > 0 ? "darkgray" : "#7596AE",
                            width: "calc(100% - 12px)",
                            mx: "auto",
                            pointerEvents:
                              snap.blades.rotation > 0 ? "none !important" : "",
                          }}
                        />
                        <Typography sx={{ ml: 1.25 }}>{t('Open')}</Typography>
                      </Stack>
                    </Box>
                  </>
                ) : null}
              </Stack>
            </Collapse>
          </>
        ) : null}
        {modelID !== 18 && modelID !== 16 && modelID !== 11 ? (
          <AddMountingWall
            adding={snap.walls.adding}
            editing={snap.editingWall}
            wallID={snap.editingWallID}
            handleAdd={handleAdd}
            modelID={modelID}
            brandColor={brandColor}
            t={t}
          />
        ) : null}

        <Button
          sx={{
            bgcolor: brandColor,
            borderRadius: 0.5,
            width: "100%",
            display: { xs: "block", md: "none" },
            "&:hover": { bgcolor: Darken(brandColor) },
          }}
          variant="contained"
          onClick={() => setShowOption(false)}
        >
          {t('BACK')}
        </Button>
      </Stack>
    ) : null;
  }
};

export default Option;
