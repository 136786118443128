import { lazy } from 'react';
import AboutPage from 'pages/AboutPage';

const PageRoutes = {

    path: '',
    children: [
        {
            path: 'page/:id',
            element: <AboutPage  />
        },
    ]
};

export default PageRoutes;