import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from 'services/auth.service';

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const fetchData = async () => {
      const authorized = await authService.check();
      if (!authorized) {
        authService.logout();
        navigate('/login', { replace: true });
      }
    };
    fetchData();

    if (!user) {
      console.log("not user1")
      navigate('/login', { replace: true });
    }
  }, [user, navigate]);

  return children;
};

export default AuthGuard;
