import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { ConfigContext } from '../context/config';
import UserMenu from '../common/UserMenu';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { ROLE_TYPES, logo, modeltypes } from '../utils/constant';
import Balance from 'common/Balance';
import AppContext from 'context/AppContext';
import Basket from 'common/Basket';
import Cart from 'common/Cart';
import ModelLog from 'common/ModelLogo';
import ModelLogHome from 'common/ModelLogoHome';
import Help from 'common/Help';
import ProfileComplete from 'common/ProfileComplete';
import Logout from "@mui/icons-material/Logout";
import { IconButton, Popover, Typography, ListItem, Collapse, Button } from '@mui/material';
import AuthServiceEmbed from "services/auth.embed.service";
import Lang from 'common/Lang';

const logout = (embed_key) => {
  AuthServiceEmbed.logout();
  window.location.href = "/embed/login/" + embed_key;
};

const Navbar = (props) => {
  const { nomenu, userID, products } = props;
  const { isDark } = useContext(ConfigContext);
  const { mode, userFrom } = useContext(AppContext);
  const { t } = useTranslation(); // Use the useTranslation hook
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    switch (window.location.pathname) {
      case '/orders':
        setActiveStep(2);
        break;
      case '/clients':
        setActiveStep(1);
        break;
      case '/setting':
        setActiveStep(0);
        break;
      case '/home':
        setActiveStep(-1);
        break;
      default:
        break;
    }
    if (window.location.pathname.includes('/allproducts/')) setActiveStep(-1);
  }, []);

  let user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).user : null;
  if (userFrom === 'embed') {
    user = JSON.parse(localStorage.getItem('embed_user')) ? JSON.parse(localStorage.getItem('embed_user')) : null;
  }

  return (
    <Stack
      sx={{ width: '100%', zIndex: 99, position: 'relative', backgroundColor: isDark ? 'black' : 'white' }}
    >
      <Stack direction='row' justifyContent='space-between'
        sx={{
          px: { md: 0, xs: 3 }, py: { md: 4, xs: 3 },
          position: 'relative',
        }}
      >
        <Box>
          <ModelLogHome pos="home" />
        </Box>

        {nomenu === true ? null : (
          <>
            {activeStep === -1 ? (
              <Stack direction="row"
                className="center"
                sx={{ display: { md: 'flex', sm: 'none', xs: 'none' } }}
                spacing={3}
              >
                {modeltypes.map((item, index) => {
                  const hasProducts = products.filter(product => {
                    const inProducts = user.products ? user.products.includes(product.title) : false;
                    return product.product_type === item && inProducts;
                  }).length > 0;
                  return hasProducts ? (
                    <Link href="#" underline="none" color="inherit"
                      key={index}
                      onClick={e => { e.preventDefault(); handleClickScroll("menu_" + index) }}
                    >
                      {t(item)}
                    </Link>
                  ) : null;
                })}
              </Stack>
            ) : null}
            {mode === 0 || mode === 1 ? (
              <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', right: '100px' }}>
                  <Lang />
                </div>
                <div style={{ position: 'absolute', right: '50px' }}>
                  <Cart color="black" />
                </div>
                <div style={{ position: 'absolute', right: '0px', top: '0px' }}>
                  {mode === 1 && (JSON.parse(localStorage.getItem('embed_user')).embedlogin === '1' || JSON.parse(localStorage.getItem('embed_user')).embedlogin === 1) ? (
                    <IconButton>
                      <Logout color="black"
                        sx={{ cursor: 'pointer' }}
                        onClick={e => {
                          e.preventDefault();
                          logout((JSON.parse(localStorage.getItem('embed_user'))).embed_key);
                        }}
                      />
                    </IconButton>
                  ) : null}
                </div>
                <div style={{ position: 'absolute', right: '0px', top: '-35px' }}>
                  {mode === 0 ? <Balance /> : null}
                </div>
                {mode === 0 ? <UserMenu /> : null}
                {mode === 0 ? (
                  <div style={{ position: 'absolute', right: user.role === ROLE_TYPES.ADMIN ? '140px' : '200px', top: '0px' }}>
                    <ProfileComplete userID={userID} />
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default Navbar;
