import React, { useEffect, useState } from "react";
import SubSystem from "../subsystems/SubSystem";

const rotations = [
  [Math.PI / 2, Math.PI, 0],
  [Math.PI / 2, -Math.PI / 2, 0],
  [Math.PI / 2, Math.PI, Math.PI / 2],
  [Math.PI / 2, Math.PI / 2, 0],
];

function getAvailableSubID(props, calculation, py, side = null) {
  let amplified = calculation - (py / 1000);
  let subID = calculation;
  if (amplified !== calculation) {
    let validSubID = new Set([...Object.values(props.addedSubSystem)])
    if (side) {
      subID = Math.max(...validSubID) + side;
    }
  }
  props.setAddedSubSystem((prev) => {
    prev[amplified] = calculation;
    return prev;
  });
  return subID;
}

function SideInfill(props) {
  const { side, columns, length, int_length, isGardes } = props;
  const pos_list = [];
  const list = [];
  // const bias = isGardes ? 30 : 6;
  const bias = isGardes ? 24 : 0;
  const rwidth =
    length.depth <= 6000
      ? length.depth - int_length.column_depth
      : (length.depth - int_length.column_depth * 2) / 2;
  const dw =
    length.depth <= 6000
      ? 0
      : (length.depth - int_length.column_depth * 2) / 2 / 2 +
        int_length.column_depth / 2;
  if (side === 2 || side === 4) {
    const sID = columns.systemId[side === 2 ? bias + 0 : bias + 1];
    // if (props.modelID === 16 && side === 2 && !isGardes) return null;
    // if (props.modelID === 16 && side === 4 && isGardes) return null;
    if (columns.addedSubSystem[bias + side / 2 - 1]) {
      if (props.modelID === 16) {
        if (side === 2) {
          const subID = getAvailableSubID(props, side === 2 ? bias + 0 : bias + 1, null);
          list.push(
            <SubSystem
              key={-2}
              px={
                (side === 4
                  ? length.width / 2
                  : -length.width / 2 + (sID < 3 ? 90 : 30)) *
                (3 - side)
              }
              py={int_length.oy - length.depth / 2 - props.dy - dw}
              pz={-int_length.oz}
              rotation={rotations[side - 1]}
              width={rwidth}
              height={length.height1 - props.dz}
              int_div_width={int_length.int_div_width}
              max_div_width={int_length.max_div_width}
              unit={props.unit}
              ID={subID}
              systemID={sID}
              scale={props.unit}
              type={2}
              side={side}
              isGardes={isGardes}
              systemType={1}
              modelID={props.modelID}
              isTexture={props.isTexture}
              texture={props.texture}
            />
          );
        }
        if (side === 4) {
          const subID = getAvailableSubID(props, side === 2 ? bias + 0 : bias + 1, int_length.oy - length.depth / 2 - props.dy - dw, side);
          list.push(
            <SubSystem
              key={-5}
              px={
                (side === 2
                  ? length.width / 2
                  : -length.width / 2 + (sID < 3 ? 90 : 30)) *
                (3 - side)
              }
              py={int_length.oy - length.depth / 2 - props.dy - dw}
              pz={-int_length.oz}
              rotation={rotations[side - 1]}
              width={rwidth}
              height={length.height1 - props.dz}
              int_div_width={int_length.int_div_width}
              max_div_width={int_length.max_div_width}
              unit={props.unit}
              ID={subID}
              systemID={sID}
              scale={props.unit}
              type={2}
              side={side}
              isGardes={isGardes}
              systemType={1}
              modelID={props.modelID}
              isTexture={props.isTexture}
              texture={props.texture}
            />
          );
        }
      } else {
        const subID = getAvailableSubID(props, side === 2 ? bias + 0 : bias + 1, null);
        list.push(
          <SubSystem
            key={-2}
            px={(-length.width / 2 + (sID < 3 ? 90 : 30)) * (3 - side)}
            py={int_length.oy - length.depth / 2 - props.dy - dw}
            pz={-int_length.oz}
            rotation={rotations[side - 1]}
            width={rwidth}
            height={length.height1 - props.dz}
            int_div_width={int_length.int_div_width}
            max_div_width={int_length.max_div_width}
            unit={props.unit}
            ID={subID}
            systemID={sID}
            scale={props.unit}
            type={2}
            side={side}
            isGardes={isGardes}
            systemType={1}
            modelID={props.modelID}
            isTexture={props.isTexture}
            texture={props.texture}
          />
        );
      }

      if (columns.addedForte[bias + side / 2 - 1]) {
        const subID = getAvailableSubID(props, side === 2 ? bias + 0 : bias + 1, null);
        list.push(
          <SubSystem
            key={-1}
            px={(-length.width / 2) * (3 - side) - 50 * (side - 3)}
            py={int_length.oy - length.depth / 2 - dw}
            pz={-int_length.oz}
            rotation={rotations[side - 1]}
            width={rwidth}
            height={length.height1 - props.dz}
            int_div_width={int_length.int_div_width}
            max_div_width={int_length.max_div_width}
            unit={props.unit}
            ID={subID}
            systemID={6}
            scale={props.unit}
            type={2}
            side={side}
            systemType={2}
            modelID={props.modelID}
            isTexture={props.isTexture}
            texture={props.texture}
          />
        );
      }
    }
    if (length.depth > 6000) {
      if (columns.addedSubSystem[bias + side / 2 - 1]) {
        const py1 = int_length.oy - length.depth / 2 - props.dy + dw;
        const subID = getAvailableSubID(props, side === 2 ? bias + 0 : bias + 1, py1, side);
        list.push(
          <SubSystem
            key={-3}
            px={(-length.width / 2 + (sID < 3 ? 90 : 30)) * (3 - side)}
            py={py1}
            pz={-int_length.oz}
            rotation={rotations[side - 1]}
            width={rwidth}
            height={length.height1 - props.dz}
            int_div_width={int_length.int_div_width}
            max_div_width={int_length.max_div_width}
            unit={props.unit}
            ID={subID}
            systemID={sID}
            scale={props.unit}
            type={2}
            side={side}
            systemType={1}
            modelID={props.modelID}
            isTexture={props.isTexture}
            texture={props.texture}
          />
        );
        if (columns.addedForte[bias + side / 2 - 1]) {
          const subID = getAvailableSubID(props, side === 2 ? bias + 0 : bias + 1, 0);
          list.push(
            <SubSystem
              key={-4}
              px={(-length.width / 2) * (3 - side) - 50 * (side - 3)}
              py={int_length.oy - length.depth / 2 + dw}
              pz={-int_length.oz}
              rotation={rotations[side - 1]}
              width={rwidth}
              height={length.height1 - props.dz}
              int_div_width={int_length.int_div_width}
              max_div_width={int_length.max_div_width}
              unit={props.unit}
              ID={subID}
              systemID={6}
              scale={props.unit}
              type={2}
              side={side}
              systemType={2}
              modelID={props.modelID}
              isTexture={props.isTexture}
              texture={props.texture}
            />
          );
        }
      }
    }

    return list;
  } else {
    pos_list.push({ pos: columns.pos[0] + int_length.column_width, id: 0 });
    pos_list.push({ pos: length.width, id: 0 });
    for (let i = 2; i < 24; i++) {
      if (columns.added[i])
        pos_list.push({
          pos: columns.pos[i] + int_length.column_width / 2,
          id: i,
        });
      pos_list.sort(function (a, b) {
        return a.pos - b.pos;
      });
    }

    for (let i = 0; i < pos_list.length - 1; i = i + 1) {
      // if (i == 1 || i == 2 || i == 3 || i == 5 || i == 0) continue
      const ID = pos_list[i].id + 2;
      if (columns.addedSubSystem[bias + ID] === false) continue;

      const swidth =
        pos_list[i + 1].pos - pos_list[i].pos - int_length.column_width;
      const px = pos_list[i].pos;
      const subID = getAvailableSubID(props, bias + ID, null);
      list.push(
        <SubSystem
          key={i}
          px={-px - swidth / 2 + length.width / 2}
          py={int_length.oy - 150}
          pz={-int_length.oz}
          rotation={rotations[0]}
          width={swidth}
          height={length.height1}
          int_div_width={int_length.int_div_width}
          max_div_width={int_length.max_div_width}
          systemID={columns.systemId[bias + ID]}
          unit={props.unit}
          ID={subID}
          scale={props.unit}
          type={2}
          side={side}
          systemType={1}
          modelID={props.modelID}
          isTexture={props.isTexture}
          texture={props.texture}
        />
      );
      if (columns.addedForte[bias + ID] === false) continue;
      list.push(
        <SubSystem
          key={i + 100}
          px={-px - swidth / 2 + length.width / 2}
          py={int_length.oy}
          pz={-int_length.oz}
          rotation={rotations[0]}
          width={swidth}
          height={length.height1}
          int_div_width={int_length.int_div_width}
          max_div_width={int_length.max_div_width}
          systemID={6}
          unit={props.unit}
          ID={subID}
          scale={props.unit}
          type={2}
          side={side}
          systemType={2}
          modelID={props.modelID}
          isTexture={props.isTexture}
          texture={props.texture}
        />
      );
    }
    return list;
  }
}

export default function SideInfills4(props) {
  const [addedSubSystem, setAddedSubSystem] = useState({});
  const list = [];
  for (let i = 0; i < 4; i = i + 1) {
    if (i === 2 && props.modelID != 16) continue;
    if (props.walls.added[i]) continue;
    list.push(
      <SideInfill
        key={i}
        side={i + 1}
        columns={props.columns}
        length={props.length}
        int_length={props.int_length}
        systemID={props.systemID}
        unit={props.int_length.unit}
        dy={props.dy}
        dz={props.dz}
        modelID={props.modelID}
        isGardes={props.isGardes}
        isRoomy={props.isRoomy}
        isTexture={props.isTexture}
        texture={props.texture}
        walls={props.walls}
        addedSubSystem={addedSubSystem}
        setAddedSubSystem={setAddedSubSystem}
      />
    );
  }
  return list;
}
