import React from "react";
import { Typography, Container } from "@mui/material";
import { countries } from "countries-list";

const AccessDeniedPage = (props) => {
  return (
    <Container maxWidth="sm" sx={{ p: 10 }}>
      <Typography variant="h2" align="center">
        {props.message !== null ? "Access Denied" : null}
      </Typography>
      <Typography variant="body1" align="center" sx={{ mt: 20 }}>
        {props.message}
      </Typography>
      {props.message !== null ? (
        <Typography variant="body1" align="center" sx={{ mt: 40 }}>
          Your IP Address is {localStorage.getItem("ip_addr")}
          ,&nbsp;&nbsp;&nbsp;&nbsp;
          {countries[localStorage.getItem("country")]?.name}
        </Typography>
      ) : null}
    </Container>
  );
};

export default AccessDeniedPage;
