import React from 'react';
import SubSystem from '../subsystems/SubSystem';

export default function TopInfills2(props) {
    return (
        props.columns.addedSubSystem[20]?<SubSystem
            {...props}
            type={2}
            ID={20}
        />:null
    )
}
